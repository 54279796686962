import { TableCell, TableRow } from '@kubecost-frontend/holster';

import { useClusters } from '../../../contexts/ClusterConfig';
import { toCurrency } from '../../../services/format';
import { APICloudCostTableDatum } from '../../../types/cloudCosts';

interface TableRowProps extends APICloudCostTableDatum {
  costSuffix: string;
}

const CloudCostRow = ({
  costSuffix,
  credit,
  kubernetesPercent,
  name,
  totalCost,
}: TableRowProps) => {
  const { modelConfig } = useClusters();

  return (
    <TableRow>
      {/* row name */}
      <TableCell align={'left'}>{name}</TableCell>
      <TableCell align={'left'}>
        {`${toCurrency(credit, modelConfig.currencyCode)}${costSuffix}`}
      </TableCell>
      <TableCell align={'left'}>{`${(kubernetesPercent * 100).toFixed(1)}%`}</TableCell>
      {/* total cost */}
      <TableCell align={'right'} style={{ paddingRight: '2em' }}>
        {`${toCurrency(totalCost, modelConfig.currencyCode)}${costSuffix}`}
      </TableCell>
    </TableRow>
  );
};

export { CloudCostRow };
