import { Button, ButtonGroup } from '@material-ui/core';

import { colorMap } from '../../services/colors';

const timeWindows = ['12h', '24h', '7d', '30d'];

export const TimeWindowSelector = ({ activeTimeWindow, setActiveTimeWindow }: any) => (
  <ButtonGroup aria-label={'small outlined button group'} size={'small'}>
    {timeWindows.map((window: string) => (
      <Button
        key={window}
        onClick={() => setActiveTimeWindow(window)}
        size={'medium'}
        style={{ color: activeTimeWindow === window ? colorMap.blue : 'gray' }}
      >
        {window}
      </Button>
    ))}
  </ButtonGroup>
);
