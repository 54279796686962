import { ReactNode } from 'react';
/**
 * Create child component(s) based on the result of some boolean expression.
 */

interface ComponentProps {
  children: ReactNode;
  condition: boolean;
}

const If = ({ children, condition }: ComponentProps): JSX.Element => (
  <>{condition ? children : null}</> // eslint-disable-line react/jsx-no-useless-fragment
);

export { If };
