import React from 'react';

import { Button as CoreButton, Divider } from '@material-ui/core';

import { Typography } from '@kubecost-frontend/holster';

import { AdminDashboard } from './AdminDashboard';
import { ClusterManagement } from './ClusterManagement';
import { Feedback } from './Feedback';
import { KubecostInfo } from './KubecostInfo';
import { UserProfile } from './UserProfile';

export type AllocationLabelConfig = {
  department_label: string;
  environment_label: string;
  owner_label: string;
  product_label: string;
  team_label: string;
};

const Tabs: { label: string; id: string }[] = [
  { label: 'Feedback', id: 'feedback' },
  { label: 'User Profile', id: 'user-profile' },
  { label: 'Teams', id: 'teams' },
  { label: 'Cluster Management', id: 'cluster-management' },
  { label: 'Kubecost Info', id: 'kubecost-info' },
];

const Settings = (): React.ReactElement => {
  const [scrollValue, setScrollValue] = React.useState('General');

  const handleScroll = (scrollTo: string) => {
    setScrollValue(scrollTo);
    document.getElementById(scrollTo)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <Typography style={{ marginBottom: '1em' }} variant={'h4'}>
        Settings
      </Typography>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '250px 1fr',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          {Tabs.map((item) => (
            // TODO: make this use fragments
            <CoreButton
              onClick={() => handleScroll(item.id)}
              style={{ color: item.id === scrollValue ? 'green' : '' }}
            >
              {item.label}
            </CoreButton>
          ))}
        </div>
        <div className={'px-8 pb-8'}>
          <Feedback />
          <Divider style={{ marginTop: '4em', marginBottom: '4em' }} variant={'middle'} />
          <UserProfile />
          <Divider style={{ marginTop: '4em', marginBottom: '4em' }} variant={'middle'} />
          <AdminDashboard />
          <Divider style={{ marginTop: '4em', marginBottom: '4em' }} variant={'middle'} />
          <ClusterManagement />
          <Divider style={{ marginTop: '4em', marginBottom: '4em' }} variant={'middle'} />
          <KubecostInfo />
        </div>
      </div>
      <div style={{ clear: 'both' }} />
    </div>
  );
};

export default Settings;
