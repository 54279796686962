import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CachedIcon from '@material-ui/icons/Cached';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ShowChartIcon from '@material-ui/icons/ShowChart';

import {
  Alert,
  BudgetAlert,
  ClusterHealthAlert,
  DiagnosticAlert,
  EfficiencyAlert,
  RecurringUpdate,
  SpendChangeAlert,
} from '../../services/alerts';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: '2em',
    display: 'grid',
    gridTemplateRows: '30px 1fr 30px',
  },
  table: {
    minWidth: 650,
  },
  totalRow: {
    fontWeight: 500,
  },
}));

export const AlertsInspectCard = ({
  alerts,
  resourceName,
}: {
  alerts: Alert[];
  resourceName: string;
}) => {
  const classes = useStyles();

  // as of now, we are not overly concerned with health/diagnostic as it pertains to
  // the inspect page.
  const alertTypeIconMap = {
    budget: {
      icon: <AttachMoneyIcon />,
      displayName: 'Allocation Budget',
      format: ({ threshold, window }: BudgetAlert) =>
        `Every ${window} ${threshold ? `& > $${threshold}` : ''}`,
    },
    recurringUpdate: {
      icon: <CachedIcon />,
      displayName: 'Allocation Recurring Update',
      format: ({ window }: RecurringUpdate) => `Every ${window}`,
    },
    diagnostic: {
      icon: <CachedIcon />,
      displayName: 'Diagnostic',
      format: ({ window }: DiagnosticAlert) => `Event impacts uptime - ${window}`,
    },
    '': {
      icon: <CachedIcon />,
      displayName: 'Unknown',
      format: ({ window }: Alert) => `Every ${window}`,
    },
    efficiency: {
      icon: <AssessmentIcon />,
      displayName: 'Allocation Efficieny',
      format: ({ efficiencyThreshold, spendThreshold, window }: EfficiencyAlert) =>
        `Every ${window} if Efficiency < ${efficiencyThreshold * 100}% ${
          spendThreshold ? `and Total Cost > $${spendThreshold}` : ''
        } `,
    },
    health: {
      icon: <CachedIcon />,
      displayName: 'Health',
      format: ({ threshold }: ClusterHealthAlert) => `Health changes by ${threshold}`,
    },
    spendChange: {
      icon: <ShowChartIcon />,
      displayName: 'Spend Change',
      format: ({ baselineWindow, relativeThreshold, window }: SpendChangeAlert) =>
        `Cost over last ${window} > ${
          relativeThreshold * 100
        }% change relative to previous ${baselineWindow}`,
    },
    assetBudget: {
      icon: <AttachMoneyIcon />,
      displayName: 'Asset Budget',
      format: ({ threshold, window }: BudgetAlert) =>
        `Every ${window} ${threshold ? `& > $${threshold}` : ''}`,
    },
    cloudReport: {
      icon: <CachedIcon />,
      displayName: 'Asset Recurring Update',
      format: ({ window }: RecurringUpdate) => `Every ${window}`,
    },
  };

  if (!alerts || !alerts.length) {
    return (
      <Card
        className={classes.root}
        style={{
          backgroundColor: '#ececec',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <div>
          <CardHeader
            title={
              <div>
                No Alerts for <strong>{resourceName}</strong>
              </div>
            }
          />
          <CardContent>
            <Link href={'/alerts.html'}>
              <Button color={'primary'} size={'large'} variant={'contained'}>
                Explore Alerts
              </Button>
            </Link>
          </CardContent>
        </div>
      </Card>
    );
  }
  return (
    <Card className={classes.root}>
      <Typography component={'div'} variant={'h6'}>
        Alerts for <strong>{resourceName}</strong>
      </Typography>
      <CardContent>
        <TableContainer>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Trigger</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alerts.map((item: Alert) => (
                <TableRow key={item.type}>
                  <TableCell>
                    <Chip
                      icon={alertTypeIconMap[item.type].icon}
                      label={alertTypeIconMap[item.type].displayName}
                    />
                  </TableCell>
                  <TableCell>{alertTypeIconMap[item.type].format(item)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions disableSpacing>
        <Link href={'/alerts.html'} style={{ marginLeft: 'auto' }} target={'_blank'}>
          <Button endIcon={<KeyboardArrowRightIcon />} style={{ color: '#2196f3' }}>
            all alerts
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};
