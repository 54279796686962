import { useNavigate } from 'react-router';

import { IconButton, Link } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';

import { Header } from '../../components/Header';

export const InspectHeader = () => {
  const navigate = useNavigate();
  return (
    <Header breadcrumbs={[{ href: '', name: '' }]}>
      <IconButton
        onClick={() => {
          window.location.reload();
        }}
      >
        <RefreshIcon />
      </IconButton>
      <Link href={''}>
        <IconButton onClick={() => navigate('/settings')}>
          <SettingsIcon />
        </IconButton>
      </Link>
    </Header>
  );
};
