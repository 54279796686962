import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { colorMap } from '../../services/colors';

interface AreaChartVisualAddOnProps {
  // width: number;
  dataSet: any;
}

export const BarChartVisualAddOn = ({ dataSet }: AreaChartVisualAddOnProps) => {
  const currency = 'USD';

  return (
    <ResponsiveContainer height={'100%'} width={'100%'}>
      <BarChart
        data={dataSet}
        height={300}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        width={500}
      >
        <CartesianGrid strokeDasharray={'3 3'} />
        <XAxis dataKey={'name'} />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        <Bar dataKey={'cost'} fill={colorMap.blue} stackId={'a'} />
      </BarChart>
    </ResponsiveContainer>
  );
};
