import { useState } from 'react';

import capitalize from 'lodash/capitalize';

import { Chip, IconButton, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { AssetLabelOverride, ReportConfig } from './reportingHelpers';

interface DataSourceGroupingChipProps {
  handleRowUpdate: (value: string, allocationAggType: string) => void;
  item: any;
  labelMap: any;
  reportConfig: ReportConfig;
  saveOverride: any;
}

export const DataSourceGroupingChip = ({
  handleRowUpdate,
  item,
  labelMap,
  reportConfig,
  saveOverride,
}: DataSourceGroupingChipProps) => {
  const [showTextField, setShowTextField] = useState(false);

  const handleSave = () => {
    saveOverride();
    setShowTextField(false);
  };

  const handleDefaultLableValueForType = (aggType: string) => {
    const defaultResponse = `label:${labelMap[`${aggType}`] || aggType}`;
    if (reportConfig.assetLabelOverrides.length > 0) {
      const foundItem = reportConfig.assetLabelOverrides.find(
        (item: AssetLabelOverride) => item.allocationAggregation === aggType,
      );
      if (foundItem) {
        return foundItem.assetLabelOverride;
      }
      return defaultResponse;
    }
    if (aggType.startsWith('label:')) {
      return aggType;
    }
    return defaultResponse;
  };
  return (
    <>
      {showTextField ? (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '30px 1fr',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={handleSave}>
            <SaveIcon color={'primary'} />
          </IconButton>
          <TextField
            defaultValue={handleDefaultLableValueForType(item)}
            id={'standard-basic'}
            onChange={(e: any) => handleRowUpdate(e.currentTarget.value, item)}
            fullWidth
          />
        </div>
      ) : (
        <Chip
          deleteIcon={<EditIcon />}
          label={capitalize(item)}
          onDelete={() => setShowTextField(true)}
        />
      )}
    </>
  );
};
