import { memo, useEffect, useState } from 'react';

import get from 'lodash/get';
import map from 'lodash/map';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/styles';

import { Alert } from '@kubecost-frontend/holster';

import ETLFileList from '../../components/ETL/ETLFileList';
import { Header } from '../../components/Header';
import { Loading } from '../../components/Loading';
import Section from '../../components/PaperSection';
import { Warning, Warnings } from '../../components/Warnings';
import cluster from '../../services/cluster';
import { diagnostics } from '../../services/diagnostics';
import { model } from '../../services/model';

const Breadcrumbs = [
  { name: 'Settings', href: 'settings' },
  { name: 'ETL Status', href: 'etl-status' },
];

const KubecostETLDoc =
  'https://github.com/kubecost/docs/blob/master/diagnostics.md#kubecost-etl-pipeline-metrics';

const useStyles = makeStyles({
  page: {
    minWidth: '700px',
    maxWidth: '1200px',
    width: '95%',
    margin: 'auto',
  },
  card: {
    margin: '12px 12px 12px 12px',
    display: 'flex',
    flexFlow: 'column',
    padding: '12px 0px 12px 0px',
  },
  description: {
    padding: '24px 36px',
    marginBottom: 20,
  },
});

const ETLStatusPage = () => {
  const classes = useStyles();

  const [fetch, setFetch] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [etlAllocationDailyStatus, setETLAllocationDailyStatus] = useState({});
  const [etlAllocationHourlyStatus, setETLAllocationHourlyStatus] = useState({});
  const [etlAssetsDailyStatus, setETLAssetsDailyStatus] = useState({});
  const [etlAssetsHourlyStatus, setETLAssetsHourlyStatus] = useState({});
  const [etlDisabled, setETLDisabled] = useState(false);
  const [etlStatusOpen, setETLStatusOpen] = useState(false);
  const [etlMessageType, setETLMessageType] = useState('');
  const [etlStatusTitle, setETLStatusTitle] = useState('');
  const [etlMessages, setETLMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [warnings, setWarnings] = useState<Array<Warning>>([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [openedETLDay, setOpenedETLDay] = useState(null);
  const [repairingETL, setRepairingETL] = useState(false);
  const [repairETLError, setRepairETLError] = useState('');

  // removes all _leading_ empty files and reverses the order to start
  // with most recent
  const filterETLStatusFiles = (status) => {
    for (let i = 0; i < status.files.length; ++i) {
      if (!status.files[i].empty) {
        break;
      }
      status.files[i] = null;
    }

    status.files.reverse();
  };

  // initialize
  async function initialize() {
    const clusterMap = await model.clusterInfoMap();
    const clusterInfo = await model.clusterInfo();
    const cinfo = get(clusterMap, clusterInfo.id, clusterInfo);
    const clusterNameId = cluster.clusterNameId({
      clusterId: cinfo.id,
      clusterName: cinfo.name,
    });

    const crumbs = Breadcrumbs.concat();
    crumbs[1].name = `ETL Status [${clusterNameId}]`;

    setBreadcrumbs(crumbs);
    setInitialized(true);
  }

  // Fetch all diagnostic data
  async function fetchData() {
    if (fetch) {
      setLoading(true);

      await initETLDiagnostics();

      setLoading(false);
      setFetch(false);
    }
  }

  // initializes ETL diagnostics
  const initETLDiagnostics = async () => {
    try {
      let etlAllocDaily = {};
      let etlAllocHourly = {};
      let etlAssetDaily = {};
      let etlAssetHourly = {};

      const etlStatus = await diagnostics.etlStatus();

      if (etlStatus.allocation) {
        if (etlStatus.allocation['1d']) {
          etlAllocDaily = etlStatus.allocation['1d'];
          filterETLStatusFiles(etlAllocDaily);
        }
        if (etlStatus.allocation['1h']) {
          etlAllocHourly = etlStatus.allocation['1h'];
          filterETLStatusFiles(etlAllocHourly);
        }
      }

      if (etlStatus.asset) {
        if (etlStatus.asset['1d']) {
          etlAssetDaily = etlStatus.asset['1d'];
          filterETLStatusFiles(etlAssetDaily);
        }
        if (etlStatus.asset['1h']) {
          etlAssetHourly = etlStatus.asset['1h'];
          filterETLStatusFiles(etlAssetHourly);
        }
      }

      setETLAllocationDailyStatus(etlAllocDaily);
      setETLAllocationHourlyStatus(etlAllocHourly);
      setETLAssetsDailyStatus(etlAssetDaily);
      setETLAssetsHourlyStatus(etlAssetHourly);
    } catch (err) {
      console.error(err);
      // Errors here indicate that either ETL backups are disabled, or ETL is disabled
      // So, we'll just disable ETL panels if this happens
      setETLDisabled(true);
    }
  };

  // Handle an ETL Expand File Clicked
  const handleETLExpandClicked = (file, etlType, messages) => {
    setETLStatusTitle(`ETL Status [${file.name}]`);
    setOpenedETLDay(file);
    setETLMessages(messages);
    setETLMessageType(etlType);
    setETLStatusOpen(true);
  };

  // Handle Closing the ETL Error Dialog
  const handleETLStatusClose = () => {
    setETLStatusTitle('');
    setETLMessages([]);
    setOpenedETLDay(null);
    setETLMessageType('');
    setETLStatusOpen(false);
  };

  // Handle repairing an ETL entry
  const handleETLRepair = async () => {
    // File Name is epoch start -> end
    const dateRange = get(openedETLDay, 'name', '');
    if (dateRange === '') {
      return;
    }

    setRepairingETL(true);

    try {
      if (etlMessageType == 'allocation') {
        await model.etlAllocationRepair(dateRange);
      } else if (etlMessageType == 'assets') {
        await model.etlAssetRepair(dateRange);
      }
    } catch (e: any) {
      console.log(e);
      setRepairETLError('Server Error: Unable to repair ETL. Please check your console for logs.');
    }
    await initETLDiagnostics();

    setRepairingETL(false);

    handleETLStatusClose();
  };

  // Handle fetching data
  useEffect(() => {
    if (!initialized) {
      initialize();
    }
    if (initialized && fetch) {
      fetchData();
    }
  }, [initialized, fetch]);

  return (
    <div className={classes.page}>
      <Header breadcrumbs={breadcrumbs}>
        <IconButton aria-label={'refresh'} onClick={() => setFetch(true)}>
          <RefreshIcon />
        </IconButton>
      </Header>

      {initialized && !loading && warnings.length > 0 && <Warnings warnings={warnings} />}
      {(!initialized || loading) && <Loading message={'Running Diagnostics...'} />}

      {initialized && !etlDisabled && !loading && (
        <Section
          buttonHref={KubecostETLDoc}
          buttonText={'?'}
          buttonVariant={'outlined'}
          id={'etlAllocHourly'}
          info={'Diagnostic data for Kubecost ETL Allocation Pipeline'}
          title={'ETL Allocation (Hourly) Status'}
        >
          {!loading && (
            <>
              <Typography variant={'body2'}>
                <b>Coverage</b>
              </Typography>
              <Typography color={'textSecondary'} variant={'body2'}>
                {etlAllocationHourlyStatus.start} - {etlAllocationHourlyStatus.end}
              </Typography>
              <Box p={1} />
              <Typography variant={'body2'}>
                <b>Completed</b>
              </Typography>
              <Typography color={'textSecondary'} variant={'body2'}>
                {etlAllocationHourlyStatus.progress}
              </Typography>
              <Box p={1} />
              <ETLFileList
                etlType={'allocation'}
                files={etlAllocationHourlyStatus.files}
                onETLFileClick={handleETLExpandClicked}
              />
            </>
          )}
        </Section>
      )}

      {initialized && !etlDisabled && !loading && (
        <Section
          buttonHref={KubecostETLDoc}
          buttonText={'?'}
          buttonVariant={'outlined'}
          id={'etlAllocDaily'}
          info={'Diagnostic data for Kubecost ETL Allocation Pipeline'}
          title={'ETL Allocation (Daily) Status'}
        >
          {!loading && (
            <>
              <Typography variant={'body2'}>
                <b>Coverage</b>
              </Typography>
              <Typography color={'textSecondary'} variant={'body2'}>
                {etlAllocationDailyStatus.start} - {etlAllocationDailyStatus.end}
              </Typography>
              <Box p={1} />
              <Typography variant={'body2'}>
                <b>Completed</b>
              </Typography>
              <Typography color={'textSecondary'} variant={'body2'}>
                {etlAllocationDailyStatus.progress}
              </Typography>
              <Box p={1} />
              <ETLFileList
                etlType={'allocation'}
                files={etlAllocationDailyStatus.files}
                onETLFileClick={handleETLExpandClicked}
              />
            </>
          )}
        </Section>
      )}

      {initialized && !etlDisabled && !loading && (
        <Section
          buttonHref={KubecostETLDoc}
          buttonText={'?'}
          buttonVariant={'outlined'}
          id={'etlAssetsHourly'}
          info={'Diagnostic data for Kubecost ETL Assets Pipeline'}
          title={'ETL Assets (Hourly) Status'}
        >
          {!loading && (
            <>
              <Typography variant={'body2'}>
                <b>Coverage</b>
              </Typography>
              <Typography color={'textSecondary'} variant={'body2'}>
                {etlAssetsHourlyStatus.start} - {etlAssetsHourlyStatus.end}
              </Typography>
              <Box p={1} />
              <Typography variant={'body2'}>
                <b>Completed</b>
              </Typography>
              <Typography color={'textSecondary'} variant={'body2'}>
                {etlAssetsHourlyStatus.progress}
              </Typography>
              <Box p={1} />
              <ETLFileList
                etlType={'assets'}
                files={etlAssetsHourlyStatus.files}
                onETLFileClick={handleETLExpandClicked}
              />
            </>
          )}
        </Section>
      )}

      {initialized && !etlDisabled && !loading && (
        <Section
          buttonHref={KubecostETLDoc}
          buttonText={'?'}
          buttonVariant={'outlined'}
          id={'etlAssetsDaily'}
          info={'Diagnostic data for Kubecost ETL Assets Pipeline'}
          title={'ETL Assets (Daily) Status'}
        >
          {!loading && (
            <>
              <Typography variant={'body2'}>
                <b>Coverage</b>
              </Typography>
              <Typography color={'textSecondary'} variant={'body2'}>
                {etlAssetsDailyStatus.start} - {etlAssetsDailyStatus.end}
              </Typography>
              <Box p={1} />
              <Typography variant={'body2'}>
                <b>Completed</b>
              </Typography>
              <Typography color={'textSecondary'} variant={'body2'}>
                {etlAssetsDailyStatus.progress}
              </Typography>
              <Box p={1} />
              <ETLFileList
                etlType={'assets'}
                files={etlAssetsDailyStatus.files}
                onETLFileClick={handleETLExpandClicked}
              />
            </>
          )}
        </Section>
      )}

      <Dialog maxWidth={'lg'} onClose={handleETLStatusClose} open={etlStatusOpen} fullWidth>
        <DialogTitle id={'alert-dialog-title'}>{etlStatusTitle}</DialogTitle>
        <DialogContent>
          {repairETLError !== '' && (
            <Alert content={repairETLError} title={'ETL Repair Error'} variant={'danger'} />
          )}
          {repairingETL && <Loading message={'Repairing...'} />}
          <List dense>
            {map(
              etlMessages,
              (etlError, index) =>
                etlError !== null && (
                  <ListItem key={index} dense>
                    <code>{etlError}</code>
                  </ListItem>
                ),
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={handleETLStatusClose}>
            OK
          </Button>
          {openedETLDay != null && !openedETLDay.isRepairing && (
            <Button color={'primary'} onClick={handleETLRepair}>
              Repair
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default memo(ETLStatusPage);
