// Reect
import React, { FC, ReactNode } from 'react';

// Holster
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Chip,
} from '@kubecost-frontend/holster';
// Icons
import { EllipsisIcon } from '../../../assets/images/ellipsis-icon';

// types
import {
  HostedTeamInvitation,
  HostedTeamsResponse,
  HostedTeamsUser,
} from '../types';
import DeleteTeamInvitation from './DeleteTeamInvitation';
import DeleteTeamMember from './DeleteTeamMember';

interface HostedTeamMemberTableProps {
  team: HostedTeamsResponse;
}

export const TeamMembersTable: FC<HostedTeamMemberTableProps> = ({ team }) => {
  const columns: ReactNode[] = [
    'Name',
    'Email',
    <span>
      <EllipsisIcon />
    </span>,
  ];

  if (!team) {
    return <></>;
  }

  return (
    <>
      {(team.users.length > 0 || team.team_invitations.length > 0) && (
        <Table className="w-[100%] mt-8">
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableHeadCell className="text-left">{col}</TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {team.users.map((member) => (
              <TableRow className="h-12">
                <TableCell className="text-left font-bold">
                  {member.name}
                </TableCell>
                <TableCell className="text-left">{member.email}</TableCell>
                <TableCell className="text-left">
                  <DeleteTeamMember
                    name={member.name}
                    teamId={team.id}
                    teamName={team.name}
                    userId={member.id}
                  />
                </TableCell>
              </TableRow>
            ))}
            {team.team_invitations.length > 0 &&
              team.team_invitations.map((invitation) => (
                <TableRow className="h-12">
                  <TableCell className="text-left text-kc-gray-200">
                    Invite Pending
                  </TableCell>
                  <TableCell className="text-left">
                    {invitation.email}
                  </TableCell>
                  <TableCell className="text-left">
                    <DeleteTeamInvitation
                      teamId={team.id}
                      email={invitation.email}
                      invitationId={invitation.id}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
      {team.users.length === 0 && team.team_invitations.length === 0 && (
        <div className="text-center">
          <svg
            className="mx-auto mt-8 mb-8 h-12 w-12 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          <h2 className="mt-2 text-lg font-medium text-gray-900">
            Add team members
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            You haven’t added any team members to your project yet. Get started
            by adding a new member at the top!
          </p>
        </div>
      )}
    </>
  );
};
