import { ReactElement, memo } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { SpotReady } from '../../services/savings/spot';

import ReadyIcon from './ReadyIcon';

function keyEntryFor(readiness: SpotReady): ReactElement {
  return (
    <ListItem>
      <ListItemIcon>
        <ReadyIcon readiness={readiness} />
      </ListItemIcon>
      <ListItemText>
        <Typography>{readiness}</Typography>
      </ListItemText>
    </ListItem>
  );
}

const TableKey = memo(() => (
  <List>
    {keyEntryFor(SpotReady.No)}
    {keyEntryFor(SpotReady.Maybe)}
    {keyEntryFor(SpotReady.Yes)}
    {keyEntryFor(SpotReady.NA)}
  </List>
));

export { TableKey };
