import { useEffect, useState } from 'react';

import { OpenInNew } from '@material-ui/icons';

import {
  Button,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

import { ArrowIcon } from '../../../assets/images';
import { EllipsisIcon } from '../../../assets/images/ellipsis-icon';
import { Header } from '../../../components/Header2New';
import { useClusters } from '../../../contexts/ClusterConfig';

import { getUnutilizedLocalDisks } from './api';

const LOW_DISK_THRESHOLD = 0.2;

export const LowDiskUsageNew = () => {
  const [underutilizedDisks, setUnderutilizedDisks] = useState([]);
  const [openActionsMenu, setOpenActionsMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { clusterConfig, envConfig, grafanaUrl, promQueryUrl, thanosQueryUrl } = useClusters();

  const grafanaDiskURL = (diskName: string): string =>
    `${grafanaUrl}/d/nBH7qBgMk/attached-disk-metrics?var-disk=${diskName}`;

  const onTableRowClick = (diskName: string) => () => {
    window.open(grafanaDiskURL(diskName));
  };

  const handleOpenMenu = (idx: number) => {
    setOpenActionsMenu(!openActionsMenu);
    setCurrentIndex(idx);
  };
  async function fetchData() {
    const values = await getUnutilizedLocalDisks(
      LOW_DISK_THRESHOLD,
      clusterConfig,
      envConfig,
      thanosQueryUrl,
      promQueryUrl,
    );
    setUnderutilizedDisks(values);
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header
        refreshCallback={fetchData}
        title={
          <>
            Cluster Savings
            <Typography className={'font-normal text-kc-gray-200'} variant={'p'}>
              <a href={'savings'}>
                <ArrowIcon className={'inline'} direction={'UP'} />
                Back to savings
              </a>
            </Typography>
          </>
        }
      />

      <div style={{ marginBottom: '2em' }}>
        <Typography variant={'h5'}>Local Disks with low utilization</Typography>
        <br />
        Attached disks with under 20% current usage and 30% predicted usage can often be resized to
        save costs. Consider launching new nodes with smaller disks on the next node turndown.
      </div>

      <div>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableHeadCell>Disk Name</TableHeadCell>
              <TableHeadCell>Cluster</TableHeadCell>
              <TableHeadCell>Current Utilization</TableHeadCell>
              <TableHeadCell>Recommendation</TableHeadCell>
              <TableHeadCell>Savings</TableHeadCell>
              <TableHeadCell>Actions</TableHeadCell>
            </TableRow>
          </TableHead>
          {!underutilizedDisks.length ? (
            <TableCell align={'center'} colSpan={5}>
              No data available
            </TableCell>
          ) : (
            <TableBody>
              {underutilizedDisks.map((disk, index) => (
                <TableRow>
                  <TableCell style={{ color: '#2196f3', textDecoration: 'underline' }}>
                    {disk.metric.instance}{' '}
                    <OpenInNew style={{ fontSize: 'small', marginLeft: '0.5em' }} />
                  </TableCell>
                  <TableCell>{clusterConfig.id}</TableCell>
                  <TableCell>
                    {`${parseFloat((disk.value[1] / disk.value[2]) * 100).toFixed(1)}%`}
                  </TableCell>
                  <TableCell>
                    Resize disk to {Math.ceil(disk.value[2] / 1024 / 1024 / 1024 / 5) * 5 * 0.5} Gb
                  </TableCell>
                  <TableCell>${disk.savings.toFixed(2)}</TableCell>
                  <TableCell className={'hover: flex cursor-pointer justify-center pt-5'}>
                    <Button onClick={() => handleOpenMenu(index)} variant={'default'}>
                      <EllipsisIcon />
                    </Button>
                  </TableCell>
                  {openActionsMenu && index === currentIndex ? (
                    <Menu
                      items={[{ leftIcon: null, text: ' View Grafana Instance' }]}
                      onClose={handleOpenMenu}
                      selectItem={onTableRowClick(disk.metric.instance)}
                    />
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </div>
    </>
  );
};

LowDiskUsageNew.displayName = 'LowDiskUsage';
