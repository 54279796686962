import { FacebookCircularProgress } from '../../components/CircularProgress';

interface CircleProgressProps {
  label: string;
  value: number;
  width: string;
}

export const CircleProgress = ({ label, value, width }: CircleProgressProps) => (
  <div>
    <FacebookCircularProgress
      size={width}
      thickness={10}
      value={value > 100 ? 100 : value}
      variant={'determinate'}
    />
    <div>{label}</div>
    <div>{value}%</div>
  </div>
);
