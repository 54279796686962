import { analytics } from './analytics';

/**
 * This module defines a standard way of communicating with the Kubecost API,
 * and provides methods to simplify this communication.
 *
 * In particular, on top of typical HTTP conventions, we expect all responses
 * to be JSON-formatted, and to adhere to the shape:
 *
 * {
 *   "code": number,
 *   "data": dataPayload
 * }
 */

// default headers
// any passed headers are merged with these
const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export interface KubecostResponse<T> {
  code: number;
  data: T;
  warning?: string;
}

export default {
  /**
   * Issue a request to the Kubecost API.
   *
   * @param url - The url to GET.
   * @param opts - Options passed along to ``fetch()``.
   * @returns - A ``Promise`` containing the expected response.
   */
  async fetch<T>(url: string, opts?: RequestInit): Promise<KubecostResponse<T>> {
    // shallow-copy ``opts`` using the spread operator
    // this way, the ``headers`` property of the ``opts`` argument isn't modified,
    // and if ``opts`` is ``undefined``, we get ``options = {}``
    const options = { ...opts };

    // merge default headers with ``opts`` headers, giving preference to ``opts`` headers.
    options.headers = { ...defaultHeaders, ...options.headers };

    // Grab the start time.
    const startTime = performance.now();

    // make request
    const response = await fetch(url, opts);

    // Grab the ending perf time.
    const endTime = performance.now();
    const responseDurationMilliseconds = endTime - startTime;

    analytics.recordRequestResponse(url, response, responseDurationMilliseconds, opts);

    // error on non-2XX response codes
    if (!response.ok) {
      const txt = await response.text();
      throw new Error(`Got a non-2XX response code.
        Status: ${response.status}
        Reason: ${txt}`);
    }

    // decode json
    return response.json();
  },

  // An alias to ``fetch`` which implies a method of GET.
  async get<T>(url: string, opts?: RequestInit): Promise<KubecostResponse<T>> {
    const options = { ...opts };
    options.method = 'GET';
    return this.fetch<T>(url, options);
  },

  // An alias to ``fetch`` which implies a method of POST.
  // NOTE: In the future we may want to permit a second generic type here,
  // representing the type of the request payload.
  // Then, the method could handle JSON-serializing the payload
  // as appropriate. For the time being, callers must provide
  // JSON-serialized strings themselves.
  async post<T>(url: string, opts?: RequestInit): Promise<KubecostResponse<T>> {
    const options = { ...opts };
    options.method = 'POST';
    return this.fetch<T>(url, options);
  },

  // An alias to ``fetch`` which implies a method of PATCH.
  // NOTE: In the future we may want to permit a second generic type here,
  // representing the type of the request payload.
  // Then, the method could handle JSON-serializing the payload
  // as appropriate. For the time being, callers must provide
  // JSON-serialized strings themselves.
  async patch<T>(url: string, opts?: RequestInit): Promise<KubecostResponse<T>> {
    const options = { ...opts };
    options.method = 'PATCH';
    return this.fetch<T>(url, options);
  },

  // An alias to ``fetch`` which implies a method of PUT.
  // NOTE: In the future we may want to permit a second generic type here,
  // representing the type of the request payload.
  // Then, the method could handle JSON-serializing the payload
  // as appropriate. For the time being, callers must provide
  // JSON-serialized strings themselves.
  async put<T>(url: string, opts?: RequestInit): Promise<KubecostResponse<T>> {
    const options = { ...opts };
    options.method = 'PUT';
    return this.fetch<T>(url, options);
  },

  // An alias to ``fetch`` which implies a method of DELETE.
  async delete<T>(url: string, opts?: RequestInit): Promise<KubecostResponse<T>> {
    const options = { ...opts };
    options.method = 'DELETE';
    return this.fetch<T>(url, options);
  },
};
