import { MouseEventHandler, memo, useEffect, useState } from 'react';

import get from 'lodash/get';

import grey from '@material-ui/core/colors/grey';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import SharedIcon from '@material-ui/icons/ControlCamera';
import NetworkIcon from '@material-ui/icons/DeviceHub';
import AnyIcon from '@material-ui/icons/GroupWork';
import InfoIcon from '@material-ui/icons/Info';
import ComputeIcon from '@material-ui/icons/Memory';
import StorageIcon from '@material-ui/icons/SdStorage';

import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

import { TablePagination } from '../../components/TablePagination';
import { colorMap, greyscale } from '../../services/colors';
import { toCurrency } from '../../services/format';

import { AssetChartMemoized } from './AssetChart';
import { AssetsFilterSection } from './AssetsFilterSection';
import { ReportRow } from './types';

// sort object in descending order on a given property
function descendingComparator(a: ReportRow, b: ReportRow, orderBy: string) {
  if (get(b, orderBy) < get(a, orderBy)) {
    return -1;
  }
  if (get(b, orderBy) > get(a, orderBy)) {
    return 1;
  }
  return 0;
}

// return a comparator (either ascending or descending)
function getComparator(order: 'asc' | 'desc', orderBy: string) {
  return order === 'desc'
    ? (a: ReportRow, b: ReportRow) => descendingComparator(a, b, orderBy)
    : (a: ReportRow, b: ReportRow) => -descendingComparator(a, b, orderBy);
}

// given an array and comparator, sort the array stably.
// if comparator finds two items to be equivalent,
// the item which came first in the original array will continue to come first.
function stableSort(array: ReportRow[], comparator: (arg0: ReportRow, arg1: ReportRow) => number) {
  const stabilizedThis: [ReportRow, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const adjustmentTooltip =
  "Amount added to total cost based on reconciliation with cloud provider's billing data";
const creditTooltip =
  'Amount deducted from total cost due to provider-applied credit. A negative number means the total cost was reduced.';

// return header cells to use for the table.
// the cells can change dynamically with aggregation.
const getHeadCells = (aggregateBy: string[]) => {
  if (!aggregateBy || !aggregateBy.length || aggregateBy.includes('unaggregated')) {
    return [
      { id: 'category', numeric: false, label: '', width: 25 },
      { id: 'name', numeric: false, label: 'NAME', width: 'auto' },
      { id: 'providerID', numeric: false, label: 'Provider ID', width: 'auto' },
      { id: 'details', numeric: false, label: '', width: 300 },
      {
        id: 'credit',
        numeric: true,
        label: 'CREDITS',
        width: 120,
        tooltip: creditTooltip,
      },
      {
        id: 'adjustment',
        numeric: true,
        label: 'ADJUSTED',
        width: 120,
        tooltip: adjustmentTooltip,
      },
      { id: 'totalCost', numeric: true, label: 'COST', width: 120 },
    ];
  }

  return [
    { id: 'category', numeric: false, label: '', width: 25 },
    { id: 'name', numeric: false, label: 'NAME', width: 'auto' },
    { id: 'details', numeric: false, label: '', width: 300 },
    {
      id: 'credit',
      numeric: true,
      label: 'CREDITS',
      width: 120,
      tooltip: creditTooltip,
    },
    {
      id: 'adjustment',
      numeric: true,
      label: 'ADJUSTED',
      width: 120,
      tooltip: adjustmentTooltip,
    },
    { id: 'totalCost', numeric: true, label: 'COST', width: 120 },
  ];
};

// map row category names to icons shown at the left side of the row.
const categoryToIcon = (category: string) => {
  if (category === undefined || category === null) {
    category = '';
  }
  if (category.toLowerCase() === 'storage') {
    return (
      <Tooltip placement={'bottom'} title={'Storage'}>
        <StorageIcon htmlColor={greyscale[2]} />
      </Tooltip>
    );
  }
  if (category.toLowerCase() === 'compute') {
    return (
      <Tooltip placement={'bottom'} title={'Compute'}>
        <ComputeIcon htmlColor={greyscale[2]} />
      </Tooltip>
    );
  }
  if (category.toLowerCase() === 'network') {
    return (
      <Tooltip placement={'bottom'} title={'Network'}>
        <NetworkIcon htmlColor={greyscale[2]} />
      </Tooltip>
    );
  }
  if (category.toLowerCase() === 'shared') {
    return (
      <Tooltip placement={'bottom'} title={'Shared'}>
        <SharedIcon htmlColor={greyscale[2]} />
      </Tooltip>
    );
  }
  return <AnyIcon htmlColor={greyscale[2]} />;
};

// informative tooltip with extra info about a row.
const AssetToolTip = ({
  rowName,
  tooltipData,
}: {
  rowName: string;
  tooltipData: Record<string, { hourlyCost: number; hoursRun: number; preemptible: number | null }>;
}) => {
  const hoursRun = tooltipData[rowName] && tooltipData[rowName].hoursRun.toFixed(2);
  const hourlyCost = tooltipData[rowName] && tooltipData[rowName].hourlyCost.toFixed(5);
  const preemptible = tooltipData[rowName] && tooltipData[rowName].preemptible;
  return (
    <Tooltip
      placement={'top'}
      title={
        <>
          <Typography variant={'p-small'}>Asset Info</Typography>
          <Typography variant={'p-small'}>
            Hours Run: {hoursRun} <br />
            Hourly Cost: {hourlyCost} <br />
            {preemptible !== null &&
              (preemptible === 1 ? (
                <Typography variant={'p-small'}>Preemptible: True</Typography>
              ) : (
                <Typography variant={'p-small'}>Preemptible: False</Typography>
              ))}
          </Typography>
        </>
      }
    >
      <InfoIcon style={{ fontSize: 12, color: grey[500], margin: '0 4px' }} />
    </Tooltip>
  );
};

interface AssetReportProps {
  aggregateBy: any;
  assetData: any[];
  cumulativeData: any[];
  currency: string;
  drillDown: (row: ReportRow) => void;
  tooltipData: {};
  totalData: {
    adjustment: number;
    credit: number;
    name: string;
    providerID: string;
    totalCost: number;
  };
}
const AssetReport = memo(
  ({
    aggregateBy,
    assetData,
    cumulativeData,
    currency,
    drillDown,
    tooltipData,
    totalData,
  }: AssetReportProps) => {
    const headCells = getHeadCells(aggregateBy);

    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState('totalCost');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [filterValue, setFilterValue] = useState('');

    const filteredData = cumulativeData.filter((item) =>
      item.name.toLowerCase().includes(filterValue.toLowerCase()),
    );
    const numData = filteredData.length;
    const lastPage = Math.floor(numData / rowsPerPage);

    useEffect(() => {
      setPage(0);
    }, [numData]);

    const newHandleChangePage = (newPage: number) => setPage(newPage);

    const newHandleChangeRowsPerPage = (newRowCount: number) => {
      setRowsPerPage(newRowCount);
      setPage(0);
    };

    const createSortHandler: (property: string) => MouseEventHandler<HTMLSpanElement> =
      (property: string) => (event) =>
        handleRequestSort(event, property);

    const handleRequestSort = (
      event: MouseEvent<HTMLSpanElement, MouseEvent>,
      property: string,
    ) => {
      const isDesc = orderBy === property && order === 'desc';
      setOrder(isDesc ? 'asc' : 'desc');
      setOrderBy(property);
    };
    9;

    const orderedRows = stableSort(filteredData, getComparator(order, orderBy));
    const pageRows = orderedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    if (assetData.length === 0) {
      return (
        <Typography style={{ padding: 24 }} variant={'p'}>
          No results
        </Typography>
      );
    }

    return (
      <div>
        <div style={{ border: `2px solid ${greyscale[2]}` }}>
          <AssetChartMemoized assetRange={assetData} currency={currency} height={300} n={10} />
        </div>
        <AssetsFilterSection updateFilter={setFilterValue} />
        <TableContainer>
          <Table style={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                {headCells.map((cell) => (
                  <TableHeadCell
                    align={cell.numeric ? 'left' : 'left'}
                    key={cell.id}
                    style={{ width: cell.width }}
                  >
                    <TableSortLabel
                      active={orderBy === cell.id}
                      direction={orderBy === cell.id ? order : 'asc'}
                      onClick={createSortHandler(cell.id)}
                    >
                      {cell.label}
                      {cell.tooltip && (
                        <Tooltip aria-label={cell.tooltip} title={cell.tooltip}>
                          <InfoIcon
                            style={{
                              fontSize: 12,
                              color: grey[500],
                              margin: '0 4px',
                            }}
                          />
                        </Tooltip>
                      )}
                    </TableSortLabel>
                  </TableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {headCells.map((cell) => (
                  <TableCell
                    align={cell.numeric ? 'left' : 'left'}
                    key={cell.id}
                    style={{ fontWeight: 500, width: cell.width }}
                  >
                    {cell.numeric ? toCurrency(totalData[cell.id], currency) : totalData[cell.id]}
                  </TableCell>
                ))}
              </TableRow>
              {pageRows.map((row, key) => {
                const hasUndefined = row.name.indexOf('__undefined__') >= 0;
                const isUndefined = !row.name
                  .split('/')
                  .filter((s: string) => s !== '__undefined__').length;

                return (
                  <Tooltip
                    key={key}
                    title={
                      hasUndefined
                        ? 'Cannot drill in to a row where an aggregation parameter is undefined.'
                        : ''
                    }
                  >
                    <TableRow
                      onClick={() => (hasUndefined ? void 0 : drillDown(row))}
                      key={key}
                      // hover={true}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell align={'left'}>{categoryToIcon(row.category)}</TableCell>
                      <TableCell
                        align={'left'}
                        style={{
                          fontSize:
                            !aggregateBy ||
                            !aggregateBy.length ||
                            aggregateBy.includes('unaggregated')
                              ? '12px'
                              : undefined,
                          color: colorMap.green,
                        }}
                      >
                        {isUndefined ? `No ${aggregateBy}` : row.name}
                        {/* <AssetToolTip rowName={row.name} tooltipData={tooltipData} /> */}
                      </TableCell>
                      {(!aggregateBy ||
                        !aggregateBy.length ||
                        aggregateBy.includes('unaggregated')) && (
                        <TableCell align={'left'} style={{ fontSize: 12 }}>
                          {row.providerID}
                        </TableCell>
                      )}
                      <TableCell align={'left'}>
                        {row.details &&
                          row.details.map((d, i) => (
                            <Chip
                              className={'m-[2px] text-[13px]'}
                              color={'primary'}
                              key={i}
                              label={d}
                            />
                          ))}
                      </TableCell>
                      <TableCell align={'left'} className={'p-[16px] text-[14px]'}>
                        {toCurrency(row.credit, currency)}
                      </TableCell>
                      <TableCell align={'left'} className={'p-[16px] text-[14px]'}>
                        {toCurrency(row.adjustment, currency)}
                      </TableCell>
                      <TableCell align={'left'} className={'p-[16px] text-[14px]'}>
                        {toCurrency(row.totalCost, currency)}
                      </TableCell>
                    </TableRow>
                  </Tooltip>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          count={numData}
          onPageChange={newHandleChangePage}
          onRowsPerPageChange={newHandleChangeRowsPerPage}
          page={Math.min(page, lastPage)}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </div>
    );
  },
);

export { AssetReport };
