import { useQuery } from 'react-query';
import { APIClient } from '../../../services/APIClient';
import { useHosted } from '../../../hooks/useHosted';
import { HostedTeamsResponse } from '../types';

export const hostedTeamsQueryKey = (teamId: string) => ['hosted', 'teams', teamId];

const queryHostedTeam = async (hostedUrl: string, currentTeamId: string) => {
  if (currentTeamId === undefined) {
    return;
  }
  return (await APIClient.get<HostedTeamsResponse>(`${hostedUrl}/api/teams/${currentTeamId}`)).data;
};

export const useGetHostedTeam = (teamId: string) => {
  if (teamId === undefined)
    return {
      data: null,
      team: null,
    };

  const { config } = useHosted();

  const { data, ...args } = useQuery(hostedTeamsQueryKey(teamId), () =>
    queryHostedTeam(config!.userServiceAPIUrl, teamId),
  );
  return { data, team: data, ...args };
};
