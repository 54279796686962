// holster
import { Typography } from '@kubecost-frontend/holster';

import { useHosted } from '../../hooks/useHosted';

export const KubecostInfo = () => {
    const { productVersion } = useHosted();

    return (
      <div className='flex' id={'kubecost-info'}>
        <div className='mb-4'>
          <Typography variant='h5'>
            <strong>Kubecost Info</strong>
          </Typography>
          <Typography style={{ color: 'gray' }} variant='p'>
            Install information
          </Typography>
          <Typography style={{ color: 'gray', marginTop: '10px' }} variant='p'>
            Product version: {productVersion ?? 'N/A'}
          </Typography>
        </div>
      </div>
    );
};
