import blue from '@material-ui/core/colors/blue';
import brown from '@material-ui/core/colors/brown';
import cyan from '@material-ui/core/colors/cyan';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import indigo from '@material-ui/core/colors/indigo';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';
import yellow from '@material-ui/core/colors/yellow';

const primary = [
  blue[500],
  red[500],
  green[500],
  yellow[500],
  cyan[500],
  orange[500],
  teal[500],
  indigo[500],
  deepOrange[500],
  deepPurple[500],
];

const colorMap = {
  blue: primary[0],
  red: primary[1],
  green: primary[2],
  yellow: primary[3],
  cyan: primary[4],
  orange: primary[5],
  teal: primary[6],
  indigo: primary[7],
  deepOrange: primary[8],
  deepPurple: primary[9],
};

const designSystemColorMap = {
  primary: '#63E892',
  secondary: '#9C8152',
  link: '#28B359',
  danger: '#F21B3F',
  warning: '#FFB30F',
  success: '#31C46C',
  background: '#F7FAF8',
  offWhiteDarker: '#E6EBE9',
};

const greyscale = [grey[300], grey[400], grey[200], grey[500], grey[100], grey[600]];

const browns = [brown[500]];

const d3SchemeCategory10 = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
];

const dataVizColors7 = [
  '#64e892',
  '#6475e8',
  '#e86478',
  '#64c9e8',
  '#e8d064',
  '#c564e8',
  '#e88e64',
];

const accents = ['#FF4215', '#28F0E2', '#FFB12C', '#DB62B9', '#338DBA'];

export {
  accents,
  browns,
  colorMap,
  d3SchemeCategory10,
  dataVizColors7,
  designSystemColorMap,
  greyscale,
  primary,
};
