import { makeStyles } from '@material-ui/styles';
import { memo } from 'react';

import AggregateByControl from '../../../components/AggregateByControl';
import { SelectWindowMemoized } from '../../../components/SelectWindow';

import AlertsControl from './Alerts';
import DownloadControl from './Download';
import EditControl from './Edit';
import OpenControl from './Open';
import SaveControl from './Save';

const useStyles = makeStyles({
  controls: {
    flexGrow: 0,
    minWidth: 200,
    display: 'flex',
    alignItems: 'flex-end',
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
});

const Controls = ({
  aggregateBy,
  chartDisplay,
  chartDisplayOptions,
  clearContext,
  context,
  cumulativeData,
  customShareLabels,
  customShareNamespaces,
  customSharedOverhead,
  defaultShareLabels,
  defaultShareNamespaces,
  defaultSharedOverhead,
  filters,
  findSavedReport,
  idle,
  idleOptions,
  rate,
  rateOptions,
  save,
  savedReports,
  selectReport,
  setAggregateBy,
  setChartDisplay,
  setFilters,
  setIdle,
  setRate,
  setSavedReports,
  setShareSplit,
  setSharedLabels,
  setSharedNamespaces,
  setSharedOverhead,
  setTitle,
  setTitleField,
  setWindow,
  shareSplit,
  shareSplitOptions,
  shareTenancyCosts,
  title,
  titleField,
  unsave,
  window,
  windowOptions,
}) => {
  const classes = useStyles();

  let reportAggregateBy = aggregateBy;
  if (context.length > 0) {
    reportAggregateBy = [context[0].key];
  }

  return (
    <div className={classes.controls}>
      <div style={{ margin: 8 }}>
        <SelectWindowMemoized
          helperText={''}
          setWindow={setWindow}
          window={window}
          windowOptions={windowOptions}
        />
      </div>
      <AggregateByControl
        aggregateBy={aggregateBy}
        id={'aggregate-by-control'}
        setAggregateBy={(aggBy) => {
          // Clear context before manually applying a new aggregateBy
          clearContext();
          setAggregateBy(aggBy);
        }}
        unaggregatedEnabled={false}
      />
      <EditControl
        aggregateBy={aggregateBy}
        chartDisplay={chartDisplay}
        chartDisplayOptions={chartDisplayOptions}
        clearContext={clearContext}
        customShareLabels={customShareLabels}
        customShareNamespaces={customShareNamespaces}
        customSharedOverhead={customSharedOverhead}
        defaultShareLabels={defaultShareLabels}
        defaultShareNamespaces={defaultShareNamespaces}
        defaultSharedOverhead={defaultSharedOverhead}
        filters={filters}
        idle={idle}
        idleOptions={idleOptions}
        rate={rate}
        rateOptions={rateOptions}
        setAggregateBy={setAggregateBy}
        setChartDisplay={setChartDisplay}
        setFilters={setFilters}
        setIdle={setIdle}
        setRate={setRate}
        setShareSplit={setShareSplit}
        setSharedLabels={setSharedLabels}
        setSharedNamespaces={setSharedNamespaces}
        setSharedOverhead={setSharedOverhead}
        setWindow={setWindow}
        shareSplit={shareSplit}
        shareSplitOptions={shareSplitOptions}
        shareTenancyCosts={shareTenancyCosts}
        window={window}
        windowOptions={windowOptions}
      />

      <SaveControl
        aggregateBy={reportAggregateBy}
        chartDisplay={chartDisplay}
        customShareLabels={customShareLabels}
        customShareLabels={customShareLabels}
        customShareNamespaces={customShareNamespaces}
        customShareNamespaces={customShareNamespaces}
        customSharedOverhead={customSharedOverhead}
        defaultShareLabels={defaultShareLabels}
        defaultShareLabels={defaultShareLabels}
        defaultShareNamespaces={defaultShareNamespaces}
        defaultShareNamespaces={defaultShareNamespaces}
        defaultSharedOverhead={defaultSharedOverhead}
        filters={filters}
        findSavedReport={findSavedReport}
        idle={idle}
        rate={rate}
        save={save}
        setSavedReports={setSavedReports}
        setTitle={setTitle}
        setTitleField={setTitleField}
        title={title}
        titleField={titleField}
        unsave={unsave}
        window={window}
      />

      <AlertsControl aggregation={aggregateBy.join(',')} window={window} />

      <OpenControl
        clearContext={clearContext}
        savedReports={savedReports}
        selectReport={selectReport}
      />

      <DownloadControl cumulativeData={cumulativeData} title={title} />
    </div>
  );
};

export default memo(Controls);
