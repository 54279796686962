import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';

import { Header } from '../../components/Header';
import { FetchStates } from '../../constants';
import Logger from '../../services/logger';
import { model as Model } from '../../services/model';
import { AllocationSet } from '../../types/allocation';

import AllocationCard from './AllocationCard';
import AssetCard from './AssetCard';
import CloudCard from './CloudCard';
import EfficiencyCard from './EfficiencyCard';
import MonthlyCostsCard from './MonthlyCostsCard';
import NetworkCard from './NetworkCard';
import SavingsCard from './SavingsCard';
import UtilizationCard from './UtilizationCard';

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'flex-end',
  },
});

const Overview = () => {
  const classes = useStyles();

  const [clusterCount, setClusterCount] = useState(0);
  const [sufficientData, setSufficientData] = useState(false);
  const [fetchState, setFetchState] = useState(FetchStates.LOADING);
  const [timeseriesWindow, setTimeseriesWindow] = useState<'1d' | '7d'>('7d');
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header
        breadcrumbs={[
          {
            href: '',
            name: clusterCount
              ? `Overview / ${clusterCount} cluster${clusterCount > 1 ? 's' : ''}`
              : 'Overview',
          },
        ]}
      >
        <Toolbar className={classes.toolbar} variant={'dense'}>
          <IconButton onClick={() => window.location.reload()}>
            <RefreshIcon />
          </IconButton>
          <Link component={RouteLink} to={'/settings'}>
            <IconButton>
              <SettingsIcon />
            </IconButton>
          </Link>
        </Toolbar>
      </Header>
      {fetchState === FetchStates.ERROR ? (
        <Alert severity={'error'}>Could not connect to Kubecost. Check console for details.</Alert>
      ) : (
        <></>
      )}
      {fetchState === FetchStates.DONE && !sufficientData ? (
        <Alert severity={'info'}>Kubecost is collecting data. Check back in 5 minutes.</Alert>
      ) : (
        <></>
      )}
      <Grid alignItems={'stretch'} spacing={3} container>
        <Grid lg={4} xs={12} item>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <SavingsCard />
          ) : (
            <Skeleton height={215} variant={'rect'} width={365} />
          )}
        </Grid>
        <Grid lg={4} xs={12} item>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <MonthlyCostsCard />
          ) : (
            <Skeleton height={215} variant={'rect'} width={365} />
          )}
        </Grid>
        <Grid lg={4} xs={12} item>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <UtilizationCard />
          ) : (
            <Skeleton height={215} variant={'rect'} width={365} />
          )}
        </Grid>
        <Grid lg={6} xs={12} item>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <AllocationCard win={timeseriesWindow} />
          ) : (
            <Skeleton height={500} variant={'rect'} width={560} />
          )}
        </Grid>
        <Grid lg={6} xs={12} item>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <AssetCard win={timeseriesWindow} />
          ) : (
            <Skeleton height={500} variant={'rect'} width={560} />
          )}
        </Grid>
        <Grid lg={6} xs={12} item>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <EfficiencyCard win={timeseriesWindow} />
          ) : (
            <Skeleton height={500} variant={'rect'} width={560} />
          )}
        </Grid>
        <Grid lg={6} xs={12} item>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <CloudCard />
          ) : (
            <Skeleton height={500} variant={'rect'} width={560} />
          )}
        </Grid>
        <Grid lg={6} xs={12} item>
          {fetchState !== FetchStates.LOADING && sufficientData ? (
            <NetworkCard />
          ) : (
            <Skeleton height={500} variant={'rect'} width={560} />
          )}
        </Grid>
      </Grid>
    </>
  );

  async function fetchData() {
    try {
      const response = await Model.getAllocationSummary('3d', 'cluster', {
        accumulate: true,
        idleByNode: false,
        shareIdle: true,
      });
      const clusters = Object.values(response.data.sets[0].allocations as AllocationSet);
      const minutes = Math.max.apply(
        null,
        clusters.map((alloc) => Model.getSummaryMinutes(alloc)),
      );
      setClusterCount(clusters.length);
      setSufficientData(minutes > 5);
      setTimeseriesWindow(minutes < 2880 ? '1d' : '7d');
      setFetchState(FetchStates.DONE);
    } catch (err) {
      Logger.error(err);
      setFetchState(FetchStates.ERROR);
    }
  }
};

export default Overview;
