import { forwardRef } from 'react';

import { IconProps } from './types';

const SquareInfoIcon = forwardRef<SVGSVGElement>(({ ...args }: IconProps, ref) => (
  <svg
    ref={ref}
    height={'1em'}
    preserveAspectRatio={'xMidYMid meet'}
    viewBox={'0 0 24 24'}
    width={'1em'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...args}
  >
    <path
      d={
        'M3 4v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1zm8 3h2v2h-2V7zm0 4h2v6h-2v-6z'
      }
      fill={'currentColor'}
    />
  </svg>
));

export { SquareInfoIcon };
