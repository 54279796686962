import { Button, Typography } from '@material-ui/core';

const KubecostETLCloudDoc =
  'https://github.com/kubecost/docs/blob/master/diagnostics.md#kubecost-etl-cloud-pipeline-metrics';

const CloudIntegrationEmptyState = () => (
  <div data-testid={'ci-empty-state'} style={{ textAlign: 'center', padding: '1.5em' }}>
    <Typography variant={'h6'}>No Cloud Integrations!</Typography>
    <Typography variant={'body2'}>Get started with our documentation below!</Typography>
    <Button
      onClick={() => {
        window.open(KubecostETLCloudDoc, '_blank');
      }}
      style={{ marginTop: '1em' }}
      variant={'outlined'}
    >
      Cloud Integration Documentation
    </Button>
  </div>
);

export default CloudIntegrationEmptyState;
