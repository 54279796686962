import { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

import { Alert, SpendChangeAlert } from '../../services/alerts';

interface SpendChangeAlertsTableProps {
  alerts: SpendChangeAlert[];
  createAlert: (alert: Alert) => void;
  locked?: {
    aggregate?: string;
    baseline?: string;
    threshold?: number;
    window?: string;
  };
  removeAlert: (alert: Alert) => void;
}

const SpendChangeAlertsTable = ({
  alerts,
  createAlert,
  removeAlert,
  locked = {},
}: SpendChangeAlertsTableProps) => {
  const [newWindow, setNewWindow] = useState('');
  const [newAgg, setNewAgg] = useState('');
  const [newFilters, setNewFilters] = useState('');
  const [newBaseline, setNewBaseline] = useState('');
  const [newThresh, setNewThresh] = useState('');

  const handleOnClick = () => {
    const newAlert = new SpendChangeAlert({
      window: typeof locked.window !== 'undefined' ? locked.window : newWindow,
      aggregation: typeof locked.aggregate !== 'undefined' ? locked.aggregate : newAgg,
      filter: newFilters,
      baselineWindow: typeof locked.baseline !== 'undefined' ? locked.baseline : newBaseline,
      relativeThreshold:
        typeof locked.threshold !== 'undefined' ? locked.threshold : parseFloat(newThresh),
    });
    createAlert(newAlert);
    setNewWindow('');
    setNewAgg('');
    setNewFilters('');
    setNewBaseline('');
    setNewThresh('');
  };

  return (
    <Table className={'w-full'} data-test={'alerts-table'}>
      <TableHead>
        <TableRow>
          <TableHeadCell>Window</TableHeadCell>
          <TableHeadCell>Aggregation</TableHeadCell>
          <TableHeadCell>Filter</TableHeadCell>
          <TableHeadCell>Baseline window</TableHeadCell>
          <TableHeadCell>Relative threshold</TableHeadCell>
          <TableHeadCell>Add/Delete</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {alerts.map((a) => (
          <TableRow key={a.id}>
            <TableCell>{a.window}</TableCell>
            <TableCell>{a.aggregation}</TableCell>
            <TableCell>{a.filter}</TableCell>
            <TableCell>{a.baselineWindow}</TableCell>
            <TableCell>{a.relativeThreshold}</TableCell>
            <TableCell>
              <IconButton data-test={'alert-delete'} onClick={() => removeAlert(a)}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell data-test={'new-alert-window'}>
            {locked.window || (
              <Input onChange={(e) => setNewWindow(e.target.value)} value={newWindow} />
            )}
          </TableCell>
          <TableCell data-test={'new-alert-aggregate'}>
            {locked.aggregate || (
              <Input onChange={(e) => setNewAgg(e.target.value)} value={newAgg} />
            )}
          </TableCell>
          <TableCell data-test={'new-alert-filters'}>
            <Input onChange={(e) => setNewFilters(e.target.value)} value={newFilters} />
          </TableCell>
          <TableCell data-test={'new-alert-baseline'}>
            {locked.baseline || (
              <Input onChange={(e) => setNewBaseline(e.target.value)} value={newBaseline} />
            )}
          </TableCell>
          <TableCell data-test={'new-alert-threshold'}>
            {typeof locked.threshold === 'undefined' ? (
              <Input onChange={(e) => setNewThresh(e.target.value)} value={newThresh} />
            ) : (
              locked.threshold
            )}
          </TableCell>
          <TableCell>
            <IconButton data-test={'new-alert-add'} onClick={handleOnClick}>
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export { SpendChangeAlertsTable };
