import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core';

import { ETLCloudStatus } from '../../../services/diagnostics';

type Props = {
  cloudProvider: ETLCloudStatus | null;
  onClose: () => void;
  open: boolean;
};

type CloudIntegrationListStatProps = {
  content: string;
  name: string;
};

const CloudIntegrationListStat = ({ content, name }: CloudIntegrationListStatProps) => (
  <div
    style={{
      marginTop: '2em',
      width: '100%',
      height: '50px',
      display: 'inline-flex',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    }}
  >
    <Typography color={'textSecondary'} variant={'body2'}>
      {name}
    </Typography>
    <Typography align={'right'} style={{ flexGrow: '1' }} variant={'body2'}>
      {content}
    </Typography>
  </div>
);

const CloudIntegrationDetailDialog = ({ cloudProvider, onClose, open }: Props) => (
  <Dialog data-testid={'ci-dialog'} maxWidth={'md'} onClose={onClose} open={open} fullWidth>
    <DialogTitle>
      <div style={{ display: 'inline-flex', width: '100%' }}>
        <span>Cloud Integration Details</span>
        <div style={{ flexGrow: '1' }}>
          <Chip
            label={cloudProvider && cloudProvider.cloudConnectionStatus}
            style={{
              float: 'right',
              color:
                cloudProvider && cloudProvider.cloudConnectionStatus === 'Connection Successful'
                  ? 'green'
                  : '',
            }}
            variant={'outlined'}
          />
        </div>
      </div>
    </DialogTitle>
    <DialogContent dividers>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          width: '100%',
        }}
      >
        <div style={{ borderRight: '1px solid rgba(0,0,0,0.1)', padding: '1em' }}>
          <Typography variant={'h6'}>
            <b>Cloud Usage</b>
          </Typography>
          <Divider style={{ marginTop: '1em' }} />
          {cloudProvider &&
            cloudProvider.cloudUsage &&
            cloudProvider.cloudConnectionStatus === 'Connection Successful' && (
              <>
                <CloudIntegrationListStat
                  content={`${cloudProvider.cloudUsage.coverage.start} - ${cloudProvider.cloudUsage.coverage.end}`}
                  name={'Coverage'}
                />
                <CloudIntegrationListStat
                  content={cloudProvider.cloudUsage.progress}
                  name={'Completed'}
                />
                <CloudIntegrationListStat
                  content={cloudProvider.cloudUsage.startTime}
                  name={'Start Time'}
                />
                <CloudIntegrationListStat
                  content={cloudProvider.cloudUsage.lastRun}
                  name={'Last Run'}
                />
                <CloudIntegrationListStat
                  content={cloudProvider.cloudUsage.nextRun}
                  name={'Next Run'}
                />
                <CloudIntegrationListStat
                  content={cloudProvider.cloudUsage.refreshRate}
                  name={'Refresh Rate'}
                />
              </>
            )}
        </div>
        <div style={{ padding: '1em' }}>
          <Typography variant={'h6'}>
            <b>Cloud Reconciliation</b>
          </Typography>
          <Divider style={{ marginTop: '1em' }} />
          {cloudProvider &&
            cloudProvider.reconciliation &&
            cloudProvider.cloudConnectionStatus === 'Connection Successful' && (
              <>
                <CloudIntegrationListStat
                  content={`${cloudProvider.reconciliation.coverage.start} - ${cloudProvider.reconciliation.coverage.end}`}
                  name={'Coverage'}
                />
                <CloudIntegrationListStat
                  content={cloudProvider.reconciliation.progress}
                  name={'Completed'}
                />
                <CloudIntegrationListStat
                  content={cloudProvider.reconciliation.startTime}
                  name={'Start Time'}
                />
                <CloudIntegrationListStat
                  content={cloudProvider.reconciliation.lastRun}
                  name={'Last Run'}
                />
                <CloudIntegrationListStat
                  content={cloudProvider.reconciliation.nextRun}
                  name={'Next Run'}
                />
                <CloudIntegrationListStat
                  content={cloudProvider.reconciliation.refreshRate}
                  name={'Refresh Rate'}
                />
              </>
            )}
        </div>
      </div>
    </DialogContent>
    <DialogActions>
      <Button data-testid={'ci-dialog-close'} onClick={onClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default CloudIntegrationDetailDialog;
