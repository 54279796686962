import { Button, Paper, Tooltip, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  section: {
    margin: '12px 12px 12px 12px',
    display: 'flex',
    flexFlow: 'column',
    padding: '12px 0px 12px 0px',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    verticalAlign: 'middle',
  },
  info: {
    margin: '0 16px 0 0',
    verticalAlign: 'middle',
    fontSize: '20px',
    color: grey[500],
  },
});

const PaperSection = (props) => {
  const classes = useStyles();

  const {
    buttonColor = 'default',
    buttonDisabled,
    buttonHref = '',
    buttonText = '',
    buttonVariant = '',
    elevation = 0,
    id,
    info = '',
    title,
  } = props;

  return (
    <Paper elevation={elevation}>
      <div className={classes.section}>
        <div className={classes.content}>
          <Typography variant={'h6'} gutterBottom>
            {title}
          </Typography>
          {info !== '' && (
            <Tooltip placement={'right'} title={info}>
              <InfoIcon className={classes.info} />
            </Tooltip>
          )}
          {buttonText !== '' && (
            <>
              <div style={{ flex: 1 }} />
              {buttonDisabled ? (
                <Tooltip title={buttonDisabled}>
                  <span>
                    <Button
                      color={buttonColor}
                      href={buttonHref}
                      rel={'noopener'}
                      target={'_blank'}
                      variant={buttonVariant}
                      disabled
                    >
                      {buttonText}
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  color={buttonColor}
                  href={buttonHref}
                  rel={'noopener'}
                  target={'_blank'}
                  variant={buttonVariant}
                >
                  {buttonText}
                </Button>
              )}
            </>
          )}
        </div>
        {props.children}
      </div>
    </Paper>
  );
};

export default PaperSection;
