import { Switch } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import useBetaUI from '../../hooks/useBetaUI';

const BetaUISetting = () => {
  const { isBetaUI, setIsBetaUI } = useBetaUI();

  return (
    <section>
      <header className={'flex items-center justify-between pb-4'}>
        <Typography className={'!font-[700]'} variant={'h5'}>
          🎉 A new look is coming to Kubecost!
        </Typography>

        <Typography className={'flex items-center'} variant={'body1'}>
          Preview Beta Theme
          <Switch checked={isBetaUI} color={'primary'} onChange={() => setIsBetaUI(!isBetaUI)} />
        </Typography>
      </header>
      <Typography className={'pb-4'} variant={'body1'}>
        We're excited to announce Kubecost is getting a new look that makes Kubecost even easier to
        use. We've cleaned up your most loved features and improved performance in the areas you use
        the most. Best of all you can preview the new look early by opting into our beta theme
        today.
      </Typography>

      <Typography variant={'body1'}>
        As part of this preview period you might find some features haven't been updated yet (we’re
        working on those) or some things might not work the way you expected (we’re working on those
        too). We've put a ton of work into making your Kubecost experience even better and we thank
        you for your{' '}
        <a
          className={'text-kc-link'}
          href={'https://github.com/kubecost/cost-analyzer-helm-chart/issues/new/choose'}
          target={'_blank'}
        >
          early feedback and bug reports
        </a>
        .{' '}
      </Typography>

      <Divider style={{ marginTop: '4em', marginBottom: '4em' }} variant={'middle'} />
    </section>
  );
};

export default BetaUISetting;
