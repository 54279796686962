import { memo } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import { default as MUIHelpIcon } from '@material-ui/icons/HelpOutline'; // eslint-disable-line import/no-named-default

import { analytics as Analytics } from '../services/analytics';

interface HelpIconLinkProps {
  href: string;
  tooltipText: string;
}

const HelpIconLink = memo(({ href, tooltipText }: HelpIconLinkProps) => (
  <Tooltip title={tooltipText}>
    <a
      href={href}
      onClick={() => Analytics.record('documentation_visited', { url: href })}
      target={'_blank'}
    >
      <IconButton>
        <MUIHelpIcon />
      </IconButton>
    </a>
  </Tooltip>
));

export { HelpIconLink };
