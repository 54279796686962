import { FC } from 'react';

import { Button } from '@kubecost-frontend/holster';

import { EllipsisIcon } from '../../assets/images/ellipsis-icon';

interface MoreControlProps {
  onClick: () => void;
}

const MoreControl: FC<MoreControlProps> = ({ onClick }) => (
  <Button className={'h-[100%]'} onClick={onClick} variant={'default'}>
    <EllipsisIcon />
  </Button>
);

export { MoreControl as default };
