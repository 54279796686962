import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AllocationIcon from '@material-ui/icons/AssessmentOutlined';
import AssetsIcon from '@material-ui/icons/DeveloperBoard';
import { Alert } from '@material-ui/lab';
import upperFirst from 'lodash/upperFirst';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { toDisplayDateRange } from '../../services/format';
import { AllocationFilter, AllocationReport } from '../../types/allocation';
import { AssetFilter, AssetReport } from '../../types/asset';

import { IdleListItem } from './IdleListItem';
import { ReportActionMenu } from './ReportActionMenu';

export type CombinedReports = {
  accumulate: string;
  aggregateBy: string | string[];
  chartDisplay: string;
  filters: AssetFilter[] | AllocationFilter[];
  idle: string;
  rate: string;
  sharedLabels: string[];
  sharedNamespaces: string[];
  sharedOverhead: number;
  title: string;
  window: string;
};

interface ReportsTableProps {
  allocationReports: AllocationReport[];
  assetReports: AssetReport[];
  handleDeleteAllocationReport: (report: AllocationReport) => void;
  handleDeleteAssetReport: (report: AssetReport) => void;
  readOnly: boolean;
  searchTerm: string;
}

export const ReportsTable: FC<ReportsTableProps> = ({
  allocationReports,
  assetReports,
  handleDeleteAllocationReport,
  handleDeleteAssetReport,
  readOnly,
  searchTerm,
}) => {
  const navigate = useNavigate();

  const getAllocationReportLink = (report: AllocationReport) => {
    const searchParams = new URLSearchParams();
    searchParams.set('title', report.title);
    searchParams.set('window', report.window);
    searchParams.set('agg', report.aggregateBy.join(','));
    searchParams.set('idle', report.idle);
    searchParams.set('rate', report.rate);
    searchParams.set('chartDisplay', report.chartDisplay);
    searchParams.set('filters', btoa(JSON.stringify(report.filters)));

    if (report.sharedNamespaces != null) {
      searchParams.set('sharedNamespaces', report.sharedNamespaces.join(','));
    }

    if (report.sharedOverhead != null) {
      searchParams.set('sharedOverhead', report.sharedOverhead.toString());
    }

    if (report.sharedLabels != null) {
      searchParams.set('sharedLabels', report.sharedLabels.toString());
    }

    return `/allocations?${searchParams.toString()}`;
  };

  const getAssetReportLink = (report: AssetReport) => {
    const searchParams = new URLSearchParams();
    searchParams.set('title', report.title);
    searchParams.set('window', report.window);
    searchParams.set('agg', report.aggregateBy);
    searchParams.set('acc', report.accumulate);
    searchParams.set('filters', btoa(JSON.stringify(report.filters)));

    return `/assets?${searchParams.toString()}`;
  };

  const goToAllocationReport = (report: AllocationReport) => {
    navigate(getAllocationReportLink(report));
  };

  const goToAssetReport = (report: AssetReport) => {
    navigate(getAssetReportLink(report));
  };

  const handleGoToReports = (report: CombinedReports) => {
    if (report.idle !== `n/a`) {
      goToAllocationReport(report);
    } else {
      goToAssetReport(report);
    }
  };

  const handleDeleteReports = (report: CombinedReports) => {
    if (report.idle !== `n/a`) {
      handleDeleteAllocationReport(report);
    } else {
      handleDeleteAssetReport(report);
    }
  };

  const handleReportType = (report: CombinedReports) => {
    if (report.idle !== `n/a`) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <AllocationIcon style={{ marginRight: '.5rem' }} />
          <Typography variant={'overline'}>Allocation</Typography>
        </div>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <AssetsIcon style={{ marginRight: '.5rem' }} />
        <Typography variant={'overline'}>Asset</Typography>
      </div>
    );
  };

  const handleTooltipType = (report: CombinedReports) => {
    if (report.idle !== 'n/a') {
      return 'Allocation';
    }
    return 'Asset';
  };

  const merged = [...allocationReports, ...assetReports];

  const tableReports = merged.map((a) => ({
    title: a.title,
    window: a.window,
    aggregateBy: [a.aggregateBy],
    idle: a.idle || 'n/a',
    filters: a.filters,
    accumulate: a.accumulate,
    chartDisplay: a.chartDisplay,
    rate: a.rate,
    sharedNamespaces: a.sharedNamespaces || undefined,
    sharedOverhead: a.sharedOverhead || undefined,
    sharedLabels: a.sharedLabels || undefined,
  }));

  return (
    <Paper style={{ width: '100%' }}>
      <TableContainer data-test={'reports-list'}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align={'left'}>Reports</TableCell>
              <TableCell>Type</TableCell>
              <TableCell align={'left'}>Window</TableCell>
              <TableCell align={'left'}>Aggregate By</TableCell>
              <TableCell align={'left'}>Idle</TableCell>
              <TableCell align={'left'}>Actions</TableCell>
            </TableRow>
          </TableHead>
          {tableReports
            .filter(
              (report) =>
                !searchTerm || report.title.toLowerCase().includes(searchTerm.toLowerCase()),
            )
            .map((report) => (
              <TableBody key={uuidv4()}>
                <TableRow>
                  <TableCell
                    align={'left'}
                    padding={'normal'}
                    style={{ paddingTop: '0rem', paddingBottom: '0rem' }}
                  >
                    <Tooltip title={`View Your ${handleTooltipType(report)} Report`}>
                      <Link component={'button'} onClick={() => handleGoToReports(report)}>
                        {report.title}
                      </Link>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{handleReportType(report)}</TableCell>
                  <TableCell
                    align={'left'}
                    padding={'normal'}
                    style={{ paddingTop: '0rem', paddingBottom: '0rem' }}
                  >
                    {toDisplayDateRange(report.window)}
                  </TableCell>
                  <TableCell
                    align={'left'}
                    padding={'normal'}
                    style={{ paddingTop: '0rem', paddingBottom: '0rem' }}
                  >
                    {report.aggregateBy.map(upperFirst).join(', ')}
                  </TableCell>
                  <TableCell
                    align={'left'}
                    padding={'normal'}
                    style={{ paddingTop: '0rem', paddingBottom: '0rem' }}
                  >
                    {report.idle !== 'n/a' ? <IdleListItem idle={report.idle} /> : 'N/A'}
                  </TableCell>
                  <TableCell
                    align={'left'}
                    padding={'normal'}
                    style={{ paddingTop: '0rem', paddingBottom: '0rem' }}
                  >
                    <div>
                      <ReportActionMenu
                        deleteClicked={() => handleDeleteReports(report)}
                        readonly={readOnly}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
        </Table>
        {!tableReports.length ? (
          <Alert severity={'warning'}>
            <Typography>
              You currently don&apos;t have any reports you can create one using the Create Report
              button above
            </Typography>
          </Alert>
        ) : null}
      </TableContainer>
    </Paper>
  );
};

ReportsTable.displayName = 'ReportsTable';
export default ReportsTable;
