import { FC } from 'react';

import reverse from 'lodash/reverse';

// recharts
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Typography } from '@kubecost-frontend/holster';

import { useClusters } from '../../../contexts/ClusterConfig';
import { accents, browns, greyscale } from '../../../services/colors';
import { toCurrency } from '../../../services/format';

function toBarLabels(assetRange) {
  const nameToFill = {};
  let p = 0;
  let b = 0;

  assetRange.forEach(({ other, top }) => {
    top.forEach((asset) => {
      if (nameToFill[asset.name] === undefined) {
        // assets get the next available color
        nameToFill[asset.name] = accents[p];
        p = (p + 1) % accents.length;
      }
    });
    other.forEach((asset) => {
      if (nameToFill[asset.name] === undefined) {
        // other assets are assigned brown
        nameToFill[asset.name] = browns[b];
        b = (b + 1) % browns.length;
      }
    });
  });

  let labels = Object.entries(nameToFill).map(([dataKey, fill]) => ({
    dataKey,
    fill,
  }));

  // Pin other category to bottom
  const other = labels.filter((l) => l.dataKey === 'other');
  if (other.length > 0) {
    labels = labels.filter((l) => l.dataKey !== 'other');
    labels.push(other[0]);
  }

  return reverse(labels);
}

function toBar(datum) {
  const { other, top } = datum;
  const bar = {};

  const dateFormatter = Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  });

  const timeFormatter = Intl.DateTimeFormat(navigator.language, {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC',
  });

  top.forEach((asset) => {
    if (!asset.window) {
      console.warn(`Asset ${asset.name} is missing a window.`);
      return;
    }

    const windowStart = new Date(asset.window.start);
    const windowEnd = new Date(asset.window.end);
    const windowHours = (windowEnd - windowStart) / 1000 / 60 / 60;

    bar.start = new Date(asset.start);
    bar.end = new Date(asset.end);
    if (windowHours >= 24) {
      bar.key = dateFormatter.format(bar.start);
    } else {
      bar.key = timeFormatter.format(bar.start);
    }

    bar[asset.name] = asset.totalCost;
  });

  other.forEach((asset) => {
    if (!asset.window) {
      console.warn(`Asset ${asset.name} is missing a window.`);
      return;
    }
    const windowStart = new Date(asset.window.start);
    const windowEnd = new Date(asset.window.end);
    const windowHours = (windowEnd - windowStart) / 1000 / 60 / 60;

    bar.start = new Date(asset.start);
    bar.end = new Date(asset.end);
    if (windowHours >= 24) {
      bar.key = dateFormatter.format(bar.start);
    } else {
      bar.key = timeFormatter.format(bar.start);
    }

    bar[asset.name] = asset.totalCost;
  });

  return bar;
}

interface RangeChartProps {
  currency: string;
  data: [];
  height: string | number;
}

export const RangeChart: FC<RangeChartProps> = ({ currency, data, height }) => {
  const { modelConfig } = useClusters();

  const barData = data.map(toBar);
  const barLabels = toBarLabels(data);

  const CustomTooltip = (params) => {
    const { active, payload } = params;

    if (!payload || payload.length === 0) {
      return null;
    }

    const dateTimeFormatter = Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

    const total = payload.reduce((sum, item) => sum + item.value, 0.0);

    let start = null;
    if (payload[0] && payload[0].payload) {
      start = payload[0].payload.start;
    }

    let end = null;
    if (payload[0] && payload[0].payload) {
      end = payload[0].payload.end;
    }

    if (active) {
      return (
        <div
          style={{
            borderRadius: 6,
            backgroundColor: '#FFFFFF',
            boxShadow:
              '0px 5px 15px rgba(0, 0, 0, 0.08), 0px 15px 35px rgba(49, 49, 93, 0.1), 0px 0px 0px 1px rgba(136, 152, 170, 0.1)',
          }}
        >
          {start && end && (
            <div
              style={{
                height: '3em',
                backgroundColor: greyscale[4],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1em',
              }}
            >
              <Typography variant={'h6'}>
                <p>{`${dateTimeFormatter.format(start)} - ${dateTimeFormatter.format(end)}`}</p>
              </Typography>
            </div>
          )}
          <div style={{ padding: '2em', paddingRight: '4em' }}>
            {reverse(payload).map((item, i) => (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '2em 1fr 2em',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '.25em',
                }}
              >
                <div
                  style={{
                    height: '1em',
                    width: '1em',
                    backgroundColor: item.fill,
                  }}
                />
                <div key={i} style={{ color: '#607971' }}>{`${item.name}`}</div>
                <div style={{ marginLeft: '.5em' }}>{`${toCurrency(item.value, currency)}`}</div>
              </div>
            ))}
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 2em',
                marginTop: '1em',
              }}
            >
              <div>
                <strong>Total</strong>
              </div>
              <div style={{ marginLeft: '.5em' }}>{toCurrency(total, currency)}</div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer height={height} width={'100%'}>
      <BarChart data={barData} margin={{ top: 30, right: 30, left: 30, bottom: 12 }}>
        <CartesianGrid strokeDasharray={'3 3'} />
        <XAxis dataKey={'key'} />
        <YAxis tickFormatter={(val) => toCurrency(val, modelConfig.currencyCode, 0, true)} />
        <Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: 'none' }} />
        {barLabels.map((barLabel, i) => (
          <Bar dataKey={barLabel.dataKey} fill={barLabel.fill} key={i} stackId={'a'} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

RangeChart.displayName = 'RangeChart';
