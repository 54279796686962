import { Dispatch, SetStateAction, useState } from 'react';

import { Chip, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import {
  AllocationFilter,
  DropdownControl,
  ReportConfig,
  allocationFilterTypes,
  dateRangeOptions,
  shareSplitOptions,
} from './reportingHelpers';

const BaseConfigurations = ({
  reportConfig,
  updateConfig,
}: {
  reportConfig: ReportConfig;
  updateConfig: Dispatch<SetStateAction<ReportConfig>>;
}) => {
  const [filterType, setFilterType] = useState(allocationFilterTypes[0].key);
  const [filterText, setFilterText] = useState('');

  const updateAggBy = (aggList: string[]) => {
    updateConfig({
      ...reportConfig,
      allocationAggregationBy: aggList.toString(),
    });
  };

  const addNewFilter = () => {
    if (filterText !== '') {
      updateConfig({
        ...reportConfig,
        filtersInclude: [
          ...reportConfig.filtersInclude,
          { property: filterType, value: filterText },
        ],
      });
    }
  };

  const deleteFilterItem = (item: AllocationFilter) => {
    const filteredItems = reportConfig.filtersInclude.filter(
      (existingItem: AllocationFilter) =>
        existingItem.property !== item.property && existingItem.value !== item.value,
    );
    updateConfig({
      ...reportConfig,
      filtersInclude: filteredItems,
    });
  };
  return (
    <Grid justifyContent={'start'} spacing={8} style={{ paddingTop: '4em' }} container>
      <Grid xs={3} item>
        <Typography component={'div'} variant={'h6'}>
          General Configurations
        </Typography>
        <DropdownControl
          currentValue={reportConfig.window}
          labelText={'Date Range'}
          placeholder={'Custom'}
          setCurrentValue={(value: string) => updateConfig({ ...reportConfig, window: value })}
          valueList={dateRangeOptions.map((value: string) => ({
            name: value,
            key: value,
          }))}
        />
        {/* <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled={true}
              disableToolbar
              fullWidth
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="Start Date"
              value={reportConfig.startDate}
              style={{ marginBottom: '1em' }}
              onChange={
                (date: MaterialUiPickersDate) =>
                  updateConfig({
                    ...reportConfig,
                    startDate: date || new Date(),
                  })
              }
            />
            <KeyboardDatePicker
              disabled={true}
              disableToolbar
              fullWidth
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="End Date"
              value={reportConfig.endDate}
              onChange={
                (date: MaterialUiPickersDate) =>
                  updateConfig({
                    ...reportConfig,
                    endDate: date || new Date(),
                  }) // TODO
              }
            />
          </MuiPickersUtilsProvider>
        </div> */}
      </Grid>
      <Grid xs={3} item>
        <Typography variant={'body1'}>Sharing (Allocations)</Typography>
        <TextField
          label={'Shared Overhead Cost'}
          onChange={(e: any) =>
            updateConfig({
              ...reportConfig,
              sharedOverheadCost: e.target.value,
            })
          }
          placeholder={'0'}
          style={{ marginTop: '1em' }}
          type={'number'}
          value={reportConfig.sharedOverheadCost}
          fullWidth
        />
        <TextField
          label={'Shared Namespaces'}
          onChange={(e: any) =>
            updateConfig({
              ...reportConfig,
              sharedNamespaces: e.target.value,
            })
          }
          placeholder={'kubecost,kube-system etc.'}
          style={{ marginTop: '2em' }}
          value={reportConfig.sharedNamespaces}
          fullWidth
        />
        <TextField
          label={'Shared Labels'}
          onChange={(e: any) => updateConfig({ ...reportConfig, sharedLabels: e.target.value })}
          placeholder={'app:kubecost,team:dev'}
          style={{ marginTop: '2em' }}
          value={reportConfig.sharedLabels}
          fullWidth
        />
        <div style={{ marginTop: '2em' }}>
          <DropdownControl
            currentValue={reportConfig.shareBy}
            labelText={'Share By'}
            placeholder={'Weighted By Cost, etc.'}
            setCurrentValue={(value: string) => updateConfig({ ...reportConfig, shareBy: value })}
            valueList={shareSplitOptions}
          />
        </div>
      </Grid>
      <Grid xs={3} item>
        <Typography variant={'body1'}>Filters (Allocation)</Typography>
        <DropdownControl
          currentValue={filterType}
          labelText={'Add Filters'}
          placeholder={'Filter by Cluster, etc.'}
          setCurrentValue={(value: string) => setFilterType(value)}
          valueList={allocationFilterTypes}
        />
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <IconButton aria-label={'toggle password visibility'} onClick={addNewFilter}>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          defaultValue={filterText}
          label={'Aggregation Name'}
          onChange={(e: any) => setFilterText(e.target.value)}
          placeholder={'app:kubecost'}
          fullWidth
        />
        <div>
          {reportConfig.filtersInclude.length !== 0 ? (
            reportConfig.filtersInclude.map((item: AllocationFilter) => (
              <div key={item.property} style={{ margin: '1em' }}>
                <Chip
                  color={'primary'}
                  deleteIcon={<CloseIcon />}
                  label={`${item.property}: ${item.value}`}
                  onDelete={() => deleteFilterItem(item)}
                  variant={'outlined'}
                  clickable
                />
              </div>
            ))
          ) : (
            <Typography
              color={'textSecondary'}
              style={{
                marginTop: '2em',
                marginLeft: '1em',
                textAlign: 'center',
              }}
              variant={'body1'}
            >
              No filters applied
            </Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export { BaseConfigurations };
