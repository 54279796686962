import { memo, useState } from 'react';

import { FormControlLabel, Select, Typography } from '@kubecost-frontend/holster';

import {
  Alert,
  AlertTypes,
  BudgetAlert,
  ClusterHealthAlert,
  DiagnosticAlert,
  EfficiencyAlert,
  RecurringUpdate,
  SpendChangeAlert,
} from '../../services/alerts';
import { If } from '../If';

import { BudgetAlertsTable } from './BudgetAlertsTable';
import { ClusterHealthAlertsTable } from './ClusterHealthAlertsTable';
import { DiagnosticAlertsTable } from './DiagnosticAlertsTable';
import { EfficiencyAlertsTable } from './EfficiencyAlertsTable';
import { RecurringAlertsTable } from './RecurringAlertsTable';
import { SpendChangeAlertsTable } from './SpendChangeAlertsTable';

interface AlertTypeOpt {
  explanation?: React.ReactElement[] | React.ReactElement | string;
  label: string;
  lockedFields?: {
    aggregate?: string;
    baseline?: string;
    effThresh?: number;
    spendThresh?: number;
    threshold?: number;
    window?: string;
  };
  value: AlertTypes;
}

interface AlertsTableProps {
  alertTypeOpts: AlertTypeOpt[];
  alerts: Alert[];
  createAlert: (arg0: Alert) => void;
  removeAlert: (arg0: Alert) => void;
}

const AlertsTable = memo(
  ({ alertTypeOpts, alerts, createAlert, removeAlert }: AlertsTableProps) => {
    const [alertType, setAlertType] = useState(alertTypeOpts[0]);

    function getAlertsTable() {
      switch (alertType.value) {
        case AlertTypes.Budget:
          return (
            <BudgetAlertsTable
              alerts={alerts.filter((a) => a.type === alertType.value) as BudgetAlert[]}
              createAlert={createAlert}
              locked={alertType.lockedFields}
              removeAlert={removeAlert}
            />
          );
        case AlertTypes.Health:
          return (
            <ClusterHealthAlertsTable
              alerts={alerts.filter((a) => a.type === alertType.value) as ClusterHealthAlert[]}
              createAlert={createAlert}
              locked={alertType.lockedFields}
              removeAlert={removeAlert}
            />
          );
        case AlertTypes.Diagnostic:
          return (
            <DiagnosticAlertsTable
              alerts={alerts.filter((a) => a.type === alertType.value) as DiagnosticAlert[]}
              createAlert={createAlert}
              locked={alertType.lockedFields}
              removeAlert={removeAlert}
            />
          );
        case AlertTypes.Efficiency:
          return (
            <EfficiencyAlertsTable
              alerts={alerts.filter((a) => a.type === alertType.value) as EfficiencyAlert[]}
              createAlert={createAlert}
              locked={alertType.lockedFields}
              removeAlert={removeAlert}
            />
          );
        case AlertTypes.Recurring:
          return (
            <RecurringAlertsTable
              alerts={alerts.filter((a) => a.type === alertType.value) as RecurringUpdate[]}
              createAlert={createAlert}
              locked={alertType.lockedFields}
              removeAlert={removeAlert}
            />
          );
        case AlertTypes.SpendChange:
          return (
            <SpendChangeAlertsTable
              alerts={alerts.filter((a) => a.type === alertType.value) as SpendChangeAlert[]}
              createAlert={createAlert}
              locked={alertType.lockedFields}
              removeAlert={removeAlert}
            />
          );
        default:
          return null;
      }
    }

    return (
      <>
        {/* select alert type to work with */}
        <section className={'my-4'}>
          <FormControlLabel>Alert Type</FormControlLabel>
          <Select
            data-test={'alerts-table-selector'}
            options={alertTypeOpts.map(({ label, value }) => ({
              value,
              label: `${label} (${alerts.filter((a) => a.type === value).length})`,
            }))}
            setValue={(e) => {
              const chosen = alertTypeOpts.find((ato) => ato.value === e);
              if (chosen) {
                setAlertType(chosen);
              }
            }}
            value={alertType.value}
          />
        </section>

        {/* blurb explaining the chosen alert type */}
        <If condition={!!alertType.explanation}>
          <Typography variant={'p'}>{alertType.explanation}</Typography>
        </If>

        {/* table showing alerts of the selected type */}
        {getAlertsTable()}
      </>
    );
  },
);

export { AlertsTable };
