import { FC } from 'react';

import { Tooltip, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Link from '@material-ui/core/Link';
import InfoIcon from '@material-ui/icons/Info';

export const NoCacheMessage: FC = () => {
  const noCacheMessage = 'No Caches currently available.';

  return (
    <Typography color={'textSecondary'} variant={'body2'}>
      {noCacheMessage}
      <Tooltip title={'To Learn more about savings diagnotics click here'}>
        <Link
          href={'https://guide.kubecost.com/hc/en-us/articles/4407595942679-Kubecost-Diagnostics'}
          target={'_blank'}
        >
          <InfoIcon style={{ color: grey[500], fontSize: '20px' }} />
        </Link>
      </Tooltip>
    </Typography>
  );
};

NoCacheMessage.displayName = 'NoCacheMessage';
