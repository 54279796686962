import { analytics } from './analytics';
import { getClusterNameId, getCurrentContainerAddressModel, parseResponseJSON } from './util';

interface NodePool {
  machineType: string;
  name: string;
  nodeCount: number;
}

interface RecNodePoolType {
  RAMGB: number;
  architecture: string;
  hourlyPrice: number;
  name: string;
  pricePerCPUCoreHr: number;
  pricePerRAMByteHr: number;
  provider: string;
  sharedCore: boolean;
  spotHourlyPrice: number;
  spotPricePerCPUCoreHr: number;
  spotPricePerRAMByteHr: number;
  vCPUs: number;
}

interface RecommendationNodePool {
  count: number;
  totalMonthlyCost: number;
  totalRAMGB: number;
  totalVCPUs: number;
  type: RecNodePoolType;
}

export interface ClusterSizingRecommendation {
  monthlySavings: number;
  nodeCount: number;
  pools: RecommendationNodePool[];
  requiredRAMGB: number;
  requiredVCPUs: number;
  strategy: string;
  totalMonthlyCost: number;
  totalRAMGB: number;
  totalVCPUs: number;
  utilizationRAMGB: number;
  utilizationVCPUs: number;
}

// TODO make this better
const baseURL = getCurrentContainerAddressModel().substr(
  0,
  getCurrentContainerAddressModel().length - 6,
);

const cluster = {
  async getStatus(): Promise<unknown> {
    return fetch(`${baseURL}/cluster/status`).then(parseResponseJSON);
  },

  resize: async (nodePools: NodePool[]): Promise<unknown> => {
    analytics.record('cluster_size_1_click');

    const url = `${baseURL}/cluster/update`;
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(nodePools),
    }).then(parseResponseJSON);
  },

  getMultiClusterStatus: (clusterInfo: {
    thanosEnabled: string;
    thanosOffset: string;
  }): { thanosEnabled: boolean; thanosOffset: string } => {
    let thanosEnabled = false;
    let thanosOffset = 'offset 3h';
    if (clusterInfo.thanosEnabled) {
      thanosEnabled = clusterInfo.thanosEnabled === 'true';
    } else {
      thanosEnabled = clusterInfo.thanosOffset !== undefined;
    }

    if (thanosEnabled) {
      if (clusterInfo.thanosOffset !== '') {
        thanosOffset = `offset ${clusterInfo.thanosOffset}`;
      }
    }

    return { thanosEnabled, thanosOffset };
  },

  clusterNameId: (entity: { clusterId?: string; clusterName?: string }): string =>
    getClusterNameId(entity),

  getClusterId: (nameId: string): string => {
    const result = nameId.split('/');
    if (result.length !== 2) {
      return '';
    }

    return result[1];
  },
};

export default cluster;
