import { FC, FocusEvent, useState } from 'react';

import sortBy from 'lodash/sortBy';

import {
  Button,
  Chip,
  FormControlLabel,
  Input,
  Menu,
  Modal,
  Select,
  Typography,
} from '@kubecost-frontend/holster';
import { MenuItemProps } from '@kubecost-frontend/holster/src/components/Menu';

import { FilterIcon, PlusIcon } from '../../assets/images';
import { QueryWindowSelector } from '../../components/QueryWindowSelector/QueryWindowSelector';
import { useQueryWindowParamState } from '../../components/QueryWindowSelector/useQueryWindowParamState';
import { useURLStore } from '../../hooks/useURLStore';

import { decoders, encoders, filterPropertyOptions, userInputDefaults } from './tokens';
import { AggregationType, Filter, Profile, RecommendationAlgorithm, SelectOptions } from './types';

const requestSizingWindowSelectorOptions = {
  today: 'Today',
  yesterday: 'Yesterday',
  week: 'Week to date',
  month: 'Month to date',
  lastweek: 'Last week',
  lastmonth: 'Last month',
  '24h': 'Last 24h',
  '48h': 'Last 48h',
  '7d': 'Last 7 days',
  '30d': 'Last 30 days',
  '60d': 'Last 60 days',
  '90d': 'Last 90 days',
};

type RequestSizingWindow = keyof typeof requestSizingWindowSelectorOptions;

interface ChipOption {
  equality: string;
  property: string;
  value: string;
}
type FilterEquality = ':' | '!:';

const DEFAULTS = {
  aggregation: 'cluster',
  labelValue: '',
  filterEquality: ':',
};

interface CustomizeRequestSizingMenuProps {
  onClose: () => void;
  open: boolean;
}

const CustomizeRequestSizingMenu: FC<CustomizeRequestSizingMenuProps> = ({ onClose, open }) => {
  const { setState: setUrlState, state: urlState } = useURLStore(
    encoders,
    decoders,
    userInputDefaults,
  );

  const [openMenu, setOpenMenu] = useState(false);
  const [newURLState, setNewURLState] = useState(urlState);
  const {
    algorithmCPU,
    algorithmRAM,
    filter,
    profile,
    qCPU,
    qRAM,
    targetCPUUtilization,
    targetRAMUtilization,
  } = newURLState;

  const [aggregation, setAggregation] = useState<AggregationType['value']>(DEFAULTS.aggregation);
  const [labelValue, setLabelValue] = useState(DEFAULTS.labelValue);
  const [chips, setChips] = useState<ChipOption[]>(filter || []);
  const [filterEquality, setFilterEquality] = useState<FilterEquality | string>(
    DEFAULTS.filterEquality,
  );

  const { queryWindowState: window } = useQueryWindowParamState<RequestSizingWindow>(
    requestSizingWindowSelectorOptions,
  );
  const isLabel = aggregation === 'label';
  const [labelError, setLabelError] = useState(false);

  const cpuAlgorithmOptions: SelectOptions[] = [
    {
      label: 'Max',
      value: RecommendationAlgorithm.Max,
    },
    {
      label: 'Percentile',
      value: RecommendationAlgorithm.Quantile,
    },
  ];

  const ramAlgorithmOptions: SelectOptions[] = [
    {
      label: 'Max',
      value: RecommendationAlgorithm.Max,
    },
    {
      label: 'Percentile',
      value: RecommendationAlgorithm.Quantile,
    },
  ];

  const filterEqualOptions: SelectOptions[] = [
    {
      label: 'Equals',
      value: ':',
    },
    {
      label: 'Not Equals',
      value: '!:',
    },
  ];

  const handleOnOpenMenu = () => setOpenMenu(true);
  const handleOnCloseMenu = () => setOpenMenu(false);
  const profileSelectOptions: SelectOptions[] = [
    {
      label: 'Development',
      value: 'development',
    },
    {
      label: 'Production',
      value: 'production',
    },
    {
      label: 'High Availability',
      value: 'highAvailability',
    },
    {
      label: 'Custom',
      value: 'custom',
    },
  ];

  const handleSelectProfile = (nextProfile: string) => {
    switch (nextProfile) {
      case Profile.Development:
        setNewURLState((prev) => ({
          ...prev,
          profile: nextProfile,
          targetRAMUtilization: 0.8,
          targetCPUUtilization: 0.8,
          algorithmCPU: RecommendationAlgorithm.Max,
          algorithmRAM: RecommendationAlgorithm.Max,
        }));
        break;
      case Profile.Production:
        setNewURLState((prev) => ({
          ...prev,
          profile: nextProfile,
          targetRAMUtilization: 0.65,
          targetCPUUtilization: 0.65,
          algorithmCPU: RecommendationAlgorithm.Max,
          algorithmRAM: RecommendationAlgorithm.Max,
        }));
        break;
      case Profile.HighAvailability:
        setNewURLState((prev) => ({
          ...prev,
          profile: nextProfile,
          targetRAMUtilization: 0.5,
          targetCPUUtilization: 0.5,
          algorithmCPU: RecommendationAlgorithm.Max,
          algorithmRAM: RecommendationAlgorithm.Max,
        }));
        break;
      default:
        setNewURLState((prev) => ({ ...prev, profile: Profile.Custom }));
    }
  };

  const handleOnSetRAMUtilization = (event: FocusEvent<HTMLInputElement, Element>) => {
    const ram = event.target.value;
    setNewURLState((prev) => ({ ...prev, targetRAMUtilization: Number(ram) }));
  };

  const handleOnSetCPUUtilization = (event: FocusEvent<HTMLInputElement, Element>) => {
    const cpu = event.target.value;
    setNewURLState((prev) => ({ ...prev, targetCPUUtilization: Number(cpu) }));
  };

  const handleOnSetRAMPercentile = (event: FocusEvent<HTMLInputElement, Element>) => {
    const ram = event.target.value;
    setNewURLState((prev) => ({ ...prev, qRAM: Number(ram) }));
  };

  const handleOnSetCPUPercentile = (event: FocusEvent<HTMLInputElement, Element>) => {
    const cpu = event.target.value;
    setNewURLState((prev) => ({ ...prev, qcp: cpu }));
  };

  const handleSetAlgorithmCPU = (algoCPU: string) => {
    setNewURLState((prev) => ({ ...prev, algorithmCPU: algoCPU as RecommendationAlgorithm }));
  };

  const handleSetAlgorithmRAM = (algoRAM: string) => {
    setNewURLState((prev) => ({ ...prev, algorithmRAM: algoRAM as RecommendationAlgorithm }));
  };

  const handleSetFilterProperty = (property: MenuItemProps) => {
    setAggregation(property.text);
    setOpenMenu(false);
  };

  const handleOnSetFilterLabelValue = (event: FocusEvent<HTMLInputElement, Element>) => {
    const label = event.target.value;
    setLabelValue(label);
  };

  const handleOnRemoveChip = (index: number) => {
    const newFilters = [
      ...newURLState.filter!.slice(0, index),
      ...newURLState.filter!.slice(index + 1),
    ];
    setChips((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
    setNewURLState((prev) => ({ ...prev, filter: newFilters }));
  };

  const handleOnAddFilterValue = () => {
    let newFltr: Filter = {
      property: aggregation,
      value: labelValue,
      equality: filterEquality,
    };
    if (isLabel) {
      // label filters should be formatted as label[labelkey]:labelvalue
      const [key, value] = labelValue.split(':');
      if (key && value) {
        newFltr = {
          property: `label[${key}]`,
          value: value,
          equality: filterEquality,
        };
      } else {
        setLabelError(true)
        setInterval(() => setLabelError(false), 1500)
        return; // if both label and value aren't present, alert user and don't set
      }
    }
    setChips((prev) => [newFltr, ...prev]);
    setNewURLState((prev) => ({ ...prev, filter: [...prev.filter!, newFltr] }));
  };

  const handleOnClose = () => {
    setAggregation(DEFAULTS.aggregation);
    setLabelValue(DEFAULTS.labelValue);
    setFilterEquality(DEFAULTS.filterEquality);
    onClose();
  };

  const handleOnSave = () => {
    setUrlState({
      ...newURLState,
      window,
    });
    handleOnClose();
  };

  const isDisabled = profile !== Profile.Custom;

  const cpuAlgoHelpText = {
    [RecommendationAlgorithm.Max]: (
      <Typography variant={'h6'}>
        Recommendations that keep utilization below the target at all times.
      </Typography>
    ),
    [RecommendationAlgorithm.Quantile]: (
      <Typography variant={'h6'}>
        Recommendations that keep utilization below the target {qCPU}% of the time. Requires the{' '}
        <a
          className={'text-kc-link'}
          href={'https://github.com/kubecost/docs/blob/main/api-request-right-sizing-v2.md'}
          target={'_blank'}
        >
          ContainerStats pipeline to be enabled
        </a>
        .
      </Typography>
    ),
  }[algorithmCPU];

  const ramAlgoHelpText = {
    [RecommendationAlgorithm.Max]: (
      <Typography variant={'h6'}>
        Recommendations that keep utilization below the target at all times.
      </Typography>
    ),
    [RecommendationAlgorithm.Quantile]: (
      <Typography variant={'h6'}>
        Recommendations that keep utilization below the target {qRAM}% of the time. Requires the{' '}
        <a
          className={'text-kc-link'}
          href={'https://github.com/kubecost/docs/blob/main/api-request-right-sizing-v2.md'}
          target={'_blank'}
        >
          ContainerStats pipeline to be enabled
        </a>
        .
      </Typography>
    ),
  }[algorithmRAM];

  const profileSelectHelpText = {
    [Profile.Development]: (
      <Typography variant={'h6'}>
        In development clusters, the aim is 80% resource utilization. Utilization is based on the
        maximum resource usage during the window.
      </Typography>
    ),
    [Profile.Production]: (
      <Typography variant={'h6'}>
        In production clusters, the aim is 65% resource utilization. Utilization is based on the
        maximum resource usage during the window.
      </Typography>
    ),
    [Profile.HighAvailability]: (
      <Typography variant={'h6'}>
        In high-availability clusters, the aim is 50% resource utilization. Utilization is based on
        the maximum resource usage during the window.
      </Typography>
    ),
    [Profile.Custom]: (
      <Typography variant={'h6'}>
        Using a custom profile you are able to set your own goals for resource utilization.
        Utilization is based on the maximum resource usage during the window.
      </Typography>
    ),
  }[profile];

  return (
    <Modal onClose={handleOnClose} open={open} title={'Customize Request Sizing Recommendations'}>
      <div className={'grid grid-cols-2 gap-1 p-4'}>
        <div>
          <FormControlLabel>Window</FormControlLabel>
          <QueryWindowSelector<RequestSizingWindow> options={requestSizingWindowSelectorOptions} />
        </div>

        <div>
          <FormControlLabel>Profile</FormControlLabel>
          <Select options={profileSelectOptions} setValue={handleSelectProfile} value={profile} />
          <Typography variant={'h6'}>{profileSelectHelpText}</Typography>
        </div>
      </div>

      <div className={'grid grid-cols-2 gap-4 p-4'}>
        <div>
          <FormControlLabel>CPU recommendation algorithm</FormControlLabel>
          <Select
            disabled={isDisabled}
            options={cpuAlgorithmOptions}
            setValue={handleSetAlgorithmCPU}
            value={algorithmCPU}
          />
          <Typography variant={'h6'}>{cpuAlgoHelpText}</Typography>
        </div>
        <div>
          <FormControlLabel>RAM recommendation algorithm</FormControlLabel>
          <Select
            disabled={isDisabled}
            options={ramAlgorithmOptions}
            setValue={handleSetAlgorithmRAM}
            value={algorithmRAM}
          />
          <Typography variant={'h6'}>{ramAlgoHelpText}</Typography>
        </div>
      </div>
      <div className={'grid grid-cols-2 gap-4 p-4'}>
        <div>
          <FormControlLabel>CPU target utilization</FormControlLabel>
          <Input
            className={'w-full p-4'}
            disabled={isDisabled}
            max={'1'}
            min={'0'}
            onChange={handleOnSetCPUUtilization}
            placeholder={'CPU Target Utilization'}
            step={'0.05'}
            type={'number'}
            value={targetCPUUtilization}
          />
          <Typography variant={'h6'}>
            Recommendations will aim to keep utilization below this percentage.
          </Typography>
          {algorithmCPU === 'quantile' && (
            <div className={'mt-5'}>
              <FormControlLabel>CPU Percentile</FormControlLabel>
              <Input
                className={'w-full p-4'}
                max={'1'}
                min={'0'}
                onChange={handleOnSetCPUPercentile}
                placeholder={'CPU Percentile'}
                step={'0.05'}
                type={'number'}
                value={qCPU}
              />
            </div>
          )}
        </div>
        <div>
          <FormControlLabel>RAM target utilization</FormControlLabel>
          <Input
            className={'w-full p-4'}
            disabled={isDisabled}
            max={'1'}
            min={'0'}
            onChange={handleOnSetRAMUtilization}
            placeholder={'RAM Target Utilization'}
            step={'0.05'}
            type={'number'}
            value={targetRAMUtilization}
          />
          <Typography variant={'h6'}>
            Recommendations will aim to keep utilization below this percentage.
          </Typography>
          {algorithmRAM === 'quantile' && (
            <div className={'mt-5'}>
              <FormControlLabel>RAM Percentile</FormControlLabel>
              <Input
                className={'w-full p-4'}
                onChange={handleOnSetRAMPercentile}
                placeholder={'RAM Percentile'}
                value={qRAM}
              />
            </div>
          )}
        </div>
      </div>
      <div className={'pl-4'}>
        <FormControlLabel>Add Filters</FormControlLabel>
      </div>
      <div className={'flex gap-4 p-4'}>
        <div>
          <Button onClick={handleOnOpenMenu} variant={'default'}>
            <div className={'flex justify-start'}>
              <div className={'pr-2 pt-1'}>
                <FilterIcon />
              </div>
              {aggregation || 'Filters'}
            </div>
          </Button>
          {openMenu && (
            <Menu
              items={sortBy(filterPropertyOptions, 'asc')}
              onClose={handleOnCloseMenu}
              selectItem={handleSetFilterProperty}
            />
          )}
        </div>
        <div>
          <Select
            options={filterEqualOptions}
            setValue={setFilterEquality}
            value={filterEquality}
          />
        </div>
        <div className={'flex-grow'}>
          <Input
            className={'h-8 w-full'}
            onChange={handleOnSetFilterLabelValue}
            placeholder={'Label'}
            value={labelValue}
            helperText={
              isLabel && (
                <span className={`${labelError && 'text-kc-danger'}`}>
                  format label as key:value ie, app:cost-analyzer
                </span>
              )
            }
          />
        </div>
        <div className={'justify-start'}>
          <Button className={'h-8'} onClick={handleOnAddFilterValue} variant={'default'}>
            <PlusIcon />
          </Button>
        </div>
      </div>

      {chips.length > 0 &&
        chips.map((chip, i) => (
          <ul className={'inline-block p-2'}>
            <li>
              <Chip
                color={'primary'}
                label={`${chip.property}${chip.equality}${chip.value}`}
                onDelete={() => handleOnRemoveChip(i)}
              />
            </li>
          </ul>
        ))}
      <div className={'grid grid-cols-[.2fr,.2fr] justify-end gap-2.5 px-4 pt-4 pb-0'}>
        <Button onClick={handleOnSave} variant={'primary'}>
          Save
        </Button>
        <Button onClick={handleOnClose} variant={'default'}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

CustomizeRequestSizingMenu.displayName = 'CustomizeRequestSizingMenu';

export { CustomizeRequestSizingMenu };
