import { useState } from 'react';

import { Button, MenuButton } from '@kubecost-frontend/holster';

import { ArrowIcon } from '../../../assets/images';
import { Header } from '../../../components/Header2New';

import { FilterItem } from './FilterItem';
import { InspectedResourceListItem } from './inspectState';

export const InspectFilterTile = ({
  activeTimeWindow,
  handleFilterUpdate,
  primaryAggregation,
  secondaryAggregations,
  setActiveTimeWindow,
}: InspectFilterTileProps) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const filterCount = secondaryAggregations.filter(
    ({ name }: InspectedResourceListItem) => name !== '',
  ).length;
  return (
    <div className={'mb-8'}>
      {primaryAggregation && (
        <Header
          refreshCallback={() => location.reload()}
          title={`${primaryAggregation.name} ${primaryAggregation.type}`}
        />
      )}
      <div className={'flex h-8 justify-end gap-4'}>
        <Button onClick={() => setShowFilters((prevState) => !prevState)} variant={'default'}>
          Filters ({filterCount})
        </Button>
        <MenuButton
          options={timeWindowOptions}
          setValue={setActiveTimeWindow}
          trailingIcon={<ArrowIcon direction={'LEFT'} />}
          value={activeTimeWindow}
        />
      </div>
      {showFilters && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '1em',
            marginBottom: '1em',
          }}
        >
          {secondaryAggregations.map(({ name, type }: InspectedResourceListItem) => (
            <FilterItem defaultValue={name} type={type} updateItem={handleFilterUpdate} />
          ))}
        </div>
      )}
    </div>
  );
};

const timeWindowOptions = ['12h', '24h', '7d', '30d'].map((time: string) => ({
  label: `Last ${time}`,
  value: time,
}));

export interface InspectFilterTileProps {
  activeTimeWindow: string;
  handleFilterUpdate: (type: string, value: string) => Promise<boolean>;
  primaryAggregation?: InspectedResourceListItem;
  secondaryAggregations: InspectedResourceListItem[];
  setActiveTimeWindow: (time: string) => void;
}
