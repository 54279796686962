import { makeStyles } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import reverse from 'lodash/reverse';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';

import { primary, greyscale, browns } from '../../../services/colors';
import { Allocation } from '../../../types/allocation';

interface GraphDatum {
  idle: Allocation[];
  other: Allocation[];
  top: Allocation[];
  totalEfficiency: number;
}

const useStyles = makeStyles({
  tooltip: {
    borderRadius: 2,
    background: 'rgba(255, 255, 255, 0.95)',
    padding: 12,
  },
  tooltipLineItem: {
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  },
});

function toLineLabels(allocationRange) {
  const keyToFill = {};
  let p = 1;
  let g = 0;
  let b = 0;

  allocationRange.forEach(({ idle, other, top }) => {
    idle.forEach((allocation) => {
      const key = allocation.name;
      if (keyToFill[key] === undefined) {
        // idle allocations are assigned grey
        keyToFill[key] = greyscale[g];
        g = (g + 1) % greyscale.length;
      }
    });

    top.forEach((allocation) => {
      const key = allocation.name;
      if (keyToFill[key] === undefined) {
        if (key === '__unallocated__') {
          // unallocated gets black (clean up)
          keyToFill[key] = '#212121';
        } else {
          // non-idle allocations get the next available color
          keyToFill[key] = primary[p];
          p = (p + 1) % primary.length;
        }
      }
    });

    other.forEach((allocation) => {
      const key = allocation.name;
      if (keyToFill[key] === undefined) {
        // other allocations are assigned brown
        keyToFill[key] = browns[b];
        b = (b + 1) % browns.length;
      }
    });
  });
  keyToFill.totalEfficiency = primary[0];

  let labels = Object.entries(keyToFill)
    .map(([dataKey, fill]) => ({
      dataKey,
      fill,
    }))
    .sort(({ dataKey: dk1 }, { dataKey: dk2 }) => {
      if (dk1 === '__idle__') {
        return -1;
      }
      if (dk2 === '__idle__') {
        return 1;
      }
      return dk1 > dk2 ? 1 : -1;
    });

  // Pin other category to bottom
  const other = labels.filter((l) => l.dataKey === 'other');
  if (other.length > 0) {
    labels = labels.filter((l) => l.dataKey !== 'other');
    labels.push(other[0]);
  }

  return reverse(labels);
}

const toLine = (datum: GraphDatum) => {
  const { idle, other, top, totalEfficiency } = datum;
  const line = {};

  const dateFormatter = Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  });

  const timeFormatter = Intl.DateTimeFormat(navigator.language, {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC',
  });

  top.forEach((allocation) => {
    if (!allocation.window) {
      console.warn(`Allocation ${allocation.name} is missing a window.`);
      return;
    }
    const windowStart = new Date(allocation.window.start);
    const windowEnd = new Date(allocation.window.end);
    const windowHours = (windowEnd - windowStart) / 1000 / 60 / 60;

    line.start = new Date(allocation.start);
    line.end = new Date(allocation.end);
    if (windowHours >= 24) {
      line.key = dateFormatter.format(line.start);
    } else {
      line.key = timeFormatter.format(line.start);
    }

    line[allocation.name] = allocation.totalEfficiency * 100;
  });

  other.forEach((allocation) => {
    if (!allocation.window) {
      console.warn(`Allocation ${allocation.name} is missing a window.`);
      return;
    }
    const windowStart = new Date(allocation.window.start);
    const windowEnd = new Date(allocation.window.end);
    const windowHours = (windowEnd - windowStart) / 1000 / 60 / 60;

    line.start = new Date(allocation.start);
    line.end = new Date(allocation.end);
    if (windowHours >= 24) {
      line.key = dateFormatter.format(line.start);
    } else {
      line.key = timeFormatter.format(line.start);
    }

    line[allocation.name] = allocation.totalEfficiency * 100;
  });

  idle.forEach((allocation) => {
    if (!allocation.window) {
      console.warn(`Allocation ${allocation.name} is missing a window.`);
      return;
    }

    const windowStart = new Date(allocation.window.start);
    const windowEnd = new Date(allocation.window.end);
    const windowHours = (windowEnd - windowStart) / 1000 / 60 / 60;

    line.start = new Date(allocation.start);
    line.end = new Date(allocation.end);
    if (windowHours >= 24) {
      line.key = dateFormatter.format(line.start);
    } else {
      line.key = timeFormatter.format(line.start);
    }

    line[allocation.name] = allocation.totalEfficiency * 100;
  });
  line.totalEfficiency = totalEfficiency * 100;

  return line;
};

const LineChart = ({ data, height }) => {
  const classes = useStyles();
  const lineData = data.map(toLine);
  const lineLabels = toLineLabels(data);

  const CustomTooltip = (params) => {
    const { active, payload } = params;

    if (!payload || payload.length === 0) {
      return null;
    }

    const dateTimeFormatter = Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

    let start = null;
    if (payload[0] && payload[0].payload) {
      start = payload[0].payload.start;
    }

    let end = null;
    if (payload[0] && payload[0].payload) {
      end = payload[0].payload.end;
    }

    // remove totalEfficiency, __idle__ and NaNs
    const filteredPayload = payload.filter(
      (item) =>
        item.dataKey !== 'totalEfficiency' && !isNaN(item.value) && item.name !== '__idle__',
    );

    const sortedPayload = orderBy(filteredPayload, ['value'], ['desc']);

    if (active) {
      return (
        <div className={classes.tooltip}>
          {start && end && (
            <p
              className={classes.tooltipLineItem}
              style={{ color: '#808080', fontSize: '0.75rem' }}
            >{`${dateTimeFormatter.format(start)} to ${dateTimeFormatter.format(end)}`}</p>
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '25px 1fr 60px',
              alignItems: 'center',
              gap: '.5em',
              margin: '.5em .25em .5em .25em',
              fontWeight: 600,
            }}
          >
            <div>
              <div style={{ backgroundColor: primary[0], width: 24, height: 24 }} />
            </div>
            <div className={classes.tooltipLineItem}>{`Total Efficiency: `}</div>
            <div style={{ textAlign: 'end' }}>{`${payload[0].payload.totalEfficiency.toFixed(
              2,
            )}%`}</div>
          </div>
          {sortedPayload.map((item) => (
            <div
              key={item.name}
              style={{
                display: 'grid',
                gridTemplateColumns: '25px 1fr 60px',
                alignItems: 'center',
                gap: '.5em',
                margin: '.25em',
                marginBottom: '.5em',
              }}
            >
              <div
                style={{
                  border: `${item.fill} 2px solid`,
                  width: 18,
                  height: 18,
                }}
              />
              <div className={classes.tooltipLineItem}>{`${item.name}: `}</div>
              <div style={{ textAlign: 'end' }}>{`${
                !item.value ? 'Inf' : item.value.toFixed(2)
              }%`}</div>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer data-testid={'efficiency-line-chart'} height={height} width={'100%'}>
      <AreaChart data={lineData} margin={{ top: 10, right: 30, left: 30, bottom: 0 }}>
        <XAxis dataKey={'name'} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} wrapperStyle={{ zIndex: 1000 }} />
        <CartesianGrid strokeDasharray={'5 5'} />
        {lineLabels.map((barLabel, i) => (
          <Area
            dataKey={barLabel.dataKey}
            fill={`${barLabel.fill}`}
            fillOpacity={barLabel.dataKey === 'totalEfficiency' ? 0.8 : 0.02}
            key={barLabel.dataKey}
            stroke={`${barLabel.fill}`}
            type={'monotone'}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
