import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useState } from 'react';

import { Alert, SpendChangeAlert } from '../../services/alerts';

const SpendChangeAlertsTable = ({
  alerts,
  createAlert,
  removeAlert,
  locked = {},
}: ComponentProps) => {
  const [newWindow, setNewWindow] = useState('');
  const [newAgg, setNewAgg] = useState('');
  const [newFilters, setNewFilters] = useState('');
  const [newBaseline, setNewBaseline] = useState('');
  const [newThresh, setNewThresh] = useState('');
  return (
    <TableContainer data-test={'alerts-table'}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Window</TableCell>
            <TableCell>Aggregation</TableCell>
            <TableCell>Filter</TableCell>
            <TableCell>Baseline window</TableCell>
            <TableCell>Relative threshold</TableCell>
            <TableCell>Add/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alerts.map((a) => (
            <TableRow key={a.id}>
              <TableCell>{a.window}</TableCell>
              <TableCell>{a.aggregation}</TableCell>
              <TableCell>{a.filter}</TableCell>
              <TableCell>{a.baselineWindow}</TableCell>
              <TableCell>{a.relativeThreshold}</TableCell>
              <TableCell>
                <IconButton data-test={'alert-delete'} onClick={() => removeAlert(a)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell data-test={'new-alert-window'}>
              {locked.window || (
                <TextField onChange={(e) => setNewWindow(e.target.value)} value={newWindow} />
              )}
            </TableCell>
            <TableCell data-test={'new-alert-aggregate'}>
              {locked.aggregate || (
                <TextField onChange={(e) => setNewAgg(e.target.value)} value={newAgg} />
              )}
            </TableCell>
            <TableCell data-test={'new-alert-filters'}>
              <TextField onChange={(e) => setNewFilters(e.target.value)} value={newFilters} />
            </TableCell>
            <TableCell data-test={'new-alert-baseline'}>
              {locked.baseline || (
                <TextField onChange={(e) => setNewBaseline(e.target.value)} value={newBaseline} />
              )}
            </TableCell>
            <TableCell data-test={'new-alert-threshold'}>
              {typeof locked.threshold === 'undefined' ? (
                <TextField onChange={(e) => setNewThresh(e.target.value)} value={newThresh} />
              ) : (
                locked.threshold
              )}
            </TableCell>
            <TableCell>
              <IconButton data-test={'new-alert-add'} onClick={handleAddAlert}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  function handleAddAlert() {
    const newAlert = new SpendChangeAlert({
      window: typeof locked.window !== 'undefined' ? locked.window : newWindow,
      aggregation: typeof locked.aggregate !== 'undefined' ? locked.aggregate : newAgg,
      filter: newFilters,
      baselineWindow: typeof locked.baseline !== 'undefined' ? locked.baseline : newBaseline,
      relativeThreshold:
        typeof locked.threshold !== 'undefined' ? locked.threshold : parseFloat(newThresh),
    });
    createAlert(newAlert);
    setNewWindow('');
    setNewAgg('');
    setNewFilters('');
    setNewBaseline('');
    setNewThresh('');
  }
};

SpendChangeAlertsTable.defaultProps = {
  locked: {},
};

interface ComponentProps {
  alerts: SpendChangeAlert[];
  createAlert: (alert: Alert) => void;
  locked?: {
    aggregate?: string;
    baseline?: string;
    threshold?: number;
    window?: string;
  };
  removeAlert: (alert: Alert) => void;
}

export default SpendChangeAlertsTable;
