import { FC, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import logger from '../../services/logger';
import { model } from '../../services/model';

import { AzureConfigForm } from './AzureConfigForm';
import { Transition } from './DialogTransition';
import { SnackbarTypes } from './types';

interface UpdateAzureConfigModalProps {
  closeModal: () => void;
  visible: boolean;
}

export const UpdateAzureConfigModal: FC<UpdateAzureConfigModalProps> = ({
  closeModal,
  visible,
}) => {
  const [snackbar, setSnackbar] = useState<SnackbarTypes>({});

  const handleUpdate = async (values: any) => {
    try {
      await model.updateAzureConfig(values);
      setSnackbar({
        message: 'Azure Config updates succesfully',
        severity: 'success',
      });
    } catch (err) {
      if (err instanceof Error) {
        setSnackbar({
          message: err.message,
          severity: 'error',
        });
      }
      logger.log(err);
    }
    closeModal();
  };

  return (
    <Dialog
      TransitionComponent={Transition as any}
      data-testid={'azure-form-modal'}
      onClose={closeModal}
      open={visible}
      keepMounted
    >
      <DialogActions>
        <Button color={'primary'} onClick={closeModal} type={'submit'}>
          X
        </Button>
      </DialogActions>
      <DialogTitle>Azure Billing Data Export Configuration</DialogTitle>
      <DialogContent>
        <Typography variant={'body2'}>
          To allocate out of cluster Azure costs (e.g. Azure blob storage), enable billing data
          export and tag assets appropriately.{' '}
          <a
            href={'https://guide.kubecost.com/hc/en-us/articles/4407595934871-Azure-Config'}
            target={'_blank'}
          >
            Learn More
          </a>
        </Typography>
        <Grid
          spacing={3}
          style={{
            marginTop: '.5em',
            display: 'flex',
          }}
          container
        >
          <Grid xs={6} item>
            <AzureConfigForm handleUpdateAzureConfig={handleUpdate} snackbar={snackbar} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
