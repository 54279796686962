import React, { FC, ReactNode, useEffect } from 'react';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Button, Card, Typography } from '@kubecost-frontend/holster';

import { ArrowIcon } from '../../assets/images';
import { BetaBadge } from '../../components';
import { useClusters } from '../../contexts/ClusterConfig';
import { toCurrency } from '../../services/format';

interface SavingsCardProps {
  loadingComplete: (value: number) => void;
  savingsItem: SavingsItem;
}

export { SavingsCard };

const SavingsCard: FC<SavingsCardProps> = ({
  loadingComplete,
  savingsItem: { dataLoader, description, icon, isBeta, isBetaMessage, savingsType, title, url },
}) => {
  const navigate = useNavigate();
  const { modelConfig: config } = useClusters();
  const currency = config.currencyCode;

  const { data: savingsValue, isLoading } = useQuery(['savings', title], {
    queryFn: dataLoader,
    staleTime: 10 * 1000 * 60, // 10 Minutes
    cacheTime: 24 * 60 * 1000 * 60, // 1 day
  });

  const logoVerbiage =
    savingsType === 'kube' ? 'Kubernetes Insight' : 'Cloud insight outside clusters';

  const hasSavings = savingsValue && savingsValue > 0;
  const footerText = hasSavings ? 'Save up to' : 'Explore savings';

  useEffect(() => {
    if (savingsValue) {
      loadingComplete(savingsValue);
    }
  }, [savingsValue]);

  return (
    <Card
      onClick={() => navigate(url)}
      style={{
        border: '1px solid #E6EBE9',
        borderRadius: '0px',
        transition: 'box-shadow .1s',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateRows: '1fr 70px',
          height: '200px',
        }}
      >
        <div style={{ padding: '1em', color: '#023927' }}>
          <Typography
            style={{
              color: '#023927',
              fontWeight: 'bolder',
              paddingBottom: '1em',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            variant={'p'}
          >
            {title}
            {isBeta && <BetaBadge message={isBetaMessage} />}
          </Typography>
          <Typography style={{ color: '#607971', fontSize: '14px' }} variant={'p-small'}>
            {description}
          </Typography>
        </div>

        <div className={'flex items-center gap-4 bg-kc-white-100 px-4'}>
          {icon}
          <Typography className={'flex-grow'} variant={'p-small'}>
            {logoVerbiage}
          </Typography>

          {isLoading && <Typography variant={'p-small'}>Loading...</Typography>}
          <div>
            {hasSavings ? (
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignContent: 'start',
                  paddingRight: '1em',
                }}
                variant={'p-small'}
              >
                {footerText}
              </Typography>
            ) : (
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: '1em',
                }}
                variant={'p-small'}
              >
                {footerText}
              </Typography>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignContent: 'start',
              }}
            >
              {hasSavings ? (
                <>
                  <Typography style={{ color: '#28B359' }} variant={'h5'}>
                    {toCurrency(savingsValue, currency)} /mo
                  </Typography>
                  <Button
                    onClick={() => navigate(url)}
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                      padding: 0,
                      color: '#28B359',
                    }}
                    variant={'primary'}
                  >
                    <ArrowIcon direction={'RIGHT'} />
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

SavingsCard.displayName = 'SavingsCard';
export interface SavingsItem {
  dataLoader: () => Promise<number>;
  description: ReactNode;
  icon: any;
  isBeta?: boolean;
  isBetaMessage?: string;
  savingsType: string;
  title: string;
  url: string;
}
