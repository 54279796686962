import { ArrowIcon } from '../../../assets/images';

interface InspectLinkProps {
  href: string;
  text: string;
}

// TODO: Fix the direction of the ArrowIcon Component
export const InspectLink = ({ href, text }: InspectLinkProps) => (
  <a className={'flex text-kc-primary'} href={href} title={text}>
    {text} <ArrowIcon direction={'DOWN'} />
  </a>
);
