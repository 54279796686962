import { FC, FocusEvent, useState } from 'react';

import sortBy from 'lodash/sortBy';

import { Button, Input, Menu } from '@kubecost-frontend/holster';
import { MenuItemProps } from '@kubecost-frontend/holster/dist/components/Menu';

import { FilterIcon, PlusIcon } from '../../assets/images';

interface AddFilterProps {
  handleAddFilter: (f: { property: string; value: string }) => void;
  propertyOptions: string[];
}

export const AddFilter: FC<AddFilterProps> = ({ handleAddFilter, propertyOptions }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [property, setProperty] = useState('');
  const [value, setValue] = useState('');

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleSetFilterProperty = (prop: MenuItemProps) => {
    setProperty(prop.text);
    setOpenMenu(false);
  };

  const handleSetFilterValue = (event: FocusEvent<HTMLInputElement, Element>) => {
    const val = event.target.value;
    setValue(val);
  };

  const handleAddNewFilter = () => {
    handleAddFilter({ property, value });
    setOpenMenu(false);
    setValue('');
  };

  return (
    <div
      className={'py-4'}
      style={{
        display: 'grid',
        gridTemplateColumns: '.3fr 1fr .1fr',
        gap: '10px',
      }}
    >
      <Button onClick={handleOpenMenu} variant={'default'}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div style={{ paddingRight: '.5em', paddingTop: '.2em' }}>
            <FilterIcon />
          </div>
          {property || 'Filters'}
        </div>
      </Button>
      {openMenu ? (
        <Menu
          items={sortBy(propertyOptions, 'asc')}
          onClose={() => setOpenMenu(false)}
          selectItem={handleSetFilterProperty}
        />
      ) : null}
      <div>
        <Input
          onChange={handleSetFilterValue}
          onKeyDown={(press) => {
            if (press.key === 'Enter') {
              handleAddNewFilter();
            }
          }}
          style={{ width: '100%', paddingLeft: '1em' }}
          value={value}
        />
      </div>

      <div style={{ display: 'flex', gap: '5px' }}>
        <Button onClick={handleAddNewFilter} variant={'default'}>
          <PlusIcon />
        </Button>
      </div>
    </div>
  );
};
