import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';

import { WithDataLoader } from '../../components/DataLoader';
import { model as Model } from '../../services/model';
import { AllocationSet } from '../../types/allocation';

const useStyles = makeStyles({
  actionButton: {
    color: '#2196f3',
  },
  actionLink: {
    marginLeft: 'auto',
  },
  centered: {
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  root: {
    height: '100%',
  },
  savings: {
    fontWeight: 400,
  },
});

const BoxWithLoading = WithDataLoader(Box);

const SavingsCard = () => {
  const classes = useStyles();

  const [promise, setPromise] = useState<Promise<unknown> | null>(null);
  const [efficiency, setEfficiency] = useState(0);

  useEffect(() => {
    setPromise(fetchData());
  }, []);
  return (
    <Card classes={{ root: classes.root }}>
      <BoxWithLoading
        className={classes.centered}
        errorMessage={'Could not load savings data'}
        loadMessage={'Loading savings data'}
        promise={promise}
      >
        <CardContent className={classes.content}>
          <Tooltip
            title={'Percentage of cluster resources, weighted by cost, used over the last 7 days.'}
          >
            <div>
              <Typography
                className={classes.savings}
                style={{ color: getColor() }}
                variant={'h2'}
                paragraph
              >
                {(efficiency * 100).toFixed(1)}%
              </Typography>
              <Typography variant={'h5'}>Cost efficiency</Typography>
            </div>
          </Tooltip>
        </CardContent>
        <CardActions>
          <Link
            className={classes.actionLink}
            component={RouteLink}
            to={'/allocations?window=7d&agg=cluster'}
          >
            <Button className={classes.actionButton} endIcon={<KeyboardArrowRightIcon />}>
              Cost Efficiency
            </Button>
          </Link>
        </CardActions>
      </BoxWithLoading>
    </Card>
  );

  async function fetchData() {
    const config = await Model.getConfigs();
    const allocationResponse = await Model.getAllocationSummary('7d', 'cluster', {
      accumulate: true,
      external: 'false',
      shareIdle: false,
      shareTenancyCosts: config.shareTenancyCosts === 'true',
    });
    const allocationSet: AllocationSet = allocationResponse.data.sets[0].allocations;

    // Compute the usedCost and totalCost for a cost-weighted average efficiency.
    // Ultimate efficiency computation is (usedCost / totalCost) where:
    const costs = Object.values(allocationSet).reduce(
      (accumulator, allocation) => {
        const total = Model.getSummaryTotalCost(allocation);
        const eff = Model.getSummaryTotalEfficiency(allocation);
        const used = eff * total;
        return {
          used: used + accumulator.used,
          total: total + accumulator.total,
        };
      },
      { used: 0, total: 0 },
    );

    setEfficiency(costs.used / costs.total);

    let cpuCost = 0;
    let cpuReq = 0;
    let cpuUse = 0;
    let ramCost = 0;
    let ramReq = 0;
    let ramUse = 0;
    Object.entries(allocationSet).forEach(([key, alloc]) => {
      // don't calculate idle efficiency like a normal allocation
      // this is because idle's `request` and `usage` of 0 give it, technically,
      // an `undefined` efficiency which can affect the cumulative result in odd ways.
      // we want to treat the idle allocation's efficiency as 0, and weight it's contribution
      // by the total size of the idle cost.
      if (key === '__idle__') {
        return;
      }
      const hrs = Model.getSummaryMinutes(alloc) / 60;
      cpuCost += alloc.cpuCost;
      cpuReq += alloc.cpuCoreRequestAverage * hrs;
      cpuUse += alloc.cpuCoreUsageAverage * hrs;
      ramCost += alloc.ramCost;
      ramReq += alloc.ramByteRequestAverage * hrs;
      ramUse += alloc.ramByteUsageAverage * hrs;
    });
    // Compute efficiency
    let cpuEff = 0;
    if (cpuReq > 0) {
      cpuEff = cpuUse / cpuReq;
    } else if (cpuUse > 0) {
      cpuEff = 1.0;
    }

    let ramEff = 0;
    if (ramReq > 0) {
      ramEff = ramUse / ramReq;
    } else if (ramUse > 0) {
      ramEff = 1.0;
    }

    let totalEff = 0;
    if (cpuCost + ramCost > 0) {
      totalEff = (cpuCost * cpuEff + ramCost * ramEff) / (cpuCost + ramCost);
    }

    // account for idle's contibution to inefficiency
    if (allocationSet.__idle__) {
      const cpuRamCost = cpuCost + ramCost;
      const idle = allocationSet.__idle__;
      totalEff = (totalEff * cpuRamCost) / (cpuRamCost + idle.cpuCost + idle.ramCost);
    }
    setEfficiency(totalEff);
  }

  function getColor() {
    if (efficiency >= 99.0) {
      return '#e86478';
    }
    if (efficiency >= 60.0) {
      return '#0F9D58';
    }
    if (efficiency >= 30) {
      return '#e8d064';
    }
    return '#e86478';
  }
};

export default SavingsCard;
