import { FC } from 'react';

import CloudIcon from '@material-ui/icons/Cloud';

import { K8sLogo } from '../../components/k8sLogo';

export const ColoredCloudIcon: FC = () => (
  <CloudIcon htmlColor={'#607971'} style={{ fontSize: 40 }} />
);

export const ColoredKubernetesIcon: FC = () => K8sLogo(50, '#607971');
