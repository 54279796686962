// react
import React, { useState, useEffect } from 'react';

// holster
import {
  Alert as HolsterAlert,
  Button,
  Typography,
  Input,
  Modal,
} from '@kubecost-frontend/holster';

import { APIClient } from '../../../services/APIClient';
import { useHosted } from '../../../hooks/useHosted';
import { useMutation, useQueryClient } from 'react-query';
import { hostedTeamsQueryKey } from '../hooks.ts/api';

interface InviteTeamMemberProps {
  teamId: string;
}

export const InviteTeamMember = ({ teamId }: InviteTeamMemberProps) => {
  const { config } = useHosted();
  const queryClient = useQueryClient();
  const [emailValue, setEmailValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => {
      return APIClient.post(`${config!.userServiceAPIUrl}/api/teams/${teamId}/invite`, payload);
    },
    onError() {
      setSuccessMessage('');
      setErrorMessage('Uh oh. Looks like something went wrong.');
    },
    onSuccess() {
      setEmailValue('');
      setErrorMessage('');
      setSuccessMessage("We've invited your teammate! Tell them to check their email.");
      queryClient.invalidateQueries({ queryKey: hostedTeamsQueryKey(teamId) });
    },
  });

  const handleInvite = async (e: any) => {
    e.preventDefault();
    if (emailValue === '') {
      setErrorMessage('E-Mail is required.');
      return;
    }

    const payload = {
      email: emailValue,
      role: 'admin',
    };
    console.log('Invite payload: ' + JSON.stringify(payload));
    mutate(payload);
  };

  const close = (e: any) => {
    e.preventDefault();
  };

  return (
    <div className='mt-8'>
      <form onSubmit={handleInvite}>
        <div className='mb-4'>
          {errorMessage && (
            <HolsterAlert
              variant='danger'
              title='Whoops.'
              content={errorMessage}
              style={{ marginBottom: 20 }}
            />
          )}
          {successMessage && (
            <HolsterAlert
              variant='success'
              title='Success'
              content={successMessage}
              style={{ marginBottom: 20 }}
            />
          )}
        </div>
        <Typography variant='p' className='mt-8'>
          Invite Member
        </Typography>
        <div className='mt-4 flex gap-4'>
          <Input
            disabled={isLoading}
            data-test='emailRecipientInput'
            placeholder='user@example.com'
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
            style={{ width: '100%' }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleInvite(event);
              }
            }}
          />
          <Button type='submit' disabled={isLoading} variant='primary' color='primary'>
            Invite
          </Button>
        </div>
      </form>
    </div>
  );
};
