import { Dispatch, SetStateAction, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { DataSourceGroupingChip } from './DataSourceGroupingChip';
import { AssetLabelOverride, ReportConfig } from './reportingHelpers';

export const DataSourceGroupingTable = ({
  labelMap,
  reportConfig,
  updateConfig,
}: {
  labelMap: any;
  reportConfig: ReportConfig;
  updateConfig: Dispatch<SetStateAction<ReportConfig>>;
}) => {
  const [canSave, setCanSave] = useState(false);
  const [currentDataSourceOverrides, setCurrentDataSourceOverrides] = useState<
    AssetLabelOverride[]
  >(reportConfig.assetLabelOverrides);

  const saveOverrides = () => {
    updateConfig({
      ...reportConfig,
      assetLabelOverrides: currentDataSourceOverrides,
    });
  };

  const handleRowUpdate = (value: string, allocationAggType: string) => {
    if (!canSave) {
      setCanSave(true);
    }
    const currentValueIndex = currentDataSourceOverrides.findIndex(
      (item: AssetLabelOverride) => item.allocationAggregation === allocationAggType,
    );
    if (currentValueIndex !== -1) {
      currentDataSourceOverrides[currentValueIndex].assetLabelOverride = value;
      setCurrentDataSourceOverrides([...currentDataSourceOverrides]);
    } else {
      setCurrentDataSourceOverrides([
        ...currentDataSourceOverrides,
        { allocationAggregation: allocationAggType, assetLabelOverride: value },
      ]);
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell key={'Allocation Agg'}>Allocation Agg</TableCell>
            <TableCell key={'Asset Label'}>Asset Label</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportConfig.allocationAggregationBy.split(',').map((item: string) => (
            <TableRow key={item}>
              <TableCell key={item}>{item}</TableCell>
              <TableCell key={'Asset Label'}>
                <DataSourceGroupingChip
                  handleRowUpdate={handleRowUpdate}
                  item={item}
                  labelMap={labelMap}
                  reportConfig={reportConfig}
                  saveOverride={saveOverrides}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
