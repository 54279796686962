import { useEffect, useRef } from 'react';

import trim from 'lodash/trim';

import { useClusters } from '../../../contexts/ClusterConfig';

type defaultConfigType = {
  shareLabelNames: string[] | null;
  shareNamespaces: string[] | null;
  shareTenancyCosts: boolean;
  sharedOverhead: number;
};

const defaultConfigState: defaultConfigType = {
  shareTenancyCosts: true,
  shareNamespaces: null,
  shareLabelNames: null,
  sharedOverhead: 0.0,
};

const useAllocationConfig = (): defaultConfigType => {
  const { modelConfig: config } = useClusters();
  const defaultConfig = useRef<defaultConfigType>(defaultConfigState);

  const CSVtoArray = (stringValue: string) =>
    stringValue
      .split(',')
      .map((value) => trim(value))
      .filter((value) => value !== '');

  useEffect(() => {
    defaultConfig.current.shareTenancyCosts = config.shareTenancyCosts !== 'false';

    defaultConfig.current.shareNamespaces =
      typeof config.sharedNamespaces === 'string' ? CSVtoArray(config.sharedNamespaces) : null;

    const slns =
      typeof config.sharedLabelNames === 'string' ? CSVtoArray(config.sharedLabelNames) : null;
    const slvs =
      typeof config.sharedLabelValues === 'string' ? CSVtoArray(config.sharedLabelValues) : null;

    if (slns && slvs && slns.length === slvs.length && slns.length > 0) {
      defaultConfig.current.shareLabelNames = [];
      for (let i = 0; i < slns.length; i++) {
        defaultConfig.current.shareLabelNames.push(`${slns[i]}:${slvs[i]}`);
      }
    }

    defaultConfig.current.sharedOverhead = parseFloat(config.sharedOverhead ?? 0);
  }, []);

  return defaultConfig.current;
};

export default useAllocationConfig;
