import { model } from '../../services/model';

interface OrphanedResourcesResponse {
  description: Map<string, string>;
  diskName: string;
  diskSizeInGB: number;
  ipAddress: string;
  monthlyCost: number;
  region: string;
  resourceKind: string;
}

async function fetchOrphanedResources(): Promise<OrphanedResourcesResponse[]> {
  const response = await model.get<{ data: OrphanedResourcesResponse[] }>(
    '/savings/orphanedResources',
  );

  return response.data;
}

export { OrphanedResourcesResponse, fetchOrphanedResources };
