import { Suspense } from 'react';

import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { ErrorFallback } from './components/Errors/ErrorFallback';
import { ClusterContextProvider } from './contexts/ClusterConfig';
import { LocalStorageHydrationManager } from './HydrationManager';
import { KubecostRoutes } from './routes';
import { AnalyticsHOC } from './services/analytics';
import { ErrorReportingHOC } from './services/error_reporting';
import { themeConfig } from './themes/themeConfig';
import './css/tailwind.css';
import './css/body.css';
import './vendor/css/health.css';

const theme = createTheme(themeConfig);

const App = () => {
  const basename = location.pathname.split('/').slice(0, -1).join('/');
  return (
    <Suspense fallback={<ErrorFallback />}>
      <ThemeProvider theme={theme}>
        <LocalStorageHydrationManager>
          <ErrorReportingHOC>
            <AnalyticsHOC>
              <ClusterContextProvider>
                <BrowserRouter basename={basename}>
                  <KubecostRoutes />
                  <ReactQueryDevtools initialIsOpen={false} />
                </BrowserRouter>
              </ClusterContextProvider>
            </AnalyticsHOC>
          </ErrorReportingHOC>
        </LocalStorageHydrationManager>
      </ThemeProvider>
    </Suspense>
  );
};

export { App };
