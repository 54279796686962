import { IconProps } from './types';

const CheckCircleOutlineIcon = ({ ...args }: IconProps) => (
  <svg
    height={'1em'}
    preserveAspectRatio={'xMidYMid meet'}
    viewBox={'0 0 24 24'}
    width={'1em'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...args}
  >
    <g fill={'none'} stroke={'currentColor'} strokeWidth={'2'}>
      <path d={'m8 12.5l3 3l5-6'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      <circle cx={'12'} cy={'12'} r={'10'} />
    </g>
  </svg>
);

export { CheckCircleOutlineIcon };
