import { Button, Card, CardActions, CardContent, Link, Typography } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { primary } from '../../services/colors';
import { toCurrency } from '../../services/format';

interface SavingsCardProps {
  currency: string;
  onClickHref?: string;
  title: string;
  value: number;
}

export const SavingsCard = ({ currency, onClickHref, title, value }: SavingsCardProps) => (
  <Card style={{ height: '100%' }}>
    <CardContent style={{ paddingTop: '4em' }}>
      <div>
        <Typography component={'div'} variant={'h6'}>
          {title}
        </Typography>
        <Typography
          component={'div'}
          style={{ color: primary[0], paddingTop: '.5em' }}
          variant={'h4'}
        >
          <strong>{toCurrency(value, currency)}</strong>
        </Typography>
      </div>
    </CardContent>
    {onClickHref && (
      <CardActions>
        <Link href={onClickHref} style={{ marginLeft: 'auto' }}>
          <Button endIcon={<KeyboardArrowRightIcon />} style={{ color: '#2196f3' }}>
            request sizing
          </Button>
        </Link>
      </CardActions>
    )}
  </Card>
);
