import { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

import { Alert, AlertTypes, EfficiencyAlert } from '../../services/alerts';

interface EfficiencyAlertsTableProps {
  alerts: EfficiencyAlert[];
  createAlert: (alert: Alert) => void;
  locked?: {
    aggregate?: string;
    effThresh?: number;
    spendThresh?: number;
    window?: string;
  };
  removeAlert: (alert: Alert) => void;
}

const EfficiencyAlertsTable = ({
  alerts,
  createAlert,
  removeAlert,
  locked = {},
}: EfficiencyAlertsTableProps) => {
  const [newWindow, setNewWindow] = useState('');
  const [newAgg, setNewAgg] = useState('');
  const [newFilters, setNewFilters] = useState('');
  const [newEff, setNewEff] = useState('');
  const [newSpend, setNewSpend] = useState('');

  const handleOnClick = () => {
    const newAlert = new EfficiencyAlert({
      type: AlertTypes.Efficiency,
      window: newWindow || locked.window,
      aggregation: newAgg || locked.aggregate,
      filter: newFilters,
      efficiencyThreshold:
        typeof locked.effThresh !== 'undefined' ? locked.effThresh : parseFloat(newEff),
      spendThreshold:
        typeof locked.spendThresh !== 'undefined' ? locked.spendThresh : parseFloat(newSpend),
    });
    createAlert(newAlert);
    setNewWindow('');
    setNewAgg('');
    setNewFilters('');
    setNewEff('');
    setNewSpend('');
  };

  return (
    <Table className={'w-full'} data-test={'alerts-table'}>
      <TableHead>
        <TableRow>
          <TableHeadCell>Window</TableHeadCell>
          <TableHeadCell>Aggregation</TableHeadCell>
          <TableHeadCell>Filter</TableHeadCell>
          <TableHeadCell>Efficiency threshold</TableHeadCell>
          <TableHeadCell>Spend threshold</TableHeadCell>
          <TableHeadCell>Add/Delete</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {alerts.map((a) => (
          <TableRow key={a.id}>
            <TableCell>{a.window}</TableCell>
            <TableCell>{a.aggregation}</TableCell>
            <TableCell>{a.filter}</TableCell>
            <TableCell>{a.efficiencyThreshold}</TableCell>
            <TableCell>{a.spendThreshold || 'None'}</TableCell>
            <TableCell>
              <IconButton data-test={'alert-delete'} onClick={() => removeAlert(a)}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell data-test={'new-alert-window'}>
            {locked.window || (
              <Input onChange={(e) => setNewWindow(e.target.value)} value={newWindow} />
            )}
          </TableCell>
          <TableCell data-test={'new-alert-aggregate'}>
            {locked.aggregate || (
              <Input onChange={(e) => setNewAgg(e.target.value)} value={newAgg} />
            )}
          </TableCell>
          <TableCell data-test={'new-alert-filters'}>
            <Input onChange={(e) => setNewFilters(e.target.value)} value={newFilters} />
          </TableCell>
          <TableCell data-test={'new-alert-efficiency'}>
            {locked.effThresh || (
              <Input onChange={(e) => setNewEff(e.target.value)} value={newEff} />
            )}
          </TableCell>
          <TableCell data-test={'new-alert-spend'}>
            {locked.spendThresh || (
              <Input onChange={(e) => setNewSpend(e.target.value)} value={newSpend} />
            )}
          </TableCell>
          <TableCell>
            <IconButton data-test={'new-alert-add'} onClick={handleOnClick}>
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export { EfficiencyAlertsTable };
