import { FC } from 'react';

import round from 'lodash/round';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

import { toCurrency } from '../../services/format';
import { NodeAsset } from '../../types/asset';

interface NodeAssetDetailsProps {
  currency: string;
  hours: number;
  nodeAsset: NodeAsset;
}

export const NodeAssetDetails: FC<NodeAssetDetailsProps> = ({ currency, hours, nodeAsset }) => {
  const { cpuCores, cpuCost, gpuCost, gpuCount, ramBytes, ramCost } = nodeAsset;
  let { discount } = nodeAsset;

  discount = 1 - discount;

  let costPerCpuPerHour = 0;
  let costPerRamGBHour = 0;
  let costPerGPUHour = 0;
  const ramGB = ramBytes / 1024 / 1024 / 1024;
  if (hours !== 0) {
    // if hours or cpuCores is 0, then either we're getting bad data, or the cost is also 0
    // for now, just show 0 (not sure what to show in event that data is just wrong).
    if (cpuCores !== 0) {
      costPerCpuPerHour = (cpuCost / (cpuCores * hours)) * discount;
    } else if (cpuCost) {
      console.warn('cpuCost is nonzero, but core count is 0');
    }

    // if hours or ramGB is 0, then either we're getting bad data, or the cost is also 0
    // for now, just show 0 (not sure what to show in event that data is just wrong).
    if (ramGB !== 0) {
      costPerRamGBHour = (ramCost / (ramGB * hours)) * discount;
    } else if (ramCost) {
      console.warn('ramCost is nonzero, but ramBytes is 0');
    }

    if (gpuCount !== 0) {
      costPerGPUHour = gpuCost / (gpuCount * hours);
    } else if (ramCost) {
      console.warn('gpuCost is nonzero, but gpuCount is 0');
    }
  }
  return (
    <>
      <Typography variant={'h5'}>Node Breakdown</Typography>

      <Table className={'w-[100%]'}>
        <TableHead>
          <TableRow>
            <TableHeadCell align={'left'} scope={'row'}>
              Resource
            </TableHeadCell>
            <TableHeadCell align={'right'} scope={'row'}>
              Amount
            </TableHeadCell>
            <TableHeadCell align={'right'} scope={'row'}>
              Hourly Rate
            </TableHeadCell>
            <TableHeadCell align={'right'} scope={'row'}>
              Cost
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align={'left'} scope={'row'} width={200}>
              CPU
            </TableCell>
            <TableCell align={'right'} scope={'row'}>
              {cpuCores} Cores
            </TableCell>
            <TableCell align={'right'} scope={'row'}>
              {toCurrency(costPerCpuPerHour, currency, 3)}/CoreHr
            </TableCell>
            <TableCell align={'right'} scope={'row'}>
              {toCurrency(cpuCost * discount, currency, 2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align={'left'} scope={'row'} width={200}>
              RAM
            </TableCell>
            <TableCell align={'right'} scope={'row'}>
              {round(ramGB, 3)} GiB
            </TableCell>
            <TableCell align={'right'} scope={'row'}>
              {toCurrency(costPerRamGBHour, currency, 3)}/GiBHr
            </TableCell>
            <TableCell align={'right'} scope={'row'}>
              {toCurrency(ramCost * discount, currency, 2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align={'left'} scope={'row'} width={200}>
              GPU
            </TableCell>
            <TableCell align={'right'} scope={'row'}>
              {gpuCount} GPU
            </TableCell>
            <TableCell align={'right'} scope={'row'}>
              {toCurrency(costPerGPUHour, currency, 3)}/GPUHR
            </TableCell>
            <TableCell align={'right'} scope={'row'}>
              {toCurrency(gpuCost, currency, 2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

NodeAssetDetails.displayName = 'NodeAssetDetails';
