import * as Sentry from '@sentry/react';

import { isProduction, version } from './env';
import Logger from './logger';
import { model } from './model';
import { isDebugMode } from './util';

const ErrorURL = 'https://formspree.io';
let enabled = false;
/**
  If running in production, attempt to initialize Sentry and keep a reference to
  the Sentry reporter. Does nothing outside of production.
*/
async function init(): Promise<void> {
  // Do not init sentry if errorReporting is not on
  try {
    if (!isProduction()) {
      return;
    }
    const clusterInfo = await model.clusterInfo();
    enabled = clusterInfo.errorReporting === 'true';
    if (!enabled) {
      return;
    }
    Sentry.init({
      dsn: 'https://23f9f6e81ea14c8c8d40788f56c40abc@o394722.ingest.sentry.io/5477757',
      ignoreErrors: [
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
      ],
      release: `cost-analyzer-frontend@v${version}`,
    });
  } catch (err) {
    Logger.warn(`Unable to initialize Sentry ${err}`);
  }
}

async function captureError(error: Error): Promise<void> {
  // Do not capture Error if errorReporting is not enabled
  if (!isProduction() || !enabled) {
    return;
  }
  Logger.log('error detected...');
  const { message } = error;

  const page = window.location.pathname;
  const { stack } = error;
  const gaMessage = `(APP VERSION: ${version}, SOURCE: ${page}) ${message} -- ${stack}`;

  if (isDebugMode()) {
    throw error;
  }

  // don't send empty reports
  if (!stack) {
    return;
  }

  fetch(`${ErrorURL}/xzbeopgo`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      message: gaMessage,
      _subject: 'Kubecost error react',
    }),
  }).then((resp) => Logger.log(resp));
}

const ErrorReportingHOC = ({ children }: { children: React.ReactElement }) => {
  init();
  return children;
};

export { ErrorReportingHOC, captureError };
