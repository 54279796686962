import { Button, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChevronRight from '@material-ui/icons/ChevronRight';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import get from 'lodash/get';
import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useClusters } from '../../../contexts/ClusterConfig';
import { analytics as Analytics } from '../../../services/analytics';
import { model } from '../../../services/model';
import { parseResponseJSON } from '../../../services/util';
import { NavItem } from '../NavItem';

const re = /^(https?:\/\/)(.+):(.+)@(.+)/;
const useStyles = makeStyles({
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      cursor: 'pointer',
    },
  },
});

async function fetchClusterList(allEndpoints: string[]): Promise<any> {
  const clusters = [];

  for (let i = 0; i < allEndpoints.length; i++) {
    const cluster = allEndpoints[i].replace(/\/api$/, '');
    const url = `${cluster}/model/clusterInfo?req=${Date.now()}`;

    try {
      const info = await fetch(url).then(parseResponseJSON);

      const name = get(info, 'data.name', cluster);
      const federatedCluster = get(info, 'data.thanosEnabled', false);

      clusters.push({
        name,
        federatedCluster: federatedCluster === 'true',
        address: cluster,
        details: get(info, 'details', {}),
      });
    } catch (e) {
      clusters.push({
        address: cluster,
        federatedCluser: false,
        name: cluster,
        details: {},
      });
    }
  }

  return clusters;
}

const ContextSwitcher: FC = () => {
  const classes = useStyles();
  const [clusterList, setClusterList] = useState([]);
  const [clusterName, setClusterName] = useState('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { activeContext, localClusterEndpoints, remoteClusterEndpoints } = useClusters();

  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const handleGoToAddCluster = () => {
    setDialogOpen(false);
    navigate('context-list');
  };

  const handleSetCluster = (cluster: any) => {
    const basicAuthAddress = re.exec(cluster.address);
    const { auth } = cluster.details;

    if (!(basicAuthAddress || auth)) {
      localStorage.setItem('container', cluster.address);
      setDialogOpen(false);
      Analytics.record('general:switch_cluster', {
        from: '',
        to: cluster.name,
      });
      window.location.reload();
    } else {
      window.open(cluster.address);
    }
  };

  useEffect(() => {
    fetchClusterList([...remoteClusterEndpoints, ...localClusterEndpoints]).then(setClusterList);
  }, [remoteClusterEndpoints, localClusterEndpoints]);

  useEffect(() => {
    model.clusterInfo().then((resp) => {
      setClusterName(resp.name);
    });
  }, [activeContext]);

  return (
    <>
      <NavItem
        active={dialogOpen}
        icon={<SwapHorizIcon />}
        name={'Switch Context'}
        onClick={handleOpenDialog}
        secondary={clusterName}
        title={'Current Context Name'}
      />
      <Dialog maxWidth={'sm'} onClose={handleOpenDialog} open={dialogOpen} fullWidth>
        <DialogTitle style={{ padding: '1em' }}>
          Select Context &nbsp;
          <a
            href={'https://guide.kubecost.com/hc/en-us/articles/4407595970711#multi-cluster'}
            rel={'noreferrer'}
            style={{ fontSize: 13 }}
            target={'_blank'}
          >
            What is a context?
          </a>
        </DialogTitle>
        <hr
          style={{
            margin: 0,
            border: 'none',
            borderTop: '1px solid rgba(0,0,0,0.1)',
          }}
        />
        <DialogContent style={{ padding: 0 }}>
          {clusterList.length === 0 ? (
            <DialogContentText style={{ padding: '1em' }}>
              No contexts configured.
            </DialogContentText>
          ) : (
            <div style={{ padding: 0 }}>
              {clusterList.map((cluster: any) => {
                const basicAuthName = re.exec(cluster.name);
                const basicAuthAddress = re.exec(cluster.address);
                return (
                  <div
                    className={classes.listItem}
                    key={cluster.address}
                    onClick={() => handleSetCluster(cluster)}
                    style={{
                      padding: '1em',
                      display: 'grid',
                      gridTemplateColumns: '1fr auto 30px',
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: '17px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {basicAuthName ? basicAuthName[4] : cluster.name}
                      </div>
                      <p
                        style={{
                          margin: 0,
                          color: 'rgba(0,0,0,0.6)',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {basicAuthAddress ? basicAuthAddress[4] : cluster.address}
                      </p>
                    </div>
                    <div style={{ textAlign: 'right', paddingTop: '12px' }}>
                      {cluster.federatedCluster ? 'Multi Cluster' : 'Single Cluster'}
                    </div>

                    <ChevronRight style={{ marginTop: '10px' }} />
                  </div>
                );
              })}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={handleGoToAddCluster}>
            Manage Contexts
          </Button>
          <Button onClick={handleOpenDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ContextSwitcher.displayName = 'ContextSwitcher';
export default ContextSwitcher;
