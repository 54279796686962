import SearchIcon from '@material-ui/icons/Search';

import { Typography } from '@kubecost-frontend/holster';

const EmptyAllocationData = () => (
  <div className={'mb-8 h-48 w-full p-16 text-center'}>
    <SearchIcon style={{ height: '4rem', width: '4rem', marginBottom: '2rem' }} />
    <Typography variant={'h5'}>We looked everywhere and couldn't find anything!</Typography>
    <Typography variant={'p'}>Try adjusting your report parameters and try again!</Typography>
  </div>
);

export default EmptyAllocationData;
