import { SyntheticEvent, useContext } from 'react';

import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import UserIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';

import { Typography } from '@kubecost-frontend/holster';

import { AlertsNavIcon } from '../../assets/images/alerts-nav-icon';
import { AllocationNavIcon } from '../../assets/images/allocation-nav-icon';
import { AssetsNavIcon } from '../../assets/images/assets-nav-icon';
import { CloudNavIcon } from '../../assets/images/cloud-nav-icon';
import { HealthNavIcon } from '../../assets/images/health-nav-icon';
import { OverviewNavIcon } from '../../assets/images/overview-nav-icon';
import { ReportsNavIcon } from '../../assets/images/reports-nav-icon';
import { SavingsNavIcon } from '../../assets/images/savings-nav-icon';
import useEnablements, { EnablementKeys } from '../../hooks/useEnablements';
import { useHosted } from '../../hooks/useHosted';
import logo from '../../images/kubecost-full-logo.png';
import logoBeta from '../../images/kubecost_logo_beta.png';
import { SearchBarContext } from '../Page';

import ContextSwitcher from './ContextSwitcher';
import { NavItem } from './NavItem';
import TeamSwitcher from './TeamSwitcher';

const Nav = ({ active }: { active: string }) => {
const { config, isHostedEnvironment } = useHosted();
const { data: enablements } = useEnablements();
  // Search box
  const { setIsVisible } = useContext(SearchBarContext);
  

  const top = [
    { name: 'Overview', href: 'overview', icon: OverviewNavIcon },
    {
      name: 'Allocations',
      href: 'allocations',
      icon: AllocationNavIcon,
    },
    { name: 'Assets', href: 'assets', icon: AssetsNavIcon },
    { name: 'Savings', href: 'savings', icon: SavingsNavIcon },
    { name: 'Health', href: 'health', icon: HealthNavIcon },
    { name: 'Reports', href: 'reports', icon: ReportsNavIcon },
    { name: 'Alerts', href: 'alerts', icon: AlertsNavIcon },
  ];
  if (enablements && enablements[EnablementKeys.cloudCosts]?.isEnabled) {
    top.splice(3, 0, {
      name: 'Cloud Costs',
      href: 'cloud',
      icon: CloudNavIcon,
    });
  }

  const topHosted = [
    {
      name: 'Allocations',
      href: 'allocations',
      icon: AllocationNavIcon,
    },
    {
      name: 'Assets',
      href: 'assets',
      icon: AssetsNavIcon,
    },
    {
      name: 'Reports',
      href: 'reports',
      icon: ReportsNavIcon,
    },
    {
      name: 'Savings',
      href: 'savings',
      icon: SavingsNavIcon,
    },
  ];

  const navItems = () => {
    if (isHostedEnvironment) return topHosted;
    return top;
  };

  return (
    <div className={'w-[240px] shrink-0 grow-0 bg-kc-background'}>
      <nav className={'fixed flex h-full w-[240px] flex-col p-4'}>
        <img
          alt={'Kubecost'}
          className={'mx-5 mb-4 mt-6 w-[135px]'}
          src={isHostedEnvironment ? logoBeta : logo}
        />

        <div className={'grow'}>
          {navItems().map((l) => (
            <NavItem active={active === `/${l.href}`} key={l.name} {...l} />
          ))}
        </div>
        <div>
          {isHostedEnvironment && <TeamSwitcher />}
          {!isHostedEnvironment && <ContextSwitcher />}
          <a href={'settings'}>
            <div className={'flex items-center p-4'}>
              <SettingsIcon className={'mr-4'} />
              <Typography
                className={'cursor-pointer font-bold hover:text-kc-primary'}
                variant={'p'}
              >
                Settings
              </Typography>
            </div>
          </a>
          {isHostedEnvironment && (
            <div
              className={'flex items-center p-4'}
              onClick={(e: React.SyntheticEvent) => {
                localStorage.removeItem('auth');
                localStorage.removeItem('current_team');
                window.location.href = `${
                  config!.userServiceAPIUrl
                }/logout?redirect=${encodeURIComponent(window.location.href)}`;
              }}
            >
              <MeetingRoomIcon className={'mr-4'} />
              <Typography
                className={'cursor-pointer font-bold hover:text-kc-primary'}
                variant={'p'}
              >
                Logout
              </Typography>
            </div>
          )}
          {document.cookie.indexOf('token=') != -1 && (
            <div
              className={'flex items-center p-4'}
              onClick={(e: SyntheticEvent) => {
                window.location.href = '/logout';
              }}
            >
              <MeetingRoomIcon className={'mr-4'} />
              <Typography
                className={'cursor-pointer font-bold hover:text-kc-primary'}
                variant={'p'}
              >
                Logout
              </Typography>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export { Nav };
