function computeUnassignedAddresses(zones) {
  return Object.values(zones).reduce((addresses, zone) => {
    if (!(zone.addresses && zone.addresses.length)) {
      return addresses;
    }
    const newAddresses = zone.addresses
      .filter((addr) => !(addr.users && addr.users.length))
      .map((addr) => ({ ...addr, savings: 0.01 * 730 }));
    return addresses.concat(newAddresses);
  }, []);
}

function computeUnassignedDisks(zones) {
  return Object.values(zones).reduce((disks, zone) => {
    if (!zone.disks) {
      return disks;
    }
    const newDisks = zone.disks
      .filter((disk) => !(disk.users && disk.users.length))
      .map((disk) => ({
        ...disk,
        savings: getHourlyStoragePrice('standard', 'us-central', 'gcp', true) * disk.sizeGb,
      }));
    return disks.concat(newDisks);
  }, []);
}

export default { computeUnassignedAddresses, computeUnassignedDisks };
