import Typography from '@material-ui/core/Typography';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { memo } from 'react';

import { getSingleDayEfficiency } from '../../../services/util';
import { Allocation } from '../../../types/allocation';

import CategoryChart from './CategoryChart';
import LineChart from './LineChart';
import RangeChart from './RangeChart';
import SummaryChart from './SummaryChart';
import TreemapChart from './TreeMapChart';

// TODO niko/etl
// sum allocationSet to single allocation
function agg(allocationSet, name) {
  if (allocationSet.length === 0) {
    return null;
  }

  return reduce(
    allocationSet,
    (aggr, cur) => ({
      name: aggr.name,
      aggregatedBy: cur.aggregatedBy,
      properties: aggr.properties,
      start: cur.start,
      end: cur.end,
      window: cur.window,
      cpuCost: aggr.cpuCost + cur.cpuCost,
      gpuCost: aggr.gpuCost + cur.gpuCost,
      ramCost: aggr.ramCost + cur.ramCost,
      pvCost: aggr.pvCost + cur.pvCost,
      totalCost: aggr.totalCost + cur.totalCost,
      count: aggr.count + 1,
    }),
    {
      name,
      properties: null,
      cpuCost: 0.0,
      gpuCost: 0.0,
      ramCost: 0.0,
      pvCost: 0.0,
      totalCost: 0.0,
      count: 0,
    },
  );
}

function isIdle(allocation) {
  return allocation.name.indexOf('__idle__') >= 0;
}

function top(n, by) {
  return (allocations) => {
    if (isArray(allocations[0])) {
      return map(allocations, top(n, by));
    }

    const sorted = reverse(sortBy(allocations, by));
    const active = filter(sorted, (a) => !isIdle(a));
    const idle = filter(sorted, (a) => isIdle(a));
    const topn = active.slice(0, n);
    const other = [];
    if (active.length > n) {
      other.push(agg(active.slice(n), 'other'));
    }

    return {
      top: topn,
      other,
      idle,
    };
  };
}

const AllocationChart = ({
  aggregateBy,
  allocationRange,
  allocationRows,
  chartDisplay,
  drillDownCompatible,
  drillDownExemptRows,
  height,
  n,
  sharingIdle,
}) => {
  if (allocationRange.length === 0) {
    return <Typography variant={'body2'}>No data</Typography>;
  }

  if (allocationRange.length === 1) {
    const datum = top(n, (alloc) => alloc.totalCost)(allocationRange[0]);
    if (chartDisplay === 'category') {
      return (
        <CategoryChart
          aggregateBy={aggregateBy}
          height={height}
          idle={datum.idle}
          other={datum.other}
          top={datum.top}
        />
      );
    }
    if (chartDisplay === 'treemap') {
      return (
        <TreemapChart
          aggregateBy={aggregateBy}
          allocationRows={allocationRows}
          canDrillDown={aggregateBy.length === 1 && drillDownCompatible.includes(aggregateBy[0])}
          drillDownExemptRows={drillDownExemptRows}
          height={2 * height} // For clarity, double heigh from barcharts
          idle={datum.idle}
          other={datum.other}
          sharingIdle={sharingIdle}
          top={datum.top}
        />
      );
    }
    return <SummaryChart height={height} idle={datum.idle} other={datum.other} top={datum.top} />;
  }

  const data = top(n, (alloc) => alloc.totalCost)(allocationRange);
  if (chartDisplay === 'efficiency') {
    const efficiencyTotals = allocationRange.map((range: Allocation[]) =>
      getSingleDayEfficiency(range),
    );
    const lineData = data.map((item, idx: number) => ({
      ...item,
      totalEfficiency: efficiencyTotals[idx],
    }));
    return <LineChart data={lineData} height={height} />;
  }
  return <RangeChart data={data} height={height} />;
};

export default memo(AllocationChart);
