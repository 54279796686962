import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';

// recharts
import { BarChart, Bar, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';

import { WithDataLoader } from '../../components/DataLoader';
import { toCurrency } from '../../services/format';
import { model as Model } from '../../services/model';
import { AllocationSet } from '../../types/allocation';

const useStyles = makeStyles({
  actionButton: {
    color: '#2196f3',
  },
  actionLink: {
    marginLeft: 'auto',
  },
});

const BarChartWithLoading = WithDataLoader(BarChart);

const EfficiencyCard = ({ win }: { win: '1d' | '7d' }) => {
  const classes = useStyles();

  const [currency, setCurrency] = useState('USD');
  const [data, setData] = useState<BarData[]>([]);
  const [promise, setPromise] = useState<Promise<unknown> | null>(null);
  const [showMode, setShowMode] = useState<'totals' | 'breakdown'>('totals');
  const [cumulativeData, setCumulativeData] = useState<CumulativeData[]>([]);

  useEffect(() => {
    setPromise(fetchData());
  }, []);

  // resize chart when the containing element resizes
  const [chartWidth, setChartWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      const el = document.querySelector('[data-ref="cost-efficiency-card-wrapper"]');
      if (!el) {
        return;
      }
      const w = el.clientWidth - 25;
      setChartWidth(w);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Card>
      <CardHeader
        subheader={`${
          showMode === 'totals' ? 'Daily' : 'Cumulative'
        } usage, allocation, and idle resources over the last ${win === '1d' ? 'day' : '7 days'}`}
        title={'Cluster Efficiency'}
      />
      <CardContent data-ref={'cost-efficiency-card-wrapper'}>
        <BarChartWithLoading
          data={showMode === 'totals' ? data : cumulativeData}
          errorMessage={'Error loading efficiency metrics'}
          height={350}
          loadMessage={'Loading efficiency metrics'}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          promise={promise}
          width={chartWidth}
        >
          <CartesianGrid strokeDasharray={'0'} vertical={false} />
          <XAxis dataKey={showMode === 'totals' ? 'timestamp' : 'key'} />
          <YAxis tickFormatter={(val: number) => toCurrency(val, currency, 0, true)} />
          <Tooltip formatter={(val) => toCurrency(val, currency)} />
          <Legend />
          {showMode === 'totals' && <Bar dataKey={'Usage'} fill={'#3cba54'} stackId={'total'} />}
          {showMode === 'totals' && (
            <Bar dataKey={'Allocation'} fill={'#78f690'} stackId={'total'} />
          )}
          {showMode === 'totals' && <Bar dataKey={'Idle'} fill={'#b8b8b8'} stackId={'total'} />}
          {showMode === 'breakdown' && (
            <Bar dataKey={'Usage'} fill={'#3cba54'} stackId={'breakdown'} />
          )}
          {showMode === 'breakdown' && (
            <Bar dataKey={'Allocation'} fill={'#78f690'} stackId={'breakdown'} />
          )}
          {showMode === 'breakdown' && (
            <Bar dataKey={'Idle'} fill={'#b8b8b8'} stackId={'breakdown'} />
          )}
        </BarChartWithLoading>
      </CardContent>
      <CardActions disableSpacing>
        <FormControl>
          <Select
            onChange={(e) => setShowMode(e.target.value as 'breakdown' | 'totals')}
            value={showMode}
          >
            <MenuItem value={'totals'}>Totals over time</MenuItem>
            <MenuItem value={'breakdown'}>Cumulative by resource</MenuItem>
          </Select>
        </FormControl>
        <Link className={classes.actionLink} component={RouteLink} to={'/request-sizing'}>
          <Button className={classes.actionButton} endIcon={<KeyboardArrowRightIcon />}>
            Request Sizing
          </Button>
        </Link>
      </CardActions>
    </Card>
  );

  async function fetchData() {
    const prom = Promise.all([
      Model.getAllocationSummary(win, 'cluster', {
        accumulate: false,
        external: 'false',
        shareIdle: false,
        shareTenancyCosts: false,
      }),
      Model.getAllocationSummary(win, 'cluster', {
        accumulate: true,
        external: 'false',
        shareIdle: false,
        shareTenancyCosts: false,
      }),
      Model.getConfigs(),
    ]);
    const [allocationSetRange, cumulative, modelConfigs] = await prom;
    const d = allocationSetRange.data.sets.map(
      (set: { allocations: AllocationSet; window: { end: string; start: string } }) => {
        const allocationSet = set.allocations;
        const idle = Model.getSummaryTotalCost(allocationSet.__idle__ || {});
        return Object.values(allocationSet).reduce(
          (accumulator, allocation) => {
            if (allocation.name === '__idle__') {
              return accumulator;
            }
            const totalCost = Model.getSummaryTotalCost(allocation);
            const totalEfficiency = Model.getSummaryTotalEfficiency(allocation);
            const used = totalCost * totalEfficiency;
            const unused = totalCost - used;
            return {
              Usage: accumulator.Usage + used,
              Allocation: accumulator.Allocation + unused,
              Idle: idle,
              timestamp: set.window.start.split('T')[win === '1d' ? 1 : 0],
            };
          },
          { Idle: 0, Allocation: 0, Usage: 0, timestamp: '' },
        );
      },
    );

    const cumulativeSet: AllocationSet = cumulative.data.sets[0].allocations;
    const cIdleCpu = cumulativeSet.__idle__?.cpuCost;
    const cIdleRam = cumulativeSet.__idle__?.ramCost;
    const cData = Object.values(cumulativeSet).reduce(
      (accumulator, allocation) => {
        if (allocation.name === '__idle__') {
          return accumulator;
        }
        const cpuEfficiency = Model.getSummaryCpuEfficiency(allocation);
        const ramEfficiency = Model.getSummaryRamEfficiency(allocation);
        const cpuUsage = allocation.cpuCost * cpuEfficiency;
        const cpuAllocation = allocation.cpuCost - cpuUsage;
        const ramUsage = allocation.ramCost * ramEfficiency;
        const ramAllocation = allocation.ramCost - ramUsage;
        return {
          CPU: {
            Usage: accumulator.CPU.Usage + cpuUsage,
            Allocation: accumulator.CPU.Allocation + cpuAllocation,
            Idle: cIdleCpu,
          },
          RAM: {
            Usage: accumulator.RAM.Usage + ramUsage,
            Allocation: accumulator.RAM.Allocation + ramAllocation,
            Idle: cIdleRam,
          },
        };
      },
      {
        CPU: { Usage: 0, Allocation: 0, Idle: 0 },
        RAM: { Usage: 0, Allocation: 0, Idle: 0 },
      },
    );
    setCurrency(modelConfigs.currencyCode || 'USD');
    setData(d);
    setCumulativeData(Object.entries(cData).map(([key, value]) => ({ key, ...value })));
  }
};

export default EfficiencyCard;

interface BarData {
  Idle: number;
  Unused: number;
  Used: number;
  timestamp: string;
}

interface CumulativeData {
  Allocation: number;
  Idle: number;
  Usage: number;
  key: string;
}
