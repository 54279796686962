import yellow from '@material-ui/core/colors/yellow';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';

const useStyles = makeStyles({
  popover: {
    padding: '12px 16px',
  },
});

const WarningPopover = ({ container }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'warning-popover' : undefined;

  let checkPodStatus = false;
  for (const key in container.warnings) {
    if (['nonode', 'nocpu', 'noram'].indexOf(key) >= 0) {
      checkPodStatus = true;
    }
  }

  return (
    <>
      <IconButton onClick={handleClick} size={'small'}>
        <WarningIcon style={{ color: yellow[700] }} />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        id={id}
        onClose={handleClose}
        open={open}
      >
        <div className={classes.popover}>
          {checkPodStatus && (
            <>
              <Typography variant={'body1'}>Check pod status</Typography>
              <Typography style={{ fontWeight: 700 }} variant={'body1'}>
                kubectl describe pod {container.pod} -n {container.namespace}
              </Typography>
              <hr />
            </>
          )}
          {Object.keys(container.warnings).map((k) => (
            <Typography key={k} variant={'body2'}>
              {container.warnings[k]}
            </Typography>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default WarningPopover;
