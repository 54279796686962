import { memo, useEffect, useState } from 'react';

import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { SnackbarCloseReason } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

import { Alert, Button, Toggle, Typography } from '@kubecost-frontend/holster';

import { ArrowIcon, FilterIcon } from '../../assets/images';
import { Header } from '../../components/Header2New';
import { Loading } from '../../components/Loading';
import { Warning, Warnings } from '../../components/Warnings';
import { useGetModelConfig, useInterval } from '../../hooks';
import { useHosted } from '../../hooks/useHosted';
import api from '../../services/api';
import cluster from '../../services/cluster';
import { captureError } from '../../services/error_reporting';
import Logger from '../../services/logger';
import { model } from '../../services/model';
import { fetchClusterSizingRecommendations } from '../../services/savings';
import { randomSuffix } from '../../services/util';
import { APIClient } from '../../services/APIClient';

import { AdvancedMetrics } from './AdvancedMetrics';
import { ClusterSizingFilterModal } from './ClusterSizingFilterModal';
import { getProfile } from './profiles';
import { RecommendationTable } from './RecommendationTable';

const useStyles = makeStyles({
  description: {
    padding: '24px 36px',
    marginBottom: 20,
  },
  recommendations: {
    display: 'flex',
  },
  recommendationTable: {
    display: 'flex',
  },
  form: {
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: 'flex',
    padding: '16px 24px',
    marginBottom: 0,
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
  advancedSwitch: {
    paddingLeft: 6,
    paddingTop: 8,
    paddingBottom: 8,
  },
});

const ClusterSizingPage = () => {
  const classes = useStyles();

  const [fetch, setFetch] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [warnings, setWarnings] = useState<Array<Warning>>([]);
  const [negotiatedDiscount, setNegotiatedDiscount] = useState(0.0);
  const [parameters, setParameters] = useState({});
  const [currentClusterInfo, setCurrentClusterInfo] = useState<any>(null);
  const [profile, setProfile] = useState('development');
  const [architecture, setArchitecture] = useState('x86');
  const [restrictArchitectureOptions, setRestrictArchitectureOptions] = useState(false);
  const [recommendations, setRecommendations] = useState({});
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [status, setStatus] = useState<
    { data: string } | { error: unknown; message: string; status: number }
  >({ data: 'Ready' });
  const [ready, setReady] = useState(false);
  const [setup, setSetup] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isThanosEnabled, setThanosEnabled] = useState(false);
  const [selectedCluster, setSelectedCluster] = useState('');
  const [clusters, setClusters] = useState<string[]>([]);
  const [clusterSizingRecommendations, setClusterSizingRecommendations] = useState<any | null>(
    null,
  );
  const { modelConfig } = useGetModelConfig();
  const currency = model.getModelCurrency(modelConfig);
  const { config, isHostedEnvironment } = useHosted();

  useInterval(() => {
    if (!isThanosEnabled) {
      pollStatus();
    }
  }, 60000);

  async function adoptRecommendation(recommendation) {
    const data: { machineType: string; name: string; nodeCount: number }[] = [];
    recommendation.pools.forEach((pool, index) => {
      let { name } = pool.type;
      name = name.replace('.', '-');
      name += `-${randomSuffix()}`;
      data.push({
        name,
        machineType: pool.type.name,
        nodeCount: pool.count,
      });
    });

    setReady(false);
    cluster.resize(data);
  }

  async function initialize() {
    if (isHostedEnvironment) {
      const clusterResp = await APIClient.get('clusterInfoMap');
      const clusterInfoMap = clusterResp.data.data as Record<string, { provider: string }>;

      setRestrictArchitectureOptions(
        !Object.values(clusterInfoMap).some((c) => c.provider === 'AWS'),
      );

      setInitialized(true);
      return;
    }

    const nd = await model.getNegotiatedDiscount();
    setNegotiatedDiscount(nd);

    const clusterMap = await model.clusterInfoMap();
    const clusterInfo = await model.clusterInfo();
    const { thanosEnabled, thanosOffset: offset } = cluster.getMultiClusterStatus(clusterInfo);
    const cinfo = clusterMap[clusterInfo.id];

    // It is possible that ``clusterMap``, in some cases, may not contain an entry for a given cluster ID.
    // This causes ``cinfo`` to be undefined, which leaves us with no way to obtain the clusterNameId string.
    // In these cases, we simply use the cluster ID as-is for display and selection purposes.
    let clusterNameIdOrClusterId = clusterInfo.id;
    if (cinfo) {
      clusterNameIdOrClusterId = cluster.clusterNameId({
        clusterId: cinfo.id,
        clusterName: cinfo.name,
      });
    }

    setProfile(get(clusterInfo, 'clusterProfile', 'development'));
    setSelectedCluster(clusterNameIdOrClusterId);
    setThanosEnabled(thanosEnabled);

    const ccs = await model.clusterCosts('2d', offset, thanosEnabled);
    const clusts: string[] = [];
    keys(ccs).forEach((k) => {
      // for clusters not present in the map, use the cluster ID
      if (!(k in clusterMap)) {
        clusts.push(k);
        return;
      }
      const ci = clusterMap[k];
      const cnid = cluster.clusterNameId({
        clusterId: ci.id,
        clusterName: ci.name,
      });
      clusts.push(cnid);

      if (ci.provider === 'Azure' || ci.provider === 'GCP') {
        // NOTE: Cluster sizing ARM recommendations currently not available for Azure & GCP
        setRestrictArchitectureOptions(true);
      }
    });

    setClusters(clusts);

    // NOTE: Since Cluster Sizing spans multicluster now, we'll only want to enable
    // NOTE: actions when we're using non-multicluster.
    // console.log("ThanosEnabled: " + thanosEnabled + ", clusterInfo.Provider: " + clusterInfo.provider)
    if (!thanosEnabled) {
      // Enable cluster resize action on GCP and EKS only
      if (clusterInfo.provider === 'GCP') {
        setShowActions(true);
      } else if (clusterInfo.provider === 'AWS' && (await api.isEKS())) {
        setShowActions(true);
      }

      await pollStatus();
    }

    setInitialized(true);
  }

  // TODO niko/clustersizing clean up
  const handleCloseSnackbar = (event: unknown, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  async function pollStatus() {
    let stat: { data: string } | null = null;
    let error: { error: unknown; message: string; status: number } | null = null;

    try {
      stat = await cluster.getStatus();
    } catch (err) {
      error = {
        error: err,
        status: 0,
        message: 'Cluster controller temporarily down',
      };
      if (err && typeof err === 'object' && (err as { status: number }).status) {
        error.status = (err as { status: number }).status;
      }
    }
    if (stat) {
      setStatus(stat);
    } else if (error) {
      setStatus(error);
    }

    const su = !error || error.status !== 404;
    setSetup(su);

    const message = get(stat, 'data', 'Cluster controller temporarily down');
    if (message !== get(status, 'data', '') && su) {
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    }

    setReady(!!stat && stat.data === 'Ready');
  }

  async function fetchData() {
    clearWarnings();
    setLoading(true);

    try {
      const { allowSharedCore, description, minNodeCount, p, range, targetUtilization } =
        getProfile(profile);
      let realClusterId = cluster.getClusterId(selectedCluster) || selectedCluster;

      const queryArch = architecture === 'any' ? '' : architecture;
      const { data: csrs } = await fetchClusterSizingRecommendations(
        range,
        targetUtilization,
        minNodeCount,
        allowSharedCore,
        queryArch,
      );

      if (isEmpty(csrs)) {
        throw new Error(
          'No cluster sizing information available. Check console logs for more information.',
        );
      }

      setClusterSizingRecommendations(csrs);

      if (isHostedEnvironment) {
        realClusterId = Object.keys(csrs)[0];
        setClusters(Object.keys(csrs));
      }

      const params = csrs[realClusterId].parameters;
      params.minNodeCount = minNodeCount;
      params.targetUtilization = targetUtilization;
      params.description = description;
      params.range = range;
      params.p = p;
      params.allowSharedCore = allowSharedCore;

      setParameters(params);

      const clusterInfo = csrs[realClusterId].currentClusterInfo;

      if (clusterInfo.totalCounts) {
        clusterInfo.totalCounts.utilizationVCPUs =
          (params.staticVCPUs + params.daemonSetVCPUs * clusterInfo.totalCounts.totalNodeCount) /
          clusterInfo.totalCounts.totalVCPUs;
        clusterInfo.totalCounts.utilizationRAMGB =
          (params.staticRAMGB + params.daemonSetRAMGB * clusterInfo.totalCounts.totalNodeCount) /
          clusterInfo.totalCounts.totalRAMGB;
      }

      setCurrentClusterInfo(clusterInfo);

      let recs = csrs[realClusterId].recommendations;

      if (recs.length === 0) {
        pushWarning({
          primary: 'No valid recommendations found',
          secondary: <span>Change the input parameters (profile, chipset) and try again.</span>,
        });
      }

      // Adjust cost with discount and add strategy name to recommendation
      recs = map(recs, (r, k) => {
        if (r === null) {
          return null;
        }

        const totalMonthlyCost = r.totalMonthlyCost * (1.0 - negotiatedDiscount);

        let strategy = 'Recommendation';
        if (k === 'single') {
          strategy = 'Recommendation: Simple';
        }
        if (k === 'multi') {
          strategy = 'Recommendation: Complex';
        }

        return { ...r, totalMonthlyCost, strategy };
      });

      // Ignore null recommendations
      recs = filter(recs, (r) => r !== null);

      // Ignore recommendations that are more expensive than the current cluster state
      recs = sortBy(recs, 'totalMonthlyCost');

      setRecommendations(recs);
    } catch (err) {
      Logger.error(err);
      pushWarning({
        primary: 'Failed to compute cluster sizing recommendations',
        secondary: <span>{err.message}</span>,
      });
      if (err instanceof Error) {
        captureError(err);
      }
    }

    setLoading(false);
    setFetch(false);
  }

  function clearWarnings() {
    setWarnings([]);
  }

  function pushWarning(warn: Warning) {
    setWarnings((warns: Warning[]) => [...warns, warn]);
  }

  useEffect(() => {
    setFetch(true);
  }, [profile, architecture]);

  async function reloadParameters() {
    try {
      const realClusterId = cluster.getClusterId(selectedCluster) || selectedCluster;

      const { allowSharedCore, description, minNodeCount, p, range, targetUtilization } =
        getProfile(profile);

      const params = clusterSizingRecommendations[realClusterId].parameters;

      params.minNodeCount = minNodeCount;
      params.targetUtilization = targetUtilization;
      params.description = description;
      params.range = range;
      params.p = p;
      params.allowSharedCore = allowSharedCore;

      setParameters(params);
    } catch (err) {
      Logger.error(err);
      pushWarning({
        primary: 'Failed to fetch recommendations for secondary cluster',
        secondary: <span>Check console logs for more information.</span>,
      });
      if (err instanceof Error) {
        captureError(err);
      }
    }
  }

  async function reloadRecommendations() {
    try {
      const realClusterId = cluster.getClusterId(selectedCluster) || selectedCluster;

      let recs = clusterSizingRecommendations[realClusterId].recommendations;

      if (recs.length === 0) {
        pushWarning({
          primary: 'No valid recommendations found',
          secondary: <span>Change the input parameters (profile, chipset) and try again.</span>,
        });
      }

      // Adjust cost with discount and add strategy name to recommendation
      recs = map(recs, (r, k) => {
        if (r === null) {
          return null;
        }

        const totalMonthlyCost = r.totalMonthlyCost * (1.0 - negotiatedDiscount);

        let strategy = 'Recommendation';
        if (k === 'single') {
          strategy = 'Recommendation: Simple';
        }
        if (k === 'multi') {
          strategy = 'Recommendation: Complex';
        }

        return { ...r, totalMonthlyCost, strategy };
      });

      // Ignore null recommendations
      recs = filter(recs, (r) => r !== null);

      // Ignore recommendations that are more expensive than the current cluster state
      recs = sortBy(recs, 'totalMonthlyCost');

      setRecommendations(recs);
    } catch (err) {
      Logger.error(err);
      pushWarning({
        primary: 'Failed to fetch recommendations for secondary cluster',
        secondary: <span>Check console logs for more information.</span>,
      });
      if (err instanceof Error) {
        captureError(err);
      }
    }
  }

  async function reloadCurrentClusterInfo() {
    try {
      const realClusterId = cluster.getClusterId(selectedCluster) || selectedCluster;

      const clusterInfo = clusterSizingRecommendations[realClusterId].currentClusterInfo;
      const params = clusterSizingRecommendations[realClusterId].parameters;

      if (clusterInfo.totalCounts) {
        clusterInfo.totalCounts.utilizationVCPUs =
          (params.staticVCPUs + params.daemonSetVCPUs * clusterInfo.totalCounts.totalNodeCount) /
          clusterInfo.totalCounts.totalVCPUs;
        clusterInfo.totalCounts.utilizationRAMGB =
          (params.staticRAMGB + params.daemonSetRAMGB * clusterInfo.totalCounts.totalNodeCount) /
          clusterInfo.totalCounts.totalRAMGB;
      }
      setCurrentClusterInfo(clusterInfo);
    } catch (err) {
      Logger.error(err);
      pushWarning({
        primary: 'Failed to fetch recommendations for secondary cluster',
        secondary: <span>Check console logs for more information.</span>,
      });
      if (err instanceof Error) {
        captureError(err);
      }
    }
  }

  useEffect(() => {
    if (selectedCluster === '' || !clusterSizingRecommendations) {
      return;
    }

    if (warnings.length > 0) {
      setFetch(true);
    } else {
      clearWarnings();

      reloadParameters();
      reloadRecommendations();
      reloadCurrentClusterInfo();
    }
  }, [selectedCluster]);

  // Handle fetching data
  useEffect(() => {
    if (!initialized) {
      initialize();
    }
    if (initialized && fetch) {
      fetchData();
    }
  }, [initialized, fetch]);

  return (
    <>
      <Header
        refreshCallback={() => setFetch(true)}
        title={
          <>
            Cluster Sizing Recommendations
            <Typography className={'font-normal text-kc-gray-200'} variant={'p'}>
              <a href={'savings'}>
                <ArrowIcon className={'inline'} direction={'UP'} />
                Back to savings
              </a>
            </Typography>
          </>
        }
      />
      <ClusterSizingFilterModal
        architecture={architecture}
        clusters={clusters}
        profile={profile}
        restrictArchitectureOptions={restrictArchitectureOptions}
        selectedCluster={selectedCluster}
        setVisible={setFilterModalVisible}
        updateArchitecture={setArchitecture}
        updateProfile={setProfile}
        updateSelectedCluster={setSelectedCluster}
        visible={filterModalVisible}
      />
      <Alert
        content={`Right-sizing recommendations use two primary inputs: first, your own description of the type of work running on the cluster (e.g. development, production, high-availability); second, the "shape" of the each workload's resource requirements, as measured by Kubecost metrics. We then consider different heuristic strategies for meeting the cluster's requirements.`}
        link={'https://bfy.tw/TQYP'}
        style={{ marginTop: '1em', marginBottom: '1em' }}
        title={'It’s important to know'}
        variant={'info'}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={() => setFilterModalVisible(true)}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.5em',
            marginTop: '.5em',
            marginBottom: '.5em',
          }}
          variant={'default'}
        >
          <FilterIcon />
          Filter
        </Button>
        <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
          <div>Show advanced metrics</div>
          <Toggle checked={showAdvanced} onChange={() => setShowAdvanced(!showAdvanced)} />
        </div>
      </div>
      {showAdvanced && <AdvancedMetrics parameters={parameters} />}
      {!loading && warnings.length > 0 ? <Warnings warnings={warnings} /> : <></>}

      {loading ? (
        <Loading message={'Computing optimal node recommendations. This may take a moment...'} />
      ) : (
        <></>
      )}

      {!loading && warnings.length === 0 ? (
        <div>
          <div className={classes.recommendationTable}>
            <RecommendationTable
              adoptRecommendation={adoptRecommendation}
              currency={currency}
              currentClusterInformation={currentClusterInfo}
              negotiatedDiscount={negotiatedDiscount}
              ready={ready}
              recommendations={recommendations}
              setup={setup}
              showActions={showActions}
              status={status}
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      <Snackbar
        action={
          <IconButton
            aria-label={'close'}
            color={'inherit'}
            onClick={handleCloseSnackbar}
            size={'small'}
          >
            <CloseIcon fontSize={'small'} />
          </IconButton>
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={10000}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        open={snackbarOpen}
      />
    </>
  );
};

export default memo(ClusterSizingPage);
