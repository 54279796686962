import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useState } from 'react';

import { AlertTypes, Alert, DiagnosticAlert } from '../../services/alerts';

const DiagnosticAlertsTable = ({
  alerts,
  createAlert,
  removeAlert,
  locked = {},
}: ComponentProps) => {
  const [newWindow, setNewWindow] = useState('');

  return (
    <TableContainer data-test={'alerts-table'}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Window</TableCell>
            <TableCell>Add/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alerts.map((a) => (
            <TableRow key={a.id}>
              <TableCell>{a.window}</TableCell>
              <TableCell>
                <IconButton data-test={'alert-delete'} onClick={() => removeAlert(a)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell data-test={'new-alert-window'}>
              {locked.window || (
                <TextField onChange={(e) => setNewWindow(e.target.value)} value={newWindow} />
              )}
            </TableCell>
            <TableCell>
              <IconButton data-test={'new-alert-add'} onClick={handleAddAlert}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  function handleAddAlert() {
    const newAlert = new DiagnosticAlert({
      type: AlertTypes.Budget,
      window: newWindow || locked.window,
    });
    createAlert(newAlert);
    setNewWindow('');
  }
};

export default DiagnosticAlertsTable;

DiagnosticAlertsTable.defaultProps = {
  locked: {},
};

interface ComponentProps {
  alerts: DiagnosticAlert[];
  createAlert: (alert: Alert) => void;
  locked?: {
    window?: string;
  };
  removeAlert: (alert: Alert) => void;
}
