import { useNavigate } from 'react-router-dom';

import { Grid, Paper } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export const AlphaAdvancedReportingBanner = () => {
  const navigate = useNavigate();
  return (
    <Grid
      component={Paper}
      onClick={() => navigate('/advanced-reporting')}
      style={{
        backgroundColor: 'rgba(180, 222, 250, 1.9)',
        cursor: 'pointer',
        marginTop: '1em',
        paddingLeft: '1em',
      }}
      xs={12}
      item
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ margin: '1em', color: '#0f3e0f' }}>
          <strong>Alpha |</strong> Visit the new Advanced Reporting page
        </div>
        <ArrowForwardIcon style={{ fill: '#0f3e0f' }} />
      </div>
    </Grid>
  );
};
