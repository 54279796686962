import { useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';

const apiQueryWindowParams = {
  today: 'Today',
  yesterday: 'Yesterday',
  week: 'Week to date',
  month: 'Month to date',
  lastweek: 'Last week',
  lastmonth: 'Last month',
  '24h': 'Last 24h',
  '48h': 'Last 48h',
  '7d': 'Last 7 days',
  '30d': 'Last 30 days',
  '60d': 'Last 60 days',
  '90d': 'Last 90 days',
} as const;

type RFC3339String = string;
type APIQueryWindowRange = `${RFC3339String},${RFC3339String}`;
type APIQueryWindow = keyof typeof apiQueryWindowParams | APIQueryWindowRange;

const rfc3339Regex =
  /^((?:(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}(?:\.\d+)?))(Z|[+-]\d{2}:\d{2})?)$/;

function isRFC3339String(dateString: string): dateString is RFC3339String {
  return rfc3339Regex.test(dateString);
}

function isAPIQueryWindowRange(dateString: string): dateString is APIQueryWindowRange {
  const dateArray = dateString.split(',');
  return dateArray.length === 2 && dateArray.every(isRFC3339String);
}

function useQueryWindowParamState<T extends string>(
  validParams: Record<T, string>,
  defaultState = '7d' as T,
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const isQueryWindow = useCallback(
    (windowParam: string): windowParam is T =>
      Object.keys(validParams ?? []).includes(windowParam) || isAPIQueryWindowRange(windowParam),
    [validParams],
  );

  const getWindowState = () => {
    const windowState = searchParams.get('window') ?? defaultState;

    if (isQueryWindow(windowState)) return windowState;

    return defaultState;
  };

  const setQueryWindowState = (newQueryWindow: T) => {
    const paramState = {
      ...[...searchParams.entries()].reduce(
        (previousValue, [key, value]) => ({ ...previousValue, [key]: value }),
        {},
      ),
      window: newQueryWindow,
    };
    setSearchParams(paramState, { replace: false });
  };

  return {
    queryWindowState: getWindowState(),
    setQueryWindowState,
    isQueryWindow,
  };
}

export {
  APIQueryWindow,
  APIQueryWindowRange,
  apiQueryWindowParams,
  isAPIQueryWindowRange,
  useQueryWindowParamState,
};
