import { getCurrentContainerAddressModel } from './util';

/**
 * Fetch and store data that should be globally available to the application.
 */
class AppConfig {
  apiConfig: ApiConfig | null = null;

  modelConfig: ModelConfig | null = null;

  /**
   * Fetch all relevant payloads, and store a copy on this instance.
   *
   * @remarks
   * A pattern that can be used to ensure that a UI always has all configs available
   * is to ``await AppConfig.init()`` before rendering the page.
   */
  async init() {
    const [apiConfig, modelConfig] = await Promise.all([
      this.getApiConfig(),
      this.getModelConfig(),
    ]);
    this.apiConfig = apiConfig;
    this.modelConfig = modelConfig;
  }

  /**
   * Fetch and return the API Config.
   * @returns The ApiConfig response.
   */
  async getApiConfig(): Promise<ApiConfig> {
    const response = await fetch(`${getCurrentContainerAddressModel()}/getApiConfig`);
    const config = await response.json();
    return config as ApiConfig;
  }

  /**
   * Fetch and return the Model Config.
   * @returns The ModelConfig response.
   */
  async getModelConfig(): Promise<ModelConfig> {
    const response = await fetch(`${getCurrentContainerAddressModel()}/getConfigs`);
    const config = await response.json();
    return config.data;
  }

  getCurrency() {
    if (!this.modelConfig) {
      return 'USD';
    }
    if (!this.modelConfig.currencyCode) {
      return 'USD';
    }
    return this.modelConfig.currencyCode;
  }

  getGrafanaEnabled() {
    if (!this.apiConfig) {
      return false;
    }
    return this.apiConfig.grafanaEnabled;
  }

  getGrafanaUrl() {
    if (!this.apiConfig) {
      return false;
    }
    return this.apiConfig.grafanaURL;
  }

  getSharedLabels() {
    if (!this.modelConfig) {
      return [];
    }
    const sharedLabelNames = this.modelConfig.sharedLabelNames
      .split(',')
      .map((labelName) => labelName.trim())
      .filter((labelName) => labelName);
    const sharedLabelValues = this.modelConfig.sharedLabelValues
      .split(',')
      .map((labelValue) => labelValue.trim())
      .filter((labelValue) => labelValue);
    if (sharedLabelNames.length === sharedLabelValues.length && sharedLabelNames.length > 0) {
      return sharedLabelNames.map((name, i) => `${name}:${sharedLabelValues[i]}`);
    }
    return [];
  }

  getSharedNamespaces() {
    if (!this.modelConfig) {
      return [];
    }
    return this.modelConfig.sharedNamespaces
      .split(',')
      .map((namespace) => namespace.trim())
      .filter((namespace) => namespace);
  }

  getSharedOverhead() {
    if (!this.modelConfig) {
      return 0;
    }
    return parseFloat(this.modelConfig.sharedOverhead || '0');
  }

  getShareTenancyCosts() {
    if (!this.modelConfig) {
      return false;
    }
    return this.modelConfig.shareTenancyCosts === 'true';
  }
}

export default new AppConfig();

interface ApiConfig {
  cluster_external_label: string;
  controller_external_label: string;
  daemonset_external_label: string;
  department_external_label: string;
  department_label: string;
  deployment_external_label: string;
  disableFE: string;
  environment_external_label: string;
  environment_label: string;
  grafanaEnabled: boolean;
  grafanaURL: string;
  namespace_external_label: string;
  owner_external_label: string;
  owner_label: string;
  pod_external_label: string;
  product_external_label: string;
  product_label: string;
  service_external_label: string;
  statefulset_external_label: string;
  team_external_label: string;
  team_label: string;
}

interface ModelConfig {
  CPU: string;
  GPU: string;
  LBIngressDataCost: string;
  RAM: string;
  additionalForwardingRuleCost: string;
  athenaBucketName: string;
  athenaDatabase: string;
  athenaProjectID?: string;
  athenaRegion: string;
  athenaTable: string;
  awsSpotDataBucket?: string;
  awsSpotDataRegion?: string;
  azureBillingRegion: string;
  azureClientID: string;
  azureClientSecret: string;
  azureSubscriptionID: string;
  azureTenantID: string;
  clusterName: string;
  currencyCode: string;
  customPricesEnabled: string;
  defaultIdle: string;
  description: string;
  discount: string;
  firstFiveForwardingRulesCost: string;
  internetNetworkEgress: string;
  kubecostToken: string;
  masterPayerARN: string;
  negotiatedDiscount: string;
  projectID?: string;
  provider: string;
  readOnly: string;
  regionNetworkEgress: string;
  shareTenancyCosts: string;
  sharedLabelNames: string;
  sharedLabelValues: string;
  sharedNamespaces: string;
  sharedOverhead: string;
  spotCPU: string;
  spotGPU: string;
  spotRAM: string;
  storage: string;
  zoneNetworkEgress: string;
}
