import Chip from '@material-ui/core/Chip';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';

const EmailRecipients = ({ addItem, recipients, removeItem }: ComponentProps) => {
  const [text, setText] = useState('');

  return (
    <>
      <FormGroup>
        <TextField
          helperText={'List of email recipients (optional). Press Enter after typing each address.'}
          inputProps={{
            'data-test': 'emailRecipientInput',
          }}
          label={'Add a recipient'}
          onChange={(e) => setText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key.toLowerCase() === 'enter') {
              addItem(text);
              setText('');
            }
          }}
          placeholder={'user@domain.tld'}
          value={text}
        />
      </FormGroup>
      {recipients.map((r, i) => (
        <Chip data-test={'emailRecipientsList'} key={r} label={r} onDelete={() => removeItem(i)} />
      ))}
    </>
  );
};

export default EmailRecipients;

interface ComponentProps {
  addItem: (item: string) => void;
  recipients: string[];
  removeItem: (item: number) => void;
}
