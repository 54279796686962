import { ReactElement } from 'react';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  alert: {
    marginBottom: 16,
  },
});

const Alerts = ({ alerts }: { alerts: AlertData[] }) => {
  const classes = useStyles();

  if (!alerts || alerts.length === 0) {
    return null;
  }

  return (
    <div>
      {alerts.map((alert) => (
        <Alert
          className={classes.alert}
          key={`${alert.primary} + ${alert.secondary}`}
          severity={alert.level || 'info'}
        >
          {alert.secondary ? (
            <>
              <AlertTitle>{alert.primary}</AlertTitle>
              {alert.secondary}
            </>
          ) : (
            alert.primary
          )}
        </Alert>
      ))}
    </div>
  );
};

export type AlertData = {
  level: 'error' | 'info' | 'success' | 'warning' | undefined;
  primary: string | ReactElement;
  secondary: string | ReactElement;
};

export { Alerts };
