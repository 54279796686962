import { memo } from 'react';

import { Tooltip, Typography } from '@kubecost-frontend/holster';

import { NavigateNextIcon } from '../../assets/images/NavigateNextIcon';
import { toVerboseTimeRange } from '../../services/format';
import { AllocationContext } from '../../types/allocation';

type SubtitleProps = {
  clearContext: () => void;
  context: AllocationContext;
  goToContext: (i: number) => void;
  report: { aggregateBy: string[]; window: string };
};

const Subtitle = ({ clearContext, context, goToContext, report }: SubtitleProps) => {
  const { aggregateBy, window } = report;

  const aggByStr = aggregateBy.join(', ');

  if (!Array.isArray(context) || context.length === 0) {
    return (
      <Typography variant={'p-small'}>
        {toVerboseTimeRange(window)}
        {aggByStr ? ` by ${aggByStr}` : ''}
      </Typography>
    );
  }

  return (
    <>
      <a onClick={clearContext} style={{ cursor: 'pointer', display: 'inline-block' }}>
        <Typography variant={'p-small'}>
          {toVerboseTimeRange(window)} by {context[0].property.toLowerCase()}
        </Typography>
      </a>
      {context.map((ctx, i) => {
        if (i === context.length - 1) {
          return (
            <>
              <NavigateNextIcon style={{ fontSize: 14 }} />
              <Tooltip content={ctx.property} key={ctx.property}>
                <Typography variant={'p-small'}>{ctx.name}</Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            <NavigateNextIcon style={{ fontSize: 14 }} />
            <a
              key={ctx.property}
              onClick={(e) => {
                e.preventDefault();
                goToContext(i);
              }}
              style={{ display: 'inline-block' }}
            >
              <Tooltip content={ctx.property}>
                <Typography variant={'p-small'}>{ctx.name}</Typography>
              </Tooltip>
            </a>
          </>
        );
      })}
    </>
  );
};

export default memo(Subtitle);
