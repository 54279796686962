// TODO: set function type
export const monitorWidthResize = (ref: any, setWidthFunction: (width: number) => void) => {
  const handleResize = () => {
    const el = ref.current;
    if (!el) {
      return;
    }
    const w = el.clientWidth - 25;
    setWidthFunction(w);
  };
  window.addEventListener('resize', handleResize);
  handleResize();
  return () => {
    window.removeEventListener('resize', handleResize);
  };
};
