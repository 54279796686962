import { memo, useState } from 'react';

import get from 'lodash/get';
import round from 'lodash/round';

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { useClusters } from '../../contexts/ClusterConfig';
import { toCurrency } from '../../services/format';

import type { Allocation } from './api/AllocationsQuery';

type DetailsRowProps = {
  allocation: Allocation;
};

const DetailsRow = ({ allocation }: DetailsRowProps) => {
  const [open, setOpen] = useState(false);
  const { modelConfig } = useClusters();

  const container = allocation.container ?? '';

  // Init breakdown values
  const startTime = allocation.start ? (new Date(allocation.start)).getTime() : 0;
  const endTime = allocation.end ? (new Date(allocation.end)).getTime() : 0;

  const duration = endTime - startTime;
  const hours = duration / (1000 * 60 * 60);

  const perHour = (n: number) => hours > 0 ? (n / hours) : 0;

  const cost = allocation.totalCost;
  const costPerHour = perHour(cost);

  const cpuCost = allocation.cpuCost;
  const cpuCostPerHour = perHour(cpuCost);

  const ramCost = allocation.ramCost;
  const ramCostPerHour = perHour(ramCost);

  const gpuCost = allocation.gpuCost;
  const gpuCostPerHour = perHour(gpuCost);

  const networkCost = allocation.networkCost;
  const networkCostPerHour = perHour(networkCost);

  const pvCost = allocation.pvCost;
  const pvCostPerHour = perHour(pvCost);

  const loadBalancerCost = allocation.loadBalancerCost;
  const loadBalancerCostPerHour = perHour(loadBalancerCost);
  
  return (
    <>
      <TableRow data-test={'allocation-detail-row'}>
        <TableCell width={25}>
          <IconButton
            aria-label={'expand allocation'}
            onClick={() => setOpen(!open)}
            size={'small'}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align={'left'} scope={'allocation'} width={200}>
          {container}
        </TableCell>
        <TableCell align={'right'} data-test={'hours'} scope={'allocation'}>
          {hours}
        </TableCell>
        <TableCell align={'right'} data-test={'costPerHour'} scope={'allocation'}>
          {toCurrency(costPerHour, modelConfig.currencyCode, 3)}
        </TableCell>
        <TableCell align={'right'} data-test={'totalCost'} scope={'allocation'}>
          {toCurrency(cost, modelConfig.currencyCode, 2)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout={'auto'} unmountOnExit>
            <Box p={6}>
              <Typography component={'div'} variant={'h6'} gutterBottom>
                {container} | Resource Breakdown:
              </Typography>
              <Table aria-label={'breakdown'} size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Resource</TableCell>
                    <TableCell>Hourly-Rate</TableCell>
                    <TableCell>Cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cpuCost !== 0 && (
                    <TableRow>
                      <TableCell>CPU</TableCell>
                      <TableCell>
                        {toCurrency(cpuCostPerHour, modelConfig.currencyCode, 3)}
                        /HR
                      </TableCell>
                      <TableCell>
                        {toCurrency(cpuCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {ramCost !== 0 && (
                    <TableRow>
                      <TableCell>RAM</TableCell>
                      <TableCell>
                        {toCurrency(ramCostPerHour, modelConfig.currencyCode, 3)}
                        /HR
                      </TableCell>
                      <TableCell>
                        {toCurrency(ramCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {gpuCost !== 0 && (
                    <TableRow>
                      <TableCell>GPU</TableCell>
                      <TableCell>
                        {toCurrency(gpuCostPerHour, modelConfig.currencyCode, 3)}
                        /HR
                      </TableCell>
                      <TableCell>
                        {toCurrency(gpuCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {pvCost !== 0 && (
                    <TableRow>
                      <TableCell>PV</TableCell>
                      <TableCell>
                        {toCurrency(pvCostPerHour, modelConfig.currencyCode, 3)}
                        /HR
                      </TableCell>
                      <TableCell>
                        {toCurrency(pvCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {networkCost !== 0 && (
                    <TableRow>
                      <TableCell>Network</TableCell>
                      <TableCell>
                        {toCurrency(networkCostPerHour, modelConfig.currencyCode, 3)}
                        /HR
                      </TableCell>
                      <TableCell>
                        {toCurrency(networkCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {loadBalancerCost !== 0 && (
                    <TableRow>
                      <TableCell>Load Balancer</TableCell>
                      <TableCell>
                        {toCurrency(loadBalancerCostPerHour, modelConfig.currencyCode, 3)}
                        /HR
                      </TableCell>
                      <TableCell>
                        {toCurrency(loadBalancerCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default memo(DetailsRow);
