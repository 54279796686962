type AggregationType = {
  name: string;
  value: string;
};

interface AutoscalingSchedule {
  frequencyMinutes: number;
  lastModified?: Date;
  start: Date;
  targetUtilizationCPU: number;
  targetUtilizationMemory: number;
}

interface AutoscalingWorkload {
  clusterID: string;
  controllerKind: string;
  controllerName: string;
  namespace: string;
}

interface AutoscalingWorkloadSchedule {
  schedule: AutoscalingSchedule;
  workload: AutoscalingWorkload;
}

interface AvgSavingsChartData {
  cost: number;
  request: number;
  time: string | undefined;
  usage: number;
}

interface DrillDownFilters {
  filterClusters: string;
  filterContainers: string;
  filterControllers: string;
  filterNamespaces: string;
}

interface DrilldownChartData {
  currentRequest: number;
  dailyAvg: number;
  recommendation: number;
  time: string;
}

type Filter = {
  equality: string;
  property: string;
  value: string;
};

// reusable type definitions!
// NOTE: These types are for the V2 Request Sizing API
enum RecommendationAlgorithm {
  Max = 'max',
  Quantile = 'quantile',
}

enum Profile {
  Production = 'production',
  Development = 'development',
  HighAvailability = 'highAvailability',
  Custom = 'custom',
}

interface RequestSizingParams {
  algorithmCPU: RecommendationAlgorithm;
  algorithmRAM: RecommendationAlgorithm;
  filter?: Filter[];
  profile: Profile;
  qCPU?: number;
  qRAM?: number;
  targetCPUUtilization: number;
  targetRAMUtilization: number;
  window: string;
  page: number;
  itemsPerPage: number;
}

interface RequestSizingRecommendation {
  clusterID: string;
  containerName: string;
  controllerKind: string;
  controllerName: string;
  currentEfficiency: ResourceToNumberMapping;

  latestKnownRequest: ResourceToAmountMapping;
  monthlySavings: ResourceToNumberMapping;

  namespace: string;
  recommendedRequest: ResourceToAmountMapping;
}

// The ultimate response format of the request sizing V2 API
type RequestSizingResponse = {
  Recommendations: RequestSizingRecommendation[];
  Count: number;
  TotalMonthlySavings: number;
};

// E.g. { "cpu": "55m" } or { "memory": "5Gi" }
// The API does not guarantee that all resource types will be present.
interface ResourceToAmountMapping {
  cpu?: string;
  memory?: string;
}

// E.g. { "cpu": 500 } or { "memory": 230.3 }
// The API does not guarantee that all resource types will be present.
interface ResourceToNumberMapping {
  cpu?: number;
  memory?: number;
  total?: number; // Some fields will have a built-in total, refer to API docs
}

type SelectOptions = {
  label: string;
  value: string;
};

export {
  AggregationType,
  AutoscalingSchedule,
  AutoscalingWorkload,
  AutoscalingWorkloadSchedule,
  AvgSavingsChartData,
  DrillDownFilters,
  DrilldownChartData,
  Filter,
  Profile,
  RecommendationAlgorithm,
  RequestSizingParams,
  RequestSizingRecommendation,
  RequestSizingResponse,
  ResourceToAmountMapping,
  ResourceToNumberMapping,
  SelectOptions,
};
