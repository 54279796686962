import { DateSelectorNew } from '../../components/DateSelectorNew';
import { Header } from '../../components/Header2New';
import { useAggregationParamState } from '../../hooks/useAggregationParamState';
import { useCloudCosts } from '../../hooks/useCloudCosts';
import { APICloudCostParamType, APICloudCostParams } from '../../types/cloudCosts';

import { AggregationMenu } from './AggregataionMenu';
import { CloudCostTable } from './CloudCostTable';
import { windowOptions } from './constants';
import { RangeChart } from './RangeChart';

const CloudCostsPage = () => {
  const { aggregationState, setParamState, windowState } =
    useAggregationParamState<APICloudCostParamType>(APICloudCostParams);
  const { data, invalidate, isError, isLoading, isSuccess } = useCloudCosts(
    windowState,
    aggregationState,
  );

  const handleSetWindow = (window: string) => setParamState({ window });

  return (
    <>
      <Header refreshCallback={invalidate} title={'Cloud Cost Explorer'} />

      <div className={'flex justify-between'}>
        <div className={'mr-4'}>
          <DateSelectorNew
            setWindow={handleSetWindow}
            window={windowState}
            windowOptions={windowOptions}
          />
        </div>
        <AggregationMenu<APICloudCostParamType> validItems={APICloudCostParams} />
      </div>

      {isLoading ? 'Loading!' : null}
      {isSuccess && !isError && (
        <>
          <RangeChart data={data.graphData} height={300} />
          <hr
            /* TODO: add tailwind default color untilities to Holster so that
             * we can use `border-black/10` here */
            className={'border-t-[1px] border-[rgba(0,0,0,0.1)]'}
          />
          <CloudCostTable
            rate={'cumulative'}
            tableData={data.tableRows}
            totalsRow={data.tableTotal}
          />
        </>
      )}
    </>
  );
};

export { CloudCostsPage };
