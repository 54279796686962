import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';

import { useClusters } from '../../contexts/ClusterConfig';
import AppConfig from '../../services/appconfig';
import { AllocationProperties } from '../../types/allocation';

import Details from './Details';

type DetailsDialogProps = {
  close: () => void;
  open: boolean;
  properties: null | AllocationProperties;
  service: string;
  window: string;
};

const DetailsDialog: React.FC<DetailsDialogProps> = ({
  close,
  open,
  properties,
  service,
  window,
}: DetailsDialogProps) => {
  const { apiConfig, grafanaUrl } = useClusters();

  // If the dialog is not open, or no properties have been passed (these states
  // should be synonymous) then early return to prevent accessing sub-properties
  // that do not exist.
  if (!open || properties === null) {
    return null;
  }

  const { controller, namespace, pod } = properties;

  // Additionally, early-return if `pod` is not defined.
  // Without pod, there will be no filterPods= parameter in the allocation query, and data
  // for items outside of the accessed row would be displayed.
  if (!pod) {
    return null;
  }

  const grafanaLink = `${grafanaUrl}/d/at-cost-analysis-pod/pod-cost-and-utilization-metrics?from=now-1d&orgId=1&var-costStorageStandard=0.044&var-costStorageSSD=0.187&var-costDiscount=30&var-namespace=${namespace}&var-pod=${pod}`;

  let title;

  if (namespace && controller) {
    title = `${namespace} / ${controller} : ${pod}`;
  } else if (namespace) {
    title = `${namespace} / ${pod}`;
  } else if (controller) {
    title = `${controller} : ${pod}`;
  } else {
    title = pod;
  }

  return (
    <Dialog maxWidth={'lg'} onClose={close} open={open} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {AppConfig.getCurrency() && (
          <Details properties={properties} service={service} window={window} />
        )}
      </DialogContent>
      <DialogActions>
        {apiConfig.grafanaEnabled || apiConfig.grafanaURL ? (
          <Button color={'primary'} href={grafanaLink} target={'_blank'}>
            Open in Grafana
          </Button>
        ) : (
          <Tooltip title={'Grafana not enabled. Install Grafana to gain access.'}>
            <span>
              <Button color={'primary'} disabled>
                Open in Grafana
              </Button>
            </span>
          </Tooltip>
        )}
        <Button color={'primary'} onClick={close}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailsDialog;
