import React, { createContext, useContext } from 'react';
import {
  ClusterEnvResponse,
  ClusterInfoResponse,
  ModelConfig,
  ApiConfig,
  LocalConfig,
} from '../services/model';
import { ProductKey } from '../services/config';
import { useOnPremContextProvider } from './OnPremContextConfig';
import { useHostedContextProvider } from './HostedContextConfig';
import FailedClusterDialog from '../pages/FailedClusterDialog/FailedClusterDialog';
import { useHosted } from '../hooks/useHosted';

export type ClusterContextProvider = {
  error?: ClusterContextError;
  loading: boolean;
  data?: ClusterContextType;
};

export type ClusterContextError = {
  error: string;
};

export interface ClusterContextType {
  clusterConfig: ClusterInfoResponse;
  envConfig: ClusterEnvResponse;
  apiConfig: ApiConfig;
  apiVersion: string;
  baseApiUrl: string;
  clusterConfig: ClusterInfoResponse;
  config: LocalConfig;
  envConfig: ClusterEnvResponse;
  grafanaUrl: string;
  licenseConfig: ProductKey;
  localClusterEndpoints: string[];
  modelConfig: ModelConfig;
  promQueryUrl: string;
  promRangeQueryUrl: string;
  remoteClusterEndpoints: string[];
  removeContext: (contextURL: string) => void;
  thanosQueryUrl: string;
}

export const ClusterContext = createContext<ClusterContextType>({});

export const ClusterContextProvider = ({ children }: any) => {
  const { isHostedEnvironment } = useHosted();

  if (isHostedEnvironment) {
    return <HostedProvider children={children} />;
  } else {
    return <OnPremProvider children={children} />;
  }
};

const HostedProvider = ({ children }: any) => {
  const value = useHostedContextProvider();

  return (
    <ClusterContext.Provider value={value.data}>
      {children}
    </ClusterContext.Provider>
  );
};

const OnPremProvider = ({ children }: any) => {
  const value = useOnPremContextProvider();

  if (value.loading) {
    return <></>;
  }

  if (value.error) {
    console.error(value.error.error);
    return <FailedClusterDialog />;
  }

  return (
    <ClusterContext.Provider value={value.data}>
      {children}
    </ClusterContext.Provider>
  );
};

export const useClusters = () => useContext(ClusterContext);
