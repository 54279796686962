import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useState } from 'react';

import { AlertTypes, Alert, ClusterHealthAlert } from '../../services/alerts';

interface ClusterHealthAlertsTableProps {
  alerts: ClusterHealthAlert[];
  createAlert: (alert: Alert) => void;
  locked?: {
    threshold?: number;
    window?: string;
  };
  removeAlert: (alert: Alert) => void;
}

const ClusterHealthAlertsTable = ({
  alerts,
  createAlert,
  removeAlert,
  locked = {},
}: ClusterHealthAlertsTableProps) => {
  const [newWindow, setNewWindow] = useState('');
  const [newThreshold, setNewThreshold] = useState('');

  function handleAddAlert() {
    const newAlert = new ClusterHealthAlert({
      type: AlertTypes.Health,
      window: newWindow || locked.window,
      threshold:
        typeof locked.threshold === 'undefined' ? parseFloat(newThreshold) : locked.threshold,
    });
    createAlert(newAlert);
    setNewWindow('');
    setNewThreshold('');
  }

  return (
    <TableContainer data-test={'alerts-table'}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Window</TableCell>
            <TableCell>Threshold</TableCell>
            <TableCell>Add/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alerts.map((a) => (
            <TableRow key={a.id}>
              <TableCell>{a.window}</TableCell>
              <TableCell>{a.threshold}</TableCell>
              <TableCell>
                <IconButton data-test={'alert-delete'} onClick={() => removeAlert(a)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell data-test={'new-alert-window'}>
              {locked.window || (
                <TextField onChange={(e) => setNewWindow(e.target.value)} value={newWindow} />
              )}
            </TableCell>
            <TableCell data-test={'new-alert-threshold'}>
              {typeof locked.threshold === 'undefined' ? (
                <TextField onChange={(e) => setNewThreshold(e.target.value)} value={newThreshold} />
              ) : (
                locked.threshold
              )}
            </TableCell>
            <TableCell>
              <IconButton data-test={'new-alert-add'} onClick={handleAddAlert}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ClusterHealthAlertsTable.defaultProps = {
  locked: {},
};

export { ClusterHealthAlertsTable };
