import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Link from '@material-ui/core/Link';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';

// recharts
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

import { BarChartTooltipMaker } from '../../components/BarChartTooltip';
import { WithDataLoader } from '../../components/DataLoader';
import { primary } from '../../services/colors';
import { toCurrency } from '../../services/format';
import { model as Model } from '../../services/model';

const useStyles = makeStyles({
  actionButton: {
    color: '#2196f3',
  },
  actionLink: {
    marginLeft: 'auto',
  },
});

const BarChartWithLoading = WithDataLoader(BarChart);

const AllocationCard = ({ win }: { win: '1d' | '7d' }) => {
  const classes = useStyles();

  const [promise, setPromise] = useState<Promise<unknown> | null>(null);
  const [barData, setBarData] = useState<BarData[]>([]);
  const [barLabels, setBarLabels] = useState<Array<[string, string]>>([]);
  const [currency, setCurrency] = useState('USD');

  useEffect(() => {
    setPromise(fetchData());
  }, []);

  // resize chart when the containing element resizes
  const [chartWidth, setChartWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      const el = document.querySelector('[data-ref="cost-efficiency-card-wrapper"]');
      if (!el) {
        return;
      }
      const w = el.clientWidth - 25;
      setChartWidth(w);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const CustomTooltip = BarChartTooltipMaker(currency);

  return (
    <Card>
      <CardHeader
        subheader={`Cumulative Kubernetes cluster costs per namespace over the last ${
          win === '1d' ? 'day' : '7 days'
        }. Includes Network costs (if enabled).`}
        title={'Cost Allocation'}
      />
      <CardContent>
        <BarChartWithLoading
          data={barData}
          errorMessage={'Could not load cost allocation chart'}
          height={350}
          loadMessage={'Loading cost allocation chart'}
          margin={{ top: 30, right: 30, left: 30, bottom: 12 }}
          promise={promise}
          width={chartWidth}
        >
          <CartesianGrid strokeDasharray={'0'} vertical={false} />
          <XAxis dataKey={'start'} />
          <YAxis tickFormatter={(val: number) => toCurrency(val, currency, 0, true)} />
          <Tooltip content={<CustomTooltip />} />
          {barLabels.map(([key, color]) => (
            <Bar dataKey={key} fill={color} key={key} stackId={'a'} />
          ))}
        </BarChartWithLoading>
      </CardContent>
      <CardActions>
        <Link
          className={classes.actionLink}
          component={RouteLink}
          to={'/allocations?agg=namespace&chartDisplay=series'}
        >
          <Button className={classes.actionButton} endIcon={<KeyboardArrowRightIcon />}>
            Cost Allocation
          </Button>
        </Link>
      </CardActions>
    </Card>
  );

  async function fetchData() {
    // fetch allocation and model config data
    const [allocationResponse, modelConfig] = await Promise.all([
      Model.getAllocationSummary(win, 'namespace', {
        accumulate: false,
        external: 'false',
        shareTenancyCosts: true,
      }),
      Model.getConfigs(),
    ]);
    const allocationSetRange = allocationResponse.data.sets;

    // create bar labels for the n most-expensive namespaces over time, plus a label for "other"
    const maxIndividualNamespaces = 8;
    const data: { [index: string]: number | string; other: number }[] = [];
    const keyToColor: { [index: string]: string } = {};
    let i = 0;
    let useOther = false;
    allocationSetRange.forEach((set) => {
      const allocationSet = set.allocations;
      const entry: { [index: string]: number | string; other: number } = {
        other: 0,
      };
      const sorted = Object.entries(allocationSet).sort((a, b) =>
        Model.getSummaryTotalCost(a[1]) > Model.getSummaryTotalCost(b[1]) ? -1 : 1,
      );
      const idle = sorted.find(([name]) => name === '__idle__');
      const active = sorted.filter(([name]) => name !== '__idle__');
      const top = active.slice(0, maxIndividualNamespaces);
      const other = active.slice(maxIndividualNamespaces);
      top.forEach(([key, allocation]) => {
        entry[key] = Model.getSummaryTotalCost(allocation);
        entry.start = set.window.start.split('T')[win === '1d' ? 1 : 0];
        if (!keyToColor[key]) {
          keyToColor[key] = primary[i % primary.length];
          i += 1;
        }
      });
      other.forEach(([, allocation]) => {
        entry.other += Model.getSummaryTotalCost(allocation);
      });
      if (idle) {
        entry.__idle__ = Model.getSummaryTotalCost(idle[1]) - idle[1].networkCost;
        useOther = useOther || !!other.length; // if any timepoint has an "other", then make a label for "other"
        data.push(entry);
      }
    });
    keyToColor.__idle__ = '#e0e0e0';
    if (useOther) {
      keyToColor.other = primary[i % primary.length];
    }
    const bls = Object.entries(keyToColor).sort(([key1], [key2]) => {
      if (key1 === '__idle__') {
        return 1;
      }
      if (key2 === '__idle__') {
        return -1;
      }
      return key1 > key2 ? -1 : 1;
    });
    setBarLabels(bls);
    setBarData(data);
    setCurrency(modelConfig.currencyCode || 'USD');
  }
};

export default AllocationCard;

interface BarData {
  [index: string]: string | number;
}
