import { ChangeEvent, memo, useState } from 'react';

import get from 'lodash/get';

import TableSortLabel from '@material-ui/core/TableSortLabel';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

import { useClusters } from '../../../contexts/ClusterConfig';
import { toCurrency } from '../../../services/format';
import { APICloudCostTableDatum } from '../../../types/cloudCosts';

import { CloudCostRow } from './CloudCostRow';
import { TablePagination } from './TablePagination';

// descendingComparator provides a comparator for stableSort, which compares
// the given orderBy column of two rows, a and b. Due to the design of getCost,
// whereby the complete value of a "cost" column is actually the cost plus the
// associated adjustment (e.g. cpuCost = cpuCost + cpuCostAdjustment) the
// getCost function must be called here for "cost" columns. Kind of a hacky
// solution, but it's the simplest way to fix sorting. See the complete
// discussion: https://github.com/kubecost/cost-analyzer-frontend/issues/301
function descendingComparator(
  a: APICloudCostTableDatum,
  b: APICloudCostTableDatum,
  orderBy: string,
) {
  if (get(b, orderBy, 0) < get(a, orderBy, 0)) {
    return -1;
  }
  if (get(b, orderBy, 0) > get(a, orderBy, 0)) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: APICloudCostTableDatum, b: APICloudCostTableDatum) => descendingComparator(a, b, orderBy)
    : (a: APICloudCostTableDatum, b: APICloudCostTableDatum) =>
        -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<APICloudCostTableDatum>,
  comparator: (a: APICloudCostTableDatum, b: APICloudCostTableDatum) => number,
) {
  const stabilizedThis: [APICloudCostTableDatum, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort(
    (a: [APICloudCostTableDatum, number], b: [APICloudCostTableDatum, number]) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    },
  );
  return stabilizedThis.map((el) => el[0]);
}

interface CloudCostTableProps {
  rate: string;
  tableData: APICloudCostTableDatum[];
  totalsRow: APICloudCostTableDatum;
}

const CloudCostTable = memo(({ rate, tableData, totalsRow }: CloudCostTableProps) => {
  const { modelConfig } = useClusters();
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('totalCost');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      label: 'Name',
      width: 'auto',
    },
    { id: 'credits', numeric: true, label: 'Credits', width: 90 },
    {
      id: 'kubernetesPercent',
      numeric: true,
      label: 'K8 Utilization',
      width: 160,
    },
    { id: 'totalCost', numeric: true, label: 'Total cost', width: 155 },
  ];

  const orderedRows = stableSort(tableData, getComparator(order, orderBy));
  const pageRows = orderedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleOnChangePaginationOption = (event: ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleOnClickNextButton = (page: number) => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleOnClickPrevButton = (page: number) => {
    setPage((prevPage) => prevPage - 1);
  };

  const dataToCloudCostRow = (row: APICloudCostTableDatum) => {
    const suffix = { hourly: '/hr', monthly: '/mo', daily: '/day' }[rate] || '';
    return (
      <CloudCostRow
        costSuffix={suffix}
        credit={row.credit}
        key={row.name}
        kubernetesPercent={row.kubernetesPercent}
        name={row.name}
        totalCost={row.totalCost ?? 0}
      />
    );
  };

  return (
    <>
      <div className={'overflow-x-auto'}>
        <Table className={'w-full'}>
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <TableHeadCell
                  align={cell.numeric ? 'right' : 'left'}
                  key={cell.id}
                  style={{
                    width: cell.width,
                    paddingRight: cell.id === 'totalCost' ? '2em' : '',
                  }}
                >
                  <TableSortLabel
                    active={orderBy === cell.id}
                    direction={orderBy === cell.id ? order : 'asc'}
                    onClick={() => {
                      const isDesc = orderBy === cell.id && order === 'desc';
                      setOrder(isDesc ? 'asc' : 'desc');
                      setOrderBy(cell.id);
                    }}
                  >
                    {cell.label}
                  </TableSortLabel>
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align={'left'} style={{ fontWeight: 500 }}>
                {totalsRow.name}
              </TableCell>
              <TableCell align={'right'} style={{ fontWeight: 500 }}>
                {toCurrency(totalsRow.credit || 0, modelConfig.currencyCode)}
              </TableCell>
              <TableCell align={'right'} style={{ fontWeight: 500 }}>
                {Math.round((totalsRow.kubernetesPercent || 0) * 100).toFixed(1)}%
              </TableCell>
              <TableCell align={'right'} style={{ fontWeight: 500, paddingRight: '2em' }}>
                {toCurrency(totalsRow.totalCost || 0, modelConfig.currencyCode)}
              </TableCell>
            </TableRow>
            {pageRows.map(dataToCloudCostRow)}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        currentPage={page}
        onChangeRowOptions={handleOnChangePaginationOption}
        onClickNextButton={handleOnClickNextButton}
        onClickPrevButton={handleOnClickPrevButton}
        selectedRowOption={rowsPerPage}
        totalRows={
          /* add 1 to account for "Totals" row */
          tableData.length + 1
        }
      />
    </>
  );
});

export { CloudCostTable };
