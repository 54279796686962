import { round } from 'lodash';

import { Chip, Typography } from '@kubecost-frontend/holster';

import { greyscale } from '../../services/colors';
import { toCurrency } from '../../services/format';

interface InstanceBreakdownCellProps {
  currency?: string;
  discount?: number;
  isExpanded: boolean;
  nodes: any;
}

interface CellDisplayElementProps {
  title: string;
  value: string;
}
const CellDisplayElement = ({ title, value }: CellDisplayElementProps) => (
  <div>
    <Typography className={'font-bold'} variant={'p-large'}>
      {title}
    </Typography>
    <Typography variant={'p'}>{value}</Typography>
  </div>
);

export const CurrentInstanceBreakdownCell = ({ isExpanded, nodes }: InstanceBreakdownCellProps) => {
  const firstNode = nodes[0];
  return (
    <div>
      {isExpanded ? (
        <div>
          {nodes.map((node, i) => (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}>
                <Chip color={'primary'} label={node.count} />
                <Typography variant={'p'}>{node.name}</Typography>
                <Chip color={'primary'} label={node.architecture === 'ARM' ? 'ARM' : 'x86'} />
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  marginTop: '.5em',
                  marginBottom: '.5em',
                }}
              >
                <CellDisplayElement title={'VCPUs'} value={`${round(node.vCPUs, 2)} VCPUs ea.`} />
                <CellDisplayElement title={'RAM'} value={`${round(node.RAMGB, 2)} RAM (GB) ea.`} />
                <CellDisplayElement title={'Cost'} value={'n/a'} />
              </div>
              <div
                style={{
                  height: 1,
                  width: '100%',
                  backgroundColor: greyscale[0],
                  margin: '1em',
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}>
          <Chip color={'primary'} label={firstNode.count} />
          <Typography variant={'p'}>
            {firstNode.name} {nodes.length > 1 && <strong>{` + ${nodes.length - 1} more`}</strong>}
          </Typography>
        </div>
      )}
    </div>
  );
};

export const RecommendedInstanceBreakdownCell = ({
  currency,
  discount,
  isExpanded,
  nodes,
}: InstanceBreakdownCellProps) => {
  const firstNode = nodes[0];
  return (
    <div>
      {isExpanded ? (
        <div>
          {nodes.map((node, i) => (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}>
                <Chip color={'primary'} label={node.count} />
                <Typography variant={'p'}>{node.type.name}</Typography>
                <Chip color={'primary'} label={node.type.architecture === 'ARM' ? 'ARM' : 'x86'} />
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  marginTop: '.5em',
                  marginBottom: '.5em',
                }}
              >
                <CellDisplayElement
                  title={'VCPUs'}
                  value={`${round(node.type.vCPUs, 2)} VCPUs ea.`}
                />
                <CellDisplayElement
                  title={'RAM'}
                  value={`${round(node.type.RAMGB, 2)} RAM (GB) ea.`}
                />
                <CellDisplayElement
                  title={'Cost'}
                  value={`${toCurrency(
                    round(730.0 * (1.0 - discount!) * node.type.hourlyPrice, 2),
                    currency!,
                  )}/mo ea.`}
                />
              </div>
              <div
                style={{
                  height: 1,
                  width: '100%',
                  backgroundColor: greyscale[0],
                  margin: '1em',
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}>
          <Chip color={'primary'} label={firstNode.count} />
          <Typography variant={'p'}>
            {firstNode.type.name}{' '}
            {nodes.length > 1 && <strong>{` + ${nodes.length - 1} more`}</strong>}
          </Typography>
        </div>
      )}
    </div>
  );
};
