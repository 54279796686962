import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AssessmentIcon from '@material-ui/icons/Assessment';

import { colorMap } from '../../services/colors';

interface CreateNewReportModalProps {
  createNewReportFromTemplate: (templateName: string) => void;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

interface TemplateCardProps {
  Icon: any;
  createNewReport: (templateName: string) => void;
  dashed: boolean;
  description: string;
  title: string;
}

const TemplateCard = ({ Icon, createNewReport, dashed, description, title }: TemplateCardProps) => (
  <div
    onClick={() => createNewReport(title)}
    style={{
      width: 600,
      display: 'grid',
      gridTemplateColumns: '3em 1fr',
      padding: '2em',
      cursor: 'pointer',
      border: dashed ? '2px dashed rgba(0,0,0,0.3)' : '1px solid rgba(0,0,0,0.3)',
      borderRadius: '0.5em',
    }}
  >
    <Icon style={{ fontSize: 40, color: colorMap.blue }} />
    <Typography style={{ color: colorMap.blue }} variant={'h4'}>
      {title}
    </Typography>
    <div />
    <div>
      <Typography variant={'body2'}>{description}</Typography>
    </div>
  </div>
);

export const CreateNewReportModal = ({
  createNewReportFromTemplate,
  isOpen,
  setIsOpen,
}: CreateNewReportModalProps) => (
  <Dialog maxWidth={'lg'} onClose={setIsOpen} open={isOpen} fullWidth>
    <DialogContent>
      <div
        style={{
          display: 'grid',
          gap: '3em',
          gridTemplateColumns: '1fr 1fr',
          padding: '2em',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateRows: '1fr 40px',
          }}
        >
          <div>
            <Typography variant={'h4'} gutterBottom>
              New Report
            </Typography>
            <Typography variant={'body2'}>
              Not sure where to get started? Try one of our example templates to hit the ground
              running. Or, choose a blank report for a fresh start.
            </Typography>
          </div>
          <Button color={'primary'} onClick={() => setIsOpen(false)} variant={'contained'}>
            Cancel
          </Button>
        </div>

        <div
          style={{
            display: 'grid',
            gap: '2em',
          }}
        >
          <TemplateCard
            Icon={AssessmentIcon}
            createNewReport={createNewReportFromTemplate}
            dashed={false}
            description={
              'Basic breakdown that shows you the costs incurred by kuberentes namespace, and joined together with assets. '
            }
            title={'K8s Allocation Report + Assets by Service'}
          />
          <TemplateCard
            Icon={AssessmentIcon}
            createNewReport={createNewReportFromTemplate}
            dashed={false}
            description={
              'An example that filters to a specific namespace and shared asset cost to submit for internal chargeback.'
            }
            title={'Chargeback Report'}
          />
          <TemplateCard
            Icon={AddIcon}
            createNewReport={createNewReportFromTemplate}
            description={'Starting fresh with Allocations and Assets for all resources.'}
            title={'Blank'}
            dashed
          />
        </div>
      </div>
    </DialogContent>
  </Dialog>
);
