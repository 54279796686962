import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ExportIcon from '@material-ui/icons/GetApp';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import { memo } from 'react';

const columns = [
  {
    head: 'Container',
    prop: 'name',
  },
  {
    head: 'Cluster',
    prop: 'cluster',
  },
  {
    head: 'CPU Cores Used',
    prop: 'usage.cpuCores',
  },
  {
    head: 'CPU Cores Requested',
    prop: 'requests.cpuCores',
  },
  {
    head: 'CPU Cores Recommended',
    prop: 'target.cpuCores',
  },
  {
    head: 'RAM Usage Bytes',
    prop: 'usage.ramBytes',
  },
  {
    head: 'RAM Request Bytes',
    prop: 'requests.ramBytes',
  },
  {
    head: 'RAM Recommended Bytes',
    prop: 'target.ramBytes',
  },
  {
    head: 'Efficiency',
    prop: 'efficiency',
  },
  {
    head: 'Savings',
    prop: 'monthlySavings',
  },
];

const toCSVLine = (datum) => {
  const cols = [];

  forEach(columns, (c) => {
    switch (c.prop) {
      case 'name':
        // recreate name from ContainerRow
        const name = get(datum, 'controller.name', '')
          ? `${get(datum, 'controller.namespace', '')}/${get(datum, 'controller.name', '')}:${get(
              datum,
              c.prop,
              '',
            )}`
          : `${get(datum, 'namespace', '')}/${get(datum, c.prop, '')}`;

        cols.push(`"${name}"`);
        break;

      // Formatting of cpu/ram values relies on appending a unit depending on the rounding, potentially
      // leading to a scenario where there are two or more numbers with different units in the same
      // column. Thus, all cpu/ram values are left unchanged in the csv.
      // CPU values are in whole Kubernetes CPU units
      // RAM values are in GiB

      case 'monthlySavings':
        cols.push(round(`${get(datum, c.prop, 0.0)}`, 2));
        break;

      default:
        cols.push(`${get(datum, c.prop, 0.0)}`);
    }
  });

  return cols.join(',');
};

const DownloadControl = ({ cumulativeData, title }) => {
  // downloadReport downloads a CSV of the cumulative allocation data
  function downloadReport() {
    // Build CSV
    const head = columns.map((c) => c.head).join(',');
    const body = sortBy(cumulativeData, 'monthySavings').map(toCSVLine).join('\r\n');
    const csv = `${head}\r\n${body}`;

    // Create download link
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
    const filename = title.toLowerCase().replace(/\s/gi, '-');
    a.setAttribute('download', `${filename}-${Date.now()}.csv`);

    // Click the link
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <Tooltip title={'Download CSV'}>
      <IconButton onClick={downloadReport}>
        <ExportIcon />
      </IconButton>
    </Tooltip>
  );
};

export default memo(DownloadControl);
