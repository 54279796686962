import React, { createContext, useContext, useEffect, useState } from 'react';
import { ProductTier } from '../hooks/useProductTier';
import { getCurrentContainerAddressModel } from '../services/util';
import { ClusterContextProvider } from './ClusterConfig';

const unimplementedFunction = (name: string) => {
  console.warn('Hosted unimplementedFunction: ' + name);
};

export const useHostedContextProvider = (): ClusterContextProvider => {
  const baseApiUrl = getCurrentContainerAddressModel();

  useEffect(() => {}, []);

  return {
    loading: false,
    data: {
      clusterConfig: () => unimplementedFunction('clusterConfig'),
      envConfig: () => unimplementedFunction('envConfig'),
      apiConfig: () => unimplementedFunction('apiConfig'),
      modelConfig: () => unimplementedFunction('modelConfig'),
      config: () => unimplementedFunction('modelConfig'),
      apiVersion: 'HOSTED',
      baseApiUrl: 'HOSTED',
      thanosQueryUrl: '',
      promQueryUrl: '',
      promRangeQueryUrl: '',
      grafanaUrl: '',
      localClusterEndpoints: ['http://app.test.kubecost.io'],
      remoteClusterEndpoints: [],
      activeContext: 'http://app.test.kubecost.io',
      licenseConfig: {
        productKey: {
          endDate: 9999999999,
          startDate: 0,
          remaining: 99999999,
          tier: ProductTier.Enterprise,
          key: 'hosted',
        },
        trialRemaining: 0,
        usedTrial: true,
      },
      addContext: () => unimplementedFunction('modelConfig'),
      removeContext: () => unimplementedFunction('modelConfig'),
      activateContext: () => unimplementedFunction('modelConfig'),
    },
  };
};
