import { Button, Modal, Typography } from '@kubecost-frontend/holster';
import { StringNullableChain } from 'lodash';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { APIClient } from '../../../services/APIClient';
import { useHosted } from '../../../hooks/useHosted';
import { hostedTeamsQueryKey } from '../hooks.ts/api';

type DeleteTeamInvitationProps = {
  invitationId: number;
  email: string;
  teamId: string;
};

const DeleteTeamInvitation = ({ invitationId, teamId, email }: DeleteTeamInvitationProps) => {
  const queryClient = useQueryClient();
  const { config } = useHosted();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const { mutate } = useMutation({
    mutationFn: () => {
      return APIClient.delete(
        `${config?.userServiceAPIUrl}/api/teams/${teamId}/invite/${invitationId}`,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: hostedTeamsQueryKey(teamId),
      });
    },
  });

  const removeInvite = () => {
    mutate();
    setConfirmationOpen(false);
  };

  return (
    <>
      <a
        onClick={() => setConfirmationOpen(true)}
        className='text-xs text-kc-danger hover:cursor-pointer hover:underline'
      >
        Cancel Invite
      </a>
      <Modal
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        className='w-48'
        title='Cancel Invite'
      >
        <Typography variant='p'>
          Are you sure you want to cancel the invite to <b>{email}</b>?
        </Typography>
        <div className='flex gap-4'>
          <div className='w-full flex-grow'></div>
          <Button onClick={() => setConfirmationOpen(false)} variant='default'>
            Cancel
          </Button>
          <Button onClick={removeInvite} variant='primary'>
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteTeamInvitation;
