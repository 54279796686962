import { useState } from 'react';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const ReportCardOverflowMenu = ({ deleteItem }: { deleteItem: () => void }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDelete = (event) => {
    handleClose(event);
    deleteItem();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id={'simple-menu'}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        keepMounted
      >
        <MenuItem onClick={handleDelete}>Delete Report</MenuItem>
      </Menu>
    </div>
  );
};
