import { useReducer } from 'react';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { Button } from '@kubecost-frontend/holster';

import { Alert, AlertService } from '../../services/alerts';

interface TestAlertButtonProps {
  alert: Alert;
  validate?: () => Promise<boolean>;
}

type TestStatusAction =
  | { type: 'test' }
  | { message: string; type: 'testFail' }
  | { type: 'testSuceeded' }
  | { type: 'reset' };

type TestStatusState = {
  checkFailed: boolean;
  checkSucceeded: boolean;
  failureMessage: string;
  loading: boolean;
};

const initialTestStatusState: TestStatusState = {
  checkSucceeded: false,
  checkFailed: false,
  failureMessage: '',
  loading: false,
};

const TestAlertButton = ({ alert, validate }: TestAlertButtonProps) => {
  const [{ checkFailed, checkSucceeded, failureMessage, loading }, dispatch] = useReducer(
    (state: TestStatusState, action: TestStatusAction): TestStatusState => {
      switch (action.type) {
        case 'test':
          return {
            ...state,
            checkFailed: false,
            checkSucceeded: false,
            failureMessage: '',
            loading: true,
          };
        case 'testFail':
          return {
            ...state,
            checkFailed: true,
            checkSucceeded: false,
            failureMessage: action.message,
            loading: false,
          };
        case 'testSuceeded':
          return {
            ...state,
            checkFailed: false,
            checkSucceeded: true,
            failureMessage: '',
            loading: false,
          };
        case 'reset':
          return {
            ...state,
            checkFailed: false,
            checkSucceeded: false,
            failureMessage: '',
            loading: false,
          };
      }
    },
    initialTestStatusState,
  );

  const testClicked = async () => {
    if (validate !== undefined && (await validate()) === false) return;
    dispatch({ type: 'test' });
    const response = await AlertService.testAlert(alert);
    if (response.status === 204) {
      dispatch({
        type: 'testSuceeded',
      });
    } else {
      const message = await response.text();
      dispatch({
        type: 'testFail',
        message,
      });
    }
  };

  return (
    <>
      <Button
        data-testid={'test-alert-button'}
        disabled={loading}
        onClick={async () => {
          testClicked();
        }}
        variant={'default'}
      >
        {loading ? 'Testing...' : 'Test Alert'}
      </Button>

      <Snackbar
        autoHideDuration={4000}
        onClose={() => dispatch({ type: 'reset' })}
        open={checkFailed}
      >
        <MuiAlert
          data-testid={'test-alert-failure'}
          onClose={() => dispatch({ type: 'reset' })}
          severity={'error'}
        >
          {failureMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        autoHideDuration={4000}
        onClose={() => dispatch({ type: 'reset' })}
        open={checkSucceeded}
      >
        <MuiAlert
          data-testid={'test-alert-success'}
          onClose={() => dispatch({ type: 'reset' })}
          severity={'success'}
        >
          Alert sent successfully!
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default TestAlertButton;
