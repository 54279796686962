import { Box, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import { colorMap, primary } from '../../../services/colors';
import { toCurrency } from '../../../services/format';

interface CostBreakdownTileProps {
  currency: string;
  sortedTypesByCost: { dataString: string; label: string; value: number }[];
  tooltipMessage: string;
  totalCost: number;
}

const renderDot = (color: string) => (
  <div
    style={{ height: 10, width: 10, borderRadius: 10, backgroundColor: color, marginRight: '.5em' }}
  />
);

export const CostBreakdown = ({
  currency,
  sortedTypesByCost,
  tooltipMessage,
  totalCost,
}: CostBreakdownTileProps) => (
  <Box
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      textAlign: 'center',
      padding: '1em',
    }}
  >
    <div>
      <div
        style={{
          display: 'flex',
          fontSize: 22,
          marginBottom: '.5em',
          alignItems: 'center',
        }}
      >
        {renderDot(colorMap.indigo)}
        <strong>Cost </strong>
      </div>
      <div style={{ fontSize: 34 }}>
        <strong>{toCurrency(totalCost || 0, currency)}</strong>
      </div>
    </div>
    {sortedTypesByCost.map(({ label, value }, idx: any) =>
      label === 'Shared' ? (
        <div key={'shared'}>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: 18, marginBottom: '1em' }}>
            {renderDot(primary[idx])}
            <div>Shared</div>
            <Tooltip placement={'right'} title={tooltipMessage}>
              <HelpIcon style={{ fontSize: 14, color: 'gray', marginLeft: 5 }} />
            </Tooltip>
          </div>
          <div
            style={{
              fontSize: 28,
            }}
          >
            <strong>{toCurrency(value || 0, currency)}</strong>
          </div>
        </div>
      ) : (
        <div key={label}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 18,
              marginBottom: '1em',
            }}
          >
            {renderDot(primary[idx])}
            <div>{label}</div>
          </div>
          <div style={{ fontSize: 28 }}>
            <strong>{toCurrency(value || 0, currency)}</strong>
          </div>
        </div>
      ),
    )}
  </Box>
);
