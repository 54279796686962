import { Link, Typography } from '@material-ui/core';

import useProductTier from '../../hooks/useProductTier';

import { ThanosCheck } from './ThanosCheck';

export const DurableStorage = () => {
  const isProductTier = useProductTier();

  return (
    <div>
      <Typography style={{ marginBottom: '1.5em' }} variant={'h5'}>
        <strong>Durable Storage</strong>
      </Typography>
      {isProductTier.Enterprise ? (
        <ThanosCheck />
      ) : (
        <Typography style={{ marginBottom: '1em' }} variant={'h6'}>
          Product upgrade required to enable long-term metric retention.
          <Link href={'https://docs.kubecost.com/enterprise#long-term-metric-retention'}>
            {' '}
            Learn more
          </Link>
        </Typography>
      )}
    </div>
  );
};
