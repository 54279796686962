// types
import { toCurrency } from '../../services/format';

import { RequestSizingRecommendation, RequestSizingResponse } from './types';

function sumNonNegativeSavings(recs: RequestSizingResponse): number {
  const precision = 2;
  const roundingFactor = 10 ** precision;
  return recs
    .filter((rec) => !!rec.monthlySavings) // this was blowing up as some recommendations didn't have monthly savings
    .map((rec) => Object.values(rec.monthlySavings).reduce((a, b) => a + b, 0)) // get total savings for each rec
    .filter((totalSavings) => totalSavings > 0) // drop recs that increase spend
    .map((savings) => Math.round(savings * roundingFactor) / roundingFactor) // round savings to their displayed values
    .reduce((a, b) => a + b, 0); // sum these savings values for total positive savings
}

function formatFilters(filters?: { equality: string; property: string; value: string }[]) {
  const filterMap = {};
  filters?.forEach(
    ({ equality, property, value }: { equality: string; property: string; value: string }) => {
      const mapKey = `${property}${equality}`;
      if (filterMap[mapKey]) {
        filterMap[mapKey] = [...filterMap[mapKey], `"${value}"`];
      } else {
        filterMap[mapKey] = [`"${value}"`];
      }
    },
  );

  const newStringArray = Object.keys(filterMap).map(
    (key: string) => `${key}${filterMap[key].join(',')}`,
  );

  return newStringArray.join('+');
}

// The CPU and Ram parse are from https://github.com/etiennedi/kubernetes-resource-parser and modified
// Returns 0 for unparsable strings and falsy values
function parseCpuToCores(input?: string): number {
  if (!input) {
    return 0;
  }
  try {
    const milliMatch = input.match(/^([0-9]+)m$/);
    if (milliMatch) {
      return parseInt(milliMatch[1], 10) / 1000.0;
    }

    return parseFloat(input);
  } catch (err) {
    return 0;
  }
}

const memoryMultipliers = {
  k: 1000,
  M: 1000 ** 2,
  G: 1000 ** 3,
  T: 1000 ** 4,
  P: 1000 ** 5,
  E: 1000 ** 6,
  Ki: 1024,
  Mi: 1024 ** 2,
  Gi: 1024 ** 3,
  Ti: 1024 ** 4,
  Pi: 1024 ** 5,
  Ei: 1024 ** 6,
};

// Returns 0 for unparsable strings and falsy values
function parseMemoryToBytes(input?: string): number {
  if (!input) {
    return 0;
  }
  try {
    const unitMatch = input.match(/^([0-9]+)([A-Za-z]{1,2})$/);
    if (unitMatch) {
      return parseInt(unitMatch[1], 10) * memoryMultipliers[unitMatch[2]];
    }

    return parseInt(input, 10);
  } catch (err) {
    return 0;
  }
}

const recCurrency = (recommendation: RequestSizingRecommendation, currency: string) =>
  // This isn't a great solution, but I needed to move quickly for a
  // patch without adding proper error handling logic for the page.
  recommendation.monthlySavings != null &&
  toCurrency(recommendation.monthlySavings.cpu + recommendation.monthlySavings.memory, currency);

const mebibyteFactor = memoryMultipliers.Mi;

export {
  formatFilters,
  mebibyteFactor,
  parseCpuToCores,
  parseMemoryToBytes,
  recCurrency,
  sumNonNegativeSavings,
};
