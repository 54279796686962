import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Tooltip,
  Grid,
} from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CategoryIcon from '@material-ui/icons/Category';
import CloudIcon from '@material-ui/icons/Cloud';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import FolderIcon from '@material-ui/icons/Folder';
import ClusterIcon from '@material-ui/icons/GroupWork';
import MapIcon from '@material-ui/icons/Map';
import NodeIcon from '@material-ui/icons/Memory';
import StorageIcon from '@material-ui/icons/SdStorage';
import get from 'lodash/get';
import round from 'lodash/round';
import { memo, useEffect, useState } from 'react';
import * as React from 'react';

import { captureError } from '../../services/error_reporting';
import { toCurrency } from '../../services/format';
import { model } from '../../services/model';
import { Asset, DiskAsset, NodeAsset } from '../../types/asset';

type NodeAssetDetailsProp = {
  currency: string;
  hours: number;
  nodeAsset: NodeAsset;
};

const NodeAssetDetails = ({ currency, hours, nodeAsset }: NodeAssetDetailsProp) => {
  const { cpuCores, cpuCost, gpuCost, gpuCount, ramBytes, ramCost } = nodeAsset;
  let { discount } = nodeAsset;

  discount = 1 - discount;

  let costPerCpuPerHour = 0;
  let costPerRamGBHour = 0;
  let costPerGPUHour = 0;
  const ramGB = ramBytes / 1024 / 1024 / 1024;
  if (hours !== 0) {
    // if hours or cpuCores is 0, then either we're getting bad data, or the cost is also 0
    // for now, just show 0 (not sure what to show in event that data is just wrong).
    if (cpuCores !== 0) {
      costPerCpuPerHour = (cpuCost / (cpuCores * hours)) * discount;
    } else if (cpuCost) {
      console.warn('cpuCost is nonzero, but core count is 0');
    }

    // if hours or ramGB is 0, then either we're getting bad data, or the cost is also 0
    // for now, just show 0 (not sure what to show in event that data is just wrong).
    if (ramGB !== 0) {
      costPerRamGBHour = (ramCost / (ramGB * hours)) * discount;
    } else if (ramCost) {
      console.warn('ramCost is nonzero, but ramBytes is 0');
    }

    if (gpuCount !== 0) {
      costPerGPUHour = gpuCost / (gpuCount * hours);
    } else if (ramCost) {
      console.warn('gpuCost is nonzero, but gpuCount is 0');
    }
  }
  return (
    <>
      <Box p={1} />
      <Typography variant={'h6'}>Node Breakdown</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align={'left'} component={'th'} scope={'row'} width={200}>
              Resource
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              Amount
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              Hourly Rate
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              Cost
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align={'left'} component={'th'} scope={'row'} width={200}>
              CPU
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              {cpuCores} Cores
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              {toCurrency(costPerCpuPerHour, currency, 3)}/CoreHr
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              {toCurrency(cpuCost * discount, currency, 2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align={'left'} component={'th'} scope={'row'} width={200}>
              RAM
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              {round(ramGB, 3)} GiB
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              {toCurrency(costPerRamGBHour, currency, 3)}/GiBHr
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              {toCurrency(ramCost * discount, currency, 2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align={'left'} component={'th'} scope={'row'} width={200}>
              GPU
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              {gpuCount} GPU
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              {toCurrency(costPerGPUHour, currency, 3)}/GPUHR
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              {toCurrency(gpuCost, currency, 2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

type DiskAssetDetailsProp = {
  currency: string;
  diskAsset: DiskAsset;
  hours: number;
};

const DiskAssetDetails = ({ currency, diskAsset, hours }: DiskAssetDetailsProp) => {
  const totalCost = get(diskAsset, 'totalCost', 0);
  const bytes = get(diskAsset, 'bytes', 0);

  let costPerGBHour = 0;
  const gb = bytes / 1024 / 1024 / 1024;
  if (hours !== 0) {
    // if hours or ramGB is 0, then either we're getting bad data, or the cost is also 0
    // for now, just show 0 (not sure what to show in event that data is just wrong).
    if (gb !== 0) {
      costPerGBHour = totalCost / (gb * hours);
    } else if (totalCost !== 0) {
      console.warn('totalCost is nonzero, but bytes is 0');
    }
  }
  return (
    <>
      <Box p={1} />
      <Typography variant={'h6'}>Disk Breakdown</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align={'left'} component={'th'} scope={'row'}>
              Space
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              Hourly Rate
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              Cost
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align={'left'} component={'th'} scope={'row'}>
              {round(gb, 3)} GiB
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              {toCurrency(costPerGBHour, currency, 3)}/GiBHr
            </TableCell>
            <TableCell align={'right'} component={'th'} scope={'row'}>
              {toCurrency(totalCost, currency, 2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

type AssetListItemProps = {
  icon: React.ReactElement;
  tooltip: string;
  value: string;
};

const AssetPropertiesItem = ({ icon, tooltip, value }: AssetListItemProps) => (
  <Grid xs={4} item>
    <ListItem>
      <ListItemIcon>
        <Tooltip placement={'bottom'} title={tooltip}>
          {icon}
        </Tooltip>
      </ListItemIcon>
      <ListItemText primary={value} />
    </ListItem>
  </Grid>
);

type DetailsProps = {
  asset: Asset;
  currency: string;
  window: string;
};

const Details = ({ asset, currency, window }: DetailsProps) => {
  const [fetch, setFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState(0);
  const [costPerHr, setCostPerHr] = useState(0);
  const [accAsset, setAccAsset] = useState<Asset>();
  const properties = get(asset, 'properties', {});
  const category = get(properties, 'category', '');
  const cluster = get(properties, 'cluster', '');
  const provider = get(properties, 'provider', '');
  const account = get(properties, 'account', '');
  const project = get(properties, 'project', '');
  const region = get(properties, 'region', '');
  const providerID = get(properties, 'providerID', '');
  const service = get(properties, 'service', '');
  const type = get(asset, 'type', '');
  let storageClass = '';
  let nodeType = '';

  if (type === 'Node') {
    nodeType = get(asset, 'nodeType', '');
    if (!nodeType) {
      nodeType = 'Unknown';
    }
  }

  if (type === 'Disk') {
    storageClass = get(asset, 'storageClass', '');
    if (!storageClass) {
      storageClass = 'Unknown';
    }
  }

  useEffect(() => {
    if (fetch) {
      fetchData();
    }
  }, [fetch]);

  async function fetchData() {
    setLoading(true);

    const filterProps: Record<string, string> = {
      category,
      cluster,
      providerID,
      service,
      type,
    };
    const filters = getRequestFilters(filterProps);

    try {
      const resp = await model.getAssets(window, {
        filters,
        accumulate: true,
      });
      // After filtering and accumulation there should only be one asset in return
      if (resp.data && resp.data.length > 0) {
        const rowData: Record<string, Asset> = resp.data[0];
        const rowKeys = Object.keys(rowData);
        if (rowKeys.length > 0) {
          setAccAsset(rowData[rowKeys[0]]);
          const hrs = get(rowData[rowKeys[0]], 'minutes', 0) / 60.0;
          setHours(hrs);
          if (hrs !== 0) {
            setCostPerHr(rowData[rowKeys[0]].totalCost / hrs);
          }
        }
      }
      setLoading(false);
      setFetch(false);
    } catch (err) {
      console.warn(err);
      captureError(err as Error);
    }
  }

  function getRequestFilters(props: Record<string, string>) {
    return Object.entries(props)
      .filter((entry) => entry[1])
      .map(([property, value]) => ({ property, value }));
  }

  if (loading) {
    return <>Loading...</>;
  }

  if (accAsset) {
    return (
      <>
        <Grid container>
          {cluster && (
            <AssetPropertiesItem icon={<ClusterIcon />} tooltip={'Cluster'} value={cluster} />
          )}
          {provider && (
            <AssetPropertiesItem icon={<CloudIcon />} tooltip={'Provider'} value={provider} />
          )}
          {account && (
            <AssetPropertiesItem icon={<AccountBoxIcon />} tooltip={'Account'} value={account} />
          )}
          {project && (
            <AssetPropertiesItem icon={<FolderIcon />} tooltip={'Project'} value={project} />
          )}
          {service && (
            <AssetPropertiesItem
              icon={<DesktopWindowsIcon />}
              tooltip={'Service'}
              value={service}
            />
          )}
          {category && (
            <AssetPropertiesItem icon={<CategoryIcon />} tooltip={'Category'} value={category} />
          )}
          {region && <AssetPropertiesItem icon={<MapIcon />} tooltip={'Region'} value={region} />}
          {type === 'Node' && nodeType && (
            <AssetPropertiesItem icon={<NodeIcon />} tooltip={'Node Type'} value={nodeType} />
          )}
          {type === 'Disk' && storageClass && (
            <AssetPropertiesItem
              icon={<StorageIcon />}
              tooltip={'Storage Class'}
              value={storageClass}
            />
          )}
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align={'left'} component={'th'} scope={'row'} width={200}>
                Hours
              </TableCell>
              <TableCell align={'right'} component={'th'} scope={'row'}>
                Discount
              </TableCell>
              <TableCell align={'right'} component={'th'} scope={'row'}>
                Adjustment
              </TableCell>
              <TableCell align={'right'} component={'th'} scope={'row'}>
                Hourly Rate
              </TableCell>
              <TableCell align={'right'} component={'th'} scope={'row'}>
                Total Cost
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align={'left'} component={'th'} scope={'row'} width={200}>
                {round(hours, 5)}
              </TableCell>
              <TableCell align={'right'} component={'th'} scope={'row'}>
                {get(accAsset, 'discount', 0)}
              </TableCell>
              <TableCell align={'right'} component={'th'} scope={'row'}>
                {toCurrency(get(accAsset, 'adjustment', 0), currency, 3)}
              </TableCell>
              <TableCell align={'right'} component={'th'} scope={'row'}>
                {toCurrency(costPerHr, currency, 3)}
              </TableCell>
              <TableCell align={'right'} component={'th'} scope={'row'}>
                {toCurrency(get(accAsset, 'totalCost', 0), currency, 2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {type === 'Node' && (
          <NodeAssetDetails currency={currency} hours={hours} nodeAsset={accAsset as NodeAsset} />
        )}
        {type === 'Disk' && (
          <DiskAssetDetails currency={currency} diskAsset={accAsset as DiskAsset} hours={hours} />
        )}
      </>
    );
  }

  return <TableContainer />;
};

export default memo(Details);
