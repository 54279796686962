import { memo, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Card, CardContent, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';

import { DiagnosticsChecker } from '../../components/DiagnosticsChecker';
import { Header } from '../../components/Header';
import { HelpIconLink } from '../../components/HelpIconLink';
import { colorMap } from '../../services/colors';
import { ReportConfig } from '../AdvancedReportingDetail/reportingHelpers';
import { sampleReportConfig } from '../AdvancedReportingDetail/sampleReportPayload';

import { CreateNewReportModal } from './CreateNewReportModal';
import { ReportCardOverflowMenu } from './ReportCardOverflowMenu';

export const advancedReportingLocalStorageKey = 'QBCostReports';

const dateCreatedValueList = [
  { name: 'All', key: 'all' },
  { name: 'Today', key: 'today' },
  { name: 'Yesterday', key: 'yesterday' },
  { name: 'This Week', key: 'thisweek' },
  { name: 'Last Week', key: 'lastweek' },
];

const AdvancedReporting = () => {
  const [currentReportList, setCurrentReportList] = useState<ReportConfig[]>([]);
  const [dateCreatedFilter, setDateCreatedFilter] = useState<string>();
  const [newReportModalOpen, setNewReportModalOpen] = useState<boolean>(false);
  const openNewReportModal = () => {
    setNewReportModalOpen(true);
  };
  const navigate = useNavigate();

  const newReportFromTemplate = (templateName: string) => {
    const existingReports = localStorage.getItem(advancedReportingLocalStorageKey);
    if (!existingReports) {
      // if none exist, we have a problem, should at least be an empty array
      console.log('no reports');
    } else if (JSON.parse(existingReports).length === 0) {
      const reportToUse = sampleReportConfig;
      // TEMP until we get good templates
      reportToUse.name = templateName;
      localStorage.setItem(advancedReportingLocalStorageKey, JSON.stringify([reportToUse]));
      setCurrentReportList([reportToUse]);
      setNewReportModalOpen(false);
      handleExistingReportNavigation(reportToUse.id);
    } else {
      // we want to add to our list of reports
      const storedReports = JSON.parse(existingReports);
      const reportToUse = sampleReportConfig;
      // TEMP until we get good templates
      reportToUse.name = templateName;
      reportToUse.id = storedReports[storedReports.length - 1].id + 1;
      storedReports.push(reportToUse);
      localStorage.setItem(advancedReportingLocalStorageKey, JSON.stringify(storedReports));
      setCurrentReportList(storedReports);
      setNewReportModalOpen(false);
      handleExistingReportNavigation(reportToUse.id);
    }
  };

  const handleExistingReportNavigation = (reportId: number) => {
    navigate(`/advanced-reporting-detail?id=${reportId}`);
  };

  const deleteReport = (reportId: number) => {
    const existingReports = localStorage.getItem(advancedReportingLocalStorageKey);
    if (!existingReports) {
      // if none exist, then we have a problem
      // throw error
      console.log('no reports');
    } else {
      // we want to add to our list of reports
      const storedReports = JSON.parse(existingReports);
      const filteredReports = storedReports.filter((item: ReportConfig) => item.id !== reportId);
      localStorage.setItem(advancedReportingLocalStorageKey, JSON.stringify(filteredReports));
      setCurrentReportList(filteredReports);
    }
  };

  // It would be nice to have this for v1
  const ReportCard = ({ reportItem }: { reportItem: ReportConfig }) => (
    <Grid key={reportItem.id} style={{ margin: '1em' }} xs={3} item>
      <Card
        onClick={() => handleExistingReportNavigation(reportItem.id)}
        style={{
          cursor: 'pointer',
        }}
      >
        <CardContent>
          <Grid justifyContent={'space-between'} container>
            <Grid item xs>
              <Typography style={{ fontSize: 24 }}>{reportItem.name}</Typography>
              <Typography style={{ fontSize: 48 }}>$XXX.XK</Typography>
            </Grid>
            <Grid style={{ width: 35 }} item>
              <ReportCardOverflowMenu deleteItem={() => deleteReport(reportItem.id)} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );

  const handleLocaleStorageExtraction = () => {
    const existingReports = localStorage.getItem(advancedReportingLocalStorageKey);
    if (!existingReports) {
      // if none exist, then we want to pre-load our sample
      localStorage.setItem(advancedReportingLocalStorageKey, JSON.stringify([sampleReportConfig]));
      setCurrentReportList([sampleReportConfig]);
    } else {
      setCurrentReportList(JSON.parse(existingReports));
    }
  };

  useEffect(() => {
    // we want to extract the values currently in localStorage,
    // if none exist, we want to stick in a sample for sake of a POC
    handleLocaleStorageExtraction();
  }, []);

  return (
    <>
      <Header breadcrumbs={[{ name: 'Advanced Report Manager', href: 'advanced-reporting.html' }]}>
        <IconButton aria-label={'refresh'} onClick={() => console.log('refreshing')}>
          <RefreshIcon />
        </IconButton>
        <DiagnosticsChecker />
        <Link href={'settings.html'}>
          <IconButton aria-label={'refresh'}>
            <SettingsIcon />
          </IconButton>
        </Link>
        <HelpIconLink
          href={'https://guide.kubecost.com/hc/en-us/articles/5991192077079-Advanced-Reporting'}
          tooltipText={'AR Documentation'}
        />
      </Header>
      <Paper style={{ padding: '2em', minHeight: '75vh' }}>
        <CreateNewReportModal
          createNewReportFromTemplate={newReportFromTemplate}
          isOpen={newReportModalOpen}
          setIsOpen={() => setNewReportModalOpen(false)}
        />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 150px',
            marginBottom: '2em',
            alignItems: 'center',
          }}
        >
          <Typography component={'div'} variant={'h5'}>
            Saved Reports
          </Typography>
          {/* <FormControl style={{ width: '100%' }}>
            <InputLabel>Date Created</InputLabel>
            <Select
              style={{ marginRight: '2em' }}
              onChange={(e: any) => setDateCreatedFilter(e.target.value)}
            >
              {dateCreatedValueList.map((item: any) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Filters</FormHelperText>
          </FormControl> */}
          <Button
            onClick={openNewReportModal}
            size={'medium'}
            style={{ height: 40, backgroundColor: colorMap.blue, color: 'white' }}
            variant={'contained'}
          >
            Create Report
          </Button>
        </div>
        <Grid style={{ padding: '2em' }} container>
          {currentReportList.map((item: ReportConfig) => (
            <ReportCard reportItem={item} />
          ))}
        </Grid>
      </Paper>
    </>
  );
};

export default memo(AdvancedReporting);
