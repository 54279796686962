import Chip from '@material-ui/core/Chip';
import grey from '@material-ui/core/colors/grey';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SharedIcon from '@material-ui/icons/ControlCamera';
import NetworkIcon from '@material-ui/icons/DeviceHub';
import AnyIcon from '@material-ui/icons/GroupWork';
import InfoIcon from '@material-ui/icons/Info';
import ComputeIcon from '@material-ui/icons/Memory';
import StorageIcon from '@material-ui/icons/SdStorage';
import { makeStyles } from '@material-ui/styles';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import * as React from 'react';

import { toCurrency } from '../../services/format';

import AssetChart from './AssetChart';
import { ReportRow } from './types';

const useStyles = makeStyles({
  chip: {
    margin: 4,
  },
  noResults: {
    padding: 24,
  },
});

// sort object in descending order on a given property
function descendingComparator(a: ReportRow, b: ReportRow, orderBy: string) {
  if (get(b, orderBy) < get(a, orderBy)) {
    return -1;
  }
  if (get(b, orderBy) > get(a, orderBy)) {
    return 1;
  }
  return 0;
}

// return a comparator (either ascending or descending)
function getComparator(order: 'asc' | 'desc', orderBy: string) {
  return order === 'desc'
    ? (a: ReportRow, b: ReportRow) => descendingComparator(a, b, orderBy)
    : (a: ReportRow, b: ReportRow) => -descendingComparator(a, b, orderBy);
}

// given an array and comparator, sort the array stably.
// if comparator finds two items to be equivalent,
// the item which came first in the original array will continue to come first.
function stableSort(array: ReportRow[], comparator: (arg0: ReportRow, arg1: ReportRow) => number) {
  const stabilizedThis: [ReportRow, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const adjustmentTooltip =
  "Amount added to total cost based on reconciliation with cloud provider's billing data";
const creditTooltip =
  'Amount deducted from total cost due to provider-applied credit. A negative number means the total cost was reduced.';

// return header cells to use for the table.
// the cells can change dynamically with aggregation.
const getHeadCells = (aggregateBy: string[]) => {
  if (!aggregateBy || !aggregateBy.length || aggregateBy.includes('unaggregated')) {
    return [
      { id: 'category', numeric: false, label: '', width: 25 },
      { id: 'name', numeric: false, label: 'Name', width: 'auto' },
      { id: 'providerID', numeric: false, label: 'Provider ID', width: 'auto' },
      { id: 'details', numeric: false, label: '', width: 'auto' },
      {
        id: 'credit',
        numeric: true,
        label: 'Credits',
        width: 120,
        tooltip: creditTooltip,
      },
      {
        id: 'adjustment',
        numeric: true,
        label: 'Adjusted',
        width: 120,
        tooltip: adjustmentTooltip,
      },
      { id: 'totalCost', numeric: true, label: 'Total cost', width: 120 },
    ];
  }

  return [
    { id: 'category', numeric: false, label: '', width: 25 },
    { id: 'name', numeric: false, label: 'Name', width: 'auto' },
    { id: 'details', numeric: false, label: '', width: 'auto' },
    {
      id: 'credit',
      numeric: true,
      label: 'Credits',
      width: 120,
      tooltip: creditTooltip,
    },
    {
      id: 'adjustment',
      numeric: true,
      label: 'Adjusted',
      width: 120,
      tooltip: adjustmentTooltip,
    },
    { id: 'totalCost', numeric: true, label: 'Total cost', width: 120 },
  ];
};

// map row category names to icons shown at the left side of the row.
const categoryToIcon = (category: string) => {
  if (category === undefined || category === null) {
    category = '';
  }
  if (category.toLowerCase() === 'storage') {
    return (
      <Tooltip placement={'bottom'} title={'Storage'}>
        <StorageIcon />
      </Tooltip>
    );
  }
  if (category.toLowerCase() === 'compute') {
    return (
      <Tooltip placement={'bottom'} title={'Compute'}>
        <ComputeIcon />
      </Tooltip>
    );
  }
  if (category.toLowerCase() === 'network') {
    return (
      <Tooltip placement={'bottom'} title={'Network'}>
        <NetworkIcon />
      </Tooltip>
    );
  }
  if (category.toLowerCase() === 'shared') {
    return (
      <Tooltip placement={'bottom'} title={'Shared'}>
        <SharedIcon />
      </Tooltip>
    );
  }
  return <AnyIcon />;
};

// informative tooltip with extra info about a row.
const AssetToolTip = ({
  rowName,
  tooltipData,
}: {
  rowName: string;
  tooltipData: Record<string, { hourlyCost: number; hoursRun: number; preemptible: number | null }>;
}) => {
  const hoursRun = tooltipData[rowName] && tooltipData[rowName].hoursRun.toFixed(2);
  const hourlyCost = tooltipData[rowName] && tooltipData[rowName].hourlyCost.toFixed(5);
  const preemptible = tooltipData[rowName] && tooltipData[rowName].preemptible;
  return (
    <Tooltip
      placement={'top'}
      title={
        <>
          <Typography>Asset Info</Typography>
          <Typography variant={'inherit'}>
            Hours Run: {hoursRun} <br />
            Hourly Cost: {hourlyCost} <br />
            {preemptible !== null &&
              (preemptible === 1 ? (
                <Typography variant={'inherit'}>Preemptible: True</Typography>
              ) : (
                <Typography variant={'inherit'}>Preemptible: False</Typography>
              ))}
          </Typography>
        </>
      }
    >
      <InfoIcon style={{ fontSize: 12, color: grey[500], margin: '0 4px' }} />
    </Tooltip>
  );
};

const AssetReport = ({
  aggregateBy,
  assetData,
  cumulativeData,
  currency,
  drillDown,
  tooltipData,
  totalData,
}: {
  aggregateBy: string[];
  currency: string;
  drillDown: (row: ReportRow) => void;
}) => {
  const classes = useStyles();
  const headCells = getHeadCells(aggregateBy);

  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState('totalCost');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const numData = cumulativeData.length;
  const lastPage = Math.floor(numData / rowsPerPage);

  useEffect(() => {
    setPage(0);
  }, [numData]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => setPage(newPage);

  const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler: (property: string) => React.MouseEventHandler<HTMLSpanElement> =
    (property: string) => (event) =>
      handleRequestSort(event, property);

  const handleRequestSort = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    property: string,
  ) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const orderedRows = stableSort(cumulativeData, getComparator(order, orderBy));
  const pageRows = orderedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  if (assetData.length === 0) {
    return (
      <Typography className={classes.noResults} variant={'body2'}>
        No results
      </Typography>
    );
  }

  return (
    <div id={'report'}>
      <AssetChart assetRange={assetData} currency={currency} height={300} n={10} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <TableCell
                  align={cell.numeric ? 'right' : 'left'}
                  key={cell.id}
                  sortDirection={orderBy === cell.id ? order : false}
                  style={{ width: cell.width }}
                >
                  <TableSortLabel
                    active={orderBy === cell.id}
                    direction={orderBy === cell.id ? order : 'asc'}
                    onClick={createSortHandler(cell.id)}
                  >
                    {cell.label}
                    {cell.tooltip && (
                      <Tooltip aria-label={cell.tooltip} title={cell.tooltip}>
                        <InfoIcon
                          style={{
                            fontSize: 12,
                            color: grey[500],
                            margin: '0 4px',
                          }}
                        />
                      </Tooltip>
                    )}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {headCells.map((cell) => (
                <TableCell
                  align={cell.numeric ? 'right' : 'left'}
                  key={cell.id}
                  style={{ fontWeight: 500 }}
                >
                  {cell.numeric ? toCurrency(totalData[cell.id], currency) : totalData[cell.id]}
                </TableCell>
              ))}
            </TableRow>
            {pageRows.map((row, key) => {
              const hasUndefined = row.name.indexOf('__undefined__') >= 0;
              const isUndefined = !row.name.split('/').filter((s: string) => s !== '__undefined__')
                .length;

              return (
                <Tooltip
                  key={key}
                  title={
                    hasUndefined
                      ? 'Cannot drill in to a row where an aggregation parameter is undefined.'
                      : ''
                  }
                >
                  <TableRow
                    key={key}
                    onClick={() => (hasUndefined ? void 0 : drillDown(row))}
                    style={{ cursor: 'pointer' }}
                    hover
                  >
                    <TableCell align={'left'}>{categoryToIcon(row.category)}</TableCell>
                    <TableCell
                      align={'left'}
                      style={{
                        fontSize:
                          !aggregateBy ||
                          !aggregateBy.length ||
                          aggregateBy.includes('unaggregated')
                            ? '12px'
                            : undefined,
                      }}
                    >
                      {isUndefined ? `No ${aggregateBy}` : row.name}
                      <AssetToolTip rowName={row.name} tooltipData={tooltipData} />
                    </TableCell>
                    {(!aggregateBy ||
                      !aggregateBy.length ||
                      aggregateBy.includes('unaggregated')) && (
                      <TableCell align={'left'} style={{ fontSize: 12 }}>
                        {row.providerID}
                      </TableCell>
                    )}
                    <TableCell align={'left'}>
                      {row.details &&
                        row.details.map((d, i) => (
                          <Chip className={classes.chip} key={i} label={d} size={'small'} />
                        ))}
                    </TableCell>
                    <TableCell align={'right'}>{toCurrency(row.credit, currency)}</TableCell>
                    <TableCell align={'right'}>{toCurrency(row.adjustment, currency)}</TableCell>
                    <TableCell align={'right'}>{toCurrency(row.totalCost, currency)}</TableCell>
                  </TableRow>
                </Tooltip>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component={'div'}
        count={numData}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={Math.min(page, lastPage)}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
      />
    </div>
  );
};

export default React.memo(AssetReport);
