import { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

import { Alert, AlertTypes, ClusterHealthAlert } from '../../services/alerts';

interface ClusterHealthAlertsTableProps {
  alerts: ClusterHealthAlert[];
  createAlert: (alert: Alert) => void;
  locked?: {
    threshold?: number;
    window?: string;
  };
  removeAlert: (alert: Alert) => void;
}

const ClusterHealthAlertsTable = ({
  alerts,
  createAlert,
  removeAlert,
  locked = {},
}: ClusterHealthAlertsTableProps) => {
  const [newWindow, setNewWindow] = useState('');
  const [newThreshold, setNewThreshold] = useState('');

  const handleOnClick = () => {
    const newAlert = new ClusterHealthAlert({
      type: AlertTypes.Health,
      window: newWindow || locked.window,
      threshold:
        typeof locked.threshold === 'undefined' ? parseFloat(newThreshold) : locked.threshold,
    });
    createAlert(newAlert);
    setNewWindow('');
    setNewThreshold('');
  };
  return (
    <Table className={'w-full'} data-test={'alerts-table'}>
      <TableHead>
        <TableRow>
          <TableHeadCell>Window</TableHeadCell>
          <TableHeadCell>Threshold</TableHeadCell>
          <TableHeadCell>Add/Delete</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {alerts.map((a) => (
          <TableRow key={a.id}>
            <TableCell>{a.window}</TableCell>
            <TableCell>{a.threshold}</TableCell>
            <TableCell>
              <IconButton data-test={'alert-delete'} onClick={() => removeAlert(a)}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell data-test={'new-alert-window'}>
            {locked.window || (
              <Input onChange={(e) => setNewWindow(e.target.value)} value={newWindow} />
            )}
          </TableCell>
          <TableCell data-test={'new-alert-threshold'}>
            {typeof locked.threshold === 'undefined' ? (
              <Input onChange={(e) => setNewThreshold(e.target.value)} value={newThreshold} />
            ) : (
              locked.threshold
            )}
          </TableCell>
          <TableCell>
            <IconButton data-test={'new-alert-add'} onClick={handleOnClick}>
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export { ClusterHealthAlertsTable };
