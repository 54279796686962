import get from 'lodash/get';

import { Button, Chip, Modal, Typography } from '@kubecost-frontend/holster';

import { toVerboseTimeRange } from '../../services/format';
import { Asset } from '../../types/asset';

import { DetailsMemoized } from './Details';

type DetailsDialogProps = {
  asset: Asset;
  close: () => void;
  currency: string;
  open: boolean;
  window: string;
};

const DetailsDialog = ({ asset, close, currency, open, window }: DetailsDialogProps) => {
  let title = '';
  let name = get(asset, 'name', '');
  const properties = get(asset, 'properties', {});
  const providerID = get(properties, 'providerID', '');
  // Attempt to reconstruct dialog name (type/name) if it is empty
  if (name === '') {
    name = get(properties, 'name', '');
    const type = get(asset, 'type', '');
    if (type !== '') {
      name = `${type}/${name}`;
    }
  }
  title = name || '';

  if (providerID) {
    title = `${name} | ${providerID}`;
  }
  const preemptible = get(asset, 'preemptible', 0);

  return (
    <Modal onClose={close} open={open} size={'large'} title={title}>
      <div className={'flex'}>
        <Typography className={'my-5'} variant={'p'}>
          {toVerboseTimeRange(window)}
        </Typography>
        {preemptible > 0 && <Chip color={'default'} label={'Preemtible'} />}
      </div>
      <DetailsMemoized asset={asset} currency={currency} window={window} />
      <div className={'mt-2 flex justify-end'}>
        <Button onClick={close} variant={'primary'}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default DetailsDialog;
