import { useState } from 'react';

import get from 'lodash/get';
import map from 'lodash/map';
import moment from 'moment';

import { Grid } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { green, red, yellow } from '@material-ui/core/colors';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const DiagnosticsPrometheusTarget = ({ job, target }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(!open);

  // checks the health for an inner target
  const iconFor = (t) => {
    if (t.health === 'up') {
      return <CheckIcon style={{ color: green[700] }} />;
    }

    return <ReportProblemOutlinedIcon style={{ color: yellow[700] }} />;
  };

  // extracts the name for the specific target
  const nameFor = (t) => {
    const labels = get(t, 'labels', {});

    if (labels.app_kubernetes_io_name !== undefined) {
      return labels.app_kubernetes_io_name;
    }

    if (labels.component !== undefined) {
      return labels.component;
    }

    if (labels.job !== undefined) {
      return labels.job;
    }

    return '';
  };

  const totalTargets = target.targets.length;
  const totalUp = target.up;
  const label = `${job} [${totalUp}/${totalTargets}]`;
  const icon =
    totalTargets === totalUp ? (
      <CheckCircleOutlinedIcon style={{ color: green[700] }} />
    ) : (
      <ReportProblemOutlinedIcon style={{ color: yellow[700] }} />
    );

  return (
    <>
      <ListItem onClick={handleClick} button dense>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout={'auto'} unmountOnExit>
        <Grid direction={'column'} spacing={2} style={{ paddingLeft: '5%' }} container>
          {map(
            target.targets,
            (innerTarget, innerKey) =>
              innerTarget !== null && (
                <Grid key={innerKey} xs={12} item>
                  <Grid direction={'row'} spacing={1} container>
                    <Grid item>{iconFor(innerTarget)}</Grid>
                    <Grid item>
                      <div style={{ width: '100%', paddingLeft: '10px' }}>
                        <Typography variant={'body2'}>
                          <b>Name:</b> {nameFor(innerTarget)}
                        </Typography>
                        <Typography variant={'body2'}>
                          <b>Scrape URL:</b> {innerTarget.scrapeUrl}
                        </Typography>
                        <Typography variant={'body2'}>
                          <b>Last Scrape:</b> {moment(innerTarget.lastScrape).toDate().toString()}
                        </Typography>
                        <Typography variant={'body2'}>
                          <b>Last Scrape Duration:</b>{' '}
                          {innerTarget.lastScrapeDuration.toPrecision(2)}s
                        </Typography>
                        {innerTarget.lastError !== undefined && innerTarget.lastError !== '' && (
                          <Typography style={{ color: red[700] }} variant={'body2'}>
                            <b>Last Error:</b> {innerTarget.lastError}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ),
          )}
        </Grid>
      </Collapse>
    </>
  );
};

export default DiagnosticsPrometheusTarget;
