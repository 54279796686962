// react
import { useState, useEffect } from 'react';

// holster
import { Button, Typography } from '@kubecost-frontend/holster';

import { useHosted } from '../../hooks/useHosted';
import HostedOnboarding from '../../components/HostedOnboarding';

import ClusterManagementTable from './ClusterManagementTable';
import isEmpty from 'lodash/isEmpty';

export const ClusterManagement = () => {
    const { onlineAgents } = useHosted();
    const [addClusterModalOpen, setAddClusterModalOpen] = useState(false);

    return (
      <div className='flex' id='cluster-management'>
        <div className='mb-4 basis-1/3'>
          <Typography variant='h5'>
            <strong>Manage Clusters</strong>
          </Typography>
          <Typography style={{ color: 'gray' }} variant='p'>
            Clusters you have currently set up
          </Typography>
        </div>
        <div className='basis-2/3'>
          <div className='flex'>
            <div className='flex-grow'></div>
            <Button onClick={() => setAddClusterModalOpen(true)} variant={'primary'}>
              Add Cluster
            </Button>
          </div>
          {isEmpty(onlineAgents) ? (
            <Typography style={{ color: 'gray' }} variant='p'>
              No clusters available
            </Typography>
          ) : (
            <ClusterManagementTable onlineAgents={onlineAgents} />
          )}
        </div>
        <HostedOnboarding
          initial={false}
          onClose={() => setAddClusterModalOpen(false)}
          show={addClusterModalOpen}
        />
      </div>
    );
};