import React, { useEffect, useState } from 'react';
import { useHosted } from './hooks/useHosted';
import logger from './services/logger';

const fetchAndSetDefaultLocalStorageValues = (): Promise<void> => {
  const URL = `config.json`;
  return fetch(URL)
    .then((resp) => resp.json())
    .then((data) => {
      if (process.env.NODE_ENV !== 'development') {
        const dir =
          window.location.origin + window.location.pathname.split('/').slice(0, -1).join('/');

        data.container = dir;
        data.all_containers = dir;
      }

      Object.keys(data).forEach((key) => {
        if (localStorage.getItem(key) === null) {
          localStorage.setItem(key, data[key]);
        }
      });
    });
};

const checkUrlForContainersOverride = (): void => {
  const searchParams = new URLSearchParams(window.location.search);
  const backends = searchParams.get('backends');
  if (backends) {
    try {
      localStorage.setItem('container', backends.split(',')[0]);
      localStorage.setItem('all_containers', backends);
      searchParams.delete('backends');
      window.location.search = searchParams.toString();
    } catch (err) {
      logger.log(`Failed to load backends from url param: ${err}`);
    }
  }
};

export const LocalStorageHydrationManager = ({ children }: any) => {
  const { isHostedEnvironment, config } = useHosted();
  const [hydrated, setHydrated] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem('cpu') === null) {
      fetchAndSetDefaultLocalStorageValues().then(() => {
        checkUrlForContainersOverride();
        setHydrated(true);
      });
    } else {
      checkUrlForContainersOverride();
      setHydrated(true);
    }

    // Manual override to set the container property to the hosted config.
    if (isHostedEnvironment) {
      localStorage.setItem('container', config!.costModelAPIUrl);
    }
  }, []);

  if (!hydrated) return <></>;
  return children;
};
