import { FC, useEffect, useState } from 'react';

import { Button, Input, Modal, Typography } from '@kubecost-frontend/holster';

import { AlertTypes, DiagnosticAlert } from '../../services/alerts';

interface ComponentProps {
  alert: DiagnosticAlert | null;
  close: () => void;
  save: (alert: DiagnosticAlert) => Promise<void>;
  test: (alert: DiagnosticAlert) => Promise<void>;
}

const DiagnosticAlertModal: FC<ComponentProps> = ({ alert, close, save, test }: ComponentProps) => {
  const [slackDirty, setSlackDirty] = useState(false);
  const [slackUrl, setSlackUrl] = useState('');
  useEffect(() => {
    if (alert && alert.slackWebhookUrl) {
      setSlackUrl(
        'https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX',
      );
    }
  }, [alert]);

  const onClose = () => {
    setSlackUrl('');
    setSlackDirty(false);
    close();
  };

  const onSave = async () => {
    const a = new DiagnosticAlert({
      type: AlertTypes.Diagnostic,
      window: '5m',
      slackWebhookUrl: alert?.slackWebhookUrl,
      id: alert?.id,
    });
    if (slackDirty) {
      a.slackWebhookUrl = slackUrl;
    }
    await save(a);
    onClose();
  };

  const onTest = async () => {
    const a = new DiagnosticAlert({
      type: AlertTypes.Diagnostic,
      slackWebhookUrl: alert?.slackWebhookUrl,
      window: '5m',
    });
    if (slackDirty) {
      a.slackWebhookUrl = slackUrl;
    }
    test(a);
  };

  return (
    <Modal
      data-test={'diagnostic-alert-dialog'}
      onClose={onClose}
      open={!!alert}
      title={'Enable Kubecost Health Monitoring'}
    >
      <Typography style={{ marginBottom: 16 }} variant={'p'}>
        With Kubecost Health monitoring enabled, Kubecost will send a message to the designated
        Slack webhook any time it is unable to contact its backing data store.
      </Typography>

      <Typography variant={'p'}>
        Learn more about Slack webhooks{' '}
        <a
          href={'https://api.slack.com/messaging/webhooks'}
          style={{ color: '#28B359' }}
          target={'_blank'}
        >
          here
        </a>
        .
      </Typography>

      <Input
        label={'Slack webhook'}
        onChange={(e) => {
          setSlackUrl(e.target.value);
          setSlackDirty(true);
        }}
        placeholder={
          'https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX'
        }
        style={{ width: '100%' }}
        value={slackUrl}
      />

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}>
        <Button onClick={onClose} style={{ marginRight: 8 }} variant={'primary'}>
          Cancel
        </Button>
        <Button onClick={onSave} style={{ marginRight: 8 }} variant={'primary'}>
          Save
        </Button>
        <Button onClick={onTest} variant={'default'}>
          Test
        </Button>
      </div>
    </Modal>
  );
};

export { DiagnosticAlertModal };
