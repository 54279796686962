import { Typography } from '@kubecost-frontend/holster';
import { useHosted } from '../hooks/useHosted';

const FooterHosted = () => (
  <div className={'text-center'} style={{ padding: 24 }}>
    <Typography variant={'p-small'}>
      Have questions or feedback on Kubecost Cloud Beta? We&apos;re on{' '}
      <a
        className={'text-kc-link'}
        href={'https://kubecost.slack.com/archives/C052PHJNQ49'}
        target={'_blank'}
      >
        Slack
      </a>{' '}
      or email at{' '}
      <a href={'mailto:cloud@kubecost.com'} style={{ color: '#28B359' }}>
        cloud@kubecost.com
      </a>
    </Typography>
  </div>
);

const FooterOnPrem = () => (
  <div className={'text-center'} style={{ padding: 24 }}>
    <Typography variant={'p-small'}>
      Have questions? We&apos;re on{' '}
      <a
        className={'text-kc-link'}
        href={'https://join.slack.com/t/kubecost/shared_invite/zt-1dz4a0bb4-InvSsHr9SQsT_D5PBle2rw'}
        target={'_blank'}
      >
        Slack
      </a>{' '}
      or email at{' '}
      <a href={'mailto:team@kubecost.com'} style={{ color: '#28B359' }}>
        team@kubecost.com
      </a>
    </Typography>
  </div>
);

const Footer = () => {
  const { isHostedEnvironment } = useHosted();

  return isHostedEnvironment ? <FooterHosted /> : <FooterOnPrem />;
};

export { Footer };
