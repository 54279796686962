import { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

import { Alert, AlertTypes, DiagnosticAlert } from '../../services/alerts';

interface DiagnosticAlertsTableProps {
  alerts: DiagnosticAlert[];
  createAlert: (alert: Alert) => void;
  locked?: {
    window?: string;
  };
  removeAlert: (alert: Alert) => void;
}

const DiagnosticAlertsTable = ({
  alerts,
  createAlert,
  removeAlert,
  locked = {},
}: DiagnosticAlertsTableProps) => {
  const [newWindow, setNewWindow] = useState('');

  const handleOnClick = () => {
    const newAlert = new DiagnosticAlert({
      type: AlertTypes.Budget,
      window: newWindow || locked.window,
    });
    createAlert(newAlert);
    setNewWindow('');
  };

  return (
    <Table className={'w-full'} data-test={'alerts-table'}>
      <TableHead>
        <TableRow>
          <TableHeadCell>Window</TableHeadCell>
          <TableHeadCell>Add/Delete</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {alerts.map((a) => (
          <TableRow key={a.id}>
            <TableCell>{a.window}</TableCell>
            <TableCell>
              <IconButton data-test={'alert-delete'} onClick={() => removeAlert(a)}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell data-test={'new-alert-window'}>
            {locked.window || (
              <Input onChange={(e) => setNewWindow(e.target.value)} value={newWindow} />
            )}
          </TableCell>
          <TableCell>
            <IconButton data-test={'new-alert-add'} onClick={handleOnClick}>
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export { DiagnosticAlertsTable };
