import { Alert, Button, Input, Modal, Typography } from '@kubecost-frontend/holster';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { APIClient } from '../../../services/APIClient';
import { ProfileQueryKey, useHosted } from '../../../hooks/useHosted';
import { useAuth } from '../../../PKCE';

type CreateTeamProps = {
  open: boolean;
  onClose: () => void;
};

const CreateTeam = ({ onClose, open }: CreateTeamProps) => {
  const queryClient = useQueryClient();
  const { config } = useHosted();
  const { authService } = useAuth();
  const [teamName, setTeamName] = useState('');

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleOnClose = () => {
    setTeamName('');
    setErrorMessage('');
    onClose();
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => {
      return APIClient.post(`${config?.userServiceAPIUrl}/api/teams`, payload);
    },
    onError: () => {
      setErrorMessage(
        'Looks like we had some trouble creating your team. Give us a few minutes and try again.',
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ProfileQueryKey);
      authService.refreshToken();

      handleOnClose();
    },
  });

  const createTeam = async () => {
    const payload = {
      name: teamName,
    };
    await mutate(payload);
  };

  return (
    <Modal onClose={handleOnClose} open={open} title='Create a new Team'>
      {errorMessage && (
        <Alert className='my-4' variant='danger' title='Error' content={errorMessage}></Alert>
      )}
      <Typography className='my-4' variant='p-large'>
        Give your team a unique name
      </Typography>
      <div className='flex gap-4'>
        <Input
          className='flex-grow'
          disabled={isLoading}
          placeholder='Kubecost Team'
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          style={{ width: '100%' }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              createTeam();
            }
          }}
        />
        <Button onClick={createTeam} variant='primary'>
          Create
        </Button>
      </div>
    </Modal>
  );
};

export default CreateTeam;
