import { Link } from '@material-ui/core';

import { Typography } from '@kubecost-frontend/holster';

import { designSystemColorMap } from '../../../services/colors';
import { toCurrency } from '../../../services/format';

interface SavingsCard2Props {
  currency: string;
  onClickHref?: string;
  title: string;
  value: number;
}

export const SavingsCard2 = ({ currency, onClickHref, title, value }: SavingsCard2Props) => (
  <div
    className={'border-b border-kc-gray-100'}
    style={{
      padding: '1em',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      justifyContent: 'space-between',
    }}
  >
    <div>
      <Typography variant={'h6'}>{title}</Typography>
      <Typography variant={'h5'}>
        <strong>{toCurrency(value, currency)}</strong>
      </Typography>
    </div>
    {onClickHref && (
      <Link href={onClickHref} style={{ marginLeft: 'auto', color: designSystemColorMap.primary }}>
        Request sizing
      </Link>
    )}
  </div>
);
