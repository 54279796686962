import { useAPIClient } from '../../../services/APIClient';

type Breakdown = {
  idle: number;
  other: number;
  system: number;
  user: number;
};

type AssetQueryFilter = {
  property: string;
  value: string;
};

type AssetQueryParams = {
  window: string;
  aggregate?: string[];
  accumulate: boolean;
  filters: AssetQueryFilter[];
};

type BaseAsset = {
  adjustment: number;
  discount: number;
  end: string;
  key?: string;
  labels: Record<string, string>;
  name: string;
  nodeType: string;
  preemptible?: number;
  properties: {
    account?: string;
    category?: string;
    cluster?: string;
    currency?: string;
    name?: string;
    pricingSource?: string;
    project?: string;
    provider?: string;
    providerID?: string;
    region?: string;
    service?: string;
  };
  start: string;
  totalCost: number;
  type: string;
};

export type NodeAsset = BaseAsset & {
  cpuBreakdown: Breakdown;
  cpuCoreHours: number;
  cpuCores: number;
  cpuCost: number;
  gpuCost: number;
  gpuCount: number;
  gpuHours: number;
  nodeType: string;
  ramBreakdown: Breakdown;
  ramByteHours: number;
  ramBytes: number;
  ramCost: number;
};

export type DiskAsset = BaseAsset & {
  breakdown: Breakdown;
  byteHours: number;
  bytes: number;
  storageClass: string;
};

type CloudAsset = BaseAsset & {
  usageDetail: string;
  usageType: string;
};

export type Asset = BaseAsset | NodeAsset | DiskAsset | CloudAsset;

type AssetSet = {
  results: Asset[];
  window: {
    end: string;
    start: string;
  };
};

type AssetResponse = {
  data: AssetSet[];
};

const FilterParamMap: Record<string, string> = {
  account: 'filterAccount',
  category: 'filterCategories',
  cluster: 'filterClusters',
  'label/tag': 'filterLabels',
  label: 'filterLabels',
  name: 'filterNames',
  project: 'filterProjects',
  provider: 'filterProviders',
  providerid: 'filterProviderIds',
  region: 'filterRegions',
  service: 'filterServices',
  type: 'filterTypes',
};

function parseFilters(filters: AssetQueryFilter[]) {
  const params: Record<string, string> = {};

  for (let filter of filters) {
    const prop = filter.property.toLowerCase();
    const param = FilterParamMap[prop];

    if (param) {
      params[param] = filter.value;
    }
  }

  return params;
}

function useAssetQueryFn() {
  const client = useAPIClient();

  return async (
    { window, aggregate, accumulate, filters }: AssetQueryParams,
    signal?: AbortSignal,
  ) => {
    const { data: resp } = await client.get<AssetResponse>('/assets/query', {
      params: {
        window,
        accumulate,
        aggregate,
        ...parseFilters(filters ?? []),
      },
      signal,
    });

    return resp;
  };
}

export type { AssetQueryFilter };
export { useAssetQueryFn };
