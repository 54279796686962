import { InspectedResourceListItem } from './inspectState';

interface AggregateHierarchyItem {
  name: string;
  value: string;
}

export const aliasedLabelDefaults = {
  owner: 'owner',
  team: 'team',
  product: 'product',
  environment: 'environment',
  department: 'department',
};

export const createDataForSpendingGraph = (
  createData: any,
  aggregationNameString: string,
  activeTimeWindow: string,
) =>
  createData.sets.map((item: any) => {
    try {
      const {
        cpuCost,
        externalCost,
        gpuCost,
        loadBalancerCost,
        networkCost,
        pvCost,
        ramCost,
        sharedCost,
      } = item.allocations[aggregationNameString];

      return {
        name: formatDate(item.window.start, activeTimeWindow),
        ramCost,
        cpuCost,
        gpuCost,
        networkCost,
        loadBalancerCost,
        externalCost,
        pvCost,
        sharedCost,
      };
    } catch (error) {
      return {};
    }
  });

export const establishHierarchy = (aggs: InspectedResourceListItem[]) => {
  // take the aggs
  const aggMap = {};
  const listToReturn: InspectedResourceListItem[] = [];
  const labelList: InspectedResourceListItem[] = [];
  aggs.forEach((item: InspectedResourceListItem) => {
    // if item is a label, go ahead and add to list
    if (labelAliases.includes(item.type)) {
      labelList.push({ name: item.name, type: `${item.type}` });
    } else if (item.type.includes('label')) {
      const strippedName = item.name.substring(item.name.indexOf('=') + 1);
      labelList.push({ name: strippedName, type: item.type });
    } else {
      aggMap[item.type] = item; // what if more than one of same type?
    }
  });
  defaultAggregateByOptions.forEach((item: AggregateHierarchyItem) => {
    if (aggMap[item.value]) {
      listToReturn.push(aggMap[item.value]);
    } else {
      listToReturn.push({ name: '', type: item.value });
    }
  });

  return [...listToReturn, ...labelList];
};

export const formatDate = (isoString: string, activeTimeWindow: string) => {
  const tempDate = new Date(isoString);
  let formattedDate = `${tempDate.getMonth() + 1}/${tempDate.getDate()}`;
  if (activeTimeWindow.includes('h')) {
    formattedDate = tempDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  }
  return formattedDate;
};

const labelAliases = [
  'team',
  'department',
  'environment',
  'product',
  'daemonset',
  'job',
  'deployment',
  'owner',
  'service',
  'controllerkind',
  'statefulset',
];

export const getEffiencyData = (allocationSummaryData: any, aggregationNameString: string) => {
  const { sets } = allocationSummaryData;
  const {
    cpuCoreRequestAverage,
    cpuCoreUsageAverage,
    cpuCost,
    externalCost,
    gpuCost,
    loadBalancerCost,
    networkCost,
    pvCost,
    ramByteRequestAverage,
    ramByteUsageAverage,
    ramCost,
    sharedCost,
  } = sets[0].allocations[aggregationNameString];

  let cpuEfficiency = 0;
  if (cpuCoreRequestAverage > 0) {
    cpuEfficiency = cpuCoreUsageAverage / cpuCoreRequestAverage;
  } else if (cpuCoreUsageAverage > 0) {
    cpuEfficiency = 1;
  }
  let ramEfficiency = 0;
  if (ramByteRequestAverage > 0) {
    ramEfficiency = ramByteUsageAverage / ramByteRequestAverage;
  } else if (ramByteUsageAverage > 0) {
    ramEfficiency = 1;
  }
  const totalEfficiency = (cpuCost * cpuEfficiency + ramCost * ramEfficiency) / (cpuCost + ramCost);

  return {
    cpu: cpuCoreUsageAverage / cpuCoreRequestAverage,
    ram: ramByteUsageAverage / ramByteRequestAverage,
    cpuCost,
    ramCost,
    networkCost,
    loadBalancerCost,
    externalCost,
    pvCost,
    sharedCost,
    gpuCost,
    cpuEfficiency,
    ramEfficiency,
    totalEfficiency,
  };
};

// as of now, external cost data is only visible on namespaces.
// however, this map is here with longevity in mind.
export const labelAssetsMap = {
  cluster: 'kubernetes_cluster',
  namespace: 'kubernetes_namespace',
  controller: 'kubernetes_controller',
  daemonset: 'kubernetes_daemonset',
  deployment: 'kubernetes_deployment',
  statefulset: 'kubernetes_statefulset',
  service: 'kubernetes_service',
  pod: 'kubernetes_pod',
  department: 'kubernetes_label_department',
  environment: 'kubernetes_label_env',
  owner: 'kubernetes_label_owner',
  product: 'kubernetes_label_app',
  team: 'kubernetes_label_team',
};

// Order represents hierarchy. We're assuming the lowest index has highest granularity
const defaultAggregateByOptions: AggregateHierarchyItem[] = [
  {
    name: 'Container',
    value: 'container',
  },
  {
    name: 'Pod',
    value: 'pod',
  },
  {
    name: 'Controller',
    value: 'controller',
  },
  {
    name: 'Namespace',
    value: 'namespace',
  },
  {
    name: 'Cluster',
    value: 'cluster',
  },
];
