// react
import React, { useState } from 'react';

// holster
import { Button, Typography } from '@kubecost-frontend/holster';

import { InviteTeamMemberModal } from './InviteTeamMemberModal';

export const AdminDashboard = () => {
    const [inviteTeamMemberModalVisible, setInviteTeamMemberModalVisible] = useState<boolean>(false);

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: 1 }}>
                    <Typography style={{ marginBottom: '0.5em' }} variant="h5">
                        <strong>Admin Dashboard</strong>
                    </Typography>
                    <Typography variant="p">
                        A space to manage your team and members.
                    </Typography>
                </div>
                <div style={{ alignSelf: 'center' }}>
                <Button variant="primary" onClick={() => setInviteTeamMemberModalVisible(true)}>
                    + Add new
                </Button>
                </div>
            </div>
            <InviteTeamMemberModal
                visible={inviteTeamMemberModalVisible}
                closeModal={() => setInviteTeamMemberModalVisible(false)}
            />
        </div>
    );
};