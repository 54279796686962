import { FC } from 'react';

// recharts
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { useClusters } from '../../../contexts/ClusterConfig';
import { toCurrency } from '../../../services/format';
import { TopResultsGraph } from '../../../services/model';

interface CategoryChartProps {
  data: TopResultsGraph;
  height: number;
}

const CategoryChart: FC<CategoryChartProps> = ({ data, height }) => {
  const { modelConfig } = useClusters();
  const margin = { top: 30, right: 35, left: 15, bottom: 45 };
  const items = data.items
    .map((item) => ({
      name: item.name,
      cost: item.cost || 0,
    }))
    .filter((item) => !(item.name === 'others' && !item.cost));
  items.sort((a, b) => (a.cost > b.cost ? 1 : -1));
  return (
    <ResponsiveContainer height={height} width={'100%'}>
      <BarChart data={items} margin={margin}>
        <CartesianGrid strokeDasharray={'3 3'} vertical={false} />
        <XAxis
          angle={330}
          dataKey={'name'}
          interval={0}
          tick={{ fontSize: 12 }}
          tickFormatter={shorten}
          tickMargin={16}
        />
        <YAxis tickFormatter={(val) => toCurrency(val, modelConfig.currencyCode, 2, true)} />
        <Tooltip formatter={tooltipFormatter} />
        <Bar dataKey={'cost'} fill={'#2196f3'} />
      </BarChart>
    </ResponsiveContainer>
  );

  function tooltipFormatter(value: number) {
    return [toCurrency(value, modelConfig.currencyCode), 'Total cost'];
  }

  function shorten(s: string) {
    const maxlen = 16;
    if (s.length < maxlen) {
      return s;
    }

    if (typeof s !== 'string') return s;

    return `${s.slice(0, maxlen - 3)}...`;
  }
};

export default CategoryChart;
