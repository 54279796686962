import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import { memo, useEffect, useState } from 'react';
import { Link as RouteLink, useNavigate } from 'react-router-dom';

import { DiagnosticsChecker } from '../../components/DiagnosticsChecker';
import { Header } from '../../components/Header';
import { HelpIconLink } from '../../components/HelpIconLink';
import { Loading } from '../../components/Loading';
import { FetchStates } from '../../constants';
import { model } from '../../services/model';
import {
  deleteAllocationReport,
  deleteAssetReport,
  listAllocationReports,
  listAssetReports,
  saveAllocationReport,
  saveAssetReport,
} from '../../services/reports';
import { AllocationReport } from '../../types/allocation';
import { AssetReport } from '../../types/asset';
import { AlphaAdvancedReportingBanner } from '../AdvancedReportingManager/AlphaAdvancedReportingBanner';

import { CombinedReports, ReportsTable } from './ReportsTable';

const useStyles = makeStyles({
  emptyState: {
    fontSize: '24px',
    color: '#aaa',
    textAlign: 'center',
  },
  reportHeader: {
    padding: 24,
  },
  reportCards: {
    marginTop: 24,
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
  },
});

const Reports = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  // component state variables
  const [allocationReportsFetchState, setAllocationReportsFetchState] = useState(FetchStates.INIT);
  const [assetReportsFetchState, setAssetReportsFetchState] = useState(FetchStates.INIT);
  const [allocationReports, setAllocationReports] = useState<AllocationReport[]>([]);
  const [assetReports, setAssetReports] = useState<AssetReport[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  // used to anchor the Create Report popup menu
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);

  // allow users to undo a recent delete action
  const [justDeleted, setJustDeleted] = useState<
    AllocationReport | AssetReport | CombinedReports | null
  >(null);
  const [justDeletedType, setJustDeletedType] = useState<'allocation' | 'asset' | null>(null);

  // user's readonly status
  const [readOnly, setReadOnly] = useState<boolean>(true);

  // whenever fetch state is "init", fetch allocation reports
  useEffect(() => {
    if (allocationReportsFetchState !== FetchStates.INIT) {
      return;
    }
    fetchAllocationReports();
  }, [allocationReportsFetchState]);

  // whenever fetch state is "init", fetch asset reports
  useEffect(() => {
    if (assetReportsFetchState !== FetchStates.INIT) {
      return;
    }
    fetchAssetReports();
  }, [assetReportsFetchState]);

  // get application config on page load
  useEffect(() => {
    model.get('/getConfigs').then((configs) => {
      setReadOnly(configs.data.readOnly !== 'false' && configs.data.editorAccess !== 'true');
    });
  }, []);

  // content is determined by the current load-state of the page

  async function fetchAllocationReports() {
    setAllocationReportsFetchState(FetchStates.LOADING);

    try {
      const reports = await listAllocationReports();
      setAllocationReports(sortReports(reports));
      setAllocationReportsFetchState(FetchStates.DONE);
    } catch (err) {
      setAllocationReportsFetchState(FetchStates.ERROR);
    }
  }

  async function fetchAssetReports() {
    setAssetReportsFetchState(FetchStates.LOADING);

    try {
      const reports = await listAssetReports();
      setAssetReports(sortReports(reports));
      setAssetReportsFetchState(FetchStates.DONE);
    } catch (err) {
      setAssetReportsFetchState(FetchStates.ERROR);
    }
  }

  function fetchAll() {
    fetchAllocationReports();
    fetchAssetReports();
  }

  function sortReports(reports: AllocationReport[]): AllocationReport[];
  function sortReports(reports: AssetReport[]): AssetReport[];
  function sortReports(
    reports: Array<AllocationReport | AssetReport>,
  ): Array<AllocationReport | AssetReport> {
    return reports.sort((a, b) => (a.title > b.title ? 1 : -1));
  }

  function isInitializing(): boolean {
    return (
      allocationReportsFetchState === FetchStates.INIT ||
      assetReportsFetchState === FetchStates.INIT
    );
  }

  function isLoading(): boolean {
    return (
      allocationReportsFetchState === FetchStates.LOADING ||
      assetReportsFetchState === FetchStates.LOADING
    );
  }

  function isError(): boolean {
    return (
      !isLoading() &&
      !isInitializing() &&
      (allocationReportsFetchState === FetchStates.ERROR ||
        assetReportsFetchState === FetchStates.ERROR)
    );
  }

  const handleDeleteAllocationReport = async (report: AllocationReport) => {
    const resp = await deleteAllocationReport(report);
    setAllocationReports(
      sortReports(
        resp.map((r) => ({
          ...r,
          aggregateBy: r.aggregateBy.split(','),
        })),
      ),
    );
    setJustDeleted(report);
    setJustDeletedType('allocation');
  };

  const handleDeleteAssetReport = async (report: AssetReport) => {
    const resp = await deleteAssetReport(report);
    setAssetReports(sortReports(resp));
    setJustDeleted(report);
    setJustDeletedType('asset');
  };

  const handleUndoReportDelete = async () => {
    if (justDeleted) {
      if (justDeletedType === 'allocation') {
        const resp = await saveAllocationReport(justDeleted as AllocationReport);
        setAllocationReports(
          sortReports(
            resp.map((r) => ({
              ...r,
              aggregateBy: r.aggregateBy.split(','),
            })),
          ),
        );
      } else if (justDeletedType === 'asset') {
        const resp = await saveAssetReport(justDeleted as AssetReport);
        setAssetReports(sortReports(resp));
      }
      setJustDeleted(null);
      setJustDeletedType(null);
    }
  };

  const ready =
    allocationReportsFetchState === FetchStates.DONE && assetReportsFetchState === FetchStates.DONE;

  return (
    <>
      <Header breadcrumbs={[{ name: 'Reports', href: 'reports.html' }]}>
        <IconButton aria-label={'refresh'} onClick={() => fetchAll()}>
          <RefreshIcon />
        </IconButton>
        <DiagnosticsChecker />
        <Link component={RouteLink} to={'/settings'}>
          <IconButton aria-label={'refresh'}>
            <SettingsIcon />
          </IconButton>
        </Link>
        <HelpIconLink
          href={'https://docs.kubecost.com/saved-reports'}
          tooltipText={'Product Documentation'}
        />
      </Header>

      <Paper>
        <div className={classes.reportHeader}>
          <Typography variant={'h5'} paragraph>
            Saved Reports
          </Typography>
          <Typography paragraph>
            Kubecost saved reports are predefined views stored for easy access. These reports can be
            configured in the Cost Allocation and Asset tabs or via YAML at install time.{' '}
            <Link href={'http://docs.kubecost.com/saved-reports.html'} target={'_blank'}>
              Learn more.
            </Link>
          </Typography>
          <Button
            color={'primary'}
            onClick={(e) => setMenuAnchorEl(e.currentTarget)}
            style={{ marginLeft: 8 }}
            variant={'contained'}
            disableElevation
          >
            Create a report
          </Button>
          <Menu
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            getContentAnchorEl={null}
            onClose={() => setMenuAnchorEl(null)}
            open={Boolean(menuAnchorEl)}
          >
            <MenuItem
              onClick={() => {
                const searchParams = new URLSearchParams();
                searchParams.set('new-report', 'true');
                navigate(`/allocations?${searchParams.toString()}`);
              }}
            >
              Allocation Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                const searchParams = new URLSearchParams();
                searchParams.set('new-report', 'true');
                navigate(`/assets?${searchParams.toString()}`);
              }}
            >
              Asset Report
            </MenuItem>
          </Menu>
          <Input
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            placeholder={'Filter reports'}
            style={{ marginLeft: 24 }}
          />
          <Alert
            action={
              <Link
                href={
                  'https://guide.kubecost.com/hc/en-us/articles/4407595977879-Saved-Reports#combining-ui-report-management-with-values-yaml'
                }
                target={'_blank'}
              >
                <Button color={'inherit'} size={'small'}>
                  Learn More
                </Button>
              </Link>
            }
            severity={'info'}
            style={{ marginTop: 28 }}
          >
            Note: Any modifications to reports (creating / deleting) will be overwritten by the
            reporting ConfigMap on pod restart.
          </Alert>
        </div>
      </Paper>

      <AlphaAdvancedReportingBanner />

      {isInitializing() && <></>}

      {isLoading() && <Loading />}

      {isError() ? (
        <Typography>Unable to retrieve a list of reports. Refresh to try again.</Typography>
      ) : (
        <></>
      )}

      {ready ? (
        <Grid className={classes.reportCards} spacing={2} container>
          <ReportsTable
            allocationReports={allocationReports}
            assetReports={assetReports}
            handleDeleteAllocationReport={handleDeleteAllocationReport}
            handleDeleteAssetReport={handleDeleteAssetReport}
            readOnly={readOnly}
            searchTerm={searchTerm}
          />
        </Grid>
      ) : (
        <></>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => {
          setJustDeleted(null);
          setJustDeletedType(null);
        }}
        open={Boolean(justDeleted)}
      >
        <Alert
          action={
            <Button color={'inherit'} onClick={handleUndoReportDelete} size={'small'}>
              Undo
            </Button>
          }
          severity={'info'}
        >
          Deleted report: <b>{justDeleted?.title}</b>
        </Alert>
      </Snackbar>
    </>
  );
};

export default memo(Reports);
