import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

import { primary } from '../../services/colors';
import { toCurrency } from '../../services/format';

export interface AllocationOverTimeSet {
  cpuCost: number;
  gpuCost: number;
  loadBalancerCost: number;
  name: string;
  networkCost: number;
  ramCost: number;
}

interface HistoricalCostAreaChartProps {
  chartWidth: number;
  currency: string;
  data: AllocationOverTimeSet[];
  resourceTypes: string[];
}

export const HistoricalCostBarChart = ({
  chartWidth,
  currency,
  data,
  resourceTypes,
}: HistoricalCostAreaChartProps) => (
  <BarChart data={data} height={250} margin={{ left: 30 }} width={chartWidth}>
    <CartesianGrid strokeDasharray={'0'} vertical={false} />
    <XAxis dataKey={'name'} />
    <YAxis tickFormatter={(val: number) => toCurrency(val, currency, undefined, true)} />
    <Tooltip formatter={(val) => toCurrency(val, currency)} />
    {resourceTypes.map((type: string, idx: number) => (
      <Bar dataKey={type} fill={primary[idx]} key={type} stackId={'a'} />
    ))}
  </BarChart>
);
