// react
import React, { useState } from 'react';

// holster
import { Button, Typography } from '@kubecost-frontend/holster';

import { useHosted } from '../../hooks/useHosted';
import TeamsTable from './Teams/TeamsTable';
import PendingInvitations from './Teams/PendingInvitations';
import CreateTeam from './Teams/CreateTeam';

export const AdminDashboard = () => {
  const { profile } = useHosted();
  const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);

  const allTeams = [...profile.owned_teams, ...profile.teams];

  return (
    <div id='teams'>
      <div>
        <PendingInvitations />
      </div>
      <div className='flex'>
        <div className='mb-4 basis-1/3'>
          <Typography variant='h5'>
            <strong>Manage Teams</strong>
          </Typography>
          <Typography style={{ color: 'gray' }} variant='p'>
            Teams you own and are a member of
          </Typography>
        </div>
        <div className='basis-2/3'>
          <div className='flex'>
            <div className='flex-grow'></div>
            <Button onClick={() => setCreateTeamModalOpen(true)} variant='primary'>
              Create Team
            </Button>
          </div>
          <TeamsTable teams={allTeams} />
        </div>
      </div>
      <CreateTeam open={createTeamModalOpen} onClose={() => setCreateTeamModalOpen(false)} />
    </div>
  );
};
