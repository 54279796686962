import { ChangeEvent, useId } from 'react';

import { Button, NativeSelect } from '@kubecost-frontend/holster';

interface TablePaginationProps {
  currentPage: number;
  onChangeRowOptions: (event: ChangeEvent<HTMLSelectElement>) => void;
  onClickNextButton: (currentPage: number) => void;
  onClickPrevButton: (currentPage: number) => void;
  rowOptions?: number[];
  selectedRowOption: number;
  totalRows: number;
}

const TablePagination = ({
  currentPage,
  onClickNextButton,
  onClickPrevButton,
  onChangeRowOptions,
  rowOptions = [10, 25, 50],
  selectedRowOption,
  totalRows,
}: TablePaginationProps) => {
  const paginationOptionsId = useId();
  const paginationButtons = useId();

  const lastPage = Math.floor(totalRows / selectedRowOption);
  const p = Math.min(currentPage, lastPage);
  const startIndex = p * selectedRowOption + 1;
  const stopIndex = selectedRowOption > totalRows ? totalRows : (p + 1) * selectedRowOption;

  return (
    <div className={'flex items-center justify-between pt-3'}>
      <label htmlFor={paginationOptionsId}>
        <span className={'pr-2'}>Rows per page</span>
        <NativeSelect
          id={paginationOptionsId}
          onChange={onChangeRowOptions}
          options={rowOptions.map((option: number) => ({
            label: `${option}`,
            value: `${option}`,
          }))}
          value={`${selectedRowOption}`}
        />
      </label>
      <label htmlFor={paginationButtons}>
        <span className={'sr-only'}>Currently showing rows</span>
        {`${startIndex}-${stopIndex} of ${totalRows}`}
        <Button
          className={'ml-5 mr-2'}
          disabled={!currentPage}
          id={paginationButtons}
          onClick={() => onClickPrevButton(currentPage)}
          variant={'default'}
        >
          Previous <span className={'sr-only'}>{`${selectedRowOption} rows`}</span>
        </Button>
        <Button
          disabled={(currentPage + 1) * selectedRowOption >= totalRows}
          id={paginationButtons}
          onClick={() => onClickNextButton(currentPage)}
          variant={'default'}
        >
          Next <span className={'sr-only'}>{`${selectedRowOption} rows`}</span>
        </Button>
      </label>
    </div>
  );
};

export { TablePagination };
