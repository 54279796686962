import { Box, FormControlLabel, Switch, Button } from '@material-ui/core';
import { useState, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ClusterHealthAlert, AlertTypes, AlertService } from '../../../services/alerts';
import TestAlertButton from '../TestAlertButton';

import { HealthAlertModal as CreateHealthAlertModal } from './HealthAlertModal';

const HealthAlert = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { data } = useQuery('alerts', AlertService.getAlerts);
  const createAlert = useMutation(AlertService.upsertAlert, {
    onSuccess: () => {
      queryClient.invalidateQueries('alerts');
    },
  });
  const deleteAlert = useMutation(AlertService.deleteAlert, {
    onSuccess: () => {
      queryClient.invalidateQueries('alerts');
    },
  });

  const clusterHealthAlert = useMemo((): ClusterHealthAlert | undefined => {
    if (!data) return undefined;
    const values = data.find((a) => a.type === 'health');
    if (!values) return undefined;

    return new ClusterHealthAlert({
      ...values,
      type: AlertTypes.Health,
    });
  }, [data]);

  const newAlertCreated = async (alert: ClusterHealthAlert) => {
    await createAlert.mutateAsync(alert);
    setShowDialog(false);
  };

  const deleteAlertClicked = () => {
    if (clusterHealthAlert === undefined) return;
    deleteAlert.mutate(clusterHealthAlert);
  };

  return (
    <div>
      <Box width={260}>
        <FormControlLabel
          checked={clusterHealthAlert !== undefined}
          control={
            <Switch color={'primary'} inputProps={{ 'data-testid': 'alert-health-switch' }} />
          }
          data-test={'cluster-health-switch'}
          label={'Monitor Cluster Health'}
          onChange={async () => {
            if (clusterHealthAlert === undefined) {
              setShowDialog(true);
            } else {
              deleteAlertClicked();
            }
          }}
        />
        {clusterHealthAlert && (
          <Button
            color={'primary'}
            data-testid={'ha-edit-button'}
            onClick={() => {
              setShowDialog(true);
            }}
            style={{ marginRight: 8 }}
            variant={'contained'}
            disableElevation
          >
            Edit
          </Button>
        )}
        {clusterHealthAlert && (
          <span data-testid={'test-alert-button'}>
            <TestAlertButton alert={clusterHealthAlert} />
          </span>
        )}
        <CreateHealthAlertModal
          alert={clusterHealthAlert}
          alertSaved={newAlertCreated}
          cancelClicked={() => setShowDialog(false)}
          open={showDialog}
        />
      </Box>
    </div>
  );
};

export default HealthAlert;
