/* eslint-disable no-restricted-syntax */
import { useAPIClient } from '../../../services/APIClient';
import { parseFilters } from '../../../services/model';
import type { TimeSeriesGraph, TopResultsGraph, ViewQueryOpts } from '../../../services/model';

const allocationKeyMap: Record<string, string> = {
  cluster: 'filterClusters',
  node: 'filterNodes',
  namespace: 'filterNamespaces',
  label: 'filterLabels',
  service: 'filterServices',
  controllerkind: 'filterControllerKinds',
  controller: 'filterControllers',
  pod: 'filterPods',
  department: 'filterDepartments',
  environment: 'filterEnvironments',
  owner: 'filterOwners',
  product: 'filterProducts',
  team: 'filterTeams',
};

type Filter = {
  property: string;
  value: string;
};

type Aggregate =
  | 'cluster'
  | 'container'
  | 'controller'
  | 'controllerkind'
  | 'department'
  | 'environment'
  | 'externalCost'
  | 'namespace'
  | 'node'
  | 'owner'
  | 'pod'
  | 'product'
  | 'service'
  | 'team';

type Allocation = {
  cpuCost: number;
  end?: string;
  gpuCost: number;
  loadBalancerCost: number;
  name: string;
  networkCost: number;
  pvCost: number;
  ramCost: number;
  start?: string;
  totalCost: number;
} & { [k in Aggregate]?: string };

type AllocationSet = {
  results: Allocation[];
  window: {
    end: string;
    start: string;
  };
};

type AccumulatedResp = {
  data: AllocationSet[];
};

const MetricCoefficients = {
  1: 1, // Minutes Per Minute
  2: 60 * 730, // Minutes Per Month (https://github.com/opencost/opencost/blob/develop/pkg/util/timeutil/timeutil.go#L33)
  3: 60 * 24, // Minutes Per Day
  4: 60, // Minutes Per Hour
};

const DetailsAgg = [
  'cluster',
  'node',
  'container',
  'controller',
  'controllerkind',
  'namespace',
  'pod',
  'service',
] as const;

function useAllocationDetailsFn() {
  const client = useAPIClient();

  return async (window: string, filters: Filter[]) => {
    const { data: resp } = await client.get<AccumulatedResp>('/allocation/query', {
      params: {
        window,
        aggregate: DetailsAgg.join(','),
        accumulate: true,
        ...parseFilters(filters, allocationKeyMap),
      },
    });

    return getRows(resp.data[0].results, Array.from(DetailsAgg)).tableResults;
  };
}

function useAllocationsQueryFn() {
  const client = useAPIClient();

  return async (
    window: string,
    params: {
      aggregate: string[];
      filters: Filter[];
      idle?: boolean;
      idleByNode?: boolean;
    },
    viewOpts: ViewQueryOpts,
  ) => {
    const { aggregate, filters, idle = true, idleByNode = false } = params;
    const {
      data: { data: allocations },
    } = await client.get<AccumulatedResp>('/allocation/query', {
      params: {
        window,
        aggregate,
        accumulate: false,
        idle,
        idleByNode,
        ...parseFilters(filters, allocationKeyMap),
      },
    });

    if (allocations.length === 0) {
      return {
        graphData: null,
        totalItems: 0,
        totalsRow: null,
        tableResults: [],
      };
    }

    const costMetric = MetricCoefficients[viewOpts.costMetric] ?? MetricCoefficients[1];

    const collapsed = collapseSets(allocations, costMetric);

    return {
      graphData: getGraphData(collapsed, allocations, viewOpts, costMetric),
      ...getRows(collapsed, aggregate as Aggregate[]),
    };
  };
}

const ensureNum = (x?: number) => x ?? 0;
const sumAllocations = (a?: Allocation, b?: Allocation): Allocation => ({
  name: a?.name ?? b?.name ?? '',
  start: a?.start ?? b?.start,
  end: b?.end ?? a?.end,
  cpuCost: ensureNum(a?.cpuCost) + ensureNum(b?.cpuCost),
  gpuCost: ensureNum(a?.gpuCost) + ensureNum(b?.gpuCost),
  loadBalancerCost: ensureNum(a?.loadBalancerCost) + ensureNum(b?.loadBalancerCost),
  networkCost: ensureNum(a?.networkCost) + ensureNum(b?.networkCost),
  pvCost: ensureNum(a?.pvCost) + ensureNum(b?.pvCost),
  ramCost: ensureNum(a?.ramCost) + ensureNum(b?.ramCost),
  totalCost: ensureNum(a?.totalCost) + ensureNum(b?.totalCost),
});

const sumAllocationList = (allocations: Allocation[]): Allocation =>
  allocations.reduce((acc, alloc) => sumAllocations(acc, alloc), {} as Allocation);

const scaleAllocation = (allocation: Allocation, scale: number): Allocation => ({
  name: allocation.name,
  start: allocation.start,
  end: allocation.end,
  cpuCost: allocation.cpuCost * scale,
  gpuCost: allocation.gpuCost * scale,
  loadBalancerCost: allocation.loadBalancerCost * scale,
  networkCost: allocation.networkCost * scale,
  pvCost: allocation.pvCost * scale,
  ramCost: allocation.ramCost * scale,
  totalCost: allocation.totalCost * scale,
});

const getSetScaleFactor = (set: AllocationSet, metric: number = 1) => {
  const { end, start } = set.window;
  // I don't think the getTime is strictly necessary, but Typescript does
  const minutes = (new Date(end).getTime() - new Date(start).getTime()) / 1000 / 60;

  return metric === 1 ? metric : metric / minutes;
};

const collapseSets = (sets: AllocationSet[], metric: number = 1): Allocation[] => {
  const order: string[] = [];
  const totaledAllocations: Map<string, Allocation> = new Map();

  for (const set of sets) {
    const scale = getSetScaleFactor(set, metric);

    for (const alloc of set.results) {
      if (!order.includes(alloc.name)) {
        order.push(alloc.name);
      }

      if (!totaledAllocations.has(alloc.name)) {
        totaledAllocations.set(alloc.name, alloc);
      } else {
        totaledAllocations.set(
          alloc.name,
          sumAllocations(totaledAllocations.get(alloc.name), scaleAllocation(alloc, scale)),
        );
      }
    }
  }

  // Typescript doesn't seem to understand filter(Boolean). Without the as it things this is (Allocation | undefined)[]
  return order.map((n) => totaledAllocations.get(n)).filter(Boolean) as Allocation[];
};

function getRows(allocations: Allocation[], aggregate: Aggregate[]) {
  return allocations.reduce(
    (acc, alloc) => {
      const nameParts = alloc.name.split('/');

      acc.tableResults.push({
        ...alloc,
        ...aggregate.reduce((aggs, agg, idx) => ({ ...aggs, [agg]: nameParts[idx] }), {}),
      });

      acc.totalsRow = sumAllocations(acc.totalsRow, alloc);

      return acc;
    },
    {
      totalItems: allocations.length,
      tableResults: [],
      totalsRow: {
        name: 'Total',
        start: allocations[0].start,
        end: allocations[allocations.length - 1].end,
        cpuCost: 0,
        gpuCost: 0,
        loadBalancerCost: 0,
        networkCost: 0,
        pvCost: 0,
        ramCost: 0,
        totalCost: 0,
      },
    } as { tableResults: Allocation[]; totalItems: number; totalsRow: Allocation },
  );
}

type GeneratorGroup = {
  costTypes: number[];
  generate: (
    allocations: Allocation[],
    sets: AllocationSet[],
    viewOpts: ViewQueryOpts,
    costMetric: number,
  ) => TopResultsGraph | TimeSeriesGraph;
};

const GraphGenerators: GeneratorGroup[] = [
  {
    costTypes: [1, 4, 5],
    generate: (allocations): TopResultsGraph => ({
      items: allocations
        .map((a) => ({ name: a.name, cost: a.totalCost, efficiency: 0 }))
        .sort((a, b) => b.cost - a.cost)
        .slice(0, 10),
    }),
  },
  {
    costTypes: [2],
    generate: (allocations, sets, viewOpts, metric): TimeSeriesGraph => ({
      graphItems: sets.map((set) => {
        const scale = getSetScaleFactor(set, metric);

        return {
          start: set.window.start,
          end: set.window.end,

          graph: {
            items: set.results
              .sort((a, b) => b.totalCost - a.totalCost)
              .slice(0, 10)
              .map((alloc) => {
                const scaled = scaleAllocation(alloc, scale);

                return {
                  name: scaled.name,
                  cost: scaled.totalCost,
                  efficiency: 0,
                };
              }),
          },
        };
      }),
    }),
  },
];

function getGraphData(
  allocations: Allocation[],
  sets: AllocationSet[],
  viewOpts: ViewQueryOpts,
  costMetric: number,
) {
  const generator = GraphGenerators.find((g) => g.costTypes.includes(viewOpts.chartType));

  return generator?.generate(allocations, sets, viewOpts, costMetric);
}

export type { Aggregate, Allocation };
export { DetailsAgg, useAllocationDetailsFn, useAllocationsQueryFn };
