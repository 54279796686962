import { FC, useCallback, useEffect, useState } from 'react';

import { Grid, Tooltip } from '@material-ui/core';

import { Header } from '../../components/Header2New';
import { FetchStates } from '../../constants';
import usePersistentVolumeSizing from '../../hooks/usePersistentVolumeSizing';
import { toCurrency } from '../../services/format';
import logger from '../../services/logger';
import { defaultHeadroom } from '../PersistentVolumeSizing/PersistentVolumeSizing';

import {
  Api,
  ClusterTotals,
  NamespaceTotals,
  NetworkTotals,
  ProviderAccount,
  ProviderService,
} from './api';
import { GraphCard } from './GraphCard';
import { HighlightCard } from './HighlightCard';
import { SectionBreak } from './SectionBreak';
import { TableCard } from './TableCard';

const defaultNumDays = 7;
const server = new Api();

const Overview: FC = () => {
  const [configsFetchState, setConfigsFetchState] = useState(FetchStates.LOADING);
  const [countsLoading, setCountsLoading] = useState(FetchStates.LOADING);
  const [costsLoading, setCostsLoading] = useState(FetchStates.LOADING);
  const [assetFetchState, setAssetFetchState] = useState(FetchStates.LOADING);
  const [savingsFetchState, setSavingsFetchState] = useState(FetchStates.LOADING);

  const [currency, setCurrency] = useState('USD');
  const [k8sCosts, setK8sCosts] = useState(0);
  const [k8sCostTrend, setK8sCostTrend] = useState(0);
  const [totalCostTrend, setTotalCostTrend] = useState(0);
  const [clusterCount, setClusterCount] = useState(0);
  const [cloudCosts, setCloudCosts] = useState(0);
  const [cloudCostsTrend, setCloudCostsTrend] = useState(0);
  const [providerCount, setProviderCount] = useState(0);
  const [clusters, setClusters] = useState<ClusterTotals[]>([]);
  const [namespaces, setNamespaces] = useState<NamespaceTotals[]>([]);
  const [cloudServices, setCloudServices] = useState<ProviderService[]>([]);
  const [providers, setProviders] = useState<ProviderAccount[]>([]);
  const [allNamespaces, setAllNamespaces] = useState<string[]>([]);
  const [networkTotals, setNetworkTotals] = useState<NetworkTotals[]>([]);
  const [allClusters, setAllClusters] = useState<string[]>([]);
  const [allSavings, setAllSavings] = useState(0);
  const [savingsInitialized, setSavingsInitialized] = useState(false);
  const [totalEfficiency, setTotalEfficiency] = useState(0);
  const [totalEfficienyTrend, setTotalEfficiencyTrend] = useState(0);
  const { isLoading: pvIsLoading, pvTotalSavings } = usePersistentVolumeSizing(defaultHeadroom);
  const [namespaceDailyCostData, setNamespaceDailyCostData] = useState<
    Record<string, number | string>[]
  >([]);
  const [namespaceDailyCountData, setNamespaceDailyCountData] = useState<
    Record<string, number | string>[]
  >([]);
  const [clusterDailyCostData, setClusterDailyCostData] = useState<
    Record<string, number | string>[]
  >([]);
  const [clusterDailyCountData, setClusterDailyCountData] = useState<
    Record<string, number | string>[]
  >([]);

  const refreshCallback = useCallback(() => {
    setConfigsFetchState(FetchStates.LOADING);
    setCountsLoading(FetchStates.LOADING);
    setCostsLoading(FetchStates.LOADING);
    setAssetFetchState(FetchStates.LOADING);
    setSavingsFetchState(FetchStates.LOADING);
  }, []);

  // fetch configs
  useEffect(() => {
    if (configsFetchState !== FetchStates.LOADING) {
      return;
    }
    server
      .initConfigs()
      .then(() => {
        setCurrency(server.currency);
        setConfigsFetchState(FetchStates.DONE);
      })
      .catch((err) => {
        logger.error(err);
        setConfigsFetchState(FetchStates.ERROR);
      });
  }, [configsFetchState]);

  // fetch allocations
  useEffect(() => {
    let costCounter = 0;
    let countCounter = 0;
    if (
      configsFetchState !== FetchStates.DONE ||
      countsLoading !== FetchStates.LOADING ||
      costsLoading !== FetchStates.LOADING
    ) {
      return;
    }
    server.initAllocations(
      defaultNumDays,
      () => {
        countCounter += 1;
        if (countCounter === 7) {
          setAllClusters(Array.from(server.allClusters));
          setAllNamespaces(Array.from(server.allNamespaces));
          setClusters(
            Object.values(server.clusterTotals).sort((a, b) => (a.cost > b.cost ? -1 : 1)),
          );
          setNamespaces(
            Object.values(server.namespaceTotals).sort((a, b) => (a.cost > b.cost ? -1 : 1)),
          );
          setClusterDailyCountData(server.clusterDailyCounts);
          setNamespaceDailyCountData(server.namespaceDailyCounts);
          setCountsLoading(FetchStates.DONE);
        }
      },
      () => {
        costCounter += 1;
        if (costCounter === 16) {
          const diff = (server.kubernetesCostsNew / server.kubernetesCostsOld) * 100 - 100;
          const totalCostsDiff =
            ((server.kubernetesCostsNew + server.cloudCostsNew) /
              (server.kubernetesCostsOld + server.cloudCostsOld)) *
              100 -
            100;
          setK8sCostTrend(diff);
          setTotalCostTrend(totalCostsDiff);
          setAllNamespaces(Array.from(server.allNamespaces));
          setAllClusters(Array.from(server.allClusters));
          setTotalEfficiency(server.totalEfficiency);
          const efficiencyDiff =
            (server.totalEfficiencyNew / server.totalEfficiencyOld) * 100 - 100;
          setTotalEfficiencyTrend(efficiencyDiff);
          setK8sCosts(server.kubernetesCosts);
          setAllClusters(Array.from(server.allClusters));
          setAllNamespaces(Array.from(server.allNamespaces));
          setTotalEfficiency(server.totalEfficiency);
          setClusters(
            Object.values(server.clusterTotals).sort((a, b) => (a.cost > b.cost ? -1 : 1)),
          );
          setNamespaces(
            Object.values(server.namespaceTotals).sort((a, b) => (a.cost > b.cost ? -1 : 1)),
          );
          setNetworkTotals(
            Object.values(server.namespaceNetworkCosts)
              .filter((a) => a.cost > 0.01)
              .sort((a, b) => (a.cost > b.cost ? -1 : 1)),
          );
          setClusterDailyCostData(server.clusterDailyCosts);
          setNamespaceDailyCostData(server.namespaceDailyCosts);
          setCostsLoading(FetchStates.DONE);
        }
      },
    );
  }, [configsFetchState, costsLoading, countsLoading]);

  // fetch assets
  useEffect(() => {
    if (assetFetchState !== FetchStates.LOADING) {
      return;
    }
    server
      .initAssets(defaultNumDays)
      .then(() => {
        setCloudCosts(server.cloudCosts);
        const diff = (server.cloudCostsNew / server.cloudCostsOld) * 100 - 100;
        const totalCostsDiff =
          ((server.cloudCostsNew + server.kubernetesCostsNew) /
            (server.cloudCostsOld + server.kubernetesCostsOld)) *
            100 -
          100;
        setCloudCostsTrend(diff);
        setClusterCount(server.clusterCount);
        setProviders(server.providers);
        setProviderCount(server.providerCount);
        setCloudServices(server.cloudServices);
        setTotalCostTrend(totalCostsDiff);
        setAssetFetchState(FetchStates.DONE);
      })
      .catch((err) => {
        logger.error(err);
        setAssetFetchState(FetchStates.ERROR);
      });
  }, [assetFetchState]);

  // fetch savings data
  useEffect(() => {
    if (savingsFetchState !== FetchStates.LOADING) {
      return;
    }

    server
      .initSavings()
      .then(() => {
        setAllSavings(server.totalSavings);
        setSavingsInitialized(true);
        setSavingsFetchState(FetchStates.DONE);
      })
      .catch((err) => {
        logger.error(err);
        setSavingsInitialized(true);
        setSavingsFetchState(FetchStates.ERROR);
      });
  }, [savingsFetchState]);

  // check for network daemonset
  useEffect(() => {
    server.networkCheck();
  }, []);

  const clusterTableRows = clusters.map((c) => ({
    ...c,
    totalCost: toCurrency(c.cost, currency, 2, false),
  }));
  const accountTableRows = providers.map((provider) => ({
    ...provider,
    totalCost: toCurrency(provider.totalCost, currency, 2, false),
    provider: provider.provider,
  }));
  const cloudTableRows = cloudServices.map((cs) => ({
    ...cs,
    totalCost: toCurrency(cs.totalCost, currency, 2, false),
  }));

  return (
    <>
      <Header
        refreshCallback={refreshCallback}
        title={
          <>
            Overview <div style={{ fontSize: '0.5em' }}>Last {defaultNumDays} Days</div>
          </>
        }
      />

      <section
        className={
          'grid grid-cols-[repeat(auto-fit,_minmax(14rem,_auto))] border-l border-t border-kc-gray-100'
        }
      >
        <HighlightCard
          arrowDirection={k8sCostTrend > 0 ? 'UP' : 'DOWN'}
          content={toCurrency(k8sCosts, currency, 2, true)}
          footer={`Including ${clusterCount} ${clusterCount > 1 ? 'clusters' : 'cluster'}`}
          header={'Kubernetes costs'}
          href={`allocations?window=${defaultNumDays}d`}
          isEfficiency={false}
          loading={costsLoading === FetchStates.LOADING || assetFetchState === FetchStates.LOADING}
          trendIndicator={k8sCostTrend.toFixed(2)}
        />
        <HighlightCard
          arrowDirection={cloudCostsTrend > 0 ? 'UP' : 'DOWN'}
          content={toCurrency(cloudCosts, currency, 2, true)}
          footer={`Including ${providerCount} cloud ${
            providerCount > 1 ? 'providers' : 'provider'
          }`}
          header={'Cloud costs'}
          href={`assets?window=${defaultNumDays}d&agg=service&filters=${encodeURIComponent(
            btoa(JSON.stringify([{ property: 'type', value: 'cloud' }])),
          )}`}
          isEfficiency={false}
          loading={assetFetchState === FetchStates.LOADING}
          trendIndicator={cloudCostsTrend.toFixed(2)}
        />
        <HighlightCard
          arrowDirection={totalCostTrend > 0 ? 'UP' : 'DOWN'}
          content={toCurrency(k8sCosts + cloudCosts, currency, 2, true)}
          footer={'Combined Kubernetes and Cloud costs'}
          header={'Total costs'}
          href={`assets?window=${defaultNumDays}d`}
          isEfficiency={false}
          loading={costsLoading === FetchStates.LOADING || assetFetchState === FetchStates.LOADING}
          trendIndicator={totalCostTrend.toFixed(2)}
        />
        <HighlightCard
          content={
            savingsInitialized && toCurrency(allSavings + pvTotalSavings * 0.65, currency, 2, true)
          }
          footer={'See Recommendations'}
          header={'Possible savings'}
          href={'savings'}
          loading={savingsFetchState === FetchStates.LOADING || pvIsLoading}
        />
        {totalEfficiency >= 0 && totalEfficiency <= 100 ? (
          <HighlightCard
            arrowDirection={totalEfficienyTrend > 0 ? 'UP' : 'DOWN'}
            content={`${totalEfficiency.toFixed(1)}%`}
            footer={`Including ${clusterCount} ${clusterCount > 1 ? 'clusters' : 'cluster'}`}
            header={'Efficiency'}
            href={`allocations?window=${defaultNumDays}d&agg=namespace&chartDisplay=series&idle=shareByCluster`}
            loading={
              costsLoading === FetchStates.LOADING || assetFetchState === FetchStates.LOADING
            }
            trendIndicator={totalEfficienyTrend.toFixed(2)}
            isEfficiency
          />
        ) : (
          <Tooltip
            title={
              'More data is required to report a reliable efficiency number. Try back in a few minutes.'
            }
          >
            <div style={{ height: '100%', width: '100%' }}>
              <HighlightCard
                content={'...'}
                footer={`Including ${clusterCount} ${clusterCount > 1 ? 'clusters' : 'cluster'}`}
                header={'Total Efficiency'}
                href={`allocations?window=${defaultNumDays}d&agg=namespace&chartDisplay=series&idle=shareByCluster`}
                loading={
                  costsLoading === FetchStates.LOADING || assetFetchState === FetchStates.LOADING
                }
              />
            </div>
          </Tooltip>
        )}
      </section>
      <SectionBreak title={'Clusters'} />
      <Grid alignItems={'stretch'} spacing={3} container>
        <Grid lg={6} xs={12} item>
          <TableCard
            columns={[
              { name: 'CLUSTER', prop: 'name' },
              { name: 'COST', prop: 'totalCost' },
            ]}
            emptyCondition={clusterTableRows.length < 2}
            emptyHref={'https://guide.kubecost.com/hc/en-us/articles/4407595970711-Multi-cluster'}
            emptyLinkStyle={{ textDecorationColor: 'rgba(0, 0, 0, 0.5)' }}
            emptySubhead={'Click Here to learn how to add more!'}
            emptyTitle={'Have more clusters?'}
            loading={costsLoading === FetchStates.LOADING}
            rowHref={(row) =>
              `allocations?window=${defaultNumDays}d&context=${encodeURIComponent(
                btoa(JSON.stringify([{ property: 'cluster', value: row.name }])),
              )}&idle=separate&agg=namespace`
            }
            rows={clusterTableRows}
            title={'Cluster breakdown'}
          />
        </Grid>
        <Grid lg={6} xs={12} item>
          <GraphCard
            colorKeys={allClusters}
            data={{
              count: clusterDailyCountData,
              cost: clusterDailyCostData,
            }}
            loading={costsLoading === FetchStates.LOADING}
            selectOpts={[{ display: 'Total Cost', value: 'cost' }]}
            title={'Cluster trends'}
          />
        </Grid>
      </Grid>
      <SectionBreak title={'Namespaces'} />
      <Grid alignItems={'stretch'} spacing={3} container>
        <Grid lg={6} xs={12} item>
          <TableCard
            columns={[
              { name: 'CLUSTER', prop: 'cluster' },
              { name: 'NAMESPACE', prop: 'namespace' },
              { name: 'COST', prop: 'totalCost' },
            ]}
            loading={costsLoading === FetchStates.LOADING}
            rowHref={(row) =>
              `allocations?window=${defaultNumDays}d&filters=${encodeURIComponent(
                btoa(
                  JSON.stringify([
                    { property: 'cluster', value: row.cluster },
                    { property: 'namespace', value: row.namespace },
                  ]),
                ),
              )}&idle=shareByCluster&agg=controller`
            }
            rows={namespaces.map((ns) => ({
              ...ns,
              totalCost: toCurrency(ns.cost, currency, 2, false),
            }))}
            title={'Namespace breakdown'}
          />
        </Grid>
        <Grid lg={6} xs={12} item>
          <GraphCard
            colorKeys={allNamespaces}
            data={{
              cost: namespaceDailyCostData,
              count: namespaceDailyCountData,
            }}
            loading={costsLoading === FetchStates.LOADING}
            selectOpts={[
              { display: 'Total Cost', value: 'cost' },
              { display: '# of Clusters', value: 'count' },
            ]}
            title={'Namespace trends'}
          />
        </Grid>
      </Grid>
      <SectionBreak title={'Cloud Costs Breakdown'} />
      <Grid alignItems={'stretch'} spacing={3} container>
        <Grid lg={6} xs={12} item>
          <TableCard
            columns={[
              { name: 'PROVIDER', prop: 'provider' },
              { name: 'ACCOUNT', prop: 'account' },
              { name: 'COST', prop: 'totalCost' },
            ]}
            emptyCondition={accountTableRows.length < 2}
            emptyHref={
              'https://guide.kubecost.com/hc/en-us/articles/4407595968919-Setting-Up-Cloud-Integrations'
            }
            emptySubhead={'Click here to learn how to add more!'}
            emptyTitle={'Have more providers?'}
            loading={assetFetchState === FetchStates.LOADING}
            rowHref={(row) => {
              if (row.account === '__undefined__') {
                return '';
              }
              const context = [
                { property: 'provider', value: row.provider },
                { property: 'account', value: row.account },
              ];
              return `assets.html?window=${defaultNumDays}d&agg=unaggregated&context=${encodeURIComponent(
                btoa(JSON.stringify(context)),
              )}`;
            }}
            rows={accountTableRows}
            title={'Accounts'}
          />
        </Grid>
        <Grid lg={6} xs={12} item>
          <TableCard
            columns={[
              { name: 'PROVIDER', prop: 'provider' },
              { name: 'SERVICE', prop: 'service' },
              { name: 'COST', prop: 'totalCost' },
            ]}
            emptyCondition={cloudTableRows.length < 2}
            emptyHref={
              'https://guide.kubecost.com/hc/en-us/articles/4407595968919-Setting-Up-Cloud-Integrations'
            }
            emptySubhead={'Click here to learn how to add more!'}
            emptyTitle={'Have more providers?'}
            loading={assetFetchState === FetchStates.LOADING}
            rowHref={(row) =>
              `assets.html?window=${defaultNumDays}d&context=${encodeURIComponent(
                btoa(
                  JSON.stringify([
                    { property: 'provider', value: row.provider },
                    { property: 'service', value: row.service },
                  ]),
                ),
              )}&agg=unaggregated`
            }
            rows={cloudTableRows}
            title={'Services'}
          />
        </Grid>
      </Grid>
      <SectionBreak title={'Network Costs Breakdown'} />
      <Grid alignItems={'stretch'} spacing={3} container>
        <Grid lg={6} xs={12} item>
          <TableCard
            columns={[
              { name: 'NAMESPACE', prop: 'namespace' },
              { name: 'COST', prop: 'networkCost' },
            ]}
            emptyCondition={server.networkDaemonsetNotActive || !networkTotals.length}
            emptyHref={
              server.networkDaemonsetNotActive
                ? 'https://guide.kubecost.com/hc/en-us/articles/4407595973527'
                : ''
            }
            emptySubhead={server.networkDaemonsetNotActive ? 'Learn more' : ''}
            emptyTitle={
              server.networkDaemonsetNotActive
                ? 'Set up the Network Costs Daemonset to see data'
                : 'No namespace network costs recorded yet'
            }
            loading={costsLoading === FetchStates.LOADING || !server.networkCheckComplete}
            rowHref={(row) => `/network.html?window=${defaultNumDays}d&ns=${row.namespace}`}
            rows={
              server.networkDaemonsetNotActive || !networkTotals.length
                ? []
                : networkTotals.map((ns) => ({
                    ...ns,
                    networkCost: toCurrency(ns.cost, currency, 2, false),
                  }))
            }
            title={'Namespace breakdown'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export { Overview };
