import { FC } from 'react';

export const StatCard: FC<StatCardProps> = ({ title, value }) => (
  <div style={{ textAlign: 'center', margin: '1em' }}>
    <div style={{ margin: 'auto', border: '1px solid rgba(0,0,0,0.1)' }}>
      <p style={{ color: 'rgba(0,0,0,0.6)' }}>{title}</p>
      <h1>{value}</h1>
    </div>
  </div>
);

export interface StatCardProps {
  title: string;
  value: string;
}

StatCard.displayName = 'StatCard';
