import { FC } from 'react';

import { NavIconProps } from './types';

const ReportsNavIcon: FC<NavIconProps> = ({ active, ...args }) => (
  <svg
    fill={'none'}
    height={'30'}
    viewBox={'0 0 30 30'}
    width={'30'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...args}
  >
    <rect
      fill={active ? '#63E892' : '#607971'}
      height={'14'}
      rx={'1'}
      width={'5'}
      x={'5'}
      y={'11'}
    />
    <rect
      fill={active ? '#63E892' : '#607971'}
      height={'20'}
      rx={'1'}
      width={'5'}
      x={'12.647'}
      y={'5'}
    />
    <rect
      fill={active ? '#63E892' : '#607971'}
      height={'12'}
      rx={'1'}
      width={'5'}
      x={'20'}
      y={'13'}
    />
  </svg>
);

export { ReportsNavIcon };
