import { Button, Modal, Typography } from '@kubecost-frontend/holster';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { APIClient } from '../../../services/APIClient';
import { useHosted } from '../../../hooks/useHosted';
import { hostedTeamsQueryKey } from '../hooks.ts/api';

type DeleteTeamMemberProps = {
  userId: string;
  teamId: string;
  name: string;
  teamName: string;
};

const DeleteTeamMember = ({ userId, teamId, name, teamName }: DeleteTeamMemberProps) => {
  const { config } = useHosted();
  const queryClient = useQueryClient();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const { mutate } = useMutation({
    mutationFn: () => {
      return APIClient.post(
        `${config?.userServiceAPIUrl}/api/teams/${teamId}/members/${userId}/remove`,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: hostedTeamsQueryKey(teamId) });
    },
  });

  const removeMember = () => {
    mutate();
    setConfirmationOpen(false);
  };

  return (
    <>
      <a
        onClick={() => setConfirmationOpen(true)}
        className='text-xs text-kc-danger hover:cursor-pointer hover:underline'
      >
        Remove Member
      </a>
      <Modal
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        className='w-48'
        title='Cancel Invite'
      >
        <Typography variant='p' className='mb-4'>
          Are you sure you want to remove <span className='font-bold'>{name}</span> as a member from{' '}
          <span className='font-bold'>{teamName}</span>?
        </Typography>
        <div className='flex gap-4'>
          <div className='w-full flex-grow'></div>
          <Button onClick={() => setConfirmationOpen(false)} variant='default'>
            Cancel
          </Button>
          <Button onClick={removeMember} variant='primary'>
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteTeamMember;
