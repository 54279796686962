/**
 * For now its just easier to create a copy of the Allocations page
 * and modify it to work for hosted. That saves us the hassle of supporting
 * both APIs in the same UI.
 * 
 * TODO: DELETE THIS AND UNIFY AS SOON AS POSSIBLE
 */

import { ReactElement, useCallback, useEffect, useState } from 'react';

import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import trim from 'lodash/trim';
import { useLocation, useNavigate } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// holster
import { Button, Alert as HolsterAlert, Typography } from '@kubecost-frontend/holster';

import AggregateByControl from '../../components/AggregateByControlAllocation';
import { Alerts } from '../../components/Alerts';
import { DateSelectorNew } from '../../components/DateSelectorNew';
import { Header } from '../../components/Header2New';
import HostedOnboarding, {withOnboardingEnsured} from '../../components/HostedOnboarding';
import { useHosted } from '../../hooks/useHosted';
import { analytics as Analytics } from '../../services/analytics';
import clusterService from '../../services/cluster';
import ConfigService from '../../services/config';
import { lowerFirst, toVerboseTimeRange } from '../../services/format';
import logger from '../../services/logger';
import {
  TableResults,
  TimeSeriesGraph,
  TopResultsGraph,
  TotalsRow,
  model,
} from '../../services/model';
import {
  deleteAllocationReport,
  listAllocationReports,
  saveAllocationReport,
} from '../../services/reports';
import { AllocationProperties, AllocationReport as Report } from '../../types/allocation';

import AlertsDialog from './AlertsDialog';
import { AllocationTotals } from './allocation';
import { AllocationReport } from './AllocationReport';
import EmptyAllocationData from './AllocationReport/EmptyAllocationData';
import { DetailsDialog } from './DetailsDialog';
import { EditControl } from './EditControl';
import { EditReportDialog } from './EditReportDialog';
import {
  HostedIdleCostAction,
  useHostedIdleViewControl,
  normalizeParam,
} from './hooks/useHostedIdleViewControl';
import useAllocationConfig from './hooks/useAllocationConfig';
import MoreControl from './MoreControl';
import MoreMenu from './MoreMenu';
import OpenReportDialog from './OpenReportDialog';
import { SaveControl } from './SaveControl';
import { SaveReportDialog } from './SaveReportDialog';
import Subtitle from './Subtitle';
import { UnsaveReportDialog } from './UnsaveReportDialog';
import { useAllocationsQueryFn } from './api/AllocationsQuery';
import type { Allocation } from './api/AllocationsQuery';

// Regex to detect tier-related query warnings.
const tierWarningRegex = /Requesting (.+) of data. Tier\[(.+)\] only supports up to (.+) of data/g;

// control options
const windowOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Week-to-date', value: 'week' },
  { label: 'Month-to-date', value: 'month' },
  { label: 'Last week', value: 'lastweek' },
  { label: 'Last month', value: 'lastmonth' },
  { label: 'Last 24h', value: '24h' },
  { label: 'Last 48h', value: '48h' },
  { label: 'Last 7 days', value: '7d' },
  { label: 'Last 30 days', value: '30d' },
  { label: 'Last 60 days', value: '60d' },
  { label: 'Last 90 days', value: '90d' },
];

const rateOptions = [
  { name: 'Cumulative Cost', value: 'cumulative' },
  { name: 'Monthly Rate', value: 'monthly' },
  { name: 'Daily Rate', value: 'daily' },
  { name: 'Hourly Rate', value: 'hourly' },
];

const idleOptions = [
  { name: 'Hide', value: 'hide' },
  { name: 'Separate By Cluster', value: 'separateByCluster' },
  { name: 'Separate By Node', value: 'separateByNode' },
];

const chartDisplayOptions = [
  { name: 'Cost over time', value: 'series' },
  { name: 'Cost', value: 'category' },
  // { name: 'Efficiency over time', value: 'efficiency' },
  { name: 'Proportional cost', value: 'percentage' },
  { name: 'Cost Treemap', value: 'treemap' },
];

const shareSplitOptions = [
  { name: 'Share evenly', value: 'even' },
  { name: 'Share weighted by cost', value: 'weighted' },
];

// drilldown compatible aggregations and exempt rows
const drillDownCompatible = [
  'pod',
  'controller',
  'cluster',
  'namespace',
  'controllerkind',
  'node',
  'service',
  'department',
  'environment',
  'owner',
  'product',
  'team',
];

const drillDownExemptRows = ['__idle__', '__unmounted__', 'Unmounted PVs', 'Undistributable idle'];

export const unwrappedLabels = (labels?: Array<string>): string | null => {
  if (!Array.isArray(labels)) return '';
  return labels.sort().join(',');
};

const AllocationPage = () => {
  // URL data: router location, search params, etc.
  const routerLocation = useLocation();
  const searchParams = new URLSearchParams(routerLocation.search);
  const navigate = useNavigate();
  const { agentOnline, isHostedEnvironment } = useHosted();

  const queryAllocations = useAllocationsQueryFn();

  const [showOnboarding, setShowOnboarding] = useState(false);

  // Allocation data state
  const [totalData, setTotalData] = useState<AllocationTotals | null>(null);
  const [tableData, setTableData] = useState<Allocation[]>();
  const [totalRow, setTotalRow] = useState<Allocation | null>();
  const [graphData, setGraphData] = useState<TimeSeriesGraph | TopResultsGraph | null>();
  const [itemCount, setItemCount] = useState<number>();

  // Form state, which controls form elements, but not the report itself. On
  // certain actions, the form state may flow into the report state.
  const [window, setWindow] = useState(windowOptions[0].value);
  const [aggregateBy, setAggregateBy] = useState<string[]>(['namespace']);

  const [chartDisplay, setChartDisplay] = useState(chartDisplayOptions[0].value);
  const [filters, setFilters] = useState<{ property: string; value: string }[]>([]);

  const defaultConfig = useAllocationConfig();

  const [shareSplit, setShareSplit] = useState('weighted');
  const [rate, setRate] = useState(rateOptions[0].value);

  const [customSharedOverhead, setCustomSharedOverhead] = useState<number | null>(null);

  const [customShareNamespaces, setCustomShareNamespaces] = useState<string[] | null>(null);

  const [customShareLabels, setCustomShareLabels] = useState<string[] | null>([]);

  // Context is used for drill-down; each drill-down gets pushed onto the
  // context stack. Clearing resets to an empty stack. Using a breadcrumb
  // should pop everything above that on the stack.
  const [context, setContext] = useState<
    { key: string; name: string; property: string; value: string }[]
  >([]);

  // page and settings state
  const [init, setInit] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alerts, setAlerts] = useState<
    {
      level: 'success' | 'error' | 'warning' | 'info';
      primary: string;
      secondary: string | ReactElement;
    }[]
  >([]);
  const [snackbar, setSnackbar] = useState<{
    message?: string;
    severity?: 'success' | 'info' | 'warning' | 'error';
  }>({});

  // Report state, including current report and saved options
  const [title, setTitle] = useState('');
  const [savedReports, setSavedReports] = useState<Report[]>([]);

  // Setting details to null closes the details dialog. Setting it to an
  // object describing a controller opens it with that state.
  const [details, setDetails] = useState<null | AllocationProperties>(null);

  const [editDialogAnchorEl, setEditDialogAnchorEl] = useState<EventTarget & HTMLButtonElement>();
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const [openDialogOpen, setOpenDialogOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [unsaveDialogOpen, setUnsaveDialogOpen] = useState(false);
  const [activeReport, setActiveReport] = useState<Report>();

  const { dispatch, idle, idleBy, idleByNode } = useHostedIdleViewControl();

  const handleSetIdle = (nextidle: string) => {
    searchParams.set('idle', nextidle);
    navigate({
      search: `?${searchParams.toString()}`,
    });
    dispatch(nextidle as HostedIdleCostAction);
  };

  const drillDownForRow = useCallback(
    (row: {
        cluster: string;
        controllerkind: string;
        department: string;
        environment: string;
        externalCost: number;
        name: string;
        namespace: string;
        node: string;
        owner: string;
        product: string;
        service: string;
        team: string;
        totalCost: number;
      }) =>
      () => {
        if (!canDrillDown(row)) {
          return;
        }

        /*
         * The drilldown mapping is as follows:
         *
         * cluster -> namespace
         * namespace, label, node, controllerkind -> controller
         * controller, service, deployment, statefulset, daemonset, job -> pod
         * pod -> details
         */
        const levels = [
          ['pod'],
          ['controller', 'service', 'deployment', 'statefulset', 'daemonset', 'job'],
          [
            'namespace',
            'department',
            'environment',
            'owner',
            'product',
            'team',
            'node',
            'controllerkind',
          ],
          ['cluster'],
        ];

        const nameParts = row.name.split('/');

        let nextAgg = '';

        if (aggregateBy[0] === 'pod') {
          const pod = nameParts[nameParts.length - 1];
          const { cluster, node } = row;
          let namespace = '';
          let controllerKind = '';
          let controller = '';

          forEach(context, (ctx) => {
            if (ctx.key === 'namespace') {
              namespace = ctx.value;
            } else if (ctx.key === 'controller') {
              const tokens = ctx.value.split(':');
              if (tokens.length === 2) {
                controllerKind = tokens[0];
                controller = tokens[1];
              } else {
                controller = ctx.value;
              }
            }
          });

          const props: AllocationProperties = {
            cluster,
            node,
            controller,
            controllerKind,
            namespace,
            pod,
            container: '',
            services: [],
            providerID: '',
            labels: {},
            annotations: {},
          };
          openDetails(props);
          return;
        } else if (levels[1].some((l) => aggregateBy.includes(l))) {
          nextAgg = levels[0][0];
        } else if (
          levels[2].some((l) => aggregateBy.includes(l)) ||
          aggregateBy.some((a) => a.startsWith('label:'))
        ) {
          nextAgg = levels[1][0];
        } else if (levels[3].some((l) => aggregateBy.includes(l))) {
          nextAgg = levels[2][0];
        }

        const ctx = aggregateBy.map((aggregation, i) => {
          if (aggregation.startsWith('label:')) {
            return {
              key: 'label',
              name: nameParts[i],
              property: 'label',
              value: `${aggregation.slice(6)}:${nameParts[i]}`,
            };
          }

          return {
            key: aggregation,
            name: nameParts[i],
            property:
              aggregation === 'controllerkind'
                ? 'Controller Kind'
                : aggregation.slice(0, 1).toUpperCase() + aggregation.slice(1),
            value: nameParts[i],
          };
        });

        searchParams.set('agg', nextAgg);
        searchParams.set('context', btoa(JSON.stringify(ctx)));
        navigate({
          search: `?${searchParams.toString()}`,
        });
      },
    [aggregateBy, context, searchParams],
  );

  // Set parameters from the given report
  const selectReport = useCallback((report: Report) => {
    searchParams.set('title', report.title);
    searchParams.set('window', report.window);
    searchParams.set('agg', report.aggregateBy.join(','));
    searchParams.set('chartDisplay', report.chartDisplay);
    searchParams.set('idle', report.idle);
    searchParams.set('rate', report.rate);
    searchParams.set('filters', btoa(JSON.stringify(report.filters)));

    if (report.sharedOverhead != null) {
      searchParams.set('sharedOverhead', String(report.sharedOverhead));
    } else {
      searchParams.delete('sharedOverhead');
    }

    if (report.sharedNamespaces != null) {
      searchParams.set('sharedNamespaces', report.sharedNamespaces.join(','));
    } else {
      searchParams.delete('sharedNamespaces');
    }

    if (report.sharedLabels != null) {
      searchParams.set('sharedLabels', report.sharedLabels.join(','));
    } else {
      searchParams.delete('sharedLabels');
    }

    navigate({
      search: `?${searchParams.toString()}`,
    });
  }, []);

  const saveReport = useCallback(async (report: Report) => {
    try {
      await saveAllocationReport(report);
      setSavedReports(await listAllocationReports());
      setSnackbar({
        message: 'Report saved',
        severity: 'success',
      });
      selectReport(report);
    } catch (err) {
      setSnackbar({
        message: 'Failed to save report',
        severity: 'error',
      });
    }
  }, []);

  const unsaveReport = useCallback(async (report: Report) => {
    try {
      await deleteAllocationReport(report);
      setSavedReports(await listAllocationReports());
      setSnackbar({
        message: 'Report unsaved',
        severity: 'success',
      });
    } catch (err) {
      setSnackbar({
        message: 'Failed to unsave report',
        severity: 'success',
      });
    }
  }, []);

  /*
    A number of effects must be run, in order, to initialize the Allocation view.

    1. Initialization
    This entails fetching the list of saved reports, and setting the first one in the list to be active.
    Making a report "active" means navigating to the URL with query params set according to the report's configuration.

    2. Setting input values
    The state values of all form inputs are read from the URL query params, and set based on those params.
    This is a separate step from initialization, because we want query param changes from sources other than
    initialization to be treated in the same way.

    3a. Setting the report title
    The title of the report is set by either.
    a) finding a saved report whose parameters match current inputs, and using its name
    b) when no matching report is found, generating a reasonable title using the current inputs

    3b. Fetching allocation data
    Once the input values have been set, fetch allocation data for display based on the inputs provided.
    This step can also be triggered by setting the state variable ``fetch`` to ``true``.
    This is to allow refreshing data without changing input parameters.

    NOTE: Steps 3a. and 3b. are only treated separately because 3b. has the extra dependency (``fetch``).

    4. If necessary, compute rate-based data from original data.

    Each of these steps has to be executed separately, but they work together. Getting the reports allows us to determine
    whether there is a default set of inputs to use. All of these inputs flow through the URL navigation, which sets
    state variables. Those state variables are then used to fetch the correct data.
  */

  // 1. initialize
  useEffect(() => {
    initialize();
    return () => setInit(true);
  }, []);

  // 2. parse any context information from the URL and set input values
  useEffect(() => {
    // context

    const urlContext = searchParams.get('context') || '';
    let ctx: Array<{
      key: string;
      name: string;
      property: string;
      value: string;
    }> = [];

    try {
      ctx = JSON.parse(atob(urlContext)) || [];
    } catch (err) {
      ctx = [];
    }

    // details
    const urlDetails = searchParams.get('details') || '';
    let deets: AllocationProperties | null = null;

    try {
      deets = JSON.parse(atob(urlDetails)) || null;
    } catch (err) {
      deets = null;
    }

    // filters
    const urlFilter = searchParams.get('filters') || '';
    let fltr: Array<{ property: string; value: string }> = [];
    try {
      fltr = JSON.parse(atob(urlFilter)) || [];
    } catch (err) {
      fltr = [];
    }

    // shared namespaces
    const sns = searchParams.get('sharedNamespaces');
    if (typeof sns === 'string') {
      setCustomShareNamespaces(
        sns
          .split(',')
          .map((s) => trim(s))
          .filter((s) => !!s),
      );
    } else {
      setCustomShareNamespaces(null);
    }

    // shared overhead
    const soh = searchParams.get('sharedOverhead');
    if (!soh) {
      setCustomSharedOverhead(null);
    } else {
      setCustomSharedOverhead(parseFloat(soh) || null);
    }

    // shared labels
    const sls = searchParams.get('sharedLabels');
    if (typeof sls === 'string') {
      setCustomShareLabels(
        sls
          .split(',')
          .map((s) => trim(s))
          .filter((s) => !!s),
      );
    } else {
      setCustomShareLabels(null);
    }

    // Set properties based on search parameters. Only call each set function
    // if the value would change so that we don't end up needlessly re-fetching
    // data for the same parameter values. (This used to happen on opening a
    // Details dialog.)

    const win = searchParams.get('window');
    if (win !== window) {
      setWindow(win || '7d');
    }

    const agg = searchParams.get('agg');

    if (agg !== aggregateBy.join(',')) {
      if (agg) {
        setAggregateBy(agg.split(','));
      } else {
        setAggregateBy(['namespace']);
      }
    }

    const idl = normalizeParam(searchParams.get('idle') ?? '');
    if (idl !== idleBy || searchParams.get('idle') !== idl) {
      handleSetIdle(idl || 'separateByCluster');
    }

    const titl = searchParams.get('title');
    if (titl !== title) {
      setTitle(titl || 'Last 7 days by namespace daily');
    }

    const split = searchParams.get('split');
    if (split !== shareSplit) {
      setShareSplit(split || 'weighted');
    }

    const display = searchParams.get('chartDisplay');
    if (display !== chartDisplay) {
      setChartDisplay(display || chartDisplayOptions[0].value);
    }

    const r = searchParams.get('rate');
    if (r !== rate) {
      setRate(r || 'cumulative');
    }

    if (btoa(JSON.stringify(ctx)) !== btoa(JSON.stringify(context))) {
      setContext(ctx);
    }

    if (btoa(JSON.stringify(deets)) !== btoa(JSON.stringify(details))) {
      setDetails(deets);
    }

    if (btoa(JSON.stringify(fltr)) !== btoa(JSON.stringify(filters))) {
      setFilters(fltr);
    }
  }, [routerLocation]);

  // 3a. Set report title
  // When parameters change, set report title.
  // The cleanup function calls ``setFetch(true)`` to trigger a data fetch with the new state parameters.
  useEffect(() => {
    if (!init) {
      // Do not continue if the page is still initializing
      return;
    }

    // Use "aggregateBy" by default, but if we're within a context, then
    // only use the top-level context; e.g. if we started by namespace, but
    // drilled down, we'll have (aggregateBy == "controller"), but the
    // report should keep the title of "by namespace".
    let aggBy = aggregateBy;
    if (context.length > 0) {
      aggBy = [context[0].key];
    }

    const curr = {
      window,
      aggregateBy: aggBy,
      rate,
      idle: idleBy,
      filters,
      chartDisplay,
      sharedOverhead: customSharedOverhead || 0,
      sharedNamespaces: customShareNamespaces,
      sharedLabels: customShareLabels,
      title,
    };
    setActiveReport(curr);
    const sr = findSavedReport(curr);
    if (sr) {
      setTitle(sr.title);
    } else {
      setTitle(generateTitle(curr));
    }
    setFetch(true);
  }, [
    window,
    aggregateBy,
    rate,
    idle,
    idleBy,
    idleByNode,
    filters,
    shareSplit,
    chartDisplay,
    init,
    customSharedOverhead,
    customShareNamespaces,
    customShareLabels,
    savedReports,
  ]);

  // 3b. Fetch data.
  useEffect(() => {
    if (!(init && fetch)) {
      return undefined;
    }
    setFetch(false);
    const abortController = new AbortController();
    fetchData(abortController);
    return () => {
      if (!fetch) {
        abortController.abort();
      }
    };
  }, [init, fetch]);

  let chartType = 1;
  if (chartDisplay === 'percentage' || chartDisplay === 'category' || chartDisplay === 'treemap') {
    chartType = 1;
  }
  if (chartDisplay === 'series') {
    chartType = 2;
  }
  if (chartDisplay === 'efficiency') {
    chartType = 3;
  }

  const accumulate = chartDisplay !== 'series' && chartDisplay !== 'efficiency';

  const queryFilters = context.map(({ property, value }) => ({ property, value })).concat(filters);
  let querySharedOverhead = defaultConfig.sharedOverhead;
  let querySharedNamespaces = defaultConfig.shareNamespaces;
  let querySharedLabels = defaultConfig.shareLabelNames;

  if (typeof customSharedOverhead === 'number') {
    querySharedOverhead = customSharedOverhead;
  }
  if (Array.isArray(customShareNamespaces)) {
    querySharedNamespaces = customShareNamespaces;
  }
  if (Array.isArray(customShareLabels)) {
    querySharedLabels = customShareLabels;
  }

  const costMetric = ['cumulative', 'monthly', 'daily', 'hourly'].indexOf(rate) + 1;

  return (
    <div>
      <Header
        helpHref={'https://docs.kubecost.com/kubecost-cloud/cloud-allocations-dashboard'}
        helpTooltip={'Product Documentation'}
        refreshCallback={() => setFetch(true)}
        title={'Allocations'}
      />
      {!loading && alerts.length > 0 ? (
        <div style={{ marginBottom: 20 }}>
          <Alerts alerts={alerts} />
        </div>
      ) : null}

      {init ? (
        <>
          <Typography style={{ overflowWrap: 'break-word' }} variant={'h5'}>
            {title}
          </Typography>
          <Subtitle
            clearContext={() => {
              clearContext();
              navigate({ search: `?${searchParams.toString()}` });
            }}
            context={context}
            goToContext={goToContext}
            report={{
              window,
              aggregateBy,
            }}
          />
          {isHostedEnvironment && !loading && !graphData && (
            <div className={'my-8'}>
              <HolsterAlert
                content={
                  <>
                    Welcome to Kubecost Cloud! We're collecting your data right now, your data will
                    appear in about 15 minutes! Until then, go grab a cup of coffee, check slack, or
                    go do a quick base jump.
                  </>
                }
                title={'Waiting for Data'}
                variant={'info'}
              />
            </div>
          )}
          <div className={'mt-6 flex justify-between'}>
            <DateSelectorNew
              setWindow={(win: string) => {
                searchParams.set('window', win);
                navigate({
                  search: `?${searchParams.toString()}`,
                });
              }}
              window={window}
              windowOptions={windowOptions}
            />
            <div className={'flex items-stretch'}>
              <AggregateByControl
                aggregateBy={aggregateBy}
                setAggregateBy={(aggBy) => {
                  searchParams.set('agg', aggBy.toString());
                  navigate({ search: `?${searchParams.toString()}` });
                }}
              />
              <SaveControl
                saved={Boolean(findSavedReport(activeReport))}
                setSaveDialogOpen={setSaveDialogOpen}
                setUnsaveDialogOpen={setUnsaveDialogOpen}
              />
              <EditControl onClick={(e) => setEditDialogAnchorEl(e.currentTarget)} />

              {activeReport && (
                <EditReportDialog
                  anchorEl={editDialogAnchorEl}
                  chartDisplay={chartDisplay}
                  chartDisplayOptions={chartDisplayOptions}
                  customSharedLabels={customShareLabels}
                  customSharedNamespaces={customShareNamespaces}
                  customSharedOverhead={customSharedOverhead}
                  defaultSharedLabels={defaultConfig.shareLabelNames || []}
                  defaultSharedNamespaces={defaultConfig.shareNamespaces || []}
                  defaultSharedOverhead={defaultConfig.sharedOverhead}
                  filters={filters}
                  idle={idleBy}
                  idleOptions={idleOptions}
                  onClose={() => setEditDialogAnchorEl(undefined)}
                  rate={rate}
                  rateOptions={rateOptions}
                  setChartDisplay={(cd: string) => {
                    searchParams.set('chartDisplay', cd);
                    navigate({
                      search: `?${searchParams.toString()}`,
                    });
                  }}
                  setCustomSharedLabels={(lbls: string) => {
                    if (lbls !== null) {
                      searchParams.set('sharedLabels', lbls);
                    } else {
                      searchParams.delete('sharedLabels');
                    }
                    navigate({
                      search: `?${searchParams.toString()}`,
                    });
                  }}
                  setCustomSharedNamespaces={(ns: string) => {
                    if (ns !== null) {
                      searchParams.set('sharedNamespaces', ns);
                    } else {
                      searchParams.delete('sharedNamespaces');
                    }
                    navigate({
                      search: `?${searchParams.toString()}`,
                    });
                  }}
                  setCustomSharedOverhead={(o: string | null) => {
                    if (o !== null) {
                      searchParams.set('sharedOverhead', o);
                    } else {
                      searchParams.delete('sharedOverhead');
                    }
                    navigate({
                      search: `?${searchParams.toString()}`,
                    });
                  }}
                  setFilters={(fs: { property: string; value: string }[]) => {
                    const fltr = btoa(JSON.stringify(fs));
                    searchParams.set('filters', fltr);
                    navigate({
                      search: `?${searchParams.toString()}`,
                    });
                  }}
                  setIdle={handleSetIdle}
                  setRate={(r: string) => {
                    searchParams.set('rate', r);
                    navigate({
                      search: `?${searchParams.toString()}`,
                    });
                  }}
                  shareTenancyCosts={defaultConfig.shareTenancyCosts}
                />
              )}

              <div className={'relative h-[100%]'}>
                <MoreControl onClick={() => setMoreMenuOpen(true)} />
                {activeReport && moreMenuOpen && (
                  <MoreMenu
                    onClose={() => setMoreMenuOpen(false)}
                    onSelectAlerts={() => {
                      searchParams.set('new-alert', 'true');
                      navigate({ search: `?${searchParams.toString()}` });
                    }}
                    onSelectCsv={() => {
                      if (!tableData) {
                        return;
                      }
                      let w = window;
                      try {
                        const wins = model.relativeToAbsoluteWindows(w).reverse();
                        w = `${wins[0].split(',')[0]},${wins[wins.length - 1].split(',')[1]}`;
                      } catch (err) {
                        logger.warn(err);
                      }
                      w = `"${w}"`;
                      const csvRows: (string | number)[][] = [
                        [
                          'Name',
                          'CPU',
                          'GPU',
                          'RAM',
                          'PV',
                          'Network',
                          'LB',
                          'Shared',
                          'Efficiency',
                          'Total',
                          'Window',
                        ],
                      ];
                      tableData.forEach((row) => {
                        csvRows.push([
                          row.name,
                          row.cpuCost || 0,
                          row.gpuCost || 0,
                          row.ramCost || 0,
                          row.pvCost || 0,
                          row.networkCost || 0,
                          row.loadBalancerCost || 0,
                          row.sharedCost || 0,
                          row.efficiency || 0,
                          row.totalCost || 0,
                          w,
                        ]);
                      });
                      const csv = csvRows.map((row) => row.join(',')).join('\r\n');

                      // Create download link
                      const a = document.createElement('a');
                      a.href = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
                      const filename = title.toLowerCase().replace(/\s/gi, '-');
                      a.setAttribute('download', `${filename}-${Date.now()}.csv`);

                      // Click the link
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                    }}
                    onSelectOpen={() => setOpenDialogOpen(true)}
                    onSelectPDF={() => {
                      model.getAllocationPdf(window, aggregateBy, {
                        accumulate,
                        filters: queryFilters,
                        shareNamespaces: querySharedNamespaces,
                        shareLabels: querySharedLabels,
                        shareCost: querySharedOverhead,
                        shareSplit,
                        shareTenancyCosts: defaultConfig.shareTenancyCosts,
                        external: 'false',
                        idle,
                        idleByNode,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ paddingTop: 100, paddingBottom: 100 }}>
                <CircularProgress />
              </div>
            </div>
          )}
          {!loading && !itemCount && <EmptyAllocationData />}
          {!loading && totalRow && tableData && (itemCount ?? 0) > 0 && (
            <AllocationReport
              aggregateBy={aggregateBy}
              canDrillDown={canDrillDown}
              chartDisplay={chartDisplay}
              count={itemCount}
              drillDownCompatible={drillDownCompatible}
              drillDownExemptRows={drillDownExemptRows}
              drillDownForRow={drillDownForRow}
              graphData={graphData}
              rate={rate}
              tableData={tableData}
              totalData={totalData}
              totalsRow={totalRow}
              window={window}
            />
          )}
        </>
      ) : null}

      <DetailsDialog
        close={() => closeDetails()}
        open={details !== null}
        properties={details}
        service={get(details, 'service', '')}
        window={window}
      />

      {activeReport && (
        <SaveReportDialog
          onClose={() => setSaveDialogOpen(false)}
          open={saveDialogOpen}
          report={activeReport}
          save={saveReport}
          title={title}
        />
      )}

      {activeReport && (
        <UnsaveReportDialog
          onClose={() => setUnsaveDialogOpen(false)}
          open={unsaveDialogOpen}
          report={activeReport}
          title={title}
          unsave={unsaveReport}
        />
      )}

      <AlertsDialog aggregation={aggregateBy[0]} window={window} />

      <OpenReportDialog
        onClose={() => setOpenDialogOpen(false)}
        open={openDialogOpen}
        reports={savedReports}
        selectReport={selectReport}
      />

      <Snackbar autoHideDuration={4000} onClose={() => setSnackbar({})} open={!!snackbar.message}>
        <Alert onClose={() => setSnackbar({})} severity={snackbar.severity} variant={'filled'}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );

  async function initialize() {
    try {
      const srs = await listAllocationReports();
      setSavedReports(srs);
    } catch (error) {
      logger.error(error);

      // setAlerts([
      //  {
      //    primary: 'Failed to initialize page',
      //    secondary: String(error),
      //    level: 'error',
      //  },
      // ]);
    }

    setInit(true);
  }

  function findSavedReport(report?: Report) {
    if (!report) {
      return null;
    }
    if (!isArray(savedReports) || savedReports.length === 0) {
      return null;
    }

    const filtersToStr = (fs: { property: string; value: string }[]) =>
      isArray(fs)
        ? sortBy(fs, 'property')
            .map((f) => `${f.property}=${f.value}`)
            .join('|')
        : '';

    // eslint-disable-next-line no-param-reassign
    report.filterStr = filtersToStr(report.filters);

    for (let i = 0; i < savedReports.length; i += 1) {
      let match =
        savedReports[i].window === report.window &&
        savedReports[i].aggregateBy.join(',') === report.aggregateBy.join(',') &&
        savedReports[i].rate === report.rate &&
        savedReports[i].idle === report.idle &&
        savedReports[i].chartDisplay === report.chartDisplay &&
        filtersToStr(savedReports[i].filters) === report.filterStr;

      match = match && savedReports[i].sharedOverhead === report.sharedOverhead;

      const thisReportNS =
        savedReports[i].sharedNamespaces == null
          ? ''
          : savedReports[i].sharedNamespaces.sort().join(',');
      const thatReportNS =
        report.sharedNamespaces == null ? '' : report.sharedNamespaces.sort().join(',');

      const thisReportLabels = unwrappedLabels(savedReports[i].sharedLabels);
      const thatReportLabels = unwrappedLabels(report.sharedLabels);

      // if the current report has null sharedNamespaces, default to ignoring
      match = match && thisReportNS === thatReportNS;
      match = match && thisReportLabels === thatReportLabels;

      if (match) {
        return savedReports[i];
      }
    }

    return null;
  }

  function closeDetails() {
    searchParams.set('details', btoa(JSON.stringify(null)));
    navigate({ search: `?${searchParams.toString()}` });
  }

  function openDetails(properties: AllocationProperties) {
    searchParams.set('details', btoa(JSON.stringify(properties)));
    navigate({ search: `?${searchParams.toString()}` });
  }

  function clearContext() {
    if (context.length > 0) {
      searchParams.set('agg', context[0].key);
    }
    searchParams.set('context', btoa(JSON.stringify([])));
  }

  function goToContext(i: number) {
    if (!isArray(context)) {
      logger.warn(`context is not an array: ${context}`);
      return;
    }

    if (i > context.length - 1) {
      logger.warn(`selected context out of range: ${i} with context length ${context.length}`);
      return;
    }

    if (i === context.length - 1) {
      logger.warn(`selected current context: ${i} with context length ${context.length}`);
    }

    searchParams.set('agg', context[i + 1].key);
    searchParams.set('context', btoa(JSON.stringify(context.slice(0, i + 1))));
    navigate({ search: `?${searchParams.toString()}` });
  }

  function canDrillDown(row: { externalCost: number; name: string; totalCost: number }) {
    return !drillDownExemptRows.includes(row.name);
  }

  async function fetchData(abortController: AbortController) {
    setLoading(true);

    // Collect new alerts and warnings throughout fetching, then display them all.
    const newAlerts: {
      level: 'error' | 'warning' | 'info' | 'success';
      primary: string;
      secondary: string | ReactElement;
    }[] = [];

    // if the user navigated here to create a report, show instructions on how to do that.
    if (searchParams.get('new-report') === 'true') {
      newAlerts.push({
        primary: 'Creating a new report',
        secondary: `Adjust settings to see the data you want, and save using the bookmark icon on the right.
    Afterward, the saved report will be accessible from the Reports tab. `,
        level: 'success',
      });
    }

    try {
      // TODO: CHeck if we realllly need this here.
      // const clusterInfoMap = await model.clusterInfoMap();

      // combine user-set filters with filters that have been applied due to drilldown contexts
      const queryFilters = context
        .map(({ property, value }) => ({ property, value }))
        .concat(filters);

      // accumulate on non-timeseries views
      const accumulate = chartDisplay !== 'series' && chartDisplay !== 'efficiency';

      // determine sharing parameters using either query parameters or defaults
      let querySharedOverhead;
      let querySharedNamespaces;
      let querySharedLabels;

      if (typeof customSharedOverhead !== 'number') {
        querySharedOverhead = defaultConfig.sharedOverhead;
      } else {
        querySharedOverhead = customSharedOverhead;
      }

      if (!Array.isArray(customShareNamespaces)) {
        querySharedNamespaces = defaultConfig.shareNamespaces;
      } else {
        querySharedNamespaces = customShareNamespaces;
      }

      if (!Array.isArray(customShareLabels)) {
        querySharedLabels = defaultConfig.shareLabelNames;
      } else {
        querySharedLabels = customShareLabels;
      }

      let chartType = 1;
      if (
        chartDisplay === 'percentage' ||
        chartDisplay === 'category' ||
        chartDisplay === 'treemap'
      ) {
        chartType = 1;
      }
      if (chartDisplay === 'series') {
        chartType = 2;
      }
      if (chartDisplay === 'efficiency') {
        chartType = 3;
      }

      const newResp = await queryAllocations(
        window,
        { aggregate: aggregateBy, filters: queryFilters, idle, idleByNode },
        {
          chartType,
          costMetric,
          startIndex: 0,
          maxResults: 0,
        },
      );

      if (newResp) {
        if (newResp && newResp.tableResults.length === 0 && !agentOnline) {
          setShowOnboarding(true);
        }

        setTableData(newResp.tableResults);
        setTotalRow(newResp.totalsRow);
        setItemCount(newResp.totalItems);
        setGraphData(newResp.graphData);

        setLoading(false);
        return;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        setLoading(false);
        return;
      }
      if (err.message.indexOf('404') === 0) {
        newAlerts.push({
          primary: 'Failed to load report data',
          secondary:
            'Please update Kubecost to the latest version, then contact support if problems persist.',
          level: 'warning',
        });
      } else if (err.name === 'AbortError') {
        return;
      } else {
        // eslint-disable-next-line no-console
        console.error(err);
        let secondary = 'Please contact Kubecost support with a bug report if problems persist.';
        if (err.message.length > 0) {
          secondary = err.message;
        }
        newAlerts.push({
          primary: 'Failed to load report data',
          secondary,
          level: 'warning',
        });
      }
      setTableData(undefined);
      setTotalRow(undefined);
      setGraphData(undefined);
    }

    setAlerts(newAlerts);
    setLoading(false);
  }
};

// generateTitle generates a string title from a report object
function generateTitle({
  aggregateBy,
  filters,
  idle,
  rate,
  window,
}: {
  aggregateBy: string[];
  filters: { property: string; value: string }[];
  idle: string;
  rate: string;
  window: string;
}) {
  let windowName = get(find(windowOptions, { value: window }), 'name', '');
  if (windowName === '') {
    windowName = toVerboseTimeRange(window);
  }

  const aggregationName = aggregateBy.join(', ').toLowerCase();
  if (aggregationName === '') {
    logger.warn(`unknown aggregation: ${aggregateBy}`);
  }

  const windowNameLower = lowerFirst(windowName);
  let str = `${rate.slice(0, 1).toUpperCase()}${rate.slice(
    1,
  )} cost for ${windowNameLower} by ${aggregationName}`;

  if (idle === 'share') {
    str = `${str} sharing idle`;
  }
  if (idle === 'hide') {
    str = `${str} hiding idle`;
  }

  if (filters && filters.length > 0) {
    str = `${str} with filters`;
  }

  return str;
}

// maps the cluster identifier to clusterName/clusterId for display
function getClusterNameId(item: { name?: string }, infoMap: Record<string, string>) {
  const clusterId = get(item, 'name', '');

  const info = infoMap[clusterId];
  let clusterName;
  if (info !== undefined) {
    clusterName = get(info, 'name', undefined);
  }

  return clusterService.clusterNameId({ clusterId, clusterName });
}

export default withOnboardingEnsured(AllocationPage);
