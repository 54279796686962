import { Typography } from '@kubecost-frontend/holster';

export const Feedback = () => (
  <div id='feedback'>
    <Typography variant='h5'>
      <strong>Setting for Kubecost Cloud Beta.</strong>
    </Typography>
    <Typography style={{ color: 'gray' }} variant='p'>
      Need help or have feedback? Reach out to{' '}
      <a className={'text-kc-link'} href={'mailto:cloud@kubecost.com'}>
        cloud@kubecost.com
      </a>{' '}
      or join us on Slack in on{' '}
      <a
        className={'text-kc-link'}
        href={'https://join.slack.com/t/kubecost/shared_invite/zt-1dz4a0bb4-InvSsHr9SQsT_D5PBle2rw'}
      >
        #kc-cloud-beta.
      </a>
    </Typography>
  </div>
);
