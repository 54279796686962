import { FC } from 'react';

import reverse from 'lodash/reverse';

// recharts
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { makeStyles } from '@material-ui/core';

import { useClusters } from '../../../contexts/ClusterConfig';
import { accents } from '../../../services/colors';
import { toCurrency } from '../../../services/format';
import { APIAllocationsTimeSeriesGraphPoint, TimeSeriesGraph } from '../../../services/model';

const useStyles = makeStyles({
  tooltip: {
    borderRadius: 2,
    background: 'rgba(255, 255, 255, 0.95)',
    padding: 12,
  },
  tooltipLineItem: {
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  },
});

function toBarLabels(timeSeriesPoint: APIAllocationsTimeSeriesGraphPoint[]) {
  const keyToFill: Record<string, string> = {};
  let p = 0;

  timeSeriesPoint.forEach(({ graph }) => {
    graph.items.forEach(({ name }: { name: string }) => {
      const key = name;
      if (keyToFill[key] === undefined) {
        if (key === '__unallocated__') {
          // unallocated gets black (clean up)
          keyToFill[key] = '#212121';
        } else {
          // non-idle allocations get the next available color
          keyToFill[key] = accents[p];
          p = (p + 1) % accents.length;
        }
      }
    });
  });

  const labels = Object.entries(keyToFill)
    .map(([dataKey, fill]) => ({
      dataKey,
      fill,
    }))
    .sort(({ dataKey: dk1 }, { dataKey: dk2 }) => {
      if (dk1 === '__idle__') {
        return -1;
      }
      if (dk2 === '__idle__') {
        return 1;
      }
      return dk1 > dk2 ? 1 : -1;
    });

  return reverse(labels);
}

function toBar({ end, graph, start }: APIAllocationsTimeSeriesGraphPoint) {
  const points = graph.items.map((item) => ({
    ...item,
    window: { end, start },
  }));
  const bar: Record<string, Date | number | string> = {};

  const dateFormatter = Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  });

  const timeFormatter = Intl.DateTimeFormat(navigator.language, {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC',
  });

  points.forEach((item) => {
    if (!item.window) {
      console.warn(`Allocation ${item.name} is missing a window.`);
      return;
    }
    const windowStart = new Date(item.window.start);
    const windowEnd = new Date(item.window.end);
    const windowHours = (windowEnd.valueOf() - windowStart.valueOf()) / 1000 / 60 / 60;

    bar.start = new Date(windowStart);
    bar.end = new Date(windowEnd);
    if (windowHours >= 24) {
      bar.key = dateFormatter.format(bar.start);
    } else {
      bar.key = timeFormatter.format(bar.start);
    }

    bar[item.name] = item.cost;
  });

  return bar;
}

interface RangeChartProps {
  data: TimeSeriesGraph;
  height: number;
}

// NOTE
// Doing my best with types here, but Recharts' types are just plain wrong in many cases.
// The tooltip's payload claims in the typedef that it will never have a `fill` property
// (hence TypeScript's complaint), but it clearly does and works as-is.
// It also claims `value` may not be defined, but that never seems to happen.
//
// Using some `any`s as a result. Don't hate.
// - Neal
const RangeChart: FC<RangeChartProps> = ({ data, height }) => {
  const classes = useStyles();
  const { modelConfig } = useClusters();
  const barData = data.graphItems.map(toBar);
  const barLabels = toBarLabels(data.graphItems);

  const CustomTooltip = (params: any) => {
    const { active, payload } = params;

    if (!payload || payload.length === 0) {
      return null;
    }

    const dateTimeFormatter = Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    });

    const total = payload.reduce((sum: number, item: { value: number }) => sum + item.value, 0.0);

    let start = null;
    if (payload[0] && payload[0].payload) {
      start = payload[0].payload.start;
    }

    let end = null;
    if (payload[0] && payload[0].payload) {
      end = payload[0].payload.end;
    }

    if (active) {
      return (
        <div className={classes.tooltip}>
          {start && end && (
            <p
              className={classes.tooltipLineItem}
              style={{ color: '#808080', fontSize: '0.75rem' }}
            >{`${dateTimeFormatter.format(start)} to ${dateTimeFormatter.format(end)}`}</p>
          )}
          <p
            className={classes.tooltipLineItem}
            style={{
              color: '#000000',
              marginBottom: '1em',
              marginTop: '.25em',
            }}
          >{`Total: ${toCurrency(total, modelConfig.currencyCode, 0, true)}`}</p>
          {reverse(payload).map((item: any) => (
            <div
              key={item.name}
              style={{
                display: 'grid',
                gridTemplateColumns: '20px 1fr',
                gap: '.5em',
                margin: '.25em',
              }}
            >
              <div>
                <div style={{ backgroundColor: item.fill, width: 18, height: 18 }} />
              </div>
              <div>
                <p className={classes.tooltipLineItem}>{`${item.name}: ${toCurrency(
                  item.value,
                  modelConfig.currencyCode,
                )}`}</p>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer height={height} width={'100%'}>
      <BarChart data={barData} margin={{ top: 30, right: 35, left: 30, bottom: 45 }}>
        <CartesianGrid strokeDasharray={'3 3'} vertical={false} />
        <XAxis dataKey={'key'} />
        <YAxis tickFormatter={(val) => toCurrency(val, modelConfig.currencyCode, 2, true)} />
        <Tooltip content={<CustomTooltip />} wrapperStyle={{ zIndex: 1000 }} />
        {barLabels.map((barLabel, i) => (
          <Bar dataKey={barLabel.dataKey} fill={barLabel.fill} key={i} stackId={'a'} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RangeChart;
