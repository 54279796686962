import { forwardRef, useState } from 'react';

import { TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const Transition = forwardRef((props, ref) => <Slide direction={'up'} ref={ref} {...props} />);

interface UpgradeModalProps {
  closeModal: () => void;
  handleUpgrade: (key: string) => void;
  visible: boolean;
}

export const LicenseKeyModal = ({ closeModal, handleUpgrade, visible }: UpgradeModalProps) => {
  const [keyText, setKeyText] = useState('');

  const bulletPoints = [
    'Full visibility into costs and performance across all Kubernetes clusters',
    'Real-time notifications to avoid cost overruns & product outages',
    'Unified visibility across all cloud environments',
  ];

  return (
    <div>
      <Dialog TransitionComponent={Transition} onClose={closeModal} open={visible} keepMounted>
        <DialogTitle>
          <Typography variant={'h4'}>Upgrade Access</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ color: 'gray' }} variant={'body1'}>
            You can upgrade your free trial to a paid subscription to gain access to more features
            and integrations like the following:
          </Typography>
          {bulletPoints.map((item: string) => (
            <div style={{ display: 'flex', gap: '.5em', marginTop: '1em' }}>
              <CheckCircleOutlineIcon style={{ color: 'green' }} />
              <Typography variant={'body1'}>{item}</Typography>
            </div>
          ))}
          <TextField
            label={'New Key'}
            onChange={(e: any) => setKeyText(e.target.value)}
            style={{ marginTop: '1.5em' }}
            value={keyText}
            variant={'outlined'}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={closeModal}>
            Close
          </Button>
          <Button color={'primary'} disabled={!keyText} onClick={() => handleUpgrade(keyText)}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
