enum FetchStates {
  DONE,
  ERROR,
  INIT,
  LOADING,
}

enum PromiseStates {
  Fulfilled = 'fulfilled',
  Pending = 'pending',
  Rejected = 'rejected',
}

enum Time {
  MinutesPerHour = 60,
  MinutesPerDay = 1440, // 60 * 24
  MinutesPerMonth = 43804.8, // 60 * 24 * 30.42
  HoursPerDay = 24,
  HoursPerMonth = 730.08, // 24 * 30.42
  DaysPerMonth = 30.42, // as determined by mighty Pike
}

const zendeskToken = '7e19179ce2a646439cd1ddec1dcd80db8e3a76a5268dacbee806e10ef23ca725';

const AllocationAggExplanations: Record<string, string> = {
  cluster: 'Cluster the Kubernetes term for a collection of hardware nodes.',
  container: 'Containers are individual runtimes existing on the cluster.',
  controller: 'Controllers are a Kubernetes object that manages pods.',
  controllerkind: 'Controller Kind groups costs by type of controller.',
  daemonset: 'Daemonsets are a type of controller.',
  department: 'Department uses labels on Kubernetes objects to group costs by business unit.',
  deployment: 'Deployments are a type of controller.',
  environment:
    'Environment uses labels on Kubernetes objects to group costs by environment, (e.g. development, production).',
  job: 'Job uses labels on Kubernetes objects to group costs by job function.',
  namespace:
    'Namespaces are top-level Kubernetes grouping constructs. They hold containers, pods, controllers, and more.',
  node: 'A node represents a single unit of hardware in a cluster.',
  owner: 'Owner uses labels on Kubernetes objects to group costs by the owner of that resource.',
  pod: 'A pod is the atomic unit of Kubernetes.',
  product: 'Product uses labels on Kubernetes objects to group costs by the product they support.',
  service: 'A Service is a Kubernetes object that exposes an interface to outside consumers.',
  statefulset: 'Statefulsets are a type of controller.',
  team: 'Team uses labels on Kubernetes objects to group costs by team.',
};

export { AllocationAggExplanations, FetchStates, PromiseStates, Time, zendeskToken };
