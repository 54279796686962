import React from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { TeamMembersTable } from './TeamMemberTable';
import { useGetHostedTeam } from '../hooks.ts/api';
import { Icon, Typography } from '@kubecost-frontend/holster';
import { InviteTeamMember } from './InviteTeamMember';
import { CloseIcon } from '../../../assets/images';

export const EditTeamDrawer = ({ teamId, onClose }) => {
  const { data } = useGetHostedTeam(teamId);

  return (
    <Transition.Root show={teamId !== undefined} as={Fragment}>
      <Dialog as='div' className='relative' onClose={onClose}>
        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-300 sm:duration-300'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-300 sm:duration-200'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-2xl'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-kc-calcite py-6 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                      <div className='flex items-start justify-between'>
                        <Dialog.Title className='text-gray-900 text-lg font-medium'>
                          Edit {data && data.name}
                        </Dialog.Title>
                        <div className='ml-3 flex h-7 items-center'>
                          <button
                            type='button'
                            className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2'
                            onClick={() => onClose()}
                          >
                            <CloseIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                      <Typography variant='h5'>Members</Typography>
                      {data && <TeamMembersTable team={data} />}
                      {data && <InviteTeamMember teamId={data.id} />}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
