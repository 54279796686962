import { IconProps } from './types';

const DownloadIcon = ({ ...args }: IconProps) => (
  <svg
    height={'1em'}
    preserveAspectRatio={'xMidYMid meet'}
    viewBox={'0 0 24 24'}
    width={'1em'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...args}
  >
    <path
      d={
        'M12 15V3m0 12l-4-4m4 4l4-4M2 17l.621 2.485A2 2 0 0 0 4.561 21h14.878a2 2 0 0 0 1.94-1.515L22 17'
      }
      fill={'none'}
      stroke={'currentColor'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      strokeWidth={'2'}
    />
  </svg>
);

export { DownloadIcon };
