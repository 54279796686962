import { ReportConfig, VisualAddOnType } from './reportingHelpers';

export const bogusReportConfig: ReportConfig = {
  id: 0,
  lastTotal: 0,
  name: 'Sample',
  includeAllocationData: true,
  includeAssetData: true,
  allocationAggregationBy: 'namespace',
  assetAggregateBy: 'service',
  assetLabelOverrides: [],
  window: '7d',
  startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
  endDate: new Date(),
  filtersInclude: [],
  filtersExclude: [],
  sharedOverheadCost: 0,
  sharedNamespaces: '',
  sharedLabels: '',
  shareBy: '',
  visualAddOns: [],
  dataMap: [],
};

export const sampleReportConfig: ReportConfig = {
  id: 1,
  lastTotal: 1015, // TODO
  name: 'Sample Report',
  includeAllocationData: true,
  includeAssetData: true,
  allocationAggregationBy: 'namespace',
  assetAggregateBy: 'service',
  assetLabelOverrides: [],
  window: '7d',
  startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
  endDate: new Date(),
  filtersInclude: [],
  filtersExclude: [],
  sharedOverheadCost: 0,
  sharedNamespaces: '',
  sharedLabels: '',
  shareBy: 'weighted',
  // a visual Add-On should be for entire data set, ?or for a single item in table?
  visualAddOns: [
    {
      name: 'Total Cost Card',
      type: VisualAddOnType.Card,
      config: {
        label: 'Total Cost',
        action: 'sum',
        dataKey:
          'assets.totalCost,allocation.cpuCost,allocation.gpuCost,allocation.ramCost,allocation.pvCost,allocation.networkCost,allocation.sharedCost',
      },
    },
    {
      name: 'Allocation Total Card',
      type: VisualAddOnType.Card,
      config: {
        label: 'Allocation Cost',
        action: 'sum',
        dataKey:
          'allocation.cpuCost,allocation.gpuCost,allocation.ramCost,allocation.pvCost,allocation.networkCost,allocation.sharedCost',
      },
    },
    {
      name: 'Asset Total Card',
      type: VisualAddOnType.Card,
      config: {
        label: 'Asset Total',
        dataKey: 'assets.totalCost',
      },
    },
  ],
  dataMap: [
    { columnName: 'Name', dataKey: 'allocation.name' },
    { columnName: 'CPU', dataKey: 'allocation.cpuCost' },
    { columnName: 'GPU', dataKey: 'allocation.gpuCost' },
    { columnName: 'RAM', dataKey: 'allocation.ramCost' },
    { columnName: 'PV', dataKey: 'allocation.pvCost' },
    { columnName: 'Network', dataKey: 'allocation.networkCost' },
    { columnName: 'Shared', dataKey: 'allocation.sharedCost' },
    { columnName: 'External', dataKey: 'assets.totalCost' },
    { columnName: 'Efficiency', action: 'costEfficiency', dataKey: '' },
    {
      columnName: 'Total Cost',
      action: 'sum',
      dataKey:
        'assets.totalCost,allocation.cpuCost,allocation.gpuCost,allocation.ramCost,allocation.pvCost,allocation.networkCost,allocation.sharedCost',
    },
  ],
};

export const templateReportConfig: ReportConfig = {
  id: 2,
  lastTotal: 1015,
  name: 'Chargeback Template',
  includeAllocationData: true,
  includeAssetData: true,
  allocationAggregationBy: 'namespace',
  assetAggregateBy: 'service',
  assetLabelOverrides: [],
  window: '7d',
  startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
  endDate: new Date(),
  filtersInclude: [],
  filtersExclude: [],
  sharedOverheadCost: 0,
  sharedNamespaces: '',
  sharedLabels: '',
  shareBy: '',
  // a visual Add-On should be for entire data set, ?or for a single item in table?
  visualAddOns: [
    {
      name: 'Total Cost Card',
      type: VisualAddOnType.Card,
      config: {
        label: 'Total Cost',
        action: 'sum',
        dataKey:
          'assets.totalCost,allocation.cpuCost,allocation.gpuCost,allocation.ramCost,allocation.pvCost,allocation.networkCost,allocation.sharedCost',
      },
    },
  ],
  dataMap: [
    { columnName: 'Name', dataKey: 'allocation.name' },
    { columnName: 'CPU', dataKey: 'allocation.cpuCost' },
    { columnName: 'GPU', dataKey: 'allocation.gpuCost' },
    { columnName: 'RAM', dataKey: 'allocation.ramCost' },
    { columnName: 'PV', dataKey: 'allocation.pvCost' },
    { columnName: 'Network', dataKey: 'allocation.networkCost' },
    { columnName: 'Shared', dataKey: 'allocation.sharedCost' },
    { columnName: 'External', dataKey: 'assets.totalCost' },
    {
      columnName: 'Total Cost',
      action: 'sum',
      dataKey:
        'assets.totalCost,allocation.cpuCost,allocation.gpuCost,allocation.ramCost,allocation.pvCost,allocation.networkCost,allocation.sharedCost',
    },
  ],
};
