import { memo, useState } from 'react';

import get from 'lodash/get';
import round from 'lodash/round';

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { useClusters } from '../../contexts/ClusterConfig';
import { toCurrency } from '../../services/format';
import { Allocation } from '../../types/allocation';

type DetailsRowProps = {
  allocation: Allocation;
};

const DetailsRow = ({ allocation }: DetailsRowProps) => {
  const [open, setOpen] = useState(false);
  const { modelConfig } = useClusters();

  const container = get(allocation, 'properties.container', '');

  // Init breakdown values
  const hours = round(get(allocation, 'minutes', 0.0) / 60.0, 2);
  let costPerHour = 0;
  let cpu = 0;
  let cpuCostPerCoreHr = 0;
  let ram = 0;
  let ramGiB = 0;
  let ramCostPerGiBHr = 0;
  let gpu = 0;
  let gpuCostPerGPUHr = 0;
  let pv = 0;
  let pvGiB = 0;
  let pvCostPvGIBHr = 0;
  let networkCostPerHr = 0;
  let lbCostPerHR = 0;
  let sharedCostPerHR = 0;
  let externalCostPerHR = 0;

  if (hours > 0) {
    // Total Cost Rate
    costPerHour = round(get(allocation, 'totalCost', 0.0) / hours, 3);

    // CPU breakdown values
    cpu = get(allocation, 'cpuCores', 0.0);
    if (cpu !== 0) {
      cpuCostPerCoreHr = get(allocation, 'cpuCost', 0.0) / (cpu * hours);
    }

    // Ram breakdown values
    ram = get(allocation, 'ramBytes', 0.0);
    ramGiB = ram / 1024 / 1024 / 1024;
    if (ramGiB !== 0) {
      ramCostPerGiBHr = get(allocation, 'ramCost', 0.0) / (ramGiB * hours);
    }

    // GPU breakdown values
    gpu = get(allocation, 'gpuCount', 0.0);
    if (gpu !== 0) {
      gpuCostPerGPUHr = get(allocation, 'gpuCost', 0.0) / (gpu * hours);
    }
    // PV breakdown values
    pv = get(allocation, 'pvBytes', 0.0);
    pvGiB = pv / 1024 / 1024 / 1024;
    pvCostPvGIBHr = get(allocation, 'pvCost', 0.0) / (pvGiB * hours);

    // Network breakdown values
    networkCostPerHr = get(allocation, 'networkCost', 0.0) / hours;

    // LoadBalancer breakdown values
    lbCostPerHR = get(allocation, 'loadBalancerCost', 0.0) / hours;

    // Shared breakdown values
    sharedCostPerHR = get(allocation, 'sharedCost', 0.0) / hours;

    // External breakdown values
    externalCostPerHR = get(allocation, 'externalCost', 0.0) / hours;
  }

  return (
    <>
      <TableRow data-test={'allocation-detail-row'}>
        <TableCell width={25}>
          <IconButton
            aria-label={'expand allocation'}
            onClick={() => setOpen(!open)}
            size={'small'}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align={'left'} scope={'allocation'} width={200}>
          {container}
        </TableCell>
        <TableCell align={'right'} data-test={'hours'} scope={'allocation'}>
          {hours}
        </TableCell>
        <TableCell align={'right'} data-test={'costPerHour'} scope={'allocation'}>
          {toCurrency(costPerHour, modelConfig.currencyCode, 3)}
        </TableCell>
        <TableCell align={'right'} data-test={'totalCost'} scope={'allocation'}>
          {toCurrency(allocation.totalCost, modelConfig.currencyCode, 2)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout={'auto'} unmountOnExit>
            <Box p={6}>
              <Typography component={'div'} variant={'h6'} gutterBottom>
                {container} | Resource Breakdown:
              </Typography>
              <Table aria-label={'breakdown'} size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Resource</TableCell>
                    <TableCell>Resource Amount</TableCell>
                    <TableCell>Hourly-Rate</TableCell>
                    <TableCell>Adjustment</TableCell>
                    <TableCell>Cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allocation.cpuCost !== 0 && (
                    <TableRow>
                      <TableCell>CPU</TableCell>
                      <TableCell>{cpu} Cores</TableCell>
                      <TableCell>
                        {toCurrency(cpuCostPerCoreHr, modelConfig.currencyCode, 3)}
                        /CoreHR
                      </TableCell>
                      <TableCell>
                        {toCurrency(allocation.cpuCostAdjustment, modelConfig.currencyCode, 3)}
                      </TableCell>
                      <TableCell>
                        {toCurrency(allocation.cpuCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {allocation.ramCost !== 0 && (
                    <TableRow>
                      <TableCell>RAM</TableCell>
                      <TableCell>{round(ramGiB, 3)} GiB</TableCell>
                      <TableCell>
                        {toCurrency(ramCostPerGiBHr, modelConfig.currencyCode, 3)}
                        /GiBHR
                      </TableCell>
                      <TableCell>
                        {toCurrency(allocation.ramCostAdjustment, modelConfig.currencyCode, 3)}
                      </TableCell>
                      <TableCell>
                        {toCurrency(allocation.ramCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {allocation.gpuCost !== 0 && (
                    <TableRow>
                      <TableCell>GPU</TableCell>
                      <TableCell>{gpu} GPU</TableCell>
                      <TableCell>
                        {toCurrency(gpuCostPerGPUHr, modelConfig.currencyCode, 3)}
                        /GPUHR
                      </TableCell>
                      <TableCell>
                        {toCurrency(allocation.gpuCostAdjustment, modelConfig.currencyCode, 3)}
                      </TableCell>
                      <TableCell>
                        {toCurrency(allocation.gpuCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {allocation.pvCost !== 0 && (
                    <TableRow>
                      <TableCell>PV</TableCell>
                      <TableCell>{round(pvGiB, 3)} GiB</TableCell>
                      <TableCell>
                        {toCurrency(pvCostPvGIBHr, modelConfig.currencyCode, 3)}
                        /GiBHR
                      </TableCell>
                      <TableCell>
                        {toCurrency(allocation.pvCostAdjustment, modelConfig.currencyCode, 3)}
                      </TableCell>
                      <TableCell>
                        {toCurrency(allocation.pvCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {allocation.networkCost !== 0 && (
                    <TableRow>
                      <TableCell>Network</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>
                        {toCurrency(networkCostPerHr, modelConfig.currencyCode, 3)}
                        /HR
                      </TableCell>
                      <TableCell>
                        {toCurrency(allocation.networkCostAdjustment, modelConfig.currencyCode, 3)}
                      </TableCell>
                      <TableCell>
                        {toCurrency(allocation.networkCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {allocation.loadBalancerCost !== 0 && (
                    <TableRow>
                      <TableCell>Load Balancer</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>
                        {toCurrency(lbCostPerHR, modelConfig.currencyCode, 3)}
                        /HR
                      </TableCell>
                      <TableCell>
                        {toCurrency(
                          allocation.loadBalancerCostAdjustment,
                          modelConfig.currencyCode,
                          3,
                        )}
                      </TableCell>
                      <TableCell>
                        {toCurrency(allocation.loadBalancerCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {allocation.sharedCost !== 0 && (
                    <TableRow>
                      <TableCell>Shared</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>
                        {toCurrency(sharedCostPerHR, modelConfig.currencyCode, 3)}
                        /HR
                      </TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>
                        {toCurrency(allocation.sharedCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {allocation.externalCost !== 0 && (
                    <TableRow>
                      <TableCell>Shared</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>
                        {toCurrency(externalCostPerHR, modelConfig.currencyCode, 3)}
                        /HR
                      </TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>
                        {toCurrency(allocation.externalCost, modelConfig.currencyCode, 2)}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default memo(DetailsRow);
