import React from 'react';
import { useHosted } from '../hooks/useHosted';
import { Alert } from '@kubecost-frontend/holster';
import { useNavigate } from 'react-router';

const TeamInviteBanner = () => {
  const { isHostedEnvironment, profile } = useHosted();
  const navigate = useNavigate();

  return (
    <div className="mb-8 hover:cursor-pointer">
      {isHostedEnvironment && profile.pending_invites.length > 0 && (
        <Alert
          title="Team Invite"
          variant="info"
          onClose={() => {}}
          onClick={() => navigate('/settings#pending-invites')}
          content={
            <>
              {profile.pending_invites.length === 1 && (
                <>
                  You've been invited to join{' '}
                  <span className="font-bold">
                    {profile.pending_invites[0].team.name}
                  </span>
                  ! Click here to review!
                </>
              )}
              {profile.pending_invites.length > 1 && (
                <>
                  You have multiple invitations awaiting your response! Click
                  here to review!
                </>
              )}
            </>
          }
        ></Alert>
      )}
    </div>
  );
};

export default TeamInviteBanner;
