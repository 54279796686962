import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from 'react-query';
import clsx from 'clsx';
import {
  Button,
  Card,
  CodeBlock,
  Icon,
  Input,
  Modal,
  Typography,
} from '@kubecost-frontend/holster';
import { makeStyles } from '@material-ui/core';

import { useHosted } from '../hooks/useHosted';
import { APIClient } from '../services/APIClient';

import { Header } from './Header2New';

const useStyles = makeStyles({
  codeblock: {
    '& > div > span': {
      maxWidth: '100%',
      display: 'block !important',
    },
  },
});

type ConnectStepProps = {
  initial?: boolean;
  onConnected?: () => void;
};

type HostedOnboardingProps = {
  onClose: () => void;
  show: boolean;
  title?: string;
} & Omit<ConnectStepProps, 'onConnected'>;

type ClusterInfoMap = {
  data: Record<string, any>;
};

const getInstallCommand = (
  token: string,
  id: string,
  server: string,
  imageVersion: string,
) => `helm upgrade --install kubecost-cloud \\
--repo https://kubecost.github.io/cost-analyzer/ cost-analyzer \\
--namespace kubecost-cloud --create-namespace \\
-f https://raw.githubusercontent.com/kubecost/cost-analyzer-helm-chart/develop/cost-analyzer/values-cloud-agent.yaml  \\
--set imageVersion="${imageVersion}" \\
--set cloudAgentKey="${token}" \\
--set cloudAgentClusterId="${id}" \\
--set cloudReportingServer="${server}" \\
--set networkCosts.enabled=true
`;

const useConnectionInfo = () => {
  const { config, currentTeamId } = useHosted();

  return {
    reportingServer: config?.reportingServer,
    imageVersion: config?.agentImageVersion ?? 'lunar-sandwich.v0.0.6',
    token: btoa(
      JSON.stringify({
        teamId: currentTeamId,
      }),
    ),
  };
};

const ConnectSteps = ({ initial = true, onConnected: handleOnConnected }: ConnectStepProps) => {
  const { reportingServer, token, imageVersion } = useConnectionInfo();
  const styles = useStyles();

  const [clusterId, setClusterId] = useState<string>();
  const handleOnClusterIdChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setClusterId(e.target.value),
    [setClusterId],
  );

  const { data, isLoading } = useQuery({
    queryKey: ['allocation-onboarding'],
    refetchInterval: 5000,
    queryFn: async () => APIClient.get<ClusterInfoMap>('clusterInfoMap'),
  });

  useEffect(() => {
    if (clusterId == null && data?.data?.data) {
      setClusterId(`cluster-${Object.keys(data.data.data).length + 1}`);
    }
  }, [clusterId, data]);

  // Results return at least one cluster
  const agentOnline = data && !isEmpty(data.data.data);
  const isValidClusterId = clusterId && !isLoading && !data?.data?.data?.[clusterId];

  let clusterIdHelper = null;

  if (clusterId && isLoading) {
    clusterIdHelper = <span>Checking Id...</span>;
  } else if (!isLoading && !clusterId) {
    clusterIdHelper = <span className={'text-kc-danger'}>Cannot be Empty</span>;
  } else if (!isLoading && clusterId && data?.data.data?.[clusterId]) {
    // There is a race condition with this check, but hopefully this will help cut down on duplicate ids.
    clusterIdHelper = <span className={'text-kc-danger'}>ID Already in Use</span>;
  }

  return (
    <div>
      <div className={'my-4'}>
        {initial && agentOnline && (
          <Card className={'mb-8 flex gap-4 border border-kc-gray-100 p-4'}>
            <div className={'flex-1'}>
              <Typography className={'font-bold'} variant={'p'}>
                Agent Online
              </Typography>
              <Typography variant={'p'}>Your initial install is done!</Typography>
            </div>
            <Button onClick={handleOnConnected}>Take me to my data</Button>
          </Card>
        )}
        {initial && !agentOnline ? (
          <>
            <Typography variant={'p'}>
              To get started, install the Kubecost Agent on each cluster you want to monitor.
            </Typography>
          </>
        ) : (
          <Typography variant={'p'}>
            Install the Kubecost Agent on each cluster you want us to monitor
          </Typography>
        )}
        <div className={'mt-4'}>
          <label>
            <span>Simply choose a unique ID for this cluster:</span>
            <br />
            <Input
              disabled={isLoading}
              helperText={clusterIdHelper}
              onChange={handleOnClusterIdChange}
              value={clusterId}
            />
          </label>
        </div>
        <Typography className={'mt-4'} variant={'p'}>
          Point your Kubectl to the cluster you want to monitor and <br />
          execute the script below!
        </Typography>
      </div>
      <div className={'my-4'}>
        <div
          className={clsx(
            styles.codeblock,
            isValidClusterId && reportingServer ? '' : 'pointer-events-none select-none opacity-50',
          )}
        >
          <CodeBlock
            language={'bash'}
            showLineNumbers={false}
            text={getInstallCommand(token, clusterId ?? '', reportingServer ?? '', imageVersion)}
            copy
          />
        </div>
      </div>
      <Typography className={'my-4'} variant={'p'}>
        <a
          className={'text-kc-link'}
          href={'https://helm.sh/docs/intro/install/'}
          target={'_blank'}
        >
          Requires Helm Client (v3.1+)
        </a>
      </Typography>

      {!initial && (
        <>
          <hr className={'border-kc-white-200'} />
          <div className={'flex gap-4'}>
            {!agentOnline && (
              <>
                <Icon className={'mt-5 h-5 w-4'} variant={'warning'} />
                <Typography className={'mt-5 font-bold'} variant={'p'}>
                  Agent Offline
                </Typography>

                <Typography className={'mt-5 flex-grow'} variant={'p'}>
                  We'll let you know when we see your agent!
                </Typography>
                {/* <Typography
                  className={'mt-5 text-kc-gray-200 hover:cursor-pointer hover:underline'}
                  onClick={() => onClose()}
                  variant={'p'}
                >
                  Skip This
                </Typography> */}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const HostedOnboarding = ({
  initial = false,
  onClose: handleOnClose,
  show,
  title,
}: HostedOnboardingProps) => (
  <Modal onClose={handleOnClose} open={show} title={title ?? 'Welcome to Kubecost Cloud!'}>
    <ConnectSteps initial={initial} onConnected={handleOnClose} />
  </Modal>
);

type EnsureOnboardingProps = {
  children: React.ReactNode;
};

const EnsureOnboarding = ({ children }: EnsureOnboardingProps) => {
  const { isHostedEnvironment, onlineAgents } = useHosted();
  const hasAgents = onlineAgents && Object.keys(onlineAgents).length > 0;

  const [connected, setConnected] = useState<boolean>(false);
  const handleOnConnected = useCallback(() => setConnected(true), [setConnected]);

  if (!isHostedEnvironment || hasAgents || connected) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <div>
      <Header
        title={'Welcome to Kubecost Cloud!'}
        helpHref={'https://docs.kubecost.com/kubecost-cloud/cloud-installation-and-onboarding'}
        helpTooltip={'Product Documentation'}
      />
      <ConnectSteps onConnected={handleOnConnected} initial />
    </div>
  );
};

export function withOnboardingEnsured<T extends object>(Cmp: React.ComponentType<T>) {
  const Wrapped = React.forwardRef<typeof Cmp, T>((props, ref) => (
    <EnsureOnboarding>
      <Cmp ref={ref} {...props} />
    </EnsureOnboarding>
  ));

  Wrapped.displayName = `OnboardingEnsured<${Cmp.displayName ?? Cmp.name}>`;

  return Wrapped;
}

export default HostedOnboarding;
