import ConfigService from '../config';
import { getCurrentContainerAddressModel } from '../util';

import GCP from './gcp';

/**
 * ProviderService
 *
 * Abstract interface for querying provider-specific information.
 * Delegates calls to concrete, provider-specific implementations
 * based on the value of ``ConfigService.provider``.
 */
class ProviderService {
  constructor() {
    this.persistentVolumes = null;
    this.unassignedAddresses = null;
    this.unassignedDisks = null;
  }

  async getPersistentVolumes() {
    if (this.persistentVolumes === null) {
      return this.fetchPersistentVolumes();
    }
    return this.persistentVolumes;
  }

  async getUnassignedAddresses() {
    if (this.unassignedAddresses === null) {
      return this.fetchUnassignedAddresses();
    }
    return this.unassignedAddresses;
  }

  async getUnassignedDisks() {
    if (this.unassignedDisks === null) {
      return this.fetchUnassignedDisks();
    }
    return this.unassignedDisks;
  }

  async fetchPersistentVolumes() {
    const response = await fetch(`${getCurrentContainerAddressModel()}/allPersistentVolumes`);
    const data = await response.json();
    this.persistentVolumes = data.items.filter((item) => item.status.phase !== 'Bound');
    return this.persistentVolumes;
  }

  async fetchUnassignedAddresses() {
    const dataPromise = fetch(`${getCurrentContainerAddressModel()}/projectAddresses`).then(
      (response) => response.json(),
    );
    const providerPromise = ConfigService.getProvider();
    const [data, provider] = await Promise.all([dataPromise, providerPromise]);

    if (provider === 'GCP') {
      this.unassignedAddresses = GCP.computeUnassignedAddresses(data.items);
    } else if (data.Addresses) {
      this.unassignedAddresses = data.Addresses.filter((addr) => !addr.AssociationId).map(
        (addr) => ({ ...addr, savings: 0.005 * 730 }),
      );
    } else {
      this.unassignedAddresses = 0;
    }
    return this.unassignedAddresses;
  }

  async fetchUnassignedDisks() {
    const dataPromise = fetch(`${getCurrentContainerAddressModel()}/projectDisks`).then(
      (response) => response.json(),
    );
    const providerPromise = ConfigService.getProvider();
    const [data, provider] = await Promise.all([dataPromise, providerPromise]);

    if (provider === 'GCP') {
      this.unassignedDisks = GCP.computeUnassignedDisks(data.items);
    } else if (data.Volumes) {
      this.unassignedDisks = data.Volumes.filter((volume) => !volume.Attachments).map((volume) => ({
        ...volume,
        savings: getHourlyStoragePrice('standard', 'us-central', 'gcp', true) * volume.Size,
      }));
    } else {
      this.unassignedDisks = 0;
    }
    return this.unassignedDisks;
  }
}

export default new ProviderService();
