import { FC, useState } from 'react';

import Popover from '@material-ui/core/Popover';

import { Chip, FormControlLabel, Input, Select, Typography } from '@kubecost-frontend/holster';

import { useClusters } from '../../contexts/ClusterConfig';
import { pluralize, toCurrency } from '../../services/format';

import { AddFilter } from './AddFilter';

interface EditReportDialogProps {
  anchorEl?: Element;
  chartDisplay: string;
  chartDisplayOptions: { name: string; value: string }[];
  customSharedLabels: string[] | null;
  customSharedNamespaces: string[] | null;
  customSharedOverhead: number | null;
  defaultSharedLabels: string[];
  defaultSharedNamespaces: string[];
  defaultSharedOverhead: number;
  filters: { property: string; value: string }[];
  idle: string;
  idleOptions: { name: string; value: string }[];
  onClose: () => void;
  rate: string;
  rateOptions: { name: string; value: string }[];
  setChartDisplay: (s: string) => void;
  setCustomSharedLabels: (s: string) => void;
  setCustomSharedNamespaces: (s: string) => void;
  setCustomSharedOverhead: (arg: string | null) => void;
  setFilters: (arg: { property: string; value: string }[]) => void;
  setIdle: (s: string) => void;
  setRate: (s: string) => void;
  shareTenancyCosts: boolean;
}

const allFilterPropertyOptions = [
  'cluster',
  'node',
  'namespace',
  'label',
  'service',
  'controller',
  'controllerkind',
  'pod',
];

export const EditReportDialog: FC<EditReportDialogProps> = ({
  anchorEl,
  chartDisplay,
  chartDisplayOptions,
  customSharedLabels,
  customSharedNamespaces,
  customSharedOverhead,
  defaultSharedLabels,
  defaultSharedNamespaces,
  defaultSharedOverhead,
  filters,
  idle,
  idleOptions,
  onClose,
  rate,
  rateOptions,
  setChartDisplay,
  setCustomSharedLabels,
  setCustomSharedNamespaces,
  setCustomSharedOverhead,
  setFilters,
  setIdle,
  setRate,
  shareTenancyCosts,
}) => {
  const baseLocalOverhead = customSharedOverhead != null ? customSharedOverhead : 0;
  const baseLocalNamespaces =
    customSharedNamespaces && customSharedNamespaces.length ? customSharedNamespaces : [];
  const baseLocalLabels = customSharedLabels && customSharedLabels.length ? customSharedLabels : [];
  const { modelConfig } = useClusters();
  const [localSharedOverhead, setLocalSharedOverhead] = useState(baseLocalOverhead.toString());
  const [localSharedNamespaces, setLocalSharedNamespaces] = useState(baseLocalNamespaces.join(','));
  const [localSharedLabels, setLocalSharedLabels] = useState(baseLocalLabels.join(','));

  const handleUpdateFilter = (f: { property: string; value: string }) => {
    const { property } = f;
    const value = f.value.replace('=', ':');

    setFilters([...filters, { property, value }]);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={onClose}
      open={Boolean(anchorEl)}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={'p-4'} style={{ width: 450 }}>
        <section className={'my-3'}>
          <FormControlLabel>Idle costs</FormControlLabel>
          <Select
            options={idleOptions.map(({ name, value }) => ({
              label: name,
              value,
            }))}
            setValue={setIdle}
            value={idle}
          />
        </section>

        <section className={'my-3'}>
          <FormControlLabel>Chart</FormControlLabel>
          <Select
            options={chartDisplayOptions.map(({ name, value }) => ({
              label: name,
              value,
            }))}
            setValue={setChartDisplay}
            value={chartDisplay}
          />
        </section>

        <section className={'my-3'}>
          <FormControlLabel>Cost metric</FormControlLabel>
          <Select
            options={rateOptions.map(({ name, value }) => ({
              label: name,
              value,
            }))}
            setValue={setRate}
            value={rate}
          />
        </section>

        <section className={'my-3'}>
          <FormControlLabel>Filters</FormControlLabel>
          <AddFilter
            handleAddFilter={handleUpdateFilter}
            propertyOptions={allFilterPropertyOptions}
          />
          {filters.map(({ property, value }) => (
            <Chip
              className={'mb-2 mr-2 text-tiny'}
              color={'primary'}
              label={`${property}: ${value}`}
              onDelete={() => setFilters(filters.filter((f) => value !== f.value))}
            />
          ))}
        </section>

        <section className={'my-3'}>
          <FormControlLabel>Default shared resources</FormControlLabel>
          <Typography variant={'h6'}>
            Default shared namespaces, shared labels, and fixed costs can be adjusted in{' '}
            <a className={'text-kc-link'} href={'settings'}>
              settings
            </a>
            .
          </Typography>
          {defaultSharedNamespaces && defaultSharedNamespaces.length ? (
            <Chip
              className={`mt-1 text-tiny ${
                customSharedNamespaces && customSharedNamespaces.length ? 'line-through' : ''
              }`}
              color={'primary'}
              label={`${pluralize(
                'namespace',
                defaultSharedNamespaces.length,
              )}: ${defaultSharedNamespaces.join(', ')}`}
            />
          ) : null}
          {defaultSharedLabels && defaultSharedLabels.length ? (
            <Chip
              className={`mt1-1 text-tiny ${
                customSharedLabels && customSharedLabels.length ? 'line-through' : ''
              }`}
              color={'primary'}
              label={`${pluralize('label', defaultSharedLabels.length)}: ${defaultSharedLabels.join(
                ', ',
              )}`}
            />
          ) : null}
          {defaultSharedOverhead ? (
            <Chip
              className={`mt-1 text-tiny ${customSharedOverhead ? 'line-through' : ''}`}
              color={'primary'}
              label={`cost: ${toCurrency(defaultSharedOverhead, modelConfig.currencyCode)}/mo`}
            />
          ) : null}
          {shareTenancyCosts ? (
            <Chip className={'mt-1 text-tiny'} color={'primary'} label={'Tenancy Costs'} />
          ) : null}
        </section>

        <section className={'my-3'}>
          <FormControlLabel>Custom shared resources</FormControlLabel>
          <Typography variant={'h6'}>
            Custom shared namespaces, shared labels, and fixed costs can be set here to override the
            defaults above.
          </Typography>
          {customSharedNamespaces && customSharedNamespaces.length ? (
            <Chip
              className={'m-1 text-tiny'}
              color={'primary'}
              label={`${pluralize(
                'namespace',
                customSharedNamespaces.length,
              )}: ${customSharedNamespaces.join(', ')}`}
              onDelete={() => {
                setCustomSharedNamespaces('');
              }}
            />
          ) : null}
          {customSharedLabels && customSharedLabels.length ? (
            <Chip
              className={'m-1 text-tiny'}
              color={'primary'}
              label={`${pluralize('label', customSharedLabels.length)}: ${customSharedLabels.join(
                ', ',
              )}`}
              onDelete={() => {
                setCustomSharedLabels('');
              }}
            />
          ) : null}
          {customSharedOverhead != null ? (
            <Chip
              className={'m-1 text-tiny'}
              color={'primary'}
              label={`cost: ${toCurrency(customSharedOverhead, modelConfig.currencyCode)}/mo`}
              onDelete={() => {
                setCustomSharedOverhead(null);
              }}
            />
          ) : null}
        </section>

        <section className={'my-3'}>
          <FormControlLabel>Configure custom shared resources</FormControlLabel>
          <Input
            className={'mt-2 w-full'}
            helperText={'Custom shared overhead cost'}
            onChange={(e) => setLocalSharedOverhead(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setCustomSharedOverhead(localSharedOverhead);
              }
            }}
            value={localSharedOverhead}
          />
          <Input
            className={'mt-2 w-full'}
            helperText={'Custom shared namespaces'}
            onChange={(e) => setLocalSharedNamespaces(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setCustomSharedNamespaces(localSharedNamespaces);
              }
            }}
            value={localSharedNamespaces}
          />
          <Input
            className={'mt-2 w-full'}
            helperText={'Custom shared labels'}
            onChange={(e) => setLocalSharedLabels(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setCustomSharedLabels(localSharedLabels);
              }
            }}
            value={localSharedLabels}
          />
        </section>
      </div>
    </Popover>
  );
};
