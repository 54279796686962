import { FC } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button, Input, Modal, Typography } from '@kubecost-frontend/holster';

import { AlertTypes, ClusterHealthAlert } from '../../../services/alerts';
import TestAlertButton from '../TestAlertButton';

interface HealthAlertModalProps {
  alert?: ClusterHealthAlert;
  alertSaved?: (values: ClusterHealthAlert) => void;
  cancelClicked?: () => void;
  open?: boolean;
}

const HealthAlertFormSchema = Yup.object().shape({
  threshold: Yup.number()
    .required('Health score is required.')
    .min(0, 'Health score ranges from 0 to 100!')
    .max(100, 'Health score ranges from 0 to 100!'),
  window: Yup.string().required('Window is required.'),
  slackWebhookUrl: Yup.string().required('Slack Url is required'),
});

const HealthAlertModal: FC<HealthAlertModalProps> = ({
  alert,
  alertSaved,
  cancelClicked,
  open = false,
}: HealthAlertModalProps) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    setTouched,
    submitForm,
    touched,
    validateForm,
    values,
  } = useFormik<ClusterHealthAlert>({
    enableReinitialize: true,
    initialValues:
      alert ??
      new ClusterHealthAlert({
        type: AlertTypes.Health,
        window: '',
        threshold: 50,
        slackWebhookUrl: '',
      }),
    onSubmit: (values) => {
      if (!values || !alertSaved) return;
      values.slackWebhookUrl = '';
      alertSaved(values);
    },
    validationSchema: HealthAlertFormSchema,
  });

  return (
    <Modal
      data-test={'health-alert-dialog'}
      onClose={cancelClicked}
      open={open}
      title={'Enable Cluster Health Monitoring'}
    >
      <form onSubmit={handleSubmit}>
        <Typography style={{ marginBottom: 16 }} variant={'p'}>
          With cluster health monitoring enabled, Kubecost will send a message to the designated
          Slack webhook any time the cluster&apos;s Health score deteriorates by more than the
          designated threshold, within the designated time window.
        </Typography>

        <Typography variant={'p'}>
          Learn more about Slack webhooks{' '}
          <a
            href={'https://api.slack.com/messaging/webhooks'}
            style={{ color: '#28B359' }}
            target={'_blank'}
          >
            here
          </a>
          .
        </Typography>
        <Input
          label='Health change threshold'
          name='threshold'
          onChange={handleChange}
          style={{ width: '100%' }}
          type='number'
          value={values.threshold}
          // error={touched.threshold && Boolean(errors.threshold)}
          helperText={
            touched.threshold && errors.threshold
              ? errors.threshold
              : 'Health scores range from 0 to 100'
          }
        />
        <Input
          label='Window'
          name='window'
          onChange={handleChange}
          style={{ width: '100%' }}
          value={values.window}
          // error={touched.window && Boolean(errors.window)}
          helperText={
            touched.window && errors.window
              ? errors.window
              : 'Must be a relative window, such as 7d or 10m'
          }
        />
        <Input
          label='Slack webhook'
          name='slackWebhookUrl'
          onChange={handleChange}
          style={{ width: '100%' }}
          type='password'
          value={values.slackWebhookUrl}
          placeholder='https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX'
          // error={touched.slackWebhookUrl && Boolean(errors.slackWebhookUrl)}
          helperText={touched.slackWebhookUrl && errors.slackWebhookUrl}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}>
          <Button onClick={cancelClicked} style={{ marginRight: 8 }} variant={'primary'}>
            Cancel
          </Button>
          <Button onClick={submitForm} style={{ marginRight: 8 }} variant={'primary'}>
            Save
          </Button>
          <TestAlertButton
            alert={values}
            validate={async () => {
              setTouched({
                threshold: true,
                slackWebhookUrl: true,
                window: true,
              });
              const errors = await validateForm();
              if (Object.keys(errors).length > 0) return false;
              return true;
            }}
          />
        </div>
      </form>
    </Modal>
  );
};

export { HealthAlertModal, HealthAlertModalProps };
