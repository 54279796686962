import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import capitalize from 'lodash/capitalize';

interface Alert {
  aggregation: string;
  filter: string;
  id: string;
  type: string;
  window: string;
}

interface ComponentProps {
  alerts: Alert[];
  deleteAlert?: (alert: Alert) => void;
  editAlert?: (alert: Alert) => void;
  onRowClick?: (row: Alert) => void;
  testAlert?: (alert: Alert) => Promise<void>;
}

const AlertsList = ({
  alerts,
  deleteAlert,
  editAlert,
  onRowClick,
  testAlert,
}: ComponentProps): JSX.Element => (
  <TableContainer>
    <Table data-test={'alerts-list'}>
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell>Window</TableCell>
          <TableCell>Aggregation</TableCell>
          <TableCell>Filter</TableCell>
          {editAlert && <TableCell>Edit</TableCell>}
          {testAlert && <TableCell>Test</TableCell>}
          {deleteAlert && <TableCell>Delete</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {alerts.map((alert) => (
          <TableRow key={alert.id} onClick={() => onRowClick && onRowClick(alert)}>
            <TableCell>{formatType(alert.type)}</TableCell>
            <TableCell>{alert.window}</TableCell>
            <TableCell>{alert.aggregation}</TableCell>
            <TableCell>{alert.filter}</TableCell>
            {editAlert && (
              <TableCell onClick={() => editAlert(alert)}>
                <EditIcon style={{ cursor: 'pointer' }} />
              </TableCell>
            )}
            {testAlert && (
              <TableCell onClick={() => testAlert(alert)}>
                <SendIcon style={{ cursor: 'pointer' }} />
              </TableCell>
            )}
            {deleteAlert && (
              <TableCell data-test={'delete-alert'} onClick={() => deleteAlert(alert)}>
                <DeleteIcon style={{ cursor: 'pointer' }} />
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

AlertsList.defaultProps = {
  deleteAlert: undefined,
  editAlert: undefined,
  testAlert: undefined,
  onRowClick: undefined,
};

/**
 *
 * @param type The Alert type to format for display.
 * @returns The words of the type, separated and capitalized.
 *
 * @remarks
 * Example: spendChange -> Spend Change
 */
function formatType(type: string): string {
  return type
    .split(/(?=[A-Z])/)
    .map(capitalize)
    .join(' ');
}

export default AlertsList;
