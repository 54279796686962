import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  Card,
  CardContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';

import { Header } from '../../../components/Header';
import { useClusters } from '../../../contexts/ClusterConfig';

import { getUnutilizedLocalDisks } from './api';

// 20 %
const LOW_DISK_THRESHOLD = 0.2;

const LowDiskUsage = () => {
  const [underutilizedDisks, setUnderutilizedDisks] = useState([]);
  const { clusterConfig, envConfig, grafanaUrl, promQueryUrl, thanosQueryUrl } = useClusters();

  const grafanaDiskURL = (diskName: string): string =>
    `${grafanaUrl}/d/nBH7qBgMk/attached-disk-metrics?var-disk=${diskName}`;

  async function fetchData() {
    const values = await getUnutilizedLocalDisks(
      LOW_DISK_THRESHOLD,
      clusterConfig,
      envConfig,
      thanosQueryUrl,
      promQueryUrl,
    );
    setUnderutilizedDisks(values);
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Cluster Savings', href: 'savings' },
          { name: 'Low Disk Utilization', href: 'action' },
        ]}
      >
        <IconButton aria-label={'refresh'} onClick={() => fetchData()}>
          <RefreshIcon />
        </IconButton>
        <Link to={'settings'}>
          <IconButton aria-label={'refresh'}>
            <SettingsIcon />
          </IconButton>
        </Link>
      </Header>

      <Card style={{ marginBottom: '2em' }}>
        <CardContent>
          <Typography variant={'h5'}>Local Disks with low utilization</Typography>
          <br />
          Attached disks with under 20% current usage and 30% predicted usage can often be resized
          to save costs. Consider launching new nodes with smaller disks on the next node turndown.
        </CardContent>
      </Card>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Disk Name</TableCell>
              <TableCell>Cluster</TableCell>
              <TableCell>Current Utilization</TableCell>
              <TableCell>Recommendation</TableCell>
              <TableCell>Savings</TableCell>
            </TableRow>
          </TableHead>
          {underutilizedDisks.length === 0 ? (
            <TableCell align={'center'} colSpan={5}>
              No data available
            </TableCell>
          ) : (
            <TableBody>
              {underutilizedDisks.map((disk) => (
                <TableRow
                  onClick={() => {
                    window.open(grafanaDiskURL(disk.metric.instance));
                  }}
                  hover
                >
                  <TableCell style={{ color: '#2196f3', textDecoration: 'underline' }}>
                    {disk.metric.instance}{' '}
                    <OpenInNew style={{ fontSize: 'small', marginLeft: '0.5em' }} />
                  </TableCell>
                  <TableCell>{clusterConfig.id}</TableCell>
                  <TableCell>
                    {`${parseFloat((disk.value[1] / disk.value[2]) * 100).toFixed(1)}%`}
                  </TableCell>
                  <TableCell>
                    Resize disk to {Math.ceil(disk.value[2] / 1024 / 1024 / 1024 / 5) * 5 * 0.5} Gb
                  </TableCell>
                  <TableCell>${disk.savings.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default LowDiskUsage;
