/**
 * A collection of useful helper functions.
 */

// A common method for serializing complex JSON objects to ~obfuscated strings.
// First, stringify the JSON. Then, base64-encode the string.
const JSONToBase64 = <T = unknown>(arg: T) => {
  const js = JSON.stringify(arg);
  if (typeof js !== 'string') {
    throw new Error('JSONToBase64 called with an argument that did not convert to string type.');
  }
  const encoded = btoa(js);
  return encoded;
};

// A common method for decoding JSON objects that have been serialized to base64 strings.
// The inverse of JSONToBase64.
const base64ToJSON = <T = unknown>(s: string): T => {
  const decoded = atob(s);
  const data = JSON.parse(decoded);
  return data;
};

// The identity function. Returns the argument given.
const ident = <T>(x: T) => x;

// Interpret the string "true" as true, and anything else as false.
const stringToBool = (s: string) => s === 'true';

// The inverse of String(Array<unknown>)
const stringToList = (s: string) => s.split(',');

export { JSONToBase64, base64ToJSON, ident, stringToBool, stringToList };
