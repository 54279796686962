import { MouseEvent, useState } from 'react';

import { Menu, Tooltip } from '@kubecost-frontend/holster';

type ReportActionMenuProps = {
  deleteClicked: () => void;
  readonly?: boolean;
};

const ReportActionMenu = ({ deleteClicked, readonly = false }: ReportActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenActionMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (readonly) {
    return (
      <Tooltip
        content={'Only admin or editor users may delete reports'}
        style={{ width: 200, left: -100 }}
      >
        <div
          aria-controls={open ? 'actions-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup={'true'}
          data-testid={'menu-button'}
          id={'action-positioned-button'}
          style={{ cursor: 'default' }}
        >
          ...
        </div>
      </Tooltip>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <div
        aria-controls={open ? 'actions-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup={'true'}
        data-testid={'menu-button'}
        id={'action-positioned-button'}
        onClick={handleOpenActionMenu}
        style={{ cursor: 'pointer' }}
      >
        ...
      </div>
      {open && (
        <Menu items={[{ text: 'Delete' }]} onClose={handleClose} selectItem={deleteClicked} />
      )}
    </div>
  );
};

export { ReportActionMenu };
