import { ChangeEvent } from 'react';

import { Input } from '@kubecost-frontend/holster';

interface AssetsFilterSectionProps {
  updateFilter: (newText: string) => void;
}

export const AssetsFilterSection = ({ updateFilter }: AssetsFilterSectionProps) => (
  <div className={'mt-[43px] mb-[25px]'}>
    <Input
      onChange={(event: ChangeEvent<HTMLInputElement>) => updateFilter(event.target.value)}
      placeholder={'Search'}
      type={'text'}
    />
  </div>
);
