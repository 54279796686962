import { Alert } from '../../services/alerts';

export interface InspectedResourceListItem {
  name: string;
  type: string;
}

interface InspectStaticState {
  currency: string;
  inspectedResourceList: InspectedResourceListItem[] | null;
  shareCost: number;
  shareTenancyCosts: boolean | null;
  sharedLabels: string[];
  // shareTenancyCosts is either the string value 'true' or 'false'
  sharedNamespaces: any;
}

export const initialDynamicState: InspectDynamicState = {
  loading: true,
  allocationOverTime: null,
  allocationSummary: null,
  externalCosts: null,
  alertsData: [],
  efficiencyData: null,
  controllers: null,
};

interface InspectDynamicState {
  alertsData: Alert[];
  allocationOverTime: any;
  allocationSummary: any;
  controllers: any;
  efficiencyData?: any;
  externalCosts: any;
  loading: boolean;
}

export const initialStaticState: InspectStaticState = {
  inspectedResourceList: null,
  shareTenancyCosts: null,
  sharedNamespaces: [],
  sharedLabels: [],
  shareCost: 0,
  currency: 'USD',
};
