// https://usehooks.com/useLocalStorage/
import { useCallback, useState } from 'react';

type InitialFn<T> = T | (() => T);
type UpdateFn<T> = (val: T | ((val: T) => T)) => void;

export function useLocalStorage<T>(key: string, initialValue: InitialFn<T>): [T, UpdateFn<T>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (e) {
      //Swallow the error for now
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      if (typeof window !== undefined) {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }

      setStoredValue(valueToStore);
    },
    [key, storedValue, setStoredValue],
  );

  return [storedValue, setValue];
}
