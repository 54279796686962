import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeadCell,
    TableRow,
  } from '@kubecost-frontend/holster';

const ClusterManagementTable = ({ onlineAgents }) => (
    <Table className="w-full">
        <TableHead>
            <TableRow>
                <TableHeadCell>ID</TableHeadCell>
                <TableHeadCell>Provider</TableHeadCell>
                <TableHeadCell>Agent version</TableHeadCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {Object.entries(onlineAgents).map(([key, cluster]) => (
                <TableRow>
                    <TableCell className="font-bold">{cluster.id}</TableCell>
                    <TableCell>{cluster.provider}</TableCell>
                    <TableCell>{cluster.agentVersion}</TableCell>
                    <TableCell/>
                    <TableCell/>
                </TableRow>
            ))}
        </TableBody>
    </Table>
)

export default ClusterManagementTable;