import { ReactElement, memo, useEffect, useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';

import { Header } from '../../components/Header';
import { Loading } from '../../components/Loading';
import { FetchStates } from '../../constants';
import logger from '../../services/logger';
import { model } from '../../services/model';
import { ChecklistResponse, SpotReady, fetchSpotChecklist } from '../../services/savings/spot';
import {
  ValidSizingResponse,
  fetchSpotChecklistClusterSizing,
} from '../../services/savings/spotclustersizing';

import { SpotChecklistSizingCard } from './SpotChecklistSizingCard';
import { SpotChecklistTable } from './SpotChecklistTable';
import { TableKey } from './TableKey';

const useStyles = makeStyles({
  description: {
    padding: '24px 36px',
    marginBottom: 20,
  },
  recommendations: {
    display: 'flex',
  },
  spotready: {
    display: 'flex',
  },
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
});

const SpotChecklistEstimatedPage = memo(() => {
  const classes = useStyles();

  const [fetchState, setFetchState] = useState(FetchStates.INIT);
  const [fetchStateSizing, setFetchStateSizing] = useState(FetchStates.INIT);

  const [spotChecklist, setSpotChecklist] = useState<ChecklistResponse | undefined>(undefined);
  const [spotChecklistClusterSizing, setSpotChecklistClusterSizing] = useState<
    ValidSizingResponse | undefined
  >(undefined);
  const [currency, setCurrency] = useState<string | undefined>(undefined);

  const [window, setWindow] = useState<string>('1d');
  const [allowSharedCore, setAllowSharedCore] = useState<boolean>(true);
  const [sizingTargetUtilizationPct, setSizingTargetUtilizationPct] = useState<number>(90);
  const [sizingTargetUtilizationPctSlider, setSizingTargetUtilizationPctSlider] =
    useState<number>(90);
  const [minOnDemandNodeCount, setMinOnDemandNodeCount] = useState<number>(2);
  const [minSpotNodeCount, setMinSpotNodeCount] = useState<number>(3);

  const [tabsValue, setTabsValue] = useState(0);

  async function initialize() {
    try {
      const spotCLPromise = fetchSpotChecklist();
      const spotCL = await spotCLPromise;

      setSpotChecklist(spotCL);

      setFetchState(FetchStates.DONE);
    } catch (err) {
      console.error(err);
      logger.error('Unexpected error loading spot checklist:', err);
      setFetchState(FetchStates.ERROR);
    }
  }

  // Load sizing separately so it can fail separately without crashing
  // the whole page.
  //
  // TODO: Add cancellation. Users changing the parameters multiple times will
  // encounter bad data because the frontend is updated for each request that
  // returns. This means that the state will often not match the parameters and
  // will change as each request returns. If the last request sent (containing
  // the most recent parameters set by the user) does not return last, then the
  // response shown to the user will not be correct because it does not align
  // with the parameters they configured.
  async function initializeSizing() {
    try {
      const sizingPromise = fetchSpotChecklistClusterSizing(
        minOnDemandNodeCount,
        minSpotNodeCount,
        sizingTargetUtilizationPct / 100,
        allowSharedCore,
        window,
      );
      const configPromise = model.getConfigs();

      const sizing = await sizingPromise;
      const config = await configPromise;

      setCurrency(config.currencyCode);

      // These blocks are necessary for "narrowing", see
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#instanceof-narrowing
      //
      if (sizing instanceof Error) {
        logger.error('Sizing request returned an error:', sizing.message);
        setFetchStateSizing(FetchStates.ERROR);
      } else {
        setSpotChecklistClusterSizing(sizing);
        setFetchStateSizing(FetchStates.DONE);
      }
    } catch (err) {
      logger.error('Unexpected error initializing spot cluster sizing: ', err);
      setFetchStateSizing(FetchStates.ERROR);
    }
  }

  // Handle fetching data
  useEffect(() => {
    if (fetchState === FetchStates.INIT) {
      setFetchState(FetchStates.LOADING);

      initialize();
    }
  }, [fetchState]);

  useEffect(() => {
    if (fetchStateSizing === FetchStates.INIT) {
      setFetchStateSizing(FetchStates.LOADING);

      initializeSizing();
    }
  }, [fetchStateSizing]);

  const utilizationSliderMarks = [
    {
      value: 100,
      label: '100%',
    },
    {
      value: 50,
      label: '50%',
    },
  ];

  function sizingTab(): ReactElement {
    if (fetchState === FetchStates.ERROR || fetchStateSizing === FetchStates.ERROR) {
      return (
        <div>
          <Typography>Failed to load spot cluster sizing recommendation</Typography>
          <IconButton
            aria-label={'refresh'}
            onClick={() => {
              setFetchState(FetchStates.INIT);
              setFetchStateSizing(FetchStates.INIT);
            }}
          >
            <RefreshIcon />
          </IconButton>
        </div>
      );
    }
    if (fetchState === FetchStates.LOADING || fetchStateSizing === FetchStates.LOADING) {
      return <Loading message={'Computing spot cluster sizing recommendation'} />;
    }
    if (
      fetchState === FetchStates.DONE &&
      fetchStateSizing === FetchStates.DONE &&
      spotChecklist !== undefined &&
      currency !== undefined
    ) {
      return (
        <SpotChecklistSizingCard
          currencyCode={currency}
          sizingResponse={spotChecklistClusterSizing}
          spotReadyChecklists={spotChecklist.filter(
            (checklist) => checklist.readiness === SpotReady.Yes,
          )}
        />
      );
    }
    return <div />;
  }

  function checklistTab(): ReactElement {
    if (fetchState === FetchStates.ERROR) {
      return (
        <div>
          <Typography>Failed to load spot checklist</Typography>
          <IconButton
            aria-label={'refresh'}
            onClick={() => {
              setFetchState(FetchStates.INIT);
            }}
          >
            <RefreshIcon />
          </IconButton>
        </div>
      );
    }
    if (fetchState === FetchStates.LOADING) {
      return <Loading message={'Computing spot cluster sizing recommendation'} />;
    }
    if (fetchState === FetchStates.DONE && spotChecklist !== undefined) {
      return (
        <div>
          <Typography variant={'h6'}>Checklist</Typography>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Key</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableKey />
            </AccordionDetails>
          </Accordion>
          <SpotChecklistTable response={spotChecklist} />
        </div>
      );
    }
    return <div />;
  }

  function clusterSizingOptions(): ReactElement {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant={'h6'}>Cluster recommendation options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid spacing={3} container>
            <Grid spacing={3} container item>
              <Grid item>
                <Typography gutterBottom>Target resource utilization of nodes</Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    'Percentage of resource overhead. At 90%, the algorithm targets minimum 90% node resource usage (CPU and RAM) based on maximum resource usage in the window.'
                  }
                >
                  <InfoIcon />
                </Tooltip>
              </Grid>
              <Grid md={2} item>
                <Slider
                  aria-labelledby={'discrete-slider-restrict'}
                  getAriaValueText={(value) => `${value}%`}
                  marks={utilizationSliderMarks}
                  max={100}
                  min={50}
                  onChange={(event, newValue) => {
                    // Separate onChange lets the slider value update interactively
                    // without firing a request until the new value has been locked in
                    if (typeof newValue === 'number') {
                      setSizingTargetUtilizationPctSlider(newValue);
                    } else if (newValue.length === 0) {
                      logger.warn(`Slider value ${newValue} not valid`);
                    } else {
                      setSizingTargetUtilizationPctSlider(newValue[0]);
                    }
                  }}
                  onChangeCommitted={(event, newValue) => {
                    if (typeof newValue === 'number') {
                      setSizingTargetUtilizationPct(newValue);
                      setFetchStateSizing(FetchStates.INIT);
                    } else if (newValue.length === 0) {
                      logger.warn(`Slider value ${newValue} not valid`);
                    } else {
                      setSizingTargetUtilizationPct(newValue[0]);
                      setFetchStateSizing(FetchStates.INIT);
                    }
                  }}
                  value={sizingTargetUtilizationPctSlider}
                  valueLabelDisplay={'auto'}
                  valueLabelFormat={(value) => `${value}%`}
                />
              </Grid>
            </Grid>
            <Grid spacing={3} container item>
              <Grid item>
                <Typography>Minimum node count: on-demand nodes</Typography>
              </Grid>
              <Grid item>
                <TextField
                  defaultValue={minOnDemandNodeCount}
                  id={'field-min-on-demand-node-count'}
                  label={'Node Count'}
                  onChange={(event) => {
                    const newValue = parseInt(event.target.value);
                    if (newValue !== NaN) {
                      setMinOnDemandNodeCount(newValue);
                      setFetchStateSizing(FetchStates.INIT);
                    }
                  }}
                  type={'number'}
                />
              </Grid>
            </Grid>
            <Grid spacing={3} container item>
              <Grid item>
                <Typography>Minimum node count: spot nodes</Typography>
              </Grid>
              <Grid item>
                <TextField
                  defaultValue={minSpotNodeCount}
                  id={'field-min-spot-node-count'}
                  label={'Node Count'}
                  onChange={(event) => {
                    const newValue = parseInt(event.target.value);
                    if (newValue !== NaN) {
                      setMinSpotNodeCount(newValue);
                      setFetchStateSizing(FetchStates.INIT);
                    }
                  }}
                  type={'number'}
                />
              </Grid>
            </Grid>
            <Grid spacing={3} container item>
              <Grid item>
                <Typography>Window of data to base estimates and recommendations on</Typography>
              </Grid>
              <Grid item>
                <FormControl>
                  <InputLabel id={'window-select-label'}>Window</InputLabel>
                  <Select
                    id={'window-select'}
                    onChange={(e) => {
                      const newWindow = e.target.value;
                      setWindow(newWindow as string); // this is ugly, but it doesn't know its a string
                      setFetchStateSizing(FetchStates.INIT);
                    }}
                    value={window}
                  >
                    <MenuItem key={'1d'} value={'1d'}>
                      1 day
                    </MenuItem>
                    <MenuItem key={'7d'} value={'7d'}>
                      7 day
                    </MenuItem>
                    <MenuItem key={'30d'} value={'30d'}>
                      30 day
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid spacing={3} container item>
              <Grid item>
                <Typography>Allow shared-core machine types?</Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={allowSharedCore}
                  onChange={(event) => {
                    setAllowSharedCore(event.target.checked);
                    setFetchStateSizing(FetchStates.INIT);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Cluster Savings', href: 'savings.html' },
          { name: 'Spot Commander', href: 'spot.html' },
        ]}
      >
        <IconButton
          aria-label={'refresh'}
          onClick={() => {
            setFetchState(FetchStates.INIT);
            setFetchStateSizing(FetchStates.INIT);
          }}
        >
          <RefreshIcon />
        </IconButton>
        <Link href={'settings.html'}>
          <IconButton aria-label={'refresh'}>
            <SettingsIcon />
          </IconButton>
        </Link>
      </Header>

      <Paper className={classes.description}>
        <Typography variant={'h5'} paragraph>
          Spot Commander
        </Typography>
        <Typography variant={'body1'} paragraph>
          <b>Identify workloads</b> ready for spot (preemptible) nodes and{' '}
          <b>resize your cluster</b> to realize the savings of migrating workloads to spot. The
          Checklist assesses the controllers running on your cluster for spot-readiness. Learn more
          about the checks performed{' '}
          <a href={'https://github.com/kubecost/docs/blob/master/spot-checklist.md'}>here</a>. The
          recommended cluster configuration suggests a reconfiguration of your cluster's nodes to
          put spot-ready workloads on spot nodes while minimizing cost. It also generates CLI
          commands to help you implement the recommendation. Learn more{' '}
          <a href={'https://github.com/kubecost/docs/blob/main/spot-cluster-sizing.md'}>here</a>.
        </Typography>
        <Typography>
          Spot Commander is currently in beta. Reach out to our team if you encounter issues or have
          suggestions!
        </Typography>
        {clusterSizingOptions()}
      </Paper>

      <Paper className={classes.description}>
        <Tabs
          onChange={(event, newValue) => {
            setTabsValue(newValue);
          }}
          value={tabsValue}
        >
          <Tab label={'Spot Cluster Sizing Recommendation'} />
          <Tab label={'Spot Checklist'} />
        </Tabs>
        {tabsValue === 0 && sizingTab()}

        {tabsValue === 1 && checklistTab()}
      </Paper>
    </>
  );
});

SpotChecklistEstimatedPage.displayName = 'SpotChecklistEstimatedPage';

export { SpotChecklistEstimatedPage };
