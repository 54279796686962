import { FC, memo, useEffect, useState } from 'react';
import differenceInHours from 'date-fns/differenceInHours';

import get from 'lodash/get';
import round from 'lodash/round';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CategoryIcon from '@material-ui/icons/Category';
import CloudIcon from '@material-ui/icons/Cloud';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import FolderIcon from '@material-ui/icons/Folder';
import ClusterIcon from '@material-ui/icons/GroupWork';
import MapIcon from '@material-ui/icons/Map';
import NodeIcon from '@material-ui/icons/Memory';
import StorageIcon from '@material-ui/icons/SdStorage';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

import { captureError } from '../../services/error_reporting';
import { toCurrency } from '../../services/format';
import { AssetPropertiesItem } from './AssetPropertiesItem';
import { DiskAssetDetails } from './DiskAssetDetails';
import { NodeAssetDetails } from './NodeAssetDetails';
import { useAssetQueryFn } from './api/AssetsQuery';
import type { Asset, NodeAsset, DiskAsset } from './api/AssetsQuery';

interface DetailsProps {
  asset: Asset;
  currency: string;
  window: string;
}

const Details: FC<DetailsProps> = ({ asset, currency, window }) => {
  const queryAsset = useAssetQueryFn();

  const [fetch, setFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState(0);
  const [costPerHr, setCostPerHr] = useState(0);
  const [accAsset, setAccAsset] = useState<Asset>();
  const properties = get(asset, 'properties', {});
  const category = get(properties, 'category', '');
  const cluster = get(properties, 'cluster', '');
  const provider = get(properties, 'provider', '');
  const account = get(properties, 'account', '');
  const project = get(properties, 'project', '');
  const region = get(properties, 'region', '');
  const providerID = get(properties, 'providerID', '');
  const service = get(properties, 'service', '');
  const type = get(asset, 'type', '');
  let storageClass = '';
  let nodeType = '';

  if (type === 'Node') {
    nodeType = get(asset, 'nodeType', '');
    if (!nodeType) {
      nodeType = 'Unknown';
    }
  }

  if (type === 'Disk') {
    storageClass = get(asset, 'storageClass', '');
    if (!storageClass) {
      storageClass = 'Unknown';
    }
  }

  useEffect(() => {
    if (fetch) {
      fetchData();
    }
  }, [fetch]);

  async function fetchData() {
    setLoading(true);

    const filterProps: Record<string, string> = {
      category,
      cluster,
      providerID,
      service,
      type,
    };
    const filters = getRequestFilters(filterProps);

    try {
      const resp = await queryAsset({ window, filters, accumulate: true });

      if (resp.data && resp.data.length > 0) {
        const results = resp.data[0]?.results;

        if (results[0]) {
          const hours = differenceInHours(new Date(results[0].end), new Date(results[0].start));

          setAccAsset(results[0]);
          setHours(hours);
          setCostPerHr(results[0].totalCost / hours);
        }
      }

      // const resp = await model.getAssets(window, {
      //   filters,
      //   accumulate: true,
      // });
      // After filtering and accumulation there should only be one asset in return
      // if (resp.data && resp.data.length > 0) {
      //   const rowData: Record<string, Asset> = resp.data[0];
      //   const rowKeys = Object.keys(rowData);
      //   if (rowKeys.length > 0) {
      //     setAccAsset(rowData[rowKeys[0]]);
      //     const hrs = get(rowData[rowKeys[0]], 'minutes', 0) / 60.0;
      //     setHours(hrs);
      //     if (hrs !== 0) {
      //       setCostPerHr(rowData[rowKeys[0]].totalCost / hrs);
      //     }
      //   }
      // }
      setLoading(false);
      setFetch(false);
    } catch (err) {
      console.warn(err);
      captureError(err as Error);
    }
  }

  function getRequestFilters(props: Record<string, string>) {
    return Object.entries(props)
      .filter((entry) => entry[1])
      .map(([property, value]) => ({ property, value }));
  }

  if (loading) {
    return <>Loading...</>;
  }

  if (accAsset) {
    return (
      <>
        <div className={'grid grid-cols-3 grid-rows-2 gap-5'}>
          {cluster && (
            <AssetPropertiesItem icon={<ClusterIcon />} tooltip={'Cluster'} value={cluster} />
          )}
          {provider && (
            <AssetPropertiesItem icon={<CloudIcon />} tooltip={'Provider'} value={provider} />
          )}
          {account && (
            <AssetPropertiesItem icon={<AccountBoxIcon />} tooltip={'Account'} value={account} />
          )}
          {project && (
            <AssetPropertiesItem icon={<FolderIcon />} tooltip={'Project'} value={project} />
          )}
          {service && (
            <AssetPropertiesItem
              icon={<DesktopWindowsIcon />}
              tooltip={'Service'}
              value={service}
            />
          )}
          {category && (
            <AssetPropertiesItem icon={<CategoryIcon />} tooltip={'Category'} value={category} />
          )}
          {region && <AssetPropertiesItem icon={<MapIcon />} tooltip={'Region'} value={region} />}
          {type === 'Node' && nodeType && (
            <AssetPropertiesItem icon={<NodeIcon />} tooltip={'Node Type'} value={nodeType} />
          )}
          {type === 'Disk' && storageClass && (
            <AssetPropertiesItem
              icon={<StorageIcon />}
              tooltip={'Storage Class'}
              value={storageClass}
            />
          )}
        </div>
        <Table className={'my-5 w-[100%]'}>
          <TableHead>
            <TableRow>
              <TableHeadCell align={'left'} scope={'row'}>
                Hours
              </TableHeadCell>
              <TableHeadCell align={'right'} scope={'row'}>
                Discount
              </TableHeadCell>
              <TableHeadCell align={'right'} scope={'row'}>
                Adjustment
              </TableHeadCell>
              <TableHeadCell align={'right'} scope={'row'}>
                Hourly Rate
              </TableHeadCell>
              <TableHeadCell align={'right'} scope={'row'}>
                Total Cost
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align={'left'} scope={'row'} width={200}>
                {round(hours, 5)}
              </TableCell>
              <TableCell align={'right'} scope={'row'}>
                {get(accAsset, 'discount', 0)}
              </TableCell>
              <TableCell align={'right'} scope={'row'}>
                {toCurrency(get(accAsset, 'adjustment', 0), currency, 3)}
              </TableCell>
              <TableCell align={'right'} scope={'row'}>
                {toCurrency(costPerHr, currency, 3)}
              </TableCell>
              <TableCell align={'right'} scope={'row'}>
                {toCurrency(get(accAsset, 'totalCost', 0), currency, 2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {type === 'Node' && (
          <NodeAssetDetails currency={currency} hours={hours} nodeAsset={accAsset as NodeAsset} />
        )}
        {type === 'Disk' && (
          <DiskAssetDetails currency={currency} diskAsset={accAsset as DiskAsset} hours={hours} />
        )}
      </>
    );
  }

  return <></>;
};

export const DetailsMemoized = memo(Details);
