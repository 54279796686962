import { FC, ReactNode } from 'react';

import { OpenInNew } from '@material-ui/icons';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

import { useClusters } from '../../contexts/ClusterConfig';

import { mebibyteFactor } from './utils';

interface DrilldownTableProps {
  allocationData: any[];
  recommendation: any;
}

const DrilldownTable: FC<DrilldownTableProps> = ({ allocationData, recommendation }) => {
  const {
    apiConfig: { grafanaEnabled },
    grafanaUrl,
  } = useClusters();

  const columns: ReactNode[] = [
    'Pod Name',
    'CPU usage',
    'CPU request',
    "CPU recomm'd",
    'RAM usage',
    'RAM request',
    "RAM recomm'd",
    'Savings',
    'Details',
  ];

  const handleOnSelectTableRow = (namespace: string, pod: string) => {
    if (namespace && pod && grafanaEnabled) {
      window.open(
        `${grafanaUrl}/d/at-cost-analysis-pod/pod-cost-and-utilization-metrics?from=now-1d&orgId=1&var-costStorageStandard=0.044&var-costStorageSSD=0.187&var-costDiscount=30&var-namespace=${namespace}&var-pod=${pod}`,
      );
    }
  };

  const allocations =
    allocationData && Object.keys(allocationData[0]).map((key) => allocationData[0][key]);

  const totalSavings = () => {
    if (!recommendation || !recommendation.monthlySavings) {
      return 0;
    }
    const total = recommendation.monthlySavings.cpu + recommendation.monthlySavings.memory;
    return (total / allocations.length).toFixed(2);
  };
  return (
    <div className={'mt-4 flex gap-6 border-kc-gray-100 py-4'}>
      <Table className={'w-full table-fixed'}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableHeadCell className={'p-2 text-left font-bold'}>{column}</TableHeadCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {allocations.map((td) => (
            <TableRow
              className={'h-10'}
              onClick={() => handleOnSelectTableRow(td.properties.namespace, td.properties.pod)}
            >
              <TableCell className={'font-xs p-2 text-left font-bold'}>
                {(td.properties && td.properties.pod) || ''}
              </TableCell>

              <TableCell className={'p-2 text-left'}>
                {(td.cpuCoreUsageAverage * 1000).toFixed(0)}m
              </TableCell>
              <TableCell className={'p-2 text-left'}>
                {(td.cpuCoreRequestAverage * 1000).toFixed(0)}m
              </TableCell>
              <TableCell className={'p-2 text-left'}>
                {recommendation &&
                  recommendation.recommendedRequest &&
                  recommendation.recommendedRequest.cpu}
              </TableCell>

              <TableCell className={'p-2 text-left'}>
                {(td.ramByteUsageAverage / mebibyteFactor).toFixed(2)}Mi
              </TableCell>
              <TableCell className={'p-2 text-left'}>
                {(td.ramByteRequestAverage / mebibyteFactor).toFixed()}Mi
              </TableCell>
              <TableCell className={'p-2 text-left'}>
                {recommendation &&
                  recommendation.recommendedRequest &&
                  recommendation.recommendedRequest.memory}
              </TableCell>
              <TableCell className={'p-2 text-left'}>${totalSavings()}</TableCell>
              <TableCell className={'p-2 text-left'}>
                {grafanaEnabled && (
                  <>
                    <span>Grafana</span>
                    <OpenInNew fontSize={'small'} />
                  </>
                )}
                {!grafanaEnabled && <span>Grafana Disabled</span>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

DrilldownTable.displayName = 'DrilldownTable';
export { DrilldownTable };
