import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

interface SharedErrorStateDialogProps {
  open: boolean;
  resourceName: string;
  sharedLabels: string[];
  sharedNamespaces: string[];
}

export const SharedErrorStateDialog = ({
  open,
  resourceName,
  sharedLabels,
  sharedNamespaces,
}: SharedErrorStateDialogProps) => (
  <Dialog open={!!open}>
    <DialogTitle>This resource is considered shared</DialogTitle>
    <DialogContent dividers>
      <Typography gutterBottom>
        We cannot display cost and usage data for <strong>{resourceName}</strong>. Usually, this is
        caused by shared resource settings, where all of the relevant allocation data is considered
        a shared resource.
      </Typography>
      <Typography gutterBottom>Your current shared resources settings are:</Typography>
      <Typography gutterBottom>
        <strong>Namespaces: </strong>
        {sharedNamespaces.toString()}
      </Typography>
      <Typography gutterBottom>
        <strong>Labels: </strong>
        {sharedLabels.toString()}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => (window.location.href = 'settings.html')}>Go to settings</Button>
      <Button onClick={() => history.back()}>Go Back</Button>
    </DialogActions>
  </Dialog>
);
