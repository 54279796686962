import { FC } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { Snackbar, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';

import { SnackbarTypes } from './types';

interface AzureConfigFormProps {
  handleUpdateAzureConfig: (values: any) => void;
  snackbar: SnackbarTypes;
}

export const AzureConfigForm: FC<AzureConfigFormProps> = ({
  handleUpdateAzureConfig,
  snackbar,
}) => {
  const validationSchema = yup.object({
    azureSubscriptionID: yup.string().required('Subscription ID is required'),
    azureStorageAccount: yup.string().required('Storage Account is required'),
    azureStorageAccessKey: yup.string().required('Storage Account Access Key is required'),
    azureStorageContainer: yup.string().required('Storage Container is required'),
    azureContainerPath: yup.string(),
    azureCloud: yup.string(),
  });

  const initialValues = {
    azureSubscriptionID: '',
    azureStorageAccount: '',
    azureStorageAccessKey: '',
    azureStorageContainer: '',
    azureContainerPath: '',
    azureCloud: '',
  };

  const { errors, handleBlur, handleChange, handleSubmit, touched, values } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleUpdateAzureConfig(values);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        data-testid={'azure-subscription-id-field'}
        error={touched.azureSubscriptionID && Boolean(errors.azureSubscriptionID)}
        helperText={touched.azureSubscriptionID && errors.azureSubscriptionID}
        id={'azureSubscriptionID'}
        label={'Azure Subscription ID'}
        name={'azureSubscriptionID'}
        onBlur={handleBlur}
        onChange={handleChange}
        style={{ paddingBottom: '1rem' }}
        value={values.azureSubscriptionID}
        variant={'outlined'}
        fullWidth
      />

      <TextField
        data-testid={'azure-storage-account-field'}
        error={touched.azureStorageAccount && Boolean(errors.azureStorageAccount)}
        helperText={touched.azureStorageAccount && errors.azureStorageAccount}
        id={'azureSubscriptionID'}
        label={'Azure Storage Account'}
        name={'azureStorageAccount'}
        onBlur={handleBlur}
        onChange={handleChange}
        style={{ paddingBottom: '1rem' }}
        value={values.azureStorageAccount}
        variant={'outlined'}
        fullWidth
      />
      <TextField
        data-testid={'azure-storage-access-key-field'}
        error={touched.azureStorageAccessKey && Boolean(errors.azureStorageAccessKey)}
        helperText={touched.azureStorageAccessKey && errors.azureStorageAccessKey}
        id={'azureStorageAccessKey'}
        label={'Azure Storage Access Key'}
        name={'azureStorageAccessKey'}
        onBlur={handleBlur}
        onChange={handleChange}
        style={{ paddingBottom: '1rem' }}
        value={values.azureStorageAccessKey}
        variant={'outlined'}
        fullWidth
      />
      <TextField
        data-testid={'azure-storage-container-field'}
        error={touched.azureStorageContainer && Boolean(errors.azureStorageContainer)}
        helperText={touched.azureStorageContainer && errors.azureStorageContainer}
        id={'azureStorageContainer'}
        label={'Azure Storage Container'}
        name={'azureStorageContainer'}
        onBlur={handleBlur}
        onChange={handleChange}
        style={{ paddingBottom: '1rem' }}
        value={values.azureStorageContainer}
        variant={'outlined'}
        fullWidth
      />
      <TextField
        data-testid={'azure-container-path-field'}
        error={touched.azureContainerPath && Boolean(errors.azureContainerPath)}
        helperText={touched.azureContainerPath && errors.azureContainerPath}
        id={'azureContainerPath'}
        label={'Azure Container Path'}
        name={'azureContainerPath'}
        onBlur={handleBlur}
        onChange={handleChange}
        style={{ paddingBottom: '1rem' }}
        value={values.azureContainerPath}
        variant={'outlined'}
        fullWidth
      />
      <TextField
        data-testid={'azure-cloud-field'}
        error={touched.azureCloud && Boolean(errors.azureCloud)}
        helperText={touched.azureCloud && errors.azureCloud}
        id={'azureCloud'}
        label={'Azure Cloud'}
        name={'azureCloud'}
        onBlur={handleBlur}
        onChange={handleChange}
        style={{ paddingBottom: '1rem' }}
        value={values.azureCloud}
        variant={'outlined'}
        fullWidth
      />
      <Button
        color={'primary'}
        data-testid={'submit-azure-form-button'}
        type={'submit'}
        variant={'contained'}
        fullWidth
      >
        Submit
      </Button>

      <Snackbar
        autoHideDuration={4000}
        data-testid={`submit-azure-form-${snackbar.severity}-notification`}
        open={!!snackbar.message}
      >
        <Alert severity={snackbar.severity} variant={'filled'}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </form>
  );
};
