import React from 'react';

import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import { App } from './App';
import { HostedProvider } from './hooks/useHosted';
import { InitializeHosted } from './HostedInitializaer';
import { AuthProvider } from './PKCE';
import reportWebVitals from './reportWebVitals';

const rootEl = document.getElementById('root');

if (!rootEl) {
  throw new Error('Could not mount application to document: Element #root not found.');
}

const root = ReactDOM.createRoot(rootEl);

export type HostedConfig = {
  costModelAPIUrl: string;
  oauth: OAuthConfig;
  userServiceAPIUrl: string;
  reportingServer: string;
  agentImageVersion: string;
};

export type OAuthConfig = {
  clientId: string;
  provider: string;
  redirectUri: string;
};

const queryClient = new QueryClient();

const renderHostedApplication = async (config: HostedConfig) => {
  const hosted = await InitializeHosted(config);

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <HostedProvider initialConfig={hosted}>
          <AuthProvider authService={hosted.authService}>
            <App />
          </AuthProvider>
        </HostedProvider>
      </QueryClientProvider>
    </React.StrictMode>,
  );
};

const renderOnPremApplication = () => {
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>,
  );
};

// Fetch the initial Configuration

fetch('/hosted-config.json')
  .then((res) => res.json())
  .then((config) => {
    renderHostedApplication(config);
  })
  .catch(() => {
    renderOnPremApplication();
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
