import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useAPIClient } from '../../services/APIClient';

import { RequestSizingParams, RequestSizingResponse } from './types';

export type APIRequestSizingParams = Omit<RequestSizingParams, 'profile'>;
const recommendationsQueryKey = (params: APIRequestSizingParams) => [
  'requestSizing',
  'recommendations',
  { ...params },
];

const queryRecommendationsApi = async (rqParams: APIRequestSizingParams) => {
  const APIClient = useAPIClient();
  const filter = rqParams.filter
    ? rqParams.filter.map((f) => `${f.property}${f.equality}"${f.value}"`).join('+')
    : [];
  const params = { ...rqParams, filter };
  return APIClient.get<RequestSizingResponse>('/savings/requestSizingV2', {
    params: {
      ...params,
    },
  });
};

export const useGetRecommendations = (rqParams: APIRequestSizingParams) => {
  const APIClient = useAPIClient();
  const queryClient = useQueryClient();
  const { data, ...args } = useQuery(
    [recommendationsQueryKey(rqParams), { useErrorBoundary: true }],
    () => queryRecommendationsApi(rqParams),
  );

  const mutate = useMutation((payload) => APIClient.post('/savings/requestSizingV2', payload), {
    onSuccess() {
      queryClient.invalidateQueries(recommendationsQueryKey(rqParams));
    },
  });
  return {
    data,
    updateRecommendations: mutate,
    recommendations: data?.data?.Recommendations,
    count: data?.data?.Count,
    totalMonthlySavings: data?.data?.TotalMonthlySavings,
    ...args,
  };
};
