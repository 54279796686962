import { memo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Paper } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { FetchStates } from '../../constants';

const re = /^(https?:\/\/)(.+):(.+)@(.+)/;

const useStyles = makeStyles({
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      cursor: 'pointer',
    },
  },
  root: {
    cursor: 'pointer',
    padding: 8,
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
});

const ClusterListItem = ({ address, click, cluster }: { address: string }) => {
  const classes = useStyles();

  const [fetchState, setFetchState] = useState(FetchStates.INIT);
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = localStorage.getItem('container') === address;

  const handleSetCluster = (cluster: any) => {
    const basicAuthAddress = re.exec(cluster.address);
    const auth = cluster.details?.auth;

    if (!(basicAuthAddress || auth)) {
      Storage.setItem('container', cluster.address);
      click(cluster.address);
      navigate('/overview');
    } else {
      window.open(cluster.address);
    }
  };

  return (
    <Paper>
      <div
        className={classes.listItem}
        key={address}
        onClick={() => handleSetCluster(cluster)}
        style={{
          padding: '1em',
          marginBottom: '1em',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 30px',
          backgroundColor: isActive ? '' : '',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <div
            style={{
              fontWeight: 700,
              fontSize: '17px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {cluster.name}
          </div>
          <p
            style={{
              margin: 0,
              color: 'rgba(0,0,0,0.6)',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {address}
          </p>
        </div>
        <div style={{ textAlign: 'right', paddingTop: '12px' }}>
          {cluster.federatedCluster ? 'Multi Cluster' : 'Single Cluster'}
        </div>

        <ChevronRight style={{ marginTop: '10px' }} />
      </div>
    </Paper>
  );
};

export default memo(ClusterListItem);
