import { Button, Grid, Switch, TextField, Typography } from '@material-ui/core';
import {} from 'react-router-dom';

export interface DataElement {
  buttonText?: string;
  currentState?: any;
  // number?
  dataSource?: string;
  dataType: SettingDataType;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: any;
  onClick?: any;
  primaryText: string;
  secondaryText: string;
  updateState?: any;
}

export interface DataElementGrouping {
  dataElements: DataElement[];
  subText: string;
  title: string;
}

export enum SettingDataType {
  Text,
  Button,
  Toggle,
}

export const SingleDataElement = ({
  buttonText,
  currentState,
  dataSource,
  dataType,
  defaultValue,
  disabled,
  onClick,
  primaryText,
  secondaryText,
  updateState,
}: DataElement) => {
  const updateSwitch = (e: any) => {
    updateState({
      ...currentState,
      // this needs to be stored as a string to comply with api
      [`${dataSource}`]: String(e.target.checked),
    });
  };
  switch (dataType) {
    case SettingDataType.Text:
      return (
        <Grid spacing={3} style={{ marginTop: '.5em' }} container>
          <Grid xs={4} item>
            <Typography variant={'h6'}>{primaryText}</Typography>
            <Typography variant={'subtitle1'}>{secondaryText}</Typography>
          </Grid>
          <Grid xs={8} item>
            <TextField
              defaultValue={defaultValue}
              disabled={disabled}
              id={'outlined-basic'}
              label={primaryText}
              onChange={(e: any) =>
                updateState({
                  ...currentState,
                  [`${dataSource}`]: e.target.value,
                })
              }
              variant={'outlined'}
              fullWidth
            />
          </Grid>
        </Grid>
      );
    case SettingDataType.Button:
      return (
        <Grid spacing={3} style={{ marginTop: '.5em' }} container>
          <Grid xs={9} item>
            <Typography variant={'h6'}>{primaryText}</Typography>
            <Typography variant={'subtitle1'}>{secondaryText}</Typography>
          </Grid>
          <Grid style={{ textAlign: 'end' }} xs={3} item>
            <Button disabled={disabled} onClick={onClick}>
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      );
    case SettingDataType.Toggle:
      return (
        <Grid spacing={3} style={{ marginTop: '.5em' }} container>
          <Grid xs={9} item>
            <Typography variant={'h6'}>{primaryText}</Typography>
            <Typography variant={'subtitle1'}>{secondaryText}</Typography>
          </Grid>
          <Grid style={{ textAlign: 'end' }} xs={3} item>
            <Switch
              color={'primary'}
              defaultChecked={defaultValue === 'true'}
              disabled={disabled}
              name={dataSource}
              onChange={updateSwitch}
            />
          </Grid>
        </Grid>
      );
  }
};
