import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableHeadCell,
  TableCell,
} from '@kubecost-frontend/holster';
import React, { useState } from 'react';

import { CheckIcon } from '../../../assets/images';
import { useHosted } from '../../../hooks/useHosted';
import { EditTeamDrawer } from './EditTeamDrawer';

const TeamsTable = ({ teams }) => {
  const { profile, currentTeam, selectCurrentTeam } = useHosted();
  const [selectedTeam, setSelectedTeam] = useState(undefined);

  return (
    <>
      <Table className="w-full">
        <TableHead>
          <TableRow>
            <TableHeadCell></TableHeadCell>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Role</TableHeadCell>
            <TableHeadCell></TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teams.map((team) => (
            <TableRow>
              <TableCell>
                <div className={'flex justify-center items-center'}>
                  {team.id === currentTeam.id ?
                    <CheckIcon className={'text-lg text-kc-link'} /> :
                    <span className={'text-kc-link cursor-pointer'} role="button" onClick={() => selectCurrentTeam?.(team.id)}>Switch</span>
                  }
                </div>
              </TableCell>
              <TableCell className="font-bold">{team.name}</TableCell>
              <TableCell>
                {team.user_id === profile.id ? 'Owner' : 'Member'}
              </TableCell>
              <TableCell>
                <a onClick={() => setSelectedTeam(team)} className="text-right pr-8 hover:underline hover:cursor-pointer">
                  Invite Members
                </a>
                <a onClick={() => setSelectedTeam(team)} className="text-right hover:underline hover:cursor-pointer">
                  Edit
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditTeamDrawer
        onClose={() => setSelectedTeam(undefined)}
        teamId={selectedTeam && selectedTeam.id}
      />
    </>
  );
};

export default TeamsTable;
