import React, { createContext, useContext, useEffect, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { HostedConfig } from '..';
import { APIClient } from '../services/APIClient';

import { useLocalStorage } from './useLocalStorage';

const HostedProvider = ({ children, initialConfig }: any) => {
  const [currentTeamId, setCurrentTeamId] = useLocalStorage<string>(
    'current_team',
    () => (initialConfig.profileData?.owned_teams[0]?.id as string) ?? '',
  );

  useEffect(() => {
    // If somehow we don't have access to the current team
    // we should reset to the first owned team.
    if (
      !initialConfig.profileData.owned_teams.find((team) => team.id === currentTeamId) &&
      !initialConfig.profileData.teams.find((team) => team.id === currentTeamId)
    ) {
      setCurrentTeamId(initialConfig.profileData.owned_teams[0].id);
    } else {
      setAxiosTeamHeader(currentTeamId);
    }
  }, [currentTeamId]);

  const selectCurrentTeam = (teamId: string) => setCurrentTeamId(teamId);

  const { data } = useQuery({
    queryKey: ProfileQueryKey,
    initialData: initialConfig.profileData,
    queryFn: async () => {
      const response = await APIClient.get(`${initialConfig.config.userServiceAPIUrl}/api/user`);
      return response.data;
    },
  });

  const {
    data: clusterInfoMapData,
    isLoading: clusterInfoMapLoading,
    error: clusterInfoMapError,
  } = useQuery({
    queryKey: ['hosted', 'cluster_info_map', currentTeamId],
    queryFn: () => APIClient.get('/clusterInfoMap'),
  });

  // const onlineAgentsLoading = clusterInfoMapLoading;
  // const onlineAgentsError = clusterInfoMapError;
  const onlineAgents =
    clusterInfoMapError || clusterInfoMapLoading ? null : clusterInfoMapData?.data.data;

  const { data: productVersionData, error: productVersionError } = useQuery({
    queryKey: ['hosted', 'product_version'],
    queryFn: () => APIClient.get('/productVersion'),
  });

  let productVersion;

  if (!productVersionError) {
    productVersion = productVersionData?.data.data;
  }

  const currentTeam =
    data && [...data.owned_teams, ...data.teams].find((team) => team.id == currentTeamId);

  return (
    <HostedContext.Provider
      value={{
        config: initialConfig.config,
        currentTeamId,
        selectCurrentTeam,
        profile: data,
        currentTeam,
        onlineAgents,
        productVersion,
      }}
    >
      {children}
    </HostedContext.Provider>
  );
};

const setAxiosTeamHeader = (teamId: string) => {
  APIClient.defaults.headers.common['team-id'] = teamId;
};

type HostedContextState = {
  config?: HostedConfig;
  currentTeam?: any;
  currentTeamId?: string;
  onlineAgents?: any;
  productVersion?: any;
  profile?: any;
  selectCurrentTeam: (teamId: string) => void;
};

const HostedContext = createContext<HostedContextState>({
  selectCurrentTeam: () => {},
});

const ProfileQueryKey = ['profile'];

export { HostedProvider, ProfileQueryKey, useHosted };

const useHosted = () => {
  const queryClient = useQueryClient();
  const { config, currentTeam, currentTeamId, onlineAgents, productVersion, profile, selectCurrentTeam } =
    useContext<HostedContextState>(HostedContext);
  if (!config)
    return {
      isHostedEnvironment: false,
    };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate: acceptInvite } = useMutation(
    (payload: any) =>
      APIClient.post(`${config?.userServiceAPIUrl}/api/teams/${payload.team_id}/invite/accept`),
    {
      onSuccess() {
        queryClient.invalidateQueries('profile');
      },
    },
  );

  localStorage.setItem('betaUI', 'true');

  return {
    currentTeam,
    acceptInvite,
    profile,
    selectCurrentTeam,
    isHostedEnvironment: true,
    currentTeamId,
    config,
    onlineAgents,
    productVersion,
  };
};
