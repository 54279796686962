import { IconProps } from './types';

const FilterIcon = ({ ...args }: IconProps) => (
  <svg
    height={'1em'}
    preserveAspectRatio={'xMidYMid meet'}
    viewBox={'0 0 16 16'}
    width={'1em'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...args}
  >
    <path
      clipRule={'evenodd'}
      d={'M15 2v1.67l-5 4.759V14H6V8.429l-5-4.76V2h14zM7 8v5h2V8l5-4.76V3H2v.24L7 8z'}
      fill={'currentColor'}
      fillRule={'evenodd'}
    />
  </svg>
);

export { FilterIcon };
