import { FacebookCircularProgress2 } from '../../../components/CircularProgress2';

interface CircleProgress2Props {
  label: string;
  value: number;
  width: string;
}

export const CircleProgress2 = ({ label, value, width }: CircleProgress2Props) => (
  <div>
    <FacebookCircularProgress2
      size={width}
      thickness={5}
      value={value > 100 ? 100 : value}
      variant={'determinate'}
    />
    <div>{label}</div>
    <div>{value}%</div>
  </div>
);
