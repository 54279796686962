import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { Tab, Tabs } from '@material-ui/core';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

import { ArrowIcon } from '../../../assets/images';
import { Header } from '../../../components/Header2New';

import { getUnclaimedVolumeData } from './api';
import { TableColumn } from './types';

const UnclaimedVolumesNew: FC = () => {
  const [volumes, setVolumes] = useState<
    {
      name: string;
      owner: string;
      size: string;
      storageClass: string;
    }[]
  >([]);
  const [grouping, setGrouping] = useState(0);
  const [columns, setColumns] = useState<TableColumn[]>([
    { head: 'Volume', prop: 'name' },
    { head: 'Storage Class', prop: 'storageClass' },
    { head: 'Size', prop: 'size' },
  ]);

  const initializeData = () => {
    getUnclaimedVolumeData().then((data) => {
      setVolumes(data);
    });
  };

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    if (grouping === 0) {
      setColumns([
        { head: 'Volume', prop: 'name' },
        { head: 'Storage Class', prop: 'storageClass' },
        { head: 'Size', prop: 'size' },
      ]);
    } else if (grouping === 1) {
      setColumns([
        { head: 'Volume', prop: 'name' },
        { head: 'Owner', prop: 'owner' },
        { head: 'Size', prop: 'size' },
      ]);
    }
  }, [grouping]);

  const handleGroupingChange = useCallback((event: ChangeEvent<{}>, newValue: number) => {
    setGrouping(newValue);
  }, []);
  return (
    <>
      <Header
        refreshCallback={initializeData}
        title={
          <>
            Unclaimed Volumes
            <Typography className={'font-normal text-kc-gray-200'} variant={'p'}>
              <a href={'savings'}>
                <ArrowIcon className={'inline'} direction={'UP'} />
                Back to savings
              </a>
            </Typography>
          </>
        }
      />

      <Typography variant={'h5'}>Volumes unused by any volume claims</Typography>
      <Typography className={'mb-6'} variant={'p'}>
        View volumes unused by any pods. Consider deleting or moving these to a cheaper storage tier
        to save money.
      </Typography>

      <Tabs indicatorColor={'primary'} onChange={handleGroupingChange} value={grouping}>
        <Tab label={'By Owner'} />
        <Tab label={'By Namespace'} />
      </Tabs>
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableHeadCell>{col.head}</TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {volumes.map((volume) => (
            <TableRow>
              {columns.map((col) => (
                <TableCell>{volume[col.prop]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

UnclaimedVolumesNew.displayName = 'UnclaimedVolumes';

export { UnclaimedVolumesNew };
