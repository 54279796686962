import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ExportIcon from '@material-ui/icons/GetApp';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import reverse from 'lodash/reverse';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import { memo } from 'react';

const columns = [
  {
    head: 'Name',
    prop: 'name',
    currency: false,
  },
  {
    head: 'Provider ID',
    prop: 'providerID',
    currency: false,
  },
  {
    head: 'Credits',
    prop: 'credit',
    currency: true,
  },
  {
    head: 'Adjusted',
    prop: 'adjustment',
    currency: true,
  },
  {
    head: 'Total',
    prop: 'totalCost',
    currency: true,
  },
];

const toCSVLine = (datum) => {
  const cols = [];

  forEach(columns, (c) => {
    if (c.currency) {
      cols.push(round(`${get(datum, c.prop, 0.0)}`, 2));
    } else {
      cols.push(`"${get(datum, c.prop, '')}"`);
    }
  });

  return cols.join(',');
};

const DownloadControl = ({ cumulativeData, title }) => {
  // downloadReport downloads a CSV of the cumulative allocation data
  function downloadReport() {
    // Build CSV
    const head = columns.map((c) => c.head).join(',');
    const body = reverse(sortBy(cumulativeData, 'totalCost')).map(toCSVLine).join('\r\n');
    const csv = `${head}\r\n${body}`;

    // Create download link
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
    const filename = title.toLowerCase().replace(/\s/gi, '-');
    a.setAttribute('download', `${filename}-${Date.now()}.csv`);

    // Click the link
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <Tooltip title={'Download CSV'}>
      <IconButton onClick={downloadReport}>
        <ExportIcon />
      </IconButton>
    </Tooltip>
  );
};

export default memo(DownloadControl);
