import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

interface Alert {
  aggregation: string;
  baselineWindow?: string;
  efficiencyThreshold?: number;
  filter: string;
  id?: string;
  ownerContact?: string[];
  relativeThreshold?: number;
  slackWebhookUrl?: string;
  spendThreshold?: number;
  threshold?: number;
  type: string;
  window: string;
}

interface ComponentProps {
  alert: Alert | null;
  close: () => void;
  remove: (alert: Alert) => Promise<void>;
}

const DeleteAlertModal: React.FC<ComponentProps> = ({ alert, close, remove }: ComponentProps) => {
  if (!alert) {
    return <></>;
  }
  return (
    <Dialog data-test={'delete-alert-dialog'} open>
      <DialogTitle>Deleting Alert</DialogTitle>
      <DialogContent>
        <DialogContentText>Preparing to delete the following alert:</DialogContentText>
        <Typography>Type: {alert.type}</Typography>
        <Typography>Window: {alert.window}</Typography>
        <Typography>Aggregation: {alert.aggregation || '""'}</Typography>
        <Typography>Filter: {alert.filter || '*'}</Typography>
        {has(alert, 'threshold') ? (
          <Typography>Threshold: {alert.threshold || 'Not Set'}</Typography>
        ) : (
          <></>
        )}
        {has(alert, 'efficiencyThreshold') ? (
          <Typography>Efficiency Threshold: {alert.efficiencyThreshold || 'Not Set'}</Typography>
        ) : (
          <></>
        )}
        {has(alert, 'spendThreshold') ? (
          <Typography>Spend Threshold: {alert.spendThreshold || 'Not Set'}</Typography>
        ) : (
          <></>
        )}
        {has(alert, 'baselineWindow') ? (
          <Typography>Baseline Window: {alert.baselineWindow || 'Not Set'}</Typography>
        ) : (
          <></>
        )}
        {has(alert, 'relativeThreshold') ? (
          <Typography>Relative Threshold: {alert.relativeThreshold || 'Not Set'}</Typography>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <Button color={'primary'} data-test={'cancel-button'} onClick={close}>
          Cancel
        </Button>
        <Button
          color={'primary'}
          data-test={'confirm-button'}
          onClick={onDelete}
          variant={'contained'}
          disableElevation
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  async function onDelete() {
    if (alert) {
      await remove(alert);
    }
    close();
  }
};

function has(alert: Alert | null, prop: string) {
  return Object.prototype.hasOwnProperty.call(alert, prop);
}

export default DeleteAlertModal;
