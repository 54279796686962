import { FC, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { isValid } from 'date-fns';

import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { Button, Typography } from '@kubecost-frontend/holster';

interface EnableAutoscalingProps {
  handleAutoscalingRequest: (frequency: number, startDate: Date) => void;
  loading: boolean;
}

const frequencyOptions = [
  { display: 'Daily', value: 60 * 24 },
  { display: 'Weekly', value: 60 * 24 * 7 },
  { display: 'Monthly', value: 60 * 24 * 7 * 4 },
  { display: 'Quarterly', value: 60 * 24 * 7 * 4 * 3 },
];

export const EnableAutoscaling: FC<EnableAutoscalingProps> = ({
  handleAutoscalingRequest,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = useState<EventTarget & Element>();
  const [selectedFrequency, setSelectedFrequency] = useState(frequencyOptions[0].value);
  const [startDate, setStartDate] = useState(new Date());

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    if (isValid(date)) {
      setStartDate(date);
    }
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Button className={'mr-3'} onClick={(e) => setAnchorEl(e.currentTarget)} variant={'default'}>
        Enable Autoscaling {loading && <CircularProgress className={'ml-2'} size={'15px'} />}
      </Button>
      <Popover
        PaperProps={{
          className: 'p-6',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={() => {
          setAnchorEl(undefined);
        }}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ width: 200 }}>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                format={'MM/dd/yyyy HH:mm'}
                id={'date-range-control-custom-start'}
                label={'Start Date'}
                margin={'normal'}
                onChange={handleStartDateChange}
                style={{ width: '200px' }}
                value={startDate}
                variant={'inline'}
                autoOk
                disableToolbar
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={'mt-4'}>
            {frequencyOptions.map((opt: { display: string; value: number }) => (
              <Typography
                className={`cursor-pointer rounded p-2 hover:bg-kc-success-light ${
                  selectedFrequency == opt.value ? 'text-kc-link' : ''
                }`}
                onClick={() => setSelectedFrequency(opt.value)}
                variant={'p'}
              >
                {opt.display}
              </Typography>
            ))}
          </div>
        </div>
        <div className={'mt-3 flex justify-end'}>
          <div className={'cursor-pointer p-2 hover:bg-kc-success-light'}>Cancel</div>
          <div
            className={'cursor-pointer p-2 text-kc-primary hover:bg-kc-success-light'}
            onClick={() => {
              handleAutoscalingRequest(selectedFrequency, startDate);
              setAnchorEl(undefined);
            }}
          >
            Apply
          </div>
        </div>
        <div className={'mt-2'} style={{ width: 200 }}>
          <Typography variant={'p-small'}>
            <span className={'font-bold'}>Note: </span>
            this will only be applied to Deployments
          </Typography>
        </div>
      </Popover>
    </>
  );
};

EnableAutoscaling.displayName = 'EnableAutoscaling';
