export const renderScoreGraph = () => {
  // @ts-ignore
  helper.getClusterHealthScore().then((data) => {
    const rotation = data.score / (100 * 2);
    const healthCategory = data.category.toUpperCase();
    let scoreColor = '#3CBA54';

    if (healthCategory == 'FAIR') {
      scoreColor = '#F4B400';
    } else if (healthCategory == 'POOR') {
      scoreColor = 'red';
    }

    $('#gauge-loading').hide();
    $('.gauge-a').show();
    $('.gauge-c').css('transform', `rotate(${rotation}turn)`);
    $('.gauge-c').css('background-color', scoreColor);
    $('#gauge-percent').html(data.score);
    $('#gauge-percent').css('color', scoreColor);
    $('#gauge-percent').fadeIn('slow');
    $('#rating-info').fadeIn('slow');

    $('#health-description').html(
      `Your health score is <span style="color: ${scoreColor};">${healthCategory}</span>`,
    );
  });
};
