import { makeStyles } from '@material-ui/core';
import reverse from 'lodash/reverse';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import { useClusters } from '../../../contexts/ClusterConfig';
import { primary, greyscale, browns } from '../../../services/colors';
import { toCurrency } from '../../../services/format';

const useStyles = makeStyles({
  tooltip: {
    borderRadius: 2,
    background: 'rgba(255, 255, 255, 0.95)',
    padding: 12,
  },
  tooltipLineItem: {
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  },
});

function toBarLabels(allocationRange) {
  const keyToFill = {};
  let p = 0;
  let g = 0;
  let b = 0;

  allocationRange.forEach(({ idle, other, top }) => {
    idle.forEach((allocation) => {
      const key = allocation.name;
      if (keyToFill[key] === undefined) {
        // idle allocations are assigned grey
        keyToFill[key] = greyscale[g];
        g = (g + 1) % greyscale.length;
      }
    });

    top.forEach((allocation) => {
      const key = allocation.name;
      if (keyToFill[key] === undefined) {
        if (key === '__unallocated__') {
          // unallocated gets black (clean up)
          keyToFill[key] = '#212121';
        } else {
          // non-idle allocations get the next available color
          keyToFill[key] = primary[p];
          p = (p + 1) % primary.length;
        }
      }
    });

    other.forEach((allocation) => {
      const key = allocation.name;
      if (keyToFill[key] === undefined) {
        // other allocations are assigned brown
        keyToFill[key] = browns[b];
        b = (b + 1) % browns.length;
      }
    });
  });

  let labels = Object.entries(keyToFill)
    .map(([dataKey, fill]) => ({
      dataKey,
      fill,
    }))
    .sort(({ dataKey: dk1 }, { dataKey: dk2 }) => {
      if (dk1 === '__idle__') {
        return -1;
      }
      if (dk2 === '__idle__') {
        return 1;
      }
      return dk1 > dk2 ? 1 : -1;
    });

  // Pin other category to bottom
  const other = labels.filter((l) => l.dataKey === 'other');
  if (other.length > 0) {
    labels = labels.filter((l) => l.dataKey !== 'other');
    labels.push(other[0]);
  }

  return reverse(labels);
}

function toBar(datum) {
  const { idle, other, top } = datum;
  const bar = {};

  const dateFormatter = Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  });

  const timeFormatter = Intl.DateTimeFormat(navigator.language, {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC',
  });

  top.forEach((allocation) => {
    if (!allocation.window) {
      console.warn(`Allocation ${allocation.name} is missing a window.`);
      return;
    }
    const windowStart = new Date(allocation.window.start);
    const windowEnd = new Date(allocation.window.end);
    const windowHours = (windowEnd - windowStart) / 1000 / 60 / 60;

    bar.start = new Date(allocation.start);
    bar.end = new Date(allocation.end);
    if (windowHours >= 24) {
      bar.key = dateFormatter.format(bar.start);
    } else {
      bar.key = timeFormatter.format(bar.start);
    }

    bar[allocation.name] = allocation.totalCost;
  });

  other.forEach((allocation) => {
    if (!allocation.window) {
      console.warn(`Allocation ${allocation.name} is missing a window.`);
      return;
    }
    const windowStart = new Date(allocation.window.start);
    const windowEnd = new Date(allocation.window.end);
    const windowHours = (windowEnd - windowStart) / 1000 / 60 / 60;

    bar.start = new Date(allocation.start);
    bar.end = new Date(allocation.end);
    if (windowHours >= 24) {
      bar.key = dateFormatter.format(bar.start);
    } else {
      bar.key = timeFormatter.format(bar.start);
    }

    bar[allocation.name] = allocation.totalCost;
  });

  idle.forEach((allocation) => {
    if (!allocation.window) {
      console.warn(`Allocation ${allocation.name} is missing a window.`);
      return;
    }

    const windowStart = new Date(allocation.window.start);
    const windowEnd = new Date(allocation.window.end);
    const windowHours = (windowEnd - windowStart) / 1000 / 60 / 60;

    bar.start = new Date(allocation.start);
    bar.end = new Date(allocation.end);
    if (windowHours >= 24) {
      bar.key = dateFormatter.format(bar.start);
    } else {
      bar.key = timeFormatter.format(bar.start);
    }

    bar[allocation.name] = allocation.totalCost;
  });

  return bar;
}

const RangeChart = ({ data, height }) => {
  const classes = useStyles();
  const { modelConfig } = useClusters();
  const barData = data.map(toBar);
  const barLabels = toBarLabels(data);

  const CustomTooltip = (params) => {
    const { active, payload } = params;

    if (!payload || payload.length === 0) {
      return null;
    }

    const dateTimeFormatter = Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

    const total = payload.reduce((sum, item) => sum + item.value, 0.0);

    let start = null;
    if (payload[0] && payload[0].payload) {
      start = payload[0].payload.start;
    }

    let end = null;
    if (payload[0] && payload[0].payload) {
      end = payload[0].payload.end;
    }

    if (active) {
      return (
        <div className={classes.tooltip}>
          {start && end && (
            <p
              className={classes.tooltipLineItem}
              style={{ color: '#808080', fontSize: '0.75rem' }}
            >{`${dateTimeFormatter.format(start)} to ${dateTimeFormatter.format(end)}`}</p>
          )}
          <p
            className={classes.tooltipLineItem}
            style={{
              color: '#000000',
              marginBottom: '1em',
              marginTop: '.25em',
            }}
          >{`Total: ${toCurrency(total, modelConfig.currencyCode, 0, true)}`}</p>
          {reverse(payload).map((item) => (
            <div
              key={item.name}
              style={{
                display: 'grid',
                gridTemplateColumns: '20px 1fr',
                gap: '.5em',
                margin: '.25em',
              }}
            >
              <div>
                <div style={{ backgroundColor: item.fill, width: 18, height: 18 }} />
              </div>
              <div>
                <p className={classes.tooltipLineItem}>{`${item.name}: ${toCurrency(
                  item.value,
                  modelConfig.currencyCode,
                )}`}</p>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer height={height} width={'100%'}>
      <BarChart data={barData} margin={{ top: 30, right: 35, left: 30, bottom: 45 }}>
        <CartesianGrid strokeDasharray={'3 3'} vertical={false} />
        <XAxis dataKey={'key'} />
        <YAxis tickFormatter={(val) => toCurrency(val, modelConfig.currencyCode, 2, true)} />
        <Tooltip content={<CustomTooltip />} wrapperStyle={{ zIndex: 1000 }} />
        {barLabels.map((barLabel, i) => (
          <Bar dataKey={barLabel.dataKey} fill={barLabel.fill} key={i} stackId={'a'} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RangeChart;
