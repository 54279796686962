import { FC, memo, useEffect, useState } from 'react';

import { Button, Input, Modal, Typography } from '@kubecost-frontend/holster';

import { AssetReport } from '../../../types/asset';

interface SaveControlProps {
  open: boolean;
  report?: AssetReport;
  save: (title: string) => void;
  setSaveOpen: () => void;
  title: string;
  unsave: (report: AssetReport) => void;
}

export const SaveControl: FC<SaveControlProps> = ({
  open,
  report,
  save,
  setSaveOpen,
  title,
  unsave,
}) => {
  const [localTitle, setLocalTitle] = useState('');

  const saveHandler = () => {
    save(localTitle);
    setSaveOpen();
  };

  const unsaveHandler = () => {
    if (!report) {
      return;
    }

    unsave(report);
    setSaveOpen();
  };

  useEffect(() => {
    if (report && report.title) {
      setLocalTitle(report.title);
    } else if (title) {
      setLocalTitle(title);
    }
  }, [report, title]);

  return (
    <Modal onClose={setSaveOpen} open={open} title={report ? 'Unsave report' : 'Save report'}>
      <div style={{ padding: '1em' }}>
        {report ? (
          <Typography
            variant={'p'}
          >{`Are you sure you want to unsave ${localTitle}? This action affects all users.`}</Typography>
        ) : (
          <Input
            onChange={(e) => setLocalTitle(e.target.value)}
            style={{ width: '100%' }}
            value={localTitle}
          />
        )}
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '10px',
        }}
      >
        {report ? (
          <Button onClick={unsaveHandler} variant={'primary'}>
            Unsave
          </Button>
        ) : (
          <Button onClick={saveHandler} variant={'primary'}>
            Save
          </Button>
        )}
        <Button onClick={setSaveOpen} variant={'default'}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default memo(SaveControl);
