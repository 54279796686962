import { useEffect, useReducer, useState } from 'react';

import { Button, TextField } from '@material-ui/core';

import logo from '../../assets/images/kubecost_logo.svg';
import { fetchWithTimeout } from '../../services/util';
// @ts-ignore

type FailedClusterAction =
  | { address: string; type: 'checkURL' }
  | { type: 'failCheck' }
  | { type: 'checkSucceeded' };

type FailedClusterState = {
  checkFailed: boolean;
  checkSucceeded: boolean;
  checkedAddress: string;
  loading: boolean;
};

const initialState: FailedClusterState = {
  checkedAddress: '',
  loading: false,
  checkSucceeded: false,
  checkFailed: false,
};

const FailedClusterDialog = () => {
  const [urlTextFieldText, setUrlTextFieldText] = useState<string>('');
  const [{ checkFailed, checkSucceeded, checkedAddress, loading }, dispatch] = useReducer(
    (state: FailedClusterState, action: FailedClusterAction): FailedClusterState => {
      switch (action.type) {
        case 'checkSucceeded':
          return {
            ...state,
            checkSucceeded: true,
            checkFailed: false,
            loading: false,
          };
        case 'failCheck':
          return {
            ...state,
            checkSucceeded: false,
            checkFailed: true,
            loading: false,
          };
        case 'checkURL':
          return {
            checkSucceeded: false,
            checkFailed: false,
            loading: true,
            checkedAddress: action.address,
          };
      }
    },
    initialState,
  );

  // Set the local storage container for the current context
  // Replace the bad item in the all_containers to ensure
  // the cluster switcher picks it up.
  useEffect(() => {
    if (checkSucceeded) {
      const badContainer = localStorage.getItem('container');
      let allContainers: string[] | undefined = localStorage.getItem('all_containers')?.split(',');

      if (allContainers) {
        allContainers = allContainers.filter((c) => c !== badContainer);
        allContainers.push(checkedAddress);
        localStorage.setItem('all_containers', allContainers.join(','));
      }

      // Set Container
      localStorage.setItem('container', checkedAddress);

      setTimeout(() => {
        location.reload();
      }, 2000);
    }
  }, [checkSucceeded]);

  const checkUrl = (url: string) => {
    if (!url.startsWith('http')) {
      url = `http://${url}`;
    }

    dispatch({
      type: 'checkURL',
      address: url,
    });
    const URL = `${url}/model/clusterInfo`;
    fetchWithTimeout(URL)
      .then(() => {
        dispatch({
          type: 'checkSucceeded',
        });
      })
      .catch((e) => {
        dispatch({
          type: 'failCheck',
        });
      });
  };

  return (
    <div
      style={{
        width: '961px',
        height: '646px',
        borderRadius: '13px',
        backgroundColor: 'white',
        margin: 'auto',
        marginTop: '5em',
        padding: '4em',
      }}
    >
      <div>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <img src={logo} style={{ height: '98px' }} />
          <h1 style={{ margin: '.2em', fontSize: '60px', fontWeight: 600 }}>Oh No!</h1>
          <p>
            Either your <strong>Kubecost</strong> API is on vacation, or we've misplaced it.
            <br />
            Will you help us locate it by providing the current API url?
          </p>
        </div>
        <div style={{ width: '591px', margin: 'auto', marginTop: '3em' }}>
          <div style={{ width: '100%', display: 'inline-flex' }}>
            <p style={{ flexGrow: '1' }}>
              <strong>Hint:</strong> It's usually something like{' '}
            </p>
            <div
              style={{
                border: '1px solid #E5E5E5',
                borderRadius: '3px',
                paddingLeft: '2em',
                paddingRight: '2em',
                height: '40px',
              }}
            >
              <pre>http://localhost:9090</pre>
            </div>
          </div>
          <div style={{ width: '100%', display: 'inline-flex', marginTop: '1em' }}>
            <p style={{ flexGrow: '1' }}>
              <strong>Or:</strong> it may appear as
            </p>
            <div
              style={{
                border: '1px solid #E5E5E5',
                borderRadius: '3px',
                paddingLeft: '2em',
                paddingRight: '2em',
                height: '40px',
              }}
            >
              <pre>http://kubecost.mycompany.com:9090</pre>
            </div>
          </div>
          <div style={{ marginTop: '2em' }}>
            <TextField
              disabled={loading || checkSucceeded}
              inputProps={{ 'data-testid': 'url-input' }}
              label={'API Url'}
              onChange={(e) => setUrlTextFieldText(e.target.value)}
              onKeyDown={(e) => {
                if (e.code === 'Enter') checkUrl(urlTextFieldText);
              }}
              placeholder={'http://localhost:9090'}
              style={{ width: '100%' }}
              value={urlTextFieldText}
              variant={'outlined'}
              autoFocus
            />
          </div>
          {checkFailed && (
            <p data-testid={'error-message'} style={{ color: 'red' }}>
              Uh oh. It doesn't look like that URL is working.
            </p>
          )}
          {checkSucceeded && (
            <p data-testid={'success-message'} style={{ color: 'green' }}>
              Looks like that worked! Redirecting you now!
            </p>
          )}
          <Button
            color={'primary'}
            data-testid={'submit'}
            disabled={loading || checkSucceeded}
            onClick={() => {
              checkUrl(urlTextFieldText);
            }}
            style={{ marginTop: '1em' }}
            variant={'contained'}
          >
            {loading ? 'Checking...' : 'Check URL'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FailedClusterDialog;
