import { useEffect, useState } from 'react';

import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';
import AlertComponent from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';

import {
  Button,
  FormControlLabel,
  Input,
  Modal,
  Select,
  Typography,
} from '@kubecost-frontend/holster';

import { SelectWindowMemoized as SelectWindow } from '../../components/SelectWindow';
import {
  Alert as AlertModel,
  AlertTypes,
  AssetBudget,
  AssetRecurring,
  BudgetAlert,
  EfficiencyAlert,
  RecurringUpdate,
  SpendChangeAlert,
} from '../../services/alerts';
import Logger from '../../services/logger';

import EmailRecipients from './EmailRecipients';

interface Alert {
  aggregation: string;
  baselineWindow?: string;
  efficiencyThreshold?: number;
  filter: string;
  id?: string;
  ownerContact?: string[];
  relativeThreshold?: number;
  slackWebhookUrl?: string;
  spendThreshold?: number;
  threshold?: number;
  type: string;
  window: string;
}

interface ComponentProps {
  alert: Alert | null;
  close: () => void;
  save: (alert: AlertModel) => Promise<void>;
  test: (alert: AlertModel) => Promise<void>;
}

const allocationAggregationOptions = [
  {
    name: 'Cluster',
    value: 'cluster',
  },
  {
    name: 'Controller',
    value: 'controller',
  },
  {
    name: 'Container',
    value: 'container',
  },
  {
    name: 'Deployment',
    value: 'deployment',
  },
  {
    name: 'Label',
    value: 'label',
  },
  {
    name: 'Namespace',
    value: 'namespace',
  },
  {
    name: 'Pod',
    value: 'pod',
  },
  {
    name: 'Service',
    value: 'service',
  },
];

const assetAggregationOptions = [
  {
    name: 'Service',
    value: 'service',
  },
  {
    name: 'Type',
    value: 'type',
  },
  {
    name: 'Category',
    value: 'category',
  },
  {
    name: 'Cluster',
    value: 'cluster',
  },
  {
    name: 'Provider',
    value: 'provider',
  },
  {
    name: 'Account',
    value: 'account',
  },
  {
    name: 'Provider ID',
    value: 'ProviderID',
  },
];

const windowOptions = [
  { label: '1 day', value: '1d' },
  { label: '2 days', value: '2d' },
  { label: '3 days', value: '3d' },
  { label: '4 days', value: '4d' },
  { label: '5 days', value: '5d' },
  { label: '6 days', value: '6d' },
  { label: '7 days', value: '7d' },
];

const useStyles = makeStyles({
  formGroup: {
    margin: '20px 0px 20px 0px',
  },
  helpIcon: {
    color: '#999',
    fontSize: '1.2rem',
    cursor: 'pointer',
  },
  tooltip: {
    fontSize: '.9rem',
  },
});

const CreateAlertModal = ({
  alert: initialAlert,
  close,
  save,
  test,
}: ComponentProps): JSX.Element => {
  const classes = useStyles();

  const [alertId, setAlertId] = useState('');
  const [type, setType] = useState('budget');
  const [alertWindow, setAlertWindow] = useState('7d');
  const [aggregation, setAggregation] = useState('namespace');
  const [aggLabel, setAggLabel] = useState('');
  const [alertFilter, setAlertFilter] = useState('');
  const [threshold, setThreshold] = useState('');
  const [efficiencyThreshold, setEfficiencyThreshold] = useState('');
  const [spendThreshold, setSpendThreshold] = useState('');
  const [baselineWindow, setBaselineWindow] = useState('7d');
  const [relativeChangeThreshold, setRelativeChangeThreshold] = useState('');
  const [alertEmailRecipients, setAlertEmailRecipients] = useState<string[]>([]);
  const [emailSubject, setEmailSubject] = useState('');
  const [alertSlackHook, setAlertSlackHook] = useState('');
  const [alertSlackHookDirty, setAlertSlackHookDirty] = useState(false);
  const [formError, setFormError] = useState('');
  const [emailRecipientText, setEmailRecipientText] = useState('');
  const [aggregationOptions, setAggregationOptions] = useState(allocationAggregationOptions);
  useEffect(() => {
    if (initialAlert) {
      setType(initialAlert.type);
      setAlertWindow(initialAlert.window);
      if (initialAlert.aggregation.startsWith('label:')) {
        setAggregation('label');
        setAggLabel(initialAlert.aggregation.slice(6));
      } else {
        setAggregation(initialAlert.aggregation);
      }
      if (initialAlert.filter) {
        setAlertFilter(initialAlert.filter);
      }
      if (initialAlert.threshold) {
        setThreshold(initialAlert.threshold.toString());
      }
      if (initialAlert.efficiencyThreshold) {
        setEfficiencyThreshold((initialAlert.efficiencyThreshold * 100).toString());
      }
      if (initialAlert.spendThreshold) {
        setSpendThreshold(initialAlert.spendThreshold.toString());
      }
      if (initialAlert.baselineWindow) {
        setBaselineWindow(initialAlert.baselineWindow);
      }
      if (initialAlert.relativeThreshold) {
        setRelativeChangeThreshold((initialAlert.relativeThreshold * 100).toString());
      }
      if (initialAlert.ownerContact) {
        setAlertEmailRecipients(initialAlert.ownerContact);
      }
      if (initialAlert.emailSubject) {
        setEmailSubject(initialAlert.emailSubject);
      }
      if (initialAlert.slackWebhookUrl) {
        setAlertSlackHook(
          'https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX',
        );
      }
      if (initialAlert.id) {
        setAlertId(initialAlert.id);
      }
    }
  }, [initialAlert]);

  useEffect(() => {
    if (type.startsWith('asset') || type.startsWith('cloud')) {
      if (aggregationOptions !== assetAggregationOptions) {
        setAggregation('service');
      }
      setAggregationOptions(assetAggregationOptions);
    } else {
      if (aggregationOptions !== allocationAggregationOptions) {
        setAggregation('namespace');
      }
      setAggregationOptions(allocationAggregationOptions);
    }
  }, [type]);

  return (
    <Modal
      data-test={'create-alert-dialog'}
      onClose={onClose}
      open={!!initialAlert}
      title={alertId ? 'Edit Alert' : 'Create Alert'}
    >
      {/* Alert Type */}
      <div className={'my-6'}>
        <FormControlLabel>Alert Type</FormControlLabel>
        <Select
          data-test={'type-select'}
          options={[
            { label: 'Allocation Budget', value: AlertTypes.Budget },
            { label: 'Allocation Efficiency', value: AlertTypes.Efficiency },
            {
              label: 'Allocation Recurring Update',
              value: AlertTypes.Recurring,
            },
            {
              label: 'Allocation Spend Change',
              value: AlertTypes.SpendChange,
            },
            { label: 'Asset Budget', value: AlertTypes.AssetBudget },
            { label: 'Cloud Report', value: AlertTypes.AssetRecurring },
          ]}
          setValue={(alertType: string) => setType(alertType)}
          value={type}
        />
        <Typography variant={'h6'}>{getAlertTypeText()}</Typography>
      </div>

      <div className={'items-top flex justify-between'}>
        {/* Alert Window */}
        <div>
          <FormControlLabel>Window</FormControlLabel>
          <Select options={windowOptions} setValue={setAlertWindow} value={alertWindow} />
          <Typography variant={'h6'}>The date range over which to query items</Typography>
        </div>

        {/* Alert Aggregation */}
        <div>
          <FormControlLabel>Aggregation</FormControlLabel>
          <Select
            options={aggregationOptions.map((opt) => ({
              label: opt.name,
              value: opt.value,
            }))}
            setValue={(agg) => {
              setAggLabel('');
              setAggregation(agg);
            }}
            value={aggregation}
          />
          <Typography variant={'h6'}>{`Type of ${
            aggregationOptions === assetAggregationOptions ? 'cloud asset' : 'Kubernetes object'
          } to consider`}</Typography>
        </div>

        {/* An input for Label, if that aggregation is selected */}
        {aggregation === 'label' ? (
          <Input
            helperText={'The label to aggregate by'}
            label={'Label'}
            onChange={(e) => setAggLabel(e.target.value)}
            placeholder={'app:kubecost'}
            style={{ marginLeft: 12 }}
            value={aggLabel}
          />
        ) : (
          <></>
        )}
      </div>

      {/* Alert Filter */}
      <FormGroup className={classes.formGroup}>
        <Input
          helperText={getFilterHelperText()}
          label={'Filter'}
          onChange={(e) => setAlertFilter(e.target.value)}
          value={alertFilter}
        />
      </FormGroup>

      {/* Content depends on the type of Alert we are creating */}
      {fieldsForType(type)}

      {/* Finally, optional settings for recipient channels */}
      <Typography variant={'h6'}>Recipients</Typography>
      <FormGroup>
        <Input
          helperText={
            alertId
              ? 'Slack webhook for this alert (optional). Obfuscated for security purposes.'
              : 'Slack webhook for this alert (optional).'
          }
          label={'Slack webhook'}
          onChange={(e) => {
            setAlertSlackHookDirty(true);
            setAlertSlackHook(e.target.value);
          }}
          placeholder={
            'https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX'
          }
          style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
          value={alertSlackHook}
        />
      </FormGroup>

      <FormGroup>
        <Input
          helperText={'Custom email subject line (optional).'}
          label={'Subject'}
          onChange={(e) => {
            setEmailSubject(e.target.value);
          }}
          placeholder={'Kubecost Alert'}
          value={emailSubject}
        />
      </FormGroup>

      <EmailRecipients
        addItem={(item: string) => {
          if (!alertEmailRecipients.includes(item)) {
            setAlertEmailRecipients((recp) => [...recp, item]);
          }
        }}
        recipients={alertEmailRecipients}
        removeItem={(item: number) => {
          setAlertEmailRecipients((recp) => recp.slice(0, item).concat(recp.slice(item + 1)));
        }}
        setText={setEmailRecipientText}
        text={emailRecipientText}
      />
      <div>
        <Button onClick={onTest} style={{ marginTop: 36 }} variant={'primary'}>
          Test Alert
        </Button>
        <Typography style={{ fontSize: 12 }} variant={'p'}>
          Test out alert configuration by sending a test message to all recipients.
        </Typography>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onClose} style={{ marginRight: 16 }} variant={'primary'}>
          Cancel
        </Button>
        <Button data-test={'save-button'} onClick={onSave} variant={'primary'}>
          Save
        </Button>
      </div>
      <Snackbar autoHideDuration={4000} onClose={() => setFormError('')} open={Boolean(formError)}>
        <AlertComponent severity={'error'}>{formError}</AlertComponent>
      </Snackbar>
    </Modal>
  );

  function fieldsForType(alertType: string) {
    if (alertType === 'budget' || alertType === 'assetBudget') {
      return (
        <FormGroup className={classes.formGroup}>
          <Input
            data-test={'cost-threshold'}
            helperText={'Total costs rising beyond this threshold will trigger the alert'}
            label={'Cost Threshold'}
            onChange={(e) => setThreshold(e.target.value)}
            value={threshold}
          />
        </FormGroup>
      );
    }
    if (alertType === 'efficiency') {
      return (
        <>
          <FormGroup className={classes.formGroup}>
            <Input
              data-test={'efficiency-threshold'}
              helperText={
                'Total efficiency of queried items falling below this threshold triggers the alert. Ranges from 0.0 to 100.0'
              }
              label={'Efficiency Threshold'}
              onChange={(e) => setEfficiencyThreshold(e.target.value)}
              value={efficiencyThreshold}
            />
          </FormGroup>
          <FormGroup className={classes.formGroup}>
            <Input
              data-test={'cost-threshold'}
              helperText={
                'The minimum spend threshold for alerting. Items whose total costs are below this number will not trigger alerts, even if they fall below the efficiency threshold.'
              }
              label={'Cost Threshold'}
              onChange={(e) => setSpendThreshold(e.target.value)}
              value={spendThreshold}
            />
          </FormGroup>
        </>
      );
    }
    if (alertType === 'spendChange') {
      return (
        <>
          <SelectWindow
            helperText={
              'Collect data N days prior to the queried items, in order to establish a cost baseline'
            }
            setWindow={setBaselineWindow}
            window={baselineWindow}
            windowOptions={windowOptions}
          />
          <FormGroup className={classes.formGroup}>
            <Input
              helperText={
                'Percentage of change from the baseline (positive or negative) which will trigger the alert. Ranges from -100% (costs dropped to 0) upward.'
              }
              label={'Relative change threshold'}
              onChange={(e) => setRelativeChangeThreshold(e.target.value)}
              value={relativeChangeThreshold}
            />
            %
          </FormGroup>
        </>
      );
    }
    return <></>;
  }

  function getAlertTypeText() {
    if (type === 'budget') {
      return `
        Allocation Budget alerts are triggered when the total cost of allocations goes over a set budget limit.
      `;
    }
    if (type === 'efficiency') {
      return `
        Allocation Efficiency alerts are triggered when the average efficiency of allocations falls below a set threshold.
      `;
    }
    if (type === 'recurringUpdate') {
      return `
        Allocation Recurring alerts have no trigger. They simply issue a report on the state of the allocations every <date range> days.
      `;
    }
    if (type === 'spendChange') {
      return `
        Allocation Spend change alerts are triggered when a significant jump or drop in spend relative to the average occurs. 
      `;
    }
    if (type === 'assetBudget') {
      return `
        Asset Budget alerts are triggered when the total cost of assets goes over a set budget limit.
      `;
    }
    if (type === 'cloudReport') {
      return `
        Asset Recurring alerts have no trigger. They simply issue a report on the state of the assets every <date range> days.
      `;
    }
    return '';
  }

  function getFilterHelperText() {
    const aggOpt = aggregationOptions.find((opt) => opt.value === aggregation);
    if (!aggOpt) {
      return 'Filter to a specific item within the chosen aggregation';
    }
    return `Filter to a specific ${aggOpt.name}`;
  }

  function onTest() {
    setFormError('');
    try {
      validate();
    } catch (err) {
      if (err instanceof Error) {
        setFormError(err.message);
      } else {
        Logger.error(err);
      }
      return;
    }
    let recipients = [...alertEmailRecipients];
    if (emailRecipientText && !recipients.includes(emailRecipientText)) {
      recipients = [...recipients, emailRecipientText];
      setAlertEmailRecipients(recipients);
      setEmailRecipientText('');
    }
    const aType = type as AlertTypes;
    const agg = aggregation === 'label' ? `label:${aggLabel}` : aggregation;
    const slackWebhookUrl = alertSlackHookDirty
      ? alertSlackHook
      : initialAlert?.slackWebhookUrl || '';
    const alert = {
      type: aType,
      aggregation: agg,
      window: alertWindow,
      filter: alertFilter,
      id: alertId,
      threshold: parseFloat(threshold),
      efficiencyThreshold: parseFloat(efficiencyThreshold) / 100,
      spendThreshold: parseFloat(spendThreshold),
      baselineWindow,
      relativeThreshold: parseFloat(relativeChangeThreshold) / 100,
      ownerContact: recipients,
      slackWebhookUrl,
      emailSubject,
    };
    if (aType === AlertTypes.Budget) {
      test(new BudgetAlert(alert));
    } else if (aType === AlertTypes.Efficiency) {
      test(new EfficiencyAlert(alert));
    } else if (aType === AlertTypes.Recurring) {
      test(new RecurringUpdate(alert));
    } else if (aType === AlertTypes.SpendChange) {
      test(new SpendChangeAlert(alert));
    } else if (aType === AlertTypes.AssetBudget) {
      test(new AssetBudget(alert));
    } else if (aType === AlertTypes.AssetRecurring) {
      test(new AssetRecurring(alert));
    }
  }

  function onSave() {
    setFormError('');
    try {
      validate();
    } catch (err) {
      if (err instanceof Error) {
        setFormError(err.message);
      }
      return;
    }
    let recipients = [...alertEmailRecipients];
    if (emailRecipientText && !recipients.includes(emailRecipientText)) {
      recipients = [...recipients, emailRecipientText];
      setEmailRecipientText('');
    }
    const aType = type as AlertTypes;
    const agg = aggregation === 'label' ? `label:${aggLabel}` : aggregation;
    const slackWebhookUrl = alertSlackHookDirty
      ? alertSlackHook
      : initialAlert?.slackWebhookUrl || '';
    const alert = {
      type: aType,
      aggregation: agg,
      window: alertWindow,
      filter: alertFilter,
      id: alertId,
      threshold: parseFloat(threshold),
      efficiencyThreshold: parseFloat(efficiencyThreshold) / 100,
      spendThreshold: parseFloat(spendThreshold),
      baselineWindow,
      relativeThreshold: parseFloat(relativeChangeThreshold) / 100,
      ownerContact: recipients,
      slackWebhookUrl,
      emailSubject,
    };
    if (aType === AlertTypes.Budget) {
      save(new BudgetAlert(alert));
    } else if (aType === AlertTypes.Efficiency) {
      save(new EfficiencyAlert(alert));
    } else if (aType === AlertTypes.Recurring) {
      save(new RecurringUpdate(alert));
    } else if (aType === AlertTypes.SpendChange) {
      save(new SpendChangeAlert(alert));
    } else if (aType === AlertTypes.AssetBudget) {
      save(new AssetBudget(alert));
    } else if (aType === AlertTypes.AssetRecurring) {
      save(new AssetRecurring(alert));
    }
    onClose();
  }

  function onClose() {
    setAlertId('');
    setType('budget');
    setAlertWindow('7d');
    setAggregation('namespace');
    setAggLabel('');
    setAlertFilter('');
    setThreshold('');
    setEfficiencyThreshold('');
    setSpendThreshold('');
    setBaselineWindow('7d');
    setRelativeChangeThreshold('');
    setAlertEmailRecipients([]);
    setAlertSlackHook('');
    setEmailSubject('');
    setAlertSlackHookDirty(false);
    close();
  }

  function validate(): void {
    // validate window
    const validWindows = windowOptions.map((w) => w.value);
    if (!validWindows.includes(alertWindow)) {
      throw new Error(
        `Expected window to be one of: ${validWindows.join(', ')}. Instead got ${alertWindow}`,
      );
    }

    // validate aggregation
    const validAggs = aggregationOptions.map((agg) => agg.value);
    if (!validAggs.includes(aggregation)) {
      throw new Error(
        `Expected aggregation to be one of: ${validAggs.join(', ')}. Instead got ${aggregation}`,
      );
    }

    // type-specific validation
    if (type === 'budget' || type === 'assetBudget') {
      if (Number.isNaN(parseFloat(threshold))) {
        throw new Error('No cost threshold set');
      }
    } else if (type === 'efficiency') {
      if (Number.isNaN(parseFloat(spendThreshold))) {
        throw new Error('No spend threshold set');
      }
      const et = parseFloat(efficiencyThreshold);
      if (Number.isNaN(et)) {
        throw new Error('No efficiency threshold set');
      }
      if (et < 0.0 || et > 100.0) {
        throw new Error('Efficiency threshold is outside the permitted range (0 to 100)');
      }
    } else if (type === 'recurringUpdate' || type === 'cloudReport') {
    } else if (type === 'spendChange') {
      if (!validWindows.includes(baselineWindow)) {
        throw new Error(
          `Expected baseline window to be one of: ${validWindows.join(
            ', ',
          )}. Instead got ${baselineWindow}`,
        );
      }
      const rt = parseFloat(relativeChangeThreshold);
      if (Number.isNaN(rt)) {
        throw new Error('No relative change threshold set');
      }
      if (rt < -100.0) {
        throw new Error('Relative change threshold cannot be less than -100%');
      }
    } else {
      throw new Error(
        `Expected alert type to be one of: budget, assetBudget, efficiency, recurringUpdate, cloudReport, spendChange. Instead got ${type}`,
      );
    }
  }
};

export default CreateAlertModal;
