import { FC, ReactElement, ReactNode } from 'react';

import { useNavigate } from 'react-router';

import { Breadcrumbs, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Typography } from '@kubecost-frontend/holster';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  context: {
    flex: '0 1 auto',
  },
  actions: {
    flex: '0 0 auto',
  },
  ellipse: {
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
    '&:last-child': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  nowrap: {
    flexWrap: 'nowrap',
  },
});

type BreadcrumbTypes = {
  href: string;
  icon?: ReactNode;
  name: string;
};

interface HolsterHeaderProps {
  breadcrumbs?: BreadcrumbTypes[];
  children?: ReactElement[] | ReactNode | ReactElement;
  title?: string;
}

const HolsterHeader: FC<HolsterHeaderProps> = ({ breadcrumbs, children, title }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <div
        className={styles.context}
        style={{
          textOverflow: 'ellipsis',
          margin: 8,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          wordBreak: 'keep-all',
        }}
      >
        {title && (
          <Typography style={{ color: '#023927', fontWeight: 'bold' }} variant={'h4'}>
            {title}
          </Typography>
        )}
        {breadcrumbs && breadcrumbs.length > 0 && (
          <Breadcrumbs
            aria-label={'breadcrumb'}
            classes={{ ol: styles.nowrap, li: styles.ellipse }}
          >
            {breadcrumbs.slice(0, breadcrumbs.length - 1).map((b) => (
              <Link color={'inherit'} href={b.href} key={b.name}>
                {b.name}
              </Link>
            ))}
            <Typography className={styles.ellipse} variant={'h6'}>
              <span
                className={'flex cursor-pointer'}
                onClick={() => navigate(breadcrumbs[breadcrumbs.length - 1].href)}
              >
                {breadcrumbs[breadcrumbs.length - 1].icon}
                {breadcrumbs[breadcrumbs.length - 1].name}
              </span>
            </Typography>
          </Breadcrumbs>
        )}
      </div>
      <div className={styles.actions}>{children}</div>
    </div>
  );
};

HolsterHeader.displayName = 'HolsterHeader';

export { BreadcrumbTypes, HolsterHeader };
