import { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import groupBy from 'lodash/groupBy';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/styles';

import { AlertData, Alerts } from '../../components/Alerts';
import { Header } from '../../components/Header';
import { FetchStates } from '../../constants';
import { model as Model } from '../../services/model';
import { RecommendationSet, Reservation, ReservationService } from '../../services/reservations';
import { getCurrentContainerAddressModel } from '../../services/util';
import { AssetSet, AssetSetRange } from '../../types/asset';

const useStyles = makeStyles({
  regionSelect: {
    margin: 16,
    minWidth: 120,
  },
  listBox: {
    maxWidth: 400,
  },
});

const gridColumns: TCol[] = [
  {
    field: 'instanceType',
    headerName: 'Instance Type',
  },
  {
    field: 'nodes',
    headerName: 'Nodes',
  },
  {
    field: 'reservations',
    headerName: 'Active Reservations',
  },
  {
    field: 'recommendations',
    headerName: 'Recommendations',
  },
  {
    field: 'savingsPct',
    headerName: 'Savings',
  },
];

const winOpts = [
  {
    display: '7 days',
    value: '7d',
  },
  {
    display: '30 days',
    value: '30d',
  },
  {
    display: '90 days',
    value: '90d',
  },
];

const Reservations = () => {
  const classes = useStyles();

  // region data
  const [regions, setRegions] = useState<RegionOption[]>([]);
  const [region, setRegion] = useState<string>('');
  const [regionFetchState, setRegionFetchState] = useState(FetchStates.INIT);

  // windows
  const [win, setWin] = useState<number>(1);

  // table data
  const [fetchState, setFetchState] = useState(FetchStates.INIT);

  const [activeReservations, setActiveReservations] = useState<Record<string, Reservation[]>>({});
  const [recommendations, setRecommendations] = useState<RecommendationSet>({});
  const [nodes, setNodes] = useState<{ [index: string]: Node[] }>({});
  const [offerings, setOfferings] = useState<{ [index: string]: number }>({});
  const [tableData, setTableData] = useState<TRow[]>([]);

  // alerts
  const [alerts, setAlerts] = useState<AlertData[]>([]);

  // get available regions and set the default to that of the active cluster
  useEffect(() => {
    if (regionFetchState !== FetchStates.INIT) {
      return;
    }
    fetchRegionData();
  }, [regionFetchState]);

  // get active reservations, recommendations, and nodes for a given region
  useEffect(() => {
    if (!(region && regions.find((r) => r.region === region))) {
      return;
    }
    fetchTableData(region);
  }, [region, regions, win]);

  // compute rows for the data table using availble data on active nodes,
  // active reservations, and reservation recommendations.
  //
  // Only node types included in the window are represented. Unused reservations are not shown.
  //
  // TODO: it's probably wiser to compute an average hourly cost for a node type
  // rather than assume that the first node's cost is representative of all the nodes.
  useEffect(() => {
    const td: TRow[] = Object.entries(nodes).map(([nodeType, ns]) => {
      const hourlyCost = ((ns[0].totalCost * ns.length) / ns[0].minutes) * 60;
      const reservations = (activeReservations[nodeType] || []).length;
      const recs = recommendations[nodeType] ? (
        `Reserve ${recommendations[nodeType].reservations}`
      ) : (
        <CheckCircleOutlinedIcon style={{ color: 'green' }} />
      );
      const savingsPct = recommendations[nodeType]
        ? `${(100 * recommendations[nodeType].savingsPct).toFixed(2)}%`
        : 'N/A';
      return {
        instanceType: nodeType,
        nodes: ns.length,
        reservations,
        recommendations: recs,
        hourlyCost,
        savingsPct,
      };
    });
    setTableData(td);
  }, [nodes, recommendations, activeReservations, offerings]);

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Cluster Savings', href: 'savings.html' },
          { name: 'Reservations', href: 'reservations.html' },
        ]}
      >
        <IconButton aria-label={'refresh'}>
          <RefreshIcon />
        </IconButton>
        <Link href={'settings.html'}>
          <IconButton aria-label={'refresh'}>
            <SettingsIcon />
          </IconButton>
        </Link>
      </Header>

      <Paper style={{ marginBottom: 16 }}>
        <Box alignItems={'center'} display={'flex'} style={{ margin: 16 }}>
          <WarningIcon style={{ color: 'goldenrod', marginRight: 8 }} />
          <Typography>
            This feature is in alpha. Use your own best judgement when making reservation decisions.
          </Typography>
        </Box>
      </Paper>

      <Paper style={{ marginBottom: 16 }}>
        <Box alignItems={'center'} display={'flex'} style={{ margin: 16 }}>
          <WarningIcon style={{ color: 'goldenrod', marginRight: 8 }} />
          <Typography>
            For recommendations to be accurate, it is important that clusters have been rightsized.
            Otherwise, it is possible to over-reserve. See cluster rightsizing options{' '}
            <Link href={'cluster-sizing.html'}>here</Link>.
          </Typography>
        </Box>
      </Paper>

      {getAlertsFragment()}

      <Paper>
        <FormControl className={classes.regionSelect}>
          <InputLabel id={'region-select-label'}>Region</InputLabel>
          <Select labelId={'region-select-label'} onChange={handleRegionChange} value={region}>
            {regions.map((r) => (
              <MenuItem key={r.region} value={r.region}>
                {r.region} ({r.nodes})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.regionSelect}>
          <InputLabel id={'window-select-label'}>Window</InputLabel>
          <Select labelId={'window-select-label'} onChange={handleWindowChange} value={win}>
            {winOpts.map((wo, i) => (
              <MenuItem key={wo.value} value={i}>
                {wo.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {tableContents()}
      </Paper>
    </>
  );

  function handleRegionChange(e: ChangeEvent<{ value: unknown }>) {
    const newRegion = regions.find((r) => r.region === e.target.value);
    if (newRegion) {
      setRegion(newRegion.region);
    } else {
      setRegion('');
    }
  }

  function handleWindowChange(e: ChangeEvent<{ value: unknown }>) {
    setWin(e.target.value as number);
  }

  function getAlertsFragment(): ReactElement {
    if (!alerts.length) {
      return <></>;
    }
    return <Alerts alerts={alerts} />;
  }

  function tableContents() {
    switch (fetchState) {
      case FetchStates.ERROR:
        return <Typography>Failed to load reservation data. Check console for details.</Typography>;
      case FetchStates.DONE:
        return !tableData.length ? (
          <Typography>No results to show.</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {gridColumns.map((col) => (
                    <TableCell key={col.headerName}>{col.headerName}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => (
                  <TableRow key={row.instanceType}>
                    {gridColumns.map((col) => {
                      if (col.field === 'savingsPct' && row[col.field] !== 'N/A') {
                        return (
                          <TableCell
                            key={`${col.field}-${row.instanceType}`}
                            style={{
                              fontSize: 24,
                              fontWeight: 'bold',
                              color: 'green',
                            }}
                          >
                            {row[col.field]}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={`${col.field}-${row.instanceType}`}>
                          {row[col.field]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      default:
        return (
          <Box display={'flex'} justifyContent={'center'} style={{ padding: 24 }}>
            <CircularProgress />
          </Box>
        );
    }
  }

  async function fetchRegionData() {
    setRegionFetchState(FetchStates.LOADING);
    // query available regions for provider and nodes from active cluster
    const accumulate = true;
    const filters = [{ property: 'type', value: 'node' }];
    const regionPromise = fetch(`${getCurrentContainerAddressModel()}/regions`)
      .then((resp) => resp.json())
      .then((js: { data: string[] }) => js.data);
    const assetPromise = Model.getAssets('2d', { accumulate, filters });
    const [regionResponse, assetSetRange]: [string[], AssetSetRange] = await Promise.all([
      regionPromise,
      assetPromise.then((js: any) => js.data),
    ]);

    // determine region of the active cluster
    const assetSet = assetSetRange[0];
    const r = Object.values(assetSet).reduce(
      (val, asset) => val || asset.labels.label_topology_kubernetes_io_region,
      '',
    );
    const regionOpts = regionResponse.map((reg) => ({
      region: reg,
      nodes: Object.values(assetSet).filter(
        (asset) => asset.labels.label_topology_kubernetes_io_region === reg,
      ).length,
    }));
    const currentRegion = regionOpts.find((reg) => reg.region === r);

    // set region options and default region
    setRegions(regionOpts);
    if (currentRegion) {
      setRegion(currentRegion.region);
    }
    setRegionFetchState(FetchStates.DONE);
  }

  async function fetchTableData(reg: string) {
    setFetchState(FetchStates.LOADING);
    try {
      await Promise.all([
        fetchActiveReservations(reg),
        fetchNodes(reg),
        fetchRecommendations(reg, winOpts[win]),
      ]);
      setFetchState(FetchStates.DONE);
    } catch (err) {
      setFetchState(FetchStates.ERROR);
    }
  }

  async function fetchActiveReservations(reg: string) {
    const r = await ReservationService.fetchActiveReservations(reg);
    const res = groupBy(r, (o) => o.nodeType);
    setActiveReservations(res);
  }

  async function fetchRecommendations(reg: string, w: { display: string; value: string }) {
    try {
      const recs = await ReservationService.fetchReservationRecommendations(reg, w.value);
      setRecommendations(recs);
      const keys = Object.keys(recs);
      const saveProms = keys.map((nodeType) => ReservationService.fetchOfferings(nodeType, reg));
      const savingsResponses = await Promise.all(saveProms);
      const off: { [index: string]: number } = {};
      keys.forEach((k, i) => {
        off[k] = savingsResponses[i];
      });
      setOfferings(off);
    } catch (err: any) {
      if (err.message.includes('boundary error')) {
        setAlerts([
          {
            level: 'warning',
            primary: 'Not enough historical data to make reservation suggestions.',
            secondary: '',
          },
        ]);
      } else {
        throw err;
      }
    }
  }

  async function fetchNodes(reg: string) {
    const opts = {
      accumulate: true,
      filters: [
        { property: 'type', value: 'node' },
        { property: 'region', value: reg },
      ],
    };
    const response = await Model.getAssets('1d', opts);
    const assets: AssetSet = response.data[0];
    const n = groupBy(Object.values(assets), (a) => a.nodeType);
    setNodes(n);
  }
};

type Node = {
  minutes: number;
  totalCost: number;
};

type RegionOption = {
  nodes: number;
  region: string;
};

type TRow = {
  hourlyCost: number;
  instanceType: string;
  nodes: number;
  recommendations: string | ReactElement;
  reservations: number;
  savingsPct: string;
};

type TCol = {
  field: 'instanceType' | 'nodes' | 'reservations' | 'recommendations' | 'savingsPct';
  headerName: string;
};

export { Reservations };
