import {
  Button,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';
import { TableCell } from '@material-ui/core';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { APIClient } from '../../../services/APIClient';
import { ProfileQueryKey, useHosted } from '../../../hooks/useHosted';
import { AuthService, useAuth } from '../../../PKCE';

const PendingInvitations = () => {
  const { profile, config } = useHosted();
  const { authService } = useAuth();
  const queryClient = useQueryClient();
  const { mutate: acceptInvite } = useMutation({
    mutationFn: (teamId) => {
      return APIClient.post(`${config?.userServiceAPIUrl}/api/teams/${teamId}/invite/accept`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ProfileQueryKey });
      authService.refreshToken();
    },
  });

  const { mutate: rejectInvite } = useMutation({
    mutationFn: (teamId) => {
      return APIClient.post(`${config?.userServiceAPIUrl}/api/teams/${teamId}/invite/reject`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ProfileQueryKey });
    },
  });

  if (profile.pending_invites.length === 0) {
    return <></>;
  }

  return (
    <div className={'mb-8'} id={'pending-invites'}>
      <Typography style={{ marginBottom: '0.5em' }} variant='h5'>
        <strong>Pending Invitations</strong>
      </Typography>

      <Table className='w-[100%]'>
        <TableHead>
          <TableRow>
            <TableHeadCell>Team</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profile.pending_invites.map((invite) => (
            <TableRow>
              <TableCell className='w-full'>{invite.team.name}</TableCell>
              <TableCell>
                <div className='flex gap-4'>
                  <Button onClick={() => rejectInvite(invite.team.id)} variant='default'>
                    Decline
                  </Button>
                  <Button onClick={() => acceptInvite(invite.team.id)} variant='primary'>
                    Accept
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default PendingInvitations;
