import { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { isValid } from 'date-fns';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type PartialDateRange = { endDate: MaterialUiPickersDate; startDate: MaterialUiPickersDate };
type DateRange = { endDate: Date; startDate: Date };

const isDateRange = (range: PartialDateRange): range is DateRange =>
  range.startDate instanceof Date && range.endDate instanceof Date;

interface DateRangePickerProps {
  endValue?: Date;
  onAccept: (dateRange: DateRange) => void;
  startValue?: Date;
}

const DateRangePicker = ({ endValue, onAccept, startValue }: DateRangePickerProps) => {
  const [startDate, setStartDate] = useState<MaterialUiPickersDate>(startValue ?? null);
  const [endDate, setEndDate] = useState<MaterialUiPickersDate>(endValue ?? null);

  const handleOnAccept = (dateRange: PartialDateRange) => {
    // Prevents calling `onAccept` until the user has input/selected a start and end date
    if (isDateRange(dateRange)) {
      onAccept(dateRange);
    }
  };

  const handleOnChangeStartDate = (newStartDate: MaterialUiPickersDate) => {
    // Handles manual date entry from keyboard
    setStartDate(newStartDate);

    // prevents calling `handleOnAccept` until user has input/selected a complete date
    if (isValid(newStartDate)) {
      handleOnAccept({ startDate: newStartDate, endDate });
    }
  };

  const handleOnChangeEndDate = (newEndDate: MaterialUiPickersDate) => {
    // Handles manual date entry from keyboard
    setEndDate(newEndDate);

    // Prevents calling `handleOnAccept` until user has input/selected a complete date
    if (isValid(newEndDate)) {
      handleOnAccept({ startDate, endDate: newEndDate });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        format={'MM/dd/yyyy'}
        id={'date-range-control-custom-start'}
        label={'Start Date'}
        margin={'normal'}
        maxDate={new Date()}
        maxDateMessage={'Date should not be after today.'}
        onChange={handleOnChangeStartDate}
        style={{ marginLeft: '0.5rem' }}
        value={startDate}
        variant={'inline'}
        autoOk
        disableToolbar
      />
      <KeyboardDatePicker
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        format={'MM/dd/yyyy'}
        id={'date-range-control-custom-end'}
        label={'End Date'}
        margin={'normal'}
        maxDate={new Date()}
        maxDateMessage={'Date should not be after today.'}
        minDate={startDate}
        minDateMessage={'Date should not be before start date.'}
        onChange={handleOnChangeEndDate}
        style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
        value={endDate}
        variant={'inline'}
        autoOk
        disableToolbar
      />
    </MuiPickersUtilsProvider>
  );
};

export { DateRange, DateRangePicker };
