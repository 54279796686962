import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AlertsIcon from '@material-ui/icons/NotificationsOutlined';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AlertsTable } from '../../../../components/AlertsTable';
import { Alert, AlertTypes, AlertService } from '../../../../services/alerts';

const AlertControl = ({ aggregation, window }: ControlProps) => {
  const alertTypeOpts = [
    {
      label: 'Recurring',
      value: AlertTypes.Recurring,
      explanation: `
        Recurring alerts send a weekly recurring update with a summary report of cost and efficiency metrics.
      `,
      lockedFields: {
        window,
        aggregate: aggregation,
      },
    },
    {
      label: 'Efficiency',
      value: AlertTypes.Efficiency,
      explanation: `
        Efficiency alerts are triggered when Kubernetes tenants, e.g. namespaces,
        are running below defined cost efficiency thresholds.
      `,
      lockedFields: {
        window,
        aggregate: aggregation,
      },
    },
    {
      label: 'Budget',
      value: AlertTypes.Budget,
      explanation: `
        Budget alerts define spend budgets and trigger on budget overruns.
      `,
      lockedFields: {
        window,
        aggregate: aggregation,
      },
    },
    {
      label: 'Spend Change',
      value: AlertTypes.SpendChange,
      explanation: `
        Spend change alerts detect unexpected spend increases/decreases relative to historical moving averages.
      `,
      lockedFields: {
        window,
        aggregate: aggregation,
      },
    },
  ];

  const navigate = useNavigate();
  const routerLocation = useLocation();
  const searchParams = new URLSearchParams(routerLocation.search);

  const [open, setOpen] = useState(false);

  // fetch alerts of the chosen type whenever the type is set.
  const [allAlerts, setAllAlerts] = useState<Alert[]>([]);
  const [alerts, setAlerts] = useState<Alert[]>([]);
  useEffect(() => {
    fetchAlerts();
  }, []);
  useEffect(() => {
    setAlerts(allAlerts.filter((a) => a.aggregation === aggregation && a.window === window));
  }, [aggregation, window, allAlerts]);
  useEffect(() => {
    if (searchParams.get('new-alert') === 'true') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [searchParams.get('new-alert')]);

  return (
    <>
      <Tooltip title={'Alerts'}>
        <IconButton
          onClick={() => {
            searchParams.set('new-alert', 'true');
            navigate({ search: `?${searchParams.toString()}` });
          }}
        >
          <Badge badgeContent={alerts.length || undefined}>
            <AlertsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Dialog
        onClose={() => {
          setOpen(false);
          searchParams.delete('new-alert');
          navigate({ search: `?${searchParams.toString()}` });
        }}
        open={open}
        fullWidth
      >
        <DialogTitle>Alerts</DialogTitle>
        <DialogContent>
          <AlertsTable
            alertTypeOpts={alertTypeOpts}
            alerts={alerts}
            createAlert={createAlert}
            removeAlert={removeAlert}
          />
        </DialogContent>
      </Dialog>
    </>
  );

  async function fetchAlerts() {
    const alertResponse = await AlertService.getAlerts();
    setAllAlerts(alertResponse);
  }

  async function createAlert(a: Alert) {
    const response = await AlertService.upsertAlert(a);
    setAllAlerts([...allAlerts, response]);
  }

  async function removeAlert(a: Alert) {
    AlertService.deleteAlert(a);
    setAllAlerts(allAlerts.filter((aa) => aa.id !== a.id));
  }
};

type ControlProps = {
  aggregation: string;
  window: string;
};

export default AlertControl;
