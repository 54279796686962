import { QueryObserverResult, useQuery } from 'react-query';

import { model } from '../services/model';
import { useHosted } from './useHosted';

import { ProductTier } from './useProductTier';

enum EnablementKeys {
  unifiedMultiCluster = 'unified-multi-cluster',
  cloudCosts = 'cloudCost',
}

enum EnablementNames {
  'unified-multi-cluster' = 'Unified multi-cluster',
  'cloudCost' = 'Cloud Costs',
}

interface APIEnablementDatum {
  isEnabled: boolean;
  isViolation: boolean;
  key: EnablementKeys;
  requiredTier: ProductTier;
}

type APIEnablementsResponse = Record<EnablementKeys, APIEnablementDatum>;

const useEnablements = (): QueryObserverResult<APIEnablementsResponse> => {
  const { isHostedEnvironment } = useHosted();

  return useQuery({
    queryKey: ['enablements', isHostedEnvironment] as ['enablements', boolean],
    queryFn: ({ queryKey }) => {
      const [, hosted] = queryKey;

      if (hosted) {
        return {} as APIEnablementsResponse;
      }

      return model.getEnablements();
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });
  };

export {
  APIEnablementDatum,
  APIEnablementsResponse,
  EnablementKeys,
  EnablementNames,
  useEnablements as default,
};
