import { Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloudIcon from '@material-ui/icons/Cloud';
import Help from '@material-ui/icons/Help';
import OpenInNew from '@material-ui/icons/OpenInNew';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header } from '../../components/Header';
import { K8sLogo } from '../../components/k8sLogo';
import { Loading } from '../../components/Loading';
import { useClusters } from '../../contexts/ClusterConfig';
import { toCurrency } from '../../services/format';
import Logger from '../../services/logger';
import { model as ModelService } from '../../services/model';
import {
  getPvSavings,
  getReservedRecSavings,
  getSavingsSummary,
  getUnassignedAddressSavings,
  getUnassignedDiskSavings,
  getUnutilizedLocalDiskSavings,
  fetchRequestSizingRecommendationsMaxHeadroom,
} from '../../services/savings';
import {
  fetchSpotChecklistClusterSizing,
  defaultSpotAllowSharedCore,
  defaultSpotMinNodeCount,
  defaultSpotMinOnDemandNodeCount,
  defaultSpotWindow,
  defaultSpotTargetUtilization,
} from '../../services/savings/spotclustersizing';
import profiles from '../RequestSizing/profiles';

const useStyles = makeStyles({
  card: {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%)',
    margin: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  titleCard: {
    backgroundColor: 'rgba(60, 186, 84, 0.7)',
    color: '#0f3e0f',
    marginBottom: '50px',
    cursor: 'default',
  },
  tooltip: {
    padding: '0px 0px 3px 6px',
    height: '20px',
    '&:hover': {
      background: 'none',
    },
  },
  spotPaper: {
    backgroundColor: 'rgba(180, 222, 250, 1.9)',
    marginBottom: 30,
    cursor: 'pointer',
  },
  cloudPaper: {
    backgroundColor: 'rgba(230, 138, 0, 1)',
    marginTop: -15,
    marginBottom: 30,
    cursor: 'pointer',
  },
  spotPaperText: {
    display: 'flex',
    margin: '20px 0 0 10px',
    color: '#0f3e0f',
    textDecoration: 'underline',
  },
  cloudPaperText: {
    display: 'flex',
    margin: '20px 0 0 10px',
    color: '#f2f2f2',
    textDecoration: 'underline',
  },
  spotLinkIcon: {
    display: 'flex',
    padding: '15px 8px 15px 5px',
    fill: '#0f3e0f',
  },
  cloudLinkIcon: {
    display: 'flex',
    padding: '15px 8px 15px 5px',
    fill: '#f2f2f2',
  },
  title: {
    color: '#f2f2f2',
    fontWeight: 400,
  },
  costText: {
    color: '#8c8c8c',
  },
  costNumber: {
    color: '#8c8c8c',
    fontSize: '22px',
    marginLeft: '7px',
    marginRight: '2px',
  },
  costNumberPositive: {
    color: '#3CBA54',
  },
});

const ColoredKubernetesIcon = () => K8sLogo(40, '#3CBA54');
const ColoredCloudIcon = () => <CloudIcon htmlColor={'#3CBA54'} style={{ fontSize: 40 }} />;

const Savings = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { clusterConfig } = useClusters();
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<boolean>();
  const [cloudIntegration, setCloudIntegration] = useState<boolean>(true);
  const [totalSavings, setTotalSavings] = useState<number>(0);
  const [currency, setCurrency] = useState('');
  const [savingsData, setSavingsData] = useState({
    clusterSizing: {
      title: 'Right-size your cluster nodes',
      description:
        "Adjust the number and size of your cluster's nodes to stop over-spending on unused capacity.",
      url: '../cluster-sizing',
      savings: 0,
      loading: true,
      icon: ColoredCloudIcon,
      tooltipText: 'This is a cloud insight outside of your cluster(s)',
    },
    requestSizing: {
      title: 'Right-size your container requests',
      description:
        'Over-provisioned containers provide an opportunity to lower requests and save money. Under-provisioned containers may cause CPU throttling or memory-based evictions.',
      url: '../request-sizing',
      savings: 0,
      loading: true,
      icon: ColoredKubernetesIcon,
      tooltipText: 'This insight applies to Kubernetes.',
    },
    abandonedWorkloads: {
      title: 'Remedy abandoned workloads',
      description:
        "Scale down, delete or resize pods that don't send or receive a meaningful rate of network traffic.",
      url: '../abandoned-workloads',
      savings: 0,
      loading: true,
      icon: ColoredKubernetesIcon,
      tooltipText: 'This insight applies to Kubernetes.',
    },
    reservedInstances: {
      title: 'Reserve instances',
      description:
        'Consider purchasing reserved instances based on historical resource usage patterns.',
      url: '../reserved-instances',
      savings: 0,
      loading: true,
      icon: ColoredCloudIcon,
      tooltipText: 'This is a cloud insight outside of your cluster(s)',
    },
    localDisks: {
      title: 'Resize local disks',
      description:
        'Resize local disks with low utilization, and see whether you may launch new nodes with smaller disks on the next node turndown.',
      url: '../low-disk',
      savings: 0,
      loading: true,
      icon: ColoredKubernetesIcon,
      tooltipText: 'This insight applies to Kubernetes.',
    },
    underutilizedNodes: {
      title: 'Manage underutilized nodes',
      description: 'Turn down or resize nodes with low memory and CPU utilization.',
      url: '../nodes',
      savings: 0,
      loading: true,
      icon: ColoredKubernetesIcon,
      tooltipText: 'This insight applies to Kubernetes.',
    },
    unclaimedVolumes: {
      title: 'Manage unclaimed volumes',
      description:
        'Delete volumes that are unused by any pods or move them to a cheaper storage tier.',
      url: '../unclaimed-volumes',
      savings: 0,
      loading: true,
      icon: ColoredKubernetesIcon,
      tooltipText: 'This insight applies to Kubernetes.',
    },
    unassignedResources: {
      title: 'Delete unassigned resources',
      description:
        'Disks and IP addresses that are not being used by any clusters may continue to incur charges.',
      url: '../orphaned-resources',
      savings: 0,
      loading: true,
      icon: ColoredCloudIcon,
      tooltipText: 'This is a cloud insight outside of your cluster(s)',
    },
    spotSizing: {
      title: 'Spot Instances',
      description:
        'Identify workloads ready for spot (preemptible) nodes and resize your cluster to realize the savings of migrating workloads to spot.',
      url: '../spot',
      savings: 0,
      loading: true,
      icon: ColoredCloudIcon,
      tooltipText: 'This is a cloud insight outside of your cluster(s)',
    },
  });

  useEffect(() => {
    loadBasicData();
  }, []);

  useEffect(() => {
    if (!currency) {
      return;
    }
    loadSavingsData();
  }, [currency]);

  const getSaveUpToStyles = (savings: number): any =>
    clsx(classes.costNumber, savings > 0 && classes.costNumberPositive);

  const getSaveUpToText = (savings: number) => {
    if (savings > 0) {
      return (
        <span className={classes.costText}>
          save up to
          <span className={getSaveUpToStyles(savings)}>{toCurrency(savings, currency)}</span>
          /mo
        </span>
      );
    }
    return <span className={classes.costText}>explore savings</span>;
  };

  const getUtilizationsFromProfile = () => {
    const profile = clusterConfig.clusterProfile;

    if (!profile) {
      return;
    }

    const { targetUtilization } = profiles.getProfile(profile);

    const cpu = targetUtilization.cpu ? targetUtilization.cpu : 0.8;
    const ram = targetUtilization.ram ? targetUtilization.ram : 0.8;

    return { cpu, ram };
  };

  const getTooltipForSavingsCardIcon = (SavingsIcon: any, tootipText: string) => (
    <Tooltip
      placement={'top'}
      title={<span style={{ fontSize: '15px', fontWeight: 400 }}>{tootipText}</span>}
    >
      <span style={{ marginLeft: '1em' }}>
        <SavingsIcon />
      </span>
    </Tooltip>
  );

  if (loading === true) {
    return (
      <>
        <Header breadcrumbs={[{ name: 'Savings  ', href: 'savings.html' }]} />
        <Loading message={'Loading savings...'} />
      </>
    );
  }

  if (loadingError === true) {
    return (
      <>
        <Header breadcrumbs={[{ name: 'Savings  ', href: 'savings.html' }]} />
        <div style={{ textAlign: 'center', display: 'block' }}>
          Error: failed to connect to cluster.
        </div>
      </>
    );
  }

  return (
    <>
      <Header breadcrumbs={[{ name: 'Savings  ', href: 'savings.html' }]}>
        <IconButton aria-label={'refresh'} onClick={loadSavingsData}>
          <RefreshIcon />
        </IconButton>
      </Header>
      <Grid justifyContent={'center'} spacing={1} style={{ minHeight: '100vh' }} container>
        <Grid className={clsx(classes.card, classes.titleCard)} component={Card} xs={8} item>
          <CardContent>
            <Typography align={'center'} className={classes.title} variant={'h2'} paragraph>
              {toCurrency(totalSavings, currency)}
            </Typography>
            <Typography align={'center'} variant={'h5'}>
              estimated monthly savings available
              <Tooltip
                placement={'top-start'}
                title={
                  <span style={{ fontSize: '15px', fontWeight: 400 }}>
                    Estimated savings are probability adjusted and include both Kubernetes and
                    external cloud insights.
                  </span>
                }
              >
                <Help className={classes.tooltip} />
              </Tooltip>
            </Typography>
            <Typography
              style={{
                opacity:
                  Object.values(savingsData).filter((sd) => !sd.loading).length <
                  Object.values(savingsData).length
                    ? 1
                    : 0,
                textAlign: 'center',
              }}
            >
              {Object.values(savingsData).filter((sd) => !sd.loading).length}/
              {Object.values(savingsData).length} savings opportunities loaded
            </Typography>
          </CardContent>
        </Grid>
        {cloudIntegration === false && (
          <Grid
            className={clsx(classes.cloudPaper)}
            component={Paper}
            onClick={() => window.open('settings.html', '_self')}
            xs={10}
            item
          >
            <div style={{ display: 'flex' }}>
              <div className={classes.cloudPaperText}>
                Provide a Service Key to improve savings accuracy
              </div>
              <OpenInNew className={classes.cloudLinkIcon} />
            </div>
          </Grid>
        )}

        {Object.values(savingsData)
          .sort((a, b) => b.savings - a.savings)
          .map((category) => (
            <Grid
              className={classes.card}
              component={Card}
              key={category.title}
              onClick={() => navigate(category.url)}
              xs={5}
              item
            >
              <CardContent>
                <Typography variant={'h6'} paragraph>
                  {category.title}
                </Typography>
                <Typography variant={'body1'} paragraph>
                  {category.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Box
                  alignItems={'center'}
                  display={'flex'}
                  justifyContent={'space-between'}
                  width={'100%'}
                >
                  {getTooltipForSavingsCardIcon(category.icon, category.tooltipText)}
                  {category.loading ? (
                    <Typography>Loading...</Typography>
                  ) : (
                    getSaveUpToText(category.savings)
                  )}
                </Box>
                <IconButton>
                  <ArrowForwardIcon />
                </IconButton>
              </CardActions>
            </Grid>
          ))}
      </Grid>
    </>
  );

  async function loadBasicData() {
    setLoading(true);
    try {
      const configs = await ModelService.getConfigs();
      setCurrency(ModelService.getModelCurrency(configs));
    } catch (err) {
      setLoadingError(true);
      setLoading(false);
      Logger.error(err);
    }
    try {
      const etlStatus = await ModelService.etlStatus();
      const cloud = get(etlStatus, 'cloud');
      if (cloud) {
        Object.keys(cloud).forEach((providerKey) => {
          if (cloud[providerKey].cloudConnectionStatus === 'Connection Successful') {
            setCloudIntegration(true);
          } else {
            setCloudIntegration(false);
          }
        });
      }
    } catch (err) {
      Logger.error(err);
    }

    setLoading(false);
  }

  async function loadSavingsData() {
    // Use the SavingsSummary to retrieve abandonedWorkloads, requestSizing, and clusterSizing
    // Since we pick the best (max) savings between clusterSizing and turndownSavings to include
    // in the total savings calculation, we also calculate turndown savings in this group
    setSavingsData({
      clusterSizing: {
        title: 'Right-size your cluster nodes',
        description:
          "Adjust the number and size of your cluster's nodes to stop over-spending on unused capacity.",
        url: '../cluster-sizing',
        savings: 0,
        loading: true,
        icon: ColoredCloudIcon,
        tooltipText: 'This is a cloud insight outside of your cluster(s)',
      },
      requestSizing: {
        title: 'Right-size your container requests',
        description:
          'Over-provisioned containers provide an opportunity to lower requests and save money. Under-provisioned containers may cause CPU throttling or memory-based evictions.',
        url: '../request-sizing',
        savings: 0,
        loading: true,
        icon: ColoredKubernetesIcon,
        tooltipText: 'This insight applies to Kubernetes.',
      },
      abandonedWorkloads: {
        title: 'Remedy abandoned workloads',
        description:
          "Scale down, delete or resize pods that don't send or receive a meaningful rate of network traffic.",
        url: '../abandoned-workloads',
        savings: 0,
        loading: true,
        icon: ColoredKubernetesIcon,
        tooltipText: 'This insight applies to Kubernetes.',
      },
      reservedInstances: {
        title: 'Reserve instances',
        description:
          'Consider purchasing reserved instances based on historical resource usage patterns.',
        url: '../reserved-instances',
        savings: 0,
        loading: true,
        icon: ColoredCloudIcon,
        tooltipText: 'This is a cloud insight outside of your cluster(s)',
      },
      localDisks: {
        title: 'Resize local disks',
        description:
          'Resize local disks with low utilization, and see whether you may launch new nodes with smaller disks on the next node turndown.',
        url: '../low-disk',
        savings: 0,
        loading: true,
        icon: ColoredKubernetesIcon,
        tooltipText: 'This insight applies to Kubernetes.',
      },
      underutilizedNodes: {
        title: 'Manage underutilized nodes',
        description: 'Turn down or resize nodes with low memory and CPU utilization.',
        url: '../nodes',
        savings: 0,
        loading: true,
        icon: ColoredKubernetesIcon,
        tooltipText: 'This insight applies to Kubernetes.',
      },
      unclaimedVolumes: {
        title: 'Manage unclaimed volumes',
        description:
          'Delete volumes that are unused by any pods or move them to a cheaper storage tier.',
        url: '../unclaimed-volumes',
        savings: 0,
        loading: true,
        icon: ColoredKubernetesIcon,
        tooltipText: 'This insight applies to Kubernetes.',
      },
      unassignedResources: {
        title: 'Delete unassigned resources',
        description:
          'Disks and IP addresses that are not being used by any clusters may continue to incur charges.',
        url: '../orphaned-resources',
        savings: 0,
        loading: true,
        icon: ColoredCloudIcon,
        tooltipText: 'This is a cloud insight outside of your cluster(s)',
      },
      spotSizing: {
        title: 'Spot Instances',
        description:
          'Identify workloads ready for spot (preemptible) nodes and resize your cluster to realize the savings of migrating workloads to spot.',
        url: '../spot',
        savings: 0,
        loading: true,
        icon: ColoredCloudIcon,
        tooltipText: 'This is a cloud insight outside of your cluster(s)',
      },
    });
    setTotalSavings(0);
    getSavingsSummary().then((savingsSummary) => {
      setSavingsData((prevState) => {
        prevState.abandonedWorkloads.savings = savingsSummary.abandonedWorkloads;
        prevState.abandonedWorkloads.loading = false;

        prevState.clusterSizing.savings = savingsSummary.clusterSizing;
        prevState.clusterSizing.loading = false;

        prevState.underutilizedNodes.savings = savingsSummary.nodeTurndown;
        prevState.underutilizedNodes.loading = false;
        return { ...prevState };
      });

      const clusterSizing =
        savingsSummary.clusterSizing === undefined ? 0 : savingsSummary.clusterSizing;
      const nodeTurndown =
        savingsSummary.nodeTurndown === undefined ? 0 : savingsSummary.nodeTurndown;

      const nodeSavings = Math.max(clusterSizing, nodeTurndown);
      const savings =
        savingsSummary.abandonedWorkloads + savingsSummary.requestSizing + nodeSavings;
      setTotalSavings((prev) => prev + 0.65 * savings);
    });

    getPvSavings().then((savings) => {
      setSavingsData((prevState) => {
        prevState.unclaimedVolumes.savings = savings;
        prevState.unclaimedVolumes.loading = false;
        return { ...prevState };
      });
      setTotalSavings((prev) => prev + 0.65 * savings);
    });

    getReservedRecSavings().then((savings) => {
      setSavingsData((prevState) => {
        prevState.reservedInstances.savings = savings;
        prevState.reservedInstances.loading = false;
        return { ...prevState };
      });
      setTotalSavings((prev) => prev + 0.65 * savings);
    });

    Promise.all([getUnassignedAddressSavings(), getUnassignedDiskSavings()]).then(
      ([addrSavings, diskSavings]) => {
        setSavingsData((prevState) => {
          prevState.unassignedResources.savings = addrSavings + diskSavings;
          prevState.unassignedResources.loading = false;
          return { ...prevState };
        });
        setTotalSavings((prev) => prev + 0.65 * (addrSavings + diskSavings));
      },
    );

    getUnutilizedLocalDiskSavings().then((savings) => {
      setSavingsData((prevState) => {
        prevState.localDisks.savings = savings;
        prevState.localDisks.loading = false;
        return { ...prevState };
      });
      setTotalSavings((prev) => prev + 0.65 * savings);
    });

    fetchSpotChecklistClusterSizing(
      defaultSpotMinOnDemandNodeCount,
      defaultSpotMinNodeCount,
      defaultSpotTargetUtilization,
      defaultSpotAllowSharedCore,
      defaultSpotWindow,
    ).then((sizing) => {
      setSavingsData((prevState) => {
        // @ts-ignore
        prevState.spotSizing.savings = sizing.monthlySavings;
        prevState.spotSizing.loading = false;
        return { ...prevState };
      });
    });

    const { cpu, ram } = getUtilizationsFromProfile();

    fetchRequestSizingRecommendationsMaxHeadroom('2d', cpu, ram, []).then((recs) => {
      setSavingsData((prevState) => {
        prevState.requestSizing.savings = recs.monthlySavings;
        prevState.requestSizing.loading = false;
        return { ...prevState };
      });
    });
  }
};

export default Savings;
