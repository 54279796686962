import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

import { primary, browns } from '../../../services/colors';
import { toCurrency } from '../../../services/format';

function toPieData(top, other) {
  const slices = [];

  top.forEach((asset, i) => {
    const fill = asset.name === '__unallocated__' ? '#212121' : primary[i % primary.length];
    slices.push({
      name: asset.name,
      value: asset.totalCost,
      fill,
    });
  });

  other.forEach((asset, i) => {
    const fill = browns[i % browns.length];
    slices.push({
      name: asset.name,
      value: asset.totalCost,
      fill,
    });
  });

  return slices;
}

const SummaryChart = ({ currency, height, other, top }) => {
  const pieData = toPieData(top, other);

  const renderLabel = (params) => {
    const { cx, cy, fill, midAngle, name, outerRadius, percent, value } = params;

    const RADIAN = Math.PI / 180;
    const radius = outerRadius * 1.1;
    let x = cx + radius * Math.cos(-midAngle * RADIAN);
    x += x > cx ? 2 : -2;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    // y -= Math.min(Math.abs(2 / Math.cos(-midAngle * RADIAN)), 8)

    if (percent < 0.02) {
      return undefined;
    }

    return (
      <text
        dominantBaseline={'central'}
        fill={fill}
        textAnchor={x > cx ? 'start' : 'end'}
        x={x}
        y={y}
      >
        {`${name}: ${toCurrency(value, currency)} (${(percent * 100).toFixed(1)}%)`}
      </text>
    );
  };

  return (
    <ResponsiveContainer height={height} width={'100%'}>
      <PieChart>
        <Pie
          animationDuration={400}
          cy='90%'
          data={pieData}
          dataKey='value'
          labelLine
          // niko: if tooltips error, try disabling animation
          // isAnimationActive={false}
          endAngle={0}
          innerRadius='60%'
          label={renderLabel}
          nameKey='name'
          outerRadius='140%'
          startAngle={180}
        >
          {pieData.map((datum, i) => (
            <Cell fill={datum.fill} key={i} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default SummaryChart;
