import { FC } from 'react';

import orderBy from 'lodash/orderBy';
import reverse from 'lodash/reverse';

// recharts
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { makeStyles } from '@material-ui/core';

import { accents } from '../../../services/colors';
import { APIAllocationsTimeSeriesGraphPoint, TimeSeriesGraph } from '../../../services/model';

const useStyles = makeStyles({
  tooltip: {
    borderRadius: 2,
    background: 'rgba(255, 255, 255, 0.95)',
    padding: 12,
  },
  tooltipLineItem: {
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  },
});

function toLineLabels(graphItems: APIAllocationsTimeSeriesGraphPoint[]) {
  const keyToFill: Record<string, string> = {};
  let p = 1;

  graphItems.forEach((graphItem) => {
    graphItem.graph.items.forEach(({ name }) => {
      const key = name;
      if (keyToFill[key] === undefined) {
        if (key === '__unallocated__') {
          // unallocated gets black (clean up)
          keyToFill[key] = '#212121';
        } else {
          // non-idle allocations get the next available color
          keyToFill[key] = accents[p];
          p = (p + 1) % accents.length;
        }
      }
    });
  });

  const labels = Object.entries(keyToFill)
    .map(([dataKey, fill]) => ({
      dataKey,
      fill,
    }))
    .sort(({ dataKey: dk1 }, { dataKey: dk2 }) => {
      if (dk1 === '__idle__') {
        return -1;
      }
      if (dk2 === '__idle__') {
        return 1;
      }
      return dk1 > dk2 ? 1 : -1;
    });

  return reverse(labels);
}

const toLine = ({ end, graph, start }: APIAllocationsTimeSeriesGraphPoint) => {
  const graphItems = graph.items.map((item) => ({
    ...item,
    window: { start, end },
  }));
  const line: Record<string, Date | number | string> = {};

  const dateFormatter = Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  });

  const timeFormatter = Intl.DateTimeFormat(navigator.language, {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC',
  });

  graphItems.forEach((item) => {
    if (!item.window) {
      console.warn(`Allocation ${item.name} is missing a window.`);
      return;
    }
    const windowStart = new Date(item.window.start);
    const windowEnd = new Date(item.window.end);
    const windowHours = (windowEnd.valueOf() - windowStart.valueOf()) / 1000 / 60 / 60;

    line.start = new Date(windowStart);
    line.end = new Date(windowEnd);
    if (windowHours >= 24) {
      line.key = dateFormatter.format(line.start);
    } else {
      line.key = timeFormatter.format(line.start);
    }

    line[item.name] = item.efficiency * 100;
  });

  return line;
};

interface LineChartProps {
  data: TimeSeriesGraph;
  height: number;
}

const LineChart: FC<LineChartProps> = ({ data, height }) => {
  const classes = useStyles();
  const lineData = data.graphItems.map(toLine);
  const lineLabels = toLineLabels(data.graphItems);

  const CustomTooltip = (params: any) => {
    const { active, payload } = params;

    if (!payload || payload.length === 0) {
      return null;
    }

    const dateTimeFormatter = Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

    let start = null;
    if (payload[0] && payload[0].payload) {
      start = payload[0].payload.start;
    }

    let end = null;
    if (payload[0] && payload[0].payload) {
      end = payload[0].payload.end;
    }

    // remove totalEfficiency, __idle__ and NaNs
    const filteredPayload = payload.filter(
      (item: any) =>
        item.dataKey !== 'totalEfficiency' && !isNaN(item.value) && item.name !== '__idle__',
    );

    const sortedPayload = orderBy(filteredPayload, ['value'], ['desc']);

    if (active) {
      return (
        <div className={classes.tooltip}>
          {start && end && (
            <p
              className={classes.tooltipLineItem}
              style={{ color: '#808080', fontSize: '0.75rem' }}
            >{`${dateTimeFormatter.format(start)} to ${dateTimeFormatter.format(end)}`}</p>
          )}
          {sortedPayload.map((item) => (
            <div
              key={item.name}
              style={{
                display: 'grid',
                gridTemplateColumns: '25px 1fr 60px',
                alignItems: 'center',
                gap: '.5em',
                margin: '.25em',
                marginBottom: '.5em',
              }}
            >
              <div
                style={{
                  border: `${item.fill} 2px solid`,
                  width: 18,
                  height: 18,
                }}
              />
              <div className={classes.tooltipLineItem}>{`${item.name}: `}</div>
              <div style={{ textAlign: 'end' }}>{`${
                !item.value ? 'Inf' : item.value.toFixed(2)
              }%`}</div>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer data-testid={'efficiency-line-chart'} height={height} width={'100%'}>
      <AreaChart data={lineData} margin={{ top: 10, right: 30, left: 30, bottom: 0 }}>
        <XAxis dataKey={'name'} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} wrapperStyle={{ zIndex: 1000 }} />
        <CartesianGrid strokeDasharray={'5 5'} />
        {lineLabels.map((barLabel, i) => (
          <Area
            dataKey={barLabel.dataKey}
            fill={`${barLabel.fill}`}
            fillOpacity={barLabel.dataKey === 'totalEfficiency' ? 0.8 : 0.02}
            key={barLabel.dataKey}
            stroke={`${barLabel.fill}`}
            type={'monotone'}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
