import blue from '@material-ui/core/colors/blue';
import { ThemeOptions } from '@material-ui/core/styles';

// this declaration allows custom theme values as described here:
// https://mui.com/customization/theming/#custom-variables
declare module '@material-ui/core/styles' {
  interface Theme {
    tooltip: {
      fontSize: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    tooltip?: {
      fontSize?: string;
    };
  }
}

// the theme config
const themeConfig: ThemeOptions = {
  palette: {
    primary: {
      main: blue[500],
    },
  },
  props: {
    MuiLink: {
      underline: 'none',
    },
  },
  tooltip: {
    fontSize: '0.85rem',
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
};

export { themeConfig };
