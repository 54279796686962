import { Button, ButtonGroup } from '@material-ui/core';

export const GraphToggle = ({ setActiveGraph }: { setActiveGraph: (type: string) => void }) => (
  <ButtonGroup
    aria-label={'text primary button group'}
    color={'primary'}
    style={{ float: 'right', height: 20 }}
    variant={'text'}
  >
    <Button onClick={() => setActiveGraph('Bar')}>Bar</Button>
    <Button onClick={() => setActiveGraph('Line')}>Line</Button>
  </ButtonGroup>
);
