import { useState } from 'react';

import map from 'lodash/map';

import { Card, Divider, IconButton, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/styles';

import { ETLCloudStatus } from '../../../services/diagnostics';

import CloudIntegrationDetailDialog from './CloudIntegrationDetailDialog';
import CloudIntegrationEmptyState from './CloudIntegrationEmptyState';
import CloudIntegrationRow from './CloudIntegrationRow';

const KubecostETLCloudDoc =
  'https://github.com/kubecost/docs/blob/master/diagnostics.md#kubecost-etl-pipeline-cloud-metrics';

const useStyles = makeStyles({
  card: {
    paddingTop: '1em',
  },
  headerRow: {
    width: '100%',
    display: 'inline-flex',
    gap: '1em',
  },
  header: {
    marginBottom: '1em',
    paddingLeft: '1em',
  },
  helpButton: {
    flexGrow: 1,
    padding: 0,
    paddingTop: '4px',
  },
});

export type CloudIntegrationStatusProps = {
  etlCloudStatus?: { [key: string]: ETLCloudStatus };
};

const CloudIntegrationStatusPanel = ({ etlCloudStatus }: CloudIntegrationStatusProps) => {
  const classes = useStyles();
  const [selectedCloudProvider, setSelectedCloudProvider] = useState<ETLCloudStatus | null>(null);

  return (
    <Card className={classes.card} variant={'outlined'}>
      <div className={classes.headerRow}>
        <Typography className={classes.header} data-testid={'ci-header'} variant={'h6'}>
          Cloud Integrations
        </Typography>

        <div>
          <IconButton
            className={classes.helpButton}
            data-testid={'ci-help-button'}
            href={KubecostETLCloudDoc}
            target={'_blank'}
          >
            <HelpIcon />
          </IconButton>
        </div>
      </div>

      <Divider />

      {!etlCloudStatus && <CloudIntegrationEmptyState />}

      {map(etlCloudStatus, (cloudStatus: ETLCloudStatus, providerKey: string) => {
        if (providerKey === '' && cloudStatus.cloudConnectionStatus === 'Missing Configuration') {
          return;
        }

        if (
          providerKey === '_' &&
          cloudStatus.cloudConnectionStatus === 'Incomplete Configuration'
        ) {
          return;
        }

        const key = providerKey || 'Cloud Provider';

        return (
          <span key={key}>
            <CloudIntegrationRow
              cloudStatus={cloudStatus}
              key={key}
              onClick={() => setSelectedCloudProvider(cloudStatus)}
              providerKey={key}
            />
          </span>
        );
      })}

      <CloudIntegrationDetailDialog
        cloudProvider={selectedCloudProvider}
        onClose={() => setSelectedCloudProvider(null)}
        open={selectedCloudProvider != null}
      />
    </Card>
  );
};

export default CloudIntegrationStatusPanel;
