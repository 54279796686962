import React, { useEffect, useState } from 'react';

import concat from 'lodash/concat';
import filter from 'lodash/filter';
import get from 'lodash/get';
import keys from 'lodash/keys';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import SpeakerIcon from '@material-ui/icons/Speaker';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/styles';

import { CopyToClipBoard } from '../../components/CopyToClipboard';
import { DiagnosticsChecker } from '../../components/DiagnosticsChecker';
import { Header } from '../../components/Header2New';
import { Warning } from '../../components/Warnings';
import { useGetModelConfig } from '../../hooks';
import { captureError } from '../../services/error_reporting';
import { toCurrency } from '../../services/format';
import { model } from '../../services/model';
import { fetchOrphanedDisks, fetchOrphanedIPAddresses } from '../../services/savings';

const useStyles = makeStyles({
  description: {
    padding: '24px 36px',
    marginBottom: 20,
  },
  errors: {
    padding: '12px 18px',
    marginBottom: 20,
  },
  loading: {
    textAlign: 'center',
    width: '100%',
  },
  totalSavings: {
    alignItems: 'center',
    color: green[700],
    display: 'flex',
    textAlign: 'center',
  },
  flexGrow: {
    flexGrow: 1,
  },
  panelNameWrapper: {
    flex: '1 0 auto',
    display: 'flex',
  },
  chip: {
    margin: '-2px 12px -2px 0',
  },
  form: {
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: 'flex',
    padding: '16px 24px',
    marginBottom: 0,
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
  green: {
    color: green[700],
  },
});

const AddressDescription = (address) => (
  <div>
    <Typography paragraph>Address: {address.address}</Typography>
    <Typography paragraph>Region: {address.region}</Typography>
    <Typography>Description: {JSON.stringify(address.description)}</Typography>
  </div>
);

const DiskDescription = (disk) => (
  <div>
    <Typography paragraph>Size: {disk.sizeGB} GB</Typography>
    <Typography paragraph>Region: {disk.region}</Typography>
    <Typography paragraph>Last attached: {disk.lastAttached || '-'}</Typography>
    <Typography paragraph>Last detached: {disk.lastDetached || '-'}</Typography>
    <Typography>Description: {JSON.stringify(disk.description)}</Typography>
  </div>
);

const OrphanedResourcesPage = () => {
  const classes = useStyles();

  const [fetch, setFetch] = useState(true);
  const [warnings, setWarnings] = useState<Array<Warning>>([]);
  const [disks, setDisks] = useState([]);
  const [loadingDisks, setLoadingDisks] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [loadingAddresses, setLoadingAddresses] = useState(true);
  const [regionFilter, setRegionFilter] = useState('all');
  const [resourceFilter, setResourceFilter] = useState('all');
  const [durationFilter, setDurationFilter] = useState(60 * 60);
  const [items, setItems] = useState([]);
  const { modelConfig } = useGetModelConfig();
  const currency = model.getModelCurrency(modelConfig);

  async function fetchData() {
    if (fetch) {
      clearWarnings();

      try {
        setLoadingDisks(true);

        const disks = await fetchOrphanedDisks();
        setDisks(disks);
        setLoadingDisks(false);

        setLoadingAddresses(true);
        const addresses = await fetchOrphanedIPAddresses();
        setAddresses(addresses);
        setLoadingAddresses(false);
      } catch (err) {
        console.error(err);
        pushWarning('Failed to load resources');
        captureError(err);

        setDisks([]);
        setLoadingDisks(false);
        setAddresses([]);
        setLoadingAddresses(false);
      }

      setFetch(false);
    }
  }

  const clearWarnings = () => {
    setWarnings(() => []);
  };

  const pushWarning = (warn: Warning) => {
    setWarnings((warns: Warning[]) => [...warns, warn]);
  };

  // Handle fetching data
  useEffect(() => {
    fetchData();
  }, []);

  const loading = loadingDisks || loadingAddresses;

  // Handle filter states
  const regionSet = {};
  for (const address of addresses) {
    if (address.region) {
      regionSet[address.region] = address.region;
    }
  }
  for (const disk of disks) {
    if (disk.region) {
      regionSet[disk.region] = disk.region;
    }
  }
  const regions = keys(regionSet);

  const handleRegionFilterChange = (e) => setRegionFilter(e.target.value);
  const handleResourceFilterChange = (e) => setResourceFilter(e.target.value);
  const handleDurationFilterChange = (e) => setDurationFilter(e.target.value);

  // Combine all orphaned resources into one collection and sort by cost
  useEffect(() => {
    let newItems = concat(
      disks.map((d) => ({
        description: DiskDescription(d),
        icon: <SpeakerIcon />,
        label: `${d.sizeGB} GB`,
        lastUsed: moment(get(d, 'lastDetached', 0)),
        name: d.name,
        monthlyCost: d.monthlyCost,
        region: d.region,
        type: 'disk',
        url: d.url,
      })),
      addresses.map((a) => ({
        description: AddressDescription(a),
        icon: <DeviceHubIcon />,
        label: a.address,
        lastUsed: moment(0),
        name: a.name,
        monthlyCost: a.monthlyCost,
        region: a.region,
        type: 'address',
        url: a.url,
      })),
    );
    newItems = filter(newItems, (item) => {
      if (regionFilter !== 'all' && item.region !== regionFilter) {
        return false;
      }
      if (resourceFilter !== 'all' && item.type !== resourceFilter) {
        return false;
      }
      const threshold = moment().subtract(durationFilter, 'seconds');
      if (durationFilter > 0 && item.lastUsed && threshold.isBefore(item.lastUsed)) {
        return false;
      }
      return true;
    });
    setItems(reverse(sortBy(newItems, 'monthlyCost')));
  }, [disks, addresses, durationFilter, regionFilter, resourceFilter]);

  // Compute savings totals
  const diskSavings = disks.reduce((total, disk) => total + disk.monthlyCost, 0.0);
  const addressSavings = addresses.reduce((total, address) => total + address.monthlyCost, 0.0);
  const totalSavings = diskSavings + addressSavings;
  const filteredSavings = items.reduce((total, item) => total + item.monthlyCost, 0.0);

  return (
    <>
      <Header title={'Orphaned Resources'}>
        <IconButton aria-label={'refresh'} onClick={() => setFetch(true)}>
          <RefreshIcon />
        </IconButton>
        <DiagnosticsChecker />
        <Link href={'settings.html'}>
          <IconButton aria-label={'refresh'}>
            <SettingsIcon />
          </IconButton>
        </Link>
      </Header>

      <Paper className={classes.description}>
        <Grid spacing={3} container>
          <Grid className={classes.totalSavings} md={4} item>
            {loading && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
            {!loading && (
              <Typography
                className={classes.flexGrow}
                id={'total-savings'}
                variant={'h3'}
              >{`${toCurrency(totalSavings, currency)}/mo`}</Typography>
            )}
          </Grid>
          <Grid md={8} item>
            <Typography variant={'h5'} paragraph>
              Orphaned Resources
            </Typography>
            <Typography variant={'body1'}>
              Disks and IP addresses that are not being used by any clusters may continue to incur
              charges. The following resources have been identified as potentially orphaned and are
              candidates for deletion.
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {!loading && warnings.length > 0 && (
        <Paper className={classes.errors}>
          <List>
            {warnings.map((err, i) => (
              <ListItem key={i}>
                <ListItemIcon>
                  <WarningIcon />
                </ListItemIcon>
                <ListItemText
                  primary={err}
                  secondary={
                    'Check that you have a valid service key and the cost analyzer API is running, then refresh'
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {!loading && (
        <Paper className={classes.form}>
          <div className={classes.flexGrow}>
            <FormControl className={classes.formControl}>
              <InputLabel id={'resource-type-select-label'}>Resource</InputLabel>
              <Select
                id={'resource-type-select'}
                labelId={'resource-type-select-label'}
                onChange={handleResourceFilterChange}
                value={resourceFilter}
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'disk'}>Disk</MenuItem>
                <MenuItem value={'address'}>IP Address</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id={'region-select-label'}>Region</InputLabel>
              <Select
                id={'region-select'}
                labelId={'region-select-label'}
                onChange={handleRegionFilterChange}
                value={regionFilter}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id={'duration-select-label'}>Orphaned at least</InputLabel>
              <Select
                id={'duration-select'}
                labelId={'duration-select-label'}
                onChange={handleDurationFilterChange}
                value={durationFilter}
              >
                <MenuItem key={'hour'} value={60 * 60}>
                  an hour
                </MenuItem>
                <MenuItem key={'day'} value={60 * 60 * 24}>
                  a day
                </MenuItem>
                <MenuItem key={'week'} value={60 * 60 * 24 * 7}>
                  a week
                </MenuItem>
                <MenuItem key={'month'} value={60 * 60 * 730}>
                  a month
                </MenuItem>
                <MenuItem key={'year'} value={60 * 60 * 24 * 365}>
                  a year
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ textAlign: 'center', padding: '0 1em' }}>
            <Typography variant={'h5'}>
              <span className={classes.green}>{toCurrency(filteredSavings, currency)}/mo</span>
            </Typography>
            <Typography variant={'body2'}>{items.length} resources</Typography>
          </div>
        </Paper>
      )}

      {!loading &&
        items.map((item, i) => (
          <ExpansionPanel key={i}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.panelNameWrapper}>
                <Chip className={classes.chip} icon={item.icon} label={item.label} size={'small'} />
                <Typography>{item.name}</Typography>
                <div style={{ marginLeft: '.5em' }}>
                  <CopyToClipBoard copyText={item.name} />
                </div>
              </div>
              <Typography style={{ color: green[700] }}>
                {toCurrency(item.monthlyCost, currency)}/mo
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>{item.description}</div>
            </ExpansionPanelDetails>
            <ExpansionPanelActions>
              <Button color={'primary'} href={item.url} rel={'noopener'} target={'_blank'}>
                View in console
              </Button>
            </ExpansionPanelActions>
          </ExpansionPanel>
        ))}
    </>
  );
};

export default OrphanedResourcesPage;
