import { FC, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Button, Modal, Typography } from '@kubecost-frontend/holster';

import {
  applyRequestPlan,
  requestRecommendationsToPlan,
} from '../../services/clustercontroller/requestsizerv2';
import Logger from '../../services/logger';

import { ApplyExceptions } from './ApplyExceptions';
import { ApplyMessages } from './ApplyMessages';
import { RequestSizingResponse } from './types';

interface ApplyRecommendationsProps {
  filters?: any[];
  open: boolean;
  recommendations: RequestSizingResponse['Recommendations'];
  setOpen: (o: boolean) => void;
}

export const ApplyRecommendations: FC<ApplyRecommendationsProps> = ({
  filters,
  open,
  recommendations,
  setOpen,
}) => {
  // const [applyDialogOpen, setApplyDialogOpen] = useState(false);

  const [applyResp, setApplyResp] = useState();
  const [applyException, setApplyException] = useState<Error>();
  const [applying, setApplying] = useState(false);

  // let isUnfilteredRec = filters.length === 0

  async function handleApply() {
    setApplyResp(undefined);
    setApplyException(undefined);
    setApplying(true);
    try {
      Logger.log('Creating plan from recs:', recommendations);
      const plan = await requestRecommendationsToPlan(recommendations);
      Logger.log('Got plan:', plan);
      const applyResp = await applyRequestPlan(plan);
      setApplying(false);
      setApplyResp(applyResp);
      Logger.log('apply resp:', applyResp);
    } catch (error) {
      setApplying(false);
      if (error instanceof Error) {
        setApplyException(error);
      }
      console.error(error);
      Logger.error('apply failure:', error);
    }
  }

  return (
    <>
      <Button
        className={'h-33px mr-3 flex items-center'}
        onClick={() => setOpen(true)}
        variant={'default'}
      >
        Automatically Implement Recommendations
      </Button>
      <Modal
        onClose={() => setOpen(false)}
        open={open}
        title={'Confirm implementation of recommendations'}
      >
        <Typography variant={'p'}>
          Are you sure you want to apply these container request sizing recommendations to{' '}
          <Typography className={'inline'} style={{ fontWeight: 600 }} variant={'p'}>
            {recommendations.length} containers
          </Typography>
          ?
        </Typography>

        {
          /* isUnfilteredRec && ( */
          <Typography className={'mb-3'} style={{ fontWeight: 600 }} variant={'p'}>
            <br />
            Warning: this is an unfiltered recommendation and will apply to an entire cluster.
          </Typography>
          /* ) */
        }
        <div>
          <Button
            className={'mr-3'}
            onClick={() => {
              handleApply();
              setOpen(false);
            }}
            variant={'primary'}
          >
            Yes, apply the recommendation
          </Button>
          <Button
            className={'mr-3'}
            onClick={() => {
              setOpen(false);
            }}
            variant={'primary'}
          >
            No, do not apply the recommendation
          </Button>
        </div>
      </Modal>
      {applying && <CircularProgress size={20} />}
      {applyException !== undefined && (
        <ApplyExceptions exception={applyException.toString()} header={'Failed to apply'} />
      )}

      {applyResp !== undefined && applyResp.Errors !== null && applyResp.Errors.length != 0 && (
        <ApplyMessages
          data={applyResp.Errors}
          header={'Applying recommendation partially failed:'}
        />
      )}

      {applyResp !== undefined && applyResp.Warnings !== null && applyResp.Warnings.length != 0 && (
        <ApplyMessages
          data={applyResp.Warnings}
          header={'Applying recommendation had some warnings:'}
        />
      )}
    </>
  );
};

ApplyRecommendations.displayName = 'ApplyRecommendations';
