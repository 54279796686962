import { FC } from 'react';

import { NavIconProps } from './types';

const HealthNavIcon: FC<NavIconProps> = ({ active, ...args }) => (
  <svg
    fill={'none'}
    height={'30'}
    viewBox={'0 0 30 30'}
    width={'30'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...args}
  >
    <path
      d={
        'M15 5C17.6522 5 20.1957 6.05357 22.0711 7.92893C23.9464 9.8043 25 12.3478 25 15C25 17.6522 23.9464 20.1957 22.0711 22.0711C20.1957 23.9464 17.6522 25 15 25C12.3478 25 9.8043 23.9464 7.92893 22.0711C6.05357 20.1957 5 17.6522 5 15C5 12.3478 6.05357 9.8043 7.92893 7.92893C9.8043 6.05357 12.3478 5 15 5ZM15 23.5714C17.2733 23.5714 19.4535 22.6684 21.0609 21.0609C22.6684 19.4535 23.5714 17.2733 23.5714 15C23.5714 12.7267 22.6684 10.5465 21.0609 8.93908C19.4535 7.33163 17.2733 6.42857 15 6.42857C12.7267 6.42857 10.5465 7.33163 8.93908 8.93908C7.33163 10.5465 6.42857 12.7267 6.42857 15C6.42857 17.2733 7.33163 19.4535 8.93908 21.0609C10.5465 22.6684 12.7267 23.5714 15 23.5714ZM16.0714 19.6429C16.0714 19.927 15.9585 20.1995 15.7576 20.4005C15.5567 20.6014 15.2842 20.7143 15 20.7143C14.7158 20.7143 14.4433 20.6014 14.2424 20.4005C14.0415 20.1995 13.9286 19.927 13.9286 19.6429C13.9286 19.3587 14.0415 19.0862 14.2424 18.8852C14.4433 18.6843 14.7158 18.5714 15 18.5714C15.2842 18.5714 15.5567 18.6843 15.7576 18.8852C15.9585 19.0862 16.0714 19.3587 16.0714 19.6429ZM15 9.28571C15.1894 9.28571 15.3711 9.36097 15.5051 9.49492C15.639 9.62888 15.7143 9.81056 15.7143 10V16.4286C15.7143 16.618 15.639 16.7997 15.5051 16.9336C15.3711 17.0676 15.1894 17.1429 15 17.1429C14.8106 17.1429 14.6289 17.0676 14.4949 16.9336C14.361 16.7997 14.2857 16.618 14.2857 16.4286V10C14.2857 9.81056 14.361 9.62888 14.4949 9.49492C14.6289 9.36097 14.8106 9.28571 15 9.28571Z'
      }
      fill={active ? '#63E892' : '#607971'}
    />
  </svg>
);

export { HealthNavIcon };
