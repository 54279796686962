import React from 'react';
import { Menu, Tooltip } from '@kubecost-frontend/holster';

type ReportActionMenuProps = {
  readonly?: boolean;
  deleteClicked: () => void;
};

const ReportActionMenu = ({
  deleteClicked,
  readonly = false,
}: ReportActionMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  /*
  if (readonly) {
    return (
      <Tooltip
        content="Only admin or editor users may delete reports"
        style={{ width: 200, left: -100 }}
      >
        <div
          data-testid="menu-button"
          id="action-positioned-button"
          aria-controls={open ? 'actions-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          style={{ cursor: 'default' }}
        >
          ...
        </div>
      </Tooltip>
    );
  }
  */

  return (
    <div style={{ position: 'relative' }}>
      <div
        data-testid="menu-button"
        id="action-positioned-button"
        onClick={handleOpenActionMenu}
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        style={{ cursor: 'pointer' }}
      >
        ...
      </div>
      {open && (
        <Menu
          items={[{ text: 'Delete' }]}
          onClose={handleClose}
          selectItem={deleteClicked}
        />
      )}
    </div>
  );
};

export default ReportActionMenu;
