import { JSONToBase64, base64ToJSON } from '../../util/func';
import { WindowOptions } from '../AssetsNew/AssetControls/types';
import { useURLStore } from '../../hooks/useURLStore';

import { Filter, Profile, RecommendationAlgorithm, RequestSizingParams } from './types';

const allFilterPropertyOptions = [
  'Account',
  'Category',
  'Cluster',
  'Label/Tag',
  'Name',
  'Project',
  'Provider',
  'ProviderID',
  'Service',
  'Type',
];

const defaultAggOptions = [
  {
    name: 'Cluster',
    value: 'cluster',
  },
  {
    name: 'Controller',
    value: 'controller',
  },
  {
    name: 'Container',
    value: 'container',
  },
  {
    name: 'Deployment',
    value: 'deployment',
  },
  {
    name: 'Label',
    value: 'label',
  },
  {
    name: 'Namespace',
    value: 'namespace',
  },
  {
    name: 'Pod',
    value: 'pod',
  },
  {
    name: 'Service',
    value: 'service',
  },
];

const filterKeyMap: Record<string, string> = {
  cluster: 'filterClusters',
  node: 'filterNodes',
  namespace: 'filterNamespaces',
  label: 'filterLabels',
  service: 'filterServices',
  controllerkind: 'filterControllerKinds',
  controller: 'filterControllers',
  pod: 'filterPods',
  department: 'filterDepartments',
  environment: 'filterEnvironments',
  owner: 'filterOwners',
  product: 'filterProducts',
  team: 'filterTeams',
};

const filterPropertyOptions = [
  'cluster',
  'node',
  'namespace',
  'label',
  'service',
  'controller',
  'controllerKind',
  'pod',
];

const windowOptions: WindowOptions = [
  { label: '1 day', value: '1d' },
  { label: '2 days', value: '2d' },
  { label: '3 days', value: '3d' },
  { label: '4 days', value: '4d' },
  { label: '5 days', value: '5d' },
  { label: '6 days', value: '6d' },
  { label: '7 days', value: '7d' },
];

const userInputDefaults: RequestSizingParams = {
  window: '2d',
  page: 0,
  itemsPerPage: 10,
  algorithmCPU: RecommendationAlgorithm.Quantile,
  algorithmRAM: RecommendationAlgorithm.Quantile,
  qCPU: 0.95,
  qRAM: 0.95,
  targetCPUUtilization: 0.8,
  targetRAMUtilization: 0.8,
  filter: [] as Filter[],
  profile: Profile.Custom,
};

const encoders = {
  window: String,
  algorithmCPU: String,
  algorithmRAM: String,
  qCPU: String,
  qRAM: String,
  targetCPUUtilization: String,
  targetRAMUtilization: String,
  filter: JSONToBase64,
  profile: String,
  page: String,
  itemsPerPage: String,
};

const decoders = {
  window: String,
  algorithmCPU: (s: string) => s as RecommendationAlgorithm,
  algorithmRAM: (s: string) => s as RecommendationAlgorithm,
  qCPU: parseFloat,
  qRAM: parseFloat,
  targetCPUUtilization: parseFloat,
  targetRAMUtilization: parseFloat,
  filter: base64ToJSON,
  profile: (s: string) => s as Profile,
  page: (s: string) => parseInt(s, 10),
  itemsPerPage: (s: string) => parseInt(s, 10),
};

const useURLTokens = () => useURLStore(encoders, decoders, userInputDefaults);

export {
  allFilterPropertyOptions,
  decoders,
  defaultAggOptions,
  encoders,
  filterKeyMap,
  filterPropertyOptions,
  userInputDefaults,
  useURLTokens,
  windowOptions,
};
