// react
import React, { useEffect, useState } from 'react';

// mui
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// holster
import { Button, Alert as HolsterAlert, Typography } from '@kubecost-frontend/holster';

// model
import Logger from '../../services/logger';
import { model } from '../../services/model';

import { Transition } from './DialogTransition';
import InviteEmailRecipients from './InviteEmailRecipients';

interface InviteTeamMemberModalProps {
  closeModal: () => void;
  visible: boolean;
}

export const InviteTeamMemberModal = ({ closeModal, visible }: InviteTeamMemberModalProps) => {
  const [inviteEmailRecipients, setInviteEmailRecipients] = useState<string[]>([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);

  const handleInvite = async () => {
    if (inviteEmailRecipients.length === 0) {
      Logger.error('cannot send request with no recipients');
      return;
    }

    const payload = {
      recipients: inviteEmailRecipients,
    };

    try {
      await model.sendTeamInvite(payload);
      setConfirmDialogOpen(true);
    } catch (err) {
      setErrorDialogOpen(true);
      Logger.error(err);
    }
  };

  const close = () => {
    closeModal();

    setInviteEmailRecipients([]);

    if (confirmDialogOpen) {
      setConfirmDialogOpen(false);
    }

    if (errorDialogOpen) {
      setErrorDialogOpen(false);
    }
  };

  // Disable save if no recipients
  useEffect(() => {
    if (inviteEmailRecipients.length === 0) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [inviteEmailRecipients]);

  return (
    <div>
      <Dialog TransitionComponent={Transition as any} open={visible}>
        <Typography style={{ padding: '1em' }} variant={'p-large'}>
          Add member
        </Typography>
        {errorDialogOpen ? (
          <DialogContent>
            <HolsterAlert
              content={
                "Uh oh, looks like we couldn't send your team invites. Check console logs for more information."
              }
              title={'Error'}
              variant={'danger'}
            />
          </DialogContent>
        ) : confirmDialogOpen ? (
          <DialogContent>
            <HolsterAlert
              content={'Your team invites have been sent!'}
              title={'Success!'}
              variant={'success'}
            />
          </DialogContent>
        ) : (
          <DialogContent>
            <HolsterAlert
              content={
                'Your teammates will receive an email with an invite to join the team. New users will have to create an account before they can accept that invite.'
              }
              style={{ marginBottom: 20 }}
              title={"It's important to know"}
              variant={'info'}
            />
            <InviteEmailRecipients
              addItem={(item: string) => {
                if (!inviteEmailRecipients.includes(item)) {
                  setInviteEmailRecipients((recp) => [...recp, item]);
                }
              }}
              recipients={inviteEmailRecipients}
              removeItem={(item: number) => {
                setInviteEmailRecipients((recp) =>
                  recp.slice(0, item).concat(recp.slice(item + 1)),
                );
              }}
            />
          </DialogContent>
        )}
        {errorDialogOpen ? (
          <DialogActions style={{ padding: 16 }}>
            <Button color={'primary'} onClick={close}>
              Close
            </Button>
          </DialogActions>
        ) : confirmDialogOpen ? (
          <DialogActions style={{ padding: 16 }}>
            <Button color={'primary'} onClick={close}>
              Close
            </Button>
          </DialogActions>
        ) : (
          <DialogActions style={{ padding: 16 }}>
            <Button color={'primary'} onClick={close}>
              Cancel
            </Button>
            <Button
              color={'primary'}
              disabled={saveDisabled}
              onClick={handleInvite}
              variant={'primary'}
            >
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
