import { IconProps } from './types';

const PlusIcon = ({ ...args }: IconProps) => (
  <svg
    height={'1em'}
    preserveAspectRatio={'xMidYMid meet'}
    viewBox={'0 0 1024 1024'}
    width={'1em'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...args}
  >
    <path d={'M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z'} fill={'currentColor'} />
    <path d={'M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8Z'} fill={'currentColor'} />
  </svg>
);

export { PlusIcon };
