import { useMemo, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Button, Toggle } from '@kubecost-frontend/holster';

import { AlertService, AlertTypes, ClusterHealthAlert } from '../../../services/alerts';
import TestAlertButton from '../TestAlertButton';

import { HealthAlertModal as CreateHealthAlertModal } from './HealthAlertModal';

const HealthAlert = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { data } = useQuery('alerts', AlertService.getAlerts);
  const createAlert = useMutation(AlertService.upsertAlert, {
    onSuccess: () => {
      queryClient.invalidateQueries('alerts');
    },
  });
  const deleteAlert = useMutation(AlertService.deleteAlert, {
    onSuccess: () => {
      queryClient.invalidateQueries('alerts');
    },
  });

  const clusterHealthAlert = useMemo((): ClusterHealthAlert | undefined => {
    if (!data) return undefined;
    const values = data.find((a) => a.type === 'health');
    if (!values) return undefined;

    return new ClusterHealthAlert({
      ...values,
      type: AlertTypes.Health,
    });
  }, [data]);

  const newAlertCreated = async (alert: ClusterHealthAlert) => {
    await createAlert.mutateAsync(alert);
    setShowDialog(false);
  };

  const deleteAlertClicked = () => {
    if (clusterHealthAlert === undefined) return;
    deleteAlert.mutate(clusterHealthAlert);
  };

  return (
    <div style={{ marginTop: 20, width: 260 }}>
      <Toggle
        checked={clusterHealthAlert !== undefined}
        label={'Monitor Cluster Health'}
        onChange={async () => {
          if (clusterHealthAlert === undefined) {
            setShowDialog(true);
          } else {
            deleteAlertClicked();
          }
        }}
      />

      {clusterHealthAlert && (
        <Button
          data-testid={'ha-edit-button'}
          onClick={() => {
            setShowDialog(true);
          }}
          style={{ marginRight: 8 }}
          variant={'primary'}
        >
          Edit
        </Button>
      )}
      {clusterHealthAlert && (
        <span data-testid={'test-alert-button'}>
          <TestAlertButton alert={clusterHealthAlert} />
        </span>
      )}
      <CreateHealthAlertModal
        alert={clusterHealthAlert}
        alertSaved={newAlertCreated}
        cancelClicked={() => setShowDialog(false)}
        open={showDialog}
      />
    </div>
  );
};

export default HealthAlert;
