import React, { useEffect, useState } from 'react';

import filter from 'lodash/filter';
import keys from 'lodash/keys';
import reverse from 'lodash/reverse';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClusterIcon from '@material-ui/icons/GroupWork';
import LabelIcon from '@material-ui/icons/Label';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/styles';

import { DiagnosticsChecker } from '../../components/DiagnosticsChecker';
import { Header } from '../../components/Header';
import { HelpIconLink } from '../../components/HelpIconLink';
import { Warning } from '../../components/Warnings';
import { useGetModelConfig } from '../../hooks/useGetModelConfig';
import cluster from '../../services/cluster';
import { captureError } from '../../services/error_reporting';
import { bytesToString, coresToString, toCurrency } from '../../services/format';
import { model } from '../../services/model';
import { fetchAbandonedWorkloads } from '../../services/savings';

const useStyles = makeStyles({
  description: {
    padding: '24px 36px',
    marginBottom: 20,
  },
  errors: {
    padding: '12px 18px',
    marginBottom: 20,
  },
  loading: {
    textAlign: 'center',
    width: '100%',
  },
  totalSavings: {
    alignItems: 'center',
    color: green[700],
    display: 'flex',
    textAlign: 'center',
  },
  flexGrow: {
    flexGrow: 1,
  },
  panelNameWrapper: {
    flex: '1 0 auto',
    display: 'flex',
  },
  chip: {
    margin: '-2px 12px -2px 0',
  },
  topForm: {
    marginTop: 32,
  },
  slider: {
    padding: '28px 0 0 0',
  },
  form: {
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: 'flex',
    padding: '16px 24px',
    marginBottom: 0,
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
  green: {
    color: green[700],
  },
  cell: {
    padding: '0px',
    borderBottom: '0px solid',
    border: '0px solid',
  },
});

const AbandonedWorkloadsPage = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [warnings, setWarnings] = useState<Array<Warning>>([]);
  const [pods, setPods] = useState([]);
  const [filteredPods, setFilteredPods] = useState([]);
  const [currentBytesThreshold, setCurrentBytesThreshold] = useState(500);
  const [bytesThreshold, setBytesThreshold] = useState(currentBytesThreshold);
  const [durationDays, setDurationDays] = useState(2);
  const [clusterFilter, setClusterFilter] = useState('all');
  const [namespaceFilter, setNamespaceFilter] = useState('all');
  const [ownerNameFilter, setOwnerNameFilter] = useState('all');
  const [ownerKindFilter, setOwnerKindFilter] = useState('all');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { modelConfig } = useGetModelConfig();
  const currency = model.getModelCurrency(modelConfig);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function fetchData() {
    clearWarnings();

    setLoading(true);
    try {
      const ps = await fetchAbandonedWorkloads(durationDays, bytesThreshold);
      ps.forEach((pod) => {
        pod.cluster = cluster.clusterNameId(pod);
      });
      setPods(ps);
    } catch (err) {
      console.error(err);
      pushWarning({
        primary: 'Failed to load abandoned pods',
        secondary: 'Check that the Prometheus server is running, then refresh',
      });
      captureError(err);
      setPods([]);
    }
    setLoading(false);
  }

  const clearWarnings = () => {
    setWarnings(() => []);
  };

  const pushWarning = (warn: Warning) => {
    setWarnings((warns: Warning[]) => [...warns, warn]);
  };

  // Handle fetching data
  useEffect(() => {
    fetchData();
  }, [durationDays, bytesThreshold]);

  // Handle filter states
  const clusterSet = {};
  for (const pod of pods) {
    clusterSet[pod.cluster] = pod.cluster;
  }
  const clusters = keys(clusterSet);

  const namespaceSet = {};
  for (const pod of pods) {
    namespaceSet[pod.namespace] = pod.namespace;
  }
  const namespaces = keys(namespaceSet);

  const ownerNameSet = {};
  for (const pod of pods) {
    for (const cr of pod.owners) {
      ownerNameSet[cr.name] = cr.name;
    }
  }
  const ownerNames = keys(ownerNameSet);

  const ownerKindSet = {};
  for (const pod of pods) {
    for (const cr of pod.owners) {
      ownerKindSet[cr.kind] = cr.kind;
    }
  }
  const ownerKinds = keys(ownerKindSet);

  const handleBytesThresholdChange = (e, value) => setCurrentBytesThreshold(value);
  const handleDurationChange = (e) => {
    setDurationDays(e.target.value);
  };
  const handleClusterFilterChange = (e) => setClusterFilter(e.target.value);
  const handleNamespaceFilterChange = (e) => setNamespaceFilter(e.target.value);
  const handleOwnerNameFilterChange = (e) => setOwnerNameFilter(e.target.value);
  const handleOwnerKindFilterChange = (e) => setOwnerKindFilter(e.target.value);

  useEffect(() => {
    const t = setTimeout(() => {
      console.log('Updating Bytes Threshold');
      setBytesThreshold(currentBytesThreshold);
    }, 300);

    return () => clearTimeout(t);
  }, [currentBytesThreshold]);

  // Filter, then sort pods
  useEffect(() => {
    const fps = filter(pods, (pod) => {
      if (clusterFilter !== 'all' && pod.cluster !== clusterFilter) {
        return false;
      }
      if (namespaceFilter !== 'all' && pod.namespace !== namespaceFilter) {
        return false;
      }
      if (ownerNameFilter !== 'all' && !pod.owners.some((o) => o.name === ownerNameFilter)) {
        return false;
      }
      if (ownerKindFilter !== 'all' && !pod.owners.some((o) => o.kind === ownerKindFilter)) {
        return false;
      }
      return true;
    });
    const p = reverse(sortBy(fps, 'monthlySavings'));

    setFilteredPods(p);
    if (page * rowsPerPage >= p.length) {
      setPage(0);
    }
  }, [pods, ownerKindFilter, ownerNameFilter, namespaceFilter, clusterFilter]);

  // Compute savings totals
  const totalSavings = pods.reduce((acc, pod) => acc + pod.monthlySavings, 0.0);
  const filteredSavings = filteredPods.reduce((total, pod) => total + pod.monthlySavings, 0.0);
  const filteredLength = filteredPods.length;
  const lastPage = Math.floor(filteredLength / rowsPerPage);
  const pageRows = filteredPods.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Cluster Savings', href: 'savings.html' },
          { name: 'Abandoned Workloads', href: 'abandoned-workloads.html' },
        ]}
      >
        <IconButton aria-label={'refresh'}>
          <RefreshIcon />
        </IconButton>
        <DiagnosticsChecker />
        <Link href={'settings.html'}>
          <IconButton aria-label={'refresh'}>
            <SettingsIcon />
          </IconButton>
        </Link>
        <HelpIconLink
          href={'https://docs.kubecost.com/api-abandoned-workloads'}
          tooltipText={'Product Documentation'}
        />
      </Header>

      <Paper className={classes.description}>
        <Grid spacing={3} container>
          <Grid className={classes.totalSavings} md={4} item>
            {loading && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
            {!loading && (
              <Typography
                className={classes.flexGrow}
                id={'total-savings'}
                variant={'h3'}
              >{`${toCurrency(totalSavings, currency)}/mo`}</Typography>
            )}
          </Grid>
          <Grid md={8} item>
            <Typography variant={'h5'} paragraph>
              Abandoned Workloads
            </Typography>
            <Typography variant={'body1'}>
              Pods that have not sent or received a meaningful rate of traffic over a given duration
              may represent abandoned workloads. After ensuring that a pod is abandoned, potential
              remedies include scaling down replicas, deleting, resizing, or notifying their owner.
            </Typography>
            <div className={classes.topForm}>
              <FormControl className={classes.formControl} style={{ width: 200 }}>
                <Slider
                  className={classes.slider}
                  max={2000}
                  min={100}
                  onChange={handleBytesThresholdChange}
                  onChangeCommitted={() => {}}
                  step={100}
                  value={currentBytesThreshold}
                  valueLabelDisplay={'on'}
                  marks
                />
                <Typography style={{ paddingTop: 8 }} variant={'body2'}>
                  Traffic threshold (bytes/sec)
                </Typography>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Select id={'duration-select'} onChange={handleDurationChange} value={durationDays}>
                  <MenuItem key={'2d'} value={2}>
                    2 days
                  </MenuItem>
                  <MenuItem key={'7d'} value={7}>
                    7 days
                  </MenuItem>
                  <MenuItem key={'30d'} value={30}>
                    30 days
                  </MenuItem>
                </Select>
                <Typography style={{ paddingTop: 8 }} variant={'body2'}>
                  Duration
                </Typography>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </Paper>

      {!loading && warnings.length > 0 && (
        <Paper className={classes.errors}>
          <List>
            {warnings.map((err, i) => (
              <ListItem key={i}>
                <ListItemIcon>
                  <WarningIcon />
                </ListItemIcon>
                <ListItemText primary={err.primary} secondary={err.secondary} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {!loading && (
        <Paper className={classes.form}>
          <div className={classes.flexGrow}>
            <FormControl className={classes.formControl}>
              <InputLabel id={'cluster-select-label'}>Cluster</InputLabel>
              <Select
                id={'cluster-select'}
                labelId={'cluster-select-label'}
                onChange={handleClusterFilterChange}
                value={clusterFilter}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {clusters.map((cluster) => (
                  <MenuItem key={cluster} value={cluster}>
                    {cluster}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id={'namespace-select-label'}>Namespace</InputLabel>
              <Select
                id={'namespace-select'}
                labelId={'namespace-select-label'}
                onChange={handleNamespaceFilterChange}
                value={namespaceFilter}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {namespaces.map((namespace) => (
                  <MenuItem key={namespace} value={namespace}>
                    {namespace}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id={'owner-name-select-label'}>Owner name</InputLabel>
              <Select
                id={'owner-name-select'}
                labelId={'owner-name-select-label'}
                onChange={handleOwnerNameFilterChange}
                value={ownerNameFilter}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {ownerNames.map((ownerName) => (
                  <MenuItem key={ownerName} value={ownerName}>
                    {ownerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id={'owner-kind-select-label'}>Owner kind</InputLabel>
              <Select
                id={'owner-kind-select'}
                labelId={'owner-kind-select-label'}
                onChange={handleOwnerKindFilterChange}
                value={ownerKindFilter}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {ownerKinds.map((ownerKind) => (
                  <MenuItem key={ownerKind} value={ownerKind}>
                    {ownerKind}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ textAlign: 'center', padding: '0 1em' }}>
            <Typography variant={'h5'}>
              <span className={classes.green}>{toCurrency(filteredSavings, currency)}/mo</span>
            </Typography>
            <Typography variant={'body2'}>{pods.length} workloads</Typography>
          </div>
        </Paper>
      )}

      {!loading && (
        <>
          <TableContainer>
            <Table>
              <TableBody>
                {pageRows.map((pod, i) => (
                  <TableRow key={`${i}-row`}>
                    <TableCell className={classes.cell}>
                      <Accordion key={`${i}-acc`} square>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <div className={classes.panelNameWrapper}>
                            <Chip
                              className={classes.chip}
                              icon={<ClusterIcon />}
                              label={pod.cluster}
                              size={'small'}
                            />
                            <Chip
                              className={classes.chip}
                              icon={<LabelIcon />}
                              label={pod.namespace}
                              size={'small'}
                            />
                            <Typography>{pod.pod}</Typography>
                          </div>
                          <Typography style={{ color: green[700] }}>
                            {toCurrency(pod.monthlySavings, currency)}/mo
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {pod.owners.map((cr, i) => (
                              <Typography key={i} paragraph>
                                {cr.kind}: {cr.name}
                              </Typography>
                            ))}
                            <Typography paragraph>
                              Data ingress: {round(pod.ingressBytesPerSecond, 1)} B/s
                            </Typography>
                            <Typography paragraph>
                              Data egress: {round(pod.egressBytesPerSecond, 1)} B/s
                            </Typography>
                            <Typography paragraph>
                              CPU Request: {coresToString(pod.requests.cpuCores)}
                            </Typography>
                            <Typography paragraph>
                              RAM Request: {bytesToString(pod.requests.ramBytes)}
                            </Typography>
                            <Typography paragraph>
                              CPU Usage: {coresToString(pod.usage.cpuCores)}
                            </Typography>
                            <Typography paragraph>
                              RAM Usage: {bytesToString(pod.usage.ramBytes)}
                            </Typography>
                          </div>
                        </AccordionDetails>
                        {/*

                    TODO niko/savings add button that launches a dialog with instructions for scaling down
                    owners, deleting pods, etc.

                    <AccordionActions>
                      <Button href="#" color="primary" target="_blank" rel="noopener">
                        What action here?
                      </Button>
                    </AccordionActions>
                    */}
                      </Accordion>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component={'div'}
            count={filteredLength}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={Math.min(page, lastPage)}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{ backgroundColor: '#FFFFFF', borderTop: '1px solid' }}
          />
        </>
      )}
    </>
  );
};

export default AbandonedWorkloadsPage;
