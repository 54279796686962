import { memo, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';

import { FetchStates } from '../../constants';
import { useClusters } from '../../contexts/ClusterConfig';
import { fetchWithTimeout, sanitizeHTML } from '../../services/util';

import DeadClusterListItem from './DeadClusterListItem';
import LiveClusterListItem from './LiveClusterListItem';
import NewClusterModal from './NewClusterModal';

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    padding: 8,
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
});

const ClusterList = () => {
  // state
  const [fetchState, setFetchState] = useState(FetchStates.LOADING);
  const [reachedClusters, setReachedClusters] = useState([]);
  const [unreachedClusters, setUnreachedClusters] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();

  const {
    activateContext,
    addContext,
    localClusterEndpoints,
    remoteClusterEndpoints,
    removeContext,
  } = useClusters();

  const handleGoToSettings = () => {
    navigate('../settings');
  };

  useEffect(() => {
    getContextClusters();
  }, [localClusterEndpoints, fetchState]);

  return (
    <>
      {/* header and controls */}
      <Toolbar style={{ justifyContent: 'flex-end' }}>
        <IconButton
          onClick={() => {
            setFetchState(FetchStates.LOADING);
          }}
        >
          <RefreshIcon />
        </IconButton>
        <IconButton edge={'end'} onClick={handleGoToSettings}>
          <SettingsIcon />
        </IconButton>
      </Toolbar>
      <Container maxWidth={'sm'} style={{ marginTop: 24 }}>
        {/* List of available clusters (or load spinner) */}
        {fetchState === FetchStates.LOADING && (
          <Box
            component={Paper}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1em',
            }}
          >
            <CircularProgress style={{ marginLeft: -8 }} />
            <Typography style={{ marginLeft: 8 }}>Fetching clusters...</Typography>
          </Box>
        )}

        {fetchState === FetchStates.DONE ? (
          <>
            <Typography variant={'h6'}>{reachedClusters ? 'Available Contexts' : ''}</Typography>
            {reachedClusters.map(({ data, endpoint }) => (
              <LiveClusterListItem address={endpoint} click={activateContext} cluster={data} />
            ))}
          </>
        ) : (
          <></>
        )}

        {/* Add Cluster button */}
        <div onClick={() => setDialogOpen(true)}>
          <div
            style={{
              cursor: 'pointer',
              textAlign: 'center',
              color: '#777',
              marginTop: 24,
              marginBottom: 36,
              border: '.1em dashed',
            }}
          >
            <AddCircleIcon style={{ verticalAlign: 'middle' }} />
            <Typography
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                lineHeight: '48px',
              }}
            >
              Add new context
            </Typography>
          </div>
        </div>

        {/* Unavailable Clusters */}
        {fetchState === FetchStates.DONE && unreachedClusters.length ? (
          <div id={'unavailable-box'} style={{ padding: '16px 0px', marginBottom: '40px' }}>
            <Typography variant={'h6'}>Unavailable Contexts</Typography>
            {unreachedClusters.map(({ endpoint }, index) => (
              <DeadClusterListItem
                address={endpoint}
                key={`unreachable-cluster-${index}`}
                onDelete={() => deleteCluster(endpoint)}
              />
            ))}
          </div>
        ) : null}

        {/* "Add Cluster" modal. Initially hidden. */}
        <NewClusterModal
          addCluster={(address) => addNewCluster(address)}
          close={() => setDialogOpen(false)}
          open={dialogOpen}
        />
      </Container>
    </>
  );

  async function getContextClusters() {
    const addresses = [...localClusterEndpoints, ...remoteClusterEndpoints].map((endpoint) =>
      endpoint.replace(/\/api$/, ''),
    );
    const promises = addresses.map((endpoint) => fetchWithTimeout(`${endpoint}/model/clusterInfo`));
    const responses = await Promise.allSettled(promises);
    const endpointResponses = responses.map((response, i) => ({
      response,
      endpoint: addresses[i],
    }));

    const reached = endpointResponses.filter(({ response }) => response.status === 'fulfilled');
    const unreached = endpointResponses.filter(({ response }) => response.status === 'rejected');

    const reachedData = await Promise.all(reached.map((res) => res.response.value.json()));

    setReachedClusters(
      reached.map((r, i) => ({
        ...r,
        data: { ...reachedData[i].data, address: r.endpoint },
      })),
    );
    setUnreachedClusters(unreached);
    setFetchState(FetchStates.DONE);
  }

  function deleteCluster(address: string) {
    if (
      !confirm(`Are you sure you want to remove the cluster at ${address} from the Kubecost UI?`)
    ) {
      return;
    }
    removeContext(address);
  }

  function addNewCluster(newClusterAddress: string) {
    const isHttps = newClusterAddress.startsWith('https');
    const address =
      (isHttps ? 'https://' : 'http://') +
      sanitizeHTML(newClusterAddress.trim())
        .replace(/^https?:\/\//, '')
        .replace(/\/$/, '');

    if (localClusterEndpoints.includes(address)) {
      return alert(`This address (${address}) has already been added`);
    }

    addContext(address);
    setFetchState(FetchStates.LOADING);
    setDialogOpen(false);
  }
};

export default memo(ClusterList);
