import { Grid } from '@material-ui/core';

import { SavingsCard } from './SavingsCard';

interface SavingsDataCardProps {
  currency: string;
  onClickHref: string;
  savingsData: any;
}

export const SavingsCardGroup = ({ currency, onClickHref, savingsData }: SavingsDataCardProps) => (
  <Grid spacing={4} style={{ textAlign: 'center' }} container>
    <Grid xs={4} item>
      <SavingsCard
        currency={currency}
        title={'Monthly CPU Savings'}
        value={savingsData.monthlySavingsCPU}
      />
    </Grid>
    <Grid xs={4} item>
      <SavingsCard
        currency={currency}
        onClickHref={onClickHref}
        title={'Total Monthly Savings'}
        value={savingsData.monthlySavings}
      />
    </Grid>
    <Grid xs={4} item>
      <SavingsCard
        currency={currency}
        title={'Monthly RAM Savings'}
        value={savingsData.monthlySavingsRAM}
      />
    </Grid>
  </Grid>
);
