import { useReducer } from 'react';

export type HostedIdleCostAction = 'hide' | 'separateByCluster' | 'separateByNode';

type HostedIdleCostState = {
  idleBy: HostedIdleCostAction;
  idle: boolean;
  idleByNode: boolean;
};

const initialState: HostedIdleCostState = {
  idleBy: 'separateByCluster',
  idle: true,
  idleByNode: false,
};

const States = ['hide', 'separateByCluster', 'separateByNode'];
export const normalizeParam = (p: string) => (States.includes(p) ? p : 'separateByCluster');

const idleControlReducer = (
  state: HostedIdleCostState,
  action: HostedIdleCostAction,
): HostedIdleCostState => {
  switch (action) {
    case 'hide':
      return {
        ...state,
        idleBy: 'hide',
        idle: false,
        idleByNode: false,
      };
    case 'separateByCluster':
      return {
        ...state,
        idleBy: 'separateByCluster',
        idle: true,
        idleByNode: false,
      };
    case 'separateByNode':
      return {
        ...state,
        idleBy: 'separateByNode',
        idle: true,
        idleByNode: true,
      };
    default:
      return state;
  }
};

export const useHostedIdleViewControl = () => {
  const [idleState, dispatch] = useReducer(idleControlReducer, initialState);
  return { ...idleState, dispatch, initialState };
};
