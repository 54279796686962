import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useEffect, useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';

import { WithDataLoader } from '../../components/DataLoader';
import { toCurrency } from '../../services/format';
import { model as Model } from '../../services/model';
import { AssetSet } from '../../types/asset';

const useStyles = makeStyles({
  actionLink: {
    marginLeft: 'auto',
  },
  actionButton: {
    color: '#2196f3',
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
  },
  actionText: {
    marginLeft: 'auto',
    color: '#8c8c8c',
  },
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    marginBottom: 32,
  },
  totalRow: {
    fontWeight: 500,
  },
});

const CloudCard = () => {
  const classes = useStyles();

  const [promise, setPromise] = useState<Promise<unknown> | null>(null);
  const [currency, setCurrency] = useState('USD');
  const [data, setData] = useState<(string | number)[][]>([]);
  const [done, setDone] = useState(false);

  useEffect(() => {
    const p = fetchData();
    setPromise(p);
    p.then(() => setDone(true));
  }, []);

  if (done && !(data && data.length)) {
    return (
      <Card className={classes.root} style={{ backgroundColor: '#ececec', textAlign: 'center' }}>
        <CardHeader style={{ marginTop: '30%' }} title={'External cloud costs not configured'} />
        <CardContent>
          <Link component={RouteLink} to={'/settings#out-of-cluster-box'}>
            <Button color={'primary'} size={'large'} variant={'contained'}>
              Configure cloud integration
            </Button>
          </Link>
        </CardContent>
      </Card>
    );
  }
  const Content = () => (
    <>
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(([key, val]: Array<string | number>) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{toCurrency(val, currency, 2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions className={classes.actions}>
        <Link className={classes.actionLink} component={RouteLink} to={'/assets?agg=service'}>
          <Button className={classes.actionButton} endIcon={<KeyboardArrowRightIcon />}>
            Assets
          </Button>
        </Link>
      </CardActions>
    </>
  );
  const ContentWithDataLoading = WithDataLoader(Content);
  return (
    <Card className={classes.root}>
      <CardHeader
        subheader={'Top non-K8s cloud service expenditures over the last 7 days'}
        title={'Cloud Costs'}
      />
      <ContentWithDataLoading
        errorMessage={'Could not load cloud costs'}
        loadMessage={'Loading cloud costs'}
        promise={promise}
      />
    </Card>
  );

  async function fetchData() {
    const prom = Promise.all([
      Model.getAssets('7d', { accumulate: true, aggregate: 'service' }),
      Model.getConfigs(),
    ]);
    const [response, modelConfig] = await prom;
    const assetSet: AssetSet = response.data[0];
    delete assetSet.Kubernetes;
    const rows = Object.entries(assetSet).map(([key, asset]) => [key, asset.totalCost]);
    rows.sort((a, b) => (a[1] > b[1] ? -1 : 1));
    setData(rows.slice(0, 5));
    setCurrency(modelConfig.currencyCode || 'USD');
  }
};

export default CloudCard;
