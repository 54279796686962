import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import RadioIcon from '@material-ui/icons/Wifi';

import { Icon, Modal, Typography } from '@kubecost-frontend/holster';

import { useHosted } from '../hooks/useHosted';

export const AgentManager = () => {
  const [showAgentManager, setShowAgentManager] = useState(false);
  const { onlineAgents } = useHosted();

  return (
    <div
      className="mr-3 cursor-pointer"
      onClick={() => setShowAgentManager(true)}
    >
      {isEmpty(onlineAgents) ? <RadioIcon htmlColor={"red"}/> : <RadioIcon htmlColor={"green"}/>}
      <Modal
        open={showAgentManager}
        onClose={() => setShowAgentManager(false)}
        title="Agent Status"
      >
        <div className="flex gap-4 mt-4">
          {!isEmpty(onlineAgents) ? (
            <div>
              <Typography variant="p-large">
                We've detected agents for the following cluster(s):
              </Typography>
              {Object.keys(onlineAgents).map((key: string) => (
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: '8px' }}><Icon className="mt-2" variant="success" /></div>
                  <Typography variant="p-large">{key}</Typography>
                </div>
              ))}
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1, marginRight: '8px' }}><Icon className="mt-2" variant="danger" /></div>
              <Typography variant="p-large">
                You have no agent(s) reporting at the moment. Make sure your
                kubecost-cloud deployment is running in the kubecost-cloud namespace.
              </Typography>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
