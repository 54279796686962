import { FC } from 'react';

import { Button, Modal, Typography } from '@kubecost-frontend/holster';

interface Alert {
  aggregation: string;
  baselineWindow?: string;
  efficiencyThreshold?: number;
  filter: string;
  id?: string;
  ownerContact?: string[];
  relativeThreshold?: number;
  slackWebhookUrl?: string;
  spendThreshold?: number;
  threshold?: number;
  type: string;
  window: string;
}

interface ComponentProps {
  alert: Alert | null;
  close: () => void;
  remove: (alert: Alert) => Promise<void>;
}

const DeleteAlertModal: FC<ComponentProps> = ({ alert, close, remove }: ComponentProps) => {
  if (!alert) {
    return null;
  }

  function has(alert: Alert | null, prop: string) {
    return Object.prototype.hasOwnProperty.call(alert, prop);
  }

  return (
    <Modal data-test={'delete-alert-dialog'} onClose={close} title={'Deleting Alert'} open>
      <Typography style={{ marginBottom: 20 }} variant={'h6'}>
        Preparing to delete the following alert:
      </Typography>
      <Typography variant={'p'}>Type: {alert.type}</Typography>
      <Typography variant={'p'}>Window: {alert.window}</Typography>
      <Typography variant={'p'}>Aggregation: {alert.aggregation || '""'}</Typography>
      <Typography variant={'p'}>Filter: {alert.filter || '*'}</Typography>
      {has(alert, 'threshold') ? (
        <Typography variant={'p'}>Threshold: {alert.threshold || 'Not Set'}</Typography>
      ) : (
        <></>
      )}
      {has(alert, 'efficiencyThreshold') ? (
        <Typography variant={'p'}>
          Efficiency Threshold: {alert.efficiencyThreshold || 'Not Set'}
        </Typography>
      ) : (
        <></>
      )}
      {has(alert, 'spendThreshold') ? (
        <Typography variant={'p'}>Spend Threshold: {alert.spendThreshold || 'Not Set'}</Typography>
      ) : (
        <></>
      )}
      {has(alert, 'baselineWindow') ? (
        <Typography variant={'p'}>Baseline Window: {alert.baselineWindow || 'Not Set'}</Typography>
      ) : (
        <></>
      )}
      {has(alert, 'relativeThreshold') ? (
        <Typography variant={'p'}>
          Relative Threshold: {alert.relativeThreshold || 'Not Set'}
        </Typography>
      ) : (
        <></>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          data-test={'cancel-button'}
          onClick={close}
          style={{ marginRight: 16 }}
          variant={'default'}
        >
          Cancel
        </Button>
        <Button data-test={'confirm-button'} onClick={onDelete} variant={'primary'}>
          Confirm
        </Button>
      </div>
    </Modal>
  );

  async function onDelete() {
    if (alert) {
      await remove(alert);
    }
    close();
  }
};

export { DeleteAlertModal };
