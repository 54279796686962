import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';

import { colorMap } from '../../services/colors';

import { BarChartVisualAddOn } from './AreaChartVisualAddOn';
import {
  ReportConfig,
  VisualAddOn,
  VisualAddOnType,
  getDataFromDataSetGivenColumn,
} from './reportingHelpers';

const chartDataPoints = [
  {
    label: 'CPU',
    dataKey: 'allocation.cpuCost',
  },
  {
    label: 'GPU',
    dataKey: 'allocation.gpuCost',
  },
  {
    label: 'LB',
    dataKey: 'allocation.loadBalancerCost',
  },
  {
    label: 'Network',
    dataKey: 'allocation.networkCost',
  },
  {
    label: 'RAM',
    dataKey: 'allocation.ramCost',
  },
  {
    label: 'External',
    dataKey: 'assets.totalCost',
  },
];

const mainTextColor = '#4CAF50';
const subTextColor = '#BFBFBF';

// there should be a separate AddOn for display and one for managerSelection

export const AddOn = ({
  cardProperties,
  dataSet,
  selected,
  value,
}: {
  cardProperties: VisualAddOn;
  dataSet?: any;
  selected: boolean;
  value: string | number;
}) => {
  const data = dataSet
    ? chartDataPoints.map((item: any) => ({
        name: item.label,
        cost: getDataFromDataSetGivenColumn(dataSet, { dataKey: item.dataKey }),
      }))
    : [];

  return cardProperties.type === VisualAddOnType.Card ? (
    <Grid key={cardProperties.name} xs={3} item>
      <Card
        style={{
          cursor: 'pointer',
          borderStyle: selected ? 'solid' : '',
          borderColor: selected ? colorMap.blue : '',
          textAlign: 'center',
        }}
      >
        <CardContent>
          <Typography component={'h2'} style={{ color: mainTextColor }} variant={'h4'}>
            <strong>{value}</strong>
          </Typography>
          <Typography component={'h2'} style={{ color: subTextColor }} variant={'h5'}>
            {cardProperties.config.label}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ) : (
    <Grid key={cardProperties.name} xs={6} item>
      <Card
        style={{
          cursor: 'pointer',
          borderStyle: selected ? 'solid' : '',
          borderColor: selected ? colorMap.blue : '',
        }}
      >
        <CardContent style={{ height: 400, color: subTextColor }}>
          <BarChartVisualAddOn dataSet={data || []} />
        </CardContent>
      </Card>
    </Grid>
  );
};

export const AddOnManager = ({ reportConfig }: { reportConfig: ReportConfig }) => (
  <div style={{ marginTop: '2em' }}>
    <Button variant={'contained'} disabled>
      New Element
    </Button>
    <Grid spacing={4} style={{ padding: '2em' }} container>
      {reportConfig.visualAddOns!.map((item: VisualAddOn) => (
        <AddOn cardProperties={item} key={item.name} value={'$XXX.XK'} selected />
      ))}
    </Grid>
  </div>
);
