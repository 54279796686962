import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useState } from 'react';

import { AlertTypes, Alert, BudgetAlert } from '../../services/alerts';

interface BudgetAlertsTableProps {
  alerts: BudgetAlert[];
  createAlert: (alert: Alert) => void;
  defaults?: {
    aggregate?: string;
    filter?: string;
    threshold?: number;
    window?: string;
  };
  locked?: {
    aggregate?: string;
    filter?: string;
    threshold?: number;
    window?: string;
  };
  removeAlert: (alert: Alert) => void;
}

const BudgetAlertsTable = ({
  alerts,
  createAlert,
  removeAlert,
  defaults = {},
  locked = {},
}: BudgetAlertsTableProps) => {
  const [newWindow, setNewWindow] = useState('');
  const [newAgg, setNewAgg] = useState('');
  const [newFilter, setNewFilter] = useState('');
  const [newThreshold, setNewThreshold] = useState('');

  const handleAddAlert = () => {
    const newAlert = new BudgetAlert({
      type: AlertTypes.Budget,
      window: newWindow || locked.window,
      aggregation: newAgg || locked.aggregate,
      filter: newFilter || locked.filter,
      threshold:
        typeof locked.threshold === 'undefined' ? parseFloat(newThreshold) : locked.threshold,
    });
    createAlert(newAlert);
    setNewWindow(defaults.window || '');
    setNewAgg(defaults.aggregate || '');
    setNewFilter(defaults.filter || '');
    setNewThreshold(typeof defaults.threshold === 'undefined' ? '' : defaults.threshold.toString());
  };

  return (
    <TableContainer data-test={'alerts-table'}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Window</TableCell>
            <TableCell>Aggregation</TableCell>
            <TableCell>Filter</TableCell>
            <TableCell>Threshold</TableCell>
            <TableCell>Add/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alerts.map((a) => (
            <TableRow key={a.id}>
              <TableCell>{a.window}</TableCell>
              <TableCell>{a.aggregation}</TableCell>
              <TableCell>{a.filter}</TableCell>
              <TableCell>{a.threshold}</TableCell>
              <TableCell>
                <IconButton data-test={'alert-delete'} onClick={() => removeAlert(a)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell data-test={'new-alert-window'}>
              {locked.window || (
                <TextField onChange={(e) => setNewWindow(e.target.value)} value={newWindow} />
              )}
            </TableCell>
            <TableCell data-test={'new-alert-aggregate'}>
              {locked.aggregate || (
                <TextField onChange={(e) => setNewAgg(e.target.value)} value={newAgg} />
              )}
            </TableCell>
            <TableCell data-test={'new-alert-filter'}>
              {locked.filter || (
                <TextField onChange={(e) => setNewFilter(e.target.value)} value={newFilter} />
              )}
            </TableCell>
            <TableCell data-test={'new-alert-threshold'}>
              {typeof locked.threshold === 'undefined' ? (
                <TextField onChange={(e) => setNewThreshold(e.target.value)} value={newThreshold} />
              ) : (
                locked.threshold
              )}
            </TableCell>
            <TableCell>
              <IconButton data-test={'new-alert-add'} onClick={handleAddAlert}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BudgetAlertsTable.defaultProps = {
  defaults: {},
  locked: {},
};

export { BudgetAlertsTable };
