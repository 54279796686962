/*
 * Store state in browser URL parameters.
 * This hook is implemented as a generic. The supplied type is the type of the state object to be stored in the URL.
 * The hook must be given encoder/decoder functions which are used to read and write state to the URL.
 */

import { useSearchParams } from 'react-router-dom';

type Decoders<T> = { [Property in keyof T]: (arg: string) => T[Property] };
type Encoders<T> = { [Property in keyof T]: (arg: T[Property]) => string };
type OptProps<T> = { [Property in keyof T]?: T[Property] };

const useURLStore = <StateType extends object>(
  encoders: Encoders<StateType>,
  decoders: Decoders<StateType>,
  defaults: StateType,
) => {
  const [search, setSearch] = useSearchParams();

  // Handle writing state to the URL for type StateType.
  // If a given param for the new state is not set, unset it from the URL params.
  const setState = (newState: OptProps<StateType>) => {
    const newSearch = new URLSearchParams(search);
    Object.keys(newState).forEach((key) => {
      const value: StateType[keyof StateType] | undefined = newState[key as keyof StateType];
      if (typeof value === 'undefined') {
        newSearch.delete(key);
        return;
      }
      const encoder = encoders[key as keyof StateType] || String;
      const encodedValue = encoder(value);
      newSearch.set(key, encodedValue);
    });

    setSearch(newSearch);
  };

  // Determine state from search.
  const state = { ...defaults };
  Object.keys(defaults).forEach((key) => {
    const decode = decoders[key as keyof StateType];
    const value = search.get(key);
    if (value !== null) {
      state[key as keyof StateType] = decode(value);
    } else {
      state[key as keyof StateType] = defaults[key as keyof StateType];
    }
  });

  return { state, setState };
};

export { useURLStore };
