import { useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { CloseIcon } from '../assets/images';
import useBetaUI from '../hooks/useBetaUI';

const BetaUIBanner = () => {
  const [isDismissed, setIsDismissed] = useState<boolean>(false);
  const { isBetaUI } = useBetaUI();
  const { pathname } = useLocation();

  const isVisible = pathname !== '/settings' && !isBetaUI && !isDismissed;

  return isVisible ? (
    <header
      className={'relative mb-7 flex items-center justify-center bg-kc-primary p-2'}
      role={'banner'}
    >
      🎉 A new look is coming to Kubecost! Get a sneak peak today{' '}
      <Link className={'holster-button kc-button-primary ml-5 mr-9 shrink-0'} to={'/settings'}>
        Try it out
      </Link>
      <button
        className={'absolute right-0 h-full px-3'}
        onClick={() => setIsDismissed(true)}
        type={'button'}
      >
        <CloseIcon />
      </button>
    </header>
  ) : null;
};

export { BetaUIBanner };
