import { useMemo } from 'react';

import { Button, Typography } from '@material-ui/core';

import useProductTier from '../../../hooks/useProductTier';
import { ProductKey } from '../../../services/config';

export interface LicenseKeyDisplayProps {
  adjustLicenseKeyClicked: () => void;
  apiVersion: string;
  clientVersion: string;
  config: ProductKey;
  readOnly?: boolean;
  startFreeTrialClicked: () => void;
}

const LicenseKeyDisplay = ({
  adjustLicenseKeyClicked,
  apiVersion,
  clientVersion,
  config,
  readOnly = false,
  startFreeTrialClicked,
}: LicenseKeyDisplayProps) => {
  const isProductTier = useProductTier();
  const trialDaysRemaining = (seconds: number): number => {
    if (seconds <= 0) return 0;
    const daysRemaining = Math.round(seconds / (60 * 60 * 24));
    return daysRemaining;
  };

  const isTrialActive = useMemo(
    () => trialDaysRemaining(config.trialRemaining) > 0 && config.usedTrial === true,
    [config],
  );

  const isTrialExhausted = useMemo(
    () => trialDaysRemaining(config.trialRemaining) <= 0 && config.usedTrial === true,
    [config],
  );

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 200px',
      }}
    >
      <div>
        <Typography className={'pb-4 !font-[700]'} variant={'h5'}>
          Kubecost Info
        </Typography>
        <Typography style={{ marginBottom: '.5em', color: 'gray' }} variant={'body2'}>
          Version
          <br /> <strong>{clientVersion.toUpperCase()}</strong>
        </Typography>
        <Typography style={{ marginBottom: '.5em', color: 'gray' }} variant={'body2'}>
          API Version
          <br /> <strong>{apiVersion}</strong>
        </Typography>
        <Typography style={{ marginBottom: '.5em', color: 'gray' }} variant={'body2'}>
          License Version
          <br /> <strong>{config.productKey.tier.toUpperCase()}</strong>
        </Typography>

        {config.productKey.key !== '' && (
          <Typography style={{ marginBottom: '.5em', color: 'gray' }} variant={'body2'}>
            License Key
            <br /> <strong>{config.productKey.key.toUpperCase()}</strong>
          </Typography>
        )}
        {isTrialActive && isProductTier.Free && (
          <Typography style={{ marginBottom: '.5em', color: 'gray' }} variant={'body2'}>
            Trial Activated
            <br /> <strong>{trialDaysRemaining(config.trialRemaining)} Days Remaining</strong>
          </Typography>
        )}
        {isTrialExhausted && isProductTier.Free && (
          <Typography style={{ marginBottom: '.5em', color: 'gray' }} variant={'body2'}>
            Trial Exhausted
            <br /> <strong>Contact team@kubecost.com to get access!</strong>
          </Typography>
        )}
      </div>
      {readOnly === false && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {isProductTier.Free && isTrialActive !== true && isTrialExhausted === false && (
            <Button color={'primary'} onClick={startFreeTrialClicked} variant={'contained'}>
              Start Free Trial
            </Button>
          )}
          <Button onClick={adjustLicenseKeyClicked} variant={'contained'}>
            {!isProductTier.Free ? 'Change license key' : 'Add license key'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default LicenseKeyDisplay;
