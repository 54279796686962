import { FC } from 'react';

import { Tooltip, Typography } from '@kubecost-frontend/holster';

interface AssetListItemProps {
  icon: React.ReactElement;
  tooltip: string;
  value: string;
}

const AssetPropertiesItem: FC<AssetListItemProps> = ({
  icon,
  tooltip,
  value,
}: AssetListItemProps) => (
  <div className={'flex items-center'}>
    <Tooltip content={tooltip}>{icon}</Tooltip>
    <Typography className={'ml-3'} variant={'p'}>
      {value}
    </Typography>
  </div>
);

AssetPropertiesItem.displayName = 'AssetPropertiesItem';

export { AssetPropertiesItem };
