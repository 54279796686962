import { ProductTier as ProductTierType } from '../hooks/useProductTier';

import Logger from './logger';
import { model as ModelService } from './model';
import { getCurrentContainerAddressModel } from './util';

export interface ProductKey {
  productKey: Key;
  trialRemaining: number;
  usedTrial: boolean;
}

interface Key {
  endDate: number;
  key: string;
  remaining: number;
  startDate: number;
  tier: ProductTierType;
}

class ConfigService {
  data: Record<string, unknown>;

  productKey?: ProductKey;

  provider: string;

  urlRoot: string;

  validKeys: string[];

  constructor() {
    this.validKeys = [
      'all_containers',
      'cpu',
      'pcpu',
      'ram',
      'pram',
      'grafana',
      'contact_label',
      'team_label',
      'product_label',
      'environment_label',
      'department_label',
      'container',
      'discount',
      'spot_label',
      'spot_label_tag',
    ];
    this.data = {};
    this.provider = '';
    this.urlRoot = `http://${window.location.host}`;
  }

  async fetchConfigs() {
    if (Object.keys(this.data).length) {
      // data has already been loaded, so return it as a Promise
      return Promise.resolve(this.data);
    }
    if (window.localStorage.getItem('cpu') === null) {
      // no localStorage data, so fetch from the server and save
      return fetch('config.json')
        .then((response) => response.json())
        .then((data) => {
          const result = { ...data };
          if (
            window.location.href !== 'https://app.kubemonitor.com/' &&
            !window.location.href.startsWith('http://localhost')
          ) {
            const apiUrl = `${this.urlRoot}/api`;
            result.container = apiUrl;
            result.all_containers = apiUrl;
          }
          result.container = result.container.replace(/\/api$/, '');
          result.all_containers = result.all_containers
            .split(',')
            .map((c: string) => c.replace(/\/api$/, ''))
            .join(',');
          Object.entries(result).forEach(([key, value]) => {
            this.setConfig(key, value as string);
          });
          this.data = result;
          return this.data;
        });
    }
    // load data from localStorage
    this.validKeys.forEach((key) => {
      this.data[key] = window.localStorage.getItem(key);
    });
    return Promise.resolve(this.data);
  }

  async getConfig() {
    // return cached data
    if (Object.keys(this.data).length) {
      return this.data;
    }
    return this.fetchConfigs();
  }

  async getCurrency() {
    const config = await this.getConfig();
    let currency = 'USD';
    if (config && config.currencyCode && typeof config.currencyCode === 'string') {
      currency = config.currencyCode;
    }
    return currency;
  }

  async getProvider() {
    if (this.provider) {
      return this.provider;
    }
    return this.fetchProvider();
  }

  async fetchProvider() {
    try {
      const clusterInfo = await ModelService.clusterInfo();
      if (!clusterInfo.provider) {
        throw new Error('No provider defined for cluster.');
      }
      this.provider = clusterInfo.provider;
      return this.provider;
    } catch (err) {
      Logger.warn('Unable to determine provider. Assuming GCP.');
      this.provider = 'GCP';
      return this.provider;
    }
  }

  setConfig(key: string, value: string) {
    this.data[key] = value;
    window.localStorage.setItem(key, value);
  }

  setUrlRoot(urlRoot: string) {
    this.urlRoot = urlRoot;
  }

  async getProductKey() {
    if (this.productKey) return this.productKey;
    return this.fetchProductKey();
  }

  async fetchProductKey(): Promise<ProductKey | undefined> {
    const url = `${getCurrentContainerAddressModel()}/getProductKey?&req=${new Date().valueOf()}`;
    const response = await fetch(url);
    const productKeyData = await response.json();
    this.productKey = productKeyData;
    return this.productKey;
  }

  async setProductKey(key: ProductKey) {
    const url = `${getCurrentContainerAddressModel()}/setProductKey`;
    const startDate = new Date().getTime();
    const endDate = 1000 * 60 * 60 * 24 * 365 + startDate;
    const requestData = { key: key.productKey.key, startDate, endDate };
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });
    this.productKey = key;
    return response;
  }

  async startTrial() {
    const resp = await ModelService.post<{
      productKey: { key: string };
      usedTrial: boolean;
    }>('/startProductTrial');

    // If, after starting the trial, the trial is not in use, interpret that
    // as an error and throw.
    if (!resp.usedTrial) {
      throw new Error('Failed to start product trial');
    }
    return resp;
  }
}

export default new ConfigService();
