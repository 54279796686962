import { FC } from 'react';

import Grid from '@material-ui/core/Grid';

import { Typography } from '@kubecost-frontend/holster';

interface ApplyMessagesProps {
  data: string[];
  header: string;
}

export const ApplyMessages: FC<ApplyMessagesProps> = ({ data, header }) => {
  const listItems = data.map((datum, i) => (
    <li
      key={i}
      style={{
        listStyle: 'disc',
      }}
    >
      <Typography variant={'h6'}>{datum}</Typography>
    </li>
  ));
  const dataDisplay = <ul>{listItems}</ul>;

  return (
    <Grid container>
      <Grid xs={12} item>
        <Typography variant={'h5'}>{header}</Typography>
      </Grid>
      <Grid item>{dataDisplay}</Grid>
    </Grid>
  );
};

ApplyMessages.displayName = 'ApplyMessages';
