import { FC, useEffect, useState } from 'react';

import * as d3 from 'd3';

// d3

import { useClusters } from '../../../contexts/ClusterConfig';
import { toCurrency } from '../../../services/format';
import { TableResults, TopResultsGraph } from '../../../services/model';

// get color in gradient from ratio between 0.0 and 1.0 i.e. map efficiency to color
function getGradientColor(value: number) {
  const eff = value > 1.0 ? 1.0 : value;

  const oneColor = '66bb6a'; // green for 1.0 i.e. 100% efficiency
  const zeroColor = 'e53935'; // red for 0.0 i.e. 0% efficiency

  const hex = (d: number) => (d.toString(16).length === 1 ? `0${d.toString(16)}` : d.toString(16));
  const rgbToHex = (rgbArray: number[]) => rgbArray.map(hex);

  const rgb = rgbToHex([
    Math.ceil(
      parseInt(oneColor.substring(0, 2), 16) * eff +
        parseInt(zeroColor.substring(0, 2), 16) * (1 - eff),
    ),
    Math.ceil(
      parseInt(oneColor.substring(2, 4), 16) * eff +
        parseInt(zeroColor.substring(2, 4), 16) * (1 - eff),
    ),
    Math.ceil(
      parseInt(oneColor.substring(4, 6), 16) * eff +
        parseInt(zeroColor.substring(4, 6), 16) * (1 - eff),
    ),
  ]);
  return `#${rgb.reduce((a, b) => a + b).toString()}`;
}

interface HierarchyData {
  color: string;
  efficiency: number;
  id: number;
  name: string;
  root: string;
  value: number;
}

// D3 treemap takes a data hierarchy (in this case created by d3.stratify()). This function
// formats the recharts data into a list that is compatible with stratification into a
// single-level hierarchy
function toHierarchyData(d: { cost: number; efficiency: number; name: string }[]): HierarchyData[] {
  // data is tree hierarchy of all allocation as leaves to one root
  // Note: if categories are added, more parent branches can be added to draw these
  const data = [{ name: 'root', value: 0, efficiency: 0, root: '', id: -1, color: '' }];

  let id = 0;

  d.forEach(({ cost, efficiency, name }) => {
    data.push({
      name,
      value: cost,
      efficiency,
      root: 'root',
      id,
      color: getGradientColor(efficiency),
    });
    id += 1;
  });

  return data;
}

interface TreeMapChartProps {
  allocationRows: TableResults[];
  canDrillDown: boolean;
  data: TopResultsGraph;
  drillDownExemptRows: string[];
  drillDownFactory: (row: TableResults) => () => void;
  height: number;
}

const TreemapChart: FC<TreeMapChartProps> = ({
  allocationRows,
  canDrillDown,
  data,
  drillDownExemptRows,
  drillDownFactory,
  height,
}) => {
  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const { modelConfig } = useClusters();

  // stratify allocation data for d3 treemap
  const hData = toHierarchyData(data.items);

  const root = d3
    .stratify<HierarchyData>()
    .id((d) => d.name)
    .parentId((d) => d.root)(hData)
    .sum((d) => d.value);

  useEffect(() => {
    function renderTreemap() {
      setWindowDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    const el = document.getElementById('treemapContainer');

    if (el) {
      drawChart(
        el.offsetWidth,
        height,
        root,
        15,
        allocationRows,
        canDrillDown,
        drillDownExemptRows,
      ); // offsetwidth because chart is dynamically sized by page
      // redraw treemap on window resize
      window.addEventListener('resize', renderTreemap);
      return () => window.removeEventListener('resize', renderTreemap);
    }
  }, [windowDimensions]);

  return (
    // bounding padding div
    <div id={'treemapChart'} style={{ marginLeft: '17px', marginRight: '17px' }}>
      <div id={'treemapContainer'} style={{ width: '100%' }} />
    </div>
  );

  // draw treemap SVG from hierarchy root
  function drawChart(
    width: number,
    height: number,
    root: d3.HierarchyNode<HierarchyData>,
    fontsize: number,
    allocationRows: TableResults[],
    canDrillDown: boolean,
    drillDownExemptRows: string[],
  ) {
    const treemapContainer = d3.select('#treemapContainer');
    treemapContainer.select('svg').remove();
    const treemapSVG = treemapContainer.append('svg');
    d3.treemap().size([width, height]).padding(7)(root);

    // SVG text doesn't wrap, so this stopgap just approximates
    // a truncation manually. Relies on some assumptions based on
    // fixed font.
    // TODO: make this better and/or implement text wrapping
    const fitFontsizeToRect = (
      width: number,
      text: string,
      fontSize: number,
      rectHeight: number,
    ) => {
      // If the text won't fit even if truncated, no text
      // if rect is vertically too small, it won't show anyway
      let res = rectHeight > fontsize ? text : '';

      if (res === '') {
        return res;
      }

      // Truncate string until it fits
      while (res.length * fontSize > width) {
        res = res.slice(0, -1);
      }

      return res === text ? res : `${res}...`;
    };

    const isDrillDownCompatible = function (name: string) {
      return canDrillDown && !drillDownExemptRows.includes(name) && name != 'other';
    };

    // Because drilldown is tied to rows, and rows are already
    // compiled by the AllocationReport component, we just find
    // the corresponding row and drill down into it.
    const clickRow = function (name: string) {
      if (!isDrillDownCompatible(name)) {
        return;
      }

      console.log(allocationRows);
      const drillDownItem = allocationRows.filter((item) => item.name === name)[0];

      const drillDown = drillDownFactory(drillDownItem);

      drillDown();
    };

    const maxTooltipLength = 600;

    // size treemap SVG
    treemapSVG.attr('width', '100%').attr('height', height);

    // draw treemap rects
    treemapSVG
      .selectAll('rect')
      .data(root.leaves())
      .join('rect')
      .attr('x', (d) => d.x0)
      .attr('y', height)
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
      .attr('class', 'treemap-cell')
      .style('fill', (d) => d.data.color)
      .transition('cellTransition')
      .duration('200')
      .attr('y', (d) => d.y0);

    // draw labels for rects
    treemapSVG
      .selectAll('.labels')
      .data(root.leaves())
      .enter()
      .append('text')
      .attr('x', (d) => d.x0 + 2)
      .attr('y', 0)
      .text((d) => fitFontsizeToRect(d.x1 - d.x0, d.data.name, fontsize, d.y1 - d.y0))
      .attr('id', (d) => `treemap-label-${d.data.id}`)
      .attr('font-size', fontsize)
      .attr('fill', 'white')
      .attr('font', 'Roboto')
      .attr('pointer-events', 'none')
      .transition('cellTransition')
      .duration('200')
      .attr('y', (d) => d.y0 + 15);

    // create tooltip svgs
    // this should theoretically allow arbitrary tooltips e.g. additional visualizations
    const tooltipSVG = treemapSVG
      .selectAll('.treemap-cell-tooltip')
      .data(root.leaves())
      .enter()
      .append('svg');

    // tooltip text
    tooltipSVG
      .append('text')
      .data(root.leaves())
      .attr('id', (d) => `treemap-cell-tooltip-text-${d.data.id}`)
      .attr('x', (d) => d.x0)
      .attr('y', (d) => d.y0)
      .attr('class', 'tooltip-text')
      .text((d) => d.data.name)
      .attr('font', 'Roboto')
      .attr('pointer-events', 'none')
      .attr('font-size', fontsize)
      .style('opacity', 0);

    // efficiency text
    tooltipSVG
      .append('text')
      .data(root.leaves())
      .attr('id', (d) => `treemap-cell-tooltip-eff-${d.data.id}`)
      .attr('x', (d) => d.x0)
      .attr('y', (d) => d.y0)
      .attr('class', 'tooltip-eff')
      .text((d) =>
        d.data.efficiency > 0
          ? `Efficiency: ${Math.round(d.data.efficiency * 1000) / 10}%`
          : 'Cannot drill down',
      )
      .attr('font', 'Roboto')
      .attr('fill', (d) => d.data.color)
      .attr('pointer-events', 'none')
      .attr('font-size', fontsize)
      .style('opacity', 0);

    // cost text
    tooltipSVG
      .append('text')
      .data(root.leaves())
      .attr('id', (d) => `treemap-cell-tooltip-cost-${d.data.id}`)
      .attr('x', (d) => d.x0)
      .attr('y', (d) => d.y0)
      .attr('class', 'tooltip-cost')
      .text((d) => `Cost: ${toCurrency(d.data.value, modelConfig.currencyCode)}`)
      .attr('font', 'Roboto')
      .attr('pointer-events', 'none')
      .attr('font-size', fontsize)
      .style('opacity', 0);

    // tooltip boxes
    // must be appended after text to size to text
    tooltipSVG
      .append('rect')
      .data(root.leaves())
      .attr('id', (d) => `treemap-cell-tooltip-bg-${d.data.id}`)
      .attr('x', (d) => d.x0)
      .attr('y', (d) => d.y0)
      .attr('width', (d) => {
        // TODO: implement better scaling? svg doesn't support text-wrapping

        // scale tooltip svg background bounding box by text

        const text = treemapSVG.select(`#treemap-cell-tooltip-text-${d.data.id}`);
        const eff = treemapSVG.select(`#treemap-cell-tooltip-eff-${d.data.id}`);
        const cost = treemapSVG.select(`#treemap-cell-tooltip-eff-${d.data.id}`);

        let tooltipLength = d3.max([
          text.node().getComputedTextLength(),
          eff.node().getComputedTextLength(),
          cost.node().getComputedTextLength(),
        ]);

        tooltipLength += 10;
        return tooltipLength > maxTooltipLength ? maxTooltipLength : tooltipLength;
      })
      .attr('height', 80)
      .style('fill', '#f3f3f3')
      .attr('pointer-events', 'none')
      .style('opacity', 0);

    // define mouse pointer behavior (mouseover, click etc.)
    treemapSVG
      .selectAll('.treemap-cell')
      .on('mouseover', function (d, i) {
        // when mouseover, set opaque tooltip elements and move to pointer

        const cell = d3.select(this);

        if (isDrillDownCompatible(i.data.name)) {
          cell.transition().duration('50').attr('opacity', '.90');

          cell.style('cursor', 'pointer');
        }

        const bg = treemapSVG.select(`#treemap-cell-tooltip-bg-${i.data.id}`);
        const text = treemapSVG.select(`#treemap-cell-tooltip-text-${i.data.id}`);
        const eff = treemapSVG.select(`#treemap-cell-tooltip-eff-${i.data.id}`);
        const cost = treemapSVG.select(`#treemap-cell-tooltip-cost-${i.data.id}`);

        bg.style('opacity', 1)
          .attr('x', d.layerX - bg.node().getBBox().width / 2)
          .attr('y', d.layerY - 80 + 2);

        text
          .style('opacity', 1)
          .attr('x', d.layerX + 5 - bg.node().getBBox().width / 2)
          .attr('y', d.layerY - 60 + 2)
          .raise();

        eff
          .style('opacity', 1)
          .attr('x', d.layerX + 5 - bg.node().getBBox().width / 2)
          .attr('y', d.layerY - 10 + 2)
          .raise();

        cost
          .style('opacity', 1)
          .attr('x', d.layerX + 5 - bg.node().getBBox().width / 2)
          .attr('y', d.layerY - 35 + 2)
          .raise();
      })

      .on('mousemove', (d, i) => {
        // on move of mouse after mouseover, move tooltip with pointer within bounds of total svg

        const bg = treemapSVG.select(`#treemap-cell-tooltip-bg-${i.data.id}`);
        const text = treemapSVG.select(`#treemap-cell-tooltip-text-${i.data.id}`);
        const eff = treemapSVG.select(`#treemap-cell-tooltip-eff-${i.data.id}`);
        const cost = treemapSVG.select(`#treemap-cell-tooltip-cost-${i.data.id}`);

        // due to lack to text wrapping, tooltip bounding box width is variable
        const tooltipWidth = bg.node().getBBox().width;

        bg.attr('x', d.layerX - tooltipWidth / 2).attr('y', d.layerY - 80 + 2);

        text.attr('x', d.layerX + 5 - tooltipWidth / 2).attr('y', d.layerY - 60 + 2);

        eff.attr('x', d.layerX + 5 - tooltipWidth / 2).attr('y', d.layerY - 10 + 2);

        cost.attr('x', d.layerX + 5 - tooltipWidth / 2).attr('y', d.layerY - 35 + 2);

        // prevent tooltip from exceeding right boundary of total svg
        if (parseInt(bg.attr('x')) + tooltipWidth > width) {
          // right side of tooltip is x + width
          bg.attr('x', width - tooltipWidth);
          text.attr('x', width + 5 - tooltipWidth);
          eff.attr('x', width + 5 - tooltipWidth);
          cost.attr('x', width + 5 - tooltipWidth);
        }

        // prevent tooltip from exceeding left boundary of total svg
        if (parseInt(bg.attr('x')) < 0) {
          // left side is just x
          bg.attr('x', 0);
          text.attr('x', 0 + 5);
          eff.attr('x', 0 + 5);
          cost.attr('x', 0 + 5);
        }

        // prevent tooltip from exceeding top of total svg
        if (parseInt(bg.attr('y')) < 0) {
          bg.attr('y', 0);
          text.attr('y', 0 + 20);
          eff.attr('y', 0 + 70);
          cost.attr('y', 0 + 45);
        }

        // tooltip renders above mouse pointer, so no need to check if it's beyond the
        // bottom boundary of the total svg
      })

      .on('mouseout', function (d, i) {
        // hide tooltip elements when cell is no longer moused over

        const cell = d3.select(this);

        cell.transition().duration('50').attr('opacity', '1');

        cell.style('cursor', 'default');

        treemapSVG.select(`#treemap-cell-tooltip-bg-${i.data.id}`).style('opacity', 0);

        treemapSVG.select(`#treemap-cell-tooltip-text-${i.data.id}`).style('opacity', 0);

        treemapSVG.select(`#treemap-cell-tooltip-eff-${i.data.id}`).style('opacity', 0);

        treemapSVG.select(`#treemap-cell-tooltip-cost-${i.data.id}`).style('opacity', 0);
      })

      .on('click', (d, i) => {
        // drillDown into corresponding row
        clickRow(i.data.name);
      });
  }
};

export default TreemapChart;
