import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { RequestQueueStatus } from '../../services/diagnostics';

type RequestQueueProps = {
  requestQueue: RequestQueueStatus;
};

const RequestQueue = ({ requestQueue }: RequestQueueProps) => {
  const { maxQueryConcurrency, outboundRequests, totalRequests } = requestQueue;
  return (
    <>
      <Typography variant={'body2'}>
        <b>Total Requests</b>
      </Typography>
      <Typography color={'textSecondary'} variant={'body2'}>
        {totalRequests}
      </Typography>
      <Box p={1} />
      <Typography variant={'body2'}>
        <b>Outbound Requests</b>
      </Typography>
      <Typography color={'textSecondary'} variant={'body2'}>
        {outboundRequests}
      </Typography>
      <Box p={1} />
      <Typography variant={'body2'}>
        <b>Max Query Concurrency</b>
      </Typography>
      <Typography color={'textSecondary'} variant={'body2'}>
        {maxQueryConcurrency}
      </Typography>
    </>
  );
};

export default RequestQueue;
