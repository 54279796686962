import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/styles';
import isArray from 'lodash/isArray';
import upperFirst from 'lodash/upperFirst';
import { memo } from 'react';

import { toVerboseTimeRange } from '../../services/format';

const useStyles = makeStyles(() => ({
  root: {
    '& > * + *': {
      marginTop: 2,
    },
  },
  link: {
    cursor: 'pointer',
  },
}));

const handleBreadcrumbClick = (i, cb) => (e) => {
  e.preventDefault();
  cb(i);
};

const Subtitle = ({ aggregateBy, clearContext, context, goToContext, window }) => {
  const classes = useStyles();

  if (!isArray(context) || context.length === 0) {
    return (
      <div className={classes.root}>
        <Breadcrumbs aria-label={'breadcrumb'} separator={<NavigateNextIcon fontSize={'small'} />}>
          {aggregateBy && aggregateBy.length > 0 ? (
            <Typography>
              {toVerboseTimeRange(window)} by {aggregateBy.map(upperFirst).join('/')}
            </Typography>
          ) : (
            <Typography>{toVerboseTimeRange(window)}</Typography>
          )}
        </Breadcrumbs>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label={'breadcrumb'} separator={<NavigateNextIcon fontSize={'small'} />}>
        <Link className={classes.link} color={'inherit'} onClick={() => clearContext()}>
          <Typography>
            {toVerboseTimeRange(window)} by {context.map((c) => c.property).join('/')}
          </Typography>
        </Link>
        {context.map((ctx, c) =>
          c === context.length - 1 ? (
            <Typography key={c}>{ctx.name}</Typography>
          ) : (
            <Link
              className={classes.link}
              color={'inherit'}
              key={c}
              onClick={handleBreadcrumbClick(c, goToContext)}
            >
              <Typography>{ctx.name}</Typography>
            </Link>
          ),
        )}
      </Breadcrumbs>
    </div>
  );
};

export default memo(Subtitle);
