// react
import React, { useState } from 'react';

// holster
import { Chip, Input } from '@kubecost-frontend/holster';

function InviteEmailRecipients({
  addItem,
  recipients,
  removeItem,
}: ComponentProps): React.ReactElement {
  const [text, setText] = useState('');

  return (
    <>
      <Input
        data-test="emailRecipientInput"
        helperText="List of email recipients. Press Enter after typing each address."
        label="Email address"
        placeholder="user@domain.tld"
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyPress={(e) => {
          var trimText = text.trim()
          if (e.key.toLowerCase() === 'enter' && trimText.length > 0) {
            addItem(text);
            setText('');
          }
        }}
        style={{ width: '100%' }}
      />
      {recipients.map((r, i) => (
        <Chip
          color="default"
          key={r}
          label={r}
          onDelete={() => removeItem(i)}
          data-test="emailRecipientsList"
          style={{ marginTop: 6 }}
        />
      ))}
    </>
  );
}

export default InviteEmailRecipients;

interface ComponentProps {
  recipients: string[];
  addItem: (item: string) => void;
  removeItem: (item: number) => void;
}
