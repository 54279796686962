import { useState } from 'react';

import get from 'lodash/get';

import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { toCurrency } from '../../services/format';

import {
  ReportConfig,
  ReportConfigItem,
  handleActionColumn,
  handleValueFromString,
} from './reportingHelpers';

interface AssetBreakdownMapObjectItem {
  columnName: string;
  dataKey: string;
  defaultValue: string;
}

interface AssetBreakdownMapObject {
  account: AssetBreakdownMapObjectItem[];
  category: AssetBreakdownMapObjectItem[];
  cluster: AssetBreakdownMapObjectItem[];
  provider: AssetBreakdownMapObjectItem[];
  providerid: AssetBreakdownMapObjectItem[];
  service: AssetBreakdownMapObjectItem[];
  type: AssetBreakdownMapObjectItem[];
}

const baseDataSet: AssetBreakdownMapObjectItem[] = [
  {
    columnName: 'Credits',
    dataKey: 'credit',
    defaultValue: '0.00$',
  },
  {
    columnName: 'Adjusted',
    dataKey: 'adjustment',
    defaultValue: '0.00$',
  },
  {
    columnName: 'Total Cost ($)',
    dataKey: 'totalCost',
    defaultValue: '0.00$',
  },
];

const assetAggMapForBreakdownTable: AssetBreakdownMapObject = {
  category: [
    {
      columnName: 'Category',
      dataKey: 'properties.category',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
  cluster: [
    {
      columnName: 'Cluster Name',
      dataKey: 'properties.cluster',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
  service: [
    {
      columnName: 'Service',
      dataKey: 'properties.service',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
  type: [
    {
      columnName: 'Type',
      dataKey: 'properties.type',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
  provider: [
    {
      columnName: 'Provider',
      dataKey: 'properties.provider',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
  account: [
    {
      columnName: 'Account',
      dataKey: 'properties.account',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
  providerid: [
    {
      columnName: 'Provider',
      dataKey: 'properties.providerID',
      defaultValue: '-',
    },
    ...baseDataSet,
  ],
};

export const CollapsibleRow = ({
  item,
  reportConfig,
}: {
  item: any;
  reportConfig: ReportConfig;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const tableDisplay: AssetBreakdownMapObjectItem[] =
    assetAggMapForBreakdownTable[`${reportConfig.assetAggregateBy}`];
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            onClick={() => setIsOpen(!isOpen)}
            size={'small'}
            style={{ borderStyle: 'solid', borderWidth: 1 }}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        {reportConfig.dataMap.map((reportItem: ReportConfigItem) => (
          <TableCell key={reportItem.columnName} style={{ color: '#28B359' }}>
            {reportItem.action
              ? handleActionColumn(item, reportItem)
              : handleValueFromString(item, reportItem.dataKey)}
            {}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell colSpan={12} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={isOpen} timeout={'auto'} unmountOnExit>
            <Box margin={1} style={{ padding: '1em' }}>
              <Typography
                component={'div'}
                style={{ marginBottom: '1em' }}
                variant={'body1'}
                gutterBottom
              >
                <strong>
                  {item.allocation.name === '__undefined__'
                    ? '__unallocated__'
                    : item.allocation.name}
                </strong>{' '}
                Asset Breakdown by <strong>{reportConfig.assetAggregateBy}</strong>
              </Typography>
              <TableContainer component={Paper}>
                <Table aria-label={'purchases'} size={'medium'}>
                  <TableHead>
                    <TableRow>
                      {tableDisplay.map((item: AssetBreakdownMapObjectItem, idx: number) => (
                        <TableCell key={item.columnName} width={idx === 0 ? '70%' : '10%'}>
                          {item.columnName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.assets.entries.map((assetEntry: any, idx: number) => (
                      <TableRow key={idx}>
                        {tableDisplay.map((item: AssetBreakdownMapObjectItem) => {
                          const columnValue = get(assetEntry, item.dataKey);
                          return (
                            <TableCell key={item.dataKey}>
                              {typeof columnValue === 'string'
                                ? columnValue
                                : toCurrency(columnValue, 'USD') || item.defaultValue}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
