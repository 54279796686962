import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import PropTypes from 'prop-types';
import { memo } from 'react';

const OpenControl = ({ open, reports, selectReport, setOpen }) => {
  console.log(`open: ${open}`);
  if (!open) {
    return <></>;
  }
  return (
    <>
      <Tooltip title={'Open saved report'}>
        <IconButton onClick={() => setOpen(true)}>
          <FolderOpenIcon />
        </IconButton>
      </Tooltip>

      <Dialog maxWidth={'xs'} onClose={() => setOpen(false)} open={open} fullWidth>
        <DialogTitle>Open Report</DialogTitle>
        <DialogContent>
          {!reports.length ? (
            'Saved reports will be listed here. Click the "Save report" button to save the current report.'
          ) : (
            <List>
              {reports.map((report) => (
                <ListItem
                  key={report.key}
                  onClick={() => {
                    selectReport(report);
                    setOpen(false);
                  }}
                  button
                >
                  <ListItemIcon>
                    <BookmarkIcon />
                  </ListItemIcon>
                  <ListItemText primary={report.title} />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

OpenControl.defaultProps = {
  reports: [],
  selectReport: () => {},
};

OpenControl.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  selectReport: PropTypes.func,
};

export default memo(OpenControl);
