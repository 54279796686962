import { FC } from 'react';

import { upperFirst } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import AllocationIcon from '@material-ui/icons/AssessmentOutlined';
import AssetsIcon from '@material-ui/icons/DeveloperBoard';

import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Tooltip,
  Typography,
} from '@kubecost-frontend/holster';

import { toDisplayDateRange } from '../../services/format';
import { AdvancedReport } from '../../types/advancedReport';
import { AllocationReport } from '../../types/allocation';
import { AssetReport } from '../../types/asset';

import { ReportActionMenu } from './ReportActionMenu';

interface ReportsTableProps {
  advancedReports: AdvancedReport[];
  allocationReports: AllocationReport[];
  assetReports: AssetReport[];
  handleDeleteAdvancedReport: (report: AdvancedReport) => void;
  handleDeleteAllocationReport: (report: AllocationReport) => void;
  handleDeleteAssetReport: (report: AssetReport) => void;
  readOnly: boolean;
  searchTerm: string;
}

export const ReportsTable: FC<ReportsTableProps> = ({
  advancedReports,
  allocationReports,
  assetReports,
  handleDeleteAdvancedReport,
  handleDeleteAllocationReport,
  handleDeleteAssetReport,
  readOnly,
  searchTerm,
}) => {
  const navigate = useNavigate();

  const getAllocationReportLink = (report: AllocationReport) => {
    const searchParams = new URLSearchParams();
    searchParams.set('title', report.title);
    searchParams.set('window', report.window);
    searchParams.set('agg', report.aggregateBy.join(','));
    searchParams.set('idle', report.idle);
    searchParams.set('rate', report.rate);
    searchParams.set('chartDisplay', report.chartDisplay);
    searchParams.set('filters', btoa(JSON.stringify(report.filters)));

    if (report.sharedNamespaces != null) {
      searchParams.set('sharedNamespaces', report.sharedNamespaces.join(','));
    }

    if (report.sharedOverhead != null) {
      searchParams.set('sharedOverhead', report.sharedOverhead.toString());
    }

    if (report.sharedLabels != null) {
      searchParams.set('sharedLabels', report.sharedLabels.toString());
    }

    return `/allocations?${searchParams.toString()}`;
  };

  const getAssetReportLink = (report: AssetReport) => {
    const searchParams = new URLSearchParams();
    searchParams.set('title', report.title);
    searchParams.set('window', report.window);
    searchParams.set('agg', report.aggregateBy);
    searchParams.set('acc', report.accumulate ? 'true' : 'false');
    searchParams.set('filters', btoa(JSON.stringify(report.filters)));

    return `/assets?${searchParams.toString()}`;
  };

  const getAdvancedReportLink = (report: AdvancedReport) => {
    const searchParams = new URLSearchParams();
    searchParams.set('id', report.id || 'none');
    searchParams.set('cloudJoinLabel', report.cloudJoin);
    searchParams.set('breakdown', report.cloudBreakdown);
    searchParams.set('title', report.title);
    searchParams.set('window', report.window);
    searchParams.set('agg', report.aggregateBy);
    searchParams.set('idle', report.idle);
    searchParams.set('rate', report.rate);
    searchParams.set('chartDisplay', report.chartDisplay);
    searchParams.set('filters', btoa(JSON.stringify(report.filters)));

    if (report.sharedNamespaces != null) {
      searchParams.set('sharedNamespaces', report.sharedNamespaces.join(','));
    }

    if (report.sharedOverhead != null) {
      searchParams.set('sharedOverhead', report.sharedOverhead.toString());
    }

    if (report.sharedLabels != null) {
      searchParams.set('sharedLabels', report.sharedLabels.toString());
    }

    return `/advanced-reporting?${searchParams.toString()}`;
  };

  const goToAllocationReport = (report: AllocationReport) => {
    navigate(getAllocationReportLink(report));
  };

  const goToAssetReport = (report: AssetReport) => {
    navigate(getAssetReportLink(report));
  };

  const goToAdvancedReport = (report: AdvancedReport) => {
    navigate(getAdvancedReportLink(report));
  };

  const handleGoToReports = (report: AllocationReport | AssetReport | AdvancedReport) => {
    if ('cloudBreakdown' in report) {
      goToAdvancedReport(report);
    } else if ('idle' in report) {
      goToAllocationReport(report);
    } else {
      goToAssetReport(report);
    }
  };

  const handleDeleteReports = (report: AllocationReport | AssetReport | AdvancedReport) => {
    if ('cloudBreakdown' in report) {
      handleDeleteAdvancedReport(report);
    } else if ('idle' in report) {
      handleDeleteAllocationReport(report);
    } else {
      handleDeleteAssetReport(report);
    }
  };

  const handleReportType = (report: AllocationReport | AssetReport | AdvancedReport) => {
    if ('cloudBreakdown' in report) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <AllocationIcon style={{ marginRight: '.5rem' }} />
          <Typography variant={'p'}>Advanced Report</Typography>
        </div>
      );
    }
    if ('idle' in report) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <AllocationIcon style={{ marginRight: '.5rem' }} />
          <Typography variant={'p'}>Allocation</Typography>
        </div>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <AssetsIcon style={{ marginRight: '.5rem' }} />
        <Typography variant={'p'}>Asset</Typography>
      </div>
    );
  };

  const handleTooltipType = (report: AllocationReport | AssetReport | AdvancedReport) => {
    if ('cloudBreakdown' in report) {
      return 'Advanced Report';
    }
    if ('idle' in report) {
      return 'Allocation';
    }
    return 'Asset';
  };

  const getIdleText = (idle: string): string => {
    const sharing = idle.startsWith('share');
    const shareBy = idle === 'shareByNode' ? 'node' : 'cluster';
    return sharing ? `Sharing idle by ${shareBy}` : 'Not sharing idle';
  };

  const merged = [...allocationReports, ...assetReports, ...advancedReports];

  const tableReports = merged;

  return (
    <>
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableHeadCell align={'left'}>Reports</TableHeadCell>
            <TableHeadCell>Type</TableHeadCell>
            <TableHeadCell align={'left'}>Window</TableHeadCell>
            <TableHeadCell align={'left'}>Aggregate By</TableHeadCell>
            <TableHeadCell align={'left'}>Idle</TableHeadCell>
            <TableHeadCell align={'left'}>Actions</TableHeadCell>
          </TableRow>
        </TableHead>
        {tableReports
          .filter(
            (report) =>
              !searchTerm || report.title.toLowerCase().includes(searchTerm.toLowerCase()),
          )
          .map((report: AllocationReport | AssetReport | AdvancedReport) => (
            <TableBody key={uuidv4()}>
              <TableRow>
                <TableCell align={'left'} style={{ paddingTop: '0rem', paddingBottom: '0rem' }}>
                  <Tooltip
                    content={`View Your ${handleTooltipType(report)} Report`}
                    style={{ width: 250 }}
                  >
                    <a
                      onClick={() => handleGoToReports(report)}
                      style={{
                        color: '#28B359',
                        cursor: 'pointer',
                        display: 'block',
                      }}
                    >
                      {report.title}
                    </a>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ color: '#607971' }}>{handleReportType(report)}</TableCell>
                <TableCell
                  align={'left'}
                  style={{
                    paddingTop: '0rem',
                    paddingBottom: '0rem',
                    color: '#607971',
                  }}
                >
                  {toDisplayDateRange(report.window)}
                </TableCell>
                <TableCell
                  align={'left'}
                  style={{
                    paddingTop: '0rem',
                    paddingBottom: '0rem',
                    color: '#607971',
                  }}
                >
                  {Array.isArray(report.aggregateBy)
                    ? report.aggregateBy.map(upperFirst).join(', ')
                    : upperFirst(report.aggregateBy)}
                </TableCell>
                <TableCell
                  align={'left'}
                  style={{
                    paddingTop: '0rem',
                    paddingBottom: '0rem',
                    color: '#607971',
                  }}
                >
                  {'idle' in report ? getIdleText(report.idle) : 'N/A'}
                </TableCell>
                <TableCell
                  align={'left'}
                  style={{
                    paddingTop: '0rem',
                    paddingBottom: '0rem',
                    color: '#607971',
                  }}
                >
                  <div>
                    <ReportActionMenu
                      deleteClicked={() => handleDeleteReports(report)}
                      readonly={readOnly}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
      </Table>
      {!tableReports.length ? (
        <Alert
          content={
            "You currently don't have any reports you can create one using the Create Report button above"
          }
          title={'No Reports'}
          variant={'warning'}
        />
      ) : null}
    </>
  );
};

ReportsTable.displayName = 'ReportsTable';
export default ReportsTable;
