import { ReactElement, useState } from 'react';

import round from 'lodash/round';

import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import WarningIcon from '@material-ui/icons/Warning';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

import { toCurrency } from '../../services/format';

import AdoptDialog from './AdoptDialog';
import {
  CurrentInstanceBreakdownCell,
  RecommendedInstanceBreakdownCell,
} from './InstanceBreakdownCell';

const RecommendationTable = ({
  adoptRecommendation,
  currency,
  currentClusterInformation,
  negotiatedDiscount,
  ready,
  recommendations,
  setup,
  showActions,
  status,
}: RecommendationTableProps): ReactElement | null => {
  const [isExpandedCost, setIsExpandedCost] = useState(false);
  const [isExpandedCPU, setIsExpandedCPU] = useState(false);
  const [isExpandedRAM, setIsExpandedRAM] = useState(false);
  const [isExpandedBreakdown, setIsExpandedBreakdown] = useState(false);

  return (
    <div style={{ borderBottom: 'unset', width: '100% ' }}>
      <Table aria-label={'simple table'} style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell align={'center'} style={{ color: grey[800] }}>
              CURRENT
            </TableCell>
            {recommendations.map((recommendation: Recommendation, key: number) => (
              <TableCell align={'center'}>
                <div style={{ display: 'flex' }}>
                  <div style={{ flexGrow: 1 }}>{recommendation.strategy.toUpperCase()}</div>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <IconButton onClick={() => setIsExpandedCost(!isExpandedCost)}>
                {isExpandedCost ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align={'left'}>Total cost</TableCell>
            <TableCell align={'center'}>
              <Typography variant={'p'}>
                {toCurrency(currentClusterInformation.monthlyRate, currency)}/mo
              </Typography>
            </TableCell>
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align={'center'}>
                <Typography style={{ fontWeight: 'bold', color: green[700] }} variant={'p'}>
                  {toCurrency(recommendation.totalMonthlyCost, currency)}/mo
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          {isExpandedCost && (
            <TableRow style={{ borderTopStyle: 'hidden' }}>
              <TableCell />
              <TableCell align={'left'}>Savings</TableCell>
              <TableCell />
              {recommendations.map((recommendation: Recommendation) =>
                recommendation.monthlySavings > 0 ? (
                  <TableCell align={'center'}>
                    <Typography style={{ color: green[700] }} variant={'p'}>
                      {toCurrency(recommendation.monthlySavings, currency)} (
                      {round(
                        (100 * recommendation.monthlySavings) /
                          currentClusterInformation.monthlyRate,
                        1,
                      )}
                      %)
                    </Typography>
                  </TableCell>
                ) : (
                  <TableCell align={'center'}>
                    <Typography style={{ color: grey[700] }} variant={'p'}>
                      {toCurrency(-recommendation.monthlySavings, currency)} increase (
                      {round(
                        (100 * recommendation.monthlySavings) /
                          currentClusterInformation.monthlyRate,
                        1,
                      )}
                      %)
                    </Typography>
                  </TableCell>
                ),
              )}
            </TableRow>
          )}

          <TableRow>
            <TableCell />
            <TableCell align={'left'}>Node count</TableCell>
            {currentClusterInformation.totalCounts ? (
              <TableCell align={'center'} style={{ color: grey[800] }}>
                {currentClusterInformation.totalCounts.totalNodeCount}
              </TableCell>
            ) : (
              <TableCell align={'center'}>
                <Tooltip
                  placement={'bottom'}
                  title={'Could not fetch current cluster information.'}
                >
                  <WarningIcon style={{ color: grey[800] }} />
                </Tooltip>
              </TableCell>
            )}
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align={'center'}>{recommendation.nodeCount}</TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell>
              <IconButton onClick={() => setIsExpandedCPU(!isExpandedCPU)}>
                {isExpandedCPU ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align={'left'}>CPU</TableCell>
            {currentClusterInformation.totalCounts ? (
              <TableCell align={'center'} style={{ color: grey[800] }}>
                {currentClusterInformation.totalCounts.totalVCPUs} VCPUs
              </TableCell>
            ) : (
              <TableCell />
            )}
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align={'center'}>{round(recommendation.totalVCPUs, 2)} VCPUs</TableCell>
            ))}
          </TableRow>
          {isExpandedCPU && (
            <TableRow style={{ borderTopStyle: 'hidden' }}>
              <TableCell />
              <TableCell align={'left'}>CPU utilization</TableCell>
              {currentClusterInformation.totalCounts ? (
                <TableCell align={'center'} style={{ color: grey[800] }}>
                  {round(100 * currentClusterInformation.totalCounts.utilizationVCPUs, 1)}% utilized
                </TableCell>
              ) : (
                <TableCell />
              )}
              {recommendations.map((recommendation: Recommendation) => (
                <TableCell align={'center'}>
                  {round(100 * recommendation.utilizationVCPUs, 1)}% utilized
                </TableCell>
              ))}
            </TableRow>
          )}

          <TableRow>
            <TableCell>
              <IconButton onClick={() => setIsExpandedRAM(!isExpandedRAM)}>
                {isExpandedRAM ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align={'left'}>RAM</TableCell>
            {currentClusterInformation.totalCounts ? (
              <TableCell align={'center'} style={{ color: grey[800] }}>
                {currentClusterInformation.totalCounts.totalRAMGB} GB
              </TableCell>
            ) : (
              <TableCell />
            )}
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align={'center'}>{round(recommendation.totalRAMGB, 2)} GB</TableCell>
            ))}
          </TableRow>
          {isExpandedRAM && (
            <TableRow style={{ borderTopStyle: 'hidden' }}>
              <TableCell />
              <TableCell align={'left'}>RAM utilization</TableCell>
              {currentClusterInformation.totalCounts ? (
                <TableCell align={'center'} style={{ color: grey[800] }}>
                  {round(100 * currentClusterInformation.totalCounts.utilizationRAMGB, 1)}% utilized
                </TableCell>
              ) : (
                <TableCell />
              )}
              {recommendations.map((recommendation: Recommendation) => (
                <TableCell align={'center'}>
                  {round(100 * recommendation.utilizationRAMGB, 1)}% utilized
                </TableCell>
              ))}
            </TableRow>
          )}

          <TableRow style={{ verticalAlign: 'top' }}>
            <TableCell>
              <IconButton onClick={() => setIsExpandedBreakdown(!isExpandedBreakdown)}>
                {isExpandedBreakdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align={'left'}>Instance breakdown</TableCell>
            {currentClusterInformation.nodes ? (
              <TableCell align={'center'}>
                <CurrentInstanceBreakdownCell
                  isExpanded={isExpandedBreakdown}
                  nodes={currentClusterInformation.nodes}
                />
              </TableCell>
            ) : (
              <TableCell />
            )}
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align={'center'}>
                <RecommendedInstanceBreakdownCell
                  currency={currency}
                  discount={negotiatedDiscount}
                  isExpanded={isExpandedBreakdown}
                  nodes={recommendation.pools}
                />
              </TableCell>
            ))}
          </TableRow>

          <TableRow>
            <TableCell style={{ borderBottom: 'none' }} />
            <TableCell style={{ borderBottom: 'none' }} />
            <TableCell style={{ borderBottom: 'none' }} />
            {recommendations.map((recommendation: Recommendation) => (
              <TableCell align={'center'} style={{ borderBottom: 'none' }}>
                {showActions && (
                  <AdoptDialog
                    adopt={adoptRecommendation}
                    isError={status.error !== undefined}
                    isReady={ready}
                    isSetUp={setup}
                    recommendation={recommendation}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

type CurrentNodeInformation = {
  RAMGB: number;
  architecture: string;
  count: number;
  name: string;
  provider: string;
  vCPUs: number;
};

type TotalCounts = {
  totalNodeCount: number;
  totalRAMGB: number;
  totalVCPUs: number;
  utilizationRAMGB: number;
  utilizationVCPUs: number;
};

type CurrentClusterInformation = {
  monthlyRate: number;
  nodes: CurrentNodeInformation[];
  totalCounts: TotalCounts;
};

type Type = {
  RAMGB: number;
  architecture: string;
  hourlyPrice: number;
  name: string;
  pricePerCPUCoreHr: number;
  pricePerRAMByteHr: number;
  provider: string;
  sharedCore: boolean;
  spotHourlyPrice: number;
  spotPricePerCPUCoreHr: number;
  spotPricePerRAMByteHr: number;
  vCPUs: number;
};

type NodePool = {
  count: number;
  totalMonthlyCost: number;
  totalRAMGB: number;
  totalVCPUs: number;
  type: Type;
};

type Recommendation = {
  monthlySavings: number;
  nodeCount: number;
  pools: NodePool[];
  requiredRAMGB: number;
  requiredVCPUs: number;
  strategy: string;
  totalMonthlyCost: number;
  totalRAMGB: number;
  totalVCPUs: number;
  utilizationRAMGB: number;
  utilizationVCPUs: number;
};

type RecommendationTableProps = {
  adoptRecommendation: (r: Recommendation) => void;
  currency: string;
  currentClusterInformation: CurrentClusterInformation;
  negotiatedDiscount: number;
  ready: boolean;
  recommendations: any;
  setup: boolean;
  showActions: boolean;
  status: { data: string } | { error: unknown; message: string; status: number };
};

export {
  CurrentClusterInformation,
  CurrentNodeInformation,
  NodePool,
  Recommendation,
  RecommendationTable,
  RecommendationTableProps,
  TotalCounts,
  Type,
};
