import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useState } from 'react';

import { AlertTypes, Alert, RecurringUpdate } from '../../services/alerts';

const RecurringAlertsTable = ({
  alerts,
  createAlert,
  removeAlert,
  locked = {},
}: ComponentProps) => {
  const [newWindow, setNewWindow] = useState('');
  const [newAgg, setNewAgg] = useState('');
  const [newFilter, setNewFilter] = useState('');
  return (
    <TableContainer data-test={'alerts-table'}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Window</TableCell>
            <TableCell>Aggregation</TableCell>
            <TableCell>Filter</TableCell>
            <TableCell>Add/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alerts.map((a) => (
            <TableRow key={a.id}>
              <TableCell>{a.window}</TableCell>
              <TableCell>{a.aggregation}</TableCell>
              <TableCell>{a.filter}</TableCell>
              <TableCell>
                <IconButton data-test={'alert-delete'} onClick={() => removeAlert(a)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell data-test={'new-alert-window'}>
              {locked.window || (
                <TextField onChange={(e) => setNewWindow(e.target.value)} value={newWindow} />
              )}
            </TableCell>
            <TableCell data-test={'new-alert-aggregate'}>
              {locked.aggregate || (
                <TextField onChange={(e) => setNewAgg(e.target.value)} value={newAgg} />
              )}
            </TableCell>
            <TableCell data-test={'new-alert-filter'}>
              <TextField onChange={(e) => setNewFilter(e.target.value)} value={newFilter} />
            </TableCell>
            <TableCell>
              <IconButton data-test={'new-alert-add'} onClick={handleAddAlert}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  function handleAddAlert() {
    const re = /^[1-7]d$/;
    if (!re.test(newWindow || locked.window || '')) {
      alert('Recurring updates must be scheduled for a window of 1 to 7 days.');
      return;
    }
    const newAlert = new RecurringUpdate({
      type: AlertTypes.Recurring,
      window: newWindow || locked.window,
      aggregation: newAgg || locked.aggregate,
      filter: newFilter,
    });
    createAlert(newAlert);
    setNewWindow('');
    setNewAgg('');
    setNewFilter('');
  }
};

export default RecurringAlertsTable;

RecurringAlertsTable.defaultProps = {
  locked: {},
};

interface ComponentProps {
  alerts: RecurringUpdate[];
  createAlert: (alert: Alert) => void;
  locked?: {
    aggregate?: string;
    window?: string;
  };
  removeAlert: (alert: Alert) => void;
}
