import { FC, memo } from 'react';

import { Button, Modal, Typography } from '@kubecost-frontend/holster';

import { Reports } from './types';

interface OpenControlProps {
  open: boolean;
  reports: Reports[];
  selectReport: (report: Reports) => void;
  setOpen: (open: boolean) => void;
}

export const OpenControl: FC<OpenControlProps> = ({ open, reports, selectReport, setOpen }) => (
  <Modal onClose={() => setOpen(false)} open={open} title={'Open Report'}>
    {!reports.length ? (
      <Typography variant={'p'}>
        Saved reports will be listed here. Click the "Save report" button to save the current
        report.
      </Typography>
    ) : (
      reports.map((report) => (
        <Typography
          className={'flex cursor-pointer items-center rounded p-2 hover:bg-kc-primary/25'}
          key={report.key}
          onClick={() => {
            selectReport(report);
            setOpen(false);
          }}
          variant={'p'}
        >
          {report.title}
        </Typography>
      ))
    )}
    <div className={'flex justify-end'}>
      <Button onClick={() => setOpen(false)} variant={'default'}>
        Close
      </Button>
    </div>
  </Modal>
);

const OpenControlMemoized = memo(OpenControl);
export default OpenControlMemoized;
