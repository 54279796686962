import { ComponentPropsWithoutRef } from 'react';

interface IconProps extends ComponentPropsWithoutRef<'svg'> {}

const NavigateNextIcon = ({ style, ...args }: IconProps) => {
  const s = {
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '1.5rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: 0,
    useSelect: 'none',
  };
  if (style) {
    Object.assign(s, style);
  }
  return (
    <svg aria-hidden={'true'} focusable={'false'} style={s} viewBox={'0 0 24 24'} {...args}>
      <path d={'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'} />
    </svg>
  );
};

export { NavigateNextIcon };
