import { FC, memo } from 'react';

import isArray from 'lodash/isArray';
import upperFirst from 'lodash/upperFirst';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/styles';

import { Typography } from '@kubecost-frontend/holster';

import { toVerboseTimeRange } from '../../services/format';

const useStyles = makeStyles(() => ({
  root: {
    '& > * + *': {
      marginTop: 2,
    },
  },
}));

const handleBreadcrumbClick = (i: number, cb: (arg0: number) => void) => (e) => {
  e.preventDefault();
  cb(i);
};

interface SubtitleProps {
  aggregateBy: any;
  clearContext: () => void;
  context: any;
  goToContext: (item: number) => void;
  window: any;
}

const Subtitle: FC<SubtitleProps> = ({
  aggregateBy,
  clearContext,
  context,
  goToContext,
  window,
}) => {
  const classes = useStyles();

  if (!isArray(context) || context.length === 0) {
    return (
      <div className={classes.root}>
        <Breadcrumbs aria-label={'breadcrumb'} separator={<NavigateNextIcon fontSize={'small'} />}>
          {aggregateBy && aggregateBy.length > 0 ? (
            <Typography variant={'p'}>
              {toVerboseTimeRange(window)} by {aggregateBy.map(upperFirst).join('/')}
            </Typography>
          ) : (
            <Typography variant={'p'}>{toVerboseTimeRange(window)}</Typography>
          )}
        </Breadcrumbs>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label={'breadcrumb'} separator={<NavigateNextIcon fontSize={'small'} />}>
        <a onClick={() => clearContext()} style={{ color: 'inherit' }}>
          <Typography variant={'p'}>
            {toVerboseTimeRange(window)} by {context.map((c) => c.property).join('/')}
          </Typography>
        </a>
        {context.map((ctx, c) =>
          c === context.length - 1 ? (
            <Typography key={c} variant={'p'}>
              {ctx.name}
            </Typography>
          ) : (
            <a key={c} onClick={handleBreadcrumbClick(c, goToContext)} style={{ color: 'inherit' }}>
              <Typography variant={'p'}>{ctx.name}</Typography>
            </a>
          ),
        )}
      </Breadcrumbs>
    </div>
  );
};

export const SubtitleMemoized = memo(Subtitle);
