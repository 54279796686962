export default {
  getProfile(name: string | null): Profile {
    if (name === 'high-availability') {
      return {
        name: 'high-availability',
        description: 'In high-availability clusters, the aim is 50% resource utilization.',
        targetUtilization: {
          cpu: 0.5,
          ram: 0.5,
        },
        p: 0.999,
      };
    }

    if (name === 'production') {
      return {
        name: 'production',
        description: 'In production clusters, the aim is 65% resource utilization.',
        targetUtilization: {
          cpu: 0.65,
          ram: 0.65,
        },
        p: 0.98,
      };
    }

    if (name === 'custom') {
      return {
        name: 'custom',
        description:
          'Using a custom profile you are able to set your own goals for resource utilization.',
        targetUtilization: {
          cpu: 0.5,
          ram: 0.5,
        },
        p: 0.99,
      };
    }

    return {
      name: 'development',
      description: 'In development clusters, the aim is 80% resource utilization.',
      targetUtilization: {
        cpu: 0.8,
        ram: 0.8,
      },
      p: 0.85,
    };
  },
};

interface TargetUtilization {
  cpu: number;
  ram: number;
}

interface Profile {
  description: string;
  name: string;
  p: number;
  targetUtilization: TargetUtilization;
}
