import { FC, ReactNode } from 'react';

import { Avatar, Chip } from '@material-ui/core';
import ArrowDownwardICon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

interface TrendIndicatorChipProps {
  arrowDirection?: 'UP' | 'DOWN';
  isEfficiency?: boolean;
  style?: Record<string, string | number>;
  trendIndicator?: ReactNode;
}

export const TrendIndicatorChip: FC<TrendIndicatorChipProps> = ({
  arrowDirection,
  isEfficiency,
  style,
  trendIndicator,
}) => {
  const whichArrowIcon = () => {
    if (arrowDirection === 'UP') {
      return <ArrowUpwardIcon fontSize={'small'} />;
    }
    return <ArrowDownwardICon fontSize={'small'} />;
  };

  return (
    <Chip
      avatar={
        <Avatar style={{ backgroundColor: 'transparent', color: 'white' }}>
          {whichArrowIcon()}
        </Avatar>
      }
      label={`${trendIndicator}%`}
      size={'small'}
      style={{
        opacity: 0.9,
        color: 'white',
        backgroundColor: isEfficiency
          ? arrowDirection === 'UP'
            ? '#4caf50'
            : '#f50057'
          : arrowDirection === 'UP'
          ? '#f50057'
          : '#4caf50',
        ...style,
      }}
      variant={'default'}
    />
  );
};
