import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useEffect, useState } from 'react';

import { WithDataLoader } from '../../components/DataLoader';
import { toCurrency } from '../../services/format';
import { model as Model } from '../../services/model';
import { SummaryAllocationSet } from '../../types/allocation';

const useStyles = makeStyles({
  actionLink: {
    marginLeft: 'auto',
  },
  actionButton: {
    color: '#2196f3',
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
  },
  actionText: {
    marginLeft: 'auto',
    color: '#8c8c8c',
  },
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    marginBottom: 32,
  },
  totalRow: {
    fontWeight: 500,
  },
});

const NetworkCard = () => {
  const classes = useStyles();

  const [promise, setPromise] = useState<Promise<unknown> | null>(null);
  const [currency, setCurrency] = useState('USD');
  const [data, setData] = useState<(string | number)[][]>([]);
  const [done, setDone] = useState(false);

  useEffect(() => {
    const p = fetchData();
    setPromise(p);
    p.then(() => setDone(true));
  }, []);

  if (done && !(data && data.length)) {
    return (
      <Card className={classes.root} style={{ backgroundColor: '#ececec', textAlign: 'center' }}>
        <CardHeader style={{ marginTop: '25%' }} title={'Access network cost details'} />
        <CardContent style={{ marginBottom: '15%' }}>
          <Link
            href={'https://guide.kubecost.com/hc/en-us/articles/4407595973527'}
            target={'_blank'}
            underline={'always'}
          >
            {'Learn more'}
          </Link>
        </CardContent>
      </Card>
    );
  }
  const Content = () => (
    <>
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Namespace</TableCell>
                <TableCell>Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(([key, val]: Array<string | number>) => (
                <TableRow
                  key={key}
                  onClick={() => window.open(`./network.html?window=7d&ns=${key}`, '_self')}
                  hover
                >
                  <TableCell>{key}</TableCell>
                  <TableCell>{toCurrency(val, currency, 2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions className={classes.actions}>
        <Link className={classes.actionLink} href={'/network.html?window=7d'}>
          <Button className={classes.actionButton} endIcon={<KeyboardArrowRightIcon />}>
            Network Costs
          </Button>
        </Link>
      </CardActions>
    </>
  );
  const ContentWithDataLoading = WithDataLoader(Content);
  return (
    <Card className={classes.root}>
      <CardHeader
        subheader={'Top network egress costs over the last 7 days'}
        title={'Network Costs'}
      />
      <ContentWithDataLoading
        errorMessage={'Could not load network costs'}
        loadMessage={'Loading network costs'}
        promise={promise}
      />
    </Card>
  );

  async function fetchData() {
    const prom = Promise.all([
      Model.getAllocationSummary('7d', 'namespace', { accumulate: true }),
      Model.getConfigs(),
    ]);
    const [response, modelConfig] = await prom;
    const allocationSet: SummaryAllocationSet = response.data.sets[0];
    console.log(allocationSet);
    console.log(modelConfig);
    const rows = Object.entries(allocationSet.allocations)
      .filter((entry) => entry[1].networkCost > 0.01)
      .map(([key, alloc]) => [key, alloc.networkCost]);

    rows.sort((a, b) => (a[1] > b[1] ? -1 : 1));
    setData(rows.slice(0, 5));
    setCurrency(modelConfig.currencyCode || 'USD');
  }
};

export default NetworkCard;
