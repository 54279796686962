import { QueryObserverResult, useQuery } from 'react-query';

import { model } from '../services/model';

interface PVSizingResponse {
  averageUsageBytes: number;
  claimName: string;
  claimNamespace: string;
  clusterId: string;
  currentCapacityBytes: number;
  currentCostMonthly: number;
  maxUsageBytes: number;
  recommendedCapacityBytes: number;
  recommendedCostMonthly: number;
  savingsMonthly: number;
  storageClass: string;
  volumeName: string;
}

// TODO: Figure out how to make this work
// type Total = { pvTotalSavings: number };
// type UsePersistentVolumeSizingRespose = UseQueryResult<PVSizingResponse[]> &
//   Total;

const getPVQueryKey = (headroomPercent: number) => [
  'savings',
  'persistentVolumeSizing',
  { headroomPercent },
];

async function queryPVSizingApi(headroomPercent: number): Promise<PVSizingResponse[]> {
  const response = await model.get<{ recommendations: PVSizingResponse[] }>(
    '/savings/persistentVolumeSizing',
    { headroomPercent: headroomPercent * 100 },
  );

  return response.recommendations;
}

function getPVTotalSavings(data: QueryObserverResult<PVSizingResponse[]>['data']): number {
  if (!data) return 0;

  return data.map((item) => item.savingsMonthly).reduce((a, b) => a + b, 0);
}

const usePersistentVolumeSizing = (headroomPercent = 0) => {
  const { data, ...args } = useQuery(
    getPVQueryKey(headroomPercent),
    () => queryPVSizingApi(headroomPercent),
    {
      initialData: [],
    },
  );

  return { data, pvTotalSavings: getPVTotalSavings(data), ...args };
};

export { PVSizingResponse, usePersistentVolumeSizing as default, getPVQueryKey };
