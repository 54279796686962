import { useState } from 'react';

import { Alert } from '@material-ui/lab';

import { useClusters } from '../../../contexts/ClusterConfig';
import ConfigService from '../../../services/config';
import { version } from '../../../services/env';

import LicenseKeyDisplay from './LicenseKeyDisplay';
import { LicenseKeyModal } from './LicenseKeyModal';

const LicenseKeyManager = () => {
  const { apiVersion, licenseConfig, modelConfig } = useClusters();
  const [alert, setAlert] = useState<{ message: string; type: string } | undefined>();
  const [licenseKeyModalVisible, setLicenseKeyModalVisible] = useState(false);

  const handleAdjustLicenseKeyClicked = () => {
    setLicenseKeyModalVisible(true);
  };

  const handleStartFreeTrialClicked = async () => {
    try {
      const resp = await ConfigService.startTrial();
    } catch (e) {
      setAlert({
        type: 'error',
        message: e as string,
      });
    }
    setAlert({
      type: 'success',
      message:
        'Successfully started your free trial! Your page will reload shortly with the changes...',
    });
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleLicenseKeyUpgrade = async (key: string) => {
    const newKey = { ...licenseConfig };
    newKey.productKey.key = key;
    try {
      const resp = await ConfigService.setProductKey(newKey);
    } catch {
      setLicenseKeyModalVisible(false);
      setAlert({
        type: 'error',
        message:
          'Uh Oh. Looks like we had a problem setting your license key. Check the logs for more details.',
      });
    }
    setAlert({
      type: 'success',
      message:
        'Successfully set your license key! Your page will reload shortly with the changes...',
    });
    setLicenseKeyModalVisible(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  return (
    <div>
      {alert && (
        <Alert severity={alert.type as any} style={{ marginBottom: '1em' }}>
          {alert.message}
        </Alert>
      )}
      <LicenseKeyDisplay
        adjustLicenseKeyClicked={handleAdjustLicenseKeyClicked}
        apiVersion={apiVersion}
        clientVersion={version ?? ''}
        config={licenseConfig}
        readOnly={modelConfig.readOnly === 'true'}
        startFreeTrialClicked={handleStartFreeTrialClicked}
      />
      <LicenseKeyModal
        closeModal={() => setLicenseKeyModalVisible(false)}
        handleUpgrade={handleLicenseKeyUpgrade}
        visible={licenseKeyModalVisible}
      />
    </div>
  );
};

export default LicenseKeyManager;
