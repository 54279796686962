import { FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

// recharts
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

import { ArrowIcon } from '../../../assets/images/arrow-icon';
import { Header } from '../../../components/Header2New';
import { useClusters } from '../../../contexts/ClusterConfig';
import { colorMap } from '../../../services/colors';

import { getReservedInstanceData } from './api';
import { StatCard } from './StatCard';
import { ReservedData } from './types';

export const ReservedInstancesNew: FC = () => {
  const [reservedData, setReservedData] = useState<ReservedData>({
    cards: { totalSavings: 0, totalSpend: 0 },
    cpu: [],
    memory: [],
    table: [],
  });
  const { promQueryUrl, promRangeQueryUrl } = useClusters();
  const navigate = useNavigate();

  async function fetchData() {
    const values = await getReservedInstanceData(30, '3', promQueryUrl, promRangeQueryUrl);
    setReservedData(values);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Header
        refreshCallback={fetchData}
        title={
          <>
            Reserved Instances
            <Typography className={'font-normal text-kc-gray-200'} variant={'p'}>
              <a href={'savings'}>
                <ArrowIcon className={'inline'} direction={'UP'} />
                Back to savings
              </a>
            </Typography>
          </>
        }
      />

      <Typography style={{ marginTop: '1em' }} variant={'p'}>
        A recommended strategy for purchasing reserved instances is to observe the sustained
        usage/request low point (1st percentile) for both RAM and CPU capacity. This is often a good
        indicator for your future floor for CPU/RAM needs.
      </Typography>
      <hr style={{ marginTop: '1em', opacity: 0.1 }} />
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div
          style={{
            paddingTop: '1em',
            paddingBottom: '1em',
          }}
        >
          <Typography variant={'p-large'}>Memory Usage and Reservation Recommendation</Typography>
          <Typography style={{ marginBottom: '1em' }} variant={'p'}>
            Requests and usage for all containers in this pod
          </Typography>
          <ResponsiveContainer height={290} width={'100%'}>
            <LineChart
              data={reservedData.memory}
              margin={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 15,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey={'time'} />
              <YAxis />
              <Line dataKey={'request'} dot={false} stroke={colorMap.blue} type={'monotone'} />
              <Line dataKey={'recommended'} dot={false} stroke={'#f4b400'} type={'monotone'} />
              <Line dataKey={'usage'} dot={false} stroke={colorMap.red} type={'monotone'} />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div style={{ paddingTop: '1em' }}>
          <Typography variant={'p-large'}>CPU Usage and Reservation Recommendation</Typography>
          <Typography style={{ marginBottom: '1em' }} variant={'p'}>
            Requests and usage for all containers in this pod
          </Typography>
          <ResponsiveContainer height={290} width={'100%'}>
            <LineChart
              data={reservedData.cpu}
              margin={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 15,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey={'time'} />
              <YAxis />
              <Line dataKey={'request'} dot={false} stroke={colorMap.blue} type={'monotone'} />
              <Line dataKey={'recommended'} dot={false} stroke={'#f4b400'} type={'monotone'} />
              <Line dataKey={'usage'} dot={false} stroke={colorMap.red} type={'monotone'} />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <hr style={{ opacity: 0.1 }} />
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        <StatCard
          title={'Estimated Annual Savings'}
          value={`$${(reservedData.cards.totalSavings * 12).toFixed(2)}`}
        />
        <StatCard
          title={'Savings Rate'}
          value={`${(
            (100 * reservedData.cards.totalSavings) / reservedData.cards.totalSpend || 0
          ).toFixed()}%`}
        />
        <StatCard title={'Upfront Payment'} value={'$0.00'} />
      </div>
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableHeadCell>Instance Type</TableHeadCell>
            <TableHeadCell>Current Cost</TableHeadCell>
            <TableHeadCell>Recommendation</TableHeadCell>
            <TableHeadCell>Savings</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reservedData.table.map((row) => (
            <TableRow>
              <TableCell>{row.instanceType}</TableCell>
              <TableCell>{row.currentCost}</TableCell>
              <TableCell>Reserve</TableCell>
              <TableCell>{row.savings}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

ReservedInstancesNew.displayName = 'ReservedInstancesNew';
