// react
import React, { FC, useState } from 'react';

// holster
import { Card, Tooltip, Typography } from '@kubecost-frontend/holster';

import { SquareInfoIcon } from '../../assets/images';
import { Header } from '../../components/Header2New';
import { withOnboardingEnsured } from '../../components/HostedOnboarding';
import { useClusters } from '../../contexts/ClusterConfig';
import { useHosted } from '../../hooks/useHosted';
import { toCurrency } from '../../services/format';

import { SavingsCard } from './SavingsCard';
import { cloudSavings, savings } from './SavingsData';

const SavingsInner: FC = () => {
  const [totalSavings, setTotalSavings] = useState<{ [key: string]: number }>({});
  const { isHostedEnvironment } = useHosted();
  const { modelConfig: config } = useClusters();
  const currency = config.currencyCode;

  const totalSavingCalc = Object.keys(totalSavings).reduce(
    (total, key) => totalSavings[key] * 0.65 + total,
    0,
  );

  const savingsItems = isHostedEnvironment ? cloudSavings : savings;

  return (
    <>
      <Header title={'Savings'} />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: '1em',
          paddingBottom: '1em',
        }}
      >
        <Card style={{ backgroundColor: 'rgb(234, 249, 240, 0.8)', padding: '1em' }}>
          <div>
            <Typography className={'flex items-center font-bold'} variant={'h5'}>
              Estimated monthly savings available
              <Tooltip
                className={'w-52 font-normal'}
                content={
                  'Estimated savings are probability adjusted and include both Kubernetes and external cloud insights.'
                }
                position={'TOP'}
              >
                <SquareInfoIcon className={'ml-3 text-kc-gray-200'} />
              </Tooltip>
            </Typography>
          </div>
          <Typography style={{ color: '#31C46C' }} variant={'h2'}>
            {toCurrency(totalSavingCalc, currency)}
          </Typography>
        </Card>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '1em',
        }}
      >
        {Object.values(savingsItems).map((savingsItem) => (
          <SavingsCard
            key={savingsItem.title}
            loadingComplete={(value) => {
              setTotalSavings((prev) => {
                prev[savingsItem.title] = value;
                return { ...prev };
              });
            }}
            savingsItem={savingsItem}
          />
        ))}
      </div>
    </>
  );
};

export const Savings = withOnboardingEnsured(SavingsInner);
