import { SavingsCard2 } from './SavingsCard2';

interface SavingsTileProps {
  currency: string;
  onClickHref: string;
  savingsData: any;
}

export const SavingsTile = ({ currency, onClickHref, savingsData }: SavingsTileProps) => (
  <div
    style={{
      height: '100%',
      display: 'grid',
      gridTemplateRows: '1fr 1fr 1fr',
      alignItems: 'center',
    }}
  >
    <SavingsCard2
      currency={currency}
      title={'Monthly CPU Savings'}
      value={savingsData.monthlySavingsCPU}
    />
    <SavingsCard2
      currency={currency}
      onClickHref={onClickHref}
      title={'Total Monthly Savings'}
      value={savingsData.monthlySavings}
    />
    <SavingsCard2
      currency={currency}
      title={'Monthly RAM Savings'}
      value={savingsData.monthlySavingsRAM}
    />
  </div>
);
