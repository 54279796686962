const ENVS_ENUM = Object.freeze({
  DEV: 'development',
  PROD: 'production',
  TEST: 'test',
});

const version = process.env.REACT_APP_VERSION;

function isProduction(): boolean {
  return process.env.NODE_ENV === ENVS_ENUM.PROD;
}

export { isProduction, version };
