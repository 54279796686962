import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { memo, ReactNode, useState } from 'react';

import {
  Alert,
  AlertTypes,
  BudgetAlert,
  ClusterHealthAlert,
  DiagnosticAlert,
  EfficiencyAlert,
  RecurringUpdate,
  SpendChangeAlert,
} from '../../services/alerts';
import { If } from '../If';

import { BudgetAlertsTable } from './BudgetAlertsTable';
import { ClusterHealthAlertsTable } from './ClusterHealthAlertsTable';
import DiagnosticAlertsTable from './DiagnosticAlertsTable';
import EfficiencyAlertsTable from './EfficiencyAlertsTable';
import RecurringAlertsTable from './RecurringAlertsTable';
import SpendChangeAlertsTable from './SpendChangeAlertsTable';

interface ComponentProps {
  alertTypeOpts: AlertTypeOpt[];
  alerts: Alert[];
  createAlert: (arg0: Alert) => void;
  removeAlert: (arg0: Alert) => void;
}

interface AlertTypeOpt {
  explanation?: ReactNode;
  label: string;
  lockedFields?: {
    aggregate?: string;
    baseline?: string;
    effThresh?: number;
    spendThresh?: number;
    threshold?: number;
    window?: string;
  };
  value: AlertTypes;
}

const useStyles = makeStyles({
  formControl: {
    marginBottom: 16,
  },
});

const AlertsTable = memo(({ alertTypeOpts, alerts, createAlert, removeAlert }: ComponentProps) => {
  const classes = useStyles();

  const [alertType, setAlertType] = useState(alertTypeOpts[0]);

  function getAlertsTable() {
    switch (alertType.value) {
      case AlertTypes.Budget:
        return (
          <BudgetAlertsTable
            alerts={alerts.filter((a) => a.type === alertType.value) as BudgetAlert[]}
            createAlert={createAlert}
            locked={alertType.lockedFields}
            removeAlert={removeAlert}
          />
        );
      case AlertTypes.Health:
        return (
          <ClusterHealthAlertsTable
            alerts={alerts.filter((a) => a.type === alertType.value) as ClusterHealthAlert[]}
            createAlert={createAlert}
            locked={alertType.lockedFields}
            removeAlert={removeAlert}
          />
        );
      case AlertTypes.Diagnostic:
        return (
          <DiagnosticAlertsTable
            alerts={alerts.filter((a) => a.type === alertType.value) as DiagnosticAlert[]}
            createAlert={createAlert}
            locked={alertType.lockedFields}
            removeAlert={removeAlert}
          />
        );
      case AlertTypes.Efficiency:
        return (
          <EfficiencyAlertsTable
            alerts={alerts.filter((a) => a.type === alertType.value) as EfficiencyAlert[]}
            createAlert={createAlert}
            locked={alertType.lockedFields}
            removeAlert={removeAlert}
          />
        );
      case AlertTypes.Recurring:
        return (
          <RecurringAlertsTable
            alerts={alerts.filter((a) => a.type === alertType.value) as RecurringUpdate[]}
            createAlert={createAlert}
            locked={alertType.lockedFields}
            removeAlert={removeAlert}
          />
        );
      case AlertTypes.SpendChange:
        return (
          <SpendChangeAlertsTable
            alerts={alerts.filter((a) => a.type === alertType.value) as SpendChangeAlert[]}
            createAlert={createAlert}
            locked={alertType.lockedFields}
            removeAlert={removeAlert}
          />
        );
      default:
        return null;
    }
  }

  return (
    <>
      {/* select alert type to work with */}
      <FormControl className={classes.formControl} data-test={'alerts-table-select'}>
        <InputLabel id={'alert-select-label'}>Alert Type</InputLabel>
        <Select
          data-test={'alerts-table-selector'}
          labelId={'alert-select-label'}
          onChange={(e) => {
            const chosen = alertTypeOpts.find((ato) => ato.value === e.target.value);
            if (chosen) {
              setAlertType(chosen);
            }
          }}
          value={alertType.value}
        >
          {alertTypeOpts.map((at) => (
            <MenuItem key={at.value} value={at.value}>
              {at.label} ({alerts.filter((a) => a.type === at.value).length})
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* blurb explaining the chosen alert type */}
      <If condition={!!alertType.explanation}>
        <Typography>{alertType.explanation}</Typography>
      </If>

      {/* table showing alerts of the selected type */}
      {getAlertsTable()}
    </>
  );
});

export { AlertsTable };
