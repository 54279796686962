import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import SavedIcon from '@material-ui/icons/Bookmark';
import OpenIcon from '@material-ui/icons/FolderOpen';
import { memo, useState } from 'react';

const OpenControl = ({ clearContext, savedReports, selectReport }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title={'Open saved report'}>
        <IconButton id={'allocation-open-report-button'} onClick={() => setOpen(true)}>
          <OpenIcon />
        </IconButton>
      </Tooltip>
      <Dialog maxWidth={'xs'} onClose={() => setOpen(false)} open={open} fullWidth>
        <DialogTitle>Open report</DialogTitle>
        {savedReports.length === 0 ? (
          <DialogContent>
            Saved reports will be listed here. Click the "Save report" button to save the current
            report.
          </DialogContent>
        ) : (
          <List>
            {savedReports.map((sr, i) => (
              <ListItem
                key={i}
                onClick={() => {
                  clearContext();
                  selectReport(sr);
                  setOpen(false);
                }}
                button
              >
                <ListItemIcon>
                  <SavedIcon />
                </ListItemIcon>
                <ListItemText primary={sr.title} />
              </ListItem>
            ))}
          </List>
        )}
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(OpenControl);
