import { ChangeEvent, useState } from 'react';

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Input } from '@kubecost-frontend/holster';

// import { Alert } from '@kubecost-frontend/holster'
/// / Need to make some tweaks to our holster Alert to accomdate an Element as content
// Leaving this here as a TODO

interface FilterItemProps {
  defaultValue: string;
  type: string;
  updateItem: (type: string, value: string) => Promise<boolean>;
}

const FilterItem = ({ defaultValue, type, updateItem }: FilterItemProps) => {
  const [currentValue, setCurrentValue] = useState<string>(defaultValue);
  const [errorState, setErrorState] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const sendUpdate = async () => {
    if (currentValue !== defaultValue) {
      // check for bogus values
      const isSuccessful = await updateItem(type, currentValue);
      if (!isSuccessful) {
        setShowErrorAlert(true);
        setErrorState(true);
      }
    }
  };

  const checkForEnter = (e: any) => {
    if (e.keyCode === 13) {
      sendUpdate();
    }
  };

  const handlUnfocus = () => {
    if (errorState) {
      setErrorState(false);
      setCurrentValue(defaultValue);
    } else {
      sendUpdate();
    }
  };

  const updateCurrentValueText = (e: ChangeEvent<HTMLInputElement>) => {
    if (errorState === true) {
      setErrorState(false);
    }
    setCurrentValue(e.target.value);
  };

  const errorMessage =
    type === 'controller' ? (
      <div>
        Unknown {type}: this may be because a controllerKind prefix wasn&apos;t included. i.e.{' '}
        <strong>deployment:</strong>kubecost-cost-analyzer
      </div>
    ) : (
      `Unknown ${type}`
    );

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setShowErrorAlert(false)}
        open={showErrorAlert}
      >
        <Alert onClose={() => setShowErrorAlert(false)} severity={'error'}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Input
        className={errorState ? 'focus:border-kc-danger' : ''}
        helperText={errorState ? `Invalid ${type}` : ''}
        label={type}
        onBlur={handlUnfocus}
        onChange={updateCurrentValueText}
        onKeyDown={checkForEnter}
        placeholder={'ALL'}
        style={{ margin: '.25em' }}
        value={currentValue}
      />
    </div>
  );
};

export { FilterItem };
