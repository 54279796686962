/* eslint-disable no-param-reassign */
import { FC } from 'react';

import { format } from 'date-fns';

import { Tooltip as KCTooltip, Modal, Typography } from '@kubecost-frontend/holster';

import { AllocationSet } from '../../types/allocation';

import { DrilldownTable } from './DrilldownTable';
import { RequestSizingRecommendation } from './types';
import { mebibyteFactor, parseCpuToCores, parseMemoryToBytes, recCurrency } from './utils';

interface RequestSizingDrilldownProps {
  allocationData: AllocationSet[];
  currency: string;
  open: boolean;
  recommendation: RequestSizingRecommendation;
  setOpen: (open: boolean) => void;
  title: string;
}

const RequestSizingDrilldown: FC<RequestSizingDrilldownProps> = ({
  allocationData,
  currency,
  open,
  recommendation,
  setOpen,
  title,
}) => {
  const savingsFigure = recCurrency(recommendation, currency);

  const allKeys: Record<string, boolean> = {};

  const cpuDrillDownData = allocationData.map((allocationSet) => {
    const dataPoint: Record<string, any> = {
      time: '',
      allocations: {},
      currentRequestMillicores: recommendation.latestKnownRequest.cpu
        ? parseCpuToCores(recommendation.latestKnownRequest.cpu) * 1000
        : 0,
      recommendationMillicores: recommendation.latestKnownRequest.cpu
        ? parseCpuToCores(recommendation.recommendedRequest.cpu) * 1000
        : 0,
    };
    Object.entries(allocationSet).forEach(([key, alloc]) => {
      dataPoint.allocations[key] = {
        perReplicaUsageMilicores: alloc.cpuCoreUsageAverage * 1000 || 0,
      };
      dataPoint.time =
        dataPoint.time || format(new Date(alloc.window.start?.toString()), 'MM/dd/yyyy');
      allKeys[key] = true;
    });
    return dataPoint;
  });

  const ramDrillDownData = allocationData.map((allocationSet) => {
    const dataPoint: Record<string, any> = {
      time: '',
      allocations: {},
      currentRequestBytes: recommendation.latestKnownRequest.memory
        ? parseMemoryToBytes(recommendation.latestKnownRequest.memory)
        : 0,
      recommendationBytes: recommendation.latestKnownRequest.memory
        ? parseMemoryToBytes(recommendation.recommendedRequest.memory)
        : 0,
    };
    Object.entries(allocationSet).forEach(([key, alloc]) => {
      dataPoint.allocations[key] = {
        perReplicaUsageBytes: alloc.ramByteUsageAverage || 0,
      };
      dataPoint.time =
        dataPoint.time || format(new Date(alloc.window.start?.toString()), 'MM/dd/yyyy');
      allKeys[key] = true;
    });
    return dataPoint;
  });

  const tableDrillDownData = allocationData.reduce((totals, next) => {
    Object.entries(next).forEach(([key, alloc]) => {
      totals[key] = totals[key] || {
        properties: {
          namespace: '',
          pod: '',
        },
        cpuCoreUsageAverage: 0,
        ramByteUsageAverage: 0,
      };
      totals[key].cpuCoreUsageAverage += alloc.cpuCoreUsageAverage;
      totals[key].ramByteUsageAverage += alloc.ramByteUsageAverage;
      totals[key].properties.pod = alloc.properties?.pod || '';
      totals[key].properties.namespace = alloc.properties?.namespace || '';
    });
    return totals;
  }, {});

  const tableRows = Object.entries(tableDrillDownData).map(([, row]) => ({
    properties: row.properties,
    avgCPUUsage: parseCpuToCores(row.cpuCoreUsageAverage.toFixed(4).toString()) * 1000,
    avgRAMUsage: parseMemoryToBytes(row.ramByteUsageAverage.toFixed(4).toString()),
  }));

  const newCPUData = [...cpuDrillDownData];
  const newRAMData = [...ramDrillDownData];
  const newTableDrilldownData = [...tableRows];

  const renderLongKeyNames = (value: string) => {
    let formattedValue = value;
    if (value !== 'Current Request' && value !== 'Recommendation') {
      const [, , , pod] = value.split('/');
      formattedValue = pod;
    }

    return (
      <KCTooltip className={'z-[9999999] w-[300px]'} content={value}>
        <span>{formattedValue}</span>
      </KCTooltip>
    );
  };

  const cpuTooltipFormatter = (value: any, name: string): [string, string] => {
    let formattedName = name;
    if (name !== 'Recommendation' && name !== 'Current Request') {
      const [, , , pod] = name.split('/');
      formattedName = pod;
    }
    return [`${value.toFixed(1)}m`, formattedName];
  };

  const ramTooltipFormatter = (value: any, name: string): [string, string] => {
    let formattedName = name;
    if (name !== 'Current Request' && name !== 'Recommendation') {
      const [, , , pod] = name.split('/');
      formattedName = pod;
    }
    return [`${(value / mebibyteFactor).toFixed(2)}Mi`, formattedName];
  };

  return (
    <Modal onClose={() => setOpen(false)} open={open} size={'large'} title={title}>
      <div>
        <Typography variant={'p-small'}>Estimated Savings: {savingsFigure}</Typography>
      </div>
      <main className={'mt-4'}>
        {ramDrillDownData && cpuDrillDownData && (
          <div className={'mb-6 flex gap-6'}>
            <div>
              <p>Cluster</p>
              <p className={'font-bold'}>{recommendation.clusterID}</p>
            </div>
            <div>
              <p>Namespace</p>
              <p className={'font-bold'}>{recommendation.namespace}</p>
            </div>
            <div>
              <p>Type</p>
              <p className={'font-bold'}>{recommendation.controllerKind}</p>
            </div>
            <div>
              <p>Controller Name</p>
              <p className={'font-bold'}>{recommendation.controllerName}</p>
            </div>
            <div>
              <p>Container</p>
              <p className={'font-bold'}>{recommendation.containerName}</p>
            </div>
          </div>
        )}
        <div className={'mb-2 font-bold'}>Breakdown</div>
        <section className={'pb-5'}>
          <DrilldownTable allocationData={allocationData} recommendation={recommendation} />
        </section>
      </main>
    </Modal>
  );
};
RequestSizingDrilldown.displayName = 'RequestSizingDrilldown';
export { RequestSizingDrilldown };
