import { useState } from 'react';

import { Box, Button, Paper } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { FilterItem } from './FilterItem';
import { InspectedResourceListItem } from './inspectState';
import { TimeWindowSelector } from './TimeWindowSelector';

export const InspectFilterTile = ({
  activeTimeWindow,
  handleFilterUpdate,
  primaryAggregation,
  secondaryAggregations,
  setActiveTimeWindow,
}: InspectFilterTileProps) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const filterCount = secondaryAggregations.filter(
    ({ name }: InspectedResourceListItem) => name !== '',
  ).length;
  return (
    <Paper style={{ padding: '2em' }}>
      <Box
        style={{
          display: 'flex',
          gridGap: '1.5em',
        }}
      >
        {primaryAggregation && (
          <div style={{ fontSize: 28 }}>
            <strong>{primaryAggregation.name}</strong> {primaryAggregation.type}
          </div>
        )}
        <TimeWindowSelector
          activeTimeWindow={activeTimeWindow}
          setActiveTimeWindow={setActiveTimeWindow}
        />
        <Button
          color={'primary'}
          onClick={() => setShowFilters(!showFilters)}
          startIcon={<FilterListIcon />}
          variant={'contained'}
        >
          Filters ({filterCount})
        </Button>
      </Box>
      {showFilters && (
        <Box alignItems={'center'} display={'flex'} style={{ marginTop: '1em' }}>
          {secondaryAggregations.map(({ name, type }: InspectedResourceListItem) => (
            <FilterItem defaultValue={name} type={type} updateItem={handleFilterUpdate} />
          ))}
        </Box>
      )}
    </Paper>
  );
};

export interface InspectFilterTileProps {
  activeTimeWindow: string;
  handleFilterUpdate: (type: string, value: string) => Promise<boolean>;
  primaryAggregation?: InspectedResourceListItem;
  secondaryAggregations: InspectedResourceListItem[];
  setActiveTimeWindow: (time: string) => void;
}
