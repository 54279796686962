import { useState } from 'react';

import { Chip, Input } from '@kubecost-frontend/holster';

const EmailRecipients = ({ addItem, recipients, removeItem }: ComponentProps) => {
  const [text, setText] = useState('');

  return (
    <>
      <Input
        data-test={'emailRecipientInput'}
        helperText={'List of email recipients (optional). Press Enter after typing each address.'}
        label={'Add a recipient'}
        onChange={(e) => setText(e.target.value)}
        onKeyPress={(e) => {
          if (e.key.toLowerCase() === 'enter') {
            addItem(text);
            setText('');
          }
        }}
        placeholder={'user@domain.tld'}
        style={{ width: '100%' }}
        value={text}
      />
      {recipients.map((r, i) => (
        <Chip
          color={'default'}
          data-test={'emailRecipientsList'}
          key={r}
          label={r}
          onDelete={() => removeItem(i)}
        />
      ))}
    </>
  );
};

export default EmailRecipients;

interface ComponentProps {
  addItem: (item: string) => void;
  recipients: string[];
  removeItem: (item: number) => void;
}
