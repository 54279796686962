import { useState } from 'react';

import { Box, Button, Divider, TextField, Typography } from '@material-ui/core';
import { default as TooltipMUI } from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import AggregateByControl from '../../components/AggregateByControl';
import { colorMap } from '../../services/colors';

import { AddOnManager } from './AddOn';
import { BaseConfigurations } from './BaseConfigurations';
import { DataSourceGroupingTable } from './DataSourceGroupingTable';
import {
  DropdownControl,
  ReportConfig,
  allocationFilterTypes,
  assetAggregationOptions,
} from './reportingHelpers';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const TabPanel = (props) => {
  const { children, index, value, ...other } = props;

  return (
    <div
      aria-labelledby={`full-width-tab-${index}`}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      role={'tabpanel'}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const EditControls = ({
  labelMap,
  reportConfig,
  saveReport,
}: {
  labelMap: any;
  reportConfig: ReportConfig;
  saveReport: (report: ReportConfig) => void;
}) => {
  const [activeView, setActiveView] = useState<number>(0);
  const [activeReportConfig, setActiveReportConfig] = useState<ReportConfig>(reportConfig);
  const [filterType, setFilterType] = useState(allocationFilterTypes[0].key);
  const [filterText, setFilterText] = useState('');

  const updateAggBy = (aggList: string[]) => {
    setActiveReportConfig({
      ...reportConfig,
      allocationAggregationBy: aggList.toString(),
    });
  };

  const handleSaveReport = () => {
    saveReport(activeReportConfig);
  };

  const reportNeedsSave = reportConfig !== activeReportConfig;

  return (
    <div
      style={{
        padding: '2em',
        display: 'grid',
        gridTemplateColumns: '350px 50px 1fr',
        gap: '2em',
      }}
    >
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}>
          <Typography style={{ marginRight: '1em' }} variant={'h5'}>
            Configure Report
          </Typography>
          <Button
            disabled={!reportNeedsSave}
            onClick={handleSaveReport}
            style={
              !reportNeedsSave
                ? {}
                : {
                    backgroundColor: colorMap.blue,
                    color: 'white',
                    height: 40,
                  }
            }
            variant={'contained'}
          >
            Save
          </Button>
        </div>
        {/* <AppBar
          position="static"
          color="transparent"
          style={{
            background: 'transparent',
            boxShadow: 'none',
            marginBottom: '1em',
          }}
        >
          <Tabs
            value={activeView}
            onChange={(e, newValue: string) => setActiveView(newValue)}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#28B359',
                color: '#28B359'
              },
            }}
            variant="fullWidth"
          >
            <Tab label="Configure" {...a11yProps(0)} />
            <Tab label="Widgets" {...a11yProps(1)} />
          </Tabs>
        </AppBar> */}
        <TextField
          helperText={'Report Name'}
          onChange={(e: any) =>
            setActiveReportConfig({
              ...activeReportConfig,
              name: e.target.value,
            })
          }
          placeholder={'Monthly Breakdown'}
          style={{ width: 300, marginTop: '1em', marginBottom: '1em' }}
          value={activeReportConfig.name}
        />
        <div>
          <Typography component={'div'} variant={'h6'}>
            K8s Allocation Data
          </Typography>
          <div style={{ marginTop: '.5em' }}>
            <AggregateByControl
              aggregateBy={activeReportConfig.allocationAggregationBy.split(',')}
              id={'aggregate-by-control'}
              setAggregateBy={updateAggBy}
              unaggregatedEnabled={false}
            />
          </div>
        </div>
        <div style={{ marginTop: '1em', marginBottom: '1em' }}>
          <Typography component={'div'} variant={'h6'}>
            Cloud Asset Data
          </Typography>
          <DropdownControl
            currentValue={activeReportConfig.assetAggregateBy}
            labelText={'Breakdown to view Asset Data'}
            placeholder={'Asset Breakdown'}
            setCurrentValue={(value: string) => {
              setActiveReportConfig({
                ...reportConfig,
                assetAggregateBy: value,
              });
            }}
            valueList={assetAggregationOptions}
          />
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1em',
            }}
          >
            <Typography component={'div'} style={{ marginRight: '.5em' }} variant={'h6'}>
              Data Source Grouping
            </Typography>
            <TooltipMUI
              title={
                <Typography component={'div'} variant={'body2'}>
                  When combining Asset and Allocation data, we do so using labels. For instance, if
                  you aggregate Allocations by
                  <strong> namespace</strong> unless you've overriden in Settings, then we'll match
                  that in Assets with <strong>label: kubernetes_namespace</strong>. If you'd like to
                  provide custom labels to override, then do so here.
                </Typography>
              }
            >
              <InfoIcon fontSize={'small'} />
            </TooltipMUI>
          </div>
          <DataSourceGroupingTable
            labelMap={labelMap}
            reportConfig={activeReportConfig}
            updateConfig={setActiveReportConfig}
          />
        </div>
      </div>
      <Divider orientation={'vertical'} />
      <div>
        <TabPanel index={0} value={activeView}>
          <BaseConfigurations
            reportConfig={activeReportConfig}
            updateConfig={setActiveReportConfig}
          />
        </TabPanel>
        <TabPanel index={1} value={activeView}>
          <AddOnManager reportConfig={activeReportConfig} />
        </TabPanel>
      </div>
    </div>
  );
};
