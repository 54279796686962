import { FC, ReactNode } from 'react';

import { Typography } from '@kubecost-frontend/holster';

interface SectionCardProps {
  content: ReactNode;
  loading?: boolean;
  title: string;
}

const SectionCard: FC<SectionCardProps> = ({ content, title }) => (
  <div className={'flex flex-col justify-center border border-kc-gray-100'}>
    <div
      className={'align-center flex border-b border-kc-gray-100'}
      style={{
        fontSize: '17.5px',
        padding: '8px 19px',
      }}
    >
      <Typography className={'font-bold'} style={{ flexGrow: 1 }} variant={'p'}>
        {title}
      </Typography>
    </div>
    {content}
  </div>
);

SectionCard.defaultProps = {
  loading: false,
};

export { SectionCard };
