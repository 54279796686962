import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// formik/yup
import { useFormik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';

import { AlertTypes, ClusterHealthAlert } from '../../../services/alerts';
import TestAlertButton from '../TestAlertButton';

interface HealthAlertModalProps {
  alert?: ClusterHealthAlert;
  alertSaved?: (values: ClusterHealthAlert) => void;
  cancelClicked?: () => void;
  open?: boolean;
}

const HealthAlertFormSchema = Yup.object().shape({
  threshold: Yup.number()
    .required('Health score is required.')
    .min(0, 'Health score ranges from 0 to 100!')
    .max(100, 'Health score ranges from 0 to 100!'),
  window: Yup.string().required('Window is required.'),
  slackWebhookUrl: Yup.string().required('Slack Url is required'),
});

const HealthAlertModal: React.FC<HealthAlertModalProps> = ({
  alert,
  alertSaved,
  cancelClicked,
  open = false,
}: HealthAlertModalProps) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    setTouched,
    submitForm,
    touched,
    validateForm,
    values,
  } = useFormik<ClusterHealthAlert>({
    enableReinitialize: true,
    initialValues:
      alert ??
      new ClusterHealthAlert({
        type: AlertTypes.Health,
        window: '',
        threshold: 50,
        slackWebhookUrl: '',
      }),
    onSubmit: (values) => {
      if (!values || !alertSaved) return;
      alertSaved(values);
    },
    validationSchema: HealthAlertFormSchema,
  });

  return (
    <Dialog data-test={'health-alert-dialog'} onClose={cancelClicked} open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle data-testid={'ham-title'}>Enable Cluster Health Monitoring</DialogTitle>
        <DialogContent>
          <Typography style={{ marginBottom: 16 }}>
            With cluster health monitoring enabled, Kubecost will send a message to the designated
            Slack webhook any time the cluster&apos;s Health score deteriorates by more than the
            designated threshold, within the designated time window.
          </Typography>

          <Typography>
            Learn more about Slack webhooks{' '}
            <Link href={'https://api.slack.com/messaging/webhooks'} target={'_blank'}>
              here
            </Link>
            .
          </Typography>
          <FormGroup>
            <TextField
              error={touched.threshold && Boolean(errors.threshold)}
              helperText={
                touched.threshold && errors.threshold
                  ? errors.threshold
                  : 'Health scores range from 0 to 100'
              }
              inputProps={{ 'data-testid': 'ham-healthScore' }}
              label={'Health change threshold'}
              name={'threshold'}
              onChange={handleChange}
              style={{ marginTop: 16 }}
              type={'number'}
              value={values.threshold}
            />
            <TextField
              error={touched.window && Boolean(errors.window)}
              helperText={
                touched.window && errors.window
                  ? errors.window
                  : 'Must be a relative window, such as 7d or 10m'
              }
              inputProps={{ 'data-testid': 'ham-window' }}
              label={'Window'}
              name={'window'}
              onChange={handleChange}
              style={{ marginTop: 16 }}
              value={values.window}
            />
            <TextField
              error={touched.slackWebhookUrl && Boolean(errors.slackWebhookUrl)}
              helperText={touched.slackWebhookUrl && errors.slackWebhookUrl}
              inputProps={{ 'data-testid': 'ham-slackurl' }}
              label={'Slack webhook'}
              name={'slackWebhookUrl'}
              onChange={handleChange}
              style={{ marginTop: 16 }}
              value={values.slackWebhookUrl}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={cancelClicked}>
            Cancel
          </Button>
          <Button color={'primary'} onClick={submitForm} variant={'contained'} disableElevation>
            Save
          </Button>
          <TestAlertButton
            alert={values}
            validate={async () => {
              setTouched({
                threshold: true,
                slackWebhookUrl: true,
                window: true,
              });
              const errors = await validateForm();
              if (Object.keys(errors).length > 0) return false;
              return true;
            }}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export { HealthAlertModal, HealthAlertModalProps };
