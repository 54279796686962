import { FC, useState } from 'react';

import { Button } from '@kubecost-frontend/holster';

import { EllipsisIcon } from '../../assets/images/ellipsis-icon';
import { SaveIcon } from '../../assets/images/save-icon';
import { SettingsIcon } from '../../assets/images/settings-icon';
import { AggregateByControl } from '../../components/AggregateByControlNew';
import { DateSelectorNew } from '../../components/DateSelectorNew';
import { Asset, AssetFilter, AssetReport } from '../../types/asset';

import { aggregationOptions } from './AssetsPage';
import { CustomizeReportMenu } from './CustomizeReportMenu';

interface CustomizeReportHeaderProps {
  accumulate: boolean;
  aggregateBy: string[];
  cumulativeDate: Asset[];
  filters: AssetFilter[];
  report?: AssetReport;
  save: (title: string) => void;
  setAggregateBy: any;
  setEditReportDialog: (open: boolean) => void;
  setOpenReportDialog: (open: boolean) => void;
  setOpenSaveReportDialog: (open: boolean) => void;
  setWindow: (window: string) => void;
  sharedMonthlyCost: number;
  title: string;
  unsave: (report: AssetReport) => void;
  window: string;
}

const windowOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Week-to-date', value: 'week' },
  { label: 'Month-to-date', value: 'month' },
  { label: 'Last week', value: 'lastweek' },
  { label: 'Last month', value: 'lastmonth' },
  { label: 'Last 24h', value: '24h' },
  { label: 'Last 48h', value: '48h' },
  { label: 'Last 7 days', value: '7d' },
  { label: 'Last 15 days', value: '15d' },
  { label: 'Last 30 days', value: '30d' },
  { label: 'Last 60 days', value: '60d' },
  { label: 'Last 90 days', value: '90d' },
  { label: 'Custom', value: '3d' },
];

export const CustomizeReportHeader: FC<CustomizeReportHeaderProps> = ({
  accumulate,
  aggregateBy,
  cumulativeDate,
  filters,
  report,
  setAggregateBy,
  setEditReportDialog,
  setOpenReportDialog,
  setOpenSaveReportDialog,
  setWindow,
  sharedMonthlyCost,
  title,
  window,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleCustomizeClick = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div className={'mt-6 flex justify-between'}>
      <DateSelectorNew setWindow={setWindow} window={window} windowOptions={windowOptions} />
      <div className={'flex items-stretch'}>
        <AggregateByControl
          aggregateBy={aggregateBy}
          aggregateByOptions={aggregationOptions}
          id={'aggregate-by-control'}
          setAggregateBy={setAggregateBy}
          unaggregatedEnabled
        />
        {/* Reenable when reports are migrated
        <Button
          className={'mr-3 flex items-center'}
          onClick={() => setOpenSaveReportDialog(true)}
          variant={'primary'}
        >
          <SaveIcon className={'mr-2'} />
          {report ? 'Unsave Report' : 'Save Report'}
        </Button>
        */}
        <Button
          className={'mr-3 flex items-center'}
          onClick={() => setEditReportDialog(true)}
          variant={'default'}
        >
          <SettingsIcon className={'mr-2'} />
          Edit Report
        </Button>
        <div className={'relative'}>
          <Button onClick={handleCustomizeClick} style={{ height: '100%' }} variant={'default'}>
            <EllipsisIcon />
          </Button>
          {openMenu ? (
            <CustomizeReportMenu
              accumulate={accumulate}
              aggregateBy={aggregateBy}
              cumulativeData={cumulativeDate}
              filters={filters}
              onClose={() => setOpenMenu(false)}
              setOpenReportDialog={setOpenReportDialog}
              sharedMonthlyCost={sharedMonthlyCost}
              title={title}
              window={window}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
