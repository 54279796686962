import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { format } from 'date-fns';

import { Button, Typography, Alert } from '@kubecost-frontend/holster';

import { ArrowIcon, CheckIcon } from '../../assets/images';
import { SettingsIcon } from '../../assets/images/settings-icon';
import { BetaBadge } from '../../components';
import { Header } from '../../components/Header2New';
import { Loading } from '../../components/Loading';
import { useClusters } from '../../contexts/ClusterConfig';
import { useURLStore } from '../../hooks/useURLStore';
import { useAPIClient } from '../../services/APIClient/api-client';
import { isRequestSizerAvailable } from '../../services/clustercontroller/requestsizerv2';
import { toCurrency } from '../../services/format';
import { parseFilters, savingsKeyMap } from '../../services/model';
import { getCurrentContainerAddressModel } from '../../services/util';
import { AllocationSet } from '../../types/allocation';
import { useGetAllocation } from '../AllocationNew/hooks/useGetAllocationSummary';

import { APIRequestSizingParams, useGetRecommendations } from './api';
import { ApplyRecommendations } from './ApplyRecommendations';
import { CustomizeRequestSizingMenu } from './CustomizeRequestSizingMenu';
import { EnableAutoscaling } from './EnableAutoscaling';
import { RequestSizingDrilldown } from './RequestSizingDrilldown';
import { ResultsTable } from './ResultsTable';
import { decoders, encoders, userInputDefaults } from './tokens';
import { AutoscalingWorkloadSchedule, RequestSizingRecommendation } from './types';

// TODO: consolidate these from requestsizer and cluster.ts
// remove /model from service_address
const baseURL = getCurrentContainerAddressModel().substr(
  0,
  getCurrentContainerAddressModel().length - 6,
);

const RequestSizing: FC = () => {
  const { state: userInputs } = useURLStore(encoders, decoders, userInputDefaults);

  const [currency, setCurrency] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [requestSizerAvailable, setRequestSizerAvailable] = useState(false);
  const [openAutoRecommendations, setOpenAutoRecommendations] = useState(false);
  const [isSendingAutoscalingRequest, setIsSendingAutoscalingRequest] = useState(false);
  const [currentAutoscalings, setCurrentAutoscalings] = useState([]);
  const [drillDownRecommendation, setDrillDownRecommendation] =
    useState<RequestSizingRecommendation | null>(null);
  const [drilldownAllocationData, setDrilldownAllocationData] = useState<AllocationSet[]>([]);
  const [openDrilldown, setOpenDrilldown] = useState(false);
  const { modelConfig: config } = useClusters();

  const oldFilters = userInputs.filter?.map((f) => ({
    property: f.property,
    value: f.value,
  }));

  const apiInputs: APIRequestSizingParams = {
    algorithmCPU: userInputs.algorithmCPU,
    algorithmRAM: userInputs.algorithmRAM,
    filter: userInputs.filter,
    itemsPerPage: userInputs.itemsPerPage,
    page: userInputs.page,
    qCPU: userInputs.qCPU,
    qRAM: userInputs.qRAM,
    targetCPUUtilization: userInputs.targetCPUUtilization,
    targetRAMUtilization: userInputs.targetRAMUtilization,
    window: userInputs.window,
  };

  const allocationParams = useMemo(
    () => ({
      window: userInputs.window,
      // Note: By aggregating on an impossible (due to K8s character restrictions) label,
      // the backend aggregates everything into a single "__unallocated__" allocation,
      // thus doing all aggregation math for us.

      aggregate: 'label:!!!impossible!!!',
      idle: false,
      accumulate: false,
      step: '24h',
      // TODO: implement this with v2 filters
      ...parseFilters(oldFilters, savingsKeyMap),
    }),
    [oldFilters, userInputs.window],
  );

  const APIClient = useAPIClient();

  // Hook to fetch reccomendations
  const {
    recommendations = [],
    count,
    totalMonthlySavings,
    isLoading,
    refetch,
    error,
  } = useGetRecommendations(apiInputs);

  // Hook to fetch allocations
  // const { allocations = [] } = useGetAllocation(allocationParams as any);

  const handleOnOpenCustomizeMenu = () => {
    setOpenModal(!openModal);
  };

  const getCurrentAutoScalings = useCallback(() => {
    APIClient.get(`${baseURL}/cluster/kubescaler/resource/requests/schedules`).then((resp) =>
      setCurrentAutoscalings(resp.data),
    );
  }, [APIClient]);

  const handleAutoScalingRequest = (frequency: number, startDate: Date) => {
    setIsSendingAutoscalingRequest(true);
    const workloadPayload: AutoscalingWorkloadSchedule[] = recommendations
      .filter((item: RequestSizingRecommendation) => item.controllerKind === 'deployment')
      .map((rec: RequestSizingRecommendation) => ({
        workload: {
          clusterID: rec.clusterID,
          namespace: rec.namespace,
          controllerKind: rec.controllerKind,
          controllerName: rec.controllerName,
        },
        schedule: {
          start: startDate,
          frequencyMinutes: frequency,
          targetUtilizationCPU: userInputs.targetCPUUtilization,
          targetUtilizationMemory: userInputs.targetRAMUtilization,
        },
      }));

    fetch(`${baseURL}/cluster/kubescaler/resource/requests/schedules/upsert`, {
      method: 'POST',
      body: JSON.stringify(workloadPayload),
    }).then((res) => {
      alert('Successfully enabled autoscaling');
      setIsSendingAutoscalingRequest(false);
      // refetch recs & currentAutoscalings
      getCurrentAutoScalings();
      refetch();
    });
  };

  const handleOnOpenAutoRecommendationsDocuments = () => {
    window.open('https://github.com/kubecost/docs/blob/main/auto-request-sizing.md', '_blank');
  };

  const onRequestSizerAvailable = async () => {
    const sizerAvailable = await isRequestSizerAvailable();
    setRequestSizerAvailable(sizerAvailable);
  };

  const handleOnTableRowSelect = (rec: RequestSizingRecommendation) => {
    setDrillDownRecommendation(rec);

    const workloadFilters = [
      { property: 'cluster', op: ':', value: rec.clusterID },
      { property: 'namespace', op: ':', value: rec.namespace },
      {
        property: 'controllerKind',
        op: ':',
        value: rec.controllerKind,
      },
      {
        property: 'controllerName',
        op: ':',
        value: rec.controllerName,
      },
      { property: 'container', op: ':', value: rec.containerName },
    ];

    const drilldownAllocationParams = {
      window: userInputs.window,
      // Note: By aggregating on an impossible (due to K8s character
      // restrictions) label, the backend aggregates everything into a single
      // "__unallocated__" allocation, thus doing all aggregation math for us.
      // aggregate: 'label:!!!impossible!!!',
      aggregate: '',
      idle: false,
      accumulate: false,
      step: '24h',
      // Parse filters to v2 filter language parameter
      filter: workloadFilters.reduce((acc, current) => {
        if (acc.length > 0) {
          acc += '+';
        }
        acc += `${current.property} ${current.op}"${current.value}"`;
        return acc;
      }, ''),
    };

    APIClient.get<{ data: AllocationSet[] }>('/allocation', {
      params: drilldownAllocationParams,
    }).then(({ data }) => {
      const allocSets = data.data.filter((item) => item !== null);
      // const drilldownAllocations = allocSets
      //   .filter((allocSet) => '__unallocated__' in allocSet)
      //   .map(
      //     // __unallocated__ because we use the impossible aggregation, which aggs
      //     // all data under __unallocated__
      //     // eslint-disable-next-line no-underscore-dangle
      //     (allocSet) => allocSet.__unallocated__,
      //   );
      setDrilldownAllocationData(allocSets);
      setOpenDrilldown(true);
    });
  };

  // const unallocatedAllocs = allocations ? allocations
  //   .filter((allocSet) => '__unallocated__' in allocSet)
  //   .map(
  //     // __unallocated__ because we use the impossible aggregation, which aggs
  //     // all data under __unallocated__
  //     // eslint-disable-next-line no-underscore-dangle
  //     (allocSet) => allocSet.__unallocated__,
  //   ) : [];

  // const avgCpuData = unallocatedAllocs.map((alloc) => {
  //   const datapoint = {
  //     time: format(new Date(alloc.start), 'MM/dd/yyyy'),
  //     requestMillicores: alloc.cpuCoreRequestAverage * 1000,
  //     usageMillicores: alloc.cpuCoreUsageAverage * 1000,
  //     cost: alloc.cpuCost,
  //   };
  //   return datapoint;
  // });

  // const avgRamData = unallocatedAllocs.map((alloc) => {
  //   const datapoint = {
  //     time: format(new Date(alloc.start), 'MM/dd/yyyy'),
  //     requestBytes: alloc.ramByteRequestAverage,
  //     usageBytes: alloc.ramByteUsageAverage,
  //     cost: alloc.ramCost,
  //   };

  //   return datapoint;
  // });

  useEffect(() => {
    setCurrency(config.currencyCode);
    getCurrentAutoScalings();
  }, [config.currencyCode, getCurrentAutoScalings]);

  useEffect(() => {
    onRequestSizerAvailable();
  }, []);

  // TODO: Paginate table
  return (
    <>
      <Header
        refreshCallback={refetch}
        title={
          <>
            <div className={'flex items-center'}>
              Request right-sizing recommendations
              <Typography className={'pl-2'} variant={'h6'}>
                <BetaBadge
                  message={
                    'This calculation is being handled by a beta API and should be used with caution!'
                  }
                />
              </Typography>
            </div>
            <Typography className={'font-normal text-kc-gray-200'} variant={'p'}>
              <a href={'savings'}>
                <ArrowIcon className={'inline'} direction={'UP'} />
                Back to savings
              </a>
            </Typography>
          </>
        }
      />
      {error && (
        <Alert
          className='mb-8'
          content={
            <>
              <Typography variant='p'>{error.response?.data.toString()}</Typography>
            </>
          }
          title={`Error: ${error.response?.status} - ${error.response?.statusText}`}
          variant={'danger'}
        />
      )}
      <section className={'mb-8'}>
        <div className={'flex'}>
          <Typography className={'text-kc-gray-200'} variant={'h5'}>
            Estimated available savings:
          </Typography>
          <Typography className={'text-kc-success'} variant={'h5'}>
            &nbsp;
            {toCurrency(totalMonthlySavings || 0, currency)}
            /mo
          </Typography>
        </div>
      </section>

      <main>
        <div className={'flex'}>
          <Button
            className={'h-33px mr-3 flex items-center'}
            onClick={handleOnOpenCustomizeMenu}
            variant={'default'}
          >
            <SettingsIcon className={'mr-2'} />
            Customize
          </Button>

          {recommendations !== undefined && !requestSizerAvailable && (
            <Button
              className={'h-33px mr-3 flex items-center'}
              onClick={handleOnOpenAutoRecommendationsDocuments}
              variant={'default'}
            >
              <CheckIcon className={'mr-2'} />
              Set Up Auto Recommendations
            </Button>
          )}
          {recommendations !== undefined && requestSizerAvailable && (
            <ApplyRecommendations
              open={openAutoRecommendations}
              recommendations={recommendations}
              setOpen={setOpenAutoRecommendations}
            />
          )}
          {recommendations !== undefined && requestSizerAvailable && (
            <EnableAutoscaling
              handleAutoscalingRequest={handleAutoScalingRequest}
              loading={isSendingAutoscalingRequest}
            />
          )}
          <CustomizeRequestSizingMenu onClose={handleOnOpenCustomizeMenu} open={openModal} />
        </div>
        <div className={'mt-8'}>
          {recommendations === undefined || isLoading ? (
            <Loading message={'Loading'} />
          ) : (
            <ResultsTable
              currency={currency}
              currentAutoscalings={currentAutoscalings}
              onRowSelect={handleOnTableRowSelect}
              recommendations={recommendations!}
              totalRecommendations={count ?? 0}
            />
          )}
        </div>
      </main>
      {openDrilldown && drillDownRecommendation && drilldownAllocationData && (
        <RequestSizingDrilldown
          allocationData={drilldownAllocationData}
          currency={currency}
          open={openDrilldown}
          recommendation={drillDownRecommendation}
          setOpen={setOpenDrilldown}
          title={'Workload Savings'}
        />
      )}
    </>
  );
};

RequestSizing.displayName = 'RequestSizing';
export { RequestSizing };
