import { FC, useEffect, useState } from 'react';

import { Card, IconButton, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/styles';

import { model } from '../../../services/model';

import { BackupStatus } from './types';

const useStyles = makeStyles({
  card: {
    paddingTop: '1em',
  },
  headerRow: {
    width: '100%',
    display: 'inline-flex',
    gap: '1em',
  },
  header: {
    marginBottom: '1em',
    paddingLeft: '1em',
  },
  helpButton: {
    flexGrow: 1,
    padding: 0,
    paddingTop: '4px',
  },
  cloudRow: {
    display: 'inline-flex',
    width: '100%',
    boxSizing: 'border-box',
    gap: '1em',
    padding: '1em',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      cursor: 'pointer',
    },
  },
  cloudRowIcon: {
    height: '33px',
    width: '33px',
    color: 'gray',
  },
  cloudRowHeader: {
    flexGrow: 1,
    display: 'inline',
  },
  eventRow: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
});

export const BackupStatusPanel: FC = () => {
  const classes = useStyles();
  const [backups, setBackups] = useState<BackupStatus>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  async function fetchBackups(): Promise<any> {
    if (isFetching) {
      setLoading(true);

      try {
        const { data }: any = await model.getEtlConfig();
        setBackups(data);
      } catch (e) {
        console.error(e);
      }

      setLoading(false);
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchBackups();
  }, []);

  return (
    <>
      {!loading && (
        <Card className={classes.card} variant={'outlined'}>
          <div className={classes.headerRow}>
            <Typography className={classes.header} variant={'h6'}>
              File Backup
            </Typography>

            <div>
              <IconButton
                className={classes.helpButton}
                href={'https://guide.kubecost.com/hc/en-us/articles/4407601811095-ETL-Backup'}
                target={'_blank'}
              >
                <HelpIcon />
              </IconButton>
            </div>
            <div
              style={{
                textAlign: 'right',
                flexGrow: 1,
                paddingRight: '1em',
                fontWeight: 500,
                paddingTop: '7px',
              }}
            >
              {backups && backups.fileBackupEnabled ? 'Enabled' : 'Disabled'}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

BackupStatusPanel.displayName = 'BackupStatusPanel';
