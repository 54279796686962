import { Button, Menu, MenuItem } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { MouseEvent, useState } from 'react';

type ReportActionMenuProps = {
  deleteClicked: () => void;
  readonly?: boolean;
};

const ReportActionMenu = ({ deleteClicked, readonly = false }: ReportActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenActionMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls={open ? 'actions-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup={'true'}
        data-testid={'menu-button'}
        id={'action-positioned-button'}
        onClick={handleOpenActionMenu}
      >
        ...
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id={'actions-menu'}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Tooltip title={readonly ? 'Only admin or editor users may delete reports' : ''}>
          <span>
            <MenuItem disabled={readonly} onClick={deleteClicked}>
              Delete
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
    </>
  );
};

export { ReportActionMenu };
