import { SyntheticEvent, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { endOfDay, isValid, startOfDay } from 'date-fns';
import find from 'lodash/find';
import get from 'lodash/get';

import Popover from '@material-ui/core/Popover';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { Button, Typography } from '@kubecost-frontend/holster';

interface SelectWindowProps {
  setWindow: (window: string) => void;
  window: string;
  windowOptions: { label: string; value: string }[];
}

const DateSelectorNew = ({ setWindow, window, windowOptions }: SelectWindowProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [intervalString, setIntervalString] = useState<string | null>(null);

  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    if (isValid(date)) {
      setStartDate(startOfDay(date));
    }
  };

  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    if (isValid(date)) {
      setEndDate(endOfDay(date));
    }
  };

  const handleSubmitPresetDates = (dateString: string) => {
    setWindow(dateString);
    setStartDate(null);
    setEndDate(null);
    handleClose();
  };

  const handleSubmitCustomDates = () => {
    if (intervalString !== null) {
      setWindow(intervalString);
      handleClose();
    }
  };

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      // Selecting a date should result in a start date at time 00:00:00.000
      // and an end date of midnight, i.e. the following day at 00:00:00.000.
      // This is a hacky solution to say that, if the date picked is
      // 2021-06-20T23:59:59, then make endDate 2021-06-21T00:00:00.
      //
      // NOTE: We should consider, perhaps, an API-side fix that rounds-up
      // 23:59:59 to midnight, but this will work until then.

      const s = new Date(startDate);
      s.setHours(0);
      s.setMinutes(0);
      s.setSeconds(0);
      s.setMilliseconds(0);

      const e = new Date(endDate);
      e.setDate(e.getDate() + 1);
      e.setHours(0);
      e.setMinutes(0);
      e.setSeconds(0);
      e.setMilliseconds(0);

      // Note: getTimezoneOffset() is calculated based on current system locale, NOT date object
      const adjustedStartDate = new Date(s - s.getTimezoneOffset() * 60000);
      const adjustedEndDate = new Date(e - e.getTimezoneOffset() * 60000);

      const startStr = adjustedStartDate.toISOString().split('.')[0];
      const endStr = adjustedEndDate.toISOString().split('.')[0];
      setIntervalString(`${startStr}Z,${endStr}Z`);
    }
  }, [startDate, endDate]);

  const open = Boolean(anchorEl);
  const id = open ? 'date-range-popover' : undefined;

  return (
    <>
      <Button onClick={(e) => handleClick(e)} variant={'default'}>
        {get(find(windowOptions, { value: window }), 'label', 'Custom')}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={'min-width-400 p-4'}>
          <div
            className={'align-center flex justify-center'}
            style={{
              gap: '.5em',
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                format={'MM/dd/yyyy'}
                id={'date-range-control-custom-start'}
                label={'Start Date'}
                margin={'normal'}
                maxDate={new Date()}
                maxDateMessage={'Date should not be after today.'}
                onChange={handleStartDateChange}
                style={{ width: '144px' }}
                value={startDate}
                variant={'inline'}
                autoOk
                disableToolbar
              />
              <KeyboardDatePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                format={'MM/dd/yyyy'}
                id={'date-range-control-custom-end'}
                label={'End Date'}
                margin={'normal'}
                maxDate={new Date()}
                maxDateMessage={'Date should not be after today.'}
                onChange={handleEndDateChange}
                style={{ width: '144px' }}
                value={endDate}
                variant={'inline'}
                autoOk
                disableToolbar
              />
            </MuiPickersUtilsProvider>
          </div>
          <div
            className={'mt-4 grid justify-center p-4'}
            style={{
              gridTemplateColumns: '1fr 1fr',
            }}
          >
            {windowOptions.map((opt) => (
              <Typography
                className={`mr-6 cursor-pointer rounded p-2 hover:bg-kc-success-light ${
                  get(find(windowOptions, { value: window }), 'label') === opt.label
                    ? 'text-kc-link'
                    : ''
                }`}
                key={opt.value}
                onClick={() => handleSubmitPresetDates(opt.value)}
                variant={'p'}
              >
                {opt.label}
              </Typography>
            ))}
          </div>
          <div
            className={'mt-4 flex justify-end'}
            style={{
              gap: '.5em',
            }}
          >
            <Button onClick={handleClose} variant={'default'}>
              Cancel
            </Button>
            <Button onClick={handleSubmitCustomDates} variant={'primary'}>
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export { DateSelectorNew };
