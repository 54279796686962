import { AuthService } from './PKCE';
import { APIClient } from './services/APIClient';

import { HostedConfig } from '.';

export const InitializeHosted = async (config: HostedConfig) => {
  window.localStorage.setItem('container', config.costModelAPIUrl);

  // Starting up new Auth Service. Flush the PKCE Config.
  const authService = new AuthService({
    clientId: config.oauth.clientId,
    location: window.location,
    provider: config.oauth.provider,
    redirectUri: config.oauth.redirectUri,
    contentType: 'application/json',
    scopes: [],
    autoRefresh: false,
  });

  await authService.initialize();

  // We're pending, we actually need to reset.
  // This happens when a user is redirected to the auth service
  // But never completes the auth flow.
  if (authService.isPending()) {
    window.localStorage.removeItem('pkce');
  }

  // Not Pending, and not Authenticated.
  // Start the PKCE Process.
  if (!authService.isAuthenticated() && !authService.isPending()) {
    authService.authorize();
  }

  // We've made it this far, the authentication process is complete.
  // Set the API Client Token
  const authTokens = JSON.parse(localStorage.getItem('auth') ?? '');
  const bearerToken = (authTokens && authTokens.access_token) || '';
  APIClient.defaults.headers.common.Authorization = `Bearer ${bearerToken}`;

  // Fetch the profile prior to rendering the page.
  const profileData = await APIClient.get(`${config.userServiceAPIUrl}/api/user`);

  return {
    authService,
    profileData: profileData.data,
    config,
  };
};
