import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/styles';
import isArray from 'lodash/isArray';
import * as React from 'react';

import { toVerboseTimeRange } from '../../services/format';
import { AllocationContext } from '../../types/allocation';

const useStyles = makeStyles({
  root: {
    '& > * + *': {
      marginTop: 2,
    },
  },
  link: {
    cursor: 'pointer',
  },
});

type SubtitleProps = {
  clearContext: () => void;
  context: AllocationContext;
  goToContext: (i: number) => void;
  report: { aggregateBy: string[]; window: string };
};

const Subtitle = ({ clearContext, context, goToContext, report }: SubtitleProps) => {
  const classes = useStyles();

  const { aggregateBy, window } = report;

  const aggByStr = isArray(aggregateBy) ? aggregateBy.join(', ') : '';

  if (!isArray(context) || context.length === 0) {
    return (
      <div className={classes.root}>
        <Breadcrumbs aria-label={'breadcrumb'} separator={<NavigateNextIcon fontSize={'small'} />}>
          {aggByStr ? (
            <Typography>
              {toVerboseTimeRange(window)} by {aggByStr}
            </Typography>
          ) : (
            <Typography>{toVerboseTimeRange(window)}</Typography>
          )}
        </Breadcrumbs>
      </div>
    );
  }

  const handleBreadcrumbClick =
    (i: number, cb: (i: number) => void) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      cb(i);
    };

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label={'breadcrumb'} separator={<NavigateNextIcon fontSize={'small'} />}>
        <Button className={classes.link} color={'inherit'} onClick={() => clearContext()}>
          <Typography>
            {toVerboseTimeRange(window)} by {context[0].property.toLowerCase()}
          </Typography>
        </Button>
        {context.map((ctx, c) =>
          c === context.length - 1 ? (
            <Tooltip key={ctx.property} title={ctx.property} arrow>
              <Typography style={{ cursor: 'default' }}>{ctx.name}</Typography>
            </Tooltip>
          ) : (
            <Button
              className={classes.link}
              color={'inherit'}
              key={ctx.property}
              onClick={handleBreadcrumbClick(c, goToContext)}
            >
              <Tooltip title={ctx.property} arrow>
                <Typography>{ctx.name}</Typography>
              </Tooltip>
            </Button>
          ),
        )}
      </Breadcrumbs>
    </div>
  );
};

export default React.memo(Subtitle);
