import { model } from '../model';

enum SpotReady {
  No = 'Not spot ready',
  Maybe = 'May be spot ready',
  Yes = 'Spot ready',
  NA = 'Not applicable',
}

type ChecklistCheck = {
  longMessage: string;
  ready: SpotReady;
  shortMessage: string;
};

type Checklist = {
  controllerAnnotationOverrideCheck: ChecklistCheck;
  controllerPDBCheck: ChecklistCheck;
  controllerTypeCheck: ChecklistCheck;
  deploymentRollingUpdateCheck: ChecklistCheck;
  localStorageCheck: ChecklistCheck;
  namespaceAnnotationOverrideCheck: ChecklistCheck;
  replicasCheck: ChecklistCheck;
};

type ChecklistResult = {
  checklist: Checklist;
  kind: string;
  name: string;
  namespace: string;
  readiness: SpotReady;
};

type ChecklistResponse = ChecklistResult[];

// fetchSpotChecklist returns the cluster controllers with a spot ready checklist criteria
async function fetchSpotChecklist(): Promise<ChecklistResponse> {
  const result: Promise<ChecklistResponse> = model.get('/savings/spotChecklist');
  return result;
}

type EstimatedChecklist = {
  checklistResult: ChecklistResult;
  errors: string[];
  monthlyRate: number;
  monthlySavings: number;
  monthlySavingsPct: number;

  monthlySpotRate: number;
};

type EstimatedChecklistResponse = EstimatedChecklist[];

async function fetchSpotChecklistEstimated(window: string): Promise<EstimatedChecklistResponse> {
  const result: Promise<EstimatedChecklistResponse> = model.get('/savings/spotChecklistEstimated', {
    window,
  });
  return result;
}

export {
  Checklist,
  ChecklistCheck,
  ChecklistResponse,
  ChecklistResult,
  EstimatedChecklist,
  EstimatedChecklistResponse,
  SpotReady,
  fetchSpotChecklist,
  fetchSpotChecklistEstimated,
};
