import { FC } from 'react';

import { NavIconProps } from './types';

const OverviewNavIcon: FC<NavIconProps> = ({ active, ...args }) => (
  <svg
    fill={'none'}
    height={'30'}
    viewBox={'0 0 30 30'}
    width={'30'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...args}
  >
    <mask fill={'white'} id={'path-1-inside-1_2_45'}>
      <rect height={'8.75'} rx={'1'} width={'8.75'} x={'5'} y={'5'} />
    </mask>
    <rect
      height={'8.75'}
      mask={'url(#path-1-inside-1_2_45)'}
      rx={'1'}
      stroke={active ? '#63E892' : '#607971'}
      strokeWidth={'3'}
      width={'8.75'}
      x={'5'}
      y={'5'}
    />
    <mask fill={'white'} id={'path-2-inside-2_2_45'}>
      <rect height={'8.75'} rx={'1'} width={'8.75'} x={'5'} y={'16.25'} />
    </mask>
    <rect
      height={'8.75'}
      mask={'url(#path-2-inside-2_2_45)'}
      rx={'1'}
      stroke={active ? '#63E892' : '#607971'}
      strokeWidth={'3'}
      width={'8.75'}
      x={'5'}
      y={'16.25'}
    />
    <mask fill={'white'} id={'path-3-inside-3_2_45'}>
      <rect height={'8.75'} rx={'1'} width={'8.75'} x={'16.25'} y={'5'} />
    </mask>
    <rect
      height={'8.75'}
      mask={'url(#path-3-inside-3_2_45)'}
      rx={'1'}
      stroke={active ? '#63E892' : '#607971'}
      strokeWidth={'3'}
      width={'8.75'}
      x={'16.25'}
      y={'5'}
    />
    <mask fill={'white'} id={'path-4-inside-4_2_45'}>
      <rect height={'8.75'} rx={'1'} width={'8.75'} x={'16.25'} y={'16.25'} />
    </mask>
    <rect
      height={'8.75'}
      mask={'url(#path-4-inside-4_2_45)'}
      rx={'1'}
      stroke={active ? '#63E892' : '#607971'}
      strokeWidth={'3'}
      width={'8.75'}
      x={'16.25'}
      y={'16.25'}
    />
  </svg>
);
export { OverviewNavIcon };
