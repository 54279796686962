import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

import { primary } from '../../../services/colors';
import { toCurrency } from '../../../services/format';
import { AllocationOverTimeSet } from '../HistoricalCostBarChart';

interface HistoricalCostAreaChartProps {
  currency?: string;
  dataSet: AllocationOverTimeSet[];
  resourceTypes: string[];
  width: number;
}

export const GraphBreakdown = ({
  currency,
  dataSet,
  resourceTypes,
  width,
}: HistoricalCostAreaChartProps) => (
  <AreaChart
    data={dataSet}
    height={250}
    margin={{ top: 10, right: 30, left: 30, bottom: 0 }}
    width={width}
  >
    <defs>
      {resourceTypes.map((type: string, idx: number) => (
        <linearGradient id={primary[idx] + type} x1={'0'} x2={'0'} y1={'0'} y2={'1'}>
          <stop offset={'15%'} stopColor={primary[idx]} stopOpacity={0.4} />
          <stop offset={'99%'} stopColor={primary[idx]} stopOpacity={0.1} />
        </linearGradient>
      ))}
    </defs>
    <XAxis dataKey={'name'} />
    <YAxis tickFormatter={(val: number) => toCurrency(val, currency, undefined, true)} />
    <Tooltip formatter={(val) => toCurrency(val, currency)} />
    <CartesianGrid strokeDasharray={'5 5'} />
    {resourceTypes.map((type: string, idx: number) => (
      <Area dataKey={type} fillOpacity={0} stroke={primary[idx]} type={'monotone'} />
    ))}
  </AreaChart>
);
