import { Button, Paper, Typography } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import { makeStyles, withStyles } from '@material-ui/styles';

import { version } from '../../services/env';

const useStyles = makeStyles({
  card: {
    margin: '12px 12px 12px 12px',
    display: 'flex',
    flexFlow: 'column',
    padding: '12px 0px 12px 0px',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    verticalAlign: 'middle',
  },
  versionText: {
    paddingTop: '4px',
    paddingRight: '12px',
  },
});

// Orange Update Button
const UpdateButton = withStyles(() => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: orange[400],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Button);

// App Info Card
const AppInfo = ({ id, isUpdate, nodeCount }) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} id={id}>
      <div className={classes.card}>
        <Typography variant={'h5'} gutterBottom>
          App Info
        </Typography>
        <div className={classes.content}>
          <Typography className={classes.versionText} color={'textSecondary'} variant={'subtitle1'}>
            Kubecost v{version}
          </Typography>
          <div style={{ flex: '1 1 0%' }} />
          {isUpdate && (
            <UpdateButton
              color={'primary'}
              href={''}
              onClick={() => window.open('https://kubecost.com/install#update-kubecost', '_blank')}
              variant={'contained'}
              disableElevation
            >
              UPDATE AVAILABLE
            </UpdateButton>
          )}
        </div>
        <div className={classes.content}>
          <List>
            <Typography variant={'body2'}>
              <b>Node Usage over last 7 days</b>
            </Typography>
            <Typography color={'textSecondary'} variant={'body2'}>
              Max: {nodeCount.max} Avg: {nodeCount.avg}
            </Typography>
          </List>
        </div>
      </div>
    </Paper>
  );
};

export default AppInfo;
