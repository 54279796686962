import { model } from '../../../services/model';

export async function getUnclaimedVolumeData() {
  const { items } = await model.get<{ items: ApiPersistentVolume[] }>('/allPersistentVolumes');
  return items
    .filter((item) => item.status.phase !== 'Bound')
    .map((item) => ({
      name: item.metadata.name,
      owner: item.metadata?.labels?.owner || '',
      size: item.spec.capacity.storage,
      storageClass: item.spec.storageClassName,
    }));
}

interface ApiPersistentVolume {
  metadata: {
    annotation: Record<string, string>;
    creationTimestamp: string;
    finalizers: string[];
    labels: Record<string, string>;
    managedFields: Record<string, string>[];
    name: string;
    resourceVersion: string;
    uid: string;
  };
  spec: {
    accessModes: string[];
    capacity: { storage: string };
    claimRef: {
      apiVersion: string;
      kind: string;
      namespace: string;
      resourceVersion: string;
      uid: string;
    };
    gcePersistentDisk: {
      fsType: string;
      pdName: string;
    };
    // nodeAffinity: {
    //   required: {
    //     nodeSelectorTerms:
    //   };
    // };
    persistentVolumeReclaimPolicy: string;
    storageClassName: string;
    volumeMode: string;
  };
  status: {
    phase: string;
  };
}
