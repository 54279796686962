import { FC } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import { TableCell, TableRow } from '@kubecost-frontend/holster';

import { useClusters } from '../../../contexts/ClusterConfig';
import { toCurrency } from '../../../services/format';

interface ComponentProps {
  costSuffix: string;
  cpuCost: number;
  cpuRequest: number;
  drillDown: null | (() => void);
  efficiency: number;
  gpuCost: number;
  isIdle: boolean;
  isUnmounted: boolean;
  loadBalancerCost: number;
  name: string | JSX.Element;
  networkCost: number;
  pvCost: number;
  ramCost: number;
  ramRequest: number;
  sharedCost: number;
  totalCost: number;
}

const AllocationReportRow: FC<ComponentProps> = ({
  costSuffix,
  cpuCost,
  cpuRequest,
  drillDown,
  efficiency,
  gpuCost,
  isIdle,
  isUnmounted,
  loadBalancerCost,
  name,
  networkCost,
  pvCost,
  ramCost,
  ramRequest,
  sharedCost,
  totalCost,
}: ComponentProps) => {
  const { modelConfig } = useClusters();
  const tooltipText = drillDown ? '' : 'Cannot drill down';
  const showEfficiency = !(isIdle || isUnmounted);
  let shownEfficiency;
  if (!showEfficiency) {
    shownEfficiency = <>&mdash;</>;
  } else if (efficiency === 1 && !(cpuRequest || ramRequest)) {
    shownEfficiency = 'Inf';
  } else {
    shownEfficiency = `${(efficiency * 100).toFixed(1)}%`;
  }

  return (
    <Tooltip title={tooltipText}>
      <TableRow
        onClick={() => {
          if (drillDown) {
            drillDown();
          }
        }}
        style={{ cursor: drillDown ? 'pointer' : 'auto' }}
      >
        {/* row name */}
        <TableCell align={'left'}>{name}</TableCell>

        {/* cpu cost */}
        <TableCell align={'right'}>
          {`${toCurrency(cpuCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* gpu cost */}
        <TableCell align={'right'}>
          {`${toCurrency(gpuCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* ram cost */}
        <TableCell align={'right'}>
          {`${toCurrency(ramCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* pv cost */}
        <TableCell align={'right'}>
          {`${toCurrency(pvCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* network cost */}
        <TableCell align={'right'}>
          {`${toCurrency(networkCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* load balancer cost */}
        <TableCell align={'right'}>
          {`${toCurrency(loadBalancerCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* shared  cost */}
        <TableCell align={'right'}>
          {`${toCurrency(sharedCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* efficiency */}
        <TableCell align={'left'}>{shownEfficiency}</TableCell>

        {/* total cost */}
        <TableCell align={'right'} style={{ paddingRight: '2em' }}>
          {`${toCurrency(totalCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>
      </TableRow>
    </Tooltip>
  );
};

export { AllocationReportRow };
