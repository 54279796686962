import { FC } from 'react';

import Grid from '@material-ui/core/Grid';

import { Typography } from '@kubecost-frontend/holster';

interface ApplyExceptionsProps {
  exception: string;
  header: string;
}

export const ApplyExceptions: FC<ApplyExceptionsProps> = ({ exception, header }) => (
  <Grid container>
    <Grid xs={12} item>
      <Typography variant={'h5'}>{header}</Typography>
    </Grid>
    <Grid item>
      <Typography variant={'h6'}>{exception}</Typography>
    </Grid>
  </Grid>
);

ApplyExceptions.displayName = 'ApplyExceptions';
