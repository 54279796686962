import { MouseEvent, ReactNode } from 'react';

interface ActionMenuItemProps {
  children: ReactNode;
  className?: string;
  isSelected?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

const ActionMenuItem = ({ children, className, isSelected, onClick }: ActionMenuItemProps) => (
  <button
    aria-pressed={isSelected}
    className={`p-2 ${isSelected ? 'text-kc-link' : ''} ${className || ''}`}
    onClick={onClick}
    type={'button'}
  >
    {children}
  </button>
);

export { ActionMenuItem };
