import { useState } from 'react';

import { Grid, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import logger from '../../services/logger';
import { model } from '../../services/model';

import { Transition } from './DialogTransition';

interface AWSConfigModalProps {
  closeModal: () => void;
  visible: boolean;
}

interface AWSFormItem {
  dataKey: string;
  label: string;
}

const AWSFormItems: AWSFormItem[] = [
  { label: 'Athena Region', dataKey: 'athenaRegion' },
  { label: 'Athena Database', dataKey: 'athenaDatabase' },
  { label: 'Athena Tablename', dataKey: 'athenaTable' },
  { label: 'Athena Result Bucket', dataKey: 'athenaBucketName' },
  { label: 'AWS account ID', dataKey: 'projectID' },
  { label: 'Service key name', dataKey: 'serviceKeyName' },
  { label: 'Service key secret', dataKey: 'serviceKeySecret' },
];

const defaultAwsConfigPayload = {
  athenaRegion: '',
  athenaDatabase: '',
  athenaTable: '',
  athenaBucketName: '',
  projectID: '',
  serviceKeyName: '',
  serviceKeySecret: '',
};

export const AWSConfigModal = ({ closeModal, visible }: AWSConfigModalProps) => {
  const [awsConfigPayload, setAwsConfigPayload] = useState(defaultAwsConfigPayload);
  const handleUpdate = async () => {
    try {
      await model.updateAthenaInfoConfigs(awsConfigPayload);
    } catch (err) {
      logger.log(err);
    }
    closeModal();
  };

  return (
    <div>
      <Dialog
        TransitionComponent={Transition as any}
        onClose={closeModal}
        open={visible}
        keepMounted
      >
        <DialogTitle>Billing Data Export Configuration</DialogTitle>
        <DialogContent>
          <Typography variant={'body2'}>
            To allocate out of cluster AWS costs (e.g. S3 buckets and RDS instances), enable billing
            data export and tag assets appropriately.{' '}
            <a
              href={'https://guide.kubecost.com/hc/en-us/articles/4407596810519'}
              target={'_blank'}
            >
              Learn More
            </a>
          </Typography>
          {AWSFormItems.map((item: AWSFormItem) => (
            <Grid spacing={3} style={{ marginTop: '.5em' }} container>
              <Grid xs={6} item>
                <Typography variant={'body1'}>{item.label}</Typography>
              </Grid>
              <Grid xs={6} item>
                <TextField
                  id={'outlined-basic'}
                  label={item.label}
                  onChange={(e: any) =>
                    setAwsConfigPayload({
                      ...awsConfigPayload,
                      [`${item.dataKey}`]: e.target.value,
                    })
                  }
                  variant={'outlined'}
                  fullWidth
                />
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={closeModal}>
            Close
          </Button>
          <Button color={'primary'} onClick={handleUpdate}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
