import { FormControlLabel, Switch } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';

import AlertsList from '../../components/AlertsList';
import { Header } from '../../components/Header';
import { HelpIconLink } from '../../components/HelpIconLink';
import { If } from '../../components/If';
import { FetchStates } from '../../constants';
import {
  AlertTypes,
  Alert,
  BudgetAlert,
  ClusterHealthAlert,
  DiagnosticAlert,
  RBACUnauthorizedError,
  AlertService
} from '../../services/alerts';
import { analytics } from '../../services/analytics';
import Logger from '../../services/logger';

import HealthAlert from './ClusterHealthAlerts/HealthAlert';
import CreateAlertModal from './CreateAlertModal';
import CreateDiagnosticAlertModal from './CreateDiagnosticAlertModal';
import DeleteAlertModal from './DeleteAlertModal';
import EmailRecipients from './EmailRecipients';
import TestAlertButton from './TestAlertButton';

const useStyles = makeStyles({
  formControl: {
    marginBottom: 16,
  },
  progress: {
    position: 'fixed',
    width: '100%',
    top: 0,
  },
  toolbar: {
    justifyContent: 'flex-end',
  },
  content: {
    padding: 32,
  },
  helpIcon: {
    color: '#999',
    fontSize: '1.2rem',
    cursor: 'pointer',
  },
  tooltip: {
    fontSize: '.9rem',
  },
});

const Notifications = () => {
  const classes = useStyles();

  // alerts
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [tableAlerts, setTableAlerts] = useState<Alert[]>([]);
  const [displayedAlerts, setDisplayedAlerts] = useState<Alert[]>([]);
  const [alertListFilter, setAlertListFilter] = useState('');
  const [diagnosticAlert, setDiagnosticAlert] = useState<Alert | null>(null);
  const [healthAlert, setHealthAlert] = useState<Alert | null>(null);

  // alert being created / edited
  const [newAlert, setNewAlert] = useState<Alert | null>(null);
  const [newDiagnosticAlert, setNewDiagnosticAlert] = useState<DiagnosticAlert | null>(null);
  const [newHealthAlert, setNewHealthAlert] = useState<ClusterHealthAlert | null>(null);

  // alert selected for removal
  const [deletingAlert, setDeletingAlert] = useState<Alert | null>(null);

  // global settings
  const [globalEmails, setGlobalEmails] = useState<string[]>([]);
  const [globalSlack, setGlobalSlack] = useState('');
  const [globalSlackDirty, setGlobalSlackDirty] = useState(false);
  const [globalLinkback, setGlobalLinkback] = useState('');

  // effects to establish different alert subsets

  // initial request to fetch all alerts
  useEffect(() => {
    fetchData();
  }, []);

  // differentiate diagnostic and health alerts from other types.
  // other types belong in the table. Diagnostic and Health get their own controls.
  useEffect(() => {
    const da = alerts.find((a) => a.type === 'diagnostic');
    const ha = alerts.find((a) => a.type === 'health');
    const ta = alerts.filter((a) => !['diagnostic', 'health'].includes(a.type));
    setDiagnosticAlert(da || null);
    setHealthAlert(ha || null);
    setTableAlerts(ta);
  }, [alerts]);

  // determine alerts to display in the table
  // this is tableAlerts, less any alerts failing the search filter
  useEffect(() => {
    const das = tableAlerts
      .filter(
        (a) =>
          a.type.toLowerCase().includes(alertListFilter.toLowerCase()) ||
          a.window.toLowerCase().includes(alertListFilter.toLowerCase()) ||
          a.aggregation.toLowerCase().includes(alertListFilter.toLowerCase()) ||
          a.filter.toLowerCase().includes(alertListFilter.toLowerCase()),
      )
      .sort((a, b) => {
        if (a.type > b.type) {
          return 1;
        }
        if (a.type < b.type) {
          return -1;
        }
        if (a.aggregation > b.aggregation) {
          return 1;
        }
        if (b.aggregation > a.aggregation) {
          return -1;
        }
        if (a.filter > b.filter) {
          return 1;
        }
        return -1;
      });
    setDisplayedAlerts(das);
  }, [tableAlerts, alertListFilter]);

  // data initialization
  const [fetchState, setFetchState] = useState(FetchStates.INIT);

  // user messages
  const [message, setMessage] = useState('');
  const [goodMessage, setGoodMessage] = useState('');

  return (
    <>
      {/* heading */}
      <Header breadcrumbs={[{ href: '', name: 'Alerts' }]}>
        <Toolbar className={classes.toolbar}>
          <IconButton>
            <RefreshIcon onClick={() => location.reload()} />
          </IconButton>
          <Link component={RouteLink} to={'/settings'}>
            <IconButton>
              <SettingsIcon />
            </IconButton>
          </Link>
          <HelpIconLink
            href={'https://docs.kubecost.com/alerts.html'}
            tooltipText={'Alerts Documentation'}
          />
        </Toolbar>
      </Header>

      <Paper style={{ padding: 14 }}>
        <Typography variant={'body1'}>
          Kubecost alerts allow teams to receive updates on real-time Kubernetes spend. They are
          configurable via the Kubecost UI or via Helm values.
          <br />
          <Link
            href={'https://guide.kubecost.com/hc/en-us/articles/4407601796759-Alerts-Documentation'}
            target={'_blank'}
          >
            Learn More
          </Link>
        </Typography>
      </Paper>

      {/* app-wide settings */}
      <If condition={fetchState === FetchStates.LOADING}>
        <CircularProgress style={{ margin: 'auto' }} />
      </If>

      <If condition={fetchState === FetchStates.ERROR}>
        <Typography style={{ textAlign: 'center' }} variant={'h5'}>
          Something went wrong. Check console for details.
        </Typography>
      </If>

      <If condition={fetchState === FetchStates.DONE}>
        {/* health and diagnostics alerts */}
        <Paper className={classes.content} style={{ marginTop: 16 }}>
          <Typography variant={'h6'}>Cluster and Kubecost Health Alerts</Typography>
          <Typography>
            Activating these alerts monitors the health of Kubecost, as well as that of the cluster
            running Kubecost.
          </Typography>
          <HealthAlert />
          <Box alignItems={'center'} display={'flex'} style={{ marginTop: 8 }}>
            <Box width={260}>
              <FormControlLabel
                checked={!!diagnosticAlert}
                control={<Switch color={'primary'} />}
                data-test={'diagnostics-switch'}
                label={'Monitor Kubecost Health'}
                onChange={async () => {
                  if (!diagnosticAlert) {
                    setNewDiagnosticAlert(
                      new DiagnosticAlert({
                        type: AlertTypes.Diagnostic,
                        window: '5m',
                      }),
                    );
                  } else {
                    removeAlert(diagnosticAlert);
                    setDiagnosticAlert(null);
                  }
                }}
              />
              {diagnosticAlert ? (
                <Button
                  color={'primary'}
                  onClick={() => setNewDiagnosticAlert(diagnosticAlert)}
                  style={{ marginRight: 8 }}
                  variant={'contained'}
                  disableElevation
                >
                  Edit
                </Button>
              ) : (
                <></>
              )}
              {diagnosticAlert ? <TestAlertButton alert={diagnosticAlert} /> : <></>}
            </Box>
          </Box>
          <Typography style={{ marginTop: 28 }} variant={'h6'}>
            Global Recipients
            <Tooltip
              title={
                'Alerts which do not define Email and/or Slack recipients will fall back to the global setting.'
              }
            >
              <HelpIcon
                style={{
                  cursor: 'pointer',
                  color: 'rgb(158,158,158)',
                  fontSize: 18,
                }}
              />
            </Tooltip>
          </Typography>
          <FormGroup>
            <FormControl>
              <TextField
                helperText={'The global Slack webhook'}
                inputProps={{
                  'data-test': 'slackWebhook',
                }}
                label={'Slack webhook'}
                onChange={(e) => {
                  setGlobalSlackDirty(true);
                  setGlobalSlack(e.target.value);
                }}
                placeholder={
                  'https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX'
                }
                style={{ marginTop: 8, width: '100%' }}
                value={globalSlack}
              />
            </FormControl>
          </FormGroup>
          <EmailRecipients
            addItem={(email) => {
              setGlobalEmails((emails) => [...emails, email]);
            }}
            recipients={globalEmails}
            removeItem={(i) => {
              setGlobalEmails((emails) => [...emails.slice(0, i), ...emails.slice(i + 1)]);
            }}
          />
          <FormGroup>
            <FormControl>
              <TextField
                helperText={'Alert messages will refer recipients back to this URL'}
                label={'Linkback URL'}
                onChange={(e) => {
                  setGlobalLinkback(e.target.value);
                }}
                placeholder={'http://kc.my-domain.tld:port'}
                style={{ marginTop: 16, marginBottom: 8 }}
                value={globalLinkback}
              />
            </FormControl>
          </FormGroup>
          <div style={{ marginTop: 16 }}>
            <Button
              color={'primary'}
              data-test={'globalContactSave'}
              onClick={() => {
                AlertService.setGlobalEmailRecipients(globalEmails);
                AlertService.setGlobalLinkback(globalLinkback);
                if (globalSlackDirty) {
                  AlertService.setGlobalSlackWebhook(globalSlack, globalLinkback);
                }
                setGlobalSlackDirty(false);
              }}
              variant={'contained'}
              disableElevation
            >
              Save
            </Button>
          </div>
        </Paper>

        {/* alerts table */}
        <Paper
          className={classes.content}
          data-testid={'alert-container'}
          style={{ marginTop: 16 }}
        >
          <Box style={{ display: 'flex' }}>
            <Typography style={{ flexGrow: 1 }} variant={'h5'}>
              Alerts
            </Typography>
            <TextField
              onChange={(e) => setAlertListFilter(e.target.value)}
              placeholder={'Filter alerts'}
              style={{ marginRight: 16 }}
              value={alertListFilter}
            />
            <Button
              color={'primary'}
              data-test={'create-alert-button'}
              onClick={() =>
                setNewAlert(
                  new BudgetAlert({
                    type: AlertTypes.Budget,
                    window: '7d',
                    aggregation: 'namespace',
                    filter: '',
                    threshold: 0,
                  }),
                )
              }
              variant={'contained'}
              disableElevation
            >
              + Create Alert
            </Button>
          </Box>
          <If condition={!tableAlerts.length}>
            <Typography style={{ textAlign: 'center' }} variant={'h5'}>
              No alerts found! Why not create one?
            </Typography>
          </If>
          <If condition={!!tableAlerts.length && !displayedAlerts.length}>
            <Typography style={{ textAlign: 'center' }} variant={'h5'}>
              Applied filter has hidden all alerts.
            </Typography>
          </If>
          <If condition={!!tableAlerts.length && !!displayedAlerts.length}>
            <AlertsList
              alerts={displayedAlerts}
              deleteAlert={(alert) => setDeletingAlert(alert)}
              editAlert={(alert) => setNewAlert(alert)}
              testAlert={(alert) => testAlert(alert)}
            />
          </If>
        </Paper>
      </If>
      <Snackbar autoHideDuration={4000} onClose={() => setGoodMessage('')} open={!!goodMessage}>
        <MuiAlert onClose={() => setGoodMessage('')} severity={'success'}>
          {goodMessage}
        </MuiAlert>
      </Snackbar>
      <CreateAlertModal
        alert={newAlert}
        close={() => setNewAlert(null)}
        save={upsertAlert}
        test={testAlert}
      />
      <CreateDiagnosticAlertModal
        alert={newDiagnosticAlert}
        close={() => setNewDiagnosticAlert(null)}
        save={async (alert: DiagnosticAlert) => upsertAlert(alert)}
        test={testAlert}
      />
      <DeleteAlertModal
        alert={deletingAlert}
        close={() => setDeletingAlert(null)}
        remove={(alert) => removeAlert(alert)}
      />
    </>
  );

  async function fetchData() {
    setFetchState(FetchStates.LOADING);
    try {
      const [alertResponse, emailResponse, slackResponse, linkbackResponse] = await Promise.all([
        AlertService.getAlerts(),
        AlertService.getGlobalEmailRecipients(),
        AlertService.getGlobalSlackWebhook(),
        AlertService.getGlobalLinkback(),
      ]);

      setAlerts(alertResponse);
      analytics.setProfileValue('alerts_count', alertResponse.length);
      setGlobalEmails(emailResponse);
      setGlobalSlack(
        slackResponse
          ? 'https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX'
          : '',
      );
      setGlobalLinkback(linkbackResponse);
      setFetchState(FetchStates.DONE);
    } catch (err) {
      setFetchState(FetchStates.ERROR);
      Logger.error(err);
    }
  }

  async function upsertAlert(a: Alert) {
    try {
      const response = await AlertService.upsertAlert(a);
      if (!a.id) {
        setAlerts([...alerts, response]);
      } else {
        setAlerts([...alerts.filter((alert) => alert.id !== a.id), response]);
      }
    } catch (err) {
      setMessage('Failed to create alert. See console.');
      Logger.error(err);
    }
  }

  async function removeAlert(a: Alert) {
    try {
      if (await AlertService.deleteAlert(a)) setAlerts(alerts.filter((aa) => aa.id !== a.id));
    } catch (err) {
      if (err instanceof RBACUnauthorizedError) {
        setMessage('You are not authorized to do that. Please contact your administrator.');
      } else {
        setMessage('Failed to remove alert. See console.');
      }
    }
  }

  async function testAlert(a: Alert) {
    try {
      const response = await AlertService.testAlert(a);
      if (!response.ok) {
        const t = await response.text();
        throw new Error(t);
      }
      setGoodMessage('Test alert sent');
    } catch (err) {
      setMessage(`Alert test failed. Reason: ${err}`);
    }
  }
};

export { Notifications };
