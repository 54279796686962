import { FC } from 'react';

import { Chip, Tooltip } from '@kubecost-frontend/holster';

interface BetaBadgeProps {
  learnMoreLink?: string;
  message?: string;
}

export const BetaBadge: FC<BetaBadgeProps> = ({
  learnMoreLink,
  message = 'This feature is still expiremental and could have unforseen side effects.',
}) => (
  <Tooltip className={'font-lg w-52'} content={message} position={'LEFT'}>
    <a href={learnMoreLink} target={'_blank'}>
      <Chip color={'default'} label={'Beta'} />
    </a>
  </Tooltip>
);

BetaBadge.displayName = 'BetaBadge';
