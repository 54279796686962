import { forwardRef, useState } from 'react';

import { Grid, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { model } from '../../services/model';

const Transition = forwardRef((props, ref) => <Slide direction={'up'} ref={ref} {...props} />);

interface SpotInstanceModalProps {
  closeModal: () => void;
  visible: boolean;
}

interface SpotFormItem {
  dataKey: string;
  label: string;
}

const spotInstanceItems: SpotFormItem[] = [
  { label: 'Node label for spot instances', dataKey: 'spotLabel' },
  { label: 'Node label value for spot instances', dataKey: 'spotLabelValue' },
  { label: 'Spot data bucket name', dataKey: 'bucketName' },
  { label: 'Spot data prefix', dataKey: 'prefix' },
  { label: 'Spot data region', dataKey: 'region' },
  { label: 'AWS account ID', dataKey: 'projectID' },
  { label: 'Service key name', dataKey: 'serviceKeyName' },
  { label: 'Service key secret', dataKey: '' }, // We don't actually send this in the payload??
];

const defaultSpotFormPayload = {
  spotLabel: '',
  spotLabelValue: '',
  bucketName: '',
  prefix: '',
  region: '',
  projectID: '',
  serviceKeyName: '',
};

export const SpotInstanceModal = ({ closeModal, visible }: SpotInstanceModalProps) => {
  const [spotInstancePayload, setSpotInstancePayload] = useState(defaultSpotFormPayload);

  const handleUpdate = async () => {
    const spotResp = await model.updateSpotInfoConfigs(spotInstancePayload);
    closeModal();
  };

  return (
    <div>
      <Dialog TransitionComponent={Transition} onClose={closeModal} open={visible} keepMounted>
        <DialogTitle>Update Spot Feed Configuration</DialogTitle>
        <DialogContent>
          <Typography variant={'body1'}>
            Receive more accurate Spot pricing data by enabling the AWS Spot Instances data feed{' '}
            <a
              href={'https://guide.kubecost.com/hc/en-us/articles/4407595947799#spot-nodes'}
              target={'_blank'}
            >
              Learn More
            </a>
          </Typography>
          {spotInstanceItems.map((item: SpotFormItem) => (
            <Grid spacing={3} style={{ marginTop: '.5em' }} container>
              <Grid xs={6} item>
                <Typography variant={'body1'}>{item.label}</Typography>
              </Grid>
              <Grid xs={6} item>
                <TextField
                  id={'outlined-basic'}
                  label={item.label}
                  onChange={(e: any) =>
                    setSpotInstancePayload({
                      ...spotInstancePayload,
                      [`${item.dataKey}`]: e.target.value,
                    })
                  }
                  variant={'outlined'}
                  fullWidth
                />
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={closeModal}>
            Close
          </Button>
          <Button color={'primary'} onClick={handleUpdate}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
