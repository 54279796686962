import { useState } from 'react';

import { Grid, Link } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { green, yellow } from '@material-ui/core/colors';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  nested: {
    paddingLeft: '2.5%',
  },
  innerNest: {
    paddingLeft: '5%',
  },
  code: {
    textAlign: 'left',
    backgroundColor: '#f6f8fa',
    padding: '2px',
    border: '1px solid #ddd',
    borderRadius: '.2rem',
    fontSize: '.8rem',
    margin: '2px',
    fontFamily: 'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
  },
});

const DiagnosticsPrometheusMetric = ({ metric }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);

  const icon = metric.passed ? (
    <CheckCircleOutlinedIcon style={{ color: green[700] }} />
  ) : (
    <ReportProblemOutlinedIcon style={{ color: yellow[700] }} />
  );

  // sanitize the result if necessary
  if (metric.result === null || metric.result === undefined) {
    metric.result = {
      data: { result: { status: 'error', message: 'Failed' } },
    };
  }

  // set error field if error status type
  if (metric.result.status === 'error') {
    metric.result.error = metric.result.message;
  }

  const resultLine = (result) => {
    if (result.error !== undefined) {
      return (
        <Typography variant={'body2'}>
          <b>Error:</b> {result.error}
        </Typography>
      );
    }

    return (
      <Typography variant={'body2'}>
        <b>Result:</b> {JSON.stringify(result)}
      </Typography>
    );
  };

  return (
    <>
      <ListItem onClick={handleClick} button dense>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={metric.label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout={'auto'} unmountOnExit>
        <Grid direction={'row'} spacing={1} style={{ paddingLeft: '5%' }} container>
          <Grid style={{ paddingLeft: '15px' }} item>
            <div style={{ width: '100%' }}>
              <Typography variant={'body2'}>
                <b>Description:</b> {metric.description}{' '}
                {metric.docLink && (
                  <Link href={metric.docLink} rel={'noopener'} target={'_blank'}>
                    <b>Learn More</b>
                  </Link>
                )}
              </Typography>
              <Typography variant={'body2'}>
                <b>Query:</b>
                <span className={classes.code}>{metric.query}</span>
              </Typography>
              {resultLine(metric.result)}
            </div>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default DiagnosticsPrometheusMetric;
