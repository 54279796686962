import { FC } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

interface LoadingProps {
  message: string;
}

const Loading: FC<LoadingProps> = ({ message = '' }) => (
  <div className={'w-full text-center'}>
    <CircularProgress />
    {message && (
      <Typography color={'textSecondary'} variant={'h6'}>
        {message}
      </Typography>
    )}
  </div>
);

Loading.displayName = 'Loading';

export { Loading };
