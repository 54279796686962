import { FC } from 'react';

import { Grid, Paper, Table, TableBody, TableHead, TableRow, Typography } from '@material-ui/core';

import { toCurrency } from '../../services/format';
import { Controller, RequestSizingRec } from '../../services/savings/index';
import { Allocation } from '../../types/allocation';

import { BorderLinearProgress } from './BorderLinearProgress';
import { StyledTableCell } from './StyledTableCell';

interface ControllerEfficiencyCardProps {
  controllerMap: Record<string, Allocation> | null;
  currency: string;
  handleContextSwitch: (controllerName: string) => void;
  savingsData: RequestSizingRec;
}

const efficiencyForController = (item: Allocation) => {
  let cpuEfficiency = 0;
  if (item.cpuCoreRequestAverage > 0) {
    cpuEfficiency = item.cpuCoreUsageAverage / item.cpuCoreRequestAverage;
  } else if (item.cpuCoreUsageAverage > 0) {
    cpuEfficiency = 1;
  }
  let ramEfficiency = 0;
  if (item.ramByteRequestAverage > 0) {
    ramEfficiency = item.ramByteUsageAverage / item.ramByteRequestAverage;
  } else if (item.ramByteUsageAverage > 0) {
    ramEfficiency = 1;
  }
  const totalEfficiency =
    (item.cpuCost * cpuEfficiency + item.ramCost * ramEfficiency) / (item.cpuCost + item.ramCost);
  return totalEfficiency;
};

const totalCostForItem = ({
  cpuCost,
  externalCost,
  gpuCost,
  loadBalancerCost,
  networkCost,
  pvCost,
  ramCost,
  sharedCost,
}: Allocation) =>
  cpuCost + ramCost + networkCost + loadBalancerCost + externalCost + pvCost + sharedCost + gpuCost;

export const ControllerEfficiencyCard: FC<ControllerEfficiencyCardProps> = ({
  controllerMap,
  currency,
  handleContextSwitch,
  savingsData,
}) => {
  if (
    controllerMap === null ||
    !savingsData ||
    savingsData.controllers.filter((contr: Controller) => contr.name !== '').length < 1
  )
    return <div />;

  const controllerList = Object.entries(controllerMap)
    .map(([key, value]) => ({
      name: key,
      efficiency: efficiencyForController(value),
      totalCost: totalCostForItem(value),
    }))
    .filter((item: any) => item.name !== '__idle__');

  return (
    <Paper style={{ padding: '2em' }}>
      <Typography component={'div'} variant={'h6'} gutterBottom>
        Controllers
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Name (Cluster/Controller)</StyledTableCell>
            <StyledTableCell align={'right'}>Efficiency Score</StyledTableCell>
            <StyledTableCell align={'right'}>Total Cost</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {controllerList.map((row: any) => (
            <TableRow
              key={row.name}
              onClick={() => {
                handleContextSwitch(row.name);
              }}
              style={{ cursor: 'pointer' }}
            >
              <StyledTableCell component={'th'} scope={'row'}>
                {row.name}
              </StyledTableCell>
              <StyledTableCell align={'right'}>
                <Grid alignItems={'center'} spacing={2} container>
                  <Grid xs={5} item>
                    {(row.efficiency * 100).toFixed(2)}%
                  </Grid>
                  <Grid xs={7} item>
                    <BorderLinearProgress value={row.efficiency * 100} variant={'determinate'} />
                  </Grid>
                </Grid>
              </StyledTableCell>
              <StyledTableCell align={'right'}>
                {toCurrency(row.totalCost || 0, currency)}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

ControllerEfficiencyCard.displayName = 'ControllerEfficiencyCard';
