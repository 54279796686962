import { memo, useEffect, useMemo, useState } from 'react';

import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import mean from 'lodash/mean';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import toArray from 'lodash/toArray';
import { useLocation, useNavigate } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';

import { Alert, Typography } from '@kubecost-frontend/holster';

import { AlertData, Alerts } from '../../components/Alerts';
import { Header } from '../../components/Header2New';
import { Warning, Warnings } from '../../components/Warnings';
import { withOnboardingEnsured } from '../../components/HostedOnboarding';
import { Time } from '../../constants';
import { useHosted } from '../../hooks/useHosted';
import { analytics } from '../../services/analytics';
import cluster from '../../services/cluster';
import ConfigService from '../../services/config';
import { bytesToString, checkCustomWindow, toVerboseTimeRange } from '../../services/format';
import logger from '../../services/logger';
import { model } from '../../services/model';
import { deleteAssetReport, listAssetReports, saveAssetReport } from '../../services/reports';
import { Asset, AssetFilter, AssetReport as AssetReportType, AssetSet } from '../../types/asset';

import { cumulativeToRate, cumulativeToTotals, rangeToCumulative, rangeToRate } from './asset';
import { SaveControl } from './AssetControls';
import OpenReportModal from './AssetControls/Open';
import { AssetReport } from './AssetReport';
import { CustomizeReportHeader } from './CustomizeReportHeader';
import { CustomizeReportModal } from './CustomizeReportModal';
import DetailsDialog from './DetailsDialog';
import { SubtitleMemoized } from './Subtitle';
import { ReportRow } from './types';

const RateMap: Record<string, number> = {
  cumulative: 0,
  hourly: Time.MinutesPerHour,
  daily: Time.MinutesPerDay,
  monthly: Time.MinutesPerMonth,
};

const windowOptions = [
  { name: 'Today', value: 'today' },
  { name: 'Yesterday', value: 'yesterday' },
  { name: 'Week-to-date', value: 'week' },
  { name: 'Month-to-date', value: 'month' },
  { name: 'Last week', value: 'lastweek' },
  { name: 'Last month', value: 'lastmonth' },
  { name: 'Last 24h', value: '24h' },
  { name: 'Last 48h', value: '48h' },
  { name: 'Last 7 days', value: '7d' },
  { name: 'Last 15 days', value: '15d' },
  { name: 'Last 30 days', value: '30d' },
  { name: 'Last 60 days', value: '60d' },
  { name: 'Last 90 days', value: '90d' },
];

const accumulateOptions = [
  { name: 'Daily', value: false },
  { name: 'Entire window', value: true },
];

const getDetails = (
  item: {
    bytes: number;
    cluster: string;
    cpuCores: number;
    labels: Record<string, string>;
    nodeType: string;
    ramBytes: number;
    type: string;
  },
  aggregateBy: string[],
) => {
  const itemType = get(item, 'type', '').toLowerCase();

  if (itemType.toLowerCase() === 'node') {
    return getNodeDetails(item);
  }

  if (itemType.toLowerCase() === 'disk') {
    return getDiskDetails(item);
  }

  if (itemType.toLowerCase() === 'shared') {
    return [];
  }

  return getAnyDetails(item, aggregateBy);
};

function getAnyDetails(
  item: { cluster: string; labels: Record<string, string> },
  aggregateBy: string,
) {
  const details = [];

  if (item.cluster && item.cluster !== '') {
    details.push(item.cluster);
  } else if (get(item, 'labels.cluster', null) === null) {
    if (aggregateBy === '') {
      details.push('No cluster');
    }
  }

  const labels = get(item, 'labels', {});
  if (Object.keys(labels || {}).length > 0) {
    forEach(labels, (value, key) => {
      details.push(`${key}=${value}`);
    });
  }

  return details;
}

function getDiskDetails(item: { bytes: number; cluster: string }) {
  const details = [];

  if (item.cluster) {
    details.push(item.cluster);
  } else {
    details.push('No cluster');
  }

  if (item.bytes) {
    details.push(bytesToString(item.bytes));
  }

  return details;
}

function getNodeDetails(item: {
  cluster: string;
  cpuCores: number;
  nodeType: string;
  ramBytes: number;
}) {
  const details = [];

  if (item.cluster) {
    details.push(item.cluster);
  } else {
    details.push('No cluster');
  }

  if (item.nodeType) {
    details.push(item.nodeType);
  }

  if (item.cpuCores) {
    details.push(`${round(item.cpuCores, 1)} vCPU`);
  }

  if (item.ramBytes) {
    details.push(`${bytesToString(item.ramBytes)} RAM`);
  }

  return details;
}

const getName = (
  key: string,
  item: { category: string; cluster: string; providerID: string; type: string },
  aggregateBy: string[],
) => {
  let names = [];

  if (!aggregateBy || aggregateBy.includes('unaggregated')) {
    // Default name varies by type
    if (item.type.toLowerCase() === 'cloud') {
      // Cloud assets should display as "Cloud/Category/ProviderID"
      // if all are present; e.g. "Cloud/Network/i-aie134s"
      names = [item.type];

      if (item.category && item.category !== '') {
        names.push(item.category);
      }

      const itemName = get(item, 'properties.name', '');
      if (itemName !== '') {
        names.push(itemName);
      } else if (item.providerID && item.providerID !== '') {
        names.push(item.providerID);
      }
    } else if (item.type.toLowerCase() === 'shared') {
      names = ['shared'];
    } else if (item.type.toLowerCase() === 'clustermanagement') {
      names = [item.type, item.cluster];
    } else {
      // Default name for any type is "type/name"
      names = [item.type];

      const itemName = get(item, 'properties.name', '');
      if (itemName !== '') {
        names.push(itemName);
      }
    }
  } else {
    names = [key];
  }

  return names.join('/');
};

// maps the cluster identifier to clusterName/clusterId for display
const getClusterNameId = (
  item: { properties: Record<string, string> },
  infoMap: Record<string, { name: string }>,
) => {
  const cid: string = get(item, 'properties.cluster', '');
  const nid: { clusterId: string; clusterName?: string } = { clusterId: cid };

  const info = infoMap[cid];
  if (info !== undefined) {
    nid.clusterName = info.name;
  }

  return cluster.clusterNameId(nid);
};

const AssetsPage = () => {
  const { isHostedEnvironment } = useHosted();

  // reports
  const [reports, setReports] = useState<AssetReportType[]>([]);
  const [activeReport, setActiveReport] = useState<AssetReportType>();
  const [title, setTitle] = useState('');
  const [snackbar, setSnackbar] = useState<{
    message?: string;
    severity?: 'info' | 'error' | 'success' | 'warning';
  }>({});
  const [abortController, setAbortController] = useState(new AbortController());

  // fetch asset reports on init
  useEffect(() => {
    // Reenable later when Reports are migrated
    //fetchReports();
  }, []);

  // Asset data state
  const [assetData, setAssetData] = useState<Asset[][]>([]);
  const [rate, setRate] = useState('cumulative');
  const [tooltipData, setTooltipData] = useState({});

  const rateData = useMemo(() => {
    if (rate === 'cumulative') {
      return assetData;
    }
    return rangeToRate(assetData, RateMap[rate]);
  }, [assetData, rate, rangeToRate]);

  const cumulativeRaw = useMemo(() => {
    const cumu = rangeToCumulative(assetData);
    if (rate === 'cumulative') {
      return cumu;
    }
    return cumulativeToRate(cumu, RateMap[rate]);
  }, [assetData, rate, cumulativeToRate]);

  const cumulativeData = useMemo(() => toArray(cumulativeRaw), [cumulativeRaw]);

  const totalData = useMemo(() => cumulativeToTotals(cumulativeRaw), [cumulativeRaw]);

  // When asset data changes, recalculate tooltip data
  useEffect(() => {
    const tooltipDataBuffer: Record<
      string,
      { hourlyCost: number; hoursRun: number; preemptible: number | null }
    > = {};

    const rowNameMap: Record<string, any> = {};
    assetData.forEach((assetSet) => {
      assetSet.forEach((asset) => {
        if (!asset || !asset.minutes) {
          return;
        }
        if (!rowNameMap[asset.name]) {
          rowNameMap[asset.name] = {
            hoursRun: 0,
            hourlyCostArray: [],
            preemptible: null,
          };
        }
        rowNameMap[asset.name].hoursRun += asset.minutes / 60;
        rowNameMap[asset.name].hourlyCostArray.push((60 * asset.totalCost) / asset.minutes);
        rowNameMap[asset.name].preemptible = asset.preemptible || null;

        tooltipDataBuffer[asset.name] = {
          hoursRun: rowNameMap[asset.name].hoursRun,
          hourlyCost: mean(rowNameMap[asset.name].hourlyCostArray),
          preemptible: rowNameMap[asset.name].preemptible,
        };
      });
    });
    setTooltipData(tooltipDataBuffer);
  }, [assetData]);

  // Form state, which controls form elements, but not the report itself. On
  // certain actions, the form state may flow into the report state.
  const [window, setWindow] = useState(windowOptions[6].value);
  const [aggregateBy, setAggregateBy] = useState(['service']);
  const [accumulate, setAccumulate] = useState(accumulateOptions[0].value);
  const [filters, setFilters] = useState<AssetFilter[]>([]);
  const [computeCloud, setComputeCloud] = useState(false);
  const [excludeProviderID, setExcludeProviderID] = useState(false);
  const [sharedMonthlyCost, setSharedMonthlyCost] = useState(0.0);
  const [context, setContext] = useState<AssetFilter[]>([]);
  const [oocLink, setOOCLink] = useState('../settings');
  const [oocAlert, setOOCAlert] = useState(false);
  const [provider, setProvider] = useState('');
  const [openSaveReport, setOpenUnsaveReportDialog] = useState(false);
  const [editReportDialog, setEditReportDialog] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);

  // parse any context information from the URL
  const routerLocation = useLocation();
  const searchParams = new URLSearchParams(routerLocation.search);
  const navigate = useNavigate();

  const handleOpenReport = (open: boolean) => {
    setOpenReportDialog(open);
  };

  const handleOpenUnsaveReport = (open: boolean) => {
    setOpenUnsaveReportDialog(open);
  };

  const clearContext = () => {
    if (context.length > 0) {
      searchParams.set('agg', context[0].property.toLowerCase());
    }
    searchParams.set('context', btoa(JSON.stringify([])));
  };

  const goToContext = (i: number) => {
    if (!isArray(context)) {
      logger.warn(`context is not an array: ${context}`);
      return;
    }

    if (i > context.length - 1) {
      logger.warn(`selected context out of range: ${i} with context length ${context.length}`);
    }

    if (i === context.length - 1) {
      logger.warn(`selected current context: ${i} with context length ${context.length}`);
    }

    searchParams.set('agg', context[i + 1].property.toLowerCase());
    searchParams.set('context', btoa(JSON.stringify(context.slice(0, i + 1))));
    navigate({ search: `?${searchParams.toString()}` });
  };

  const showDetailModal = (row: ReportRow) => {
    const details = {
      account: row.account || '',
      category: row.category || '',
      cluster: row.clusterID || '',
      controller: '',
      controllerKind: '',
      name: row.name || '',
      providerID: row.providerID || '',
      service: row.service || '',
      type: row.type || '',
    };

    if (row.name.split('/').length === 2) {
      details.controllerKind = row.name.split('/')[0];
      details.controller = row.name.split('/')[1];
    }
    if (['Node', 'LoadBalancer', 'Disk', 'ClusterManagement'].includes(row.type)) {
      openDetails(row);
      // we want to stop after showing modal
      return;
    }
    // Implements ad hoc cloud loading when exclude_provder_id is enabled.
    // Compute Cloud should also be false before entering this block and is set to true at the end.

    if (
      excludeProviderID &&
      !computeCloud &&
      row.type === 'Cloud' &&
      provider === 'AWS' &&
      !row.providerID.includes('EKS_CLOUD_SPEND')
    ) {
      const fltrs = [
        {
          property: 'Provider',
          value: row.provider,
          name: row.provider,
        },
        {
          property: 'Account',
          value: row.account,
          name: row.account,
        },
        {
          property: 'Service',
          value: row.service,
          name: row.service,
        },
      ];

      if (row.labels) {
        const labelContextValues: string[] = [];
        const labelContextNames: string[] = [];

        Object.keys(row.labels).forEach((key) => {
          if (!key.startsWith('Account_Inherited_')) {
            labelContextNames.push(`${key}=${row.labels[key]}`);
            labelContextValues.push(`${key}:${row.labels[key]}`);
          }
        });
        fltrs.push({
          property: 'Label/Tag',
          value: labelContextValues.join(','),
          name: labelContextNames.join(','),
        });
      }

      searchParams.set('computeCloud', 'true');
      searchParams.set('agg', 'unaggregated');
      searchParams.set('fltrs', btoa(JSON.stringify(fltrs)));
      navigate({ search: `?${searchParams.toString()}` });
    }
  };

  const drillDown = (row: ReportRow) => {
    if (aggregateBy.length === 1 && aggregateBy[0] === 'unaggregated') {
      showDetailModal(row);
      return;
    }

    // push current aggregations onto context stack,
    // and aggregate by the next most granular item in flowchart described here:
    // https://github.com/kubecost/cost-analyzer-frontend/issues/910#issuecomment-1189420514
    let nextAgg = 'unaggregated';
    if (aggregateBy.includes('provider')) {
      nextAgg = 'service';
      context.push({
        property: 'Provider',
        value: row.provider,
        name: row.provider,
      });
    }

    if (aggregateBy.includes('account')) {
      nextAgg = 'service';
      context.push({
        property: 'Account',
        value: row.account,
        name: row.account,
      });
    }

    if (aggregateBy.includes('category')) {
      nextAgg = 'type';
      context.push({
        property: 'Category',
        value: row.category,
        name: row.category,
      });
    }

    if (aggregateBy.includes('cluster')) {
      nextAgg = 'type';
      context.push({
        property: 'Cluster',
        value: row.clusterID,
        name: row.clusterID,
      });
    }

    if (aggregateBy.includes('service')) {
      nextAgg = row.service === 'Kubernetes' ? 'type' : 'unaggregated';
      context.push({
        property: 'Service',
        value: row.service,
        name: row.service,
      });
    }

    if (aggregateBy.includes('type')) {
      nextAgg = 'unaggregated';
      context.push({
        property: 'Type',
        value: row.type,
        name: row.type,
      });
    }

    if (aggregateBy.includes('providerid')) {
      nextAgg = 'unaggregated';
      context.push({
        property: 'ProviderID',
        value: row.providerID,
        name: row.providerID,
      });
    }

    searchParams.set('context', btoa(JSON.stringify(context)));
    searchParams.set('agg', nextAgg);
    navigate({
      search: `?${searchParams.toString()}`,
    });
  };

  // Setting details to null closes the details dialog. Setting it to an
  // object describing a controller opens it with that state.
  const [details, setDetails] = useState(null);

  const closeDetails = () => {
    searchParams.set('details', btoa(JSON.stringify(null)));
    navigate({ search: `?${searchParams.toString()}` });
  };

  const openDetails = (row) => {
    searchParams.set('details', btoa(JSON.stringify(row)));
    navigate({ search: `?${searchParams.toString()}` });
  };

  const checkOOC = async (prov: string) => {
    if (isHostedEnvironment) {
      return;
    }

    let ooc = false;
    // Retrieve the cloud endpoint status if there are any Cloud Connections that are in a successful set OOC to true
    const status = await model.etlStatus();
    const cloud = get(status, 'cloud');
    if (cloud) {
      Object.keys(cloud).forEach((providerKey) => {
        if (cloud[providerKey].cloudConnectionStatus === 'Connection Successful') {
          ooc = true;
        }
      });
    }
    // TODO Remove once confident that above code works in all situations
    if (!ooc) {
      if (prov.toUpperCase() === 'GCP') {
        const configs = await model.getConfigs();
        const hasBilling = get(configs, 'billingDataDataset', '').length > 0;
        const hasProjectID = get(configs, 'projectID', '').length > 0;
        if (hasBilling && hasProjectID) {
          ooc = true;
        }
      } else if (prov.toUpperCase() === 'AWS') {
        const serviceAccountStatus = await model.serviceAccountStatus();
        const checks = get(serviceAccountStatus, 'checks', []);
        if (checks.length > 0) {
          ooc = true;
        }
      } else if (prov.toUpperCase() === 'AZURE') {
        const serviceAccountStatus = await model.serviceAccountStatus();
        const checks = get(serviceAccountStatus, 'checks', []);
        checks.forEach((check: { message: string; status: unknown }) => {
          if (check.message === 'Azure Storage Config exists' && check.status) {
            ooc = true;
          }
        });
      }
    }

    const oocDismissed = localStorage.getItem('oocAlert') === 'dismissed';

    setOOCAlert(!ooc && !oocDismissed);

    return ooc;
  };

  // page and settings state
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [warnings, setWarnings] = useState<Array<Warning>>([]);
  const [alerts, setAlerts] = useState<AlertData[]>([]);
  const [currency, setCurrency] = useState('');

  // Initialize once
  useEffect(() => {
    initialize();
  }, []);

  // when router location changes, set state params accordingly
  useEffect(() => {
    let ctx = searchParams.get('context');
    let deets = searchParams.get('details');
    let fltr = searchParams.get('filters');
    const searchParamAggBy = searchParams.get('agg');
    const currentAggBy = searchParamAggBy ? searchParamAggBy.split(',') : ['unaggregated'];

    try {
      ctx = JSON.parse(atob(ctx)) || [];
    } catch (err) {
      ctx = [];
    }

    try {
      deets = JSON.parse(atob(deets)) || null;
    } catch (err) {
      deets = null;
    }

    try {
      fltr = JSON.parse(atob(fltr)) || [];
    } catch (err) {
      fltr = [];
    }
    let r = searchParams.get('rate') || '';
    if (!Object.keys(RateMap).includes(r)) {
      r = 'cumulative';
    }
    setComputeCloud(searchParams.get('computeCloud') === 'true' || false);
    setAggregateBy(currentAggBy);
    setWindow(searchParams.get('window') || '7d');
    setAccumulate(searchParams.get('acc') === 'true' || false);
    setRate(r);
    setContext(ctx);
    setDetails(deets);
    setFilters(fltr);
  }, [routerLocation]);

  // Fetch data when relevant state params change
  useEffect(() => {
    if (!init || !(aggregateBy && aggregateBy.length)) return;
    fetchWrapper();
    return () => {
      setLoading(true);
      abortController.abort();
    };
  }, [init, window, aggregateBy, accumulate, filters, context]);

  // if parameters match a known report, mark that report as active
  // otherwise set active report to null
  useEffect(() => {
    if (init) {
      const filterString = filters
        .map((f) => `${f.property}=${f.value}`)
        .sort()
        .join(',');
      const report = reports.find(
        (r) =>
          r.accumulate === accumulate &&
          r.aggregateBy === aggregateBy.join(',') &&
          filterString === r.filterString &&
          r.window === window,
      );
      setActiveReport(report);
      if (report && report.title) {
        setTitle(report.title);
      } else {
        setTitle(generateTitle());
      }
    }
  }, [accumulate, aggregateBy, filters, reports, window, init]);

  async function initialize() {
    if (isHostedEnvironment) {
      setInit(true);
      return;
    }

    // local copy of provider
    let pv = '';

    // determine provider
    const clusterInfo = await model.clusterInfo();
    if (clusterInfo.provider === 'GCP') {
      setOOCLink('../keyinstructions');
      pv = 'GCP';
    } else if (clusterInfo.provider === 'AWS') {
      pv = 'AWS';
    } else if (clusterInfo.provider === 'azure') {
      pv = 'azure';
    }
    setProvider(pv);

    const config = await model.getConfigs();
    setCurrency(config.currencyCode);
    setExcludeProviderID(config.excludeProviderID === 'true');
    setSharedMonthlyCost(parseFloat(get(config, 'sharedOverhead', '0')));

    setInit(true);
  }

  useEffect(() => {
    // check if OOC is configured for provider
    checkOOC(provider).then((ooc) => {
      let updateRoute = false;
      if (!searchParams.has('agg')) {
        updateRoute = true;
        searchParams.set('agg', ooc ? 'service' : 'type');
      }
      if (updateRoute) {
        navigate({ search: `?${searchParams.toString()}` }, { replace: true });
      }
    });
  }, [provider, routerLocation]);

  async function fetchReports() {
    const reps = await listAssetReports();
    setReports(reps);
  }

  function fetchWrapper() {
    abortController.abort();
    const ctrl = new AbortController();
    setAbortController(ctrl);
    fetchData(ctrl.signal);
  }

  async function fetchData(signal: AbortSignal) {
    setWarnings([]);
    setAlerts([]);
    setLoading(true);

    const newAlerts: AlertData[] = [];

    if (searchParams.get('new-report') === 'true') {
      newAlerts.push({
        primary: 'Creating a new report',
        secondary: `Adjust settings to see the data you want, and save using the bookmark icon on the right.
        Afterward, the saved report will be accessible from the Reports tab. `,
        level: 'success',
      });
    }

    try {
      checkOOC(provider);
      // const clusterInfoMap = await model.clusterInfoMap();

      const queryFilters: AssetFilter[] = [];
      if (context.length > 0) {
        forEach(context, (contextFilter) => {
          queryFilters.push(contextFilter);
        });
      }
      forEach(filters, (filter) => {
        queryFilters.push(filter);
      });
      let resp;
      let agg = aggregateBy;
      if (agg.includes('unaggregated')) {
        agg = [];
      }
      if (computeCloud) {
        setSnackbar({
          message: 'Retrieving data from Athena, this may take a while',
          severity: 'info',
        });
        resp = await model.getCloudUsage(
          window,
          {
            aggregate: agg,
            accumulate,
            compute: computeCloud,
            filters: queryFilters,
          },
          { signal },
        );
      } else {
        resp = await model.getAssets(
          window,
          {
            aggregate: agg,
            accumulate,
            sharedMonthlyCost,
            filters: queryFilters,
          },
          { signal },
        );
      }

      // Feature gate if tier-related warning is returned
      if (resp.warning && resp.warning.length > 0) {
        const re = /Requesting (.+) of data. Tier\[(.+)\] only supports up to (.+) of data/g;
        const se = /Requested (.+) rows of Asset data which exceeds the limit of (.+)./g;

        const isTierWarning = re.exec(resp.warning);
        const isAssetLimit = se.exec(resp.warning);

        if (isTierWarning) {
          let tier = isTierWarning[2].toLowerCase();
          tier = tier[0].toUpperCase() + tier.slice(1);

          let secondary = (
            <Typography variant={'h5'}>
              To view more data
              <strong>
                <a
                  color={'inherit'}
                  href={'#'}
                  onClick={async () => {
                    const conf = confirm(
                      'Select OK to begin your 30-day free trial of all paid features!',
                    );
                    let resp;
                    if (!conf) {
                      return;
                    }
                    try {
                      resp = await ConfigService.startTrial();
                    } catch (err) {
                      alert(
                        'Failed to start trial. Please contact team@kubecost.com for assistance.',
                      );
                      return;
                    }
                    fetchWrapper();
                    analytics.record('trial_start', { page: 'Assets' });
                    analytics.setProductKey(resp.productKey.key);
                    analytics.setProductTier('trial:enterprise');
                  }}
                >
                  Start Trial
                </a>
              </strong>
              . Learn more about{' '}
              <strong>
                <a
                  color={'inherit'}
                  href={'https://kubecost.com/pricing?upgrade=true'}
                  target={'_blank'}
                >
                  Upgrade Options
                </a>
                .
              </strong>
            </Typography>
          );
          const trialResp = await model.trialStatus();
          if (trialResp.usedTrial) {
            secondary = (
              <Typography variant={'h5'}>
                Your trial period has expired. To view more data{' '}
                <strong>
                  <a
                    color={'inherit'}
                    href={'https://kubecost.com/pricing?upgrade=true'}
                    target={'_blank'}
                  >
                    Upgrade
                  </a>
                </strong>
                .
              </Typography>
            );
          }

          newAlerts.push({
            primary: `You are requesting more than ${
              tier === 'Business' ? 30 : 15
            } days worth of metrics on ${tier} tier`,
            secondary,
            level: 'error',
          });

          resp.data = [];
        }

        // requested too many rows from assets
        if (isAssetLimit) {
          const secondary = (
            <Typography variant={'h5'}>
              To reduce the amount of data returned, try changing resolution to <i>Entire Window</i>{' '}
              or adding filters.{' '}
              <strong>
                <a
                  color={'inherit'}
                  href={'https://github.com/kubecost/docs/blob/master/assets.md'}
                  target={'_blank'}
                >
                  Learn more
                </a>
                .
              </strong>
            </Typography>
          );
          newAlerts.push({
            primary: resp.warning,
            secondary,
            level: 'error',
          });

          resp.data = [];
        }
      }

      if (resp.data && resp.data.length > 0) {
        const assetRange: AssetSet[] = resp.data;
        const formattedAssetData = assetRange.map((assetSet) => {
          const arr = Object.keys(assetSet).map((key) => {
            const asset = assetSet[key];
            const category = get(asset, 'properties.category', '');
            const prov = get(asset, 'properties.provider', '');
            const clusterID = get(asset, 'properties.cluster', '');
            const providerID = get(asset, 'properties.providerID', '');
            const clust = 'TEST'; // getClusterNameId(asset, clusterInfoMap);
            const service = get(asset, 'properties.service', '');
            const account = get(asset, 'properties.account', '');
            const obj = {
              ...asset,
              category,
              provider: prov,
              clusterID,
              providerID,
              cluster: clust,
              service,
              account,
              key,
            };
            return {
              ...obj,
              name: getName(key, obj, aggregateBy),
              details: getDetails(obj, aggregateBy),
            };
          });
          return sortBy(arr, (a) => a.totalCost);
        });
        setAssetData(formattedAssetData);
      } else {
        if (resp.message && resp.message.indexOf('boundary error') >= 0) {
          const match = resp.message.match(/(ETL.* is \d+\.\d+% complete)/);
          let secondary = 'Try again after ETL build is complete';
          if (match && match.length > 0) {
            secondary = `${match[1]}. ${secondary}`;
          }
          setWarnings([
            {
              primary: 'Data unavailable while ETL is building',
              secondary,
            },
          ]);
        }
        setAssetData([]);
      }
    } catch (err) {
      if (err.message.indexOf('404') === 0) {
        setWarnings([
          {
            primary: 'Failed to load asset data',
            secondary:
              'Please update Kubecost to the latest version, then contact support if problems persist.',
          },
        ]);
      } else if (err.name === 'AbortError') {
        console.error('Request aborted');
      } else {
        let secondary = 'Please contact Kubecost support with a bug report if problems persist.';
        if (err.message.length > 0) {
          secondary = err.message;
        }
        setWarnings([
          {
            primary: 'Failed to load asset data',
            secondary,
          },
        ]);
      }
      setAssetData([]);
    }

    setAlerts(newAlerts);

    setLoading(false);
  }

  const setAggBy = (aggBy) => {
    clearContext();
    searchParams.set('agg', aggBy);
    navigate({
      search: `?${searchParams.toString()}`,
    });
  };

  return (
    <div>
      <Header
        helpHref={'https://docs.kubecost.com/assets'}
        helpTooltip={'Product Documentation'}
        refreshCallback={fetchWrapper}
        title={'Assets'}
      />

      {!loading && warnings.length > 0 ? (
        <div style={{ marginBottom: 20 }}>
          <Warnings warnings={warnings} />
        </div>
      ) : (
        <></>
      )}

      {oocAlert ? (
        <Alert
          content={'To see entire cloud spend here, configure your external cloud settings.'}
          handleClose={() => {
            localStorage.setItem('oocAlert', 'dismissed');
            setOOCAlert(false);
          }}
          link={oocLink}
          style={{
            marginBottom: 16,
            boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
          }}
          title={'External cloud cost not configured'}
          variant={'info'}
        />
      ) : (
        <></>
      )}

      {!loading && alerts.length > 0 ? (
        <div style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Alerts alerts={alerts} />
        </div>
      ) : (
        <></>
      )}

      {init ? (
        <>
          <Typography style={{ wordBreak: 'break-all' }} variant={'h5'}>
            {title}
          </Typography>
          <SubtitleMemoized
            aggregateBy={aggregateBy}
            clearContext={() => {
              clearContext();
              navigate({ search: `?${searchParams.toString()}` });
            }}
            context={context}
            goToContext={goToContext}
            window={window}
          />
          <CustomizeReportHeader
            accumulate={accumulate}
            aggregateBy={aggregateBy}
            cumulativeDate={cumulativeData}
            filters={filters}
            report={activeReport}
            save={saveReport}
            setAggregateBy={setAggBy}
            setEditReportDialog={setEditReportDialog}
            setOpenReportDialog={handleOpenReport}
            setOpenSaveReportDialog={handleOpenUnsaveReport}
            setWindow={(win: string) => {
              searchParams.set('window', win);
              navigate({
                search: `?${searchParams.toString()}`,
              });
            }}
            sharedMonthlyCost={sharedMonthlyCost}
            title={title}
            unsave={unsaveReport}
            window={window}
          />
          <CustomizeReportModal
            accumulate={accumulate}
            accumulateOptions={accumulateOptions}
            closeModal={() => setEditReportDialog(false)}
            filters={filters}
            open={editReportDialog}
            rate={rate}
          />
          <OpenReportModal
            open={openReportDialog}
            reports={reports}
            selectReport={(report) => {
              clearContext();
              searchParams.set('acc', report.accumulate);
              searchParams.set('agg', report.aggregateBy);
              searchParams.set('filters', btoa(JSON.stringify(report.filters)));
              searchParams.set('title', report.title);
              searchParams.set('window', report.window);
              navigate({
                search: `?${searchParams}`,
              });
            }}
            setOpen={setOpenReportDialog}
          />
          <SaveControl
            open={openSaveReport}
            report={activeReport}
            save={saveReport}
            setSaveOpen={() => setOpenUnsaveReportDialog(false)}
            title={title}
            unsave={unsaveReport}
          />

          <hr className={'my-5'} style={{ opacity: 0.1 }} />

          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ paddingTop: 100, paddingBottom: 100 }}>
                <CircularProgress />
              </div>
            </div>
          ) : (
            <></>
          )}
          {!loading ? (
            <AssetReport
              aggregateBy={aggregateBy}
              assetData={rateData}
              cumulativeData={cumulativeData}
              currency={currency}
              drillDown={drillDown}
              tooltipData={tooltipData}
              totalData={totalData}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {!loading ? (
        <DetailsDialog
          asset={details}
          close={() => closeDetails()}
          currency={currency}
          open={details !== null}
          window={window}
        />
      ) : (
        <></>
      )}
      <Snackbar
        autoHideDuration={4000}
        onClose={() => setSnackbar({})}
        open={!!(snackbar.message && snackbar.severity)}
      >
        <Alert
          content={snackbar.message || ''}
          handleClose={() => setSnackbar({})}
          title={''}
          variant={snackbar.severity === 'error' ? 'danger' : snackbar.severity || 'info'}
        />
      </Snackbar>
    </div>
  );

  async function saveReport(reportTitle: string) {
    const rep = {
      window,
      accumulate,
      aggregateBy: aggregateBy.toString(),
      filters,
      title: reportTitle,
    };
    try {
      const reps = await saveAssetReport(rep);
      analytics.setProfileValue('saved_asset_reports_count', reps.length);
      setReports(reps);
      setSnackbar({
        message: 'Report saved',
        severity: 'success',
      });
    } catch (err) {
      setSnackbar({
        message: 'Failed to save report',
        severity: 'error',
      });
    }
  }

  async function unsaveReport(report: AssetReportType) {
    try {
      const reps = await deleteAssetReport(report);
      analytics.setProfileValue('saved_asset_reports_count', reps.length);
      setReports(reps);
      setSnackbar({
        message: 'Report unsaved',
        severity: 'success',
      });
    } catch (err) {
      setSnackbar({
        message: 'Failed to unsave report',
        severity: 'success',
      });
    }
  }

  function generateTitle() {
    let windowName = get(find(windowOptions, { value: window }), 'name', '');
    if (windowName === '') {
      if (checkCustomWindow(window)) {
        windowName = toVerboseTimeRange(window);
      } else {
        logger.warn(`unknown window: ${window}`);
      }
    }

    const aggregationName = aggregateBy
      .map((agg) => {
        let aggName = get(find(aggregationOptions, { value: agg }), 'name', '').toLowerCase();
        if (aggName === '') {
          if (agg.startsWith('label:')) {
            aggName = `label ${agg.slice(6)}`;
          } else {
            logger.warn(`unknown aggregation: ${agg}`);
          }
        }
        return aggName;
      })
      .join(', ');

    let str = `${windowName} by ${aggregationName}`;
    if (!accumulate) {
      str += ' daily';
    }

    if (filters && filters.length) {
      str += ' with filters';
    }
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  }
};

const AssetPageMemoized = withOnboardingEnsured(memo(AssetsPage));

const aggregationOptions = [
  { name: 'Category', value: 'category' },
  { name: 'Cluster', value: 'cluster' },
  { name: 'Service', value: 'service' },
  { name: 'Type', value: 'type' },
  { name: 'Provider', value: 'provider' },
  { name: 'Project', value: 'project' },
  { name: 'Account', value: 'account' },
  { name: 'Provider ID', value: 'providerid' },
];

export { AssetPageMemoized, aggregationOptions };
