import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useState } from 'react';

import { AlertTypes, Alert, EfficiencyAlert } from '../../services/alerts';

const EfficiencyAlertsTable = ({
  alerts,
  createAlert,
  removeAlert,
  locked = {},
}: ComponentProps) => {
  const [newWindow, setNewWindow] = useState('');
  const [newAgg, setNewAgg] = useState('');
  const [newFilters, setNewFilters] = useState('');
  const [newEff, setNewEff] = useState('');
  const [newSpend, setNewSpend] = useState('');
  return (
    <TableContainer data-test={'alerts-table'}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Window</TableCell>
            <TableCell>Aggregation</TableCell>
            <TableCell>Filter</TableCell>
            <TableCell>Efficiency threshold</TableCell>
            <TableCell>Spend threshold</TableCell>
            <TableCell>Add/Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alerts.map((a) => (
            <TableRow key={a.id}>
              <TableCell>{a.window}</TableCell>
              <TableCell>{a.aggregation}</TableCell>
              <TableCell>{a.filter}</TableCell>
              <TableCell>{a.efficiencyThreshold}</TableCell>
              <TableCell>{a.spendThreshold || 'None'}</TableCell>
              <TableCell>
                <IconButton data-test={'alert-delete'} onClick={() => removeAlert(a)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell data-test={'new-alert-window'}>
              {locked.window || (
                <TextField onChange={(e) => setNewWindow(e.target.value)} value={newWindow} />
              )}
            </TableCell>
            <TableCell data-test={'new-alert-aggregate'}>
              {locked.aggregate || (
                <TextField onChange={(e) => setNewAgg(e.target.value)} value={newAgg} />
              )}
            </TableCell>
            <TableCell data-test={'new-alert-filters'}>
              <TextField onChange={(e) => setNewFilters(e.target.value)} value={newFilters} />
            </TableCell>
            <TableCell data-test={'new-alert-efficiency'}>
              {locked.effThresh || (
                <TextField onChange={(e) => setNewEff(e.target.value)} value={newEff} />
              )}
            </TableCell>
            <TableCell data-test={'new-alert-spend'}>
              {locked.spendThresh || (
                <TextField onChange={(e) => setNewSpend(e.target.value)} value={newSpend} />
              )}
            </TableCell>
            <TableCell>
              <IconButton data-test={'new-alert-add'} onClick={handleAddAlert}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  function handleAddAlert() {
    const newAlert = new EfficiencyAlert({
      type: AlertTypes.Efficiency,
      window: newWindow || locked.window,
      aggregation: newAgg || locked.aggregate,
      filter: newFilters,
      efficiencyThreshold:
        typeof locked.effThresh !== 'undefined' ? locked.effThresh : parseFloat(newEff),
      spendThreshold:
        typeof locked.spendThresh !== 'undefined' ? locked.spendThresh : parseFloat(newSpend),
    });
    createAlert(newAlert);
    setNewWindow('');
    setNewAgg('');
    setNewFilters('');
    setNewEff('');
    setNewSpend('');
  }
};

export default EfficiencyAlertsTable;

EfficiencyAlertsTable.defaultProps = {
  locked: {},
};

interface ComponentProps {
  alerts: EfficiencyAlert[];
  createAlert: (alert: Alert) => void;
  locked?: {
    aggregate?: string;
    effThresh?: number;
    spendThresh?: number;
    window?: string;
  };
  removeAlert: (alert: Alert) => void;
}
