import {
  Chip,
  IconButton,
  Link,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import { Check, Error } from '@material-ui/icons';
import Info from '@material-ui/icons/Info';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Header } from '../../components/Header';

import { findIssues } from './api';
import IssueDialog from './issueDialog';
import { issues } from './issues';
import { renderScoreGraph } from './legacy';

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'flex-end',
  },
});

const Health = () => {
  const classes = useStyles();
  const [results, setResults] = useState([]);
  const [onlyShowErrors, setOnlyShowErrors] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  const [selectedError, setSelectedError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    findIssues((results: any) => {
      setResults(results);
    });
  }, []);

  useEffect(() => {
    renderScoreGraph();
  }, []);

  return (
    <div id={'health'}>
      <IssueDialog
        error={selectedError}
        issue={selectedResult}
        onClose={() => {
          setSelectedError(null);
          setSelectedResult(null);
        }}
        open={selectedResult !== null}
      />
      <Header breadcrumbs={[{ name: 'Cluster Health', href: 'health.html' }]}>
        <Toolbar className={classes.toolbar} variant={'dense'}>
          <IconButton onClick={() => window.location.reload()}>
            <RefreshIcon />
          </IconButton>
          <Link href={''}>
            <IconButton onClick={() => navigate('/settings')}>
              <SettingsIcon />
            </IconButton>
          </Link>
        </Toolbar>
      </Header>
      <div style={{}}>
        <div style={{ marginBottom: '1em', textAlign: 'center' }}>
          <span id={'health-description'}>
            <span>Generating health score...</span>
          </span>
          <Tooltip
            title={
              'Cluster health ratings are an assessment of infrastructure reliability and performance risks. Scores range from 1-100.'
            }
          >
            <Info style={{ fontSize: '17px', marginLeft: '0.5em' }} />
          </Tooltip>
        </div>
        <div className={'gauge-container'}>
          <div className={'gauge-a'} />
          <div className={'gauge-b'} />
          <div className={'gauge-c'} />
          <div className={'gauge-data'}>
            <div className={'mdl-spinner mdl-js-spinner is-active'} id={'gauge-loading'} />
            <h1 id={'gauge-percent'} />
          </div>
        </div>
        <div id={'toggle-box'} style={{ float: 'right' }}>
          <span>Show All</span>
          <Switch
            color={'default'}
            onChange={(e) => setOnlyShowErrors(!e.target.checked)}
            defaultChecked
          />
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Health Test</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results &&
                issues.map((issue) => {
                  const result = results[issue[0]];
                  const error =
                    result &&
                    (result.data === true ||
                      (result.data !== null && result.data.length > 0) ||
                      (result.data !== null && result.data.overThreshold === true));
                  if (onlyShowErrors && !error) {
                    return <></>;
                  }

                  return (
                    <TableRow
                      id={'${id}'}
                      onClick={() => {
                        setSelectedResult(issue);
                        setSelectedError(result);
                      }}
                      hover
                    >
                      <TableCell>
                        <span className={'issue-title'}>{issue[2]}</span>

                        <Chip label={issue[1]} size={'small'} style={{ marginLeft: '1em' }} />

                        {issue[3] && issue[3] === 'high' && (
                          <Chip
                            label={'high'}
                            size={'small'}
                            style={{
                              marginLeft: '1em',
                              color: 'white',
                              backgroundColor: 'red',
                            }}
                          />
                        )}

                        {issue[3] && issue[3] !== 'high' && (
                          <Chip
                            label={'medium'}
                            size={'small'}
                            style={{
                              marginLeft: '1em',
                              color: 'white',
                              backgroundColor: 'orange',
                            }}
                          />
                        )}

                        {/* ${getSeverityChip(issues[i][3])} */}
                      </TableCell>
                      <TableCell className={'issue-cost'}>
                        {error && <Error style={{ color: '#db3236' }} />}
                        {!error && <Check style={{ color: 'green' }} />}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div id={'message-box'} />
      </div>

      <dialog className={'mdl-dialog'} id={'switch-dialog'} />
    </div>
  );
};

export default Health;
