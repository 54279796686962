import { FC } from 'react';

import { Typography } from '@kubecost-frontend/holster';

export const SectionBreak: FC<SectionBreakProps> = ({ title }) => (
  <div
    style={{
      marginBottom: 30,
      marginTop: '2em',
    }}
  >
    <Typography style={{ fontWeight: 'bold', color: '#023927' }} variant={'h5'}>
      {title}
    </Typography>
  </div>
);

/**
 *
 * Section break markers
 */
export interface SectionBreakProps {
  title: string;
}
