import { FC, useEffect, useState } from 'react';

import { Button, Input, Modal } from '@kubecost-frontend/holster';

import { AdvancedReport } from '../../types/advancedReport';

interface SaveReportDialogProps {
  onClose: () => void;
  open: boolean;
  report: AdvancedReport;
  save: (report: AdvancedReport) => void;
  title: string;
}

export const SaveReportDialog: FC<SaveReportDialogProps> = ({
  onClose,
  open,
  report,
  save,
  title,
}) => {
  const [localTitle, setLocalTitle] = useState('');

  const onSave = () => {
    save({ ...report, title: localTitle });
    onClose();
  };

  useEffect(() => {
    if (report && report.title) {
      setLocalTitle(report.title);
    } else if (title) {
      setLocalTitle(title);
    }
  }, [report, title]);

  return (
    <Modal onClose={onClose} open={open} title={'Save Report'}>
      <div style={{ padding: '1em' }}>
        <Input
          onChange={(e) => setLocalTitle(e.target.value)}
          style={{ width: '100%' }}
          value={localTitle}
        />
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '10px',
        }}
      >
        <Button onClick={onSave} variant={'primary'}>
          Save
        </Button>
        <Button onClick={onClose} variant={'default'}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
