import { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

import { toCurrency } from '../../../services/format';
import { model as Model } from '../../../services/model';
import { AssetSet } from '../../../types/asset';

import { InspectLink } from './InspectLink';

export const ExternalCostsTile = ({ costData, currency, resourceName, totalCost }: any) => {
  const [data, setData] = useState<AssetSet>({});

  useEffect(() => {
    fetchData();
  }, []);

  if (!costData || costData.length == 0) {
    const [title, buttonText, linkHref] = isEmpty(data)
      ? // we can assume that cloud integration hasn't been configured
        [
          'External cloud costs not configured',
          'Configure cloud integration',
          'settings.html#out-of-cluster-box',
        ]
      : // cloud integration is configured, we just don't have any data for this resource
        [
          <div className={'flex h-full items-center justify-center text-center'}>
            <div>
              No External Cost data for <strong>{resourceName}</strong>
            </div>
          </div>,
          'Explore Assets',
          '/assets.html',
        ];

    return (
      <div className={'flex h-full grow items-center justify-center'}>
        <div>
          <Typography variant={'h5'}>{title}</Typography>
          <div className={'flex justify-center'}>
            <InspectLink href={linkHref} text={buttonText} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={'flex grow flex-col justify-between'}>
      <Table className={'w-full'}>
        <TableHead>
          <TableRow>
            <TableCell>SERVICE</TableCell>
            <TableCell>COST</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {costData.map(([key, val]: [string, number]) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell>{toCurrency(val, currency)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell align={'right'}>
              <strong>Total:</strong>
            </TableCell>
            <TableCell>
              <strong>{toCurrency(totalCost, currency)}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className={'flex items-center p-4'}>
        <InspectLink href={'/assets'} text={'Explore Assets'} />
      </div>
    </div>
  );
  // checking to see if enabled
  async function fetchData() {
    const response = await Model.getAssets('7d', {
      accumulate: true,
      aggregate: 'service',
    });
    const assetSet: AssetSet = response.data[0];
    delete assetSet.Kubernetes;
    setData(assetSet);
  }
};
