import { FC, memo, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { endOfDay, isValid, startOfDay } from 'date-fns';
import find from 'lodash/find';
import get from 'lodash/get';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';

import { WindowOptions } from '../pages/AssetsNew/AssetControls/types';

const useStyles = makeStyles({
  dateContainer: {
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 6,
    paddingBottom: 18,
    display: 'flex',
    flexFlow: 'row',
  },
  dateContainerColumn: {
    display: 'flex',
    flexFlow: 'column',
  },
  formControl: {
    minWidth: 120,
  },
});

interface SelectWindowProps {
  helperText?: any;
  setWindow: (window: string) => void;
  window: string;
  windowOptions: WindowOptions[];
}

const SelectWindow: FC<SelectWindowProps> = ({ helperText, setWindow, window, windowOptions }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [intervalString, setIntervalString] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStartDateChange = (date) => {
    if (isValid(date)) {
      setStartDate(startOfDay(date));
    }
  };

  const handleEndDateChange = (date) => {
    if (isValid(date)) {
      setEndDate(endOfDay(date));
    }
  };

  const handleSubmitPresetDates = (dateString) => {
    setWindow(dateString);
    setStartDate(null);
    setEndDate(null);
    handleClose();
  };

  const handleSubmitCustomDates = () => {
    if (intervalString !== null) {
      setWindow(intervalString);
      handleClose();
    }
  };

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      // Selecting a date should result in a start date at time 00:00:00.000
      // and an end date of midnight, i.e. the following day at 00:00:00.000.
      // This is a hacky solution to say that, if the date picked is
      // 2021-06-20T23:59:59, then make endDate 2021-06-21T00:00:00.
      //
      // NOTE: We should consider, perhaps, an API-side fix that rounds-up
      // 23:59:59 to midnight, but this will work until then.

      const s = new Date(startDate);
      s.setHours(0);
      s.setMinutes(0);
      s.setSeconds(0);
      s.setMilliseconds(0);

      const e = new Date(endDate);
      e.setDate(e.getDate() + 1);
      e.setHours(0);
      e.setMinutes(0);
      e.setSeconds(0);
      e.setMilliseconds(0);

      // Note: getTimezoneOffset() is calculated based on current system locale, NOT date object
      const adjustedStartDate = new Date(s - s.getTimezoneOffset() * 60000);
      const adjustedEndDate = new Date(e - e.getTimezoneOffset() * 60000);

      const startStr = adjustedStartDate.toISOString().split('.')[0];
      const endStr = adjustedEndDate.toISOString().split('.')[0];
      setIntervalString(`${startStr}Z,${endStr}Z`);
    }
  }, [startDate, endDate]);

  const open = Boolean(anchorEl);
  const id = open ? 'date-range-popover' : undefined;

  return (
    <>
      <FormControl className={classes.formControl}>
        <TextField
          id={'date-range-control'}
          inputProps={{
            readOnly: true,
            style: { cursor: 'pointer' },
          }}
          label={'Date Range'}
          onClick={(e) => handleClick(e)}
          value={get(find(windowOptions, { value: window }), 'name', 'Custom')}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.dateContainer}>
          <div className={classes.dateContainerColumn}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                format={'MM/dd/yyyy'}
                id={'date-range-control-custom-start'}
                label={'Start Date'}
                margin={'normal'}
                maxDate={new Date()}
                maxDateMessage={'Date should not be after today.'}
                onChange={handleStartDateChange}
                style={{ width: '144px' }}
                value={startDate}
                variant={'inline'}
                autoOk
                disableToolbar
              />
              <KeyboardDatePicker
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                format={'MM/dd/yyyy'}
                id={'date-range-control-custom-end'}
                label={'End Date'}
                margin={'normal'}
                maxDate={new Date()}
                maxDateMessage={'Date should not be after today.'}
                onChange={handleEndDateChange}
                style={{ width: '144px' }}
                value={endDate}
                variant={'inline'}
                autoOk
                disableToolbar
              />
            </MuiPickersUtilsProvider>
            <div>
              <Button
                color={'default'}
                onClick={handleSubmitCustomDates}
                style={{ marginTop: 16 }}
                variant={'contained'}
              >
                Apply
              </Button>
            </div>
          </div>
          <div className={classes.dateContainerColumn} style={{ paddingTop: 12, marginLeft: 18 }}>
            {windowOptions.map((opt) => (
              <Typography key={opt.value}>
                <Link
                  id={`date-range-control-option-${opt.value}`}
                  key={opt.value}
                  onClick={() => handleSubmitPresetDates(opt.value)}
                  style={{ cursor: 'pointer' }}
                  value={opt.value}
                >
                  {opt.name}
                </Link>
              </Typography>
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
};

export const SelectWindowMemoized = memo(SelectWindow);
