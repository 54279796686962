import React, { FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Input, Menu, Typography } from '@kubecost-frontend/holster';

import { Header } from '../../components/Header2New';
import { Loading } from '../../components/Loading';
import { FetchStates } from '../../constants';
import { useClusters } from '../../contexts/ClusterConfig';
import {
  deleteAllocationReport,
  deleteAssetReport,
  listAllocationReports,
  listAssetReports,
} from '../../services/reports';
import { AllocationReport } from '../../types/allocation';
import { AssetReport } from '../../types/asset';

import { ReportsTable } from './ReportsTable';

const ReportsHosted: FC = () => {
  const navigate = useNavigate();
  const { modelConfig } = useClusters();

  // component state variables
  const [allocationReportsFetchState, setAllocationReportsFetchState] = useState(FetchStates.INIT);
  const [assetReportsFetchState, setAssetReportsFetchState] = useState(FetchStates.INIT);
  const [allocationReports, setAllocationReports] = useState<AllocationReport[]>([]);
  const [assetReports, setAssetReports] = useState<AssetReport[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  // used to anchor the Create Report popup menu
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | Element>(null);

  // user's readonly status
  const readOnly = modelConfig.readOnly !== 'false' && modelConfig.editorAccess !== 'true';

  // whenever fetch state is "init", fetch allocation reports
  useEffect(() => {
    if (allocationReportsFetchState !== FetchStates.INIT) {
      return;
    }
    fetchAllocationReports();
  }, [allocationReportsFetchState]);

  // whenever fetch state is "init", fetch asset reports
  useEffect(() => {
    if (assetReportsFetchState !== FetchStates.INIT) {
      return;
    }
    fetchAssetReports();
  }, [assetReportsFetchState]);

  // content is determined by the current load-state of the page

  async function fetchAllocationReports() {
    setAllocationReportsFetchState(FetchStates.LOADING);

    try {
      const reports = await listAllocationReports();
      setAllocationReports(sortReports(reports));
      setAllocationReportsFetchState(FetchStates.DONE);
    } catch (err) {
      setAllocationReportsFetchState(FetchStates.ERROR);
    }
  }

  async function fetchAssetReports() {
    setAssetReportsFetchState(FetchStates.LOADING);

    try {
      const reports = await listAssetReports();
      setAssetReports(sortReports(reports));
      setAssetReportsFetchState(FetchStates.DONE);
    } catch (err) {
      setAssetReportsFetchState(FetchStates.ERROR);
    }
  }

  function fetchAll() {
    fetchAllocationReports();
    fetchAssetReports();
  }

  // TODO: Sort reports db-side?
  function sortReports(reports: AllocationReport[]): AllocationReport[];
  function sortReports(reports: AssetReport[]): AssetReport[];
  function sortReports(
    reports: Array<AllocationReport | AssetReport>,
  ): Array<AllocationReport | AssetReport> {
    return reports.sort((a, b) => (a.title > b.title ? 1 : -1));
  }

  function isInitializing(): boolean {
    return (
      allocationReportsFetchState === FetchStates.INIT ||
      assetReportsFetchState === FetchStates.INIT
    );
  }

  function isLoading(): boolean {
    return (
      allocationReportsFetchState === FetchStates.LOADING ||
      assetReportsFetchState === FetchStates.LOADING
    );
  }

  function isError(): boolean {
    return (
      !isLoading() &&
      !isInitializing() &&
      (allocationReportsFetchState === FetchStates.ERROR ||
        assetReportsFetchState === FetchStates.ERROR)
    );
  }

  const handleDeleteAllocationReport = async (report: AllocationReport) => {
    const resp = await deleteAllocationReport(report);
    setAllocationReports(
      sortReports(
        resp.map((r) => ({
          ...r,
          aggregateBy: r.aggregateBy.split(','),
        })),
      ),
    );
  };

  const handleDeleteAssetReport = async (report: AssetReport) => {
    const resp = await deleteAssetReport(report);
    setAssetReports(sortReports(resp));
  };

  const ready =
    allocationReportsFetchState === FetchStates.DONE && assetReportsFetchState === FetchStates.DONE;

  return (
    <>
      <Header
        helpHref={'https://docs.kubecost.com/kubecost-cloud/cloud-reports-dashboard'}
        helpTooltip={'Product Documentation'}
        refreshCallback={() => fetchAll()}
        title={'Reports'}
      />

      <div>
        <div>
          <Typography style={{ marginRight: '35%' }} variant={'p'}>
            Kubecost saved reports are predefined views stored for easy access. These reports can be
            configured in the Cost Allocation and Asset tabs.{' '}
            <a
              href={'https://docs.kubecost.com/kubecost-cloud/cloud-reports-dashboard'}
              style={{ color: '#28B359', textDecoration: 'underline' }}
              target={'_blank'}
            >
              Learn more.
            </a>
          </Typography>

          <div style={{ display: 'flex', marginTop: '1em' }}>
            <div style={{ flexGrow: 1 }} />
            <div style={{ position: 'relative' }}>
              <Button onClick={(e) => setMenuAnchorEl(e.currentTarget)} variant={'primary'}>
                Create a report
              </Button>
              {Boolean(menuAnchorEl) && (
                <Menu
                  items={[
                    {
                      text: 'Allocation Report',
                    },
                    {
                      text: 'Asset Report',
                    },
                  ]}
                  onClose={() => setMenuAnchorEl(null)}
                  selectItem={({ text }) => {
                    const searchParams = new URLSearchParams();
                    searchParams.set('new-report', 'true');
                    if (text === 'Allocation Report') {
                      navigate(`/allocations?${searchParams.toString()}`);
                    } else {
                      navigate(`/assets?${searchParams.toString()}`);
                    }
                  }}
                />
              )}
            </div>
            <Input
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              placeholder={'Filter reports'}
              style={{ marginLeft: 24 }}
            />
          </div>
        </div>

        {(isInitializing() || isLoading()) && <Loading />}

        {isError() ? (
          <Typography variant={'p'}>
            Unable to retrieve a list of reports. Refresh to try again.
          </Typography>
        ) : (
          <></>
        )}

        {ready ? (
          <div style={{ marginTop: 24 }}>
            <ReportsTable
              allocationReports={allocationReports}
              assetReports={assetReports}
              handleDeleteAllocationReport={handleDeleteAllocationReport}
              handleDeleteAssetReport={handleDeleteAssetReport}
              readOnly={readOnly}
              searchTerm={searchTerm}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export { ReportsHosted };
