import { useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Box, Grid, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';

import { monitorWidthResize } from '../../hooks';
import { AlertService } from '../../services/alerts';
import { analytics as Analytics } from '../../services/analytics';
import Logger from '../../services/logger';
import { model } from '../../services/model';
import { AssetSet } from '../../types/asset';

import { AlertsInspectCard } from './AlertsTile';
import { ControllerEfficiencyCard } from './ControllerEfficiencyCard';
import { CostBreakdownTile } from './CostBreakdownTile';
import { CostEfficiencyCard } from './CostEfficiencyCard';
import { ExternalCostsTile } from './ExternalCosts';
import { GraphToggle } from './GraphToggle';
import { AreaChartComponent } from './HistoricalCostAreaChart';
import { HistoricalCostBarChart } from './HistoricalCostBarChart';
import { InspectFilterTile } from './InspectFilterTile';
import { InspectHeader } from './InspectHeader';
import {
  aliasedLabelDefaults,
  createDataForSpendingGraph,
  establishHierarchy,
  getEffiencyData,
  labelAssetsMap,
} from './inspectHelpers';
import { InspectedResourceListItem, initialDynamicState, initialStaticState } from './inspectState';
import { NetworkCostsTile } from './NetworkCostsTile';
import { PerformanceInsightsCard } from './PerformanceInsightsCard';
import { SavingsCardGroup } from './SavingsCardGroup';
import { SharedErrorStateDialog } from './SharedErrorStateDialog';

const renderSkeletonsHelper = (num: number, size: number) =>
  [...Array(num)].map((x: any) => <Skeleton animation={'wave'} height={size} />);

const Inspect = () => {
  // URL data: router location, search params, etc.
  const routerLocation = useLocation();
  const searchParams = new URLSearchParams(routerLocation.search);
  const [loading, setLoading] = useState(false);

  // dynamic state
  const [dynamicState, setDynamicState] = useState(initialDynamicState);
  const [savingsData, setSavingsData] = useState<any>(null);
  const [activeGraph, setActiveGraph] = useState('Bar');
  const [activeTimeWindow, setActiveTimeWindow] = useState('7d');
  const [errorState, setErrorState] = useState<boolean>(false);

  // static state
  const [staticState, setStaticState] = useState(initialStaticState);
  const [cloudCosts, setCloudCosts] = useState<any>(null);
  const [cloudCostsTotal, setCloudCostsTotal] = useState(0);
  const [labelMapForAssets, setLabelMapForAssets] = useState<any>(labelAssetsMap);
  const [aliasedLabels, setAliasedLabels] = useState<any>(aliasedLabelDefaults);

  // ref for width hook
  const inspectGraphRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);
  useEffect(() => {
    monitorWidthResize(inspectGraphRef, setChartWidth);
  }, []);

  const reformatLabelObj = (name: string, type: string) => {
    const labelType = type.split(':')[1];
    const fullName = `${labelType}:${name}`;
    return { property: 'label', value: fullName };
  };

  const parameterizedFilters = staticState.inspectedResourceList?.map(
    ({ name, type }: InspectedResourceListItem) =>
      type.includes('label')
        ? reformatLabelObj(name, type)
        : {
            property: type,
            value: name,
          },
  );

  const allocationRequestBaseParams = {
    shareTenancyCosts: staticState.shareTenancyCosts || undefined,
    shareNamespaces: staticState.sharedNamespaces,
    shareLabels: staticState.sharedLabels,
    // should eventually be set by url params, 'weighted' is default in allocation ui
    shareSplit: 'weighted' as 'weighted',
    shareCost: staticState.shareCost,
    filters: parameterizedFilters,
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    // ensure config has been set,
    if (staticState.inspectedResourceList !== null) {
      fetchData();
    }
  }, [staticState.inspectedResourceList, activeTimeWindow]);

  const strippedLabel = (labelName: string) => labelName.substring(labelName.indexOf(':') + 1);

  const inspectedResourcesWithoutNullNames = () =>
    staticState.inspectedResourceList?.filter(
      ({ name }: InspectedResourceListItem) => name !== '',
    ) || [];

  const getAggregationTypesString = () =>
    inspectedResourcesWithoutNullNames()
      .map(({ type }: InspectedResourceListItem) => type)
      .toString();

  const getAggregationNamesString = () =>
    inspectedResourcesWithoutNullNames()
      .map((item: InspectedResourceListItem) =>
        item.type.includes('label') ? `${strippedLabel(item.type)}=${item.name}` : item.name,
      )
      .join('/');

  async function initialize() {
    setLoading(true);
    const [config, labelConfig] = await Promise.all([model.getConfigs(), model.getApiConfig()]);
    // as of now, namespace is the only supported type with external cost information
    // check in the config to see if we need to override the default label for x (in this case namespace)
    if (labelConfig.namespace_external_label !== '') {
      setLabelMapForAssets({
        ...labelMapForAssets,
        namespace: labelConfig.namespace_external_label,
      });
    }

    // make sure to take into account custom label names
    setAliasedLabels({
      ...aliasedLabels,
      owner: labelConfig.owner_label !== '' ? labelConfig.owner_label : aliasedLabels.owner,
      product: labelConfig.product_label !== '' ? labelConfig.product_label : aliasedLabels.product,
      team: labelConfig.team_label !== '' ? labelConfig.team_label : aliasedLabels.team,
      environment:
        labelConfig.environment_label !== ''
          ? labelConfig.environment_label
          : aliasedLabels.environment,
      department:
        labelConfig.department_label !== ''
          ? labelConfig.department_label
          : aliasedLabels.department,
    });

    const sharedLabelValues = config.sharedLabelValues.split(',');
    const sharedLabelNames = config.sharedLabelNames.split(',');
    let sharedLabelArray: string[] = [];
    // make sure they are same length. prevent crash from bogus config (i.e. assigning 'values' and not 'names' )
    if (sharedLabelNames.length === sharedLabelValues.length && sharedLabelValues.length !== 0) {
      sharedLabelArray = sharedLabelValues.map(
        (val: string, idx: number) => `${sharedLabelNames[idx]}:${val}`,
      );
    }
    // split names, types and generate list
    const nameList: string[] | undefined = searchParams.get('name')?.split('/');
    const typeList: string[] | undefined = searchParams.get('type')?.split(',');
    let nameTypeList: { name: string; type: string }[] = [];
    if (nameList && typeList) {
      nameTypeList = nameList.map((name: string, idx: number) => ({
        name,
        type: typeList![idx],
      }));
    } else {
      // handle missing query param
      alert('type= AND name= must be present in query params');
    }
    setStaticState({
      inspectedResourceList: establishHierarchy(nameTypeList),
      shareTenancyCosts: config.shareTenancyCosts === 'true',
      sharedNamespaces: config.sharedNamespaces.split(','),
      sharedLabels: sharedLabelArray,
      shareCost: parseFloat(config.sharedOverhead),
      currency: config.currencyCode,
    });
  }

  async function fetchData() {
    // map over values and include a label for each type
    setLoading(true);
    const labelFiltersForAssets = {
      property: 'label',
      value: inspectedResourcesWithoutNullNames()
        .map(({ name, type }: InspectedResourceListItem) => `${labelMapForAssets[type]}:${name}`)
        .toString(),
    };
    try {
      const [
        allocationOverTimeResponse,
        allocationSummaryResponse,
        cloudCostResponse,
        alertsResponse,
        controllerDataResponse,
      ] = await Promise.all([
        getAllocationData(false, getAggregationTypesString()),
        getAllocationData(true, getAggregationTypesString()),
        model.getAssets(activeTimeWindow, {
          accumulate: true,
          aggregate: 'service',
          type: 'Cloud',
          filters: [labelFiltersForAssets],
        }),
        AlertService.getAlerts(),
        getControllerData(),
      ]);
      const controllerList = controllerDataResponse.data.sets[0].allocations;
      setExternalCosts(cloudCostResponse);
      const filteredAlerts = alertsResponse.filter(
        (item: any) =>
          item.aggregation === getAggregationTypesString() &&
          item.filter === getAggregationNamesString(),
      );
      // should only call initially (as we're showing 'Monthly Savings'), but we want values from config
      if (!savingsData) {
        getSavingsData();
      }

      let efficiencyData = null;
      const { sets } = allocationSummaryResponse.data;
      if (!sets[0].allocations[getAggregationNamesString()]) {
        // we can assume that this is due to shared settings in config
        setErrorState(true);
        efficiencyData = {};
      } else {
        efficiencyData = getEffiencyData(
          allocationSummaryResponse.data,
          getAggregationNamesString(),
        );
      }

      setDynamicState({
        allocationOverTime: createDataForSpendingGraph(
          allocationOverTimeResponse.data,
          getAggregationNamesString(),
          activeTimeWindow,
        ),
        allocationSummary: allocationSummaryResponse.data,
        externalCosts: cloudCostResponse,
        alertsData: filteredAlerts,
        efficiencyData,
        loading: false,
        controllers: controllerList,
      });
      setLoading(false);
    } catch (error) {
      Logger.log('Error: ', error);
    }
  }

  const getAllocationData = async (shouldAccumulate: boolean, aggregationTypesString: string) =>
    model.getAllocationSummary(
      activeTimeWindow,
      aggregationTypesString,
      {
        ...allocationRequestBaseParams,
        accumulate: shouldAccumulate,
      },
      {},
    );

  async function getSavingsData() {
    const inspectedResourcesWithAdjustedLabels = inspectedResourcesWithoutNullNames().map(
      ({ name, type }: InspectedResourceListItem) => {
        // Adjust properties for label types
        if (type.includes('label')) {
          // Given:
          // type=label:team and value=kubecost
          // Becomes:
          // type=label and value=team:kubecost
          return { name: `${strippedLabel(type)}:${name}`, type: 'label' };
        }

        // Adjust properties for aliased label types
        if (aliasedLabels[type] !== undefined) {
          // Given:
          // type=product and value=cost-analyzer
          // Becomes: (considering that 'product' is a customizable label, for example set to 'app')
          // type=label and value=app:cost-analyzer
          return { name: `${aliasedLabels[type]}:${name}`, type: 'label' };
        }

        return { name, type };
      },
    );

    const savingsResp = await model.getSavings('2d', {
      filters: inspectedResourcesWithAdjustedLabels.map(
        ({ name, type }: InspectedResourceListItem) => ({
          property: type,
          value: name,
        }),
      ),
    });
    setSavingsData(savingsResp);
  }

  async function getControllerData() {
    return getAllocationData(true, 'controller');
  }

  const setExternalCosts = (cloudCostResponse: any) => {
    const assetSet: AssetSet = cloudCostResponse.data[0];
    delete assetSet.Kubernetes;
    const rows = Object.entries(assetSet).map(([key, asset]) => [key, asset.totalCost]);
    rows.sort((a, b) => (a[1] > b[1] ? -1 : 1));

    const totalCost = Object.keys(cloudCostResponse.data[0]).reduce(
      (start, next) => start + cloudCostResponse.data[0][next].totalCost,
      0,
    );
    setCloudCostsTotal(totalCost);
    setCloudCosts(rows.slice(0, 5)); // get top 5
  };

  // TODO: un-gnarliarize this
  const handleFilterUpdate = async (type: string, value: string): Promise<boolean> => {
    const currentInspectResourceList = [...staticState.inspectedResourceList!];
    // find item to update
    const foundItemIndex = currentInspectResourceList?.findIndex(
      (item: InspectedResourceListItem) => item.type === type,
    );
    currentInspectResourceList![foundItemIndex] = { type, name: value };
    // remove unassigned items
    const filteredItems = currentInspectResourceList.filter(
      (item: InspectedResourceListItem) => item.name !== '',
    );
    const urlNames = filteredItems
      .map((item: InspectedResourceListItem) =>
        item.type.includes('label') ? `${strippedLabel(item.type)}=${item.name}` : item.name,
      )
      .join('/');
    const urlTypes = filteredItems.map((item: InspectedResourceListItem) => item.type).toString();

    // check to see if this is a bogus ask
    const allocationSummaryResp = await getAllocationData(true, urlTypes);
    const { sets } = allocationSummaryResp.data;
    if (!sets[0].allocations[urlNames]) {
      return false;
    }
    Analytics.record('inspected_item_filtered', {
      resourceName: urlNames,
      resourceType: urlTypes,
      filteredType: type,
      filteredName: value,
    });
    window.location.href = `details.html?name=${urlNames}&type=${urlTypes}`;
    return true;
  };

  const breakApartController = (controllerName: string) => {
    const [controllerKind, strippedControllerName] = controllerName.split(':');
    return `controllerKind:${controllerKind}%2Bcontroller:${strippedControllerName}`;
  };

  const getFiltersForRequestSizingRedirect = () =>
    // request sizing page is looking for items in the following format: type:name+type:name
    // because controllerNames are titled controllerKind:rest of controlerName, we must format
    inspectedResourcesWithoutNullNames()
      .map(({ name, type }: InspectedResourceListItem) => {
        if (type === 'controller') {
          return breakApartController(name);
        }
        if (type.includes(':')) {
          return encodeURIComponent(`${type}%3A${name}`);
        }
        if (labelMapForAssets[type].includes('label')) {
          if (aliasedLabels[type] !== '') {
            return encodeURIComponent(`label:${aliasedLabels[type]}%3A${name}`);
          }

          return encodeURIComponent(`label:${type}%3A${name}`);
        }
        return `${type}%3A${name}`;
      })
      .join('%2B');

  const requestSizingRedirectForControllerClick = (controllerName: string) => {
    const redirectURL = `${getFiltersForRequestSizingRedirect()}%2B${breakApartController(
      controllerName,
    )}`;

    window.location.href = `request-sizing.html?filters=${redirectURL}`;
  };

  const {
    cpuCost,
    cpuEfficiency,
    externalCost,
    gpuCost,
    loadBalancerCost,
    networkCost,
    pvCost,
    ramCost,
    ramEfficiency,
    sharedCost,
    totalEfficiency,
  } = dynamicState.efficiencyData || {};
  // if NaN, we want to log a warning.
  if (
    Number.isNaN(cpuCost) ||
    Number.isNaN(ramCost) ||
    Number.isNaN(networkCost) ||
    Number.isNaN(loadBalancerCost)
  ) {
    Logger.log('displaying NaN value');
  }

  const costByTypeList = [
    { value: cpuCost, label: 'CPU', dataTag: 'cpuCost' },
    { value: ramCost, label: 'RAM', dataTag: 'ramCost' },
    { value: networkCost, label: 'Network', dataTag: 'networkCost' },
    { value: loadBalancerCost, label: 'LB', dataTag: 'loadBalancerCost' },
    { value: pvCost, label: 'PV', dataTag: 'pvCost' },
    { value: sharedCost, label: 'Shared', dataTag: 'sharedCost' },
    { value: gpuCost, label: 'GPU', dataTag: 'gpuCost' },
  ];
  const totalCost = costByTypeList.reduce((acc, costItem) => acc + costItem.value, 0);
  const sortedTypesByCost = costByTypeList.sort((a, b) => b.value - a.value);
  const resourceTypesList = sortedTypesByCost.map((item) => item.dataTag);
  const sharedTooltipMessage = `Shared costs, such as cluster management fees, attributed to this ${getAggregationTypesString()}  `;
  // primary aggregation is first item in array with a 'name'
  const primaryAggregation = inspectedResourcesWithoutNullNames()[0];
  const secondaryAggregations =
    (staticState.inspectedResourceList &&
      staticState.inspectedResourceList.filter(
        (item: InspectedResourceListItem) => item.type !== primaryAggregation?.type,
      )) ||
    [];

  return (
    <>
      <InspectHeader />
      <div style={{ display: 'grid', rowGap: '2em' }}>
        <SharedErrorStateDialog
          open={errorState}
          resourceName={getAggregationNamesString()!}
          sharedLabels={staticState.sharedLabels}
          sharedNamespaces={staticState.sharedNamespaces}
        />
        <InspectFilterTile
          activeTimeWindow={activeTimeWindow}
          handleFilterUpdate={handleFilterUpdate}
          primaryAggregation={primaryAggregation}
          secondaryAggregations={secondaryAggregations}
          setActiveTimeWindow={setActiveTimeWindow}
        />
        <Grid>
          {!loading && dynamicState.allocationSummary ? (
            <CostBreakdownTile
              activeTimeWindow={activeTimeWindow}
              currency={staticState.currency}
              sortedTypesByCost={sortedTypesByCost}
              tooltipMessage={sharedTooltipMessage}
              totalCost={totalCost}
            />
          ) : (
            <div>{renderSkeletonsHelper(4, 30)}</div>
          )}
        </Grid>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 400px',
            gap: '2em',
          }}
        >
          <Paper style={{ padding: '2em' }}>
            <GraphToggle setActiveGraph={setActiveGraph} />
            <Box display={'flex'} fontSize={18} gridColumnGap={'1em'} mb={'1em'}>
              <strong>Historical Cost</strong>
            </Box>
            <div ref={inspectGraphRef}>
              {!loading && dynamicState.allocationOverTime ? (
                activeGraph === 'Bar' ? (
                  <HistoricalCostBarChart
                    chartWidth={chartWidth}
                    currency={staticState.currency}
                    data={dynamicState.allocationOverTime}
                    resourceTypes={resourceTypesList}
                  />
                ) : (
                  <AreaChartComponent
                    currency={staticState.currency}
                    dataSet={dynamicState.allocationOverTime}
                    resourceTypes={resourceTypesList}
                    width={chartWidth}
                  />
                )
              ) : (
                renderSkeletonsHelper(5, 50)
              )}
            </div>
          </Paper>
          <CostEfficiencyCard
            cpuEfficiency={cpuEfficiency * 100}
            efficiencyCostWeight={totalEfficiency * 100}
            ramEfficiency={ramEfficiency * 100}
          />
        </div>
        {savingsData && (
          <SavingsCardGroup
            currency={staticState.currency}
            onClickHref={`request-sizing.html?filters=${getFiltersForRequestSizingRedirect()}`}
            savingsData={savingsData}
          />
        )}
        {(primaryAggregation?.type === 'namespace' ||
          primaryAggregation?.type?.includes('label')) && (
          <ControllerEfficiencyCard
            controllerMap={dynamicState.controllers}
            currency={staticState.currency}
            handleContextSwitch={requestSizingRedirectForControllerClick}
            savingsData={savingsData}
          />
        )}
        <PerformanceInsightsCard
          controllers={dynamicState.controllers}
          handleContextSwitch={requestSizingRedirectForControllerClick}
        />
        {primaryAggregation?.type === 'namespace' && (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              columnGap: '2em',
            }}
          >
            <AlertsInspectCard
              alerts={dynamicState.alertsData}
              resourceName={primaryAggregation.name}
            />
            <ExternalCostsTile
              costData={cloudCosts}
              currency={staticState.currency}
              resourceName={getAggregationNamesString()}
              totalCost={cloudCostsTotal}
            />
            <NetworkCostsTile
              currency={staticState.currency}
              resourceName={primaryAggregation.name}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Inspect;
