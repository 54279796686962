/* eslint-disable react/no-unstable-nested-components */
import { FC, useEffect, useMemo, useState } from 'react';

import {
  SortingState,
  VisibilityState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { TableSortLabel } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import {
  Button,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@kubecost-frontend/holster';

import { ArrowIcon } from '../../assets/images';
import { toCurrency } from '../../services/format';

import { useURLTokens } from './tokens';
import { AutoscalingWorkloadSchedule, RequestSizingRecommendation } from './types';

interface ResultsTableProps {
  currency: string;
  currentAutoscalings: AutoscalingWorkloadSchedule[];
  onRowSelect: (Recommendation: RequestSizingRecommendation) => void;
  recommendations: RequestSizingRecommendation[];
  totalRecommendations: number;
}

const Missing = 'N/A';

const ResultsTable: FC<ResultsTableProps> = ({
  currency,
  currentAutoscalings,
  onRowSelect,
  recommendations,
  totalRecommendations,
}) => {
  const { setState: setURLState, state: urlState } = useURLTokens();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [hasAutoScaling, setHasAutoScaling] = useState(false);
  const [numRowsMenuOpen, setNumRowsMenuOpen] = useState(false);

  const columnHelper = createColumnHelper<RequestSizingRecommendation>();

  useEffect(() => {
    setHasAutoScaling(currentAutoscalings.length > 0);
  }, [currentAutoscalings]);

  const columns = [
    columnHelper.display({
      id: 'autosizing',
      enableHiding: hasAutoScaling,
      cell: (cell) => {
        const currentAutoScaling = currentAutoscalings.find(
          (item: AutoscalingWorkloadSchedule) =>
            item.workload.clusterID === cell.row.original.clusterID &&
            item.workload.namespace === cell.row.original.namespace &&
            item.workload.controllerKind === cell.row.original.controllerKind &&
            item.workload.controllerName === cell.row.original.controllerName,
        );
        return (
          currentAutoscalings.length &&
          currentAutoScaling && (
            <Tooltip content={'Automatic Right-sizing enabled'}>
              <CheckCircleIcon htmlColor={'#63E892'} />
            </Tooltip>
          )
        );
      },
    }),

    columnHelper.accessor('clusterID', {
      header: 'Cluster',
    }),
    columnHelper.accessor((row) => `${row.namespace}\\${row.controllerKind}`, {
      header: 'Namespace\\Controller',
    }),
    columnHelper.accessor('containerName', {
      header: 'Container',
    }),
    columnHelper.accessor((row) => row.latestKnownRequest?.memory ?? Missing, {
      header: 'RAM Requested',
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.recommendedRequest?.memory ?? Missing, {
      header: 'RAM Recommended',
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.latestKnownRequest?.cpu ?? Missing, {
      header: 'CPU Requested',
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.recommendedRequest?.cpu ?? Missing, {
      header: 'CPU Recommended',
      enableSorting: false,
    }),
    columnHelper.accessor('currentEfficiency.total', {
      header: 'Current Efficiency',
      cell: (value) => (
        <Tooltip content={'Efficency over the requested window'}>
          <span>{`${(value.cell.getValue() * 100).toFixed(2)}%` || '-'}</span>
        </Tooltip>
      ),
    }),
    columnHelper.accessor(
      (row) =>
        row.monthlySavings?.cpu && row.monthlySavings.memory
          ? row.monthlySavings.cpu + row.monthlySavings.memory
          : Missing,
      {
        header: 'Est. Savings (Monthly)',
        cell: (value) => {
          const val = value.cell.getValue();

          if (typeof val === 'string') {
            return val;
          }

          return toCurrency(val, currency);
        },
      },
    ),
  ];

  const sortableIds = [
    'clusterID',
    'Namespace\\Controller',
    'containerName',
    'currentEfficiency_total',
    'Est. Savings (Monthly)',
  ];

  const data = useMemo(() => recommendations, [recommendations]);
  const pagination = useMemo(
    () => ({
      pageIndex: urlState.page,
      pageSize: urlState.itemsPerPage,
    }),
    [urlState.page, urlState.itemsPerPage],
  );

  const [columnVisibility, setColumnVisiblity] = useState<VisibilityState>({
    autosizing: hasAutoScaling,
  });

  const table = useReactTable({
    columns,
    data,
    state: {
      sorting,
      pagination,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisiblity,
    pageCount: Math.ceil(totalRecommendations / pagination.pageSize),
    onSortingChange: setSorting,
    onPaginationChange: (updater) => {
      const newPage = typeof updater === 'function' ? updater(pagination) : updater;
      setURLState({
        page: newPage.pageIndex,
        itemsPerPage: newPage.pageSize,
      });
    },
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // NOTES
  // So - This is a first attempt at using react-table
  // in order to fix the perf issues with rendering a million items at once.
  // So. Here goes lol. This isn't pretty, and ideally we'd push this
  // to a datataable style component in Holster.
  // -- Thomas

  return (
    <>
      <Table className={'w-full'}>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                // Apply the header cell props
                <TableCell
                  className={'cursor-pointer'}
                  id={header.id}
                  // eslint-disable-next-line react/jsx-handler-names
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                  {sortableIds.includes(header.id) && (
                    <TableSortLabel
                      active={header.column.getIsSorted() !== false}
                      direction={header.column.getIsSorted() || 'asc'}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} onClick={() => onRowSelect(row.original)}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className={'mt-3 flex h-6 w-full justify-between'}>
        <div className={'flex items-center'}>
          <Typography className={'mr-1'} variant={'p'}>
            Rows per page
          </Typography>
          <Button className={'p-1'} onClick={() => setNumRowsMenuOpen(true)} variant={'default'}>
            <ArrowIcon className={'inline'} direction={'LEFT'} />
            {table.getState().pagination.pageSize}
          </Button>
          {numRowsMenuOpen && (
            <Menu
              items={['10', '25', '50']}
              onClose={() => setNumRowsMenuOpen(false)}
              selectItem={(v) => {
                table.setPageSize(parseInt(v.text, 10));
                setNumRowsMenuOpen(false);
              }}
            />
          )}
        </div>
        <div className={'flex items-center justify-end'}>
          <Typography style={{ marginRight: 19 }} variant={'p'}>
            Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </Typography>
          <Button
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            style={{ marginRight: 7 }}
            variant={'default'}
          >
            Previous
          </Button>
          <Button
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
            style={{ marginRight: 15 }}
            variant={'default'}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
ResultsTable.displayName = 'ResultsTable';
export { ResultsTable };
