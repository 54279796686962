// react
import React, { FC, useState, useEffect } from 'react';

// mui
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core';

// holster
import {
  Alert as HolsterAlert,
  Chip,
  Typography,
} from '@kubecost-frontend/holster';

import { useHosted } from '../../../hooks/useHosted';
import { useNavigate } from 'react-router';
import { CloseIcon } from '../../../assets/images';

const useStyles = makeStyles({
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      cursor: 'pointer',
    },
  },
});

const TeamSwitcher: FC = () => {
  const { profile, currentTeam, selectCurrentTeam, acceptInvite } = useHosted();
  const navigate = useNavigate();

  const allTeams = () => {
    if (!profile) return [];
    return [...profile.owned_teams, ...profile.teams];
  };
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleSetTeam = (team: any) => {
    selectCurrentTeam(team.id);
    navigate(0);
  };

  const handleOpenDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  if (allTeams().length === 0) {
    return <></>;
  }

  return (
    <>
      <div className="flex items-center p-4" onClick={handleOpenDialog}>
        <PeopleOutlineIcon className="mr-4" />
        <div
          style={{
            display: 'flex',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <Typography
              variant="p"
              className="font-bold hover:text-kc-primary cursor-pointer"
            >
              Switch Team
            </Typography>
            <Typography
              variant="p"
              className="font-regulat hover:text-kc-secondary cursor-pointer"
            >
              {currentTeam && currentTeam.name}
            </Typography>
          </div>
        </div>
      </div>
      <Dialog
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        open={dialogOpen}
      >
        <div className="p-8">
          <div className="flex">
            <Typography variant="h5" className="mb-4 flex-grow">
              Switch team
            </Typography>
            <CloseIcon
              onClick={() => setDialogOpen(false)}
              className="mt-2 hover:cursor-pointer"
            />
          </div>
          <hr className="border-kc-gray-100" />
          <div>
            {allTeams().length > 0 &&
              allTeams().map((team: any) => {
                return (
                  <div
                    className={classes.listItem}
                    key={team.name}
                    onClick={() => handleSetTeam(team)}
                    style={{
                      paddingTop: '1em',
                      paddingBottom: '1em',
                      display: 'grid',
                      gridTemplateColumns: '1fr auto',
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: '17px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {team.name}
                      </div>
                      {currentTeam && currentTeam.id === team.id && (
                        <Typography
                          variant="p"
                          style={{ color: '#28B359', marginRight: '10px' }}
                        >
                          Current team
                        </Typography>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Dialog>
    </>
  );
};

TeamSwitcher.displayName = 'TeamSwitcher';
export default TeamSwitcher;
