import { ActionMenuItem } from '../../pages/Cloud/ActionMenu/ActionMenuItem';

import { DateRange, DateRangePicker } from './DateRangePicker';

interface YetAnotherDatePickerProps {
  onChangeDateRange?: (dateRange: DateRange) => void;
  onClick?: (window: string) => void;
  options: Record<string, string>;
  selectedOption?: string;
  selectedRange?: DateRange;
}

const YetAnotherDatePicker = ({
  onChangeDateRange: handleOnAcceptDateRange,
  onClick: handleOnClickOption,
  options,
  selectedOption,
  selectedRange,
}: YetAnotherDatePickerProps) => (
  <>
    <ul className={'columns-2 gap-0'}>
      {Object.entries(options).map(([key, value]) => (
        <li key={key}>
          <ActionMenuItem
            className={'w-full rounded text-left hover:bg-kc-success-light'}
            isSelected={key === selectedOption}
            onClick={() => handleOnClickOption && handleOnClickOption(key)}
          >
            {value}
          </ActionMenuItem>
        </li>
      ))}
    </ul>
    {handleOnAcceptDateRange && (
      <section>
        <DateRangePicker
          endValue={selectedRange?.endDate}
          onAccept={handleOnAcceptDateRange}
          startValue={selectedRange?.startDate}
        />
      </section>
    )}
  </>
);

export { YetAnotherDatePicker };
