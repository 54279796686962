import { memo } from 'react';

import { ArrowLeftSharp } from '@material-ui/icons';

const Unauthorized = () => (
  <div className={'main'}>
    <div className={'card'}>
      <div className={'header'}>
        <h1>Unauthorized Access</h1>
      </div>
      <div className={'body'}>
        <p>
          Your account is not currently authorized to access this product. We recommend contacting
          your internal administrator to be granted access.
        </p>
        <p>
          <a href={'/'} id={'back-link'}>
            <ArrowLeftSharp />
            Back to our site
          </a>
        </p>
      </div>
    </div>
  </div>
);

export default memo(Unauthorized);
