import { useState } from 'react';

import Popover from '@material-ui/core/Popover';

import { Button } from '@kubecost-frontend/holster';

import { DateRange } from './DateRangePicker';
import { isAPIQueryWindowRange, useQueryWindowParamState } from './useQueryWindowParamState';
import { YetAnotherDatePicker } from './YetAnotherDatePicker';

interface QueryWindowSelectorProps<QueryWindowOptions extends string> {
  options: Record<QueryWindowOptions, string>;
}

/**
 * Convert a local date into the same year, month, date, hour, minute, second, ms in UTC
 * NOT just the same local date in UTC, subtle but important difference
 * 
 * @param d Date
 * @returns Date
 */
const convertToUTC = (d: Date) => {
  const utcDate = new Date();
  
  utcDate.setUTCFullYear(d.getFullYear(), d.getMonth(), d.getDate());
  utcDate.setUTCHours(d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds());

  return utcDate;
};


/**
 * Convert a utc date into the same year, month, date, hour, minute, second, ms in local
 * NOT just the same utc date in local, subtle but important difference
 * 
 * @param d Date
 * @returns Date
 */
const convertToLocal = (d: Date) => {
  const localDate = new Date();

  localDate.setFullYear(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
  localDate.setHours(d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds(), d.getUTCMilliseconds());

  return localDate;
};

const QueryWindowSelector = <QueryWindowOptions extends string>({
  options,
}: QueryWindowSelectorProps<QueryWindowOptions>) => {
  const { isQueryWindow, queryWindowState, setQueryWindowState } =
    useQueryWindowParamState<QueryWindowOptions>(options);
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);

  const getSelectedRange = () => {
    if (!isAPIQueryWindowRange(queryWindowState)) return undefined;

    const dateRange = queryWindowState.split(',').map(d => convertToLocal(new Date(d)));

    dateRange[1].setDate(dateRange[1].getDate() - 1);

    return {
      // Undo the getDate() + 1 done when setting the end date when displaying the end date
      endDate: dateRange[1],
      startDate: dateRange[0],
    };
  };

  const handleOnClickOption = (queryWindow: string) => {
    if (!isQueryWindow(queryWindow)) return;

    setQueryWindowState(queryWindow);
    setAnchorEl(undefined);
  };

  const handleOnChangeDateRange = (dateRange: DateRange) => {
    // Adjust date time to 00:00:00.000
    let adjustedStartDate = new Date(dateRange.startDate.toDateString());
    let adjustedEndDate = new Date(dateRange.endDate.toDateString());

    // Adjust end date to midnight the following day by adding 1 day (if endDate has changed).
    // TODO: Look into adjustEndDate.setUTCHours(23, 59, 59, 999)
    adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

    //Convert to UTC
    const utcStartDate = convertToUTC(adjustedStartDate);
    const utcEndDate = convertToUTC(adjustedEndDate);

    // Format as string of comma seperated rfc3339 date strings
    const dateRangeString = `${utcStartDate.toISOString().split('.')[0]}Z,${
      utcEndDate.toISOString().split('.')[0]
    }Z`;

    handleOnClickOption(dateRangeString);
  };

  const handleOnClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <>
      <Button onClick={(e) => setAnchorEl(e.currentTarget)} variant={'default'}>
        {options[queryWindowState] ?? 'Custom'}
      </Button>
      <Popover
        PaperProps={{ className: 'p-4' }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleOnClose}
        open={!!anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <YetAnotherDatePicker
          onChangeDateRange={handleOnChangeDateRange}
          onClick={handleOnClickOption}
          options={options}
          selectedOption={queryWindowState}
          selectedRange={getSelectedRange()}
        />
      </Popover>
    </>
  );
};

export { QueryWindowSelector };
