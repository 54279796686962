// react
import { SyntheticEvent } from 'react';

// holster
import { Button, Typography } from '@kubecost-frontend/holster';

import { useHosted } from '../../hooks/useHosted';

export const UserProfile = () => {
    const { config } = useHosted();

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }} id='user-profile'>
        <div>
          <Typography variant='h5'>
            <strong>User Profile</strong>
          </Typography>
          <Typography style={{ color: 'gray' }} variant='p'>
            Manage your profile
          </Typography>
        </div>
        <Button
          onClick={(e: React.SyntheticEvent) => {
            window.location.href = `${
              config!.userServiceAPIUrl
            }/user/profile?return=${encodeURIComponent(window.location.href)}`;
          }}
          style={{ marginTop: '2em' }}
          variant={'primary'}
        >
          Manage Profile
        </Button>
      </div>
    );
};